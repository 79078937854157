import store from '@store'
import { eventBus } from '@services/eventBus'
import { setDownloadStatus } from '@components/DesktopDownloader.vue'
import { ElectronInterface } from '@/global'
import checkSafeToLogout from '@/utils/checkSafeToLogout'

export interface Deeplink {
	path: string
	query: any
	token: string
}

export class OmniDesktopService {
	private ipcRenderer?: any

	isMainWindow = false
	isFirstPrimaryViewerWindow: boolean = false
	screen: any
	/**
	 * Is this the Omni Desktop or Browser?
	 */
	isConnected: boolean

	constructor(electronInterface: ElectronInterface) {
		const { ipcRenderer, screen } = electronInterface
		this.ipcRenderer = ipcRenderer
		this.screen = screen
		this.isConnected = !!this.ipcRenderer
		if (ipcRenderer) {
			ipcRenderer.on('log', function(evt, message) {
				console.log(message)
			})

			ipcRenderer.on('prompt-install-relaunch', function(evt, currentVersion) {
				store.dispatch('showAppUpdateAvailable', true)
			})

			// Keep the desktop interface cleaner and less interconnected
			ipcRenderer.on('deeplink', async function(evt, deeplinkObject: Deeplink) {
				eventBus.post('deeplink', deeplinkObject)
			})

			ipcRenderer.on('download-event', (evt, data) => {
				setDownloadStatus(data)
			})

			ipcRenderer.on('close-window', async () => {
				const isCloseConfirmed = await checkSafeToLogout()
				if (isCloseConfirmed) this.ipcRenderer.send('confirm-close')
			})

			this.request('isFirstPrimaryViewerWindow').then(r => (this.isFirstPrimaryViewerWindow = r))
			this.isMainWindow = this.ipcRenderer.sendSync('isMainWindow')
		}
	}

	// Wrap ipcRenderer calls
	on(channel: String, callback: Function) {
		if (!this.isConnected) return false
		this.ipcRenderer.on(channel, callback)
	}
	off(channel: String, callback: Function) {
		if (!this.isConnected) return false
		this.ipcRenderer.off(channel, callback)
	}

	updateConfig(annexURL, clinicCode, applicationRootUrl) {
		if (!this.ipcRenderer) return

		this.ipcRenderer.send('config-update', {
			annexURL,
			'clinic-code': clinicCode,
			applicationRootUrl,
		})
	}

	installAndRelaunch() {
		if (this.ipcRenderer) this.ipcRenderer.send('install-and-relaunch')
	}

	/**
	 * Invokes an asynchronous request and returns a promise with the result
	 * @param {String} channel
	 * @param {any} request
	 * @returns {Promise}
	 */
	request(channel: string, request?: any): Promise<any> {
		return this.ipcRenderer.invoke(channel, request)
	}
}

export const omniDesktop = new OmniDesktopService(window.ElectronInterface || ({} as ElectronInterface))

export default omniDesktop

// QUESTION: Do we need to do this?
window.omniDesktop = omniDesktop
