import macro from 'vtk.js/Sources/macro'
import vtkMouseCameraTrackballZoomManipulator from './vtkOverrides/vtkMouseCameraTrackballZoomManipulator'
import vtkMouseCameraTrackballPanManipulator from './vtkOverrides/vtkMouseCameraTrackballPanManipulator'
import vtkGestureCameraManipulator from 'vtk.js/Sources/Interaction/Manipulators/GestureCameraManipulator'

// Extend our internal MPRSlice function
import vtkInteractorStyleMPRSlice from './vtkInteractorStyleMPRSlice.js'
import vtkWindowLevelManipulator from './vtkWindowLevelManipulator.js'
import vtkCrosshairManipulator from './vtkCrosshairManipulator.js'
import vtkAnnotationManipulator from './vtkAnnotationManipulator.js'
import vtkStackScrollManipulator from './vtkStackScrollManipulator.js'
import vtkRotateSliceManipulator from './vtkRotateSliceManipulator'
import { TOOLS, BUTTONS, VTK_BUTTONS } from './consts.js'

const TOOL_MAP = {
	[TOOLS.LEVEL_TOOL]: vtkWindowLevelManipulator,
	[TOOLS.SELECT_TOOL]: vtkCrosshairManipulator,
	[TOOLS.ANGLE_TOOL]: vtkAnnotationManipulator,
	[TOOLS.TEXT_TOOL]: vtkAnnotationManipulator,
	[TOOLS.ARROW_TOOL]: vtkAnnotationManipulator,
	[TOOLS.CIRCLE_TOOL]: vtkAnnotationManipulator,
	[TOOLS.ELLIPSE_TOOL]: vtkAnnotationManipulator,
	[TOOLS.ERASER_TOOL]: vtkAnnotationManipulator,
	[TOOLS.LENGTH_TOOL]: vtkAnnotationManipulator,
	[TOOLS.RECT_TOOL]: vtkAnnotationManipulator,
	[TOOLS.PROBE_TOOL]: vtkAnnotationManipulator,
	[TOOLS.ZOOM_TOOL]: vtkMouseCameraTrackballZoomManipulator,
	[TOOLS.PAN_TOOL]: vtkMouseCameraTrackballPanManipulator,
	[TOOLS.SCROLL_TOOL]: vtkStackScrollManipulator,
	[TOOLS.PLANE_TOOL]: vtkRotateSliceManipulator,
	[TOOLS.ROLL_TOOL]: vtkRotateSliceManipulator,
}

// ----------------------------------------------------------------------------
// Event Types (copied from InteractorStyleManipulator)
// ----------------------------------------------------------------------------

const START_INTERACTION_EVENT = { type: 'StartInteractionEvent' }
// const INTERACTION_EVENT = { type: 'InteractionEvent' }
const END_INTERACTION_EVENT = { type: 'EndInteractionEvent' }

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
	levelScale: 1,
	manipulators: {
		[BUTTONS.LEFT_MKEY]: null,
		[BUTTONS.MIDDLE_MKEY]: null,
		[BUTTONS.RIGHT_MKEY]: null,
	},
}

// ----------------------------------------------------------------------------
// vtkInteractorStyleMPR methods
// ----------------------------------------------------------------------------

function vtkInteractorStyleMPR(publicAPI, model) {
	// Set our className
	model.classHierarchy.push('vtkInteractorStyleMPR')

	publicAPI.resetManipulators = () => {
		publicAPI.removeAllMouseManipulators()

		Object.values(model.manipulators).forEach(manipBtn => {
			if (manipBtn) {
				publicAPI.addMouseManipulator(manipBtn.manipulator)
			}
		})

		publicAPI.addMouseManipulator(model.scrollManipulator)
		publicAPI.updateScrollManipulator()

		// Enable 2-finger pinch and zoom, but disable arbitrary rotation
		const manipulator = vtkGestureCameraManipulator.newInstance({
			pinchEnabled: true,
			panEnabled: true,
			rotateEnabled: false,
		})
		manipulator.setInteractorStyle(publicAPI)
		publicAPI.addGestureManipulator(manipulator)
	}
	publicAPI.setTools = toolMap => {
		Object.entries(toolMap).forEach(([buttonName, toolName]) => {
			if (toolName) publicAPI.setTool(toolName, buttonName)
			else publicAPI.clearTool(buttonName)
		})
		publicAPI.resetManipulators()
	}
	publicAPI.setTool = (toolName, buttonName) => {
		const manipulator = TOOL_MAP[toolName].newInstance({
			button: VTK_BUTTONS[buttonName],
		})

		switch (toolName) {
			case TOOLS.LEVEL_TOOL:
				manipulator.setLevelScale(publicAPI.getLevelScale())
				manipulator.setOnLevelsChanged(({ windowWidth, windowCenter }) => {
					publicAPI.setWindowLevel(windowWidth, windowCenter)
					const onLevelsChanged = publicAPI.getOnLevelsChanged()
					if (onLevelsChanged) {
						onLevelsChanged({ windowCenter, windowWidth })
					}
				})
				break
			case TOOLS.SELECT_TOOL:
				manipulator.setOnClickCallback(res => {
					const onPointSelected = publicAPI.getOnPointSelected()
					if (onPointSelected) {
						onPointSelected(res)
					}
				})
				break
			case TOOLS.SCROLL_TOOL:
				manipulator.setMPRSliceInteractor(publicAPI)
				break
			case TOOLS.ZOOM_TOOL:
				manipulator.setFlipDirection(true)
				break
			case TOOLS.PLANE_TOOL:
				manipulator.setOnRotateCallback(res => {
					const onRotate = publicAPI.getOnSliceRotated()
					if (onRotate) {
						onRotate(res)
					}
				})
				break
			case TOOLS.ROLL_TOOL:
				manipulator.setArbitraryScale(2)
				manipulator.setOnRotateCallback(res => {
					const onRoll = publicAPI.getOnViewRolled()
					if (onRoll) {
						onRoll(res)
					}
				})
				break
			default:
				if (manipulator.isA('vtkAnnotationManipulator')) {
					manipulator.setToolName(toolName)
					const inter = publicAPI.getAnnotationInterface()
					if (inter) manipulator.setMouseInterface(inter)
				}
		}
		model.manipulators[buttonName] = { manipulator, toolName }
	}
	publicAPI.clearTool = buttonName => {
		model.manipulators[buttonName] = null
	}

	// We need to know if it's the eraser tool so the eraser takes higher click-handling
	// precedence over the annotation interaction.
	function isEraser(buttonName) {
		return model.manipulators[buttonName] && model.manipulators[buttonName].toolName === TOOLS.ERASER_TOOL
	}

	// Let the annotation interface take first pick of the button presses.
	const superHandleLeftButtonPress = publicAPI.handleLeftButtonPress
	publicAPI.handleLeftButtonPress = callData => {
		if (
			!isEraser(BUTTONS.LEFT_MKEY) &&
			publicAPI.getAnnotationInterface().onButtonDown(callData.position, TOOLS.PRE_TOOL)
		) {
			publicAPI.invokeStartInteractionEvent(START_INTERACTION_EVENT)
		} else superHandleLeftButtonPress && superHandleLeftButtonPress(callData)
	}
	const superHandleLeftButtonRelease = publicAPI.handleLeftButtonRelease
	publicAPI.handleLeftButtonRelease = callData => {
		if (
			!isEraser(BUTTONS.LEFT_MKEY) &&
			publicAPI.getAnnotationInterface().onButtonUp(callData.position, TOOLS.PRE_TOOL)
		) {
			model.currentManipulator = null
			publicAPI.invokeEndInteractionEvent(END_INTERACTION_EVENT)
		} else superHandleLeftButtonRelease && superHandleLeftButtonRelease(callData)
	}
	const superHandleMiddleButtonPress = publicAPI.handleMiddleButtonPress
	publicAPI.handleMiddleButtonPress = callData => {
		if (
			!isEraser(BUTTONS.MIDDLE_MKEY) &&
			publicAPI.getAnnotationInterface().onButtonDown(callData.position, TOOLS.PRE_TOOL)
		) {
			publicAPI.invokeStartInteractionEvent(START_INTERACTION_EVENT)
		} else superHandleMiddleButtonPress && superHandleMiddleButtonPress(callData)
	}
	const superHandleMiddleButtonRelease = publicAPI.handleMiddleButtonRelease
	publicAPI.handleMiddleButtonRelease = callData => {
		if (
			!isEraser(BUTTONS.MIDDLE_MKEY) &&
			publicAPI.getAnnotationInterface().onButtonUp(callData.position, TOOLS.PRE_TOOL)
		) {
			model.currentManipulator = null
			publicAPI.invokeEndInteractionEvent(END_INTERACTION_EVENT)
		} else superHandleMiddleButtonRelease && superHandleMiddleButtonRelease(callData)
	}
	const superHandleRightButtonPress = publicAPI.handleRightButtonPress
	publicAPI.handleRightButtonPress = callData => {
		if (
			!isEraser(BUTTONS.RIGHT_MKEY) &&
			publicAPI.getAnnotationInterface().onButtonDown(callData.position, TOOLS.PRE_TOOL)
		) {
			publicAPI.invokeStartInteractionEvent(START_INTERACTION_EVENT)
		} else superHandleRightButtonPress && superHandleRightButtonPress(callData)
	}
	const superHandleRightButtonRelease = publicAPI.handleRightButtonRelease
	publicAPI.handleRightButtonRelease = callData => {
		if (
			!isEraser(BUTTONS.RIGHT_MKEY) &&
			publicAPI.getAnnotationInterface().onButtonUp(callData.position, TOOLS.PRE_TOOL)
		) {
			model.currentManipulator = null
			publicAPI.invokeEndInteractionEvent(END_INTERACTION_EVENT)
		} else superHandleRightButtonRelease && superHandleRightButtonRelease(callData)
	}
	// Annotation interface isn't listening to the onMouseMove event anyways, but leave this here until it does
	// const superHandleMouseMove = publicAPI.handleMouseMove
	// publicAPI.handleMouseMove = callData => {
	// 	if (
	// 		!publicAPI.getAnnotationInterface().onMouseMove(callData.position, TOOLS.PRE_TOOL) &&
	// 		superHandleMouseMove
	// 	)
	// 		superHandleMouseMove(callData)
	// }

	// Initialize manipulators
	publicAPI.resetManipulators()
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
	Object.assign(model, DEFAULT_VALUES, initialValues)

	// Inheritance
	vtkInteractorStyleMPRSlice.extend(publicAPI, model, initialValues)

	macro.setGet(publicAPI, model, [
		'onLevelsChanged',
		'onPointSelected',
		'onSliceRotated',
		'onViewRolled',
		'annotationInterface',
		'levelScale',
	])

	// Instantiate Object specific methods
	vtkInteractorStyleMPR(publicAPI, model)
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkInteractorStyleMPRWindowLevel')

// ----------------------------------------------------------------------------

export default Object.assign({ newInstance, extend })
