










import { Cell } from '../classes'

export default {
	name: 'ResponseInfo',
	props: ['cell'],
	watch: {
		cell() {
			this.init()
		},
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			let cell: Cell = this.cell
			if (cell.editorMode) return
			cell.subLayouts = []
			let t = cell.report.response
			if (t == null) return
			let selector = t.root.controls.find(c => c.type === 'Response Selector')
			if (selector == null) return
			let infoType = t.values[selector.path]
			if (infoType) {
				let l = t.layouts.find(l => l.name === infoType)
				let layout = l.createInstance()
				layout.readOnly = true
				layout.template = t
				layout.forcePath = infoType
				layout.hydrate()
				cell.addSubLayout(layout)
			}
		},
	},
}
