<template>
	<div class="report-radio-group" :class="{ stack: cell.props['label position'] == 'Left' }">
		<label v-if="cell.props.label" :style="labelStyle">{{ cell.props.label }}</label>
		<div style="margin: -4px 0;">
			<ast-radio
				v-for="(item, i) in options"
				:key="i"
				v-model="cell.value"
				:name="'radio-' + _uid"
				:value="item"
				:disabled="disabled"
				class="radio-option"
				:class="{ vertical: cell.props.vertical }"
			>
				{{ item }}
				<span v-if="!item" style="font-style: italic;">Unspecified</span>
			</ast-radio>
		</div>
		<div v-if="disabled" class="overlay"></div>
	</div>
</template>

<script>
import AstRadio from '@components/Radio.vue'

export default {
	name: 'RadioGroup',
	components: {
		AstRadio,
	},
	props: ['cell'],
	computed: {
		disabled() {
			return this.cell.editorMode || this.cell.readOnly
		},
		options() {
			let cell = this.cell
			const options = [...cell.props.options]
			// if dropdown is not required, check if there is a null/empty option; add one if necessary
			if (!cell.props.required && !options.some(o => [null, ''].includes(o))) {
				options.unshift('')
			}
			return options
		},
		labelStyle() {
			if (this.cell.props['label position'] === 'Top') {
				return {
					display: 'block',
					marginBottom: '8px',
				}
			}
			return {}
		},
	},
	created() {
		if (this.cell.template.editorMode || (this.cell.report && this.cell.report.isPreview)) {
			this.cell.value = this.cell.props.default
		}
	},
}
</script>

<style lang="scss">
.report-radio-group {
	padding: 4px 0;
	position: relative;
	.overlay {
		position: absolute;
		background: transparent;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.radio-option {
		display: inline-block;
		margin: 4px;
		&.vertical {
			display: block;
		}
	}
}
</style>
