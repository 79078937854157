import { keys as MPRKeys } from '@/store/modules/mpr/mutations'
import { getDefaultViewData } from '@/store/modules/mpr/state'
import loadVolumeFromSeries from './loadVolumeFromSeries'
import createMprAnnotationRenderingAsync from './createMprAnnotationRenderingAsync'
import saveMprImageAsync from './saveMprImageAsync'
import printMprImageAsync from './printMprImageAsync'
import copyMprImageAsync from './copyMprImageAsync'
import { showMprPerfDialog } from '@dialogs/MprPerformance'
import { eventBus } from '@services/eventBus'
import { consts } from '@/vtk/index'

export default {
	loadVolumeFromSeries,
	createMprAnnotationRenderingAsync,
	saveMprImageAsync,
	printMprImageAsync,
	copyMprImageAsync,
	setMprActiveToolAndBroadcast({ commit }, payload) {
		const mutation = 'SET_MPR_ACTIVE_TOOL'
		commit(mutation, payload)
		eventBus.broadcast(eventBus.type.VUEX_MUTATION, { type: mutation, payload })
	},
	rotateActiveViewClockwise({ commit, getters }) {
		commit(MPRKeys.ROTATE_VIEW_BY_ANGLE, { index: getters.mprActiveViewKey, degrees: 90 })
	},
	rotateActiveViewCounterClockwise({ commit, getters }) {
		commit(MPRKeys.ROTATE_VIEW_BY_ANGLE, { index: getters.mprActiveViewKey, degrees: -90 })
	},
	flipHActiveView({ commit, getters }) {
		commit(MPRKeys.FLIP_VIEW_H, { index: getters.mprActiveViewKey })
	},
	flipVActiveView({ commit, getters }) {
		commit(MPRKeys.FLIP_VIEW_V, { index: getters.mprActiveViewKey })
	},
	resetViewData({ state, commit }) {
		// Get the current view data settings
		const currentViewData = state.activeSeries.viewData
		// Get the default view data settings
		const newViewData = getDefaultViewData()
		// Apply default settings but retain the active index
		Object.keys(newViewData).forEach(vdk => {
			newViewData[vdk].active = currentViewData[vdk].active
		})
		commit(MPRKeys.SET_MPR_VIEWDATA, newViewData)
		if (state.activeSeries.onReset) {
			state.activeSeries.onReset()
		}
	},
	setThickness({ state, commit }, { index, thickness }) {
		commit(MPRKeys.SET_MPR_SLICE_THICKNESS, { index, thickness })
		if (thickness > 1 && state.activeSeries.blendMode === consts.BLEND_NONE) {
			commit(MPRKeys.SET_MPR_BLEND_MODE_ALL, consts.BLEND_MIP)
		}
	},
	invertVolume({ state }) {
		if (state.activeSeries.onInvert instanceof Function) state.activeSeries.onInvert()
	},
	nextMPRSlice({ state }) {
		if (state.activeSeries.onMoveSlice instanceof Function) state.activeSeries.onMoveSlice(1)
	},
	previousMPRSlice({ state }) {
		if (state.activeSeries.onMoveSlice instanceof Function) state.activeSeries.onMoveSlice(-1)
	},
	nextMPRActiveView({ getters, commit }) {
		let position = 1
		if (getters.mprActiveView) {
			position = getters.mprActiveView.position + 1
			if (position > 3) position = 1
		}
		commit(MPRKeys.SET_MPR_ACTIVE_POSITION, position)
	},
	prevMPRActiveView({ getters, commit }) {
		let position = 3
		if (getters.mprActiveView) {
			position = getters.mprActiveView.position - 1
			if (position < 1) position = 3
		}
		commit(MPRKeys.SET_MPR_ACTIVE_POSITION, position)
	},
	openMPRPerformanceModal({ state }) {
		showMprPerfDialog(state.issues)
	},
}
