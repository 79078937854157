import macro from 'vtk.js/Sources/macro'
import vtkCompositeMouseManipulator from 'vtk.js/Sources/Interaction/Manipulators/CompositeMouseManipulator'
import vtkCoordinate from 'vtk.js/Sources/Rendering/Core/Coordinate'

// ----------------------------------------------------------------------------
// vtkCrosshairManipulator methods
// ----------------------------------------------------------------------------

function vtkCrosshairManipulator(publicAPI, model) {
	// Set our className
	model.classHierarchy.push('vtkCrosshairManipulator')

	publicAPI.onButtonDown = (interactor, renderer, position) => {
		publicAPI.onMouseMove(interactor, renderer, position)
	}

	publicAPI.onMouseMove = (interactor, renderer, position) => {
		if (!position) {
			return
		}

		const pos = [position.x, position.y]
		const onClickCallback = publicAPI.getOnClickCallback()
		const dPos = vtkCoordinate.newInstance()
		dPos.setCoordinateSystemToDisplay()
		dPos.setValue(pos[0], pos[1], 0)
		const worldPos = dPos.getComputedWorldValue(renderer)

		if (onClickCallback && worldPos.length) {
			onClickCallback({ worldPos, displayPos: pos })
		}
	}
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
	Object.assign(model, DEFAULT_VALUES, initialValues)

	// Inheritance
	macro.obj(publicAPI, model)
	vtkCompositeMouseManipulator.extend(publicAPI, model, initialValues)

	macro.setGet(publicAPI, model, ['onClickCallback'])

	// Object specific methods
	vtkCrosshairManipulator(publicAPI, model)
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkCrosshairManipulator')

// ----------------------------------------------------------------------------

export default { newInstance, extend }
