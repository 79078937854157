<template>
	<div style="padding: 16px;">
		<em v-if="relatedReports.length <= 1">No related reports found.</em>
		<ul v-if="relatedReports.length > 1" class="unlist">
			<li
				v-for="relatedReport in relatedReports"
				:key="relatedReport.id"
				:class="{ 'is-muted': relatedReport.id === id }"
			>
				<!-- Link to related report -->
				<span v-if="relatedReport.id !== id">
					<router-link :to="getRouteForReport(relatedReport)">
						{{ relatedReport.requestDate | localizeDate }}
					</router-link>
					<span v-if="report.consultant.type !== 'Repository'" style="padding-left: 4px; font-style: italic;">
						{{ relatedReport.state }}
					</span>
				</span>
				<!-- The active report -->
				<span v-else title="This is the open report.">
					{{ relatedReport.requestDate | localizeDate }}
					<svg-icon icon="arrow-left" />
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'RelatedReports',
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	computed: {
		report() {
			return this.cell.report
		},
		id() {
			return this.cell.report.consultantReportId
		},
		consultant() {
			if (this.cell.editorMode) {
				return {}
			}
			return this.cell.report.consultant
		},
		relatedReports() {
			if (this.cell.editorMode) {
				return []
			}
			return this.cell.report.relatedReports
		},
	},
	methods: {
		getRouteForReport(report) {
			return {
				name: report.isSaleSubmission ? 'sale-submission' : 'teleconsultation',
				params: {
					id: report.id,
					keywords: this.$route.params.keywords,
				},
			}
		},
	},
}
</script>
