import uuid from 'uuid/v4'
import { round, min as findMin, max as findMax, uniq, isString } from 'lodash'
import { detect } from 'detect-browser'
import { average, standardDeviation } from '@utils/math'
import { vec3 } from 'gl-matrix'
import { formatNumber } from '@utils/numberUtils'

export default {
	inject: ['manager'],
	props: {
		id: {
			type: String,
			default: () => uuid(),
		},
		groupId: {
			type: String,
			default: () => uuid(),
		},
		color: {
			type: String,
			default: '#14C6DF',
		},
		dragColor: {
			type: String,
			default: '#92D32E',
		},
		rotation: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			points: {},
			worldCoords: {},
			handleRadii: {},
			drag: {},
			stats: null,
			statsVisible: true,
			statTemplate: 'Area: {area}',
			origRotation: this.rotation,
			// This gets set by the manager when any annotations in the same group
			// as this one are hovered
			hovered: false,
			translating: false,
		}
	},
	computed: {
		effectiveRotation() {
			return this.rotation - this.origRotation
		},
		effectiveRotationRads() {
			return (this.effectiveRotation * Math.PI) / 180
		},
		active() {
			const annotation = this.manager.activeAnnotation
			return !!annotation && annotation.groupId === this.groupId
		},
		hasPoints() {
			return this.pointsList && this.pointsList.length > 0
		},
		pointerPosition() {
			return this.manager.pointerPosition
		},
		pointsList() {
			return Object.values(this.points).filter(p => !!(p && p.x && p.y))
		},
		pointsListNorm() {
			return this.pointsList.map(p => this.rotatePoint(p, -this.effectiveRotationRads))
		},
		minX() {
			if (!this.hasPoints) return NaN
			return Math.min(...this.pointsList.map(p => p.x))
		},
		minXNorm() {
			if (!this.hasPoints) return NaN
			return Math.min(...this.pointsListNorm.map(p => p.x))
		},
		maxX() {
			if (!this.hasPoints) return NaN
			return Math.max(...this.pointsList.map(p => p.x))
		},
		maxXNorm() {
			if (!this.hasPoints) return NaN
			return Math.max(...this.pointsListNorm.map(p => p.x))
		},
		minY() {
			if (!this.hasPoints) return NaN
			return Math.min(...this.pointsList.map(p => p.y))
		},
		minYNorm() {
			if (!this.hasPoints) return NaN
			return Math.min(...this.pointsListNorm.map(p => p.y))
		},
		maxY() {
			if (!this.hasPoints) return NaN
			return Math.max(...this.pointsList.map(p => p.y))
		},
		maxYNorm() {
			if (!this.hasPoints) return NaN
			return Math.max(...this.pointsListNorm.map(p => p.y))
		},
		topLeft() {
			if ([this.minX, this.minY].some(isNaN)) return null
			return { x: this.minX, y: this.minY }
		},
		topRight() {
			if ([this.maxX, this.minY].some(isNaN)) return null
			return { x: this.maxX, y: this.minY }
		},
		bottomLeft() {
			if ([this.minX, this.maxY].some(isNaN)) return null
			return { x: this.minX, y: this.maxY }
		},
		bottomRight() {
			if ([this.maxX, this.maxY].some(isNaN)) return null
			return { x: this.maxX, y: this.maxY }
		},
		center() {
			if ([this.minX, this.maxX, this.minY, this.maxY].some(isNaN)) {
				return null
			}
			return {
				x: (this.minX + this.maxX) / 2,
				y: (this.minY + this.maxY) / 2,
			}
		},
		centerNorm() {
			if ([this.minXNorm, this.maxXNorm, this.minYNorm, this.maxYNorm].some(isNaN)) {
				return null
			}
			return {
				x: (this.minXNorm + this.maxXNorm) / 2,
				y: (this.minYNorm + this.maxYNorm) / 2,
			}
		},
		worldCenter() {
			return this.displayToWorld(this.center)
		},
		width() {
			return this.maxX - this.minX
		},
		widthNorm() {
			return this.maxXNorm - this.minXNorm
		},
		height() {
			return this.maxY - this.minY
		},
		heightNorm() {
			return this.maxYNorm - this.minYNorm
		},
		hasSize() {
			// Check for valid display width and height
			const tolerance = 0.5
			return this.widthNorm > tolerance || this.heightNorm > tolerance
		},
		radiusX() {
			return this.widthNorm / 2
		},
		worldRadiusX() {
			return this.worldDistance(this.radiusX)
		},
		radiusY() {
			return this.heightNorm / 2
		},
		worldRadiusY() {
			return this.worldDistance(this.radiusY)
		},
		radius() {
			return Math.min(this.radiusX, this.radiusY)
		},
		worldRadius() {
			return this.worldDistance(this.radius)
		},
		diameter() {
			return Math.min(this.widthNorm, this.heightNorm)
		},
		worldDiameter() {
			return this.worldDistance(this.diameter)
		},
		position: {
			get() {
				return this.topLeft
			},
			set(val) {
				const oldVal = this.topLeft
				if (!val || !oldVal || (val.x === oldVal.x && val.y === oldVal.y)) {
					return
				}
				this.translating = true
				// Offset all points by the position delta
				const deltaX = val.x - oldVal.x
				const deltaY = val.y - oldVal.y
				Object.entries(this.points).forEach(([key, value]) => {
					if (value && value.x && value.y) {
						this.points[key] = { x: value.x + deltaX, y: value.y + deltaY }
					}
				})
				this.$emit('translate', { deltaX, deltaY })
				this.$nextTick(() => {
					this.translating = false
				})
			},
		},
		handleOffsets() {
			const offsets = {}
			Object.entries(this.points).forEach(([key, point]) => {
				const offset = { ...point }
				const radius = this.handleRadii[key]
				if (radius) {
					offset.x += Number.parseInt(radius.substring(0, radius.length - 2))
				}
				offsets[key] = offset
			})
			return offsets
		},
		// Mixin consumers should return their own area
		area() {
			return 0
		},
		conditionalStatTemplate() {
			const { statsDisabled } = this.manager
			if (statsDisabled) {
				return this.statTemplate + (isString(statsDisabled) ? `\n\n${statsDisabled}` : '')
			}
			return (
				this.statTemplate +
				`\nMean: {mean}${this.unit}\nStd Dev: {stdDev}${this.unit}\nMin: {min}${this.unit}, Max: {max}${this.unit}`
			)
		},
		unit() {
			return !this.draggingAny && this.stats ? this.manager.modalityUnit : ''
		},
		statText() {
			let stats = {
				area: `${formatNumber(round(this.area, 2))} mm²`,
				diameter: `${formatNumber(round(this.worldDiameter, 2))} mm`,
				diameterX: `${formatNumber(round(this.worldDistance(this.widthNorm), 2))} mm`,
				diameterY: `${formatNumber(round(this.worldDistance(this.heightNorm), 2))} mm`,
				mean: '...',
				stdDev: '...',
				min: '...',
				max: '...',
			}
			if (!this.draggingAny && this.stats) {
				stats = {
					...stats,
					...this.stats,
				}
			}
			// Replace placeholders in the stat template with values
			return Object.entries(stats).reduce(
				(statText, [key, value]) => statText.replace(`{${key}}`, value),
				this.conditionalStatTemplate
			)
		},
		draggingAll: {
			get() {
				return Object.values(this.drag).every(d => d)
			},
			set(val) {
				Object.keys(this.drag).forEach(key => {
					this.drag[key] = val
				})
			},
		},
		draggingAny() {
			return Object.values(this.drag).some(d => d)
		},
		groupStyle() {
			return {
				opacity: this.hasSize ? (this.draggingAny ? 0.8 : 1) : 0,
			}
		},
		activeColor() {
			return this.hovered ? this.dragColor : this.color
		},
		filterStyle() {
			// Microsoft Edge (Windows only) doesn't support url function within filters
			// Would have liked to be able to test this more specifically, but every other browser supports it except Edge and IE11
			// NOTE: New Edge Beta actually supports it, and it reports 'chrome' as the browser name
			const browser = detect()
			return browser.name !== 'edge' ? 'url(#shadow)' : ''
		},
		strokeStyle() {
			return {
				cursor: 'pointer',
				stroke: this.activeColor,
				strokeWidth: '1.5px',
				strokeOpacity: 0.9,
				fillOpacity: 0,
				filter: this.filterStyle,
				'stroke-dasharray': this.draggingAny ? '5 3' : '',
				transition: 'stroke 0.08s ease-in-out',
			}
		},
		strokeHitTargetStyle() {
			return {
				cursor: 'pointer',
				fill: 'transparent',
				stroke: 'transparent',
				strokeWidth: this.isMobileOS ? '24px' : '16px',
				pointerEvents: 'visibleStroke',
				zIndex: -10,
			}
		},
		fillStyle() {
			return {
				cursor: 'pointer',
				stroke: this.activeColor,
				strokeOpacity: 0.9,
				fill: this.activeColor,
				fillOpacity: 0.9,
				filter: this.filterStyle,
				transition: 'stroke 0.08s ease-in-out',
			}
		},
		fillHitTargetStyle() {
			return {
				cursor: 'pointer',
				fill: 'transparent',
				stroke: 'transparent',
				pointerEvents: 'visible',
				zIndex: -10,
			}
		},
		textStyle() {
			return {
				fontFamily:
					'system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif',
				fontSize: '14px',
				fontWeight: '400',
				stroke: 'transparent',
				strokeOpacity: 0.9,
				fill: this.color,
				fillOpacity: 0.9,
				userSelect: 'none',
				filter: this.filterStyle,
				transition: 'stroke 0.08s ease-in-out',
			}
		},
		textSource() {
			return this
		},
	},
	watch: {
		points: {
			deep: true,
			handler() {
				if (!this.translating) {
					this.$emit('resize', this.points)
				}
			},
		},
		draggingAny(dragging) {
			this.$emit('dragging-any', this, dragging)
			this.refreshStats()
		},
		draggingAll(dragging) {
			this.$emit('dragging-all', this, dragging)
		},
		handleOffsets: {
			deep: true,
			handler() {
				this.$emit('resize', this.points)
			},
		},
	},
	created() {
		// Update world coordinates when display coordinates have been updated
		Object.keys(this.points || {}).forEach(key => {
			this.$watch(`points.${key}`, () => this.refreshCoords(false, key), { deep: true })
			this.$watch(`worldCoords.${key}`, () => this.refreshCoords(true, key), { deep: true })
		})
	},
	mounted() {
		if (this.manager) {
			this.manager.addAnnotationToGroup(this, this.groupId)
			this.$once('hook:beforeDestroy', () => {
				this.manager.removeAnnotationFromGroup(this, this.groupId)
			})
		}
		this.initDragState()
	},
	methods: {
		// This will get called by the annotation manager
		// and should be implemented by mixin consumer
		onInteractionBegin() {},
		// This will get called by the annotation manager
		// and should be implemented by mixin consumer
		onInteractionUpdate() {},
		// This will get called by the annotation manager
		// and should be implemented by mixin consumer
		onInteractionEnd() {},
		// This will get called by the annotation manager
		onViewportModified() {
			this.refreshCoords()
		},
		refreshCoords(fromWorldToDisplay = true, forKey) {
			// Use `refreshing` state variable to prevent repetitive update loops
			if (fromWorldToDisplay && (!this.refreshing || this.refreshing === 'display')) {
				this.refreshing = 'display'
				Object.entries(this.worldCoords).forEach(([key, value]) => {
					if (!forKey || forKey === key) {
						this.points[key] = this.worldToDisplay(value)
					}
				})
			} else if (!fromWorldToDisplay && (!this.refreshing || this.refreshing === 'world')) {
				this.refreshing = 'world'
				Object.entries(this.points).forEach(([key, value]) => {
					if (!forKey || forKey === key) {
						this.worldCoords[key] = this.displayToWorld(value)
					}
				})
			}
			this.$nextTick(() => (this.refreshing = ''))
		},
		displayToWorld(...args) {
			return this.manager.displayToWorld(...args)
		},
		worldToDisplay(...args) {
			return this.manager.worldToDisplay(...args)
		},
		displayDistanceBetween(...args) {
			return this.manager.displayDistanceBetween(...args)
		},
		worldDistanceBetween(...args) {
			return this.manager.worldDistanceBetween(...args)
		},
		worldDistance(...args) {
			return this.manager.worldDistance(...args)
		},
		setHandleRadius(handle, radius) {
			// Use `$set` to trigger reactivity
			this.$set(this.handleRadii, handle, radius)
		},
		worldDistanceToDisplay(...args) {
			return this.manager.worldDistanceToDisplay(...args)
		},
		round(n, digits) {
			return round(n, digits)
		},
		getCollinearPoint(p1, p2, distance) {
			if (!p1 || !p2) return null
			const pDistance = this.displayDistanceBetween(p1, p2)
			if (distance === 0 || pDistance === 0) {
				return { ...p1 }
			}
			// Build unit vectors
			const { x: x1, y: y1 } = p1
			const { x: x2, y: y2 } = p2
			const xu = (x2 - x1) / pDistance
			const yu = (y2 - y1) / pDistance
			// Use unit vectors to find collinear point
			return { x: x1 + distance * xu, y: y1 + distance * yu }
		},
		getCornerPoints(points) {
			if (!points) return null
			const result = {}
			points.forEach(p => {
				if (!result.left || p.x < result.left.x) {
					result.left = p
				}
				if (!result.top || p.y < result.top.y) {
					result.top = p
				}
				if (!result.right || p.x > result.right.x) {
					result.right = p
				}
				if (!result.bottom || p.y > result.bottom.y) {
					result.bottom = p
				}
			})
			return result
		},
		rotatePoint({ x, y }, rads = this.effectiveRotationRads) {
			return {
				x: x * Math.cos(rads) - y * Math.sin(rads),
				y: x * Math.sin(rads) + y * Math.cos(rads),
			}
		},
		initDragState() {
			this.drag = Object.keys(this.points).reduce((prev, curr) => {
				prev[curr] = false
				return prev
			}, {})
		},
		// Throttle this computationally expensive function
		refreshStats() {
			if (!this.manager.statsDisabled && this.area && !this.draggingAny) {
				this.stats = this.getVoxelStats()
			}
		},
		getVoxelStats(fnInsideBounds = this.pointInsideBounds) {
			let mean = 0
			let min = 0
			let max = 0
			let stdDev = 0
			// Check for valid bounds
			if (this.area) {
				const voxelValues = this.getVoxelValuesInBounds(fnInsideBounds)
				mean = average(voxelValues)
				min = findMin(voxelValues)
				max = findMax(voxelValues)
				stdDev = standardDeviation(voxelValues)
			}
			return {
				mean: formatNumber(round(mean, 2)),
				min: formatNumber(round(min, 2)),
				max: formatNumber(round(max, 2)),
				stdDev: formatNumber(round(stdDev, 2)),
			}
		},
		getTextAnchorData() {
			return {
				points: Object.values(this.points),
				anchorAlign: 'right',
			}
		},
		pointBetween(p1, p2) {
			return {
				x: (p1.x + p2.x) / 2.0,
				y: (p1.y + p2.y) / 2.0,
			}
		},
		pointAligned(points, align) {
			let alignPoint = null
			const alignTop = align.includes('top')
			const alignBottom = align.includes('bottom')
			const alignLeft = align.includes('left')
			const alignRight = align.includes('right')
			const hasVerticalAlign = alignTop || alignBottom
			const hasHorizontalAlign = alignLeft || alignRight
			points.forEach(({ x, y }) => {
				if (!alignPoint) {
					alignPoint = { x, y }
				} else if ((alignRight && x > alignPoint.x) || (alignLeft && x < alignPoint.x)) {
					alignPoint.x = x
					if (!hasVerticalAlign) {
						alignPoint.y = y
					}
				} else if ((alignTop && y < alignPoint.y) || (alignBottom && y > alignPoint.y)) {
					alignPoint.y = y
					if (!hasHorizontalAlign) {
						alignPoint.x = x
					}
				}
			})
			return alignPoint
		},
		pointInsideBounds(width, height, x, y) {
			// Override this function if the annotation has a non-rectangular shape
			// The function is passed the width and height of a generic 2dArray, and the row:x column:y of a cell in that array.
			// Return true if that cell should be included.
			return true
		},
		// Returns the coordinates of the horizontal and vertical bounds
		// WARNING: This implementation will work for shapes that are symmetric when rotated.
		// For shapes that need to account for rotations, this function needs to be overridden
		getDisplayBoundsVectors() {
			return {
				origin: {
					x: this.minX,
					y: this.minY,
				},
				horizontal: {
					x: this.maxX,
					y: this.minY,
				},
				vertical: {
					x: this.minX,
					y: this.maxY,
				},
			}
		},
		// Returns an array of voxel values, that are found between minX/Y, maxX/Y,
		// using fnInsideBounds to filter by shape (circle, ellipse, etc)
		// NOTE: there is the potential to miss a voxel row on the max bounds, between the last *Step and *Dist, but should be ok in the grand scheme of things
		getVoxelValuesInBounds(fnInsideBounds = () => true) {
			// Get the coordinates of the horizontal and vertical bounds
			const { origin, horizontal, vertical } = this.getDisplayBoundsVectors()
			const { x: x1, y: y1, z: z1 } = this.manager.displayToWorld(origin)
			const { x: x2, y: y2, z: z2 } = this.manager.displayToWorld(horizontal)
			const { x: x3, y: y3, z: z3 } = this.manager.displayToWorld(vertical)

			// Build the length vectors for the horizontal and vertical bounds
			const start = vec3.fromValues(x1, y1, z1)
			const horizontalVec = vec3.subtract([], [x2, y2, z2], start)
			const verticalVec = vec3.subtract([], [x3, y3, z3], start)

			const hDist = Math.sqrt(vec3.sqrLen(horizontalVec))
			const vDist = Math.sqrt(vec3.sqrLen(verticalVec))

			const hStep = vec3.normalize([], horizontalVec)
			const vStep = vec3.normalize([], verticalVec)

			const worldGrid = []

			for (let i = 0; i < hDist; i++) {
				for (let j = 0; j < vDist; j++) {
					// NOTE: This is where we would eventually do thickness filtering,
					// using camera vector & thickness as zStep and zDist to get another array, and use the blend mode to give the result for that pixel
					if (fnInsideBounds(hDist, vDist, i, j)) {
						const pt = vec3.copy([], start)
						vec3.add(pt, pt, vec3.scale([], hStep, i))
						vec3.add(pt, pt, vec3.scale([], vStep, j))
						const index = this.manager.getVoxelIndexFromWorldCoord(pt)
						worldGrid.push(index)

						// on the first loop, check the max horizontal values
						if (i === 0 && fnInsideBounds(hDist, vDist, hDist, j)) {
							const pt = vec3.copy([], start)
							vec3.add(pt, pt, vec3.scale([], hStep, i))
							vec3.add(pt, pt, vec3.scale([], vStep, vDist))
							const index = this.manager.getVoxelIndexFromWorldCoord(pt)
							worldGrid.push(index)
						}
					}
					// for each horizontal loop, check the most vertical point as well.
					if (fnInsideBounds(hDist, vDist, i, vDist)) {
						const pt = vec3.copy([], start)
						vec3.add(pt, pt, vec3.scale([], hStep, i))
						vec3.add(pt, pt, vec3.scale([], vStep, vDist))
						const index = this.manager.getVoxelIndexFromWorldCoord(pt)
						worldGrid.push(index)
					}
				}
			}
			// bottom-right corner, check the max values
			if (fnInsideBounds(hDist, vDist, hDist, vDist)) {
				const pt = vec3.copy([], start)
				vec3.add(pt, pt, vec3.scale([], hStep, hDist))
				vec3.add(pt, pt, vec3.scale([], vStep, vDist))
				const index = this.manager.getVoxelIndexFromWorldCoord(pt)
				worldGrid.push(index)
			}

			// Deduplicate indexes before returning the mapped voxel value array
			return uniq(worldGrid)
				.map(voxelIndex => this.manager.getVoxelValueFromIndex(voxelIndex))
				.filter(v => !Number.isNaN(v))
		},
	},
}
