export default function(imageFrame) {
  let rgbaIndex = 0
  const newPixelData = new Uint8Array(imageFrame.pixelData.length * 4 / 3)

  for (let i = 0; i < imageFrame.pixelData.length; i += 3) {
    newPixelData[rgbaIndex++] = imageFrame.pixelData[i] // red
    newPixelData[rgbaIndex++] = imageFrame.pixelData[i + 1] // green
    newPixelData[rgbaIndex++] = imageFrame.pixelData[i + 2] // blue
    newPixelData[rgbaIndex++] = 255 // alpha
  }
  imageFrame.pixelData = newPixelData
}
