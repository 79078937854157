var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container",attrs:{"data-cy":"data-table"}},[_c('table',{staticClass:"data-table",class:{
			'table--striped': _vm.isStriped,
			'table--striped-report': _vm.isReport,
			'table--row-hover': _vm.isHoverable,
			'table--condensed': _vm.isCondensed,
			'table--full-width': _vm.isFullWidth,
		},style:(_vm.isLoading && { opacity: '0.3', transition: 'opacity 0.1s 0.1s' })},[_vm._t("default",[_c('thead',[_c('tr',{class:{
						asc: _vm.sort.isAscending && _vm.sort.isSorted,
						desc: !_vm.sort.isAscending && _vm.sort.isSorted,
					}},_vm._l((_vm.columns),function(column,i){return _c('th',{key:i,class:{
							active: _vm.sort.orderBy === column.sortName,
							sortable: column.isSortable,
						},on:{"click":function($event){$event.preventDefault();return _vm.sortSelected(column)}}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(column.name)+"\n\t\t\t\t\t")])}),0)]),_c('tbody',[_vm._l((_vm.rows),function(row,rowIndex){return [_c('tr',{key:("row-" + rowIndex),class:[
							_vm.expandedRow === row ? 'selected' : '',
							_vm.isExpanding && _vm.expandedRow === row ? 'expanding' : '',
							row.wasRemoved ? 'removed' : '',
							row.rowClass ? row.rowClass : '' ],on:{"click":function($event){$event.preventDefault();return _vm.onRowClick(row)}}},[_vm._t("row",null,{"row":row})],2),_c('transition',{key:("expanded-" + rowIndex),attrs:{"name":"expand"}},[(_vm.$slots.expanded && _vm.expandedRow === row)?_c('tr',{ref:"expandedRow",refInFor:true,staticClass:"expanded"},[_c('td',{attrs:{"colspan":_vm.columns.length}},[_vm._t("expanded")],2)]):_vm._e()]),(_vm.$slots.expanded && _vm.expandedRow === row)?_c('tr',{key:("striping-fix-" + rowIndex),staticClass:"striping-fix"},[_c('td',{attrs:{"colspan":_vm.columns.length}})]):_vm._e()]})],2)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }