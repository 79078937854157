





















import AstInput from '@components/Input.vue'
import ModalDialog from "@components/ModalDialog.vue";
import {dlg} from "@utils/dlgUtils";
import { openAttachmentDlg } from "@dialogs/attachmentDlg.vue";
import {mapMutations} from "vuex";
import api from '@services/api'

const RequiredFieldsDialog = {
  name: 'RequiredFieldsDialog',
  components: {
    ModalDialog,
    AstInput,
  },
  props: {
    fileObject: Object,
    isRepository: Boolean
  },
  data(){
    return {
      endoscopyDisclosure: '',
      hasError: false,
      maxlength: '200', // 200 characters
    }
  },
  methods: {
    ...mapMutations(['setEndoscopyDisclosure']),
    closeModal() {
      dlg.close(this)
    },
    submit() {
      if (this.endoscopyDisclosure.length) {
        this.hasError = false
        dlg.close(this, true, false, false)
        this.setEndoscopyDisclosure(this.endoscopyDisclosure)
        openAttachmentDlg(this.fileObject, this.isRepository, !this.isRepository)
      } else {
        this.hasError = true
      }
    }
  },
  computed: {
    actions() {
      return [
        {
          type: 'button',
          name: 'Submit',
          emit: 'submit',
        },
      ]
    },
  }
}

export default RequiredFieldsDialog

export async function openRequiredFieldsDlg( fileObject: IViewerAttachment, isRepository: boolean ) {
  const result = await api.viewer.getConsignerStudyViewingLog(fileObject.imageId)
  const imageId = result.data
  if (imageId === '00000000-0000-0000-0000-000000000000' && isRepository) {
    dlg.open(RequiredFieldsDialog, {fileObject, isRepository}, false)
  } else {
    openAttachmentDlg(fileObject, isRepository, !isRepository)
  }
}
