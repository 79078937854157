export default function(imageFrame) {
  const pixels = imageFrame.pixelData.length / 3
  let rgbaIndex = 0
  let rIndex = 0
  let gIndex = pixels
  let bIndex = pixels * 2
  const newPixelData = new Uint8Array(pixels * 4)

  for (let i = 0; i < pixels; i++) {
    newPixelData[rgbaIndex++] = imageFrame.pixelData[rIndex++] // red
    newPixelData[rgbaIndex++] = imageFrame.pixelData[gIndex++] // green
    newPixelData[rgbaIndex++] = imageFrame.pixelData[bIndex++] // blue
    newPixelData[rgbaIndex++] = 255 // alpha
  }

  imageFrame.pixelData = newPixelData
}
