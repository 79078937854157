import printImage from '@utils/printImage'

export default async function printMprImageAsync({ getters, dispatch }) {
	// Get component for the active view
	const activeComp = getters.mprActiveView.component

	// Render the viewer to a base64 image
	const imgBase64 = await activeComp.renderImage()

	// Copy image to clipboard (with notifications)
	printImage(imgBase64, dispatch)
}
