





























































import AstButton from '@components/Button.vue'
import AstCheckbox from '@components/Checkbox.vue'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import TeleconsultationThumbnails from '@components/TeleconsultationThumbnails.vue'
import WysiwygEditorTiny from "@components/WysiwygEditorTiny.vue"

import { dlg } from '@utils/dlgUtils'
import { openStudiesDlg } from '@dialogs/StudiesDlg.vue'
import { openUserStudiesDlg } from '@dialogs/UserStudiesDlg.vue'
import { openUploadDlg } from '@dialogs/UploadDlg.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import consultations from '@services/consultationService'


const AddendumDlg = {
	name: 'AddendumDlg',
	components: {
    AstButton,
		AstCheckbox,
		DlgHeader,
		ModalWrapper,
		TeleconsultationThumbnails,
    WysiwygEditorTiny,
	},
	props: {
		isStandardReport: {
			type: Boolean,
			required: true,
		},
		isReportComplete: {
			type: Boolean,
			required: true,
		},
		reportId: {
			type: String,
			required: true,
		},
		studies: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isAddendum: false, // default to "unofficial" message
			addendumTextValue: '',
			addendumHtmlValue: '',
			dropTarget: undefined,
			isSaving: false,
			isLoading: true,
			seriesToExclude: [],
			attachments: [],
			thumbnails: [],
			newThumbnails: [],
			newAttachments: [],
		}
	},
	computed: {
		isAdvancedReportsEnabled() {
			return this.$store.state.auth.claims.isAdvancedReportsEnabled
		},
		canSubmit() {
			if (this.isSaving || this.isLoading || this.isUploading) return false
			return !!this.addendumTextValue.trim()
		},
		newImagesPrompt() {
			if (!this.newAttachments.length && !this.newThumbnails.length) return ''
			let text = 'Add '
			let newItems = []
			if (this.newThumbnails.length) {
				newItems.push(this.newThumbnails.length + ' new ' + (this.newThumbnails.length > 1 ? 'images ' : 'image '))
			}
			if (this.newAttachments.length) {
				newItems.push(
					this.newAttachments.length + ' new ' + (this.newAttachments.length > 1 ? 'attachments ' : 'attachment ')
				)
			}
			text += newItems.join(' and ')
			text += 'from the source '
			text += this.studies.length > 1 ? 'studies' : 'study'
			return text
		},
	},
	mounted() {
		this.isAddendum = !this.isAdvancedReportsEnabled
		this.dropTarget = this.$refs.dropTarget
		this.checkStudiesForNewImages()
	},
	methods: {
		async checkStudiesForNewImages() {
			this.isLoading = true
			try {
				const studyIds = this.studies.map(study => study.studyId)
				const { studies } = await this.$api.viewer.getStudy({ ids: studyIds }, false)
				this.parseForNewImages(studies)
			} finally {
				this.isLoading = false
			}
		},
		parseForNewImages(newStudies) {
			newStudies.forEach(newStudy => {
				const oldStudy = this.studies.find(study => study.studyId === newStudy.studyId)
				const isNew = key => item => !oldStudy.imageData[key].some(({ seriesId }) => seriesId === item.seriesId)
				const newAttachments = newStudy.imageData.attachments.filter(isNew('attachments'))
				const newThumbnails = newStudy.imageData.thumbnails.filter(isNew('thumbnails'))
				const addStudyId = series => ({ ...series, studyId: newStudy.studyId })
				this.newAttachments = [...this.newAttachments, ...newAttachments.map(addStudyId)]
				this.newThumbnails = [...this.newThumbnails, ...newThumbnails.map(addStudyId)]
			})
		},
		addNewImages() {
			if (!this.isAddendum) return
			this.addAttachments(this.newAttachments)
			this.addThumbnails(this.newThumbnails)
			this.newAttachments = []
			this.newThumbnails = []
		},
		async close() {
			if (this.addendumTextValue.trim() || this.attachments.length || this.thumbnails.length) {
				if (!(await showConfirm('Are you sure you want to cancel this addendum?'))) return
			}
			dlg.close(this, true, false)
		},
		updateAddendumTextValue(htmlValue, textValue) {
			this.addendumTextValue = textValue
      this.addendumHtmlValue = htmlValue
		},
		async selectExistingStudy() {
			if (!this.isAddendum) return
			const openDialog = this.$store.getters.isCommunityUser ? openUserStudiesDlg : openStudiesDlg
			const study = await openDialog()
			if (!study) return
			this.addStudies([study.studyId])
		},
		async uploadNewStudy() {
			if (!this.isAddendum) return
			const studyIds = await openUploadDlg({
				context: 'addendum',
				showImageUpload: true,
				showAttachmentUpload: true,
				showViewButton: false,
				cancelOnClose: true,
				title: 'Upload new images or attachments.',
			})
			this.addStudies(studyIds)
		},
		async addStudies(studyIds) {
			if (!studyIds || !studyIds.length) return
			this.isLoading = true
			try {
				const { studies } = await this.$api.viewer.getStudy({ ids: studyIds }, false)
				// attach studyId to each thumbnail/attachment
				const addStudyId = ({ studyId }) => series => ({ ...series, studyId })
				const attachments = studies.map(study => study.imageData.attachments.map(addStudyId(study)))
				const thumbnails = studies.map(study => study.imageData.thumbnails.map(addStudyId(study)))
				this.addAttachments([].concat(...attachments))
				this.addThumbnails([].concat(...thumbnails))
			} finally {
				this.isLoading = false
			}
		},
		addAttachments(attachments) {
			const newAttachments = attachments.filter(thumbnail => {
				return !this.attachments.some(existing => existing.seriesId === thumbnail.seriesId)
			})
			this.attachments = this.attachments.concat(newAttachments)
		},
		addThumbnails(thumbnails) {
			const newThumbnails = thumbnails.filter(thumbnail => {
				return !this.thumbnails.some(existing => existing.seriesId === thumbnail.seriesId)
			})
			this.thumbnails = this.thumbnails.concat(newThumbnails)
		},
		async submit() {
			if (this.isBusy) return
			this.isSaving = true

			try {
				// determine which thumbnails are fake ids
				// add them to the excluded image ids list
				let fakeSeries = {}
				this.thumbnails.forEach(t => {
					if (t.isFakeSeries) fakeSeries[t.seriesId] = true
				})

				let excludedSeriesIds = this.seriesToExclude.filter(s => fakeSeries[s] === undefined)
				let excludedImagesIds = this.seriesToExclude.filter(s => fakeSeries[s] !== undefined)
				let studySet = new Set([...this.thumbnails, ...this.attachments].map(i => i.studyId))
				let studyIds = Array.from(studySet)

				await consultations.addReportMessage({
					reportId: this.reportId,
					htmlValue: this.addendumHtmlValue,
					textValue: this.addendumTextValue,
					isAddendum: this.isAddendum,
					studyIds,
					excludedSeriesIds,
					excludedImagesIds,
				})
				dlg.close(this, true, true)
			} finally {
				this.isSaving = false
			}
		},
	},
}
export default AddendumDlg

export function openAddendumDlg(reportId, studies, isStandardReport = false, isReportComplete) {
	return dlg.open(AddendumDlg, { reportId, studies, isStandardReport, isReportComplete })
}
