<template>
	<svg width="0" height="0" style="display: none;" v-html="$options.svgSpriteMap" />
</template>

<script>
const svgContext = require.context(
	'!svg-inline-loader?removeTags=true&removeSVGTagAttrs=true&removingTagAttrs=fill!@assets/icons',
	true,
	/\w+\.svg$/i
)

const symbols = Object.fromEntries(
	svgContext.keys().map(svgIcon => {
		const svg = svgContext(svgIcon)
		const id = svgIcon.replace(/^\.\/(.*)\.\w+$/, '$1')
		return [id, svg.replace('<svg', `<symbol id="${id}"`).replace('svg>', 'symbol>')]
	})
)
const svgSpriteMap = Object.values(symbols).join('\n')

// Export a dictionary of icon keys to use instead of icon strings, for eventual IDE use
// convert keys to camel instead of dash
// `ICONS.caretUp` vs `ICONS['caret-up']`
export const ICONS = Object.fromEntries(Object.keys(symbols).map(id => [dashToCamel(id), id]))

export default {
	name: 'SvgSpriteMap',
	svgSpriteMap,
}

function dashToCamel(string) {
	return string
		.split('-')
		.map((s, idx) => (idx > 0 ? s[0].toUpperCase() + s.slice(1) : s))
		.join('')
}
</script>
