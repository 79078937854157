import Vue from 'vue'

Vue.directive('scroll-nav', {
	bind(el, binding) {
		// For conditional binds
		if (!binding.value) {
			return
		}
		el.onTableScroll = e => {
			// Raise custom event that navbar can listen to
			const event = new CustomEvent('tablescroll')
			event.srcEvent = e
			document.dispatchEvent(event)
		}
		// Add event listener
		el.addEventListener('scroll', el.onTableScroll)
	},
	unbind(el) {
		// Clean up
		if (el.onTableScroll) el.removeEventListener('scroll', el.onTableScroll)
	},
})
