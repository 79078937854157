import Vue from 'vue'
import omniDesktop from '@/electron/omniDesktop'
import BrowserImageCache from './browserImageCache'
import DesktopImageCache from './desktopImageCache'

export interface ImageData {
	clinicCode: string
	studyId: string
	studyUid: string
	seriesId: string
	seriesUid: string
	scheme: string
	imageId: string
	instanceUid: string
	frameIndex: string
}

export type ImageLoader = (imageData: ImageData) => Promise<ArrayBuffer>

interface ImageCache {
	size: number
	allocatedSize: number
	availableSize: number
	isActive: boolean
	isPrecacheActive: boolean
	clear: () => Promise<any>
	// First tries to find the image in the cache. If it isn't found, it will fetch the
	// image using the provided imageLoader and save the image to the cache
	loadImage: (imageData: ImageData, imageLoader: ImageLoader) => Promise<ArrayBuffer>
	// Internal - shouldn't need to be externally called
	getImage: (imageData: ImageData) => Promise<ArrayBuffer | null>
	setImage: (imageData: ImageData, image: ArrayBuffer) => Promise<boolean>
}

let imageCache: ImageCache
if (omniDesktop.isConnected) {
	imageCache = new DesktopImageCache()
} else {
	imageCache = new BrowserImageCache()
}

export default Vue.observable(imageCache)
