import base64toBlob from '@utils/base64toBlob'
import { OpenCopyImageDlg } from '@dialogs/CopyImageDlg.vue'

export default async function copyImageToClipboard(imgBase64, dispatch) {
	try {
		// Method 1:
		// Clipboard API will eventually be our savior, but it only works in Chrome 76 and later
		// Reference https://caniuse.com/#feat=mdn-api_clipboard_write for support details
		if (navigator.clipboard && navigator.clipboard.write) {
			const blob = base64toBlob(imgBase64, 'image/png')
			await navigator.clipboard.write([
				/* eslint-disable-next-line no-undef */
				new ClipboardItem({
					[blob.type]: blob,
				}),
			])
			dispatch('addNotification', {
				message: 'Image copied to clipboard!',
				notificationType: 'success',
			})
			return
		}
	} catch {}

	OpenCopyImageDlg(imgBase64)
}
