import axios from 'axios'
import qs from 'qs'
import { findJsonUrl } from '../utils/urlUtility'
import cancelPending from './interceptors/cancelPending'
import handleErrors from './interceptors/handleErrors'

let APP = window.config.applicationRootUrl
let RESTAPP = window.config.restApplicationRootUrl
let ANNEX_DNS = `${window.config.keystoneRootUrl}/${window.config.keystoneBaseRoute}`

export const Annex = axios.create({ baseURL: ANNEX_DNS, headers: { common: {} } })
cancelPending.install(Annex)
handleErrors.install(Annex)

export const API = axios.create({ baseURL: APP, headers: { common: {} } })
cancelPending.install(API)
handleErrors.install(API)

export const RESTAPI = axios.create({ baseURL: RESTAPP, headers: { common: {} } })
cancelPending.install(RESTAPI)
handleErrors.install(RESTAPI)

const setApiBaseUrl = (baseUrl, annexDns) => {
	if (window.config.environmentName === 'Development') return // keep localhost for local development

	if (baseUrl) {
		if (baseUrl.substring(0, 4) !== 'http') baseUrl = 'https://' + baseUrl
		API.defaults.baseURL = baseUrl
		APP = baseUrl
	}

	if (annexDns) {
		Annex.defaults.baseURL = annexDns
		ANNEX_DNS = annexDns
	}
}

// add this config to individual requests that do not accept Authorization header
const noAuthConfig = {
	transformRequest: [
		(data, headers) => {
			delete headers.common['Authorization']
			return data
		},
	],
}

// serialize date object as ISO 8601 date string with local time zone offset (not UTC)
// e.g. 2019-07-02T13:33:43-04:00
export const serializeDate = (date, showOffset = true) => {
	if (!date) return ''
	if (typeof date === 'string') {
		date = new Date(date)
	}

	const offset = -date.getTimezoneOffset()
	const sign = offset >= 0 ? '+' : '-'
	const toTwoDigits = num => ('0' + Math.abs(num)).slice(-2)
	// prettier-ignore
	let result = date.getFullYear() +
    '-' + toTwoDigits(date.getMonth() + 1) +
    '-' + toTwoDigits(date.getDate()) +
    'T' + toTwoDigits(date.getHours()) +
    ':' + toTwoDigits(date.getMinutes()) +
		':' + toTwoDigits(date.getSeconds())
	if (showOffset) {
		result += sign + toTwoDigits(offset / 60) + ':' + toTwoDigits(offset % 60)
	}
	return result
}

export const paramsSerializer = params => qs.stringify(params, { arrayFormat: 'indices', serializeDate })

const auth = {
	logOut: () => {
		let token = API.defaults.headers['Authorization']

		fetch(`${APP}/api/logout`, {
			method: 'GET',
			keepalive: true,
			headers: {
				Authorization: token,
			},
		})
	},
	logIn: data => {
		data.timeZoneOffset = new Date().getTimezoneOffset()
		return API.post(`/api/token`, data)
	},
	refreshToken: () => API.get(`/api/refresh-token?omniVersion=${window.config.release}`),
	forgotPassword: data => API.post(`/account/forgot-password`, data),
	resetPassword: data => API.post(`/account/reset-password`, data),
	switchClinic: data => API.post(`/account/switch-active-clinic`, data),
	setHeader(token) {
		API.defaults.headers['Authorization'] = 'Bearer ' + token
		RESTAPI.defaults.headers['Authorization'] = 'Bearer ' + token
	},
	unsetHeader() {
		delete API.defaults.headers['Authorization']
		delete RESTAPI.defaults.headers['Authorization']
	},
}

const reportIssue = data => API.post(`account/report-issue`, data)

const autoComplete = {
	getAutoCompleteList: () => API.get(`/user/autocomplete-list`),
	saveAutoCompleteEntry: autoComplete => API.post(`/user/save-autocomplete-entry`, autoComplete),
	deleteAutoCompleteEntry: autoComplete => {
		API.post(`/user/delete-autocomplete-entry`, autoComplete)
	},
}

let imageViewMeta = null

const clinic = {
	getList: () => API.get(`/clinic/simple-list`),
	getModalities: () => API.get(`/clinic/modalities/simple-list`),
	getConsignerNames: consultantId => API.get(`/clinic/${consultantId}/consigners/simple-list`),
	getHangingProtocols: () => API.get(`/clinic/hanging-protocols`),
	getHangingProtocol: id => API.get(`/clinic/hanging-protocols/${id}`),
	getImageViewMeta() {
		if (imageViewMeta) {
			return Promise.resolve(imageViewMeta)
		}
		return API.get('clinic/image-view-meta').then(r => {
			imageViewMeta = r.data
			return r.data
		})
	},
	getOmniDesktopUpdateInfo: params =>
		API.get('omni-desktop/get-update-info', {
			params,
			paramsSerializer,
		})
			.then(r => r.data)
			.catch(err => null),
	/**
	 * @param {File} file
	 */
	updateLogo(file) {
		var formData = new FormData()
		formData.append('image', file)
		return API.post(`/clinic/logo`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},
}

const file = {
	uploadTemporaryImage: data => API.post(`/file/upload-temporary-image`, data),
	getDownloadTemporaryImageUrl: ({ fileName }) => `${APP}/file/download-temporary-image?FileName=${fileName}`,
	downloadImages: data => {
		downloadFile(`${API.defaults.baseURL}/file/images?` + paramsSerializer(data))
	},
	downloadStudy: ({ downloadType, clinicCode, id }) =>
		downloadFile(`${APP}/file/study?DownloadType=${downloadType}&ClinicCode=${clinicCode}&Id=${id}`),
	downloadReport: ({ downloadType, clinicCode, id }) =>
		downloadFile(`${APP}/file/report?DownloadType=${downloadType}&ClinicCode=${clinicCode}&Id=${id}`),
	downloadConsultationRequest: ({ downloadType, clinicCode, id }) =>
		downloadFile(`${APP}/file/consultation-request?DownloadType=${downloadType}&ClinicCode=${clinicCode}&Id=${id}`),
}

const legacy = {
	open({ importMode, params }) {
		let url = `${ANNEX_DNS}/OnlineViewer/OnlineViewer.Loader.application?importMode=${importMode}`
		for (let param in params) {
			if (params[param]) url += `&${param}=${params[param]}`
		}
		window.open(url, '_blank')
	},
	openImporter(params) {
		const importMode = 9
		legacy.open({ importMode, params })
	},
}

const patient = {
	getSpecies: id => API.get(`/patient/species-list?consultantId=${id}`).then(r => r.data),
	getBreeds: id => API.get(`/patient/breed-list?speciesId=${id}`).then(r => r.data),
}

const repository = {
	get: clinicCode => API.get(`/repository/get-clinic-info?clinicCode=${clinicCode}`, noAuthConfig),
	getSales: clinicCode => API.get(`/repository/${clinicCode}/sales/simple-list`),
	getConsignerStudies: params =>
		API.get(`/repository/list`, {
			cancelPending: true,
			params,
			paramsSerializer,
		}),
	getRequiredAttachments: id => API.get(`/repository/get-required-viewing-attachments?consignerStudyId=${id}`),
	downloadAttachment(id, clinicCode) {
		const url = `${ANNEX_DNS}/ImageDownload.aspx?ImageId=${id}&ClinicCode=${clinicCode}&ImageType=AttachmentContents`
		window.open(url, '_blank')
	},
	startViewing: data => API.post(`/repository/viewing-start`, data, { cancelPending: true }),
	updateViewing: id =>
		API.post(`/repository/viewing-update`, {
			consignerStudyViewingLogId: id,
		}),
  startVideoViewing: ({ sessionLogId, imageId, endoscopyDisclosure }) =>
    API.get(`${APP}/repository/video-viewing-start?viewingLogId=${sessionLogId}&imageId=${imageId}&endoscopyDisclosure=${endoscopyDisclosure}`),
	updateVideoViewing: ({ imageViewLogId }) =>
		API.get(`${APP}/repository/video-viewing-update?imageViewLogId=${imageViewLogId}`),
}

const saleSubmission = {
	getSales: () => API.get('./saleSubmission/sales-info').then(r => r?.data),
	getSubmissions: (params, cancelPending = true) =>
		API.get('/saleSubmission/list', {
			cancelPending,
			params,
			paramsSerializer,
		}).then(r => r?.data),
	getSubmissionDetails: submissionId => API.get(`/saleSubmission/details/${submissionId}`),
	// something with the submission matched images?
	reject: (submissionId, reasonMsg, imageId = null) =>
		API.post('/saleSubmission/reject', { submissionId, reasonMsg, imageId }),
	approve: submissionId => API.post('/saleSubmission/approve', { submissionId }),
	submit: submissionId => API.post('/saleSubmission/submit', { submissionId }),
	getCatalog: (params, cancelPending = true) =>
		API.get('/saleSubmission/catalog', {
			cancelPending,
			params,
			paramsSerializer,
		}).then(r => r?.data),
	getDashboard: () => API.get('/saleSubmission/dashboard'),
	getDashboardDetails: activeSaleId => API.get(`saleSubmission/dashboard/${activeSaleId}`),
}

const spelling = {
	misspelledWords(data) {
		return API.post(`/spelling/misspelled-words`, data)
	},
	alternativeSpellings(data) {
		return API.post(`/spelling/alternative-spellings`, data)
	},
}

const statistics = {
	getUserInfo(params) {
		return API.get(`/statistics/get-user-info`, {
			params,
			paramsSerializer,
		})
	},
	getSummaries(params) {
		return API.get(`/statistics/get-summaries`, {
			params,
			paramsSerializer,
		})
	},
	getViewings(params) {
		return API.get(`/statistics/get-viewings`, {
			params,
			paramsSerializer,
		})
	},
	updateConsignerClient: data => API.post(`/statistics/consigner-clients/update`, data),
	createConsignerClient: data => API.post(`/statistics/consigner-clients/create`, data),
	getConsignerClients(params) {
		return API.get(`/statistics/consigner-clients`, {
			params,
			paramsSerializer,
		})
	},
	emailConsignerClient(params) {
		return API.post(`/statistics/consigner-clients/email`, null, {
			params,
			paramsSerializer,
		})
	},
	getSaleEntryClients(params) {
		return API.get(`/statistics/get-sale-entry-clients`, {
			params,
			paramsSerializer,
		})
	},
	assignClientToSaleEntry: data => API.post(`/statistics/consigner-clients/assign`, data),
	unassignClientToSaleEntry: data => API.post(`/statistics/consigner-clients/unassign`, data),
	getSubmissions(params) {
		return API.get(`/statistics/get-submissions`, {
			params,
			paramsSerializer,
		})
	},
}

export const downloadFile = function(url) {
	document.getElementById('download_iframe').src = url
}

const study = {
	getReportStudies: reportId =>
		API.get(`/study/report-studies/${reportId}`, {
			cancelPending: true,
		}).then(r => r && r.data),
	deleteStudy: studyId => API.post(`/study/delete-study`, { studyId }),
	deleteSeries: data => API.post(`/study/delete-series`, data),
	getEmails: studyId =>
		API.get('/study/email-details', {
			params: { studyId },
		}),
	getList: (params, cancelPending = true) =>
		API.get('/study/list', {
			cancelPending,
			params,
			paramsSerializer,
		}).then(r => r && r.data),
	getStudyShareUrl: data => API.post('study/generate-consultation-request-url', data),
	renameStudy: data => API.post('/study/rename-study', data),
	shareByEmail: data => API.post('/study/share-by-email', data),
}

const user = {
	addConsultantToUser: params =>
		API.get(`user/add-consultant-to-user`, {
			params,
			paramsSerializer,
		}),
	createCommunityUser: data => API.post(`/user/create-community-user`, data),
	getCommunityDns: id => API.post(`/user/get-community-dns?consultantId=${id}`).then(r => r.data),
	getPermissions: () => API.get(`/user/permissions`),
	getClients: () => API.get(`/user/clients/simple-list`),
	// getViewerSettings: () => API.get(`/user/viewer-settings`),
	// updateViewerSettings (data) => API.patch(`/user/viewer-settings`, data)
	getViewerSettings: () => ({ data: null }),
	updateViewerSettings(settings) {
		return Promise.resolve()
	},
	getHotkeySettings: () => API.get(`/user/hotkey-settings`),
	updateHotkey: data => API.post(`/user/hotkey-setting`, data),
	getWindowLevelPresets: modalityId => API.get(`/user/window-level-presets?modalityId=${modalityId}`),
	updateWindowLevelPreset: data => API.post(`/user/window-level-preset`, data),
	deleteWindowLevelPreset: id => API.delete(`/user/window-level-preset?id=${id}`),
	getViewerMouseBindings: () => API.get('/user/viewer-mouse-bindings'),
	updateViewerMouseBindings: data => API.post('/user/viewer-mouse-bindings', data),
	getOpenStudiesWindowSetting: () => API.get(`/user/viewer-open-in-new-window`),
	setOpenStudiesWindowSetting: openInNewWindow =>
		API.post(`/user/viewer-open-in-new-window?openInNewWindow=${openInNewWindow}`),
	getThemeSettings: () => API.get('/user/theme'),
	updateThemeSettings: data => API.post('/user/theme', data),

	/**
	 * Get any user setting by name. Type will attempt to provide the correct type conversion
	 */
	getUserSetting(name, type) {
		return API.get(`/user/get-setting?name=${name}&type=${type}`)
	},
	/**
	 * Set any user setting by name. Type will attempt to provide the correct type conversion
	 */
	setUserSetting(name, type, value) {
		return API.post('/user/save-setting', { name, type, value })
	},

	/**
	 * returns:
	 *	{
	 *		modality: String,
	 *		seriesRows: Int,
	 *		seriesColumns: Int,
	 *	}
	 */
	getLayoutPreset: modalityString => API.get(`/user/viewer-layout-preset?modality=${modalityString}`),
	setLayoutPreset: data => API.post('/user/viewer-layout-preset', data),
	deleteLayoutPreset: modalityString => API.delete(`/user/viewer-layout-preset?modality=${modalityString}`),
}

const onBehalfOf = {
	getList: () => API.get('/teleconsultation/on-behalf-of-list').then(r => r.data),
	save: item => API.post('/user/on-behalf-of-save', item),
	delete: itemId => API.delete(`/user/on-behalf-of-delete/${itemId}`),
}

const viewer = {
	addStudiesToClinic: ids => API.post(`/viewer/add-studies-to-clinic`, ids),
	getReportUrls: params =>
		API.get(`/viewer/reports/get-url-list`, {
			cancelPending: true,
			params,
			paramsSerializer,
		}).then(r => r && r.data),
	getStudy: (params, cancelPending = true) =>
		API.get(`/viewer/study`, {
			cancelPending,
			params,
			paramsSerializer,
		}).then(r => r && r.data),
	getConsignerStudy: params =>
		API.get(`/viewer/consigner-study`, {
			cancelPending: true,
			params,
			paramsSerializer,
		}).then(r => r && r.data),
	getPartnerStudy: params =>
		API.get(`/viewer/partner-study`, {
			cancelPending: true,
			params,
			paramsSerializer,
		}).then(r => r && r.data),
	getPartnerTeleconsultation: params =>
		API.get(`/viewer/partner-teleconsultation`, {
			cancelPending: true,
			params,
			paramsSerializer,
		}).then(r => r && r.data),
	getConsultationRequest(params) {
		return API.get(`/viewer/consultation-request`, {
			cancelPending: true,
			params,
			paramsSerializer,
		}).then(r => r && r.data)
	},
	/**
	 *
	 * @param {string[]} images - An array of image URLs
	 * @returns
	 */
	 getMetaByImage(series) {
		const urls = series.images.map(image => findJsonUrl(image, series))
		return Promise.all(urls.map(url => axios.get(url, noAuthConfig).then(r => r.data)))
	},
	getMetadata(series) {
		const url = findJsonUrl(series)
		return axios.get(url, noAuthConfig).then(r => r.data)
	},
	renderAnnotatedImage: data => API.post(`/viewer/render-annotated-image`, data).then(r => r && r.data),
	async getConsignerStudyViewingLog(imageId) {
		const params = {
			imageId: imageId,
		}
		return API.get('/repository/consigner-study-viewing-log', { params })
	},
}

// Local is: http://localhost/AnnexSurrogate/ZipDownload.aspx
// Need a way to seperate this from other ${KEYSTONE} endpoints?
const zip = {
	create: data => axios.post(`${ANNEX_DNS}/ZipDownload.aspx/CreateFromStudy`, data),
	createFromConsultation: data => axios.post(`${ANNEX_DNS}/ZipDownload.aspx/CreateFromConsultation`, data),
	getStatus: data => axios.post(`${ANNEX_DNS}/ZipDownload.aspx/Status`, data),
	download({ studyId, clinicCode, timestamp }) {
		const url = `${ANNEX_DNS}/ZipDownload.aspx?id=${studyId}&clinicCode=${clinicCode}&timestamp=${timestamp}`
		window.open(url, '_blank')
	},
}

const repositoryReview = {
	// params: { term: string, folderId: string, startDate: string, endDate: string, page: integer, results: integer, queryTerm: string, orderby: string, isOrderAscending: boolean }
	getList: ({ consultantId, params }, cancelPending = true) =>
		API.get(`/review-submission/${consultantId}/list`, {
			cancelPending,
			params,
			paramsSerializer,
		}).then(r => r && r.data),
	getReviewSubmissionDetails: (consultantId, reviewSubmissionId) =>
		API.get(`/review-submission/${consultantId}/review-submission-details/${reviewSubmissionId}`).then(
			r => r && r.data
		),
	getTemplateImageViews: (consultantId, saleEntryId) =>
		API.get(`/review-submission/${consultantId}/report-template-image-views/${saleEntryId}`).then(r => r && r.data),
	// Get total of reviews by status by sale
	getDashboard: ({ consultantId }, cancelPending = true) =>
		API.get(`/review-submission/${consultantId}/dashboard`, {
			cancelPending,
		}).then(r => r && r.data),
	// Get total of reviews by status by sale
	getDashboardList: ({ consultantId, params }, cancelPending = true) =>
		API.get(`/review-submission/${consultantId}/dashboard-list`, {
			cancelPending,
			params,
			paramsSerializer,
		}).then(r => r && r.data),
	updateReviewSubmissionImage: (reviewSubmissionId, imageId, reportTemplateImageViewId, consultantId) =>
		API.post(`/review-submission/${consultantId}/save-review-submission-image`, {
			ReviewSubmissionId: reviewSubmissionId,
			ImageId: imageId,
			ReportTemplateImageViewId: reportTemplateImageViewId,
		}).then(r => r && r.data),
	updateReviewSubmissionImageAsExtra: (reviewSubmissionId, imageIds, consultantId) =>
		API.post(`/review-submission/${consultantId}/add-extra-images-for-review-submission`, {
			ReviewSubmissionId: reviewSubmissionId,
			ImageIds: imageIds,
		}).then(r => r && r.data),

	// #region  Status Buttons: approve, reject, approve and submit
	approveReviewSubmisssion: (consultantId, reviewSubmissionId) =>
		API.post(`/review-submission/${consultantId}/approve-review-submission/${reviewSubmissionId}`).then(
			r => r && r.data
		),
	approveAndSubmitReviewSubmisssion: (consultantId, reviewSubmissionId) =>
		API.post(`/review-submission/${consultantId}/approve-and-submit-review-submission/${reviewSubmissionId}`).then(
			r => r && r.data
		),
	rejectReviewSubmisssion: (consultantId, reviewSubmissionId, reason) =>
		API.post(`/review-submission/${consultantId}/reject-review-submission/${reviewSubmissionId}`, {
			reason,
		}).then(r => r && r.data),
	deleteReviewSubmisssionImage: (reviewSubmissionId, imageId, consultantId) =>
		API.delete(`/review-submission/${consultantId}/delete-review-submission-image`, {
			data: {
				ReviewSubmissionId: reviewSubmissionId,
				ImageId: imageId,
			},
		}).then(r => r && r.data),
	submitApprovedReviewList: (consultantId, data) =>
		API.post(`/review-submission/${consultantId}/approve-and-submit-all-review-submission`, data).then(
			r => r && r.data
		),
		async syncStudies(dateToMatch){
			return RESTAPI.get(`/review-submission/match-studies?dateToMatch=${dateToMatch}`).then(r => r.data)
		},

	// #endregion

	// #region Add a study with a submissionId
	saveReviewSubmissionStudy: (reviewSubmissionId, studyId, consultantId) =>
		API.post(`/review-submission/${consultantId}/save-review-submission-study`, {
			ReviewSubmissionId: reviewSubmissionId,
			StudyId: studyId,
		}).then(r => r && r.data),

	// #endregion
}

const sales = {
	// TODO move the endpoint from salesService.ts
	submitSaleEntry: saleEntry => API.post(`/sales/submit-sale-entry`, saleEntry).then(r => r && r.data),
}

const api = {
	auth,
	autoComplete,
	cancelAllPendingRequests: cancelPending.cancelAllPendingRequests,
	clinic,
	file,
	legacy,
	onBehalfOf,
	patient,
	reportIssue,
	repository,
	repositoryReview,
	sales,
	saleSubmission,
	setApiBaseUrl,
	spelling,
	statistics,
	study,
	user,
	viewer,
	zip,
}

window.api = api
export default api
