<template>
	<div class="report-divider"></div>
</template>

<script>
export default {
	name: 'ReportDivider',
	props: ['cell'],
}
</script>

<style lang="scss">
.report-divider {
	margin: 0 auto;
	border-top: 1px solid currentColor;
	height: 0;
	width: 100%;
	margin: 4px 0;
}
</style>
