import { API, paramsSerializer, downloadFile, serializeDate } from '@services/api'
import { userData } from './userData'
import { addNotification } from './notificationService'
import { eventBus } from './eventBus'
import { showInfo } from '@dialogs/MessageDlg.vue'
import { clinicAPI } from '@services/clinicAPI'

class StudyData {
	getRelatedStudiesAndReports(
		studyId: string,
		clinicCode?: string,
		cancelPending: boolean = true
	): Promise<IRelatedStudyInfo> {
		let config = {
			cancelPending,
			params: {
				studyId,
				importMachineName: clinicAPI.importMachineName,
				clinicCode,
			},
			paramsSerializer,
		}

		return API.get(`/study/related-studies-reports`, config).then(r => r && r.data)
	}

	getAnonymizedStudyInfo(studyId): Promise<IAnonymizedStudy> {
		return API.get(`/study/anonymized-info/${studyId}`).then(r => r.data)
	}

	getStudyAvailable(studyId: string): Promise<boolean> {
		return API.get(`/study/available/${studyId}`).then(r => r.data)
	}

	createAnonymizedStudy(data: IAnonymizedStudy) {
		data = JSON.parse(JSON.stringify(data))
		data.studyDateTime = serializeDate(data.studyDateTime)
		data.patientBirthDate = serializeDate(data.patientBirthDate)

		return API.post(`/study/create-anonymized-study`, data).then(r => {
			let studyId = r.data
			if (!studyId) return
			let count = 0
			let timer = setInterval(async () => {
				count++
				let ready = await this.getStudyAvailable(studyId)
				if (ready) {
					addNotification('New anonymized study created', 'success')
					eventBus.post('refresh-study-list')
				}

				if (ready || count > 20) {
					clearTimeout(timer)
				}
			}, 2000)
		})
	}

	downloadAnonymizedStudy(data: IAnonymizedStudy) {
		data['token'] = userData.queryToken
		let query = paramsSerializer(data)
		downloadFile(`${API.defaults.baseURL}/study/download-anonymized-dicoms?` + query)
	}

	mergeStudies(studyIds: string[]): Promise<any> {
		return API.post(`/study/merge`, studyIds)
	}

	showArchivingTip() {
		const text = `Your local Keystone server has all of these images and is transferring them to
		the cloud.  This process can take a few minutes depending on the size of the study and the
		internet speed of the Keystone server.  Some study operations require all images to be in the
		cloud.  These operations will become available as soon as your images finish transferring.`
		showInfo(text)
	}
}

export const studyData = new StudyData()
export default studyData
// @ts-ignore
window.studyData = studyData
