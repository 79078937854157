import macro from 'vtk.js/Sources/macro'
import vtkCompositeCameraManipulator from 'vtk.js/Sources/Interaction/Manipulators/CompositeCameraManipulator'
import vtkCompositeMouseManipulator from 'vtk.js/Sources/Interaction/Manipulators/CompositeMouseManipulator'
import { toWindowLevel } from '../lib/windowLevelRangeConverter'

// ----------------------------------------------------------------------------
// vtkWindowLevelManipulator methods
// ----------------------------------------------------------------------------

function vtkWindowLevelManipulator(publicAPI, model) {
	// Set our className
	model.classHierarchy.push('vtkWindowLevelManipulator')

	publicAPI.onButtonDown = (interactor, renderer, position) => {
		model.previousPosition = position
	}

	publicAPI.onMouseMove = (interactor, renderer, position) => {
		const volume = renderer.getVolumes()[0]
		if (!position || !volume) {
			return
		}

		const pos = position
		const lastPos = model.previousPosition
		model.previousPosition = position

		const range = volume
			.getMapper()
			.getInputData()
			.getPointData()
			.getScalars()
			.getRange()
		const imageDynamicRange = range[1] - range[0]
		const multiplier = (imageDynamicRange / 1024) * publicAPI.getLevelScale()

		// These are different because y is flipped.
		let dx = (pos.x - lastPos.x) * multiplier
		let dy = (lastPos.y - pos.y) * multiplier * 0.5

		const mappingRange = volume
			.getProperty()
			.getRGBTransferFunction(0)
			.getMappingRange()
			.slice()
		let { windowWidth, windowCenter } = toWindowLevel(...mappingRange)

		windowWidth = Math.max(1, Math.round(windowWidth + dx))
		windowCenter = Math.round(windowCenter + dy)

		const onLevelsChanged = publicAPI.getOnLevelsChanged()
		if (onLevelsChanged) {
			onLevelsChanged({ windowCenter, windowWidth })
		}
	}
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
	levelScale: 1,
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
	Object.assign(model, DEFAULT_VALUES, initialValues)

	// Inheritance
	macro.obj(publicAPI, model)
	vtkCompositeCameraManipulator.extend(publicAPI, model, initialValues)
	vtkCompositeMouseManipulator.extend(publicAPI, model, initialValues)

	macro.setGet(publicAPI, model, ['onLevelsChanged', 'levelScale'])

	// Object specific methods
	vtkWindowLevelManipulator(publicAPI, model)
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkWindowLevelManipulator')

// ----------------------------------------------------------------------------

export default { newInstance, extend }
