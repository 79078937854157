import { isCancel } from 'axios'
import { dlg } from '@utils/dlgUtils'
import store from '@store'
import * as Sentry from '@sentry/browser'

const is401 = error => error.response && error.response.status === 401

function hasErrorMessage(error) {
  return !!(error.response && error.response.data && (error.response.data.message || error.response.data['Message']))
}
function install(axiosInstance) {
	axiosInstance.interceptors.response.use(undefined, errorResponseInterceptor)
}

function errorResponseInterceptor(error) {
	// Ignore cancelled requests
	if (isCancel(error)) return
	// Report error to Sentry if not 401
	let sentryEventId
	if (!is401(error)) sentryEventId = Sentry.captureException(error)

	// Show notification
	showNotification(error, sentryEventId)

	// Log out if token has expired
	if (is401(error)) store.dispatch('logOut', { forceLogout: true })
	// Bubble up the error
	throw error
}

const defaultMessages = []
defaultMessages[0] =
	'<strong>No response from the server.</strong><br>You may not have an active connection to the internet, or we may be experiencing technical difficulties.'
defaultMessages[401] = 'Your authentication token has expired. You will need to sign in.'
defaultMessages[404] =
	'<strong>404: Unable to find URL.</strong><br>If you encounter unexpected behavior, please contact support.'

// It returns Date format YYYY/MM/DD
const getDate = date => {
	return `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}`
}

const today = new Date().valueOf()
let endDate = new Date('2023-09-10')
endDate.setUTCHours(8, 30, 0, 0) // 4:00 am Eastern Time
let startDate  = new Date('2023-09-10')
startDate.setUTCHours(8, 0, 0, 0) // 4:30 am Eastern Time
if (today >= startDate.valueOf()  && today <= endDate.valueOf()) {
  defaultMessages[0] =
    '<strong>Maintenance Notification: </strong><br/>' +
    'We are conducting routine maintenance between 4:00 am and 4:30 am ' +
    'Eastern Time on Sunday the 10th of September 2023. We do not anticipate any ' +
    'downtime; however, we wanted to notify you in case of any potential ' +
    'disruptions. Thank you for your understanding.'
}

function showNotification(error, sentryEventId) {
	const notification = {
		notificationType: 'info',
		message: undefined,
		duration: 10000,
		sentryEventId,
	}
	if (hasErrorMessage(error)) {
		notification.message = error.response.data.message ? error.response.data.message : error.response.data['Message']
		notification.notificationType = error.response.data.statusCode >= 500 ? 'error' : 'warn'
	} else if (defaultMessages[error.request.status]) {
		notification.message = defaultMessages[error.request.status]
		notification.notificationType = error.request.status >= 500 ? 'error' : 'warn'
	} else {
		notification.message = error.message || defaultMessages[0]
		notification.notificationType = 'error'
	}
	store.dispatch('addNotification', notification)
}

export default { install }
