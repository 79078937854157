import {
	getEnabledElement,
	EVENTS as cornerstoneEventsEnum,
	events as cornerstoneEvents,
} from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import AstStackImagePositionSynchronizer from '@/cornerstone/synchronizers/AstStackImagePositionSynchronizer.js'
import AstManualStackImagePositionSynchronizer from '@/cornerstone/synchronizers/AstManualStackImagePositionSynchronizer.js'
import store from '@store'
import { waitForEnabledElementImageToLoad } from '@/utils/wait.js'
import { eventBus } from '@services/eventBus'

/**
 * Element Enabled event.
 *
 * @event Cornerstone#ElementEnabled
 * @type {Object}
 * @property {string} type
 * @property {Object} detail
 * @property {HTMLElement} detail.element - The element being enabled.
 */

/**
 * Element Disabled event.
 *
 * @event Cornerstone#ElementDisabled
 * @type {Object}
 * @property {string} type
 * @property {Object} detail
 * @property {HTMLElement} detail.element - The element being disabled.
 */

export const synchronizers = {
	referenceLinesSynchronizer: null,
	updateImageSynchronizer: null,
	stackScrollSynchronizer: null,
}

/**
 * Creates new instances of each synchronizer and wires up event
 * listeners.
 *
 * @exports @public @method
 * @name create
 */
const create = function() {
	// Create new synchronizer instances
	synchronizers.referenceLinesSynchronizer = new cornerstoneTools.Synchronizer(
		cornerstoneEventsEnum.NEW_IMAGE,
		cornerstoneTools.updateImageSynchronizer
	)

	let updateMetaDataHandledByStackScroll = false

	// Monkeypatch the referenceLinesSynchronizer, so we can grab metadata and sync cross browser
	async function customAddSource(element) {
		// Used for setting active canvas, initialize the right element's metadata)
		//     Important because the removeSource function will trigger customFireEvent and update metadata with old image's info,
		//     which happens on .destroy() prior to the addSource event in `setActiveCanvas.js`
		const img = await waitForEnabledElementImageToLoad(element)
		if (img) store.dispatch('setActiveImageMetaDataFromElement', element)
		this.__addSource(element)
		this.__fireEvent(element)
	}
	function customFireEvent(sourceElement, eventData) {
		const isVirtual = eventData && eventData.virtual
		// Used for an image being updated, via stack scroll, CINEloop, etc
		if (this.enabled && !isVirtual && !updateMetaDataHandledByStackScroll)
			store.dispatch('setActiveImageMetaDataFromElement', sourceElement)
		updateMetaDataHandledByStackScroll = false
		this.__fireEvent(sourceElement, eventData)
	}

	synchronizers.referenceLinesSynchronizer.__fireEvent = synchronizers.referenceLinesSynchronizer.fireEvent
	synchronizers.referenceLinesSynchronizer.fireEvent = customFireEvent

	synchronizers.referenceLinesSynchronizer.__addSource = synchronizers.referenceLinesSynchronizer.addSource
	synchronizers.referenceLinesSynchronizer.addSource = customAddSource

	synchronizers.updateImageSynchronizer = new cornerstoneTools.Synchronizer(
		cornerstoneEventsEnum.NEW_IMAGE,
		cornerstoneTools.updateImageSynchronizer
	)

	function customStackScrollFireEvent(sourceElement, eventData) {
		const isVirtual = eventData && eventData.virtual
		if (this.enabled) {
			updateMetaDataHandledByStackScroll = true
			if (!isVirtual) {
				store.dispatch('setActiveImageMetaDataFromElement', sourceElement)
				eventBus.broadcast(eventBus.type.VUEX_ACTION, { type: 'virtualStackScroll' })
			}
		}
		this.__fireEvent(sourceElement, eventData)
	}
	synchronizers.stackScrollSynchronizer = new cornerstoneTools.Synchronizer(
		cornerstoneTools.EVENTS.STACK_SCROLL,
		AstStackImagePositionSynchronizer
	)
	synchronizers.stackScrollSynchronizer.__fireEvent = synchronizers.stackScrollSynchronizer.fireEvent
	synchronizers.stackScrollSynchronizer.fireEvent = customStackScrollFireEvent

	synchronizers.manualStackScrollSynchronizer = new cornerstoneTools.Synchronizer(
		cornerstoneTools.EVENTS.STACK_SCROLL,
		AstManualStackImagePositionSynchronizer
	)
	synchronizers.manualStackScrollSynchronizer.__fireEvent = synchronizers.manualStackScrollSynchronizer.fireEvent
	synchronizers.manualStackScrollSynchronizer.fireEvent = customStackScrollFireEvent

	// Set initial states
	synchronizers.stackScrollSynchronizer.enabled = store.state.viewer.settingsPanel.isStackScrollSynchronizationEnabled

	synchronizers.manualStackScrollSynchronizer.enabled =
		store.state.viewer.settingsPanel.isManualStackScrollSynchronizationEnabled

	synchronizers.referenceLinesSynchronizer.enabled =
		store.state.viewer.settingsPanel.isReferenceLineSynchronizationEnabled
	if (synchronizers.referenceLinesSynchronizer.enabled) {
		cornerstoneTools.setToolEnabled('ReferenceLines', {
			synchronizationContext: synchronizers.referenceLinesSynchronizer,
		})
	} else {
		cornerstoneTools.setToolDisabled('ReferenceLines')
	}

	// Listen for enabled/disabled element events
	_addListeners()
}

/**
 * Destroys each synchronizer and removes event listeners
 *
 * @exports @public @method
 * @name destroy
 */
const destroy = function() {
	// Clear synchronizer instances
	synchronizers.referenceLinesSynchronizer.destroy()
	synchronizers.updateImageSynchronizer.destroy()
	synchronizers.stackScrollSynchronizer.destroy()
	synchronizers.manualStackScrollSynchronizer.destroy()
	synchronizers.referenceLinesSynchronizer = null
	synchronizers.updateImageSynchronizer = null
	synchronizers.stackScrollSynchronizer = null
	synchronizers.manualStackScrollSynchronizer = null

	store.commit('SET_STACK_SCROLL_OFFSETS', [])

	cornerstoneTools.setToolDisabled('ReferenceLines')

	_removeListeners()
}

/**
 * Removes the element enabled/disabled listeners that
 * we use to add/remove enabledElement from synchronizers
 *
 * @private @method
 * @name removeListeners
 */
const _removeListeners = function() {
	// Remove listeners for enabled/disabled element events
	cornerstoneEvents.removeEventListener(cornerstoneEventsEnum.ELEMENT_ENABLED, _addEnabledElementToActiveSynchronizers)

	cornerstoneEvents.removeEventListener(
		cornerstoneEventsEnum.ELEMENT_DISABLED,
		_removeEnabledElementFromAllSynchronizers
	)
}

/**
 * Adds the element enabled/disabled listeners that
 * we use to add/remove enabledElement from synchronizers
 *
 * @private @method
 * @name addListeners
 */
const _addListeners = function() {
	_removeListeners()
	cornerstoneEvents.addEventListener(cornerstoneEventsEnum.ELEMENT_ENABLED, _addEnabledElementToActiveSynchronizers)
	cornerstoneEvents.addEventListener(cornerstoneEventsEnum.ELEMENT_DISABLED, _removeEnabledElementFromAllSynchronizers)
}

/**
 * Fired when an element is enabled by `cornerstone`. Allows
 * us to add any new elements to existing synchronizers
 *
 * @private @method
 * @name addEnabledElement
 * @param {Cornerstone#ElementEnabled} elementEnabledEvt
 * @listens Cornerstone#ElementEnabled
 */
const _addEnabledElementToActiveSynchronizers = async function(elementEnabledEvent) {
	const element = elementEnabledEvent.detail.element
	const enabledElement = await _checkIsImageLoaded(element)

	if (enabledElement) {
		if (synchronizers.updateImageSynchronizer) synchronizers.updateImageSynchronizer.add(element)
		if (synchronizers.stackScrollSynchronizer) synchronizers.stackScrollSynchronizer.add(element)
		if (synchronizers.manualStackScrollSynchronizer) synchronizers.manualStackScrollSynchronizer.add(element)

		// Notes:
		// - referenceLinesSynchronizer - Updated in `setActiveCanvas.js`
	}
}

/**
 * Fired when an element is disabled by `cornerstone`. Allows
 * us to remove elements from synchronizers when they cease to exist
 *
 * @export @private @method
 * @name addEnabledElement
 * @param {Cornerstone#ElementDisabled elementDisabledEvt
 * @listens Cornerstone#ElementDisabled
 */
const _removeEnabledElementFromAllSynchronizers = function(elementDisabledEvent) {
	const element = elementDisabledEvent.detail.element

	synchronizers.updateImageSynchronizer.remove(element)
	synchronizers.stackScrollSynchronizer.remove(element)
	synchronizers.manualStackScrollSynchronizer.remove(element)
	synchronizers.referenceLinesSynchronizer.remove(element)
}

/**
 * Waits a set amount of time, then resolves. Can be chained off of to delay
 * next call in promise chain.
 *
 * @private @function
 * @param {number} ms
 * @returns {Promise}
 */
const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Returns an enabledElement once it's image has been loaded. Returns
 * `null` if the enabledElement is destroyed before an image can be loaded.
 *
 * @private @function
 * @param {HTMLElement} element
 * @returns {Promise}
 */
const _checkIsImageLoaded = element => {
	try {
		const enabledElement = getEnabledElement(element)
		if (!enabledElement.image) {
			return wait(250).then(() => _checkIsImageLoaded(element))
		}
		console.debug("enabledElement's image is loaded. Adding to synchronizers.")
		return enabledElement
	} catch (ex) {
		// Not an enabled element, stop polling
		return null
	}
}

export default {
	create,
	destroy,
}
