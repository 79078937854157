<template>
	<div>
		<ast-card
			v-for="(study, index) in studies"
			:key="index"
			:show-header="false"
			:is-hoverable="true"
			@click.native="$emit('select-study', study)"
		>
			<div class="study-card">
				<button v-if="showViewButton" class="view-button" @click.stop="$emit('open-study', study)">
					<svg-icon icon="eye" />
				</button>
				<ul style="width: 100%;">
					<li>
						<span class="label">Patient:</span>
						{{ study.patientId }}
						<span v-if="study.patientName">- {{ study.patientName }}</span>
					</li>
					<li v-if="study.ownerName">
						<span class="label">Owner:</span>
						{{ study.ownerName }}
					</li>
					<li v-if="study.studyDateTime">
						{{ study.studyDateTime | localizeDate({ forceUTC: false }) }}
					</li>
					<li v-if="study.studyDescription" style="font-size: 0.85em;">
						{{ study.studyDescription }}
					</li>
					<li style="display: flex; justify-content: space-between;">
						<small>
							{{ study.modality }} -
							{{ study.imageCount }}
							{{ study.imageCount === 1 ? 'Image' : 'Images' }}
						</small>
						<span
							v-if="
								study.mostRecentReportRecipient ||
									study.hasBeenEmailed ||
									study.hasReport ||
									study.hasSignalPETAttachment
							"
						>
							<img
								v-if="study.hasSignalPETAttachment"
								src="@assets/icons/signalpet-colored.png"
								class="signalpet-colored"
							/>
							<svg-icon v-if="study.mostRecentReportRecipient" icon="email-check" fixed />
							<svg-icon v-if="study.hasBeenEmailed" icon="email" fixed />
							<svg-icon v-if="study.hasReport" icon="file-text-o" title="Study has standard reports" fixed />
						</span>
					</li>
				</ul>
			</div>
		</ast-card>
	</div>
</template>

<script>
import AstCard from '@components/Card'
export default {
	name: 'StudiesListCards',
	components: {
		AstCard,
	},
	props: {
		studies: {
			type: Array,
			required: true,
		},
		showViewButton: {
			type: Boolean,
			default: true,
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.study-card {
	display: flex;
	align-items: center;

	.view-button {
		display: flex;
		flex-direction: column;
		background: var(--button-default-bg);
		color: var(--button-default-color);
		width: 48px;
		height: 48px;
		min-width: 48px;
		height: 48px;
		border: 0;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover {
			background: var(--button-default-bg-hover);
			color: var(--button-default-color-hover);
		}
	}

	ul {
		padding-left: 8px;
		list-style-type: none;
		overflow: hidden;

		li {
			padding: 4px 0;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.label {
		font-weight: 400;
	}

	.signalpet-colored {
		width: 17px;
		height: auto;
		margin-right: 5px;
	}
}
</style>
