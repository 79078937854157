<template>
	<div class="dlg-header">
		<slot></slot>
		<h3>{{ title }}</h3>
		<loading-spinner :is-loading="isLoading" is-small />
		<button v-if="showClose" data-test="bt-dialog-close" @click="$emit('close')">
			<svg-icon icon="close" />
		</button>
	</div>
</template>

<script>
import LoadingSpinner from '@components/LoadingSpinner'

export default {
	name: 'DlgHeader',
	components: {
		LoadingSpinner,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		showClose: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
}
</script>

<style lang="scss">
.dlg-header {
	position: relative;
	display: flex;
	align-items: center;
	height: 48px;
	flex-shrink: 0;
	padding-left: 16px;

	// if the first child is a button-container, remove the padding
	> .button-container:first-child {
		margin-left: -16px;
	}

	h3 {
		flex-grow: 1;
		margin: 0;
	}

	* + h3 {
		margin-left: 0.5em;
	}

	button {
		background: transparent;
		cursor: pointer;
		border: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;

		.icon {
			margin: 0;
			font-size: 18px;
		}
	}
}
</style>
