










import { Cell } from '../classes'

export default {
	name: 'RequestInfo',
	props: ['cell'],
	watch: {
		cell() {
			this.init()
		},
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			let cell: Cell = this.cell
			if (cell.editorMode) return
			cell.subLayouts = []
			let t = cell.report.request
			if (t == null) return
			let selector = t.root.controls.find(c => c.type === 'Request Selector')
			if (selector == null) return
			let infoType = t.values[selector.path]
			let l
			// if Request Selector has a value, add the selected sublayout
			if (infoType) l = t.layouts.find(l => l.name === infoType)
			// if accessing Response in Preview dialog, default to the first Request Selector layout
			else if (cell.report.isPreview) l = t.layouts.find(l => l.group === 'Request Selector')
			if (!l) return
			let layout = l.createInstance()
			layout.readOnly = true
			layout.template = t
			layout.forcePath = infoType
			layout.hydrate()
			cell.addSubLayout(layout)
		},
	},
}
