import macro from 'vtk.js/Sources/macro'
import vtkCompositeMouseManipulator from 'vtk.js/Sources/Interaction/Manipulators/CompositeMouseManipulator'

// ----------------------------------------------------------------------------
// vtkAnnotationManipulator methods
// ----------------------------------------------------------------------------

function vtkAnnotationManipulator(publicAPI, model) {
	// Set our className
	model.classHierarchy.push('vtkAnnotationManipulator')

	publicAPI.onButtonDown = (interactor, renderer, position) => {
		if (position) {
			model.lastPosition = position
			publicAPI.getMouseInterface().onButtonDown(position, publicAPI.getToolName())
		}
	}
	publicAPI.onButtonUp = interactor => {
		publicAPI.getMouseInterface().onButtonUp(model.lastPosition, publicAPI.getToolName())
	}

	publicAPI.onMouseMove = (interactor, renderer, position) => {
		if (position) {
			model.lastPosition = position
			publicAPI.getMouseInterface().onMouseMove(position, publicAPI.getToolName())
		}
	}
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
	toolName: '',
	lastPosition: null,
	mouseInterface: {
		onButtonDown: () => {},
		onButtonUp: () => {},
		onMouseMove: () => {},
	},
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
	Object.assign(model, DEFAULT_VALUES, initialValues)

	// Inheritance
	macro.obj(publicAPI, model)
	vtkCompositeMouseManipulator.extend(publicAPI, model, initialValues)

	macro.setGet(publicAPI, model, ['mouseInterface', 'toolName'])

	// Object specific methods
	vtkAnnotationManipulator(publicAPI, model)
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkAnnotationManipulator')

// ----------------------------------------------------------------------------

export default { newInstance, extend }
