
















import omniDesktop from '@/electron/omniDesktop'
import { addNotification } from '@services/notificationService'

interface IDownloadInfo {
	id: number
	savePath: string
	filename: string
	total: number
	state: string
	notify?: string
	bytesReceived: number
	done: boolean
}

interface IDesktopDownloadItem {
	id: number
	savePath: string
	filename: string
	size: number
	status: string
	done: boolean
}

const items: IDesktopDownloadItem[] = []

export default {
	name: 'Loading',
	data() {
		return {
			items,
		}
	},
	computed: {
		active() {
			return this.items.length
		},
	},
	methods: {
		removeItem(item) {
			if (!item.done) {
				omniDesktop.request('cancelDownload', item.id)
			}
			items.splice(items.indexOf(item), 1)
		},
		openFolder(item) {
			omniDesktop.request('openFolder', item.savePath)
		},
	},
}

export function getActiveDownloads(): number {
	return items.filter(i => !i.done).length
}

export function setDownloadStatus(info: IDownloadInfo) {
	if (info.notify) {
		addNotification(info.notify, 'success')
		return
	}

	if (!info.savePath) return
	if (info.state === 'cancelled') {
		addNotification(`Download cancelled: ${info.filename}`, 'warn')
		return
	}

	let item = items.find(i => i.id === info.id)
	if (!item) {
		item = {
			id: info.id,
			savePath: info.savePath,
			filename: null,
			size: 0,
			status: null,
			done: false,
		}
		items.push(item)
	}

	item.done = info.done
	item.size = info.bytesReceived
	item.savePath = info.savePath
	item.filename = info.filename
}
