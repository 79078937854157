import getValue from './../internals/getValue.js'
// DBTODO: Dicom image loader
// import getModalityLUTOutputPixelRepresentation from './getModalityLUTOutputPixelRepresentation.js'

export default function(dicomTags) {
  // const modalityLUTOutputPixelRepresentation = getModalityLUTOutputPixelRepresentation(
  //   dataSet
  // )

  return {
    // DBTODO: `getNumberValues` -- expects 1 number
    windowCenter: getValue(dicomTags, 'WINDOW_CENTER'),
    windowWidth: getValue(dicomTags, 'WINDOW_WIDTH'),
    // voiLUTSequence: getLUTs(
    //   modalityLUTOutputPixelRepresentation,
    //   dataSet.elements.x00283010
    // ),
  }
}
