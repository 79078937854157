<template>
	<modal-wrapper @close="close">
		<div id="statWarningDlg" class="dialog">
			<p>
				The report for patient {{ info.description }} has been locked by you for over
				{{ info.minutesLocked }} minutes. This exceeds the threshold allowed for completion of this
				teleconsultation report. You can either complete the report within the next
				{{ info.repeatWarningMinutes }} minutes or you can release the report back to the group so
				it can be completed.
			</p>
			<p>
				If you do not respond in {{ secondsUntilRelease }} seconds, Keystone will automatically
				release the report back to the group.
			</p>
			<p>
				Note: If you select to complete this report, Keystone will remind you again in
				{{ info.repeatWarningMinutes }} minutes.
			</p>
			<div class="footer right">
				<button class="btn btn-default" @click="close(true)">Complete Now</button>
				<button class="btn btn-default" @click="close(false)">Release to Group</button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'

const StatWarningDlg = {
	name: 'StatWarningDlg',
	components: {
		ModalWrapper,
	},
	data() {
		return {
			secondsUntilRelease: 60,
			timerId: 0,
		}
	},
	props: ['info'],
	methods: {
		close(keepLock) {
			dlg.close(this, true, keepLock)
		},
	},
	created() {
		this.timerId = setInterval(() => {
			this.secondsUntilRelease--
			if (this.secondsUntilRelease <= 0) {
				clearInterval(this.timerId)
				this.close(undefined)
			}
		}, 1000)
	},
	destroyed() {
		clearInterval(this.timerId)
	},
}

export default StatWarningDlg

export function openStatWarning(info) {
	return dlg.open(StatWarningDlg, { info })
}
</script>

<style lang="scss">
#statWarningDlg {
	max-width: 500px;
	p {
		margin-bottom: 16px;
	}
}
</style>
