import getValue from './../internals/getValue.js'

export default function(dicomTags) {
  const radiopharmaceuticalInfo = getValue(
    dicomTags,
    'RADIOPHARMACEUTICAL_INFORMATION_SEQ'
  )

  if (radiopharmaceuticalInfo === undefined) {
    return
  }

  return {
    radiopharmaceuticalInfo: {
      // dicomParser.parseTM(getValue(radiopharmaceuticalInfo, 24, 4210, 0, '')),
      radiopharmaceuticalStartTime: 0,
      radionuclideTotalDose: getValue(dicomTags, 'RADIONUCLIDE_TOTAL_DOSE'),
      radionuclideHalfLife: getValue(dicomTags, 'RADIONUCLIDE_HALF_LIFE'),
    },
  }
}
