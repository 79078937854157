import { defaultInitCanvases, newConsultationRequest } from '@store/modules/viewer/_shared'
import metadata from '@/cornerstone/metadata'

/**
 *
 *
 * @public
 * @function CLEAR_VIEWER
 *
 * @param {*} state
 *
 * @returns {undefined}
 */
export default function(state) {
	state.studies = []
	state.isViewerLoading = true
	state.canvases = defaultInitCanvases()
	state.consultationRequest = newConsultationRequest()
	state.hideLoadingText = false
	state.hangingProtocol = undefined
	state.hangingProtocols = []
	state.activeDisplaySetIndex = 0

	// meta
	metadata.clear()
}
