import { validator } from '@/utils/validation'
import { Binding, METHODS } from '@/reporting/binding'
import { ContextMeta } from '@/reporting/context'
import { userData as user } from '@services/userData'
import reportService from '@services/reportService'
import consultationService from '@services/consultationService'

const textdiv = document.createElement('div')
let nextCellId = 1

export class ReportDetail {
	reportId: string = null
	consultantReportId: string = null
	maxPartnerData?: number

	areImagesAvailable: boolean
	completedBy: string
	finalizedBy: IUserInfo
	completedDateTime: string
	isComplete: boolean
	groupConsultantId?: string
	isLocked: boolean
	lockedBy: string
	lockedByUserId: string
	lockedDateTime: string
	standard: Template
	request: Template
	response: Template
	ownerId: string
	canViewResponse: boolean
	canRespond: boolean = false
	canRecall: boolean = false
	canDelete: boolean = false
	forceConsultantHeader: boolean = false
	skipValidation: boolean = false

	activeTemplate: Template

	studies: IStudy[] = []
	consultants: IConsultant[]
	priorities: IPriority[]
	services: IConsultantService[]
	billingCodes: IBillingCode[]
	seriesToExclude: string[] = []
	isValid: boolean = false
	validationErrors: string[] = []
	emails: string[] = []
	studyIds: string[] = []
	petRaysExamTypes?: string[]

	study: IStudyContext = null
	patient: IPatient = null
	client: IClient = null
	priority: number = 0

	priorityInfo: IProrityInfo

	serviceId: string = null
	billingCodeId: string = null
	consultant: IConsultant = null
	drafter: IDrafter = null
	selectedConsultant: IConsultant = null
	relatedReports: IRelatedReport[] = []
	images: IReportImage[] = []
	attachments: IAttachment[] = []
	imageComments: IImageComment[] = []
	addendums: IAddendum[] = []

	isPreview?: boolean = false
	templateSetName?: string

	appliedResidentResponseId: string = null
	residentResponses: IResidentResponse[] = []

	addStudy(study: IStudy) {
		this.addNewAttachments(study.imageData.attachments)
		this.addNewImages(study.imageData.thumbnails)
		this.studies.push(study)
		this.studyIds.push(study.studyId)
	}

	addNewAttachments(attachments: IViewerAttachment[]) {
		const newAttachments: IAttachment[] = attachments
			.filter(a => !this.attachments.some(existing => existing.seriesId === a.seriesId))
			.map(a => ({
				imageId: a.imageId,
				seriesId: a.seriesId,
				description: a.description,
				comment: undefined,
				originalFileName: a.originalFileName,
				fileExtension: a.fileExtension,
				storageLocation: a.storageLocation,
			}))

		this.attachments = this.attachments.concat(newAttachments)
	}

	addNewImages(images: IViewerImageThumbnail[]) {
		const newImages: IReportImage[] = images
			.filter(t => !this.images.some(existing => existing.seriesId === t.seriesId))
			.map(i => ({
				id: i.imageId,
				seriesId: i.seriesId,
				limb: undefined,
				view: undefined,
				anatomy: undefined,
				clinicCode: undefined,
				numberOfImages: i.numberOfImages,
				simpleName: '',
				thumbnailUrl: undefined,
				seriesNumber: i.instanceNumber,
				storageLocation: i.storageLocation,
			}))
		this.images = this.images.concat(newImages)
	}

	getStandardReportData(complete: boolean): IStandardReportData {
		let values = this.activeTemplate.saveValues()
		return {
			reportId: this.reportId,
			patient: this.patient,
			client: this.client,
			values,
			imageComments: this.imageComments.filter(i => i.changed === true),
			complete,
			billingCodeId: this.billingCodeId,
		}
	}

	getSaveResponseForm(complete = false): IConsultantResponseForm {
		let values = this.response.saveValues()

		return {
			consultantReportId: this.consultantReportId,
			responseReportId: this.response.reportId,
			values,
			imageComments: this.imageComments.filter(i => i.changed === true || this.appliedResidentResponseId),
			billingCodeId: this.billingCodeId,
			complete,
		}
	}

	getNewRequestData(): INewConsultationRequest {
		let values = this.request.saveValues()

		// determine which thumbnails are fake ids
		// add them to the excluded image ids list
		let fakeSeries = {}
		this.images.forEach(i => {
			if (i.fakeSeriesId) fakeSeries[i.fakeSeriesId] = true
		})

		let seriesToExclude = this.seriesToExclude.filter(s => fakeSeries[s] === undefined)
		let imagesToExclude = this.seriesToExclude.filter(s => fakeSeries[s] !== undefined)

		const result: INewConsultationRequest = {
			consultantId: this.consultant.id,
			selectedConsultantId: this.selectedConsultant && this.selectedConsultant.id,
			priority: this.priority,
			serviceId: this.serviceId,
			billingCodeId: this.billingCodeId,
			studyIds: this.studyIds,
			seriesToExclude,
			imagesToExclude,
			requestType: values['RequestSelector'],
			patient: this.patient,
			client: this.client,
			emails: this.emails,
			templateId: this.request.templateId,
			templateFieldId: this.request.id,
			values: values,
			modality: this.study?.modality
		}
		return result
	}

	async restartRequest(studyId: string) {
		const studyIds = studyId.split(',')
		const reportDetail = await consultationService.start(this.consultant.id, studyIds)
		const oldRequest = this.request
		reportDetail.request.report = this
		this.request = reportDetail.request
		this.request.values = oldRequest.values
		this.request.hydrate()
		this.setActiveTemplate(this.request)
	}

	refresh(r: ReportDetail) {
		this.isComplete = r.isComplete
		this.isLocked = r.isLocked
		this.lockedBy = r.lockedBy
		this.lockedByUserId = r.lockedByUserId
		this.lockedDateTime = r.lockedDateTime
		this.relatedReports = r.relatedReports
		this.images = r.images
		this.attachments = r.attachments
		this.addendums = r.addendums
		this.billingCodes = r.billingCodes
		this.imageComments = r.imageComments
		this.canDelete = r.canDelete
		this.canRecall = r.canRecall
		this.consultant = r.consultant

		this.setCanRespond()

		if (this.appliedResidentResponseId) {
			let rr = r.residentResponses.find(rr => rr.id === this.appliedResidentResponseId)
			this.imageComments = rr.imageComments
		}

		// This is causing issues. Hotfixed to remove, see CH12575 for original intent
		// if (!this.activeTemplate.pendingChanges && r.activeTemplate) {
		// 	Object.assign(this.activeTemplate.values, r.activeTemplate.values)
		// }

		this.activeTemplate.hydrate()
	}

	async mergeResidentResponse(response: IResidentResponse) {
		for (let prop in response.values) {
			this.response.changeValue(prop, response.values[prop])
		}

		for (let i = 0; i < response.imageComments.length; i++) {
			let ic = response.imageComments[i]
			if (this.imageComments.find(i => i.imageId === ic.imageId)) continue
			ic.reportImageId = await reportService
				.saveImageComment(this.reportId, {
					imageId: ic.imageId,
					htmlValue: ic.htmlValue,
					textValue: ic.textValue,
				})
				.then(r => r.data)
			this.imageComments.push(ic)
		}

		this.response.hydrate()
		this.validate()
	}

	applyResidentResponse(response: IResidentResponse) {
		if (this.response) {
			for (let prop in response.values) {
				this.response.changeValue(prop, response.values[prop])
			}
			this.imageComments = response.imageComments
			this.appliedResidentResponseId = response.id
			this.response.hydrate()
		}
	}

	setCanRespond() {
		const isRespondingAsResident = user.claims.isConsultantResident && this.residentResponses.length
		const isResidentResponseSubmitted = this.appliedResidentResponse && this.appliedResidentResponse.status > 0

		this.canRespond =
			!this.isComplete &&
			user.claims.isConsultantUser &&
			(!this.groupConsultantId || user.claims.userId === this.lockedByUserId || isRespondingAsResident) &&
			!isResidentResponseSubmitted
		if (this.response) {
			this.response.root.readOnly = !this.canRespond
		}
	}

	get appliedResidentResponse(): IResidentResponse {
		return this.residentResponses.find(rr => rr.id === this.appliedResidentResponseId)
	}

	get isSalesEntry() {
		return this.consultant.type === 'Repository'
	}

	get isStandardReport() {
		return this.consultantReportId === null
	}

	validateImages() {
		if (this.consultant && this.consultant.isImageOptional) return true
		let excludedMap = {}
		this.seriesToExclude.forEach(s => (excludedMap[s] = true))

		const hasImages = this.images.some(i => !excludedMap[i.fakeSeriesId || i.seriesId])
		const hasAttachments = this.attachments.some(i => !excludedMap[i.seriesId])

		if (!hasImages && !hasAttachments && !this.isPreview) {
			this.validationErrors.push('At least one image or attachment must be included in the report.')
		}
	}

	validateFields(template: Template) {
		let controls: Cell[] = []
		template.root.getControlsDeep(controls)

		let missingReqData = false
		for (let i = 0; i < controls.length; i++) {
			let c = controls[i]

			// dont validate field form another template (Request Info)
			if (c.template === template) {
				if (c.props.required === true && !c.hasValue) {
					missingReqData = true
				}

				if (c.onValidate) c.onValidate()
			}
		}

		if (missingReqData) {
			this.validationErrors.push('Please complete all required fields.')
		}

		this.validatePartnerData(controls)
	}

	validatePartnerData(controls: Cell[]) {
		if (!this.maxPartnerData) return true
		let map = this.activeTemplate.partnerFieldMap
		if (!map) return true

		let length = 0
		let partnerFieldPaths = {}

		for (let field in map) {
			map[field].forEach(p => (partnerFieldPaths[p] = true))
		}

		controls.forEach(c => {
			if (partnerFieldPaths[c.path] !== undefined) {
				textdiv.innerHTML = c.value
				length += textdiv.innerText.length
			}
		})

		if (length > this.maxPartnerData) {
			let over = length - this.maxPartnerData
			this.validationErrors.push(
				`The total length for partner fields cannot exceed ${this.maxPartnerData}. You are currently ${over}	character${
					over > 1 ? 's' : ''
				} over.`
			)
		}
	}

	validateClient() {
		let valid = this.client && validator.hasRequired(this.client, ['email', 'name', 'organization'])
		if (!valid) {
			this.validationErrors.push('Please complete all required fields.')
		}
	}

	validate() {
		if (this.skipValidation) return
		if (!this.activeTemplate) return
		this.validationErrors = []

		this.validateFields(this.activeTemplate)

		if (this.activeTemplate === this.request) {
			this.validateClient()
			this.validateImages()
		}

		this.isValid = this.validationErrors.length === 0
		return this.isValid
	}

	setActiveTemplate(template: Template) {
		this.activeTemplate = template
		this.validate()
	}

	static async loadFromScheduleItemReport(json: IConsultantReport): Promise<ReportDetail> {
		let response = json.response
		let result = new ReportDetail()
		result.canRespond = true
		result.skipValidation = true
		result.consultant = json.consultant
		result.consultantReportId = json.id
		result.billingCodeId = json.billingCodeId
		result.billingCodes = json.billingCodes

		let meta = await reportService.getTemplateMeta()
		let template = Template.load(response.template, false, meta)

		result.response = template
		template.id = response.template.templateFieldId
		template.templateId = response.template.templateId
		template.reportId = response.reportId
		template.values = response.values
		template.report = result

		result.response.hydrate()
		result.setActiveTemplate(template)
		return result
	}

	static loadFromStdReport(json: IStandardReport, hasWritePermission: boolean) {
		let result = new ReportDetail()
		result.reportId = json.reportId
		result.patient = json.patient
		result.client = json.client
		result.images = json.images
		result.attachments = json.attachments
		result.imageComments = json.imageComments
		result.addendums = json.addendums
		result.studyIds = json.studyIds
		result.study = json.study
		result.isComplete = json.completed
		result.finalizedBy = json.finalizedBy
		result.ownerId = json.ownerId
		result.templateSetName = json.templateSetName
		result.billingCodeId = json.billingCodeId
		result.billingCodes = json.billingCodes

		let template = Template.load(json.template, false)

		template.reportId = json.reportId
		template.values = json.values
		template.report = result
		if (result.isComplete || !hasWritePermission) {
			template.root.readOnly = true
		}
		template.hydrate()

		result.setActiveTemplate(template)
		return result
	}

	static loadFromConsultantReport(json: IConsultantReport): ReportDetail {
		let result = new ReportDetail()
		result.reportId = json.request.reportId
		result.consultantReportId = json.id
		result.maxPartnerData = json.maxPartnerData
		result.addendums = json.addendums
		result.imageComments = json.imageComments
		result.images = json.images
		result.relatedReports = json.relatedReports
		result.patient = json.patient
		result.consultant = json.consultant
		result.drafter = json.drafter
		result.client = json.client
		result.attachments = json.attachments
		result.studyIds = json.studyIds
		result.study = json.study
		result.services = json.services
		result.emails = json.emails
		result.consultants = json.consultants
		result.canViewResponse = json.canViewResponse
		result.forceConsultantHeader = json.forceConsultantHeader

		result.isLocked = json.isLocked
		result.isComplete = json.isComplete
		result.canRecall = json.canRecall
		result.canDelete = json.canDelete
		result.completedBy = json.completedBy
		result.completedDateTime = json.completedDateTime
		result.groupConsultantId = json.groupConsultantId
		result.lockedByUserId = json.lockedByUserId
		result.lockedBy = json.lockedBy
		result.areImagesAvailable = json.areImagesAvailable
		result.lockedDateTime = json.lockedDateTime

		result.priorities = json.priorities
		result.priorityInfo = json.priorityInfo
		result.priority = json.priority
		result.billingCodeId = json.billingCodeId
		result.billingCodes = json.billingCodes

		result.residentResponses = json.residentResponses || []

		let templates: ITemplate[] = [json.request.template]
		if (json.response) {
			templates.push(json.response.template)
		}

		templates.forEach(t => {
			let template = Template.load(t, false)
			let isRequest = t === json.request.template
			let report: IReport = isRequest ? json.request : json.response

			if (isRequest) {
				result.request = template
			} else {
				result.response = template
			}

			template.id = report.template.templateFieldId
			template.templateId = report.template.templateId
			template.reportId = report.reportId
			template.values = report.values
			template.report = result
		})

		result.request.hydrate()
		if (result.response) {
			result.response.hydrate()
		}

		return result
	}
}

export const TemplateSetStatus = {
	Draft: 'Draft',
	Published: 'Published',
	Retired: 'Retired',
	Deleted: 'Deleted',
}

export class TemplateSet {
	id: string
	name: string
	type: string // 'teleconsultation|report'
	modifiedDate: string = null
	status: string = null
	isLegacy: boolean = false
	templates: Template[] = []
	consultantId?: string
	apiWarnings: string[] = []

	get isActive(): boolean {
		return this.status === TemplateSetStatus.Published || this.isLegacy
	}

	set isActive(value: boolean) {
		this.status = value ? TemplateSetStatus.Published : TemplateSetStatus.Draft
	}

	get warnings(): string[] {
		let result: string[] = [...this.apiWarnings, ...new Set(...this.templates.map(t => t.warnings))]
		return result
	}

	get isStandard(): boolean {
		return this.type !== 'Teleconsultation'
	}

	static load(json: ITemplateSet, editorMode = false, meta?: IMeta): TemplateSet {
		let set = new TemplateSet()
		set.id = json.id
		set.consultantId = json.consultantId
		set.name = json.name
		set.type = json.type
		set.status = json.status
		set.isLegacy = json.isLegacy
		set.modifiedDate = json.modifiedDate
		json.templates.forEach(t => {
			let template = Template.load(t, editorMode, meta)
			set.templates.push(template)
		})

		let context = {
			Report: set.getTemplate('Report'),
			Request: set.getTemplate('Request'),
			Response: set.getTemplate('Response'),
		}

		set.templates.forEach(t => t.hydrate(context))
		return set
	}

	getTemplate(type: string): Template {
		return this.templates.find(t => t.type === type)
	}

	toJSON(): ITemplateSet {
		return {
			id: this.id,
			consultantId: this.consultantId,
			name: this.name,
			type: this.type,
			modifiedDate: this.modifiedDate,
			status: this.status,
			isLegacy: this.isLegacy,
			templates: this.templates.map(t => t.toJSON()),
		}
	}
}

export class Template {
	id: string // this is the ReportTemplateField.Id
	templateId: string // this is the ReportTemplate.Id
	metaVersion: number
	type: string
	isLegacy: boolean
	root: Layout = null
	pageHeader: Layout = null
	pageFooter: Layout = null
	layouts: Layout[] = []
	editorMode: boolean = false
	values: ValueMap = {}
	calcValues: ValueMap = {}
	pendingChanges = false
	reportId: string = null // Report.Id
	context: ValueMap = {}
	report: ReportDetail = null
	meta: IMeta
	partnerFieldMap: PartnerFieldMap = {}
	partnerLayoutMap: PartnerLayoutMap = {}
	draftWatermark: string = null
	watermarkUrl: string = null
	watermarkOpacity: number = 10
	imageCommentColumns: number = 1
	onChangeCB: () => void

	constructor(type: string, meta: IMeta) {
		if (meta === undefined) throw new Error('Missing template meta')
		this.type = type
		this.meta = meta
	}

	get isPdfTemplate() {
		return this.type === 'PDF Report' || this.type === 'Standard PDF Report'
	}

	resetLayout(name: string): Layout {
		let widget = this.meta.layouts.find(w => w.name === name)
		if (!widget) return
		let newLayout = Layout.load(widget, this)

		for (let i = this.layouts.length - 1; i >= 0; i--) {
			let l = this.layouts[i]
			if (l.name === name) {
				const isRoot = l.template.root === l
				this.layouts.splice(i, 1, newLayout)
				if (isRoot) newLayout.template.root = newLayout
				return newLayout
			}
		}
	}

	changeValue(path: string, newValue: any) {
		if (path !== undefined) {
			this.values[path] = newValue
			this.calc(path)
		}

		if (this.report) {
			this.report.validate()
		}
		this.pendingChanges = true
		if (this.onChangeCB) this.onChangeCB()
	}

	saveValues(): ValueMap {
		this.values = {}
		this.root.saveValues(this.values)
		return this.values
	}

	addLayout(layout: Layout) {
		layout.template = this
		this.layouts.push(layout)
	}

	removeLayout(layout: Layout) {
		layout.template = null
		this.layouts.splice(this.layouts.indexOf(layout), 1)
	}

	getLayout(type: string): Layout {
		return this.layouts.find(l => l.name === type)
	}

	createLayoutInstance(type: string): Layout {
		let l = this.getLayout(type)
		if (l === undefined) {
			// see if there is a know global widit template
			// copy global widgets to template
			let w = this.meta.layouts.find(w => w.name === type)
			if (w !== undefined) {
				l = Layout.load(w, this)
				this.addLayout(l)
			} else {
				throw new Error('unable to locate specificed layout: ' + type)
			}
		}
		return l.createInstance()
	}

	hasBindablePath(path: string): boolean {
		const widgetPath = path.split('.')
		let layout = this.root // default to control's root layout
		if (widgetPath.length > 1) {
			// if termNoPrefix still has a dot, path must be inside a widget
			layout = this.layouts.find(l => l.name === widgetPath[0])
			if (!layout) return false
		}
		return layout.bindableControls.some(c => c.path === path)
	}

	static load(json: ITemplate, editorMode: boolean, meta?: IMeta): Template {
		let t = new Template(json.type, json.meta || meta)
		t.id = json.templateFieldId
		t.isLegacy = json.isLegacy === true
		t.templateId = json.templateId
		t.partnerFieldMap = json.partnerFieldMap || {}
		t.partnerLayoutMap = json.partnerLayoutMap || {}
		t.draftWatermark = json.draftWatermark || null
		t.watermarkUrl = json.watermarkUrl || null
		t.watermarkOpacity = json.watermarkOpacity || 10
		t.imageCommentColumns = json.imageCommentColumns || 1
		t.editorMode = editorMode
		json.layouts.forEach(l => {
			let layout = Layout.load(l, t)
			layout.template = t
			t.layouts.push(layout)
		})

		t.pageHeader = t.layouts.find(l => l.name === json.header)
		t.pageFooter = t.layouts.find(l => l.name === json.footer)
		t.root = t.layouts.find(l => l.name === json.root)
		return t
	}

	get warnings(): string[] {
		let result: string[] = []

		this.layouts.forEach(l =>
			l.controls.forEach(c => {
				if (c.binding && !c.binding.valid) {
					const bindingErrors = c.binding.errors.join(' ')
					result.push(`${c.name} binding is invalid: ${bindingErrors}`)
				}
			})
		)
		return result
	}

	toJSON(): ITemplate {
		return {
			templateId: this.templateId,
			templateFieldId: this.id,
			type: this.type,
			metaVersion: this.meta.version,
			root: this.root.name,
			header: this.pageHeader && this.pageHeader.name,
			footer: this.pageFooter && this.pageFooter.name,
			layouts: this.layouts.map(l => l.toJSON()),
			partnerFieldMap: this.partnerFieldMap,
			partnerLayoutMap: this.partnerLayoutMap,
			draftWatermark: this.draftWatermark,
			watermarkUrl: this.watermarkUrl,
			watermarkOpacity: this.watermarkOpacity,
			imageCommentColumns: this.imageCommentColumns,
		}
	}

	hydrate(c: ValueMap = undefined) {
		const report = this.report || <ReportDetail>{}
		this.context = {
			...c,
		}

		if (report.completedDateTime) {
			this.context.CompletionDate = report.completedDateTime
		}

		if (report.client) {
			this.context.ReferredBy = {
				Address: report.client.address,
				City: report.client.city,
				State: report.client.state,
				PostalCode: report.client.postalCode,
				Email: report.client.email,
				Name: report.client.name,
				Organization: report.client.organization,
				Phone: report.client.phone,
			}
		}

		if (report.consultant) {
			this.context.Consultant = {
				Address: report.consultant.address,
				City: report.consultant.city,
				State: report.consultant.state,
				Email: report.consultant.email,
				Name: report.consultant.name,
				Organization: report.consultant.organization,
				Phone: report.consultant.phone,
				Website: report.consultant.website,
			}
		}

		if (report.drafter) {
			this.context.DraftedBy = {
				Name: report.drafter.name,
				Label: report.drafter.label,
			}
		}

		if (report.patient) {
			this.context.Patient = {
				Birthdate: report.patient.birthdate,
				Breed: report.patient.breed,
				Gender: report.patient.gender,
				Name: report.patient.name,
				Owner: report.patient.owner,
				PatientId: report.patient.id,
				Species: report.patient.species,
				Weight: report.patient.weight,
				WeightUnit: report.patient.weightUnit,
			}
		}

		if (report.finalizedBy) {
			this.context.FinalizedBy = {
				FullName: report.finalizedBy.fullName,
				FirstName: report.finalizedBy.firstName,
				LastName: report.finalizedBy.lastName,
				Organization: report.finalizedBy.organization,
				Address: report.finalizedBy.address,
				City: report.finalizedBy.city,
				State: report.finalizedBy.state,
				Zip: report.finalizedBy.zip,
				Phone: report.finalizedBy.phone,
				Website: report.finalizedBy.website,
				Email: report.finalizedBy.email,
				Suffix: report.finalizedBy.suffix,
				Degree: report.finalizedBy.degree,
				Country: report.finalizedBy.country,
				Title: report.finalizedBy.title,
			}
		}

		if (report.study) {
			this.context.Study = {
				StudyDate: report.study.studyDate,
				// Map full ModalityName to Modality key for backwards compatibility
				Modality: report.study.modalityName,
			}
		}

		if (report.billingCodeId) {
			let bc = report.billingCodes.find(b => b.id === report.billingCodeId)
			if (bc) {
				this.context.BillingCode = {
					Name: bc.name,
					Value: bc.value,
				}
			}
		}

		if (report.standard && this !== report.standard) {
			this.context.Report = this.report.standard
		}

		if (report.request && this !== report.request) {
			this.context.Request = this.report.request
		}

		if (report.response && this !== report.response) {
			this.context.Response = this.report.response
		}

		this.root.hydrate()
	}

	calc(changedPath?: string) {
		this.root.calc(changedPath)
	}

	getValue(path: string): any {
		let value = this.values[path]
		if (!value && value !== 0) value = this.calcValues[path]
		return value
	}
}

export class Layout {
	selected: boolean = false
	name: string = null
	root: Panel = null
	readOnly: boolean = false
	template: Template = null
	parent: Cell = null
	forcePath: string = null
	group: string = null
	previewInEditor: boolean = false

	// used for databinding
	context: ValueMap = {}

	constructor(name: string, template: Template) {
		this.name = name || getNewWidgetName()
		this.template = template
		this.root = new Panel('Panel', this, this.name)

		function getNewWidgetName(): string {
			let newWidgetName: string
			let newWidgetNumber = 1
			do {
				newWidgetName = `${template.type} Widget ${newWidgetNumber}`
				newWidgetNumber++
			} while (template.layouts.some(l => l.name === newWidgetName))
			return newWidgetName
		}
	}

	// get an array of control directly on this layout
	get controls(): Cell[] {
		let result: Cell[] = []
		result.push(this.root)
		Layout.getControls(this.root, result)
		return result
	}

	get report(): ReportDetail {
		return this.template.report
	}

	get activeRoot() {
		return this.report && this.report.activeTemplate.root === this
	}

	get bindableControls(): Cell[] {
		return this.controls.filter(c => c.def.isBindable)
	}

	static getControls(panel: Panel, result: Cell[]) {
		panel.cells.forEach(c => {
			result.push(c)
			if (c instanceof Panel) {
				Layout.getControls(c, result)
			}
		})
	}

	getControlsDeep(list: Cell[]) {
		this.controls.forEach(c => {
			list.push(c)
			c.subLayouts.forEach(l => l.getControlsDeep(list))
		})
	}

	get editorMode(): boolean {
		return this.template.editorMode && !this.readOnly
	}

	get isRoot(): boolean {
		return this.template.root === this
	}

	get path(): string {
		if (this.forcePath) return this.forcePath

		if (this.parent) {
			let idx = this.parent.subLayouts.indexOf(this)
			return `${this.parent.path}.[${idx}]`
		} else if (!this.isRoot) {
			return this.name
		}
		return ''
	}

	hydrate() {
		this.controls.forEach(c => c.hydrate())
	}

	createId(type: string) {
		// iterate cells in layout, create map of used names
		const usedIds = {}

		this.controls.forEach(c => {
			usedIds[c.name] = true
		})

		let index = 0
		let result = null
		do {
			index++
			result = `${type.replace(/[^a-z0-9-_]/gi, '')}${index}`
		} while (usedIds[result] !== undefined)

		usedIds[result] = true
		return result
	}

	createCell(type: string, panel: Panel, index?: number): Cell {
		let id = this.createId(type)
		let cell = type === 'Panel' || type === 'Table' ? new Panel(type, this, id) : new Cell(type, this, id)
		panel.addCell(cell)
		if (index !== undefined) {
			panel.moveCellToIndex(cell, index)
		}
		return cell
	}

	rename(name: string) {
		if (
			this.template.root.controls.some(c => c.name.toLowerCase() === name.toLowerCase()) ||
			this.template.layouts.some(l => l.name.toLowerCase() === name.toLowerCase())
		) {
			throw new Error(`The name "${name}" is already in use.`)
		}
		const templateTypes = [].concat(...this.template.meta.sets.map(s => s.templates))
		const ContextMetaKeys = Object.keys(ContextMeta).map(k => k.toLowerCase())
		if (
			ContextMetaKeys.includes(name.toLowerCase()) ||
			METHODS[name.toUpperCase()] !== undefined ||
			templateTypes.some(t => t.toLowerCase() === name.toLowerCase())
		)
			throw new Error(`The name "${name}" is reserved and cannot be used.`)
		this.name = name
	}

	saveValues(values: { [key: string]: any }) {
		if (this.template !== this.report.activeTemplate) return

		this.controls.forEach(c => {
			if (c.onSave) {
				c.onSave(values)
				return
			}
			if (c.userValue || values[c.path]) {
				values[c.path] = c.userValue
			}
			c.subLayouts.forEach(s => s.saveValues(values))
		})
	}

	calc(changedPath?: string) {
		this.controls.forEach(c => c.calc(changedPath))
	}

	createInstance(): Layout {
		let json = this.toJSON()
		return Layout.load(json, this.template)
	}

	static load(layout: ILayout, template: Template): Layout {
		let l = new Layout(layout.name, template)
		l.group = layout.group

		l.root = Cell.load(layout.panel, l) as Panel
		l.controls
			.filter(c => !c.name)
			.forEach(c => {
				c.name = l.createId(c.type)
			})
		return l
	}

	addImageCommentBinding() {
		if (this.name !== 'Image Comment') return
		const controls = this.controls
		if (controls.some(c => c.props.binding === 'ImageComment.Comment')) return
		const richText = controls.find(c => c.type === 'Rich Text Editor')
		if (!richText) return
		richText.props.binding = 'ImageComment.Comment'
	}

	toJSON(): ILayout {
		this.addImageCommentBinding()
		return {
			name: this.name,
			group: this.group && this.group,
			panel: this.root.toJSON(),
		}
	}
}

export class Cell {
	id: number = nextCellId++
	name: string = null
	type: string = null
	panel: Panel = null
	props: { [key: string]: any } = {}
	subLayouts: Layout[] = []
	userValue: any = null
	bindingValue: any = null
	el?: HTMLElement
	onSave: (values: ValueMap) => void
	onValidate: () => void
	binding?: Binding = null
	layout: Layout = null
	isPanel?: boolean

	constructor(type: string, layout: Layout, name: string) {
		this.type = type
		this.name = name
		this.layout = layout

		let def = this.def
		if (def) {
			def.props.forEach(p => {
				let value = p.value
				if (typeof value === 'object') {
					value = JSON.parse(JSON.stringify(value))
				}
				this.props[p.name] = value
			})
		}
	}

	get isTableCell(): boolean {
		return this.panel && this.panel.type === 'Table'
	}

	get isRoot(): boolean {
		return this instanceof Panel ? this.layout.root === this : false
	}

	get value(): any {
		if (this.userValue != null && (typeof this.userValue !== 'string' || this.userValue.length > 0))
			return this.userValue
		else return this.bindingValue
	}

	get isValueOverridden(): boolean {
		const hasBindingValue = this.bindingValue || this.bindingValue === 0
		const hasUserValue = this.userValue || this.userValue === 0
		return hasBindingValue && hasUserValue && this.bindingValue.toString() !== this.userValue.toString()
	}

	set value(newValue: any) {
		this.userValue = newValue
		// eslint-disable-next-line
		if (this.values[this.path] != newValue) {
			this.template.changeValue(this.path, newValue)
		}
	}

	get index(): number {
		return this.panel ? this.panel.cells.indexOf(this) : 0
	}

	get last(): boolean {
		return this.panel && this.panel.cells[this.panel.cells.length - 1] === this
	}

	get primitive(): boolean {
		return !!this.template.meta.primitives.find(p => p.name === this.type)
	}

	get subLayout(): Layout {
		return this.subLayouts[0]
	}

	get def(): IPrimitive {
		return this.template.meta.primitives.find(p => p.name === this.type)
	}

	get widgets(): Layout[] {
		return this.template.layouts.filter(l => l.group === this.type)
	}

	get editWidget(): string {
		if (this.def) {
			return this.def.editWidget
		} else {
			return this.type
		}
	}

	get context(): any {
		return { ...this.template.context, ...this.layout.context }
	}

	get values(): ValueMap {
		return this.layout.template.values
	}

	get template(): Template {
		return this.layout.template
	}

	get report(): ReportDetail {
		return this.template.report
	}

	get editorMode(): boolean {
		return this.layout.template.editorMode
	}

	get readOnly(): boolean {
		return this.layout.readOnly || this.props.readOnly === true
	}

	get hasValue(): boolean {
		let value = this.value
		if (!this.value && this.value !== 0) return false

		if (this.type === 'Rich Text Editor') {
			textdiv.innerHTML = value
			let trimValue = textdiv.innerText.trim()
			return trimValue.length > 0
		} else if (typeof value === 'string') {
			value = value.trim()
			if (value.length === 0) return false
		}

		return true
	}

	get validationWarning(): string {
		if (this.template.editorMode) return
		const isBlank = !this.hasValue

		if (this.props.required && isBlank) return 'This field is required.'
		if (!this.props.validation || isBlank) return
		const isNumber = /^(\d|\.|\,|\s|-)+$/.test(this.value) // eslint-disable-line
		if (this.props.validation === 'Number' && !isNumber) return 'Please enter numbers only.'
		return null
	}

	hydrate() {
		this.value = this.values[this.path]
		this.subLayouts.forEach(l => l.hydrate())
		if (this.props.binding) {
			this.binding = new Binding(this)
		}
		this.calc()
	}

	remove() {
		for (const field in this.template.partnerFieldMap) {
			const controls = this.template.partnerFieldMap[field]
			let idx = controls.indexOf(this.name)
			if (idx >= 0) controls.splice(idx, 1)
		}
		this.panel.removeCell(this)
	}

	addSubLayout(layout: Layout, context?: ValueMap) {
		this.subLayouts.push(layout)
		if (layout.template == null || layout.template === this.layout.template) {
			layout.parent = this
			layout.template = this.layout.template
		}

		if (context) {
			layout.context = context
		}
		layout.hydrate()
	}

	getContextValue(path: string): any {
		let context = this.context
		let parts = path.split('.')
		let value = context
		parts.forEach(p => {
			value = value == null ? null : value[p]
		})

		return value
	}

	get path(): string {
		let root = this.layout.path
		if (root) {
			return `${root}.${this.name}`
		} else {
			return this.name
		}
	}

	rename(name: string) {
		let oldPath = this.path
		var dependents: Cell[] = []

		if (
			this.layout.controls.some(c => c.name.toLowerCase() === name.toLowerCase()) ||
			this.template.layouts.some(l => l.name.toLowerCase() === name.toLowerCase())
		) {
			throw new Error(`The name "${name}" is already in use.`)
		}
		const templateTypes = [].concat(...this.template.meta.sets.map(s => s.templates))
		const ContextMetaKeys = Object.keys(ContextMeta).map(k => k.toLowerCase())
		if (
			ContextMetaKeys.includes(name.toLowerCase()) ||
			METHODS[name.toUpperCase()] !== undefined ||
			templateTypes.some(t => t.toLowerCase() === name.toLowerCase())
		)
			throw new Error(`The name "${name}" is reserved and cannot be used.`)

		// update bindings that reference this cell
		this.template.layouts.forEach(l => {
			l.controls.forEach(c => {
				if (c !== this && c.binding) {
					if (c.binding.dependencyMap[oldPath] !== undefined) {
						dependents.push(c)
					}
				}
			})
		})

		this.name = name
		dependents.forEach(d => d.binding.replaceDependency(oldPath, this.path))
	}

	calc(changedPath?: string) {
		if (this.binding) {
			if (changedPath === undefined || this.binding.hasDependency(changedPath)) {
				let currentValue = this.bindingValue
				this.bindingValue = this.binding.getValue()
				this.template.calcValues[this.path] = this.bindingValue
				if (currentValue !== this.bindingValue) this.template.calc(this.path)
			}
		}
		this.subLayouts.forEach(s => s.calc(changedPath))
	}

	static load(i: ICell, layout: Layout): Cell {
		let cell =
			i.type === 'Panel' || i.type === 'Table' ? new Panel(i.type, layout, i.name) : new Cell(i.type, layout, i.name)

		for (let prop in i.props) {
			cell.props[prop] = i.props[prop]
		}

		if (cell instanceof Panel) {
			let p = cell as Panel
			let json: IPanel = i as IPanel
			json.cells.forEach(c => p.addCell(Cell.load(c, layout)))
		}

		return cell
	}

	get borders(): any {
		if (this.props.borderColor) {
			return {
				border: `2px solid var(--report-bg-${this.props.borderColor})`,
			}
		}
		let tableprops = this.panel.props
		let sides = tableprops.border === 'sides'
		let all = tableprops.border === 'all'
		if (!sides && !all) return {}
		let color = 'black'
		if (tableprops.borderColor) {
			color = `var(--report-bg-${tableprops.borderColor}`
		}

		let x = this.props.col
		let y = this.props.row
		let columns = tableprops.cols.length
		let rows = tableprops.rows

		let left = all ? true : x === 0
		let top = all ? true : y === 0
		let right = x === columns - 1
		let bottom = y === rows - 1

		// draw borders on top left, if bottom draw bottom, if right draw right

		return {
			borderLeft: left ? `2px solid ${color}` : undefined,
			borderTop: top ? `2px solid ${color}` : undefined,
			borderRight: right ? `2px solid ${color}` : undefined,
			borderBottom: bottom ? `2px solid ${color}` : undefined,
		}
	}

	toJSON(): ICell {
		let def = this.def
		let defMap: { [key: string]: IProp } = {}
		if (def) {
			def.props.forEach(p => (defMap[p.name] = p))
		}

		let result: ICell = {
			name: this.name,
			type: this.type,
			props: {},
		}
		for (let prop in this.props) {
			let value = this.props[prop]
			let defprop = defMap[prop]
			if (defprop) {
				if (defprop.value === value) continue
			} else {
				if (value == null || value === '') continue
			}
			result.props[prop] = value
		}
		return result
	}
}

export class Panel extends Cell {
	cells: Cell[] = []

	constructor(type: string, layout: Layout, name: string) {
		super(type, layout, name)
		this.isPanel = true
	}

	addCell(cell: Cell) {
		if (cell.panel) {
			cell.panel.removeCell(cell)
		}
		cell.panel = this
		this.cells.push(cell)
		if (this.props.tableCell === true) {
			let index = this.index
			let siblings: Cell[] = []
			// its parent is the column
			let col = this.panel
			let table = col.panel
			table.cells.forEach(c => {
				if (c instanceof Panel) {
					siblings.push(c.cells[index])
				}
			})

			setTimeout(() => {
				let h = this.el.clientHeight
				siblings.forEach(s => (s.props.height = h))
			}, 1)
		}
	}

	removeCell(cell: Cell) {
		this.cells.splice(this.cells.indexOf(cell), 1)
		cell.panel = null
	}

	get firstCell(): Cell {
		return this.cells.length ? this.cells[0] : null
	}

	get lastCell(): Cell {
		return this.cells.length ? this.cells[this.cells.length - 1] : null
	}

	cellAbove(cell: Cell, allowNested: Boolean): Cell {
		let result = null
		let { row, col } = cell.props
		const { rows } = this.props
		if (!rows) {
			const index = this.cells.indexOf(cell)
			if (index > 0) {
				result = this.cells[index - 1]
			}
		} else {
			row--
			result = this.cells.find(c => c.props.row === row && c.props.col === col)
		}
		if (!result) {
			if (this.isPanel) {
				return this
			}
		} else if (result.isPanel && result.props.rows && allowNested) {
			result = (result as Panel).lastCell
		}
		result = result || (this.panel ? this.panel.cellAbove(this, false) : this.lastCell)
		return result === cell ? null : result
	}

	cellBelow(cell: Cell, allowNested: Boolean): Cell {
		let result = null
		if (cell.isPanel && cell.props.rows && allowNested) {
			result = (cell as Panel).firstCell
		} else {
			let { row, col } = cell.props
			const { rows } = this.props
			if (!rows) {
				const index = this.cells.indexOf(cell)
				if (index < this.cells.length - 1) {
					result = this.cells[index + 1]
				}
			} else {
				row++
				result = this.cells.find(c => c.props.row === row && c.props.col === col)
			}
		}
		result = result || (this.panel ? this.panel.cellBelow(this, false) : this.firstCell)
		return result === cell ? null : result
	}

	cellLeft(cell: Cell): Cell {
		let { row, col } = cell.props
		const { cols } = this.props
		if (!cols) return null
		col--
		return this.cells.find(c => c.props.col === col && c.props.row === row)
	}

	cellRight(cell: Cell): Cell {
		let { row, col } = cell.props
		const { cols } = this.props
		if (!cols) return null
		col++
		return this.cells.find(c => c.props.col === col && c.props.row === row)
	}

	get active(): boolean {
		return this.layout.selected
	}

	moveCell(cell: Cell, overCell: Cell) {
		let idx = this.cells.indexOf(overCell)
		this.moveCellToIndex(cell, idx)
	}

	moveCellToIndex(cell: Cell, newIndex: number) {
		let idx = this.cells.indexOf(cell)
		this.cells.splice(idx, 1)
		if (newIndex === undefined) {
			this.cells.push(cell)
		} else {
			this.cells.splice(newIndex, 0, cell)
		}
	}

	setColumnWidth(cols: number[], index: number, width: number) {
		let bIndex = index === cols.length - 1 ? index - 1 : index + 1
		let remaining = 0
		for (let i = 0; i < cols.length; i++) remaining += i === index || i === bIndex ? 0 : cols[i]

		if (width < 10) width = 10
		let abWidth = 100 - remaining
		let bWidth = abWidth - width
		if (bWidth < 10) {
			bWidth = 10
			width = abWidth - bWidth
		}

		cols[index] = width
		cols[bIndex] = bWidth
	}

	configureTable(columns: number = undefined, rows: number = undefined) {
		let cols: number[] = this.props.cols.slice(0)

		if (columns === undefined) columns = cols.length
		if (rows === undefined) rows = this.props.rows

		if (columns !== cols.length) {
			cols = []
			for (let i = 0; i < columns; i++) cols.push(0)
		}

		// distribute columns if outside bounds
		let sum = 0
		cols.forEach(c => (sum += c))
		if (sum !== 100) {
			const autoPercent = Math.floor(100 / cols.length)
			let remainder = 100 % autoPercent
			cols.forEach((c, i) => {
				const shareOfRemainder = i >= cols.length - remainder ? 1 : 0
				let newWidth = autoPercent + shareOfRemainder
				cols[i] = newWidth
			})
		}

		this.props.cols = cols
		this.props.rows = rows

		// create map of all the cells we have
		let used: { [key: string]: Cell } = {}
		let valid: Cell[] = []
		let deleteIdx = 0

		this.cells.sort((a, b) => {
			let diff = a.props.col - b.props.col
			if (diff === 0) {
				diff = a.props.row - b.props.row
			}
			return diff
		})

		this.cells.forEach(cell => {
			let cspan: number = cell.props.colspan
			let rspan: number = cell.props.rowspan
			let c: number = cell.props.col
			let r: number = cell.props.row

			// adjust spans to be inbounds
			while (cspan > 1 && cspan + c > columns) cspan--
			while (rspan > 1 && rspan + r > rows) rspan--
			if (cspan < 1) cspan = 1
			if (rspan < 1) rspan = 1

			cell.props.colspan = cspan
			cell.props.rowspan = rspan

			for (let x = 0; x < cspan; x++) {
				for (let y = 0; y < rspan; y++) {
					let key = `${c + x}_${r + y}`
					if (used[key] !== undefined) key += `_delete[${deleteIdx++}]`
					used[key] = cell
				}
			}
		})

		// create missing cells
		for (let y = 0; y < rows; y++) {
			for (let x = 0; x < columns; x++) {
				let key = `${x}_${y}`

				let c: Cell = used[key]
				if (c === undefined) {
					c = this.layout.createCell('Panel', this)
					c.props.row = y
					c.props.col = x
					c.props.rowspan = 1
					c.props.colspan = 1
				}

				valid.push(c)
			}
		}

		// delete the invalid cells
		for (let prop in used) {
			let cell = used[prop]
			if (!valid.includes(cell)) cell.remove()
		}
	}

	toJSON(): IPanel {
		let json = <IPanel>super.toJSON()
		json.cells = this.cells.map(c => c.toJSON())
		return json
	}
}
