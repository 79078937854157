import axios from 'axios'

// Ping active clinic or clinic list every 5 minutes
const CLINIC_AVAILABLE_CHECK_TIME = 1000 * 60 * 5
let scanInterval

interface IAvailableResult {
	sameSubnet: boolean
	available: boolean
}

class ClinicAPI {
	firstScanPromise: Promise<void> = null
	activeClinicUrl: string = null
	clinicUrls = []
	enabled = false

	startScanning() {
		this.stopScanning()
		this.enabled = window.config.pingLocalServerStrategy !== 'never'
		if (this.enabled) {
			this.setActiveClinicUrl()
			scanInterval = setInterval(this.setActiveClinicUrl.bind(this), CLINIC_AVAILABLE_CHECK_TIME)
		}
	}

	get importMachineName() {
		if (!this.activeClinicUrl) return ''
		return new URL(this.activeClinicUrl).hostname
	}

	setClinicUrls(urls) {
		this.clinicUrls = urls || []
		this.setActiveClinicUrl()
	}

	async setActiveClinicUrl() {
		// Comment this line for development purposes only
		if (!this.enabled) return

		let urls = this.activeClinicUrl ? [this.activeClinicUrl] : this.clinicUrls
		let p = this.scanClinicUrls(urls).then(r => {
			this.activeClinicUrl = r
		})

		if (!this.firstScanPromise) {
			this.firstScanPromise = p
		}
	}

	async scanClinicUrls(clinicUrls: string[]): Promise<string> {
		for (let i = 0; i < clinicUrls.length; i++) {
			if (!this.enabled) break
			let baseUrl = clinicUrls[i]
			// Don't allow https: => http: in the browser
			// Omni Desktop will always report 'file:' or 'http:'
			if (window.location.protocol === 'https:' && baseUrl.startsWith('http:')) continue
			try {
				let result: IAvailableResult = await axios.get(`${baseUrl}/available.aspx`).then(r => r.data)
				if (result.available) {
					return baseUrl
				}
			} catch {}
		}
		return null
	}

	stopScanning() {
		clearInterval(scanInterval)
		this.enabled = false
	}
}

export const clinicAPI = new ClinicAPI()
window.clinicAPI = clinicAPI
