import api from '@services/api'
import { consts } from '@vtk'
import keyboard from '@services/keyboard'
import { eventBus } from '@services/eventBus'

const state = {
	hotkeys: [],
	allHotkeys: [],
}
const getters = {
	hotkey: state => name => state.hotkeys.find(h => h.name === name).key,
}

const mutations = {
	// bind hotkeys
	// name: hotkey name (as defined in defaults)
	// method: the method the hotkey should invoke
	bindHotkeys: (state, { hotkeyActions, hotkeySettings }) => {
		// reset bindings
		state.hotkeys.forEach(({ keyBinding, method }) => keyboard.unbind(keyBinding, method))
		state.hotkeys = []
		hotkeySettings.sort((a, b) => {
			if (a.description < b.description) return -1
			if (a.description > b.description) return 1
			return 0
		})
		state.allHotkeys = hotkeySettings

		hotkeyActions.forEach(action => {
			let hotkey = hotkeySettings.find(key => key.name === action.name)
			if (hotkey !== undefined && hotkey.keyBinding !== 'None') {
				let didBind = keyboard.bind(hotkey.keyBinding, action.method)
				didBind &&
					state.hotkeys.push({
						...hotkey,
						method: action.method,
						group: action.group,
					})
			}
		})
	},
	resetBindings: state => {
		keyboard.reset()
	},
}

const actions = {
	setHotkeys({ dispatch, rootGetters }, ga) {
		if (rootGetters.isAuthenticated) {
			dispatch('setHotkeysForAuthenticatedUser', ga)
		} else {
			dispatch('setHotkeysForUnauthenticatedUser', ga)
		}
	},

	async setHotkeysForAuthenticatedUser(store, ga) {
		const response = await api.user.getHotkeySettings()
		const hotkeySettings = response.data.data
		const hotkeyActions = getHotkeyActions(store, ga)

		store.commit('bindHotkeys', { hotkeyActions, hotkeySettings })
	},
	setHotkeysForUnauthenticatedUser(store, ga) {
		let hotkeySettings = getHotkeyDefaults()
		let hotkeyActions = getHotkeyActions(store, ga)

		store.commit('bindHotkeys', { hotkeyActions, hotkeySettings })
	},

	setMPRHotkeys({ dispatch, rootGetters }, ga) {
		if (rootGetters.isAuthenticated) {
			dispatch('setMPRHotkeysForAuthenticatedUser', ga)
		} else {
			dispatch('setMPRHotkeysForUnauthenticatedUser', ga)
		}
	},

	async setMPRHotkeysForAuthenticatedUser(store, ga) {
		const response = await api.user.getHotkeySettings()
		const hotkeySettings = response.data.data
		const hotkeyActions = getMPRHotkeyActions(store, ga)

		store.commit('bindHotkeys', { hotkeyActions, hotkeySettings })
	},
	setMPRHotkeysForUnauthenticatedUser(store, ga) {
		let hotkeySettings = getHotkeyDefaults()
		let hotkeyActions = getMPRHotkeyActions(store, ga)

		store.commit('bindHotkeys', { hotkeyActions, hotkeySettings })
	},

	async modifyHotkeyAction({ dispatch }, data) {
		const response = await api.user.updateHotkey(data)
		if (response.status === 200) {
			const type = 'setHotkeysForAuthenticatedUser'
			dispatch(type)
			eventBus.broadcast(eventBus.type.VUEX_ACTION, { type })
		} else {
			// TODO: display a modal error
			console.error('modify hotkey failed', response)
		}
	},
}

function getHotkeyActions({ dispatch, commit, rootGetters } = {}, ga) {
	return [
		{
			name: 'FlipHorizontal',
			method: () => dispatch('toggleCanvasHorizontalFlip'),
			group: 'image-tools',
		},
		{
			name: 'FlipVertical',
			method: () => dispatch('toggleCanvasVerticalFlip'),
			group: 'image-tools',
		},
		{
			name: 'Copy',
			method: () => dispatch('copyCanvasAsync'),
			group: 'misc-tools',
		},
		{
			name: 'Print',
			method: () => dispatch('printCanvasAsync'),
			group: 'misc-tools',
		},
		{
			name: 'Save',
			method: () => dispatch('saveCanvasAsync'),
			group: 'misc-tools',
		},
		{
			name: 'Invert',
			method: () => dispatch('toggleCanvasInvert'),
			group: 'image-tools',
		},
		{
			name: 'RotateCW',
			method: () => dispatch('rotateCanvas', 90),
			group: 'image-tools',
		},
		{
			name: 'RotateCCW',
			method: () => dispatch('rotateCanvas', -90),
			group: 'image-tools',
		},
		{
			name: 'ResetImage',
			method: () => dispatch('resetActiveCanvas'),
			group: 'image-tools',
		},
		{
			name: 'WindowLevel',
			method: () => setAndSynchronizeTool('Wwwc', 'img', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'ZoomImage',
			method: () => setAndSynchronizeTool('Zoom', 'img', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'Pan',
			method: () => setAndSynchronizeTool('Pan', 'img', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'Roll',
			method: () => setAndSynchronizeTool('Rotate', 'img', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'StackScroll',
			method: () => setAndSynchronizeTool('StackScroll', 'img', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'ZoomArea',
			method: () => setAndSynchronizeTool('Magnify', 'img', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'Zoom100',
			method: () => dispatch('toggleZoom1to1'),
			group: 'canvas-tools',
		},
		{
			name: 'SeriesSyncManual',
			method: () => dispatch('toggleStackSynchronizationMethod', 'manual'),
			group: 'canvas-tools',
		},
		{
			name: 'SeriesSync',
			method: () => dispatch('toggleStackSynchronizationMethod', 'auto'),
			group: 'canvas-tools',
		},
		{
			name: 'SingleImage',
			method: () => dispatch('toggleCanvasFullscreen'),
			group: 'canvas-tools',
		},
		{
			name: 'Overlay',
			method: () => commit('TOGGLE_OVERLAY_TEXT'),
			group: 'misc-tools',
		},
		{
			name: 'ReferenceLines',
			method: () => dispatch('toggleReferenceLineSynchronization'),
			group: 'misc-tools',
		},
		{
			name: 'NextImage',
			method: () => dispatch('nextImage'),
			group: 'navigation-tools',
		},
		{
			name: 'PreviousImage',
			method: () => dispatch('previousImage'),
			group: 'navigation-tools',
		},
		{
			name: 'NextSeries',
			method: () => dispatch('nextSeries'),
			group: 'navigation-tools',
		},
		{
			name: 'PreviousSeries',
			method: () => dispatch('previousSeries'),
			group: 'navigation-tools',
		},
		{
			name: 'NextDisplaySet',
			method: () => dispatch('nextDisplaySet'),
			group: 'navigation-tools',
		},
		{
			name: 'PreviousDisplaySet',
			method: () => dispatch('previousDisplaySet'),
			group: 'navigation-tools',
		},
		{
			name: 'DicomTags',
			method: () => eventBus.post('toggleDicomTags'),
			group: 'misc-tools',
		},
		{
			name: 'Email',
			method: () => eventBus.post('openViewerEmail'),
			group: 'misc-tools',
		},
		{
			name: 'ReportComment',
			method: () => eventBus.post('openViewerReportComment'),
			group: 'misc-tools',
		},
		{
			name: 'ClearAnnotations',
			method: () =>
				dispatch('syncClearAnnotations', {
					activeSeries: rootGetters.activeSeries,
					activeImage: rootGetters.activeImage,
				}),
		},
	]
}

function getMPRHotkeyActions({ dispatch, commit, rootGetters } = {}, ga) {
	let hotkeyActions = [
		{
			name: 'FlipHorizontal',
			method: () => dispatch('flipHActiveView'),
			group: 'image-tools',
		},
		{
			name: 'FlipVertical',
			method: () => dispatch('flipVActiveView'),
			group: 'image-tools',
		},
		{
			name: 'Invert',
			method: () => dispatch('invertVolume'),
			group: 'image-tools',
		},
		{
			name: 'RotateCW',
			method: () => dispatch('rotateActiveViewClockwise'),
			group: 'image-tools',
		},
		{
			name: 'RotateCCW',
			method: () => dispatch('rotateActiveViewCounterClockwise'),
			group: 'image-tools',
		},
		{
			name: 'ResetImage',
			method: () => dispatch('resetViewData'),
			group: 'canvas-tools',
		},
		{
			name: 'WindowLevel',
			method: () => setAndSynchronizeTool(consts.LEVEL_TOOL, 'mpr', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'ZoomImage',
			method: () => setAndSynchronizeTool(consts.ZOOM_TOOL, 'mpr', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'Pan',
			method: () => setAndSynchronizeTool(consts.PAN_TOOL, 'mpr', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'Roll',
			method: () => setAndSynchronizeTool(consts.ROLL_TOOL, 'mpr', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'StackScroll',
			method: () => setAndSynchronizeTool(consts.SCROLL_TOOL, 'mpr', dispatch),
			group: 'canvas-tools',
		},
		{
			name: 'SingleImage',
			method: () => commit('TOGGLE_MPR_VIEW_FULLSCREEN'),
			group: 'canvas-tools',
		},
		{
			name: 'Overlay',
			method: () => commit('TOGGLE_MPR_SHOW_OVERLAY_TEXT'),
			group: 'misc-tools',
		},
		{
			name: 'NextImage',
			method: () => dispatch('nextMPRSlice'),
			group: 'navigation-tools',
		},
		{
			name: 'PreviousImage',
			method: () => dispatch('previousMPRSlice'),
			group: 'navigation-tools',
		},
		{
			name: 'NextSeries',
			method: () => dispatch('nextMPRActiveView'),
			group: 'navigation-tools',
		},
		{
			name: 'PreviousSeries',
			method: () => dispatch('prevMPRActiveView'),
			group: 'navigation-tools',
		},
		{
			name: 'Copy',
			method: () => dispatch('copyMprImageAsync'),
			group: 'misc-tools',
		},
		{
			name: 'Print',
			method: () => dispatch('printMprImageAsync'),
			group: 'misc-tools',
		},
		{
			name: 'Save',
			method: () => dispatch('saveMprImageAsync'),
			group: 'misc-tools',
		},
		{
			name: 'ClearAnnotations',
			method: () =>
				commit('CLEAR_MPR_ACTIVE_ANNOTATIONS', {
					activeSeries: rootGetters.activeSeries,
				}),
		},
	]

	return hotkeyActions
}

function setAndSynchronizeTool(tool, type, dispatch) {
	// Set the active tool
	if (type === 'img') {
		dispatch('setActiveToolAndBroadcast', { alias: tool })
	} else {
		dispatch('setMprActiveToolAndBroadcast', { tool, buttonMask: 1 })
	}
	// Synchronize the binding with the complementary viewer
	dispatch('updateMouseBinding', { tool, button: 1, type })
}

function getHotkeyDefaults() {
	return [
		{
			name: 'FlipHorizontal',
			description: 'Flip Horizontal',
			keyBinding: 'H',
		},
		{
			name: 'FlipVertical',
			description: 'Flip Vertical',
			keyBinding: 'V',
		},
		{
			name: 'Invert',
			description: 'Invert',
			keyBinding: 'I',
		},
		{
			name: 'PreviousImage',
			description: 'Move Previous',
			keyBinding: 'PageUp',
		},
		{
			name: 'NextImage',
			description: 'Move Next',
			keyBinding: 'PageDown',
		},
		{
			name: 'NextSeries',
			description: 'Move to the next series',
			keyBinding: 'Right',
		},
		{
			name: 'PreviousSeries',
			description: 'Move to the previous series',
			keyBinding: 'Left',
		},
		{
			name: 'NextDisplaySet',
			description: 'Move to the next display set',
			keyBinding: 'Right',
		},
		{
			name: 'PreviousDisplaySet',
			description: 'Move to the previous display set',
			keyBinding: 'Left',
		},
		{
			name: 'Pan',
			description: 'Pan Image',
			keyBinding: 'Q',
		},
		{
			name: 'Print',
			description: 'Print',
			keyBinding: 'P',
		},
		{
			name: 'ResetImage',
			description: 'Reset the image',
			keyBinding: 'R',
		},
		{
			name: 'RotateCW',
			description: 'Rotate Clock-wise',
			keyBinding: 'J',
		},
		{
			name: 'RotateCCW',
			description: 'Rotate Counter-clock wise',
			keyBinding: 'K',
		},
		{
			name: 'Save',
			description: 'Save',
			keyBinding: 'S',
		},
		{
			name: 'Overlay',
			description: 'Toggle Overlay',
			keyBinding: 'O',
		},
		{
			name: 'WindowLevel',
			description: 'Window level',
			keyBinding: 'L',
		},
		{
			name: 'ZoomArea',
			description: 'Zoom area',
			keyBinding: 'A',
		},
		{
			name: 'ZoomImage',
			description: 'Zoom entire image',
			keyBinding: 'Z',
		},
	]
}

// Create a list of the hotkey names that we support,
// to filter what comes from the DB and only display options Omni supports.
function getHandledHotkeyNames() {
	return [...getHotkeyActions(), ...getMPRHotkeyActions()].reduce((ret, key) => {
		ret[key.name] = true
		return ret
	}, {})
}

export const handledHotkeys = getHandledHotkeyNames()

export default {
	state,
	getters,
	mutations,
	actions,
}
