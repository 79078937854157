const videoExtensions = [
	'.3gp',
	'.3g2',
	'.asf',
	'.avi',
	'.m2v',
	'.mkv',
	'.mov',
	'.mp2',
	'.mp4',
	'.mpg',
	'.mpeg',
	'.mpeg1',
	'.mpeg2',
	'.mpeg3',
	'.mpeg4',
	'.mpv',
	'.wmv',
]

const iconNameLookup = {
	'.pdf': 'file-pdf-o',
	'.zip': 'file-archive-o',
	'.mp3': 'file-audio-o',
	'.html': 'file-code-o',
	'.xls': 'file-excel-o',
	'.xlsx': 'file-excel-o',
	'.png': 'file-image-o',
	'.jpg': 'file-image-o',
	'.jpeg': 'file-image-o',
	'.gif': 'file-image-o',
	'.mp4': 'file-movie-o',
	'.ppt': 'file-powerpoint-o',
	'.pptx': 'file-powerpoint-o',
	'.txt': 'file-text-o',
	'.mkv': 'file-movie-o',
	'.doc': 'file-word-o',
	'.docx': 'file-word-o',
	default: 'file-o',
}

function findFileIconName(extension) {
	if (extension.indexOf('.') !== 0) {
		extension = getFileExtension(extension)
	}

	if (videoExtensions.includes(extension)) return iconNameLookup['.mp4']
	let iconName = iconNameLookup[extension]
	iconName = iconName || iconNameLookup['default']
	return iconName
}

function getFileExtension(file) {
	return `.${file.split('.').pop()}`.toLowerCase()
}

function isPDF(file) {
	let ext = getFileExtension(file)
	return ext === '.pdf'
}

function isVideo(file) {
	let ext = getFileExtension(file)
	return videoExtensions.includes(ext)
}

export const FileUtils = {
	findFileIconName,
	getFileExtension,
	isPDF,
	isVideo,
}
