





























import { Cell } from '../classes'
import { ContextMeta } from '@reporting/context'
import { openAddendumDlg } from '@dialogs/AddendumDlg.vue'
import { eventBus } from '@services/eventBus'

export default {
	name: 'Addendums',
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isVisible() {
			if (this.cell.template.editorMode) return true
			const report = this.cell.report
			if (!report) return false
			if (this.cell.template.type === 'Response' && report.isPreview) return true
			if (report.isStandardReport && report.isComplete) return true
			if (report.reportId && report.reportId !== '00000000-0000-0000-0000-000000000000') return true
			return false
		},
		consultant() {
			if (!this.cell.report) return {}
			return this.cell.report.consultant
		},
	},
	created() {
		this.createSubLayouts()
		if (this.cell.template.editorMode)
			// maintain sample addendum in editor when undoing/redoing
			this.$watch('cell.report', this.createSubLayouts, { deep: true })
		else this.$watch('cell.report.addendums', this.createSubLayouts)
	},
	methods: {
		createSubLayouts() {
			let cell = this.cell
			let t = cell.template
			let addendums: IAddendum[]
			if (t.editorMode) {
				// use ContextMeta sample data
				addendums = [
					{
						fromUserDetail: ContextMeta.Addendum.items.AddedBy.sample,
						htmlValue: ContextMeta.Addendum.items.Message.sample,
						editDateTime: ContextMeta.Addendum.items.DateTime.sample,
						textValue: undefined,
					},
				]
			} else {
				addendums = cell.report.addendums
			}
			cell.subLayouts = []

			addendums.forEach(a => {
				let layout = t.createLayoutInstance('Addendum')
				layout.previewInEditor = true
				let context = {
					Addendum: {
						AddedBy: a.fromUserDetail,
						Message: a.htmlValue || a.textValue,
						DateTime: a.editDateTime,
					},
				}
				cell.addSubLayout(layout, context)
			})
		},
		async addNewAddendum() {
			let report = this.cell.report
			const addedAddendum = await openAddendumDlg(
				report.reportId,
				report.studies,
				report.isStandardReport,
				report.isComplete
			)
			if (addedAddendum) {
				eventBus.post('refresh-report')
			}
		},
	},
}
