// Google Analytics
import { event, pageview } from 'vue-gtag'

function sendGAEvent(action, eventParams) {
  event(action,eventParams)
}

function sendGAPageView(pagePath) {
  pageview(pagePath)
}

const googleAnalytics = {
  sendGAEvent,
  sendGAPageView
}

export default googleAnalytics
