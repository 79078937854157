import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { eventBus } from '@services/eventBus'

export function toggleImageOrientationMarkers({ state, dispatch }) {
	const isEnabled = !state.settingsPanel.isImageOrientationMarkersEnabled
	const action = 'setImageOrientationMarkers'
	dispatch(action, isEnabled)
	eventBus.broadcast(eventBus.type.VUEX_ACTION, { type: action, payload: isEnabled })
}

export function setImageOrientationMarkers({ commit }, value) {
	if (value) {
		cornerstoneTools.setToolEnabled('AstOrientationMarkers')
	} else {
		cornerstoneTools.setToolDisabled('AstOrientationMarkers')
	}
	commit('SET_IMAGE_ORIENTATION_MARKERS', value)
}
