const VueNotifications = {
	install(Vue, { store }) {
		// Writing a VueJS Plugin: https://vuejs.org/v2/guide/plugins.html#Using-a-Plugin
		// Dynamic Module Registration (Vuex): https://vuex.vuejs.org/en/modules.html#dynamic-module-registration
		if (!store) {
			throw new Error('Please provide vuex store.')
		}

		store.registerModule('VueNotifications', {
			state: {
				pageNotifications: [],
			},
			mutations: {
				addNotification(state, notification) {
					const MAX_NOTIFICATIONS = 20
					if (state.pageNotifications.length < MAX_NOTIFICATIONS) state.pageNotifications.push(notification)
				},
				removeNotification(state, id) {
					const foundIndex = state.pageNotifications.findIndex(notification => notification.id === id)
					if (foundIndex >= 0) {
						state.pageNotifications.splice(foundIndex, 1)
					}
				},
				clearErrorNotifications(state) {
					state.pageNotifications = state.pageNotifications.filter(
						n => n.notificationType !== 'error' && n.notificationType !== 'warn'
					)
				},
			},
			actions: {
				/**
				 * Takes a notification and adds it to our list of existing notifications
				 *
				 * @param {String} notification.id
				 * @param {String} notification.message
				 * @param {String} notification.notificationType
				 * @param {Number} notification.duration
				 */
				addNotification: ({ state, commit }, notification) => {
					const existingNotificationForMessage = state.pageNotifications.find(n => n.message === notification.message)
					if (existingNotificationForMessage) commit('removeNotification', existingNotificationForMessage.id)
					notification.id = notification.id || new Date().getTime() + '-' + new Date().getUTCMilliseconds()
					commit('addNotification', notification)
				},
			},
		})

		// Add an instance method
		Vue.prototype.$notifications = {
			notificationTypes: {
				0: 'info',
				1: 'question',
				2: 'success',
				3: 'warn',
				4: 'lock',
				5: 'error',
			},
			/**
			 * Helper method for adding info notifications
			 *
			 * @param {string} message - Message to display with notification
			 * @param {number} [15] - duration Number of seconds to display notification, can set to 0 for dismiss only
			 */
			addInfo(message, duration, closeOnLogout) {
				this.add({
					type: this.notificationTypes[0], // info
					message,
					duration,
					closeOnLogout,
				})
			},
			/**
			 * Helper method for adding success notifications
			 *
			 * @param {string} message - Message to display with notification
			 * @param {number} [15] - duration Number of seconds to display notification, can set to 0 for dismiss only
			 */
			addSuccess(message, duration, closeOnLogout) {
				this.add({
					type: this.notificationTypes[2], // success
					message,
					duration,
					closeOnLogout,
				})
			},
			/**
			 * Helper method for adding warn notifications
			 *
			 * @param {string} message - Message to display with notification
			 * @param {number} [15] - duration Number of seconds to display notification, can set to 0 for dismiss only
			 */
			addWarning(message, duration, closeOnLogout) {
				this.add({
					type: this.notificationTypes[3], // warn
					message,
					duration,
					closeOnLogout,
				})
			},
			/**
			 * Helper method for adding error notifications
			 *
			 * @param {(string|object)} data - Message to display with notification
			 * @param {number} [15] - duration Number of seconds to display notification, can set to 0 for dismiss only
			 */
			addError(data, duration, closeOnLogout) {
				let errors = []

				if (data.errors) {
					errors = data.errors.map(er => `${er.field}: ${er.message}`)
				} else {
					errors.push(data || 'no error message provided')
				}

				errors.forEach(message => {
					this.add({
						// error
						type: this.notificationTypes[5],
						message,
						duration,
						closeOnLogout,
					})
				})
			},
			/**
			 * Creates a page-level notification
			 *
			 * @param {(string|object)} options - String for info message, object for specifying other properties
			 * @param {(string|number)} options.type - Notification type that matches notificationTypes enum
			 * @param {string} options.message - Message to display with notification
			 * @param {number} [15] - options.duration Number of seconds to display notification, can set to 0 for dismiss only
			 */
			add(options) {
				// Find Mesaged
				const message = options.message || options || 'no message'
				const duration = typeof options.duration === 'number' ? options.duration * 1000 : 15 * 1000
				const closeOnLogout = options.closeOnLogout || false

				// Find type
				let notificationType = this.notificationTypes[0]
				const isTypeDefined = options.type !== null && options.type !== undefined
				const isTypeNumber = isTypeDefined && typeof options.type === 'number'

				if (isTypeNumber) {
					notificationType = this.notificationTypes[options.type] // enum
				} else if (isTypeDefined) {
					notificationType = options.type // string
				}

				// Add to store
				store.dispatch('addNotification', {
					id: new Date().getTime() + '-' + new Date().getUTCMilliseconds(),
					message,
					notificationType,
					duration,
					closeOnLogout,
				})
			},
		}
	},
}

export default VueNotifications
