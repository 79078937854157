<template>
	<div class="dialog prompt">
		<modal-dialog
			:actions="actions"
			:is-open="true"
			:title="title"
			:close-text="closeText"
      :show-close-header="false"
			data-test="text-prompt-dialog"
			@close="close"
			@submit="submit"
		>
      <p>
        {{ message }}
      </p>
			<form @submit.prevent="submit">
        <ast-checkbox v-if="prompt" v-model="promptResult" v-focus >{{ prompt }}</ast-checkbox>
			</form>
		</modal-dialog>
	</div>
</template>

<script>
import ModalDialog from '@components/ModalDialog.vue'
import AstInput from '@components/Input.vue'
import AstCheckbox from '@components/Checkbox.vue'
import {dlg} from '@utils/dlgUtils'

const TextPromptAltDlg = {
	name: 'TextPromptAltDlg',
	components: {
		AstInput,
    AstCheckbox,
		ModalDialog,
	},
	props: {
		buttonLabel: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
    message: {
      type: String,
      default: '',
    },
		prompt: {
			type: String,
			default: '',
		},
		defaultValue: {
			type: Boolean,
			default: false,
		},
		requireInput: {
			type: Boolean,
			default: true,
		},
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: 'Cancel',
    },
  },
	data() {
		return {
			promptResult: false,
		}
	},
	computed: {
		actions() {
      return [
        {
          type: 'button',
          name: this.buttonLabel,
          isDisabled: false,
          emit: 'submit',
        },
      ]
		},
	},
	mounted() {
		if (this.defaultValue) this.promptResult = this.defaultValue
	},
	methods: {
		close(wasButton) {
      const response = {
        action: false,
        prompt: this.promptResult,
      }
			dlg.close(this, true, response)
		},
		submit() {
      const response = {
        action: true,
        prompt: this.promptResult,
      }
      dlg.close(this, true, response)
		},
	},
}

export default TextPromptAltDlg

/**
 * @returns a Promise that resolves to the input value, or falsy if canceled
 */
export function openPromptDlg({
	buttonLabel = 'Submit',
	title = '',
  message = '',
	prompt = '',
	defaultValue = '',
	requireInput = false,
  showCloseButton = false,
  closeText = 'Cancel',
} = {}) {
	return dlg.open(
    TextPromptAltDlg,
		{
			buttonLabel,
			title,
      message,
			prompt,
			defaultValue,
			requireInput,
      showCloseButton,
      closeText,
		},
		false
	)
}
</script>

<style lang="scss">
.prompt textarea.input {
	min-height: 5em;
}
</style>
