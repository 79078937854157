import api from '@services/api'
import { compareVersions } from '@utils/versionCompare.js'
import { openReleaseNotes } from '@dialogs/ReleaseNotes.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { showAppUpdateAvailableDlg } from '@dialogs/UpdateAppDlg'
import { omniDesktop } from '@/electron/omniDesktop'
import { eventBus } from '@services/eventBus'
import reportService from '@services/reportService'
import workflow from '@services/workflow'
import router from '@router'

/**
 * This state is intended to capture app-wide, session length settings.
 */
const perPageOptions = [10, 25, 50]

// Nag user every hour when update is available
const displayUpdateAppBannerWaitTimeMs = 3600000

const state = {
	updateAppBannerVisible: false,
	didUserDismiss: false,
	currentPage: {},
	resultsPerPage: perPageOptions[1],
	listSelectedStudyId: null,
	isPersistentDrawerOpen: true,
	isModalDrawerOpen: false,
	desktopUpdateInfo: null,
	lastActiveSetting: '',
}

const getters = {
	perPageOptions: () => perPageOptions,
}

const actions = {
	toggleDrawers({ commit }, isOpen) {
		commit('TOGGLE_PERSISTENT_DRAWER', isOpen)
		commit('TOGGLE_MODAL_DRAWER', isOpen)
	},
	async checkLastViewedVersion(store) {
		const response = await api.user.getUserSetting('lastViewedVersion', 'ReleaseNotes')
		const lastViewedVersion = response.status === 200 ? response.data : null

		// Don't pop window for dev builds
		if (!window.config.release.includes('.')) return
		// remove build from release, check if debug or some other rando string.
		if (!lastViewedVersion || compareVersions(window.config.release, lastViewedVersion) > 0) {
			setTimeout(() => openReleaseNotes(lastViewedVersion || 'LATEST', true), 3000)
		}
	},
	async setLastViewedVersion(store, lastViewedVersion) {
		let lastVer = lastViewedVersion || window.config.release

		// Don't save if you're on a debug version
		if (!lastVer.includes('.')) return
		lastVer = lastVer
			.split('.')
			.slice(0, 3)
			.join('.')
		await api.user.setUserSetting('lastViewedVersion', 'ReleaseNotes', lastVer)
	},
	showAppUpdateAvailable({ state, commit }, fromElectron = false) {
		const prevValue = state.updateAppBannerVisible
		commit('SET_APP_UPDATE_AVAILABLE', fromElectron)
		// Did the commit change the value?
		if (!prevValue && state.updateAppBannerVisible) {
			// Often called immediately after logging in, before the rest of the app loads...
			// let's give it some time
			setTimeout(() => showAppUpdateAvailableDlg(), 1000)
		}
	},
	async handleUpdateAppBannerAction({ commit }, userClickedRefresh) {
		if (userClickedRefresh) {
			// Confirm reloading if necessary
			if (!(await confirmRefresh())) return
			commit('HIDE_UPDATE_APP_BANNER')
			// Send response back to Electron if needed
			if (omniDesktop.isConnected) {
				omniDesktop.installAndRelaunch()
			} else {
				// Reload the browser (reset cache)
				window.location.reload(true)
				// Broadcast event to reload other windows
				eventBus.broadcast(eventBus.type.RELOAD, true)
			}
		} else {
			commit('HIDE_UPDATE_APP_BANNER')
			// Don't display banner again
			commit('SET_USER_DISMISSED', true)
			// ...but nag them again after some time
			setTimeout(() => {
				commit('SET_USER_DISMISSED', false)
			}, displayUpdateAppBannerWaitTimeMs)
		}

		function confirmRefresh() {
			let warning
			if (router.currentRoute.path.includes('/viewer/')) {
				warning = 'Are you sure you want to refresh?  This will reset your current layout as well as any annotations.'
			}
			if (workflow.isInProgress) {
				warning = 'Your submission is not complete.  Are you sure you want to discard your progress?'
			}
			if (reportService.hasUnsavedChanges) {
				warning = 'You have unsaved changes.  Are you sure you want to leave without saving?'
			}
			if (warning) return showConfirm(warning)
			return true
		}
	},
	async getDesktopUpdateInfo({ rootState, commit }) {
		const clinicCode = rootState.auth?.claims?.activeClinicCode
		const updateInfo = await api.clinic.getOmniDesktopUpdateInfo({
			clinicCode,
			currentVersion: '0.0.0',
			clinicBaseUrl: null,
		})
		commit('SET_DESKTOP_UPDATE_INFO', updateInfo)
	},
}

const mutations = {
	SET_APP_UPDATE_AVAILABLE: (state, fromElectron) => {
		if (state.didUserDismiss || window.opener || (window.ElectronInterface && !fromElectron)) return
		state.updateAppBannerVisible = true
	},
	HIDE_UPDATE_APP_BANNER: state => {
		state.updateAppBannerVisible = false
	},
	SET_USER_DISMISSED: (state, didUserDismiss) => {
		state.didUserDismiss = didUserDismiss
	},
	SET_RESULTS_PER_PAGE: (state, rpp) => {
		let nrpp = Number(rpp)
		if (perPageOptions.includes(nrpp)) state.resultsPerPage = nrpp
		else state.resultsPerPage = perPageOptions[0]
	},
	SET_CURRENTPAGE: (state, { route, page }) => {
		const currentPage = { ...state.currentPage }
		currentPage[route] = page
		state.currentPage = currentPage
	},
	SET_LIST_SELECTED_STUDYID: (state, studyId) => {
		state.listSelectedStudyId = studyId
	},
	TOGGLE_PERSISTENT_DRAWER(state, isOpen) {
		if (isOpen === undefined) {
			state.isPersistentDrawerOpen = !state.isPersistentDrawerOpen
		} else {
			state.isPersistentDrawerOpen = isOpen
		}
	},
	TOGGLE_MODAL_DRAWER(state, isOpen) {
		if (isOpen === undefined) {
			state.isModalDrawerOpen = !state.isModalDrawerOpen
		} else {
			state.isModalDrawerOpen = isOpen
		}
	},
	RESET_LIST_UI_CACHE(state) {
		state.currentPage = {}
		state.listSelectedStudyId = null
	},
	SET_DESKTOP_UPDATE_INFO(state, updateInfo) {
		state.desktopUpdateInfo = updateInfo
	},
	SET_LAST_ACTIVE_SETTING(state, view) {
		state.lastActiveSetting = view
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
