const SET_IMAGE_ORIENTATION_MARKERS = function(state, isEnabled) {
	state.settingsPanel.isImageOrientationMarkersEnabled = isEnabled
}

const SET_LENGTH_ANGLES = function(state, isEnabled) {
	state.settingsPanel.isLengthAnglesEnabled = isEnabled
}

const SET_REFERENCE_LINE_SYNCHRONIZATION = function(state, isEnabled) {
	state.settingsPanel.isReferenceLineSynchronizationEnabled = isEnabled
}

const SET_STACK_SCROLL_SYNCHRONIZATION_AUTO = state => {
	state.settingsPanel.isStackScrollSynchronizationEnabled = true
	state.settingsPanel.isManualStackScrollSynchronizationEnabled = false
}
const SET_STACK_SCROLL_SYNCHRONIZATION_MANUAL = state => {
	state.settingsPanel.isStackScrollSynchronizationEnabled = false
	state.settingsPanel.isManualStackScrollSynchronizationEnabled = true
}
const SET_STACK_SCROLL_SYNCHRONIZATION_OFF = state => {
	state.settingsPanel.isStackScrollSynchronizationEnabled = false
	state.settingsPanel.isManualStackScrollSynchronizationEnabled = false
}

const SET_OVERLAY_TEXT = function(state, value) {
	state.settingsPanel.overlayText = value
}

const TOGGLE_OVERLAY_TEXT = function(state) {
	state.settingsPanel.overlayText = !state.settingsPanel.overlayText
}

const SET_VIEWER_TOOLBAR_LOCATION = (state, location) => {
	state.settingsPanel.toolbarLocation = location
}

const SET_STUDY_LIST_HEADER = (state, studyTableHeaderSettings) => {

  state.tableHeadersSettings.studyListHeader = studyTableHeaderSettings
}

const SET_TELECONSULTATION_LIST_HEADER = (state, teleconsultationTableHeaderSettings) => {
  state.tableHeadersSettings.teleconsultationListHeader = teleconsultationTableHeaderSettings
}

export default {
	SET_IMAGE_ORIENTATION_MARKERS,
	SET_LENGTH_ANGLES,
	SET_REFERENCE_LINE_SYNCHRONIZATION,
	SET_STACK_SCROLL_SYNCHRONIZATION_AUTO,
	SET_STACK_SCROLL_SYNCHRONIZATION_MANUAL,
	SET_STACK_SCROLL_SYNCHRONIZATION_OFF,
	SET_OVERLAY_TEXT,
	TOGGLE_OVERLAY_TEXT,
	SET_VIEWER_TOOLBAR_LOCATION,
  SET_STUDY_LIST_HEADER,
  SET_TELECONSULTATION_LIST_HEADER
}
