<template>
	<!--Translate arrow on drag-->
	<drag-annotation
		:id="`${id}-line`"
		v-model="position"
		:group-id="groupId"
		@drag-start="draggingAll = true"
		@drag-end="draggingAll = false"
	>
		<g v-if="start && end" :style="groupStyle" data-position-ref>
			<!--Display line-->
			<line :x1="start.x" :y1="start.y" :x2="end.x" :y2="end.y" :style="strokeStyle" />
			<!--Transparent hit target line-->
			<line
				:data-id="`${id}-line`"
				:data-group-id="groupId"
				:x1="start.x"
				:y1="start.y"
				:x2="end.x"
				:y2="end.y"
				:style="strokeHitTargetStyle"
			/>
			<!--Start handle-->
			<drag-annotation
				:id="`${id}-start`"
				v-model="start"
				:group-id="groupId"
				show-precision
				@drag-start="drag.start = true"
				@drag-end="drag.start = false"
			>
				<g>
					<polygon
						:data-group-id="groupId"
						:points="
							`${start.x},${start.y} ${start.x + arrowHeadWidth},${start.y +
								arrowHeadHeight / 2} ${start.x + arrowHeadWidth},${start.y - arrowHeadHeight / 2}`
						"
						:style="arrowHeadStyle"
						:transform="arrowHeadTransform"
					/>
					<polygon
						:data-id="`${id}-start`"
						:data-group-id="groupId"
						:points="
							`${start.x},${start.y} ${start.x + arrowHeadWidth},${start.y +
								arrowHeadHeight / 2} ${start.x + arrowHeadWidth},${start.y - arrowHeadHeight / 2}`
						"
						:style="arrowHeadHitTargetStyle"
						:transform="arrowHeadTransform"
					/>
				</g>
			</drag-annotation>
			<!--End handle-->
			<drag-annotation
				v-model="end"
				:group-id="groupId"
				:color="activeColor"
				show-precision
				drag-on-load
				@drag-start="drag.end = true"
				@drag-end="drag.end = false"
				@radius-updated="setHandleRadius('end', $event)"
			/>
			<!--Text label-->
			<text-annotation
				v-if="statsVisible"
				v-model="text"
				:group-id="groupId"
				:event-source="textSource"
				:color="activeColor"
			/>
		</g>
	</drag-annotation>
</template>

<script>
import DragAnnotation from './DragAnnotation'
import TextAnnotation from './TextAnnotation'
import { startEndMixin } from './mixins'
import { openPromptDlg } from '@dialogs/TextPromptDlg'

export default {
	name: 'ArrowAnnotation',
	components: {
		DragAnnotation,
		TextAnnotation,
	},
	mixins: [startEndMixin],
	data() {
		return {
			arrowHeadWidth: 10,
			arrowHeadHeight: 8,
			text: '',
			hasPrompted: false,
		}
	},
	computed: {
		arrowHeadTransformOrigin() {
			return `${0.5 * this.arrowHeadWidth}px, ${0.5 * this.arrowHeadHeight}px`
		},
		arrowHeadStyle() {
			return {
				...this.fillStyle,
				transformOrigin: this.arrowHeadTransformOrigin,
			}
		},
		arrowHeadHitTargetStyle() {
			return {
				...this.fillHitTargetStyle,
				transformOrigin: this.arrowHeadTransformOrigin,
			}
		},
		arrowHeadTransform() {
			if (!this.start || !this.end) {
				return ''
			}
			const height = this.end.y - this.start.y
			const width = this.end.x - this.start.x
			if (width === 0) {
				return ''
			}
			const rotation = Math.atan(height / width) * (180 / Math.PI) + (width < 0 ? 180 : 0)
			return `rotate(${rotation},${this.start.x},${this.start.y})`
		},
	},
	watch: {
		async 'drag.end'(val, oldVal) {
			if (oldVal && !val && !this.hasPrompted) {
				this.hasPrompted = true
				await this.promptForAnnotationText()
			}
		},
	},
	methods: {
		async promptForAnnotationText() {
			const input = await openPromptDlg({ title: 'Annotation', prompt: 'Enter your annotation:' })
			if (input) {
				this.text = input
			}
		},
		getTextAnchorData() {
			return {
				points: [this.start, this.pointBetween(this.start, this.end), this.end],
				anchor: this.handleOffsets.end,
				alignVertical: 'middle',
			}
		},
	},
}
</script>
