export default function(state, { studies, reportId }) {
	if (!studies || !studies.length) return
	// Get studies that don't already exist in the viewer state
	const newStudies = studies.slice().filter(study => {
		return !state.studies.some(existingStudy => existingStudy.studyId === study.studyId)
	})
	// Link the report to each study
	if (reportId) newStudies.forEach(study => (study.reportId = reportId))
	state.studies = state.studies.concat(newStudies)
}
