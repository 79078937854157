<template>
	<div
		class="report-textbox"
		:class="{ stack: cell.props['label position'] === 'Left' }"
		:title="cell.isValueOverridden ? 'Calculated value is being overridden.' : ''"
	>
		<label v-if="cell.props.label" :style="labelStyle">{{ cell.props.label }}</label>
		<input v-if="cell.editorMode" :placeholder="placeholder" class="input" disabled />
		<input
			v-else
			v-model="cellValue"
			:readonly="cell.readOnly"
			:placeholder="placeholder"
			class="input"
			:class="{ 'is-overridden': cell.isValueOverridden }"
		/>
		<button
			v-if="cell.isValueOverridden"
			class="reset-override"
			title="Clear manual entry"
			@click="cell.userValue = null"
		>
			<svg-icon icon="undo" />
		</button>
	</div>
</template>

<script>
export default {
	name: 'Textbox',
	props: ['cell'],
	computed: {
		placeholder() {
			const { editorMode } = this.cell.template
			const { binding, placeholder } = this.cell.props
			if (binding) {
				return editorMode ? `<${binding}>` : ''
			}
			return placeholder
		},
		labelStyle() {
			if (this.cell.props['label position'] !== 'Left') {
				return {
					display: 'block',
					marginBottom: '8px',
				}
			}
			return {}
		},
		cellValue: {
			get() {
				if (typeof this.cell.value === 'number')
					return this.$options.filters.formatNumber(this.cell.value)
				return this.cell.value
			},
			set(value) {
				if (this.cell.props.validation === 'Number') {
					if (!value && value !== 0) value = null // prevent persisting as 0 when user clears value
					this.cell.value = this.$options.filters.parseNumber(value, { fallback: null })
				} else {
					this.cell.value = value
				}
			},
		},
	},
}
</script>

<style lang="scss">
.report-textbox {
	padding: 4px 0;
	.reset-override {
		top: 4px;
		right: 4px;
	}
	input.is-overridden {
		box-shadow: inset 3px 0 0 0 var(--icon-warning);
	}
}
</style>
