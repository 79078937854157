<template>
	<modal-wrapper :close-on-escape="false" @close="close">
		<div class="dialog settings-dialog">
			<dlg-header :title="activeSettingView && !mq.small ? activeSettingView.title : 'Settings'" @close="close">
				<toolbar-button v-if="showBackButton" class="back-button" icon="arrow-left" @click.native="goBack()" />
			</dlg-header>
			<div class="settings-body">
				<nav v-if="!activeSettingView && !mq.small" class="mobile-nav">
					<template v-for="[group, views] in visibleSettingsGroups">
						<span :key="group + '-m'">{{ group | capitalize }}</span>
						<a
							v-for="settingView in views"
							:key="settingView.name"
							:class="{ 'active-view': settingView === activeSettingView }"
							:title="settingView.description"
							@click="pushSettingView(settingView.name)"
						>
							<span class="setting-title">{{ settingView.title }}</span>
							<span class="setting-description">{{ settingView.description }}</span>
						</a>
					</template>
				</nav>
				<nav v-if="mq.small" class="desktop-nav">
					<template v-for="[group, views] in visibleSettingsGroups">
						<span :key="group">{{ group | capitalize }}</span>
						<a
							v-for="settingView in views"
							:key="settingView.name"
							:class="{ 'active-view': settingView === activeSettingView }"
							:title="settingView.description"
							@click="replaceSettingView(settingView.name)"
						>
							{{ settingView.title }}
						</a>
					</template>
				</nav>
				<main v-if="activeSettingView" id="settings-main">
					<h3 style="margin-bottom: 16px;">{{ activeSettingView.title }}</h3>
					<component
						:is="activeSettingView.component"
						v-bind="activeSettingView.name === viewName ? viewProps : localViewProps"
						@switch-view="onSwitchView"
					/>
				</main>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import { mapMutations } from 'vuex'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import ToolbarButton from '@components/ToolbarButton.vue'
import checkSafeToLogout from '@/utils/checkSafeToLogout'
import { omniDesktop } from '@/electron/omniDesktop'
import { showConfirm } from './ConfirmDlg.vue'

const settingViews = [
	{
		title: 'Auto-Complete',
		name: 'auto-complete',
		group: 'user',
		conditions: ['isOmniUser'],
		description: 'Manage auto-complete settings',
		component: () => import(/* webpackChunkName: "autoCompleteSettingView" */ '@/settings/AutoComplete.vue'),
	},
	{
		title: 'Hotkey Configuration',
		name: 'hotkey-configuration',
		group: 'user',
		conditions: ['viewerSettingsAccess'],
		description: 'Manage Viewer keybinds',
		component: () =>
			import(/* webpackChunkName: "HotkeyConfiguration" */ '@/settings/ViewerConfiguration/HotkeyConfiguration.vue'),
	},
	{
		title: 'Navigation Menu',
		name: 'navigation-menu',
		group: 'user',
		conditions: ['isOmniUser'],
		description: 'Customize the items displayed in the navigation menu',
		component: () => import(/* webpackChunkName: "HotkeyConfiguration" */ '@/settings/NavigationMenu.vue'),
	},
	{
		title: 'Viewer Configuration',
		name: 'viewer-configuration',
		group: 'user',
		conditions: ['viewerSettingsAccess'],
		description: 'Manage Modality specific settings',
		component: () =>
			import(/* webpackChunkName: "ViewerConfiguration" */ '@/settings/ViewerConfiguration/ViewerConfiguration.vue'),
	},
	{
		title: 'Workstation Settings',
		name: 'desktop-settings',
		group: 'user',
		conditions: ['isElectron', 'isOmniUser'],
		description: 'Manage Workstation Specific Settings',
		component: () => import(/* webpackChunkName: "WorkstationView" */ '@/settings/Workstation.vue'),
	},
	{
		title: 'Teleconsultation',
		name: 'teleconsultation',
		group: 'consultation',
		conditions: ['isConsultantOrHasConsultantsForAdmin', 'isOmniUser', 'isAdvancedReportsEnabled'],
		description: 'Manage and modify teleconsultation settings and templates',
		component: () =>
			import(/* webpackChunkName: "teleconsultationSettingView" */ '@reporting/settings/Teleconsultation.vue'),
	},
	{
		title: 'Reports',
		name: 'reports',
		group: 'consultation',
		conditions: ['isOmniUser', 'isServerAdmin', 'isAdvancedReportsEnabled'],
		description: 'Manage and modify report templates',
		component: () => import(/* webpackChunkName: "reportsSettingView" */ '@reporting/settings/Reports.vue'),
	},
	{
		title: 'Repository Submission',
		name: 'repository-submission',
		group: 'consultation',
		conditions: [
			'isOmniUser',
			'isRepositorySubmissionViewer',
			'isRepositorySubmissionApproval',
			'isRepositorySubmissionSubmission',
		],
		description: 'Manage and modify repository submission settings',
		component: () =>
			import(/* webpackChunkName: "repositorySubmissionSettingView" */ '@/reporting/settings/RepositorySubmission.vue'),
	},
	{
		title: 'Billing Codes',
		name: 'billing-codes',
		group: 'consultation',
		conditions: ['isOmniUser', 'isConsultantOrServerAdmin'],
		description: 'Manage billing codes for reports',
		component: () => import(/* webpackChunkName: "billingCodesSettingView" */ '@reporting/settings/BillingCodes.vue'),
	},
	{
		title: 'Users',
		name: 'users',
		group: 'admin',
		conditions: ['isUserAdmin', 'isOmniUser'],
		description: 'Manage users and their permissions',
		component: () => import(/* webpackChunkName: "autoCompleteSettingView" */ '@/settings/Users.vue'),
	},
	{
		title: 'Schedule',
		name: 'schedule-administration',
		group: 'admin',
		conditions: ['isSchedulingAdmin', 'isSchedulingEnabled'],
		description: 'Manage schedules',
		component: () =>
			import(/* webpackChunkName: "autoCompleteSettingView" */ '@/schedule/settings/ScheduleAdministration.vue'),
	},
]

const Settings = {
	name: 'Settings',
	components: {
		DlgHeader,
		ModalWrapper,
		ToolbarButton,
	},
	props: {
		viewName: {
			type: String,
			default: null,
		},
		viewProps: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			activeSettingView: undefined,
			localViewProps: {},
		}
	},
	watch: {
		'$route.query': {
			handler(query) {
				if (query.settingView) {
					this.activeSettingView = settingViews.find(v => v.name === query.settingView)
					this.setLastActiveView(query.settingView)
				} else {
					this.setLastActiveView('')
					this.activeSettingView = undefined
					if (this.mq.small) this.close()
				}
			},
			deep: true,
		},
		'mq.small'(isSmall) {
			// Select the first setting when going from mobile to desktop settings view
			if (isSmall && !this.activeSettingView) this.replaceSettingView(this.visibleSettingViews[0].name)
		},
	},
	computed: {
		conditionsMap() {
			const { claims } = this.$store.state.auth
			const { permissions, consultantsForAdmin, consultants } = this.$store.state.static
			return {
				isConsultantOrServerAdmin: claims.isConsultantUser || permissions.serverAdministration,
				isConsultantOrHasConsultantsForAdmin:
					claims.isConsultantUser || (permissions.serverAdministration && consultantsForAdmin.length),
				isConsultantOrHasConsultants:
					claims.isConsultantUser ||
					(permissions.serverAdministration && consultantsForAdmin.length) ||
					consultants.length,
				isOmniUser: !claims.isCommunityUser && !claims.isRepositoryUser,
				isServerAdmin: permissions.serverAdministration,
				isUserAdmin: permissions.userAdministration,
				isSchedulingAdmin: permissions.schedulingAdmin,
				isAdvancedReportsEnabled: claims.isAdvancedReportsEnabled,
				viewerSettingsAccess: !claims.isCommunityUser,
				isElectron: omniDesktop.isConnected,
				isSchedulingEnabled: claims.isSchedulingEnabled,
				isRepositorySubmissionViewer: permissions.reviewSubmissionViewer,
				isRepositorySubmissionApproval: permissions.reviewSubmissionApproval,
				isRepositorySubmissionSubmission: permissions.reviewSubmissionSubmission,
			}
		},
		visibleSettingViews() {
			let conditionsMap = this.conditionsMap
			return settingViews.filter(v => {
				if (v.conditions) return v.conditions.every(c => conditionsMap[c])
				else if (v.condition) return conditionsMap[v.condition]
				else return true
			})
		},
		visibleSettingsGroups() {
			// Use the visibleSettingsViews so a user won't see category headers for empty categories.
			// Convert the flat settings array into a grouped-by-key object, then cache
			// the [key, value] entries rather than process from the object each time in v-for
			return Object.entries(
				this.visibleSettingViews.reduce((ret, view) => {
					if (!ret[view.group]) ret[view.group] = []
					ret[view.group].push(view)
					return ret
				}, {})
			)
		},
		showBackButton() {
			return this.activeSettingView && !this.mq.small
		},
	},
	created() {
		if (this.$route.query.settingView) {
			this.replaceSettingView('') // clear query param if already present, replace history stack
		}
		// if not in mobile view, open first setting view
		// OR if a specific setting view is requested, open that setting view & push stack
		const specificView = this.viewName || this.$store.state.ui.lastActiveSetting
		if (specificView || this.mq.small) {
			this.pushSettingView(specificView || this.visibleSettingViews[0].name)
		}
	},
	beforeDestroy() {
		if (this.$route.query.settingView) {
			// clear settingView query param if it's still active
			const { settingView, ...query } = this.$route.query
			if (settingView) this.$router.replace({ query })
		}
	},
	methods: {
		...mapMutations({
			setLastActiveView: 'SET_LAST_ACTIVE_SETTING',
		}),
		goBack() {
			this.$router.go(-1)
		},
		replaceSettingView(viewName, viewProps) {
			this.openSettingView(viewName, viewProps, true)
		},
		pushSettingView(viewName, viewProps) {
			this.openSettingView(viewName, viewProps, false)
		},
		openSettingView(viewName, viewProps = {}, replaceRoute = true) {
			const method = replaceRoute ? 'replace' : 'push'
			this.localViewProps = viewProps
			this.$router[method]({ query: { ...this.$route.query, settingView: viewName } })
		},
		onSwitchView(viewName, viewProps) {
			if (this.mq.small) {
				this.replaceSettingView(viewName, viewProps)
			} else {
				this.pushSettingView(viewName, viewProps)
			}
		},
		close() {
			if (this.$route.query.settingView) this.goBack() // clear settingView query param
			dlg.close(this)
			checkIfShouldRecommendReload()
		},
	},
}

async function checkIfShouldRecommendReload() {
	if (omniDesktop.isConnected) {
		if (await omniDesktop.request('getConfigIsDirty')) {
			const shouldReload = await showConfirm(
				`
				<p>It is recommended to restart Omni Desktop after changing workstation Monitor / Layout settings, so the new configuration can be applied.</p>
				<p>Would you like to restart now?</p>
				`,
				{
					title: 'Monitor Layout Settings Changed',
					confirmText: 'Restart Omni',
					cancelText: 'Cancel',
				}
			)
			if (shouldReload && (await checkSafeToLogout())) omniDesktop.request('relaunchApp')
		}
	}
}

export default Settings
export function openSettings(viewName, viewProps) {
	return dlg.open(Settings, { viewName, viewProps }, true)
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.settings-dialog {
	border: none;
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 0;
	height: 100vh;
	height: -webkit-fill-available;
	width: 100vw;
	max-width: 100vw;
	@media (min-width: $mqSmall) {
		width: 1200px;
		max-width: 90vw;
		height: 90vh;
		max-height: 90vh;
	}
	.settings-body {
		display: flex;
		flex-grow: 1;
		background: var(--primary-bg);
		border-top: 1px solid var(--primary-border);
		height: 100%;
		overflow: hidden;
		nav {
			display: flex;
			flex-direction: column;
			min-width: 200px;
			overflow: auto;
			> span {
				font-weight: bold;
				display: block;
			}
			&.mobile-nav {
				display: flex;
				width: 100vw;
				> span {
					padding: 16px 16px 0;
				}
				a {
					display: flex;
					padding: 16px;
					font-size: 1.1em;
					flex-direction: column;
					color: inherit;
					.setting-title {
						color: var(--link);
					}
					.setting-description {
						padding-top: 2px;
						font-size: 0.8em;
						opacity: 0.9;
					}
				}
				a + a,
				a + span {
					border-top: 1px solid var(--secondary-border);
				}
				a + span {
					padding-top: 24px;
				}
			}
			&.desktop-nav {
				padding: 8px 0;
				display: flex;
				background: var(--secondary-bg);
				border-right: 1px solid var(--secondary-border);
				box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

				> span {
					padding: 8px 20px;
					margin-bottom: 2px;
					font-size: 0.75em;
					text-transform: uppercase;
					letter-spacing: 0.1em;
					background: var(--nav-link-hover);
				}
				a + span {
					margin-top: 16px;
				}
				a {
					display: flex;
					align-items: center;
					min-height: 36px;
					color: var(--nav-link);
					padding: 4px 16px;
					// padding: 4px 8px 4px 20px;
					border-left: 4px solid transparent;

					&:hover {
						background: var(--nav-link-hover);
						border-left: 4px solid var(--tertiary-border);
					}
					&.active-view,
					&.active-view:hover {
						color: var(--nav-link-active);
						border-left: 4px solid var(--nav-link-active);
						cursor: default;
					}
				}
			}
		}
		main {
			padding: 16px;
			flex-grow: 1;
			overflow: auto;
			@media (max-width: $mqSmall) {
				h3 {
					display: none;
				}
			}
			h4 {
				margin-top: 24px;
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
}
</style>
