








const state = {
	message: '',
	active: false,
}

const MIN_SHOW_TIME = 1000

export default {
	name: 'Loading',
	data() {
		return {
			state,
		}
	},
	methods: {},
}

export function setLoading(message: string, promise: Promise<any>) {
	let startTime = new Date()
	state.message = message
	state.active = true
	promise.finally(() => {
		let endTime = new Date()
		var diff = endTime.getTime() - startTime.getTime()
		let ms = MIN_SHOW_TIME - diff
		setTimeout(() => {
			state.active = false
		}, ms)
	})
}
