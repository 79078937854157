import { stringToDate } from '@utils/dateUtils.js'
import { formatNumber as uFormatNumber, parseNumber as uParseNumber } from '@utils/numberUtils'
import { getLocale } from '@utils/locale'

const translations = {
	consignerStudy: {
		'en-us': 'Hip',
		'en-au': 'Lot',
	},
	consigner: {
		'en-us': 'Consigner',
		'en-au': 'Vendor',
	},
}
export const translate = key => {
	return translations[key][getLocale()] || translations[key]['en-us']
}

const DateTimeOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: '2-digit',
}
const DateTimeSecOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: '2-digit',
	second: '2-digit',
}
const TimeOptions = {
	hour: 'numeric',
	minute: '2-digit',
}
export const localizeDate = (
	dateString,
	{ showDate = true, showTime = true, forceUTC = true, showSeconds = false } = {}
) => {
	if (!dateString) return ''
	let localizedDate = dateString.getMinutes ? dateString : stringToDate(dateString, forceUTC)
	let result
	if (showDate && showTime) {
		result = localizedDate.toLocaleString(getLocale(), showSeconds ? DateTimeSecOptions : DateTimeOptions)
	} else if (showDate && !showTime) {
		result = localizedDate.toLocaleDateString(getLocale())
	} else if (!showDate) {
		result = localizedDate.toLocaleTimeString(getLocale(), TimeOptions)
	}
	return result === 'Invalid Date' ? '' : result
}

const formatNumber = (number, opts) => {
	return uFormatNumber(number, opts)
}

const parseNumber = (string, opts) => {
	return uParseNumber(string, opts)
}

export default {
	filters: { localizeDate, formatNumber, parseNumber },
	methods: { translate, getLocale },
}
