export default function(
  { state, commit, dispatch },
  { canvasIndex, domCanvas, vueCanvas }
) {
  commit('SET_CANVAS_INFO', {
    canvasIndex,
    domCanvas,
    vueCanvas,
  })

  // If we're setting the domCanvas/vueCanvas for the 0-index canvas
  // That means our Viewer Route was _just_ created. Let's set it as
  // Our active canvas
  if (canvasIndex === 0) {
    dispatch('setActiveCanvas', {
      canvasIndex,
      force: true,
    })
  }
}
