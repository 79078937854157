import { clinicAPI } from '@services/clinicAPI'
import { getLocale } from '@utils/locale'

const protocol = new URL(window.config.keystoneRootUrl).protocol
const removeProtocol = url => url.replace(/^.*\/\//, '')

function findImageUrlFromImage(image: any = {}, series: any = {}, annexOnly = false) {
	return findImageUrl(
		{
			...image,
			storageLocation: (<any>image).storageLocation || (<any>series).storageLocation,
		},
		annexOnly
	)
}

function findThumbnailUrl(thumbnail: IBaseImage) {
	return findImageUrl({
		...thumbnail,
		imageType: 'ThumbnailPNG',
	})
}

function findFileUrl(file: any = {}) {
	return findImageUrl({
		imageId: file.fileId || '',
		storageLocation: {
			clinicCode: file.clinicCode || '',
			endpointBaseUrl: file.imageEndpointBaseUrl || '',
		},
		// @ts-ignore
		imageType: file.fileType || 'PreviewImage',
	})
}

function findAttachmentUrl(attachment) {
	const keystoneRootUrl = removeProtocol(window.config.keystoneRootUrl)

	return findImageUrl({
		...attachment,
		storageLocation: {
			clinicCode: attachment.clinicCode,
			endpointBaseUrl: attachment.endpointBaseUrl || keystoneRootUrl,
		},
		imageType: 'AttachmentContents',
	})
}

/**
 * @param {object} image
 * @param {string} image.imageId
 * @param {string} [image.imageType=PreviewImage]
 * @param {number} [image.frameIndex=0]
 * @param {object} image.storageLocation
 * @param {object} image.storageLocation.endpointBaseUrl
 */
function findImageUrl(image: IBaseImage, annexOnly = false) {
	if (!image || !image.imageId) return ''

	const clinicCode = image.storageLocation.clinicCode
	let baseUrl = `${protocol}//${image.storageLocation.endpointBaseUrl}${window.config.keystoneBaseRoute}`

	if (clinicAPI.activeClinicUrl && !annexOnly) {
		baseUrl = clinicAPI.activeClinicUrl
	}

	return (
		`${baseUrl}/ImageDownload.aspx` +
		`?ClinicCode=${clinicCode}` +
		`&ImageId=${image.imageId}` +
		`&ImageType=${image.imageType || 'PreviewImage'}` +
		`&FrameIndex=${image.frameIndex || 0}` +
		`&omni=true`
	)
}

/**
 * Utility to help find the endpoint/URL that returns metadata for the provided
 * image or series at the specified storage location. imageId OR seriesId should
 * be provided.
 *
 * @param {Object} data
 * @param {string} [data.imageId]
 * @param {string} [data.seriesId]
 * @param {object} data.storageLocation
 * @param {string} data.storageLocation.endpointBaseUrl
 * @param {string} data.storageLocation.clinicCode
 * @returns {string} - The endpoint that returns metadata as JSON for the provided image/series @ storage location
 */
 function findJsonUrl(image, series) {
	const storageLocation = image.storageLocation || series.storageLocation

	let baseUrl = `${protocol}//${storageLocation.endpointBaseUrl}${window.config.keystoneBaseRoute}`

	if (clinicAPI.activeClinicUrl) {
		baseUrl = clinicAPI.activeClinicUrl
	}

	let url = `${baseUrl}/JSON.aspx?ClinicCode=${storageLocation.clinicCode}`

	if (image.imageId) {
		url += `&ImageId=${image.imageId}`
	} else if (image.seriesId) {
		url += `&SeriesId=${image.seriesId}`
	}
	return url
}

interface IPrintReportOptions {
	clinicCode: string
	reportId?: string
	consultantReportId?: string
	suppressDraftWatermark?: boolean
	useConsultantHeader?: boolean
	isResponseVisible?: boolean
	includePrivateFields?: boolean
	fontSizeAdjustment?: number
	residentResponseId?: string
}
function findReportUrl(options: IPrintReportOptions): string {
	const keystoneRootUrl = removeProtocol(window.config.keystoneRootUrl)
	const isConsultantReport = options.consultantReportId && options.consultantReportId.length > 0
	const endpoint = window.config.keystoneBaseRoute + '/PrintedReport.aspx'
	if (options.isResponseVisible === undefined) options.isResponseVisible = true
	let result =
		`${protocol}//` +
		`${keystoneRootUrl}/${endpoint}?` +
		`ClinicCode=${options.clinicCode}` + // Should always be auth'd clinic code
		`${isConsultantReport ? '&ConsultantReportId=' : '&ReportId='}` +
		`${isConsultantReport ? options.consultantReportId : options.reportId}` +
		`&IncludePrivateFields=${options.includePrivateFields ? 'True' : 'False'}` +
		`&UseConsultantHeader=${options.useConsultantHeader ? 'True' : 'False'}` +
		`&SuppressDraftWatermark=${options.suppressDraftWatermark ? 'True' : 'False'}` +
		`&FontSizeAdjustment=${options.fontSizeAdjustment || 0}` +
		`&IsResponseVisible=${options.isResponseVisible ? 'True' : 'False'}` +
		`&Culture=${getLocale()}` +
		`&TimeZoneOffset=${-new Date().getTimezoneOffset()}` +
		`&Format=PDF`

	if (options.residentResponseId) {
		result += `&ResidentResponseId=${options.residentResponseId}`
	}

	return result
}

function findImageIdAndFrameFromUrl(imageUrl) {
	let imageId
	let frameIndex
	if (imageUrl.includes('mpr')) {
		// TODO: may or may not be needed for future MPR implementation
		imageId = imageUrl.replace('mpr://', '')
		frameIndex = 0
	} else {
		const matches = imageUrl.match(/ImageId=(.{36}).*FrameIndex=([0-9]*)/)
		imageId = matches[1]
		frameIndex = parseInt(matches[2]) || 0
	}
	return {
		imageId,
		frameIndex,
	}
}

export {
	findImageUrl,
	findThumbnailUrl,
	findImageUrlFromImage,
	findImageIdAndFrameFromUrl,
	findAttachmentUrl,
	findJsonUrl,
	findFileUrl,
	findReportUrl,
}
