/** String Consts for MPR tools */

export const LEVEL_TOOL = 'LEVEL'
export const LENGTH_TOOL = 'LENGTH'
export const RECT_TOOL = 'RECT'
export const CIRCLE_TOOL = 'CIRCLE'
export const ELLIPSE_TOOL = 'ELLIPSE'
export const ANGLE_TOOL = 'ANGLE'
export const TEXT_TOOL = 'TEXT'
export const ARROW_TOOL = 'ARROW'
export const PROBE_TOOL = 'PROBE'
export const ERASER_TOOL = 'ERASER'
export const SELECT_TOOL = 'SELECT'
export const PAN_TOOL = 'PAN'
export const ZOOM_TOOL = 'ZOOM'
export const SCROLL_TOOL = 'SCROLL'
export const PLANE_TOOL = 'PLANE'
export const ROLL_TOOL = 'ROLL'
export const PRE_TOOL = 'PRE'

export const TOOLS = {
	LEVEL_TOOL,
	SELECT_TOOL,
	LENGTH_TOOL,
	RECT_TOOL,
	CIRCLE_TOOL,
	ELLIPSE_TOOL,
	ANGLE_TOOL,
	TEXT_TOOL,
	ARROW_TOOL,
	PROBE_TOOL,
	ERASER_TOOL,
	PAN_TOOL,
	ZOOM_TOOL,
	SCROLL_TOOL,
	PLANE_TOOL,
	ROLL_TOOL,
	PRE_TOOL,
}

export const BLEND_NONE = 'none'
export const BLEND_MIP = 'MIP'
export const BLEND_MINIP = 'MINIP'
export const BLEND_AVG = 'AVG'

export const FRONT = 'front'
export const TOP = 'top'
export const LEFT = 'left'

export const LEFT_MKEY = 'left'
export const MIDDLE_MKEY = 'middle'
export const RIGHT_MKEY = 'right'

export const BUTTONS = {
	LEFT_MKEY,
	MIDDLE_MKEY,
	RIGHT_MKEY,
}

export const BUTTON_MASKS = {
	1: LEFT_MKEY,
	4: MIDDLE_MKEY,
	2: RIGHT_MKEY,
}

export const VTK_BUTTONS = {
	[LEFT_MKEY]: 1,
	[MIDDLE_MKEY]: 2,
	[RIGHT_MKEY]: 3,
}

export const MOUSE_BINDINGS = {
	[LEFT_MKEY]: 'L',
	[MIDDLE_MKEY]: 'M',
	[RIGHT_MKEY]: 'R',
}

export const TOUCH_BINDINGS = {
	[LEFT_MKEY]: ' ',
}

export const ACTIONS = {
	level: LEVEL_TOOL,
	crosshair: SELECT_TOOL,
}

export default {
	TOOLS,
	LEVEL_TOOL,
	SELECT_TOOL,
	LENGTH_TOOL,
	RECT_TOOL,
	CIRCLE_TOOL,
	ELLIPSE_TOOL,
	ANGLE_TOOL,
	TEXT_TOOL,
	ARROW_TOOL,
	PROBE_TOOL,
	ERASER_TOOL,
	PAN_TOOL,
	ZOOM_TOOL,
	SCROLL_TOOL,
	PLANE_TOOL,
	ROLL_TOOL,
	PRE_TOOL,
	BUTTONS,
	BUTTON_MASKS,
	VTK_BUTTONS,
	MOUSE_BINDINGS,
	TOUCH_BINDINGS,
	LEFT_MKEY,
	MIDDLE_MKEY,
	RIGHT_MKEY,
	BLEND_AVG,
	BLEND_MINIP,
	BLEND_MIP,
	BLEND_NONE,
	FRONT,
	TOP,
	LEFT,
}
