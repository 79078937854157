// http://2ality.com/2015/02/es6-classes-final.html
export default class {
	constructor(detail, columns) {
		//
		this.isPageLoading = true
		this.isListLoading = false
		this.isDetailLodaing = false

		//
		this.list = []
		this.columns = columns
		this.numResults = 0
		this.hasMoreResults = false
		this.detail = detail
		this.emptyDetail = detail
	}
}
