<template>
	<div class="table-container" data-cy="data-table">
		<table
			class="data-table"
			:class="{
				'table--striped': isStriped,
				'table--striped-report': isReport,
				'table--row-hover': isHoverable,
				'table--condensed': isCondensed,
				'table--full-width': isFullWidth,
			}"
			:style="isLoading && { opacity: '0.3', transition: 'opacity 0.1s 0.1s' }"
		>
			<slot>
				<!-- Table content -->
				<thead>
					<tr
						:class="{
							asc: sort.isAscending && sort.isSorted,
							desc: !sort.isAscending && sort.isSorted,
						}"
					>
						<!-- Columns -->
						<th
							v-for="(column, i) in columns"
							:key="i"
							:class="{
								active: sort.orderBy === column.sortName,
								sortable: column.isSortable,
							}"
							@click.prevent="sortSelected(column)"
						>
							{{ column.name }}
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(row, rowIndex) in rows">
						<tr
							:key="`row-${rowIndex}`"
							:class="[
								expandedRow === row ? 'selected' : '',
								isExpanding && expandedRow === row ? 'expanding' : '',
								row.wasRemoved ? 'removed' : '',
								row.rowClass ? row.rowClass : '',
							]"
							@click.prevent="onRowClick(row)"
						>
							<!-- Row -->
							<slot name="row" :row="row"></slot>
						</tr>

						<!-- Expanded row -->
						<transition :key="`expanded-${rowIndex}`" name="expand">
							<tr v-if="$slots.expanded && expandedRow === row" ref="expandedRow" class="expanded">
								<td :colspan="columns.length">
									<slot name="expanded"></slot>
								</td>
							</tr>
						</transition>

						<!-- expand with two tr elements to prevent changing striping of remaining rows -->
						<tr v-if="$slots.expanded && expandedRow === row" :key="`striping-fix-${rowIndex}`" class="striping-fix">
							<td :colspan="columns.length"></td>
						</tr>
					</template>
				</tbody>
			</slot>
		</table>
	</div>
</template>

<script>
export default {
	name: 'DataTable',
	props: {
		columns: {
			type: Array,
			default: () => {
				return []
			},
		},
		rows: {
			type: Array,
			default: () => {
				return []
			},
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		sort: {
			type: Object,
			default: () => {
				return {
					orderBy: '',
					isAscending: false,
					isSorted: false,
				}
			},
		},
		collapseOnListChange: {
			type: Boolean,
			default: true,
		},
		isExpanding: {
			type: Boolean,
			default: false,
		},
		expandedRow: {
			type: Object,
			default: null,
		},
		currentPage: {
			type: Number,
			default: 0,
		},
		resultsPerPage: {
			type: Number,
			default: 0,
		},
		isStriped: {
			type: Boolean,
			default: true,
		},
		isReport: {
			type: Boolean,
			default: false,
		},
		isHoverable: {
			type: Boolean,
			default: true,
		},
		isCondensed: {
			type: Boolean,
			default: false,
		},
		isFullWidth: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			clickTimeout: null,
		}
	},
	watch: {
		rows() {
			if (this.collapseOnListChange) this.hideExpandedRow()
		},
		currentPage() {
			this.hideExpandedRow()
		},
	},
	methods: {
		hideExpandedRow() {
			this.setExpandedRow()
		},
		onRowClick(row, rowIndex) {
			const clear = () => {
				clearTimeout(this.clickTimeout)
				this.clickTimeout = null
			}
			if (this.clickTimeout) {
				// Double click
				clear()
				this.$emit('row-open', row)
			} else {
				// Single click
				const self = this
				this.clickTimeout = setTimeout(() => {
					clear()
					if (self.$slots.expanded) self.setExpandedRow(row)
					else self.$emit('row-open', row)
				}, 300)
			}
		},
		setExpandedRow(row) {
			if (this.expandedRow === row || row == null) {
				this.$emit('row-selected', undefined, this.expandedRow)
			} else {
				this.$emit('row-selected', row, this.expandedRow)
			}
		},
		sortSelected(item) {
			if (!item.isSortable) {
				return
			}

			this.hideExpandedRow()
			let orderBy = item.sortName
			let alreadyOrderedBy = orderBy === this.sort.orderBy
			let currentlyAsc = this.sort.isAscending

			// First sort click defaults
			let orderByName = orderBy
			let isAscending = true
			let isSorted = true

			// Set descending
			if (alreadyOrderedBy && currentlyAsc) isAscending = false
			else if (alreadyOrderedBy && !currentlyAsc) {
				// Remove sort
				orderByName = ''
				isSorted = false
				isAscending = false
			}

			this.$emit('sort-selected', {
				name: orderByName,
				isAscending: isAscending,
				isSorted: isSorted,
			})
		},
		getScrollParent(node) {
			if (node == null) return null
			const overflowY = node instanceof HTMLElement && window.getComputedStyle(node).overflowY
			const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden'
			if (isScrollable && node.scrollHeight > node.clientHeight) return node
			return this.getScrollParent(node.parentNode)
		},
		scrollSelectedRowIntoView(scrollToTop = false) {
			const el = this.$el.querySelector('tr.selected')
			const header = this.$el.querySelector('thead')
			const parent = this.getScrollParent(header)
			if (!el || !header || !parent) return

			// Don't do anything if element is fully visible in scroll area
			const bottomOverlap = el.offsetTop + el.clientHeight - (parent.scrollTop + parent.clientHeight)
			const topOverlap = parent.scrollTop + header.clientHeight - el.offsetTop
			if (topOverlap <= 0 && bottomOverlap <= 0) return

			// Scroll to selected row
			scrollToTop = scrollToTop || topOverlap > 0
			el.scrollIntoView(scrollToTop)

			// Offset from sticky header overlap if top aligned
			if (scrollToTop) parent.scrollTop -= header.clientHeight
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.table-container {
	min-height: 0.01%;
}
.action-button {
	color: var(--primary-label);
	display: inline-flex;
	width: 2em;
	height: 2em;
	background: transparent;
	border: 0;
	cursor: pointer;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}
</style>
