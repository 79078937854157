


















import { Layout } from '../classes'

export default {
	name: 'Layout',
	props: {
		layout: {
			type: Object,
			required: true,
		},
	},
	computed: {
		errors() {
			if (this.layout.readOnly || !this.layout.activeRoot) return []
			else return [...new Set(this.layout.report.validationErrors)]
		},
	},
}
