<template>
	<div id="user-studies-table">
		<search-and-filter
			:filter="filter"
			:filter-presets="filterPresets"
			:show-reset-button="true"
			:is-dialog="isDialog"
			@input="updateFilter($event)"
			@reset="updateFilter(filterPresets.all)"
			@reset-current-page="currentPage = 0"
		>
			<template #tags>
				<filter-tag v-if="filter.notSent" :value="filter.notSent" @input="updateFilterComponent('notSent', false)">
					Not Sent
				</filter-tag>
				<filter-tag :value="filter.modalityIds" :clear-value="[]" @input="filter.modalityIds = []">
					{{ $store.getters.modalityNamesById(filter.modalityIds).join(' / ') }}
				</filter-tag>
				<filter-tag
					v-if="filter.startDate || filter.endDate"
					:value="{ startDate: filter.startDate, endDate: filter.endDate }"
					clear-value=""
					@input="clearFilterDateRange()"
				>
					Uploaded:
				</filter-tag>
				<filter-tag
					v-if="filter.studyStartDate || filter.studyEndDate"
					:value="{ startDate: filter.studyStartDate, endDate: filter.studyEndDate }"
					clear-value=""
					@input="clearFilterDateRange('study')"
				>
					Study Date:
				</filter-tag>
			</template>
			<template #top>
				<div>
					<label>Status</label>
					<button
						class="btn"
						:class="{ 'btn-success': filter.notSent, 'btn-default': !filter.notSent }"
						@click="updateFilterComponent('notSent', true)"
					>
						Not Sent
					</button>
					<button
						class="btn"
						:class="{ 'btn-success': !filter.notSent, 'btn-default': filter.notSent }"
						@click="updateFilterComponent('notSent', false)"
					>
						All
					</button>
				</div>
			</template>
			<!-- Upload Date -->
			<date-range-picker
				label="Upload Date"
				:date-ranges="['All', 'Year', 'Month', 'Week', 'Yesterday', 'Today']"
				:filter="filter"
				@input="updateFilter($event)"
			/>
			<!-- Study Date -->
			<date-range-picker
				start-date-key="studyStartDate"
				end-date-key="studyEndDate"
				:date-ranges="['All', 'Year', 'Month', 'Week', 'Yesterday', 'Today']"
				label="Study Date"
				:filter="filter"
				@input="updateFilter($event)"
			/>
		</search-and-filter>
		<div class="table-wrapper">
			<!-- Results are loaded, no results -->
			<section v-if="!isLoading && !list.length" class="no-results">
				<em>
					No uploads found
				</em>
			</section>

			<!--Table list-->
			<data-table v-if="mq.xLarge && (list.length || isLoading)" :is-loading="isLoading" :is-hoverable="false">
				<thead>
					<tr>
						<th></th>
						<th>Upload Date</th>
						<th>Patient ID</th>
						<th>Patient Name</th>
						<th>Source</th>
						<th>Study Date</th>
						<th>Modality</th>
						<th>Images</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="study in list"
						:key="study.studyId"
						:class="{ selectable: isDialog }"
						@click="isDialog && selectStudy(study)"
					>
						<td>
							<button
								v-if="!isDialog && isStudyArchived(study)"
								class="view-button"
								@click.stop="goToStudy(study.studyId)"
							>
								<svg-icon icon="eye" />
							</button>
						</td>
						<td>{{ study.importDate | localizeDate }}</td>
						<td>{{ study.patientId }}</td>
						<td>{{ study.attachmentDescription || study.patientName }}</td>
						<td>{{ study.source }}</td>
						<td>{{ study.studyDateTime | localizeDate({ forceUTC: false }) }}</td>
						<td>{{ study.modality }}</td>
						<td>
							<div v-if="study.imagesArchived < study.imageCount" style="text-align: center;">
								<progress-bar :width="(study.imagesArchived / study.imageCount) * 100" style="min-width: 96px;" />
								<span style="font-size: 0.75em"> {{ study.imagesArchived }}/{{ study.imageCount }} </span>
							</div>
							<template v-else>
								{{ study.imageCount }}
							</template>
						</td>
						<td style="text-align: center;">
							<template v-if="!isDialog">
								<span v-if="!study.hasImagesRegistered">Receiving Images</span>
								<template v-else-if="!study.hasTeleconsultationReport">
									<button class="btn btn-primary" @click="selectStudy(study)">
										Send Images
									</button>
									<a class="is-link delete" @click="deleteStudy(study)">Delete</a>
								</template>
								<a v-else @click="selectStudy(study)">
									Send Again
								</a>
								<a v-if="study.hasErrors" class="error-tag" @click="viewErrors(study)">
									<svg-icon icon="exclamation-circle" />
									Error
								</a>
							</template>
							<template v-else>
								<span v-if="study.hasTeleconsultationReport" class="is-success">
									<svg-icon icon="check-circle" />
									Sent
								</span>
								<span v-else>Not Sent</span>
							</template>
						</td>
					</tr>
				</tbody>
			</data-table>

			<!--Tile list-->
			<div v-if="!mq.xLarge && list.length" class="tiles">
				<div
					v-for="study in list"
					:key="study.studyId"
					class="tile"
					:class="{ selectable: isDialog }"
					@click="isDialog && selectStudy(study)"
				>
					<div class="action-column">
						<button
							v-if="!isDialog && isStudyArchived(study)"
							class="view-button"
							@click.stop="goToStudy(study.studyId)"
						>
							<svg-icon icon="eye" />
						</button>
						<span v-else style="flex-grow: 1"></span>
					</div>
					<div class="info-column">
						<p>{{ study.patientId }} - {{ study.attachmentDescription || study.patientName }}</p>
						<p>
							<span style="font-weight: 200;">Source:</span>
							{{ study.source }}
						</p>
						<div>
							<div
								v-if="study.imagesArchived < study.imageCount"
								style="text-align: center; min-width: 96px; max-width: 200px;"
							>
								<progress-bar :width="(study.imagesArchived / study.imageCount) * 100" />
								<span style="font-size: 0.75em"> {{ study.imagesArchived }}/{{ study.imageCount }} </span>
							</div>
							<span v-else>
								{{ study.imageCount }}
								{{ study.imageCount === 1 ? 'Image' : 'Images' }}
								({{ study.modality }})
							</span>
							<a v-if="study.hasErrors" class="error-tag" @click="viewErrors(study)">
								<svg-icon icon="exclamation-circle" />
								Error
							</a>
						</div>
						<p style="font-size: 0.85em; font-weight: 300;">
							{{ study.studyDateTime | localizeDate }}
						</p>
					</div>
					<div class="action-column">
						<template v-if="!isDialog">
							<template v-if="!study.hasTeleconsultationReport">
								<button class="btn btn-primary" @click="selectStudy(study)">
									Send Images
								</button>
								<a class="is-link delete" @click="deleteStudy(study)">Delete</a>
							</template>
							<a v-else class="is-link" style="padding: 8px;" @click="selectStudy(study)">
								Send Again
							</a>
						</template>
						<template v-else>
							<span v-if="study.hasTeleconsultationReport" class="is-success">
								<svg-icon icon="check-circle" />
								Sent
							</span>
							<span v-else>Not Sent</span>
						</template>
					</div>
				</div>
			</div>
		</div>
		<ast-pagination
			v-show="list.length"
			:current-page="currentPage"
			:results-per-page="resultsPerPage"
			:results-length="list.length"
			:has-more-results="hasMoreResults"
			@set-results-per-page="resultsPerPage = $event"
			@set-current-page="currentPage = $event"
		/>
	</div>
</template>

<script>
import ProgressBar from '@components/ProgressBar.vue'
import { openDicomServiceLogDlg } from '@dialogs/DicomServiceLogDlg.vue'
import { getDateRanges } from '@utils/dateUtils'
import { communityService } from '@services/communityService'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import ListMixin from '@mixins/list.js'

const defaultFilter = {
	notSent: false,
	term: '',
	modalityIds: [],
	startDate: '',
	endDate: '',
	studyStartDate: '',
	studyEndDate: '',
	dateRange: null,
}
const defaultFilterString = JSON.stringify(defaultFilter)
function extendDefaultFilter(options = {}) {
	return {
		...JSON.parse(defaultFilterString),
		...options,
	}
}

export default {
	name: 'UserStudyTable',
	components: {
		ProgressBar,
	},
	mixins: [ListMixin],
	props: {
		isDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			defaultResultsPerPage: 50,
			filter: extendDefaultFilter(),
			refreshInterval: undefined,
			refreshUploadsInterval: undefined,
			uploadingStudyIds: [],
		}
	},
	watch: {
		uploadingStudyIds(currentUploads, previousUploads) {
			if (currentUploads.length && !previousUploads.length) {
				// if uploads appear, start refreshing
				clearInterval(this.refreshUploadsInterval)
				this.refreshUploadsInterval = setInterval(this.refreshUploads, 2000)
			} else if (!currentUploads.length && previousUploads.length) {
				// if no more uploads, stop refreshing
				clearInterval(this.refreshUploadsInterval)
			}
		},
	},
	mounted() {
		// default dialog to Not Sent studies
		if (this.isDialog) this.filter.notSent = true
		this.refreshInterval = setInterval(() => {
			this.refreshList(false)
		}, 30000)
	},
	beforeDestroy() {
		clearInterval(this.refreshInterval)
		clearInterval(this.refreshUploadsInterval)
	},
	methods: {
		refreshFilterPresets() {
			this.filterPresets = {
				all: extendDefaultFilter(),
				not_sent: extendDefaultFilter({ notSent: true }),
				today: extendDefaultFilter({ ...getDateRanges().Today }),
				yesterday: extendDefaultFilter({ ...getDateRanges().Yesterday }),
			}
		},
		isStudyArchived(study) {
			return study.imagesArchived && study.imagesArchived === study.imageCount
		},
		selectStudy(study) {
			this.$emit('select-study', study)
		},
		goToStudy(id) {
			let routeConfig = { name: 'studies-viewer', params: { id: id } }
			this.$router.push(routeConfig)
		},
		async deleteStudy(study) {
			if (!(await showConfirm('Are you sure you want to delete this study?'))) return
			this.isLoading = true
			try {
				await this.$api.study.deleteStudy(study.studyId)
				const studyIndex = this.list.indexOf(study)
				this.list.splice(studyIndex, 1)
			} finally {
				this.isLoading = false
			}
		},
		async refreshUploads() {
			try {
				const data = await communityService.getStudiesById(this.uploadingStudyIds)
				if (!data || !data.results) return
				data.results.forEach(study => {
					const studyIndex = this.list.findIndex(s => s.studyId === study.studyId)
					if (studyIndex >= 0) this.list.splice(studyIndex, 1, study) // update study in list
					if (study.imagesArchived >= study.imageCount) {
						// if complete, remove from uploadingStudyIds
						this.uploadingStudyIds = this.uploadingStudyIds.filter(s => s !== study.studyId)
					}
				})
			} catch (err) {
				// if the request fails, stop refreshing uploads
				clearInterval(this.refreshUploadsInterval)
			}
		},
		async fetchList() {
			const data = await communityService.getStudies(
				this.filter.startDate,
				this.filter.endDate,
				this.filter.studyStartDate,
				this.filter.studyEndDate,
				this.filter.modalityIds,
				this.filter.term,
				this.filter.notSent,
				this.currentPage,
				this.resultsPerPage
			)
			if (data) {
				this.hasMoreResults = data.hasMoreResults
				this.list = data.results
				this.uploadingStudyIds = this.list.filter(s => s.imagesArchived < s.imageCount).map(s => s.studyId)
			}
		},
		viewErrors(study) {
			openDicomServiceLogDlg(null, study.studyId)
		},
	},
}
</script>

<style lang="scss">
#user-studies-table {
	display: flex;
	flex-direction: column;
	background: var(--primary-bg);
	overflow: hidden;

	.view-button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		font-size: 14px;
		background: transparent;
		cursor: pointer;
		&:hover {
			background: var(--button-default-bg-hover);
		}
	}

	.error-tag {
		display: block;
		padding-top: 4px;
		font-weight: 400;
		color: var(--icon-danger);
		.icon {
			padding-right: 2px;
		}
		&:hover {
			text-decoration: underline;
		}
	}

	.action-column {
		align-self: stretch;
		white-space: nowrap;
		display: flex;
		flex-shrink: 1;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		text-align: center;
	}

	.delete {
		display: block;
		margin: 8px auto;
		max-width: 110px;
		padding: 8px;
		font-size: 0.8em;
		&:hover {
			background: var(--button-danger-bg);
			color: var(--button-danger-color);
		}
	}

	.tiles {
		display: flex;
		flex-direction: column;
	}

	.tile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		& > * {
			padding: 16px 8px;
		}
		.info-column {
			flex-grow: 1;
			word-break: break-word;
			& > * + * {
				padding-top: 4px;
			}
		}
		& + .tile {
			border-top: 1px solid var(--tertiary-border);
		}
		&:nth-child(odd) {
			background: var(--tertiary-bg);
		}
	}

	.selectable {
		cursor: pointer;
	}
}
</style>
