// Disable default IE help popup
// @ts-ignore
const windowF1 = window.onhelp

function removeOnHelp() {
	// @ts-ignore
	window.onhelp = function() {
		return false
	}
}
function restoreOnHelp() {
	// @ts-ignore
	window.onhelp = windowF1
}

interface IKeyMap {
	[key: string]: string | string[]
}

// Create a keymap of our known key inputs to the JS event.code value
const _keyOptionsMap: IKeyMap = [
	// Number Row
	...`1234567890`.split('').map(k => ({ [k]: 'Digit' + k })),
	// Alphabet
	...`ABCDEFGHIJKLMNOPQRSTUVWXYZ`.split('').map(k => ({ [k]: 'Key' + k })),
	// Function Keys
	...new Array(12).fill('F').map((a, i) => {
		let k = a + (i + 1)
		return { [k]: k }
	}),
	// Numpad Digits
	...`0123456789`.split('').map(n => ({ ['NumPad' + n]: 'Numpad' + n })),
	// Arrow Keys
	...['Left', 'Right', 'Up', 'Down'].map(k => ({ [k]: 'Arrow' + k })),
	// Misc Named Keys
	...[
		'Delete',
		'Insert',
		'Home',
		'End',
		'PageDown',
		'PageUp',
		'Enter',
		'Escape',
		'Space',
		'Tab',
	].map(k => ({ [k]: k })),
	// printscreen has multiple values, depending on platform
	{ PrintScreen: ['F13', 'PrintScreen'] },
].reduce(function(result, current) {
	return Object.assign(result, current)
}, {})
export const allowedKeys = Object.keys(_keyOptionsMap)
// Reverse map to match a keypress code to the saved value
const _reverseMap = Object.entries(_keyOptionsMap).reduce((acc, [key, value]) => {
	if (Array.isArray(value)) value.forEach(v => (acc[v] = key))
	else acc[value] = key
	return acc
}, {})

// TODO: make this rebindable at runtime?
const targetElement = document
// Elements that have this class will automatically allow keyboard events to be passed through;
// otherwise input element targeted events will be ignored
const skipClass = 'allow-keybinds'

const _anykeyHandlers: Function[] = []
interface IKeyEventHandler {
	[key: string]: Set<Function>
}
const _keyBindMapper: IKeyEventHandler = {}
// Is keyboard currently listening to keypress events
let _isBound = false

function _addHandler(mappedKey: string, onKey: Function) {
	if (!_keyBindMapper[mappedKey]) _keyBindMapper[mappedKey] = new Set()
	_keyBindMapper[mappedKey].add(onKey)
}

function _stopCallback(e) {
	let element = e.target
	if (element === targetElement || element === window || element === document) {
		return false
	}

	if (element.classList.contains(skipClass)) {
		return false
	}

	// *** From Mousetrap: ***
	// Events originating from a shadow DOM are re-targetted and `e.target` is the shadow host,
	// not the initial event target in the shadow tree. Note that not all events cross the
	// shadow boundary.
	// For shadow trees with `mode: 'open'`, the initial event target is the first element in
	// the event’s composed path. For shadow trees with `mode: 'closed'`, the initial event
	// target cannot be obtained.
	if ('composedPath' in e && typeof e.composedPath === 'function') {
		// For open shadow trees, update `element` so that the following check works.
		var initialEventTarget = e.composedPath()[0]
		if (initialEventTarget !== e.target) {
			element = initialEventTarget
		}
	}

	// stop for input, select, and textarea
	return (
		element.tagName === 'INPUT' ||
		element.tagName === 'SELECT' ||
		element.tagName === 'TEXTAREA' ||
		element.isContentEditable
	)
}

// Used to prevent duplicate key actions on down & up,
// because some system-reserved keys only fire on up.
interface ILastKeyMap {
	[key: string]: Boolean
}
let _lastKeyDown: ILastKeyMap = {}

function _handleKeyDown(e: KeyboardEvent) {
	_lastKeyDown[e.code] = true
	return _handleKey(e)
}
// For keys that only work on keyUp
function _handleKeyUp(e: KeyboardEvent) {
	if (_lastKeyDown[e.code]) delete _lastKeyDown[e.code]
	else {
		return _handleKey(e)
	}
}

/**
 * Will be called only once per key event. Most keys are on keyDown, but some, like PrintScreen,
 * only fire into JS on keyup. This is unified through the _handleKeyDown and _handleKeyUp handlers
 * @param {KeyboardEvent} e
 */
function _handleKey(e: KeyboardEvent) {
	// Fire for each registered "anykey" callback, if the event key is one of the allowed keys
	if (getMapping(e.code)) _anykeyHandlers.forEach(h => h(e))

	if (_stopCallback(e)) {
		return
	}
	const handlers = _keyBindMapper[e.code] || _keyBindMapper[e.key]
	if (handlers) {
		if (!e.metaKey) {
			e.preventDefault()
		}
		handlers.forEach(handler => handler(e))
		return false
	} else {
		return true
	}
}

/**
 * Attempt to coerce incorrectly cased keys
 * @param {string} key
 */
function _findMatchingKey(key: string): string | string[] {
	let mappedKey = _keyOptionsMap[key] || _keyOptionsMap[key.toUpperCase()]
	if (!mappedKey) {
		mappedKey = Object.keys(_keyOptionsMap).find(k => k.toLowerCase() === key.toLowerCase())
	}
	return mappedKey
}

/** Public Functions API */

/**
 * Bind a key, if in the _keyOptionsMap (an allowable key).
 * Will attempt to match if the key is not cased as expected.
 * Multiple listeners can be attached to the same key.
 * @returns {Boolean} true if the provided key was allowed/matched. False if not.
 */
function bind(key: string, onKey: Function): Boolean {
	const mappedKey = _findMatchingKey(key)
	if (!mappedKey) return false
	const keyArray = Array.isArray(mappedKey) ? mappedKey : [mappedKey]
	keyArray.forEach(mkey => {
		_addHandler(mkey, onKey)
	})
	return true
}

/**
 * Removes the onKey function from the set of handlers for that key, if previously set
 * @returns {Boolean} true if the onKey function was bound to that key and successfully removed, false if not bound
 */
function unbind(key: string, onKey: Function): Boolean {
	const mappedKey = _findMatchingKey(key)
	if (mappedKey) {
		const keyArray = Array.isArray(mappedKey) ? mappedKey : [mappedKey]
		let wasMapped
		keyArray.forEach(mkey => {
			if (_keyBindMapper[mkey]) {
				wasMapped = true
				_keyBindMapper[mkey].delete(onKey)
			}
		})
		return wasMapped
	}
	return false
}

/**
 * Will fire the handler for a given key, as if the key had been pressed.
 * @returns true if the key had a bound handler, false if not
 */
function trigger(key: string): Boolean {
	const mappedKey = _keyOptionsMap[key]
	if (!mappedKey) return false
	const keyArray = Array.isArray(mappedKey) ? mappedKey : [mappedKey]
	let triggered = false
	keyArray.forEach(mkey => {
		if (_keyBindMapper[mkey]) {
			_keyBindMapper[mkey].forEach(onKey => onKey())
			triggered = true
		}
	})
	return triggered
}

/**
 * Resets keyboard service by deleting all keybind map references
 */
function reset() {
	for (var member in _keyBindMapper) delete _keyBindMapper[member]
}

/**
 * Start keyboard listeners on the defined target element, which defaults to `document`
 */
function startListening() {
	if (!_isBound) {
		targetElement.addEventListener('keydown', _handleKeyDown)
		targetElement.addEventListener('keyup', _handleKeyUp)
		_isBound = true
	}
}

/**
 * Stops listening for keyboard events, but keeps the keybinds in memory
 */
function stopListening() {
	if (_isBound) {
		targetElement.removeEventListener('keydown', _handleKeyDown)
		targetElement.removeEventListener('keyup', _handleKeyUp)
		_isBound = false
	}
}

/**
 * Register a callback to be fired on any keypress
 */
function onKeypress(handler: Function) {
	if (_anykeyHandlers.indexOf(handler) < 0) {
		_anykeyHandlers.push(handler)
	}
}

/**
 * Deregister a registered onbKeypress callback
 */
function offKeypress(handler: Function) {
	_anykeyHandlers.splice(_anykeyHandlers.indexOf(handler), 1)
}

function getMapping(code: string) {
	return _reverseMap[code]
}

export default {
	bind,
	unbind,
	trigger,
	reset,
	startListening,
	stopListening,
	isListening: () => _isBound,
	removeOnHelp,
	restoreOnHelp,
	onKeypress,
	offKeypress,
	getMapping,
}

// Initialize
removeOnHelp()
startListening()
