let iframe = null

export default async function printImage(imgBase64, dispatch) {
	if (!iframe) {
		let iframe = document.createElement('iframe')
		iframe.id = 'printf'
		iframe.name = 'printf'
		iframe.style.display = 'none'
		document.body.appendChild(iframe)
	}
	var newWin = window.frames['printf']
	let css = 'html,body {height: 100%;	padding: 0;	margin: 0; }	@media print {img {	max-height: 100%;	max-width: 100%;}}'
	let html = `<html><head><style>${css}</style></head><body onload="window.print()"><img style="maxWidth:100%;maxHeight:100%" src="${imgBase64}"</body></html`
	newWin.document.write(html)
	newWin.document.close()
}
