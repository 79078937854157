<template>
	<div class="report-cb">
		<ast-checkbox
			v-model="cell.value"
			:disabled="cell.readOnly || cell.template.editorMode"
			:tabindex="cell.template.editorMode ? -1 : 0"
			:label-position="leftLabel ? 'left' : 'right'"
		>
			{{ cell.props.label }}
		</ast-checkbox>
	</div>
</template>

<script>
import AstCheckbox from '@components/Checkbox.vue'
export default {
	name: 'Checkbox',
	components: {
		AstCheckbox,
	},
	props: ['cell'],
	computed: {
		leftLabel() {
			return this.cell.props['label position'] === 'Left'
		},
	},
}
</script>

<style lang="scss">
.report-cb {
	> label {
		display: flex;
		align-items: center;
	}
	.control--checkbox {
		padding: 0;
		margin: 0;
	}
}
</style>
