import dicomTagEnum from './dicomTagEnum.js'
import valueTypes from './dicomTagValueTypeEnum.js'

/**
 * [Dicom Tag]{@link http://northstar-www.dartmouth.edu/doc/idl/html_6.2/DICOM_Attributes.html} item returned by server's JSON endpoint
 *
 * @typedef DicomTag
 * @param {number} Group - A group number that groups similar tags
 * @param {number} Element - The tag's "ID" within its group
 * @param {string} Value - The tag's value
 */

/**
 * Finds and parses the `tag` in the set of `dicom tags`. If it can't be found or parsed,
 * then the `default value` is returned.
 *
 * @param {DicomTag[]} dicomTags - The javascript object for the specified element in the metadata
 * @param {string} tagName - A tag name from the `dicomTagEnum`
 * @param {*} [defaultValue] - The default value to return if the element does not exist
 * @returns {*} - Found and parsed value or default
 */
export default function(dicomTags, tagName, defaultValue) {
	const options = dicomTagEnum[tagName].options || {}
	const { tag, type } = dicomTagEnum[tagName]
	const { group, element } = _parseHexDicomTagString(tag)

	const noTags = !dicomTags || dicomTags.length < 1
	if (noTags) {
		return defaultValue
	}

	// Attempt to find value
	const foundResult = dicomTags.find(dt => dt.Element === element && dt.Group === group)
	if (foundResult && 'Value' in foundResult) {
		options.defaultValue = options.defaultValue || defaultValue
		return _parseValueType(type, foundResult.Value, options)
	}

	return defaultValue
}

/**
 * Parses the value to the provided type.
 *
 * @private
 * @function _parseValueType
 *
 * @param {*} type
 * @param {*} value
 * @param {*} options
 * @returns {*} Parsed value
 */
function _parseValueType(type, value, options) {
	if (type === valueTypes.STRING) {
		return value
	}
	if (type === valueTypes.NUMBER) {
		return parseFloat(value)
	}
	if (type === valueTypes.NUMBERS) {
		const minimumLength = options.minimumLength || 2
		const valuesArray = value.split('\\')
		if (valuesArray.length < minimumLength) {
			return options.defaultValue
		}

		return valuesArray.map(parseFloat)
	}
}

/**
 * Converts concatenated hex strings into their decimal equivelant.
 * Expects a string length of 8; splits in half. First half is `group`,
 * and second half is `element`
 *
 * @private
 * @function _parseHexDicomTagString
 *
 * @param {*} hexDicomTagString
 * @returns {object} { group: number, element: number }
 */
function _parseHexDicomTagString(hexDicomTagString) {
	return {
		group: parseInt(hexDicomTagString.substring(0, 4), 16),
		element: parseInt(hexDicomTagString.substring(4), 16),
	}
}
