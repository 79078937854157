import { API } from '@services/api'

class DicomPartners {
	constructor() {
		this.configs = []
	}

	getSignalPetLog(studyId, clinicCode) {
		return API.get(`/study/signal-pet-log?studyId=${studyId}&clinicCode=${clinicCode}`).then(r => r.data)
	}
}

export const dicomPartners = new DicomPartners()
window.dicomPartners = dicomPartners

export const SIGNALPET_IMAGEVIEW_ID = 'ff3dc7fc-8dc6-4b7d-ac44-5621fefe0cd0'
