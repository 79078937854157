import { API, paramsSerializer } from '@services/api'
import store from '@store'
import { uploadData } from './uploads'
import workflow from './workflow'

export const SALE_ENTRY_STATUS = {
	1: 'success',
	2: 'successWaitingForImagesToArchive',
	3: 'missingRequiredImages',
	4: 'saleEntryAlreadyHasImages',
	5: 'studyTooOld',
	6: 'saleEntryNotFound',
	7: 'saleEntryNotOpenForSubmission',
	8: 'studyAlreadySubmittedForSale',
	9: 'invalidAttachmentFileType',
	10: 'saleNotFoundOrClosed',
	1000: 'unknownError',
}

class SalesService {
	sale: ISale = null
	saleEntry: ISaleEntry = null
	isExtraReasonRequired = false

	get saleEntryStatus(): string {
		return this.saleEntry ? SALE_ENTRY_STATUS[this.saleEntry.status] : undefined
	}

	async getTemplate({ saleId, hipNumber, studyIds }) {
		workflow.isLoading = true
		try {
			const data = await this._getTemplate({
				consultantId: workflow.consultantId,
				hipNumber,
				saleId: saleId || this.sale.id,
				saleEntryId: hipNumber ? null : this.saleEntry.id,
				studyIds,
			})
			data.patientId = data.patientId || hipNumber
			const hasServerMatches = data.images.some(image => image.imageId)

			let existingAttachments = this.saleEntry ? this.saleEntry.attachments : []
			let existingImages = this.saleEntry ? this.saleEntry.images : []

			if (data.errors && data.errors.length) {
				data.images = existingImages
				data.attachments = existingAttachments
			}
			// preserve existing manual matching
			const attachments = mergeExistingMatches(data.attachments, existingAttachments)
			const images = mergeExistingMatches(data.images, existingImages)
			this.saleEntry = { ...data, attachments, images }
			return { hasServerMatches }
		} finally {
			workflow.isLoading = false
		}

		function mergeExistingMatches(templateImages, existingImages) {
			let newImages = templateImages.slice()
			const existingMatches = existingImages.filter(image => image.imageId)
			for (let i = 0; i < existingMatches.length; i++) {
				const existingImage = existingMatches[i]
				if ('extraReason' in existingImage) {
					// If extra image, restore the extra image in the report
					clearServerMatchesForImageId(existingImage.imageId)
					newImages.push({ ...existingImage })
				} else {
					// Find this view in the refreshed template
					const newImage = newImages.find(
						image => image.reportTemplateImageViewId === existingImage.reportTemplateImageViewId
					)
					// Restore match made manually by user
					clearServerMatchesForImageId(existingImage.imageId)
					newImage.imageId = existingImage.imageId
				}
			}
			return newImages

			function clearServerMatchesForImageId(imageId) {
				const serverMatches = newImages.filter(image => image.imageId === imageId)
				serverMatches.forEach(serverMatch => (serverMatch.imageId = null))
			}
		}
	}

	reset() {
		this.sale = null
		this.saleEntry = null
	}

	async submitSaleEntry(): Promise<boolean> {
		workflow.isLoading = true
		const sale = this.sale
		const consultantId = workflow.consultantId
		const images = [...this.saleEntry.images, ...this.saleEntry.attachments]
			.filter(image => image.imageId)
			.map(({ extraReason, imageId, reportTemplateImageViewId }) => {
				return { extraReason, imageId, reportTemplateImageViewId }
			})
		const saleEntry = {
			consultantId,
			images,
			notificationEmails: workflow.emails,
			saleEntryId: this.saleEntry.id,
			saleId: sale.id,
			studyIds: workflow.studyIds,
		}
		try {
			const { data } = await this._submitSaleEntry(saleEntry)
			if (data.errors && data.errors.length) {
				store.dispatch('addNotification', {
					message: data.errors.join(' '),
					notificationType: 'error',
				})
			} else {
				const message = `Successfully submitted to ${sale.name}!`
				store.dispatch('addNotification', {
					message,
					notificationType: 'success',
				})
				uploadData.stopUploads({ context: 'teleconsultation-request' })
				this.reset()
				workflow.reset()
				workflow.consultantId = consultantId
				return true
			}
		} finally {
			workflow.isLoading = false
		}
	}

	getSubmissionSales(id): Promise<ISale[]> {
		return API.get(`/sales/submission-list?consultantId=${id}`).then(r => r.data.data.results)
	}

	getSalesInfo(consultantReportId: string): Promise<ISaleInfo> {
		return API.get(`/sales/info/${consultantReportId}`).then(r => r.data)
	}

	_getTemplate(params) {
		return API.get(`/sales/get-template`, {
			// @ts-ignore
			cancelPending: true,
			params,
			paramsSerializer,
		}).then(r => r.data)
	}

	imagesNotMatching(params) {
		return API.get(`/sales/images-not-matching`, {
			// @ts-ignore
			cancelPending: true,
			params,
			paramsSerializer,
		})
	}

	_submitSaleEntry(data) {
		return API.post(`/sales/submit-sale-entry`, data)
	}

	validateMetadata(params) {
		return API.get(`/sales/validate-metadata`, {
			params,
			paramsSerializer,
		}).then(r => r.data)
	}
}

export const salesService = new SalesService()
export default salesService
// @ts-ignore
window.salesService = salesService
