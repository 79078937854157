import Vue from 'vue'
import store from '@store'
import router from '@/router/index'
import { eventBus } from '@services/eventBus'

// clear the route from unwanted query params
let url = document.location.href
let newUrl = url.replace(/dlg=&?[A-Za-z]*&?/g, '')
if (url !== newUrl) {
	location.replace(newUrl)
}

const GLOBAL_EXCLUDE_LIST = ['UpdateAppDlg']

function getRouteQueryDlgs() {
	let list = []
	let query = router.currentRoute.query
	if (typeof query.dlg === 'string') {
		list = [query.dlg]
	} else if (Array.isArray(query.dlg)) {
		list = query.dlg
	}
	return list
}

eventBus.on('routechanged', () => {
	let excludeList = getRouteQueryDlgs()
	dlg.closeAll(excludeList)
})

class DlgUtils {
	dlgs: Vue[] = []

	closeAll(excludeList: string[] = []) {
		const list = [...excludeList, ...GLOBAL_EXCLUDE_LIST]
		// keep dialogs that are part of the query
		this.dlgs.forEach(d => {
			// @ts-ignore
			if (list && list.indexOf(d.compName) < 0) {
				this.close(d, true, undefined, false)
			}
		})
	}

	async close(vm, success = true, data = undefined, popRoute = true) {
		let parent = vm.$parent ? vm.$parent : vm

		parent.show = false
		let idx = this.dlgs.indexOf(parent)
		this.dlgs.splice(idx, 1)
		if (popRoute && parent.updateQueryParam) {
			await new Promise(resolve => {
				eventBus.once('routechanged', resolve)
				router.go(-1)
			})
		}

		if (success === true) {
			parent.$resolve(data)
		} else {
			parent.$reject(data)
		}
		parent.$destroy()
		Vue.nextTick(() => parent.$el.remove())
	}

	open<T>(comp, props, updateQueryParam = true): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			let vm = new Vue({
				router,
				store,
				data: {
					show: false,
					updateQueryParam,
					compName: comp.name,
					props,
				},
				mounted() {
					this.show = true
				},
				render(h) {
					if (this.show) {
						return h(comp, { props })
					} else {
						return h('div')
					}
				},
			})

			// @ts-ignore
			vm.$resolve = resolve
			// @ts-ignore
			vm.$reject = reject

			let temp = document.createElement('div')
			document.body.appendChild(temp)
			vm.$mount(temp)
			this.dlgs.push(vm)

			if (updateQueryParam) {
				// @ts-ignore
				router.push({
					// @ts-ignore
					query: { ...router.currentRoute.query, dlg: this.dlgs.map(d => d.compName) },
				})
			}
		})
	}
}

export var dlg = new DlgUtils()
