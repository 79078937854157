<template>
	<div class="panel-cells" :style="style">
		<cell v-for="c in cell.cells" :key="c.id" :cell="c" />
	</div>
</template>

<script>
export default {
	name: 'Panel',
	props: ['cell'],
	computed: {
		style() {
			let cell = this.cell
			let props = cell.props
			let style = { display: 'flex', flexDirection: 'column' }

			if (cell.props.alignment === 'left') style.alignItems = 'flex-start'
			if (cell.props.alignment === 'center') style.alignItems = 'center'
			if (cell.props.alignment === 'right') style.alignItems = 'flex-end'
			if (cell.props.alignment === 'justify') style.alignItems = 'stretch'
			if (cell.props.verticalAlignment === 'stretch') style.flexGrow = 1

			return style
		},
	},
}
</script>
