class IdleTimer {
  constructor({ idleTimeout, onIdle }) {
    this.idleTimeout = idleTimeout
    this.onIdle = onIdle
    this.isIdle = false
    this.events = ['mousemove', 'keydown', 'mousedown', 'touchstart', 'wheel']
    this.destroyOnIdle = false
    this.lastId = null
  }
  resetTimeout() {
    if (this.isIdle) this.isIdle = false
    clearTimeout(this.lastId)
    if (!this.destroyOnIdle) {
      return this.createTimer()
    }
  }
  createTimer() {
    return setTimeout(() => {
      this.isIdle = true
      this.onIdle.call()
    }, this.idleTimeout)
  }
  listener(event) {
    this.lastId = this.resetTimeout()
  }
  start() {
    this.lastId = this.createTimer()
    this.events.forEach(eventType => {
      window.addEventListener(eventType, event => this.listener(event), { passive: true })
    })
  }
  stop() {
    this.events.forEach(eventType => {
      window.removeEventListener(eventType, event => this.listener(event), { passive: true })
    })
    this.destroyOnIdle = true
    this.resetTimeout()
  }
}
export default IdleTimer
