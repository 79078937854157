<template>
	<div class="report-rich-text" :class="{ stack: cell.props['label position'] === 'Left' }">
		<label v-if="cell.props.label" :style="labelStyle">{{ cell.props.label }}</label>
		<p v-if="cell.readOnly" class="readonly-rich-text" :class="{ prewrap: !isHtml }" v-html="cell.value"></p>
    <wysiwyg-editor-tiny
      v-else
      ref="wysiwyg"
      :read-only="showEditorSample"
      :value="textEditorValue"
      :toolbar-options="toolbarOptions"
      :edit-presets="editingPresets"
      @input="updateValue"
    />
	</div>
</template>

<script>
import { Cell } from '../classes'
import WysiwygEditorTiny from "@components/WysiwygEditorTiny.vue"

const textdiv = document.createElement('div')

export default {
	name: 'RichText',
	components: {
    WysiwygEditorTiny,
	},
	props: {
		cell: {
			type: Cell,
			required: true,
		},
		editingPresets: {
			type: Boolean,
			default: false,
		},
	},
  data() {
    return {
      presetItems: []
    }
  },
	computed: {
		isHtml() {
			return this.cell.value && this.cell.value.includes('</') && this.cell.value.endsWith('>')
		},
		showEditorSample() {
			return this.cell.editorMode && !this.editingPresets
		},
		toolbarOptions() {
			const presets = (this.cell.props.presets || []).map(p => p.label)
			if (this.editingPresets) presets.unshift('Add new preset ...')
			if (!presets.length) return
			presets.unshift('')
			return {
				container:  presets,
				handler: this.selectPreset
			}
		},
		labelStyle() {
			if (this.cell.props['label position'] === 'Top') {
				return {
					display: 'block',
					marginBottom: '8px',
				}
			}
			return {
				alignSelf: 'start',
			}
		},
    textEditorValue() {
      return this.showEditorSample ? this.sampleText : this.cell.value && this.cell.value.toString()
    },
		sampleText() {
			if (this.cell.props.defaultFontSize) {
				return `<p><span style="font-size: ${this.cell.props.defaultFontSize};">Sample text</span></p>`
			} else {
				return 'Sample text'
			}
		},
	},
  methods: {
		selectPreset(label) {
			if (label === 'Add new preset ...') {
				this.$emit('add-preset')
				return
			}
			const selectedPreset = this.cell.props.presets.find(p => p.label === label)
			this.$emit('select-preset', {
				preset: selectedPreset,
				currentValue: this.cell.value,
			}) // only used for editing presets
			if (!selectedPreset) return
			this.cell.value =
			 	this.cell.value && !this.editingPresets
				? this.cell.value.concat('', selectedPreset.value)
				: selectedPreset.value
      this.$nextTick(() => {
				this.updateValue(this.cell.value)
			})
		},
    updateValue(htmlValue, textValue = '') {
			textdiv.innerHTML = htmlValue
			const trimValue = textdiv.innerText.trim()
			this.cell.value = trimValue.length ? htmlValue : null
		},
	},
}
</script>

<style lang="scss">
.report-rich-text {
	.readonly-rich-text {
		margin-bottom: 8px;
    font-size: 12px;
	}
	.ql-presets.ql-picker {
		.ql-picker-label {
			display: inline-flex;
			align-items: center;
			&::before {
				display: block;
				width: auto;
				content: attr(data-value);
			}
			&[data-value='']::before {
				content: 'Preset';
			}
		}
		.ql-picker-item::before {
			content: attr(data-value);
		}
		svg {
			// fix dropdown arrow icon placement since dropdown label width varies
			position: relative !important;
			top: initial !important;
			right: initial !important;
			margin-top: 0 !important;
		}
		.ql-picker-options {
			max-height: 250px;
			overflow: auto;
		}
	}
}
</style>
