import api from '@services/api'
import { isArray } from 'lodash'
import { TOOLS as MPR_TOOLS } from '@vtk/VTKViewport/consts'

// Name mappings between hard coded Cornerstone tools, MPR tools
// and the db tool names stored in the database. Missing names
// imply that the tool isn't supported for that particular viewer
const toolNames = [
	{
		dbName: 'Levels',
		imgName: 'Wwwc',
		mprName: MPR_TOOLS.LEVEL_TOOL,
	},
	{
		dbName: 'Zoom',
		imgName: 'Zoom',
		mprName: MPR_TOOLS.ZOOM_TOOL,
	},
	{
		dbName: 'Magnify',
		imgName: 'Magnify',
		mprName: '',
	},
	{
		dbName: 'Pan',
		imgName: 'Pan',
		mprName: MPR_TOOLS.PAN_TOOL,
	},
	{
		dbName: 'Plane',
		imgName: '',
		mprName: MPR_TOOLS.PLANE_TOOL,
	},
	{
		dbName: 'Length',
		imgName: 'AstLengthAngle',
		mprName: MPR_TOOLS.LENGTH_TOOL,
	},
	{
		dbName: 'Rectangle',
		imgName: 'AstRectangleRoi',
		mprName: MPR_TOOLS.RECT_TOOL,
	},
	{
		dbName: 'Circle',
		imgName: 'AstCircleRoi',
		mprName: MPR_TOOLS.CIRCLE_TOOL,
	},
	{
		dbName: 'Ellipse',
		imgName: 'AstEllipticalRoi',
		mprName: MPR_TOOLS.ELLIPSE_TOOL,
	},
	{
		dbName: 'Angle',
		imgName: 'AstAngle',
		mprName: MPR_TOOLS.ANGLE_TOOL,
	},
	{
		dbName: 'Text',
		imgName: 'AstText',
		mprName: MPR_TOOLS.TEXT_TOOL,
	},
	{
		dbName: 'Arrow',
		imgName: 'AstArrowAnnotate',
		mprName: MPR_TOOLS.ARROW_TOOL,
	},
	{
		dbName: 'Eraser',
		imgName: 'AstEraser',
		mprName: MPR_TOOLS.ERASER_TOOL,
	},
	{
		dbName: 'Calibration',
		imgName: 'AstCalibration',
		mprName: '',
	},
	{
		dbName: 'VertebralHeart',
		imgName: 'AstVertebralHeart',
		mprName: '',
	},
	{
		dbName: 'Tplo',
		imgName: 'AstTplo',
		mprName: '',
	},
	{
		dbName: 'Tta',
		imgName: 'AstTta',
		mprName: '',
	},
	{
		dbName: 'HipDysplasia',
		imgName: 'AstHipDysplasia',
		mprName: '',
	},
	{
		dbName: 'PennHip',
		imgName: 'AstPennHip',
		mprName: '',
	},
	{
		dbName: 'StackScroll',
		imgName: 'StackScroll',
		mprName: MPR_TOOLS.SCROLL_TOOL,
	},
	{
		dbName: 'CrossPoint',
		imgName: 'AstCrossPoint',
		mprName: MPR_TOOLS.SELECT_TOOL,
	},
	{
		dbName: 'Probe',
		imgName: 'AstProbe',
		mprName: MPR_TOOLS.PROBE_TOOL,
	},
	{
		dbName: 'Roll',
		imgName: 'Rotate',
		mprName: MPR_TOOLS.ROLL_TOOL,
	},
]

// Viewer specific tool names
const imgTools = toolNames.map(tn => tn.imgName).filter(tn => tn)
const mprTools = toolNames.map(tn => tn.mprName).filter(tn => tn)

// Viewer specific buttons supported (1:L, 2:R, 3:LR, 4:M, 5:LM, 6:MR, 7:LMR)
const imgButtons = [1, 2, 3, 4, 5, 6, 7]
const mprButtons = [1, 2, 4]

// Viewer specific default bindings (currently identical but could change in the future)
// Need to the the viewer specific tool name when declaring these
const imgDefaultBindings = [{ tool: 'Wwwc', button: 1 }, { tool: 'Zoom', button: 2 }, { tool: 'Pan', button: 4 }]
const mprDefaultBindings = [
	{ tool: MPR_TOOLS.LEVEL_TOOL, button: 1 },
	{ tool: MPR_TOOLS.ZOOM_TOOL, button: 2 },
	{ tool: MPR_TOOLS.PAN_TOOL, button: 4 },
]

const state = {
	toolNames,
	imgTools,
	mprTools,
	imgButtons,
	mprButtons,
	imgDefaultBindings,
	mprDefaultBindings,
	bindings: [],
}

const getters = {
	imgMouseBindings: state =>
		resolveBindings(
			convertBindings(state.bindings, 'db', 'img'),
			state.imgDefaultBindings,
			state.imgTools,
			state.imgButtons
		),
	mprMouseBindings: state =>
		resolveBindings(
			convertBindings(state.bindings, 'db', 'mpr'),
			state.mprDefaultBindings,
			state.mprTools,
			state.mprButtons
		),
}

const actions = {
	async setMouseBindings({ dispatch, rootGetters }) {
		if (rootGetters.isAuthenticated) {
			await dispatch('setMouseBindingsForAuthenticatedUser')
		} else {
			await dispatch('setMouseBindingsForUnauthenticatedUser')
		}
		dispatch('setImgActiveTools')
		dispatch('setMprActiveTools')
	},
	// Fetch bindings for authenticated users
	async setMouseBindingsForAuthenticatedUser({ commit }) {
		const response = await api.user.getViewerMouseBindings()
		const bindings = response.data
		commit('SET_MOUSE_BINDINGS', bindings)
	},
	// Unauthenticated users get the default bindings but will
	// still get synchronization between viewers as they are updated
	async setMouseBindingsForUnauthenticatedUser({ commit }) {
		commit('SET_MOUSE_BINDINGS', [])
	},
	async updateMouseBinding({ dispatch, commit, rootGetters }, binding) {
		commit('UPDATE_MOUSE_BINDING', binding)
		// Keep viewer tools in sync with each other
		if (binding.type === 'img') {
			dispatch('setMprActiveTools')
		} else {
			dispatch('setImgActiveTools')
		}
		if (!rootGetters.isAuthenticated) return
		const response = await api.user.updateViewerMouseBindings(state.bindings)
		if (response.status !== 200) {
			// TODO: display a modal error
			console.error('Update mouse binding failed', response)
		}
	},
	setImgActiveTools({ rootState, getters, dispatch }) {
		getters.imgMouseBindings.forEach(binding => {
			const devices = rootState.toolManager.inputDevices
			// Exclude bindings with button !== 1 on touch-only devices
			if (devices.includes('touch') && !devices.includes('mouse') && binding.button !== 1) {
				return
			}
			dispatch('setActiveToolAndBroadcast', {
				alias: binding.tool,
				options: { mouseButtonMask: binding.button },
			})
		})
	},
	setMprActiveTools({ getters, dispatch }) {
		getters.mprMouseBindings.forEach(binding => {
			dispatch('setMprActiveToolAndBroadcast', {
				tool: binding.tool,
				buttonMask: binding.button,
			})
		})
	},
}

const mutations = {
	SET_MOUSE_BINDINGS(state, bindings) {
		state.bindings = bindings
	},
	UPDATE_MOUSE_BINDING(state, binding) {
		const bindings = [...state.bindings]
		let buttons = binding.button
		if (!isArray(buttons)) buttons = [buttons]
		buttons.forEach(button => {
			// Get the db tool name for this binding type
			const tool = convertToolName(binding.tool, binding.type, 'db')
			// New binding to update with or append
			const newBinding = { tool, button }
			// Only allow one button per tool
			const toolIndex = bindings.findIndex(b => b.tool === tool)
			if (toolIndex !== -1) {
				bindings.splice(toolIndex, 1)
			}
			// Upsert the new binding for this button
			const index = bindings.findIndex(b => b.button === button)

			if (index !== -1) {
				bindings[index] = newBinding
			} else {
				bindings.push(newBinding)
			}
		})
		state.bindings = bindings
	},
}

// Converts tool names from one type to another
// Currently used when converting bindings from the database to img/mpr viewers
// and when updating bindings that the user set manually
const convertToolName = (name, fromType, toType) => {
	const nameSet = state.toolNames.find(tn => tn[`${fromType}Name`] === name)
	return nameSet ? nameSet[`${toType}Name`] : ''
}

// Converts a set of bindings from one type to another
// This is really just used to convert the db tool names stored in the database
// to the names currently "understood" by the image and mpr viewers
const convertBindings = (bindings, fromType, toType) =>
	bindings
		.map(({ tool, button }) => {
			tool = convertToolName(tool, fromType, toType)
			return { tool, button }
		})
		.filter(binding => binding.tool)

// Resolve img/mpr bindings by examining each custom binding in the database
// and "merging" with the default bindings if it is supported by their respective viewers
function resolveBindings(bindings, defaultBindings, tools, buttons) {
	// Start with the default bindings
	const result = [...defaultBindings]
	bindings.forEach(binding => {
		// Flags for testing support of binding tool and button
		const toolAvailable = tools.includes(binding.tool)
		const buttonAvailable = buttons.includes(binding.button)
		// If the tool and button are supported...
		if (toolAvailable && buttonAvailable) {
			// Upsert the binding
			const index = result.findIndex(b => b.button === binding.button)
			if (index !== -1) {
				result.splice(index, 1, { ...binding })
			} else {
				result.push({ ...binding })
			}
		}
	})
	return result
}

export default {
	state,
	getters,
	mutations,
	actions,
}
