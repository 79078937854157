<template>
	<i
		class="icon"
		:class="{
			'icon-spin': spin,
			'icon-pulse': pulse,
			'icon-fixed': fixed,
			'icon-inline': inline,
			'icon-vcenter': vcenter,
		}"
	>
		<svg :style="svgStyle">
			<use :xlink:href="`#${icon}`" />
		</svg>
	</i>
</template>

<script>
export default {
	name: 'SvgIcon',
	props: {
		icon: {
			type: String,
			required: true,
		},
		fixed: {
			type: Boolean,
			required: false,
		},
		inline: {
			type: Boolean,
			default: false,
		},
		vcenter: {
			type: Boolean,
			default: false,
		},
		pulse: {
			type: Boolean,
			default: false,
		},
		spin: {
			type: Boolean,
			default: false,
		},
		width: { type: null, default: '' },
		height: { type: null, default: '' },
		size: {
			type: null,
			default: '1em',
		},
	},
	computed: {
		svgStyle() {
			return {
				width: this.width || this.size,
				height: this.height || this.size,
			}
		},
	},
}
</script>

<style lang="scss">
i.icon {
	display: inline-flex;
	align-items: center;
	line-height: 1;
	fill: currentColor;
	svg,
	svg * {
		fill: currentColor;
	}
	&.icon-fixed {
		width: 1.28571429em;
		justify-content: center;
	}
	&.icon-pulse {
		animation: icon-spin 1s infinite steps(8);
	}
	&.icon-spin {
		animation: icon-spin 2s infinite linear;
	}
	&.icon-vcenter {
		vertical-align: middle;
	}
}

i.icon-inline,
p > i.icon,
span > i.icon,
h1 > i.icon,
h2 > i.icon,
h3 > i.icon,
h4 > i.icon {
	top: 0.125em; // improve inline text alignment when not in flexbox; not perfect
	position: relative;
}

@keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
</style>
