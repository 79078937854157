<template>
	<div class="form-group">
		<div class="form-group">
			<label v-if="showLabels" :class="{ required: required }">Birth Date</label>
			<datepicker v-model="birthDate" :disabled="disabled" />
		</div>
		<div style="margin-top: 8px;" class="form-group">
			<label v-if="showLabels">Age</label>
			<div style="display: flex;">
				<div class="age-input">
					<input
						:value="age.years || null | formatNumber"
						:disabled="disabled"
						type="number"
						class="input nospinner"
						min="0"
						@input="onInput"
						@blur="calculateBirthdate({ years: $event.target.value })"
					/>
					<label>years</label>
				</div>
				<div class="age-input">
					<input
						:value="age.months || null | formatNumber"
						:disabled="disabled"
						type="number"
						class="input nospinner"
						min="0"
						@input="onInput"
						@blur="calculateBirthdate({ months: $event.target.value })"
					/>
					<label>months</label>
				</div>
				<div class="age-input">
					<input
						:value="age.days || null | formatNumber"
						:disabled="disabled"
						type="number"
						class="input nospinner"
						min="0"
						step="1"
						@input="onInput"
						@blur="calculateBirthdate({ days: $event.target.value })"
					/>
					<label>days</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from '@components/DatePicker.vue'
export default {
	name: 'BirthdateAgeInputs',
	components: {
		Datepicker,
	},
	props: {
		value: {
			type: [Date, String],
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		showLabels: {
			type: Boolean,
			default: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			birthDate: null,
			age: {
				years: null,
				months: null,
				days: null,
			},
		}
	},
	watch: {
		value: {
			handler() {
				this.birthDate = this.value
			},
			immediate: true,
		},
		birthDate: {
			handler() {
				if (this.birthDate && !this.birthDate.getTime) {
					this.birthDate = new Date(this.birthDate)
					return
				}
				this.calculateAge()
				this.$emit('input', this.birthDate)
			},
			immediate: true,
		},
	},
	methods: {
		calculateAge() {
			if (!this.birthDate || this.birthDate.getTime() > Date.now()) {
				this.age = { years: null, months: null, days: null }
				return
			}
			// https://stackoverflow.com/questions/17732897/difference-between-two-dates-in-years-months-days-in-javascript
			const today = new Date()
			let years = today.getFullYear() - this.birthDate.getFullYear()
			let months = today.getMonth() - this.birthDate.getMonth()
			if (months < 0) {
				years--
				months += 12
			}
			let days = today.getDate() - this.birthDate.getDate()
			if (days < 0) {
				if (months > 0) {
					months--
				} else {
					years--
					months = 11
				}
				days += getDaysInMonth(this.birthDate.getFullYear(), this.birthDate.getMonth())
			}
			this.age = { years, months, days }
		},
		calculateBirthdate(changes) {
			const age = { ...this.age, ...changes }
			const today = new Date()
			// if user enters 1.5 years, for example, carry over 6 months
			const yearCarry = Math.floor((age.years % 1) * 12)
			// if user enters partial month, we will calculate using the number of days in current month
			const daysInMonth = getDaysInMonth(today.getFullYear(), today.getMonth())
			const monthCarry = Math.floor((age.months % 1) * daysInMonth)
			this.birthDate = new Date(
				today.getFullYear() - Math.floor(age.years),
				today.getMonth() - (Math.floor(age.months) + yearCarry),
				today.getDate() - (Math.floor(age.days) + monthCarry)
			)
		},
		onInput(e) {
			e.target.value = e.target.value.replace(/-/g, '') // prevent negative numbers
		},
	},
}

function getDaysInMonth(year, monthIndex) {
	const daysInFeb = year % 4 === 0 ? 29 : 28
	return [31, daysInFeb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][monthIndex]
}
</script>

<style lang="scss" scoped>
.age-input {
	display: flex;
	flex-direction: column;
	align-items: center;
	input {
		max-width: 110px;
		text-align: center;
	}
	& + .age-input {
		margin-left: 4px;
	}
	label {
		font-size: 0.9em;
	}

	.required::after {
		content: '*';
		color: var(--icon-danger);
		padding-left: 4px;
		font-weight: bold;
	}
}
</style>
