<template>
	<div class="report-checkbox-group" :class="{ stack: cell.props['label position'] == 'Left' }">
		<label v-if="cell.props.label" :style="labelStyle">{{ cell.props.label }}</label>
		<div style="margin: -4px 0;">
			<ast-checkbox
				v-for="(item, i) in cell.props.options"
				:key="i"
				v-model="cellValue"
				:array-value="item"
				class="group-checkbox"
				:disabled="cell.readOnly"
				:class="{ vertical: cell.props.vertical }"
				:tabindex="cell.template.editorMode ? -1 : 0"
			>
				{{ item }}
			</ast-checkbox>
		</div>
		<div v-if="disabled" class="overlay"></div>
	</div>
</template>

<script>
import AstCheckbox from '@components/Checkbox.vue'

export default {
	name: 'CheckboxGroup',
	components: {
		AstCheckbox,
	},
	props: ['cell'],
	computed: {
		cellValue: {
			get() {
				return (this.cell.value || '').trim().split(',,')
			},
			set(arr) {
				this.cell.value = (arr || [])
					.map(x => x.trim())
					.filter(x => !!x)
					.sort((a, b) => {
						// order values as they appear on the page (not the order they were checked)
						const aIndex = this.cell.props.options.indexOf(a)
						const bIndex = this.cell.props.options.indexOf(b)
						if (aIndex < bIndex) return -1
						return 1
					})
					.join(',,')
			},
		},
		disabled() {
			return this.cell.editorMode || this.cell.readOnly
		},
		labelStyle() {
			if (this.cell.props['label position'] === 'Top') {
				return {
					display: 'block',
					marginBottom: '8px',
				}
			}
			return {}
		},
	},
}
</script>

<style lang="scss">
.report-checkbox-group {
	padding: 4px 0;
	position: relative;
	.overlay {
		position: absolute;
		background: transparent;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.group-checkbox {
		margin: 4px;
		padding-right: 28px; // matches radio group
		&:not(.vertical) {
			display: inline-flex !important;
		}
	}
}
</style>
