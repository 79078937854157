import api from '@services/api'
import createImageFromEnabledElementAsync from './_shared/createImageFromEnabledElementAsync'

/**
 * 1. Generates a base64 image for the currently active canvas
 * 2. Kicks off an API call to add the annotated image to the study
 * 3. Dispatches success notification
 * NOTE: Manually updating ViewModel is not needed due to study synchronization
 *
 * @export
 * @param {*} { getters, commit, dispatch }
 */
export default async function({ getters, commit, dispatch }, reportId = undefined) {
	const canvas = getters.activeCanvas
	if (!canvas.seriesId && !canvas.imageId) return

	// 1. Generate a base64 image for the currently active canvas
	const imgBase64 = await createImageFromEnabledElementAsync(canvas.dom)
	const { clinicCode } = getters.activeImage.storageLocation || getters.activeSeries.storageLocation
	const params = {
		clinicCode,
		sourceImageId: getters.activeImage.imageId,
		imgBase64,
		reportId,
	}

	// 2. API call to add the annotated image to the study
	const { series } = await api.viewer.renderAnnotatedImage(params)

	// 3. Show notification
	dispatch('addNotification', {
		message: 'New image with burned in annotations successfully created!',
		notificationType: 'info',
	})

	return series
}
