import emailIsValid from '@utils/emailIsValid'

export enum RULES {
	REQUIRED = 'required',
	NUMERIC = 'numeric',
	GREATERTHANZERO = 'greater_than_zero',
	EMAIL = 'email',
	DATE = 'date',
	MINVALUE = 'min_value',
	CONFIRMED = 'confirmed',
	COMPLEXITY = 'complexity',
}

export interface IRule {
	name: string
	arg: string
}

class Validator {
	getError(field: string, rule: string, arg): string {
		switch (rule) {
			case RULES.REQUIRED:
				return `${field} is required.`
			case RULES.NUMERIC:
				return `${field} must be numeric.`
			case RULES.GREATERTHANZERO:
				return `${field} must be greater than 0.`
			case RULES.EMAIL:
				return `${field} is not a valid email address.`
			case RULES.DATE:
				return `${field} is not a valid date.`
			case RULES.MINVALUE:
				return `${field} cannot be less than ${arg}.`
			case RULES.CONFIRMED:
				return `${field} does not match ${arg}.`
			case RULES.COMPLEXITY:
				return `Password does not meet requirements.`
		}
	}

	validateRule(value: string, rule: string, arg?: any): boolean {
		switch (rule) {
			case RULES.REQUIRED: {
				if (typeof value === 'string') return !!value.trim()
				else return value !== null && value !== undefined
			}
			case RULES.NUMERIC:
				return !isNaN(parseFloat(value))
			case RULES.GREATERTHANZERO:
				return parseFloat(value) > 0
			case RULES.EMAIL:
				return emailIsValid(value)
			case RULES.DATE:
				return isNaN(parseFloat(value)) && Date.parse(value) > 0
			case RULES.MINVALUE: {
				if (value == null) return true
				let num = parseFloat(value)
				let argnum = parseFloat(arg)
				return !isNaN(num) && !isNaN(argnum) && num >= argnum
			}
			case RULES.COMPLEXITY: {
				if (value.length < 8) return false
				if (arg && value.toLowerCase().includes(arg.toLowerCase())) return false
				let categoriesPassed = 0
				if (/[a-z]/g.test(value)) categoriesPassed++
				if (/[A-Z]/g.test(value)) categoriesPassed++
				if (/[0-9]/g.test(value)) categoriesPassed++
				if (/[^A-Za-z0-9]/g.test(value)) categoriesPassed++
				return categoriesPassed >= 3
			}
		}
	}

	parseRule(rule: string): IRule {
		let parts = rule.split(':')
		return {
			name: parts[0],
			arg: parts[1],
		}
	}

	validate(value: string, rules: string[]): boolean {
		for (let i = 0; i < rules.length; i++) {
			let r = rules[i]
			let rule = this.parseRule(r)
			if (!this.validateRule(value, rule.name, rule.arg)) {
				return false
			}
		}
		return true
	}

	hasRequired(obj: any, fields: string[]) {
		for (let i = 0; i < fields.length; i++) {
			let path = fields[i]
			let value = obj[path]
			if (!this.validateRule(value, RULES.REQUIRED)) {
				return false
			}
		}
		return true
	}
}

export const validator = new Validator()
