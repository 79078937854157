<template>
	<modal-wrapper @close="close">
		<div class="select-study-container">
			<dlg-header title="Select a study." @close="close" />
			<div class="select-study-content">
				<search-and-filter v-model="filter" is-dialog @reset-current-page="currentPage = 0">
					<template #tags>
						<filter-tag :value="filter.modalityIds" :clear-value="[]" @input="updateFilterComponent('modalityIds', [])">
							{{ $store.getters.modalityNamesById(filter.modalityIds).join(' / ') }}
						</filter-tag>
						<filter-tag
							v-if="filter.startDate || filter.endDate"
							:value="{ startDate: filter.startDate, endDate: filter.endDate }"
							clear-value=""
							@input="clearFilterDateRange()"
						>
							Study Date:
						</filter-tag>
					</template>
					<!-- Study Date -->
					<date-range-picker
						label="Study Date"
						:date-ranges="['All', 'Year', 'Month', 'Week', 'Yesterday', 'Today']"
						:filter="filter"
						@input="updateFilter($event)"
					/>
				</search-and-filter>

				<div class="table-wrapper" :class="{ 'has-cards': !mq.medium }">
					<!-- Results are loaded, no results -->
					<section v-show="!isLoading && !list.length" class="no-results">
						<em>
							No results found
						</em>
					</section>

					<!--Table list-->
					<data-table
						v-if="mq.medium"
						v-show="list.length"
						:columns="columns"
						:rows="list"
						:sort="listSort"
						:current-page="currentPage"
						:sticky-headers="true"
						:results-per-page="resultsPerPage"
						@sort-selected="handleSortSelected"
						@row-open="close"
					>
						<template #row="{ row }">
							<td>
								{{ row.patientId }}
							</td>
							<td>
								{{ row.patientName }}
							</td>
							<td>
								{{ row.ownerName }}
							</td>
							<td style="font-size: 0.85em;">
								{{ row.studyDateTime | localizeDate({ forceUTC: false }) }}
							</td>
							<td style="font-size: 0.85em;">
								{{ row.modality }}
							</td>
							<td style="font-size: 0.85em;">
								{{ row.imageCount }}
							</td>
							<td>
								<svg-icon
									v-if="row.mostRecentReportRecipient"
									icon="email-check"
									title="Sent for consultations"
									fixed
								/>
								<svg-icon v-if="row.hasBeenEmailed" icon="email" title="Study has been emailed" fixed />
							</td>
						</template>
					</data-table>

					<!--Card list-->
					<ast-studies-list-cards
						v-if="!mq.medium"
						v-show="list.length"
						:studies="list"
						:show-view-button="false"
						@select-study="close"
					/>
				</div>
				<ast-pagination
					v-show="list.length"
					:current-page="currentPage"
					:results-per-page="resultsPerPage"
					:results-length="list.length"
					:has-more-results="hasMoreResults"
					@set-results-per-page="num => (resultsPerPage = parseInt(num))"
					@set-current-page="page => (currentPage = page)"
				/>
			</div>
			<p v-show="isLoading" class="loading">
				<svg-icon icon="spinner" pulse fixed />
			</p>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper'
import StudiesListCards from '@components/view/StudiesListCards'
import DlgHeader from '@components/DlgHeader.vue'
import ListMixin from '@mixins/list'
import { dlg } from '@utils/dlgUtils'

const StudiesDlg = {
	name: 'StudiesDlg',
	components: {
		ModalWrapper,
		StudiesListCards,
		DlgHeader,
	},
	mixins: [ListMixin],
	props: {
		excludeStudyIds: {
			type: Array,
			default: () => [],
		},
		searchTerm: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isDialog: true,
			filter: {
				term: '',
				order: {
					by: '',
					isAscending: false,
				},
				modalityIds: [],
				startDate: '',
				endDate: '',
			},
			columns: [
				{
					name: 'Id',
					sortName: 'PatientId',
					columnName: 'patientId',
					isSortable: true,
				},
				{
					name: 'Patient',
					sortName: 'PatientName',
					columnName: 'patientName',
					isSortable: true,
				},
				{
					name: 'Owner',
					sortName: 'OwnerName',
					columnName: 'ownerName',
					isSortable: true,
				},
				{
					name: 'Study Date',
					sortName: 'StudyDateTime',
					columnName: 'studyDateTime',
					isSortable: true,
				},
				{
					name: 'Modality',
					sortName: 'ModalityId',
					columnName: 'modality',
					isSortable: true,
				},
				{
					name: 'Images',
					sortName: 'ImageCount',
					columnName: 'imageCount',
					isSortable: true,
				},
				{}, // Icons
			],
		}
	},
	mounted() {
		this.filter.term = this.searchTerm
		this.refreshList()
	},
	methods: {
		async fetchList(options) {
			const params = {
				page: this.currentPage,
				results: this.resultsPerPage,
				queryTerm: this.filter.term,
				modalityIds: this.filter.modalityIds,
				startDate: this.filter.startDate,
				endDate: this.filter.endDate,
				orderby: this.filter.order.by,
				isOrderAscending: this.filter.order.isAscending,
			}
			const data = await this.$api.study.getList(params)
			if (data) {
				this.list = data.results.filter(study => !this.excludeStudyIds.includes(study.studyId))
				this.numResults = data.numResults || 0
				this.hasMoreResults = data.hasMoreResults
			}
		},
		close(study) {
			dlg.close(this, true, study)
		},
	},
}

export default StudiesDlg
export function openStudiesDlg({ excludeStudyIds, searchTerm } = {}) {
	return dlg.open(StudiesDlg, { excludeStudyIds, searchTerm })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.select-study-container,
.loading {
	background: var(--secondary-bg);
}
.select-study-container {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	height: -webkit-fill-available;
}
@media (min-width: $mqSmall) {
	.select-study-container {
		width: 90vw;
		height: 90vh;
	}
}
.select-study-content {
	display: flex;
	flex-direction: column;
	background: var(--primary-bg);
	border-top: 1px solid var(--secondary-border);
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	padding: 16px 16px 8px 16px;
}
.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.5;
	.icon {
		font-size: 4em;
	}
}
</style>
