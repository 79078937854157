var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toolbar-button"},[_c('div',{staticStyle:{"position":"relative"},on:{"click":function (event) { return _vm.disabled && event.stopPropagation(); }}},[_c('button',{class:[
				{
					active: (_vm.isDropdownOpen && _vm.hasDropdown) || _vm.active,
					'has-caret': _vm.hasDropdown,
					'has-icon': !!_vm.icon,
				},
				_vm.className ],attrs:{"disabled":_vm.disabled,"title":_vm.title},on:{"contextmenu":function($event){$event.preventDefault();},"click":function($event){return _vm.toggleDropdown({ isOpen: !_vm.isDropdownOpen })}}},[(_vm.hasDropdown)?_c('p',{staticClass:"caret",class:{
					down: _vm.dropdownLocation === 'bottom',
					up: _vm.dropdownLocation === 'top',
					right: _vm.dropdownLocation === 'right',
					left: _vm.dropdownLocation === 'left',
				}}):_vm._e(),_vm._t("icon",[(_vm.icon)?_c('svg-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.badge)?_c('div',{staticClass:"badge"},[_vm._v(_vm._s(_vm.badge))]):_vm._e(),(_vm.hasNotification)?_c('div',{staticClass:"notify"},[_c('p',{staticClass:"pulse"})]):_vm._e()]),_vm._t("label",[(_vm.label)?_c('p',{staticClass:"label no-break"},[_vm._v(_vm._s(_vm.label))]):_vm._e()])],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDropdownOpen),expression:"isDropdownOpen"}],ref:"dropdown",staticClass:"dropdown"},[_vm._t("dropdown",null,{"hide":function (delay) { return _vm.toggleDropdown({ isOpen: false, delayClosing: delay }); }})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }