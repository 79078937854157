import isMobileOS from './isMobileOS'
import omniDesktop from '@/electron/omniDesktop'

const modalitiesThatShouldLoadDicoms = ['CT', 'MR', 'PT']

export default function shouldLoadDicom(modality) {
	if (!omniDesktop.isConnected) {
		if (isMobileOS()) return false
		if (window.screen.width <= 768) return false
	}
	return modalitiesThatShouldLoadDicoms.includes(modality)
}
