import { BUTTONS, BUTTON_MASKS, MOUSE_BINDINGS, TOUCH_BINDINGS } from '@/vtk/VTKViewport/consts'
import { getDefaultSeriesData } from '@/store/modules/mpr/state'

const mutations = {
	SET_MPR_ACTIVE: (state, active) => {
		state.active = !!active
	},
	SET_MPR_ACTIVE_SERIES(state, { seriesId, modality }) {
		// Check for valid series that is different than current active one
		const { activeSeries } = state
		if (!seriesId || (activeSeries && seriesId === activeSeries.seriesId)) return
		// Assign active series
		state.activeSeries = {
			...getDefaultSeriesData(),
			seriesId,
			modality,
		}
	},
	SET_MPR_LOADING({ activeSeries }, loading) {
		activeSeries.isLoading = loading
	},
	SET_MPR_LOAD_PROGRESS({ activeSeries }, loadProgress) {
		activeSeries.loadProgress = loadProgress
	},
	SET_MPR_VOLUME_DATA: ({ activeSeries }, volumeData) => {
		activeSeries.volumeData = volumeData
	},
	SET_MPR_VOLUME_DIRECTION: ({ activeSeries }, direction) => {
		activeSeries.volumeDirection = direction
	},
	SET_MPR_VOLUME_SLICE_DIRECTIONALITY: ({ activeSeries }, directionality) => {
		activeSeries.sliceDirectionality = directionality
	},
	SET_MPR_VOLUME_IS_JPEG: ({ activeSeries }, isJpeg) => {
		activeSeries.isJpeg = isJpeg
	},
	SET_MPR_LAYOUT({ activeSeries }, layout) {
		activeSeries.lastLayout = activeSeries.layout
		activeSeries.layout = layout
	},
	SET_MPR_ACTIVE_TOOL({ activeTools, tools }, { tool, buttonMask }) {
		// Get the button name using the mask
		const selectedButton = BUTTON_MASKS[buttonMask]
		// Set the active tool for the given button, unset if it's active on another button.
		Object.values(BUTTONS).forEach(button => {
			if (button === selectedButton) {
				activeTools[button] = tool
			} else if (activeTools[button] === tool) {
				activeTools[button] = null
			}
		})
		// Update tool bindings
		const inputDevices = this.state.toolManager.inputDevices
		updateToolBindings(tools, activeTools, inputDevices)
	},
	UPDATE_AVAILABLE_TOOLS(state) {
		state.availableTools = state.tools.map(tool => tool.resolve(state)).filter(tool => !tool.hidden)
	},
	UPDATE_TOOL_BINDINGS({ tools, activeTools }) {
		if (!tools || !tools.length) return
		const inputDevices = this.state.toolManager.inputDevices
		updateToolBindings(tools, activeTools, inputDevices)
	},
	SET_MPR_BLEND_MODE_ALL({ activeSeries }, mode) {
		activeSeries.blendMode = mode
		// TODO: refactor this out so the 2dMPRViews listen for this root value instead
		// For now, duplicate the data so the views will see the change
		Object.values(activeSeries.viewData).forEach(v => {
			v.blendMode = mode
		})
	},
	SET_MPR_SYNC_WINDOW_LEVELS: (state, shouldSync) => {
		state.syncWindowLevels = shouldSync
	},
	SET_MPR_SLICE_ROTATION: ({ activeSeries }, { index, plane, angle }) => {
		activeSeries.viewData[index][`slicePlane${plane.toUpperCase()}Rotation`] = angle
	},
	SET_MPR_VIEW_ROLL: ({ activeSeries }, { index, angle }) => {
		activeSeries.viewData[index]['viewRotation'] = angle
	},
	SET_MPR_SLICE_THICKNESS: ({ activeSeries }, { index, thickness }) => {
		activeSeries.viewData[index].sliceThickness = thickness
	},
	SET_MPR_ACTIVE_VIEW: ({ activeSeries }, index) => {
		Object.entries(activeSeries.viewData).forEach(([i, v]) => {
			v.active = i === index
		})
	},
	SET_MPR_ACTIVE_POSITION: ({ activeSeries }, position) => {
		Object.values(activeSeries.viewData).forEach(v => {
			v.active = v.position === position
		})
	},
	SET_MPR_SWITCH_MODE_ACTIVE({ activeSeries }, active) {
		activeSeries.switchModeActive = active
	},
	SWITCH_VIEWS({ activeSeries }, { fromIndex, toIndex }) {
		const { viewData } = activeSeries
		const fromPosition = viewData[fromIndex].position
		viewData[fromIndex].position = viewData[toIndex].position
		viewData[toIndex].position = fromPosition
	},
	TOGGLE_MPR_VIEW_FULLSCREEN({ activeSeries, layouts }) {
		const { layout, lastLayout } = activeSeries
		const newLayout =
			layout.name === 'fullscreen'
				? lastLayout || layout
				: layouts.find(l => l.name === 'fullscreen')
		if (newLayout) {
			activeSeries.lastLayout = activeSeries.layout
			activeSeries.layout = newLayout
		}
	},
	SET_MPR_DEFAULT_WINDOW_LEVEL: ({ activeSeries }, { windowCenter, windowWidth }) => {
		activeSeries.defaultVoi = { windowWidth, windowCenter }
	},
	SET_MPR_WINDOW_LEVELS_TEXT: (
		{ activeSeries: { viewData }, syncWindowLevels },
		{ index, windowCenter, windowWidth }
	) => {
		Object.keys(viewData).forEach(key => {
			if (syncWindowLevels || index === key) {
				viewData[key].window.center = windowCenter
				viewData[key].window.width = windowWidth
			}
		})
	},
	TOGGLE_MPR_SHOW_OVERLAY_TEXT(state) {
		state.showOverlayText = !state.showOverlayText
	},
	SET_MPR_SHOW_OVERLAY_TEXT(state, show) {
		state.showOverlayText = show
	},
	TOGGLE_MPR_SHOW_AXIS(state) {
		state.showAxisOverlay = !state.showAxisOverlay
	},
	SET_MPR_SHOW_AXIS(state, show) {
		state.showAxisOverlay = show
	},
	SET_MPR_SHOW_IMAGE_ORIENTATION_MARKERS(state, show) {
		state.showImageOrientationMarkers = show
	},
	ROTATE_VIEW_CLOCKWISE: ({ activeSeries }, index) => {
		rotateView(activeSeries, index, 90)
	},
	ROTATE_VIEW_COUNTERCLOCKWISE: ({ activeSeries }, index) => {
		rotateView(activeSeries, index, -90)
	},
	ROTATE_VIEW_BY_ANGLE: ({ activeSeries }, { index, degrees }) => {
		rotateView(activeSeries, index, degrees)
	},
	FLIP_VIEW_H: ({ activeSeries }, { index }) => {
		activeSeries.viewData[index].flipH = !activeSeries.viewData[index].flipH
	},
	FLIP_VIEW_V: ({ activeSeries }, { index }) => {
		activeSeries.viewData[index].flipV = !activeSeries.viewData[index].flipV
	},
	SET_MPR_VIEWDATA: ({ activeSeries }, viewData) => {
		activeSeries.viewData = viewData
	},
	SET_MPR_VIEW_COMPONENT: ({ activeSeries }, { index, component }) => {
		const { viewData } = activeSeries
		if (viewData[index] && component) {
			viewData[index].component = component
		}
	},
	CLEAR_MPR_ACTIVE_ANNOTATIONS: ({ activeSeries }) => {
		const { viewData } = activeSeries
		Object.values(viewData).forEach(view => {
			if (view.active) {
				view.component.clearAnnotations()
			}
		})
	},
	CLEAR_MPR_ACTIVE_SERIES(state) {
		state.activeSeries = null
	},
	SET_ON_MPR_RESET_CB({ activeSeries }, onReset) {
		activeSeries.onReset = onReset
	},
	SET_MPR_INVERT_CB({ activeSeries }, onInvert) {
		activeSeries.onInvert = onInvert
	},
	SET_MPR_MOVESLICE_CB({ activeSeries }, onMoveSlice) {
		activeSeries.onMoveSlice = onMoveSlice
	},
	SET_MPR_PERFORMANCE_ISSUES(state, issues) {
		state.issues = issues
	},
}

function updateToolBindings(tools, activeTools, inputDevices) {
	const deviceBindings = inputDevices.includes('mouse') ? MOUSE_BINDINGS : TOUCH_BINDINGS
	tools.forEach(tool => {
		let bindings = Object.keys(activeTools)
			.filter(button => activeTools[button] === tool.name && deviceBindings[button])
			.map(button => deviceBindings[button])
		tool.binding = bindings.join('')
	})
}

function rotateView(activeSeries, index, degrees) {
	degrees = degrees % 360 // don't rotate more than 359 degrees at a time
	let newAngle = activeSeries.viewData[index].viewRotation + Math.round(degrees)
	if (newAngle < 0) newAngle += 360
	else if (newAngle >= 360) newAngle -= 360
	activeSeries.viewData[index].viewRotation = newAngle
}

export const keys = Object.keys(mutations).reduce(
	(ret, key) => ({
		...ret,
		[key]: key,
	}),
	{}
)

export default mutations
