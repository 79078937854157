/** ********** GLOBAL EVENT BUS ****************/
import Vue from 'vue'

const _eventBus = new Vue()
const _eventTypes = {
	REPORT_IMAGE_COMMENT: 'report-image-comment',
	REPORT_SAVE: 'report-save',
	REPORT_COMPLETED: 'report-completed',
	RESIZE: 'resize',
	LOGOUT: 'logout',
	IMAGE_CHANGE: 'image-change',
	CLEAR_ALL_DATA: 'clear-all-data',
	LOGIN: 'login',
	RELOAD: 'reload',
	CLOSE: 'close',
	REFRESH_REPORT: 'refresh-report',
	FETCH_LAYOUT_PRESET: 'fetch-layout-preset',
	FETCH_LEVEL_PRESETS: 'fetch-level-presets',
	VIEWER_CLOSED: 'viewer-closed',
	VIEWER_TOOLSTATE_SEND: 'sendExternalToolState',
	VIEWER_TOOLSTATE_UPDATE: 'updateExternalToolState',
	VUEX_STATE_INIT_REQ: 'vuex-state-init-req',
	VUEX_STATE_INIT_RES: 'vuex-state-init-res',
	VUEX_STATE_UPDATE: 'state-update',
	VUEX_ACTION: 'vuex-action',
	VUEX_MUTATION: 'vuex-mutation',
	ANNOTATION_CLICK: 'annotation-click',
	ANNOTATIONS_CLEARED: 'annotations-cleared',
	MPR_ANNOTATION_CLICK: 'mpr-annotation-click',
	MPR_ANNOTATION_REMOVED: 'mpr-annotation-removed',
	MPR_ANNOTATIONS_CLEARED: 'mpr-annotations-cleared',
}

// Global resize event with a debounce
let resizeDebounce

// HACK: iOS 13.4 and 13.6 fire storage events in the same window that changed storage, so we attempt
// to ignore these by ignoring events that come in with the same event type in a short amount of time
let lastBroadcast = {
	time: null,
	event: null,
}
const IGNORE_THRESHOLD = 50 // same events as the lastBroadcast.event will be ignored if received within this number of ms

window.addEventListener('resize', () => {
	clearTimeout(resizeDebounce)
	resizeDebounce = setTimeout(() => {
		eventBus.post(eventBus.type.RESIZE)
	}, 100)
})

/**
 * The storage event fires when a storage area has been changed in the context of another document.
 * Responds to storage events by either:
 * - placing stringified sessionStorage into localStorage for a requesting tab,
 * - restoring sessionStorage from a responding tab's stringified value, or
 * - clearing sessionStorage
 *
 * @param evt - the storage event, which has a key and value
 */
window.addEventListener('storage', (evt: StorageEvent) => {
	if (!evt.key || evt.newValue === null) return
	if (lastBroadcast.event === evt.key && Date.now() - lastBroadcast.time < IGNORE_THRESHOLD) return
	const isLogout = evt.key === eventBus.type.LOGOUT && window.opener !== null
	const isReload = evt.key === eventBus.type.RELOAD
	const isEvent = Object.values(_eventTypes).includes(evt.key)

	if (isLogout) {
		window.close()
	} else if (isReload) {
    // @ts-ignore
		window.location.reload(evt.newValue === 'true')
	} else if (isEvent) {
		let value
		try {
			value = JSON.parse(evt.newValue)
		} catch (err) {
			// do not set value if evt.newValue is not valid JSON
		} finally {
			eventBus.post(evt.key, value)
		}
	}
})

export const eventBus = {
	type: _eventTypes,
	/**
	 * Emit an event just to this window.
	 */
	post(event: string, ...args) {
		_eventBus.$emit(event, ...args)
	},
	/**
	 * Broadcast an event to all other open windows/tabs, using the localStorage `storage` event.
	 * `arg` will be JSON.stringified, so only primative values can be sent.
	 */
	broadcast(event: string, arg: any = true) {
		try {
			lastBroadcast.time = Date.now()
			lastBroadcast.event = event
			localStorage.setItem(event, JSON.stringify(arg))
			localStorage.removeItem(event)
		} catch (err) {
			// localStorage not supported
		}
	},
	on(event: string, cb: Function) {
		_eventBus.$on(event, cb)
	},
	off(event: string, cb: Function) {
		_eventBus.$off(event, cb)
	},
	once(event: string, cb: Function) {
		_eventBus.$once(event, cb)
	},
}
