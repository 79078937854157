import { metaData } from 'cornerstone-core/dist/cornerstone.js'
import { eventBus } from '@services/eventBus'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { getCornerstoneImageIdParts } from '@/imageLoader'
import isLocalizerImage from '@/cornerstone/_shared/isLocalizerImage.js'

// Sometimes the stack tool state currentImageIdIndex is updated before the element itself is
export default function setActiveImageMetaDataFromElement({ commit }, element, broadcast = true) {
	try {
		const { imageId, isLocalizerImage } = getImageDataFromElementStackState(element)
		if (!imageId) return // element does not have a stack tool state (e.g. Magnify Tool's generated canvas)

		// Save only what we need for tools and synchronizers
		const imagePlaneModule = metaData.get('imagePlaneModule', imageId)
		if (!imagePlaneModule) return
		const {
			rowCosines,
			columnCosines,
			imagePositionPatient,
			frameOfReferenceUID,
			columnPixelSpacing,
			rowPixelSpacing,
			columns,
			rows,
		} = imagePlaneModule
		const metadata = {
			rowCosines,
			columnCosines,
			imagePositionPatient,
			frameOfReferenceUID,
			columnPixelSpacing,
			rowPixelSpacing,
			columns,
			rows,
			imageId,
			isLocalizerImage,
		}
		commit('SET_ACTIVE_IMAGE_METADATA', metadata)
		if (broadcast && metadata.frameOfReferenceUID) {
			eventBus.broadcast(eventBus.type.VUEX_ACTION, {
				type: 'virtualNewImage',
				payload: { metadata },
			})
		}
	} catch (e) {
		console.error('error getting metadata', e)
	}
}

function getImageDataFromElementStackState(element) {
	try {
		const stackToolState = cornerstoneTools.getToolState(element, 'stack')
		if (!stackToolState) return {}
		const sourceStackData = stackToolState.data[0]
		const sourceCornerstoneImageId = sourceStackData.imageIds[sourceStackData.currentImageIdIndex]
		const { imageId } = getCornerstoneImageIdParts(sourceCornerstoneImageId)
		return {
			imageId,
			isLocalizerImage: isLocalizerImage(sourceStackData.imageIds, sourceCornerstoneImageId),
		}
	} catch (err) {
		// ignore "element not enabled" exceptions when navigating quickly between series
		return {}
	}
}
