import { downloadFile, API, Annex } from '@services/api'
import { eventBus } from '@services/eventBus'
import { userData } from '@services/userData'

let dicomServiceRefreshInterval = null
let localServiceStatusInterval = null
const NO_SERVICE_INTERVAL_MS = 10 * 60 * 1000
const HAS_SERVICE_INTERVAL_MS = 30 * 1000

interface IDicomService {
	id: string
	userId: string
	dicomPort: number
	dicomFolder: string
	dicomAeTitle: string
	hostIPAddress: string
	hostName: string
	webServerPort: number
	autoRoutingJson: string
	isActive: boolean
	lastCheckIn: number
	regToken: string
	version: string
	disabled: boolean
	clinicCode: string
	username: string
	status: string
	checkinMS: number
	pending: boolean
	recentErrors: any[]
}

interface IDicomServiceStatus {
	id: string
	studies: IDicomServiceStudy[]
	paused: boolean
}

interface IDicomServiceQueueOptions {
	studyUidPriorities: string[]
	paused: boolean
}

interface IDicomServiceRegInfo {
	ipAddress: string
	aeTitle: string
	dicomPort: number
	dicomFolder: string
	hostName:string
}

const hostname = window.ElectronInterface?.hostname || window.config.hostname

interface IDicomServiceStudy {
	studyUid: string
	patientId: string
	patientName: string
	studyDate: string
	modality: string
	pendingImages: number
	totalImages: number
	pendingAutoRoutes: {[key:string]:number}
}

class DicomServiceData {
	dicomServices: IDicomService[] = []
	isLocalServicePaused = false
	localStudies: Map<string, any> = new Map()
	firstStatusPromise: Promise<any> = Promise.resolve()

	constructor() {
		eventBus.on('login', async () => {
			this.firstStatusPromise = this.getServices().then(() => {
				this.startServicePolling()
				return this.getLocalStatus()
			})
		})
		eventBus.on('logout', this.stopServicePolling)
		localServiceStatusInterval = setInterval(this.getLocalStatus.bind(this), 5000)
	}

	get localService(): IDicomService {
		if (!hostname) return null
		return this.dicomServices.find(d => d.hostName.toLowerCase() === hostname.toLowerCase().replace('.local', ''))
	}

	get localServiceUrl(): string {
		if (!this.localService) return null
		return `http://localhost:${this.localService.webServerPort}`
	}

	get localServiceHasErrors(): boolean {
		if (!this.localService || !this.localService.recentErrors.length) return false
		return this.localService.recentErrors.some(e => this.localStudies.has(e.studyUid))
	}

	startServicePolling() {
		clearInterval(dicomServiceRefreshInterval)
		const intervalDuration = this.dicomServices.length ? HAS_SERVICE_INTERVAL_MS : NO_SERVICE_INTERVAL_MS
		dicomServiceRefreshInterval = setInterval(() => this.getServices(), intervalDuration)
	}

	stopServicePolling() {
		clearInterval(dicomServiceRefreshInterval)
		clearInterval(localServiceStatusInterval)
		this.dicomServices = []
	}

	get activeDicomServiceHosts() {
		return this.dicomServices.filter(s => s.isActive && !s.disabled).map(s => s.hostName)
	}

	get hasLocalConnection(){
		return this.localService && this.localService.checkinMS < 90000
	}

	getStudyId(studyUid) {
		return API.get(`/dicomservice/studyid/${studyUid}`).then(r => r.data)
	}

	searchDicomTags(term) {
		return API.get(`/dicomservice/dicom-tags?search=${term}`).then(r => r.data)
	}

	getPlatform() {
		let result = 'unknown'
		let isWindows = navigator.userAgent.includes('Windows')
		let isOSX = navigator.userAgent.includes('OS X')
		let x64 = navigator.userAgent.includes('x64')
		if (isWindows) {
			result = x64 ? 'win-x64' : 'win-x86'
		} else if (isOSX) {
			result = 'osx-x64'
		}
		return result
	}

	downloadPackage(platform, token) {
		let url = `${Annex.defaults.baseURL}/dicomservice/download-package?platform=${platform}&token=${token}`
		downloadFile(url)
	}

	createService(dicomAeTitle, dicomPort, dicomFolder): Promise<IDicomService> {
		return Annex.post('/dicomservice/create-service', {
			userId: userData.claims.isCommunityUser ? userData.claims.userId : null,
			clinicCode: userData.claims.activeClinicCode,
			dicomAeTitle,
			dicomPort,
			dicomFolder,
		}).then(r => r.data)
	}

	getRegInfo(regToken):Promise<IDicomServiceRegInfo> {
		return Annex.get(`/dicomservice/reg-info?regToken=${regToken}`).then(r => r.data)
	}

	getLogs({ serviceId, studyId, logType, offset, max, modalities, studyDateStart, studyDateEnd, searchTerm }) {
		return API.post('/dicomservice/logs', {
				serviceId,
				studyId,
				logType,
				offset,
				max,
				modalities,
				studyDateStart,
				studyDateEnd,
				searchTerm,
			}).then(r => r.data)
	}

	async getServices(): Promise<IDicomService[]> {
		const hadDicomServices = !!this.dicomServices.length
		try {
			this.dicomServices = (await API.get(`/dicomservice/services?hostname=${hostname}`)).data
			this.dicomServices.sort((a,b)=> a.checkinMS - b.checkinMS)

			if (!hadDicomServices && this.dicomServices.length) this.startServicePolling()
			return this.dicomServices
		} catch (err) {
			this.stopServicePolling()
		}
	}

	async saveService(service: IDicomService) {
		await API.post('/dicomservice/save-service', service)
		if (this.hasLocalConnection) {
			return fetch(`${this.localServiceUrl}/home/configure`, {
				method: 'POST',
				body: JSON.stringify(service),
			})
		}
	}

	async getLocalStatus(): Promise<IDicomServiceStatus> {
		if (!this.hasLocalConnection) {
			this.localStudies = new Map()
			return null
		}

		let status: IDicomServiceStatus = await fetch(`${this.localServiceUrl}/home/status`).then(r => r.json())
		const localStudies = new Map()
		this.isLocalServicePaused = status.paused
		status.studies.forEach(s => {
			localStudies.set(s.studyUid, s)
		})
		this.localStudies = localStudies
		return status
	}

	setServiceQueueOptions(options: IDicomServiceQueueOptions): Promise<any> {
		return fetch(`${this.localServiceUrl}/home/set-queue-options`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify(options),
		})
	}

	downloadLocalImage(imageUid: string, scheme: string): Promise<ArrayBuffer> {
		return fetch(`${this.localServiceUrl}/home/image/${imageUid}?scheme=${scheme}`).then(r => {
			if (!r.ok) throw new Error('failed to download image')
			else return r.arrayBuffer()
		})
	}

	sendEcho(device:IDicomDevice){
		let body = {
			Id: device.id,
			hostname: device.hostname,
			localAeTitle: device.userAeTitle,
			remoteAeTitle: device.aeTitle,
			port: device.port
		}
		return fetch(`${this.localServiceUrl}/home/echo`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify(body),
		}).then(r => r.json()).catch(r => false)
	}
}

export const dicomServiceData = (window.omni.dicomServiceData = new DicomServiceData())
