import metadata from './internals/metadataLookup.js'
import getValue from './internals/getValue.js'
import { getCornerstoneImageIdParts } from '@/imageLoader'
//
import getGeneralImageModule from './metadataModules/getGeneralImageModule.js'
import getImagePixelModule from './metadataModules/getImagePixelModule.js'
import getImagePlaneModule from './metadataModules/getImagePlaneModule.js'
import getModalityLutModule from './metadataModules/getModalityLutModule.js'
import getMultiFrameModule from './metadataModules/getMultiFrameModule.js'
import getPetIsotopeModule from './metadataModules/getPetIsotopeModule.js'
import getSopCommonModule from './metadataModules/getSopCommonModule.js'
import getVoiLutModule from './metadataModules/getVoiLutModule.js'

/**
 *
 * @param {String} imageId - The Cornerstone ImageId (usually a URL)
 * @param {Object} dicomTags - Dicom Tags returned by JSON endpoint
 */
export default function(imageId, dicomTags) {
	const meta = {}
	meta.raw = dicomTags
	//   meta.instance = instanceMetadata
	//   meta.frameNumber = data.frameNumber

	// accessionNumber, patientId, studyDate, studyTime, studyDescription,
	// institutionName, patientHistory
	meta.study = {
		studyInstanceUid: getValue(dicomTags, 'STUDY_INSTANCE_UID'),
	}

	meta.series = {
		seriesDescription: getValue(dicomTags, 'SERIES_DESCRIPTION'),
		seriesNumber: getValue(dicomTags, 'SERIES_NUMBER'),
		seriesDate: '', // dicomParser.parseDA(getValue(metaData, 8, 33)),
		seriesTime: '', // dicomParser.parseTM(getValue(metaData, 8, 49))
		modality: getValue(dicomTags, 'MODALITY'),
		seriesInstanceUid: getValue(dicomTags, 'SERIES_INSTANCE_UID'),
		// numImages: numImages,
	}

	meta.patient = {
		name: getValue(dicomTags, 'PATIENT_NAME'),
		id: getValue(dicomTags, 'PATIENT_ID'),
		birthDate: getValue(dicomTags, 'PATIENT_BIRTH_DATE'),
		sex: getValue(dicomTags, 'PATIENT_SEX'),
		size: getValue(dicomTags, 'PATIENT_SIZE'),
		weight: getValue(dicomTags, 'PATIENT_WEIGHT'),
		age: getValue(dicomTags, 'PATIENT_AGE'),
	}

	meta.generalImageModule = getGeneralImageModule(dicomTags)
	meta.imagePixelModule = getImagePixelModule(dicomTags)
	meta.imagePlaneModule = getImagePlaneModule(dicomTags)
	meta.modalityLutModule = getModalityLutModule(dicomTags)
	meta.multiFrameModule = getMultiFrameModule(dicomTags)
	meta.petIsotopeModule = getPetIsotopeModule(dicomTags)
	meta.sopCommonModule = getSopCommonModule(dicomTags)
	meta.voiLutModule = getVoiLutModule(dicomTags)

	// Add the meta to the imageId lookup object
	const isCornerstoneImageId = imageId.includes(':')
	if (isCornerstoneImageId) {
		const idParts = getCornerstoneImageIdParts(imageId)
		imageId = idParts.imageId
	}

	imageId = imageId.toLowerCase()
	metadata.lookup.set(imageId, meta)
}
