<template>
	<div class="dialog prompt">
		<modal-dialog
			:actions="actions"
			:is-open="true"
			:title="title"
			:close-text="closeText"
			data-test="text-prompt-dialog"
			@close="close"
			@submit="submit"
		>
			<form @submit.prevent="submit">
				<p v-if="prompt" style="margin-bottom: 8px;">{{ prompt }}</p>
				<ast-input v-model="promptResult" v-focus :type="type" style="min-width: 300px;" />
			</form>
		</modal-dialog>
	</div>
</template>

<script>
import ModalDialog from '@components/ModalDialog.vue'
import AstInput from '@components/Input.vue'
import { dlg } from '@utils/dlgUtils'

const TextPromptDlg = {
	name: 'TextPromptDlg',
	components: {
		AstInput,
		ModalDialog,
	},
	props: {
		buttonLabel: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		prompt: {
			type: String,
			default: '',
		},
		defaultValue: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
		requireInput: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			promptResult: '',
		}
	},
	computed: {
		actions() {
			if (this.requireInput)
				return [
					{
						type: 'button',
						name: this.buttonLabel,
						isDisabled: !this.promptResult.trim(),
						emit: 'submit',
					},
				]
			else return []
		},
		closeText() {
			return this.requireInput ? 'Cancel' : this.buttonLabel
		},
	},
	mounted() {
		if (this.defaultValue) this.promptResult = this.defaultValue
	},
	methods: {
		close(wasButton) {
			if (this.requireInput || !wasButton) dlg.close(this, true)
			else dlg.close(this, true, this.promptResult)
		},
		submit() {
			if (this.requireInput && !this.promptResult.trim()) return
			dlg.close(this, true, this.promptResult)
		},
	},
}

export default TextPromptDlg

/**
 * @returns a Promise that resolves to the input value, or falsy if canceled
 */
export function openPromptDlg({
	buttonLabel = 'Submit',
	title = '',
	prompt = '',
	defaultValue = '',
	type = 'text',
	requireInput = false,
} = {}) {
	return dlg.open(
		TextPromptDlg,
		{
			buttonLabel,
			title,
			prompt,
			defaultValue,
			type,
			requireInput,
		},
		false
	)
}
</script>

<style lang="scss">
.prompt textarea.input {
	min-height: 5em;
}
</style>
