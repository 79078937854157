import api, { API, paramsSerializer, downloadFile } from '@services/api'
import { setLoading } from '@components/Loading.vue'

import { Layout, Template, Cell, Panel, TemplateSet, TemplateSetStatus, ReportDetail } from '@reporting/classes'
import { userData as user } from './userData'
import { Dbounce } from '@/utils/dbounce'
import { clinicAPI } from '@services/clinicAPI'

export enum ResidentResponseStatus {
	Draft = 0,
	Complete = 1,
	Reviewed = 2,
}

class ReportService {
	prevRoute = null
	openLayouts: Layout[] = []
	activeCell: Cell = null // the selected cell
	selLayout: Layout = null
	selCell: Cell = null // the mouse over cell
	selTool: string = null
	template: Template = null
	set: TemplateSet = null
	fieldMap: { [key: string]: IPartnerField[] } = {}
	formulas = ['SUM', 'AVG']
	border = false

	undoStack: IStateSnapshot[] = []
	redoStack: IStateSnapshot[] = []
	savedState: IStateSnapshot = null

	// drag info
	dragCell: Cell = null

	// cache last loaded meta
	meta: IMeta
	_latestMetaVersion?: number
	validateDbounce: Dbounce

	clipboard: ICell = null

	constructor() {
		this.validateDbounce = new Dbounce(2000, this.validateTemplateSet.bind(this))
	}

	get pageHeader() {
		return this.selLayout === this.template.root && this.template.pageHeader
	}

	get pageFooter() {
		return this.selLayout === this.template.root && this.template.pageFooter
	}

	get selPanel(): Panel {
		return this.selCell instanceof Panel ? this.selCell : undefined
	}

	get dragging(): boolean {
		return this.dragCell != null
	}

	get stateSnapshot(): IStateSnapshot {
		if (!this.set) return null
		const getLayoutObj = l => ({
			templateId: l.template && l.template.id,
			index: l.template && l.template.layouts.indexOf(l),
		})
		return {
			setId: this.set.id,
			setJson: JSON.stringify(this.set.toJSON()),
			setStatus: this.set.status,
			templateId: this.template && this.template.id,
			openLayouts: this.openLayouts.map(getLayoutObj),
			selLayout: getLayoutObj(this.selLayout),
			activeCell: this.activeCell && this.activeCell.name,
		}
	}
	get hasUnsavedChanges(): boolean {
		if (!this.savedState) return !!this.undoStack.length
		return this.savedState.setJson !== this.stateSnapshot.setJson
	}

	copy() {
		this.clipboard = this.activeCell.toJSON()
	}

	get canPaste() {
		return !!this.clipboard
	}

	paste() {
		if (!this.canPaste) return
		let panel = this.activeCell.isPanel ? <Panel>this.activeCell : this.activeCell.panel
		let cell = Cell.load(this.clipboard, this.selLayout)
		panel.addCell(cell)
	}

	storeState(snapshot: IStateSnapshot) {
		const MAX_STATES = 30
		this.undoStack.push(snapshot)
		if (this.undoStack.length > MAX_STATES) this.undoStack = this.undoStack.slice(-MAX_STATES)
		this.redoStack = []
		this.validateDbounce.set()
	}

	replaceStoredState(snapshot: IStateSnapshot) {
		this.undoStack[this.undoStack.length - 1] = snapshot
	}

	resetStateStorage() {
		this.savedState = null
		this.undoStack = []
		this.redoStack = []
	}

	undo() {
		this.redoStack.push(this.stateSnapshot)
		const previousState = this.undoStack.pop()
		this.restoreState(previousState)
	}

	redo() {
		this.undoStack.push(this.stateSnapshot)
		const nextState = this.redoStack.pop()
		this.restoreState(nextState)
	}

	restoreState(snapshot: IStateSnapshot) {
		this.set = TemplateSet.load(JSON.parse(snapshot.setJson), true, reportService.meta)
		const getTemplateFromId = id => this.set.templates.find(t => t.id === id)
		const getLayoutFromObj = o => getTemplateFromId(o.templateId).layouts[o.index]
		this.template = getTemplateFromId(snapshot.templateId)
		this.openLayouts = snapshot.openLayouts.map(getLayoutFromObj)
		this.selectLayout(getLayoutFromObj(snapshot.selLayout))
		this.activeCell = snapshot.activeCell && this.selLayout.controls.find(c => c.name === snapshot.activeCell)
		this.validateDbounce.set()
	}

	saveTemplateSet(set: ITemplateSet) {
		this.savedState = this.stateSnapshot
		set.modifiedDate = new Date().toISOString()
		let p = API.post('/reporting/template-set', set).then(r => r.data)
		setLoading('Saving ...', p)
		return p
	}

	downloadPdfTemplatePreview(data: IPdfTemplatePreview) {
		return API.post('reporting/preview-pdf-template', data, {
			responseType: 'arraybuffer',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/pdf',
			},
		})
	}

	getStdReportTemplates(): ITemplateSet[] {
		return []
	}

	setTemplateSetStatus(set: ITemplateSet, status: string) {
		let p = API.get(`/reporting/template-set-status/${set.id}?status=${status}`).then(r => {
			set.status = status

			if (this.set && set.id === this.set.id) {
				this.set.status = set.status
				if (set.status === TemplateSetStatus.Deleted) {
					this.closeTemplate()
				}
			}
		})
		setLoading('Saving ...', p)
		return p
	}

	deleteTemplateSet(id: string) {
		return API.delete(`/reporting/template-set/${id}`)
	}

	getTemplateSets(standard: boolean, consultantId?: string): Promise<ITemplateSet[]> {
		return API.get('/reporting/template-sets', {
			params: {
				standard,
				consultantId,
			},
		}).then(r => r.data)
	}

	getTemplateSet(id: string): Promise<ITemplateSet> {
		return API.get(`/reporting/template-set/${id}`).then(r => r.data)
	}

	async exportCSV(query: ITeleconsultationListSearchForm) {
		const token = user.claims.activeClinicCode + '_' + user.claims.sessionId
		const timeZoneOffset = new Date().getTimezoneOffset()
		let url = `${API.defaults.baseURL}/reporting/export-csv?` + paramsSerializer({ ...query, token, timeZoneOffset })
		downloadFile(url)
	}

	async loadPartnerFields(consultantId?: string): Promise<any> {
		const fieldMap = await API.get(`/reporting/partner-fields/${consultantId}`).then(r => r.data)
		if (fieldMap) this.fieldMap = fieldMap
	}

	async getTemplateMeta(version: number = undefined): Promise<IMeta> {
		if (!version && this._latestMetaVersion > 0) version = this._latestMetaVersion

		if (!this.meta || this.meta.version !== version) {
			let url = '/reporting/template-meta'
			if (version) url += `/${version}`
			this.meta = await API.get(url).then(r => r.data)
			if (!version) this._latestMetaVersion = this.meta.version
		}
		return this.meta
	}

	// ----------- Standard Report Endpoints -----------------------------
	async getStandardTemplates(): Promise<ITemplateSetInfo[]> {
		return API.get('/reporting/standard-templates').then(r => r.data)
	}

	async startStandardReport(reportTemplateId: string, studyId: string): Promise<ReportDetail> {
		let report: IStandardReport = await API.get(`/reporting/start-report`, {
			params: {
				reportTemplateId,
				studyId,
			},
			paramsSerializer,
		}).then(r => r.data)

		report.template.meta = await reportService.getTemplateMeta(report.template.metaVersion)
		return ReportDetail.loadFromStdReport(report, true)
	}

	saveStandardReport(data: IStandardReportData) {
		return API.post(`/reporting/save-report`, data)
	}

	async getStandardReport(id: string): Promise<ReportDetail> {
		let report: IStandardReport = await API.get(`/reporting/report/${id}`).then(r => r.data)
		report.template.meta = await reportService.getTemplateMeta(report.template.metaVersion)
		let result = ReportDetail.loadFromStdReport(report, user.permissions.createReport)
		result.studies = await api.viewer.getStudy({ ids: result.studyIds }, false).then(r => r.studies)
		return result
	}

	saveReportImageComment(reportId: string, data: IReportImageCommentForm): Promise<any> {
		return API.post(`/reporting/standard-report/${reportId}`, data)
	}

	deleteStandardReport(id: string): Promise<any> {
		return API.delete(`/reporting/standard-report/${id}`)
	}

	// --------------------- Image Comments -------------------------------

	getReportImageComments(reportId: string): Promise<IReportImageComments> {
		return API.get(`/reporting/image-comments/${reportId}`).then(r => r.data)
	}

	saveImageComment(reportId, data: IReportImageCommentForm) {
		return API.post(`/reporting/image-comment/${reportId}`, data)
	}

	deleteImageComment(reportId, imageId) {
		return API.delete(`/reporting/${reportId}/image-comment/${imageId}`)
	}

	// -------------------- Consultant Flags -----------------------------
	saveConsultantFlags(
		isImageOnly: boolean,
		isImageOptional: boolean,
		isMultipleResidentWorkflow: boolean,
		forceConsultantHeader: boolean,
		consultantId: string
	) {
		return API.post(`/reporting/consultant-flags/${consultantId}`, null, {
			params: {
				isImageOnly,
				isImageOptional,
				forceConsultantHeader,
				isMultipleResidentWorkflow,
			},
		})
	}

	// -------------------------------------------------------------------
	async createSet(name: string, type: string, consultantId: string): Promise<string> {
		let p = API.post(`/reporting/create-template-set/${type}`, null, {
			params: {
				name,
				consultantId,
			},
		}).then(r => r.data)
		setLoading('Creating ...', p)
		return p
	}

	validateTemplateSet() {
		if (!this.set) return

		const url = '/reporting/validate-template-set'
		API.post(url, this.set.toJSON()).then(r => (this.set.apiWarnings = r.data))
	}

	get editableLayouts(): Layout[] {
		return this.template.layouts
	}

	async saveTemplateSetAs(json: ITemplateSet) {
		delete json.id
		json.templates.forEach(t => delete t.templateFieldId)
		json.modifiedDate = new Date().toISOString()
		json.status = TemplateSetStatus.Draft
		json.id = await this.saveTemplateSet(json)
	}

	async loadTemplateSet(json: ITemplateSet) {
		let meta = await this.getTemplateMeta(json.templates[0].metaVersion)
		json.templates.forEach(t => (t.meta = meta))
		this.set = TemplateSet.load(json, true)
		this.template = this.set.templates[0]
		this.selectLayout(this.template.root)

		if (json.consultantId) {
			this.loadPartnerFields(json.consultantId)
		}
		this.validateTemplateSet()
	}

	get layoutTool(): Layout {
		return this.editableLayouts.find(l => l.name === this.selTool)
	}

	closeTemplate() {
		this.set = null
		this.template = null
		this.selLayout = null
		this.openLayouts = []
	}

	get tools(): any[] {
		let result: any[] = []
		this.template.meta.primitives.forEach(p => {
			result.push({
				name: p.name,
				description: p.description,
				icon: p.icon,
				validTemplates: p.validTemplates,
				invalidTemplates: p.invalidTemplates,
				invalidLayouts: p.invalidLayouts,
				category: 'primitive',
				isBindable: p.isBindable || false,
			})
		})

		return result
	}

	dropCell(panel: Panel, offset?: number) {
		if (this.dragCell && panel) {
			if (this.dragCell === panel) {
				throw new Error('invalid drop target')
			}

			if (panel === this.dragCell.panel) {
				let idx = panel.cells.indexOf(this.dragCell)
				if (idx < offset) {
					offset--
				}
				panel.moveCellToIndex(this.dragCell, offset)
			} else {
				panel.addCell(this.dragCell)
				panel.moveCellToIndex(this.dragCell, offset)
			}
		}

		this.dragCell = undefined
	}

	// TODO: prevent circular dependency
	get layoutTypes() {
		return this.template.layouts.filter(l => l !== this.template.root).map(l => l.name)
	}

	get breadcrumbs() {
		let result = []
		let l = this.selLayout
		while (l != null) {
			result.push(l.name)
			l = null
		}
		return result
	}

	deleteCell(cell: Cell) {
		let idx = cell.panel.cells.indexOf(cell)
		cell.panel.cells.splice(idx, 1)
	}

	createNewLayout(): Layout {
		let layout = new Layout(null, this.template)
		this.template.addLayout(layout)
		this.selectLayout(layout)
		return layout
	}

	selectLayout(layout: Layout) {
		if (this.selLayout) {
			this.selLayout.selected = false
		}
		let notTemplateRoot = !this.set.templates.some(t => t.root === layout)
		if (notTemplateRoot && !this.openLayouts.includes(layout)) {
			this.openLayouts.push(layout)
		}

		this.template = layout.template
		this.selLayout = layout
		layout.selected = true
		layout.readOnly = false
		this.activeCell = layout.root
		this.selTool = null
		this.dragCell = null
	}

	closeLayout(layout: Layout) {
		this.selectLayout(layout.template.root)
		const i = this.openLayouts.findIndex(l => l === layout)
		if (i >= 0) this.openLayouts.splice(i, 1)
	}

	getResidentResponses(consultantReportId: string): Promise<IResidentResponse[]> {
		return API.get(
			`/reporting/resident-responses/${consultantReportId}?importMachineName=${clinicAPI.importMachineName}`
		).then(r => r.data)
	}

	reviewResidentResponse(responseId: string, message: string, complete: boolean) {
		const p = API.post(`/reporting/review-resident-response`, { responseId, message, complete })
		setLoading('Saving ...', p)
		return p
	}

	deepSearch(filter: IReportDeepSearchFilter): Promise<IReportDeepSearchResult> {
		return API.get(`/reporting/deep-search`, { params: filter, paramsSerializer }).then(r => r.data)
	}
}

export const reportService = new ReportService()
export default reportService
// @ts-ignore
window.reportService = reportService
