<template>
	<div class="ViewportOverlay" :style="borderStyle">
		<div
			v-if="color"
			class="viewColor"
			:style="colorStyle"
			@click="() => $emit('toggle-fullscreen')"
		>
			<svg-icon
				:icon="isFullscreen ? 'fullscreen_exit' : 'fullscreen'"
				style="color:white"
				:size="22"
			/>
		</div>
		<div class="border overlay-element" :style="borderStyle"></div>
		<!-- Corners -->
		<template v-if="showOverlayText">
			<div class="top-left overlay-element">
				<slot name="top-left"></slot>
			</div>
			<div class="top-right overlay-element">
				<slot name="top-right"></slot>
			</div>
			<div class="bottom-left overlay-element">
				<slot name="bottom-left"></slot>
			</div>
			<div class="bottom-right overlay-element">
				<slot name="bottom-right"></slot>
			</div>
		</template>
		<!-- Compass -->
		<template v-if="showImageOrientationMarkers">
			<div class="top overlay-element compass">
				<slot name="top"></slot>
			</div>
			<div class="right overlay-element compass">
				<slot name="right"></slot>
			</div>
			<div class="bottom overlay-element compass">
				<slot name="bottom"></slot>
			</div>
			<div class="left overlay-element compass">
				<slot name="left"></slot>
			</div>
		</template>
	</div>
</template>

<script>
import SvgIcon from '@components/SvgIcon'

export default {
	name: 'ViewportOverlay',
	components: { SvgIcon },
	inject: ['mprManager'],
	props: {
		active: Boolean,
		color: {
			type: String,
			default: 'white',
		},
		cameraDirection: {
			type: Array,
			default: () => [],
		},
		showOverlayText: {
			type: Boolean,
			default: true,
		},
		showImageOrientationMarkers: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		isFullscreen() {
			return this.mprManager.layout.name === 'fullscreen'
		},
		borderStyle() {
			return {
				borderWidth: '2px',
				borderColor: this.active && this.color ? this.color : 'transparent',
			}
		},
		colorStyle() {
			return (this.color && `background: ${this.color}`) || ''
		},
	},
}
</script>

<style lang="scss" scoped>
$padding: 16px;
$text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

.ViewportOverlay {
	color: white;

	.viewColor {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 4px;
		right: 4px;
		width: 24px;
		height: 24px;
		z-index: 100;
		border-radius: 9999px;
		cursor: pointer;
		&:hover {
			opacity: 0.5;
		}
	}

	.overlay-element {
		position: absolute;
		font-weight: normal;
		text-shadow: $text-shadow;
		max-width: 49%;
		padding: $padding;
		font-size: 14px;

		pointer-events: none;
		-ms-user-select: none;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
	}

	.border {
		border: 1px solid #666;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		max-width: 100%;
	}

	.compass {
		color: #01b3ce;
		background: rgba(0, 0, 0, 0.3);
		padding: 4px;
	}

	.top,
	.bottom {
		text-align: center;
		left: 50%;
		transform: translateX(-50%);
	}
	.top {
		top: $padding;
	}
	.bottom {
		bottom: $padding;
	}
	.left,
	.right {
		top: 50%;
		transform: translateY(-50%);
	}
	.right {
		right: $padding;
		text-align: right;
	}
	.left {
		left: $padding;
	}

	.top-left {
		top: 0;
		left: 0;
	}

	.top-right {
		top: 0;
		right: 0;
		text-align: right;
	}

	.bottom-left {
		bottom: 0;
		left: 0;
	}

	.bottom-right {
		bottom: 0;
		right: 0;
		text-align: right;
	}
}
</style>
