import baseMixin from './baseMixin'

export default {
	mixins: [baseMixin],
	data() {
		return {
			points: {
				start: null,
				end: null,
			},
		}
	},
	computed: {
		start: {
			get() {
				return this.points.start
			},
			set(val) {
				this.points.start = val
			},
		},
		end: {
			get() {
				return this.points.end
			},
			set(val) {
				this.points.end = val
			},
		},
	},
	methods: {
		onInteractionBegin() {
			this.start = { ...this.pointerPosition }
			this.end = { ...this.pointerPosition }
		},
	},
}
