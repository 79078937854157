<template>
	<modal-wrapper @close="close">
		<div class="dialog messageBox">
			<div class="inner">
				<svg-icon v-if="type == 'info'" icon="info-circle" class="is-info" />
				<svg-icon v-if="type == 'alert'" icon="exclamation-triangle" class="is-warning" />
				<p v-if="message">{{ message }}</p>
				<div v-if="lines && lines.length">
					<p v-for="(line, idx) in lines" :key="idx" v-html="line"></p>
				</div>
				<ul v-if="list && list.length">
					<li v-for="(item, idx) in list" :key="idx">{{ item }}</li>
				</ul>
			</div>
			<div class="footer right">
				<button class="btn btn-default" @click="close()">Close</button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
const MessageDlg = {
	name: 'MessageDlg',
	components: {
		ModalWrapper,
	},
	props: {
		lines: {
			type: Array,
			default: null,
		},
		message: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: null,
		},
		type: {
			type: String,
			default: 'noicon', // info|alert
		},
	},
	methods: {
		close() {
			dlg.close(this)
		},
	},
}
export default MessageDlg
export function openMessageDlg(message, { lines, list, type } = {}) {
	const obj = { message }
	if (lines) obj.lines = lines
	if (list) obj.list = list
	if (type) obj.type = type
	return dlg.open(MessageDlg, obj, false)
}
export function showInfo(message) {
	return dlg.open(MessageDlg, { message, type: 'info' })
}
export function showAlert(message) {
	return dlg.open(MessageDlg, { message, type: 'alert' })
}

export function showErrors(errors) {
	return dlg.open(MessageDlg, { lines: errors, type: 'alert' })
}
export function showLog(logs) {
	let result = []
	logs.forEach(l => {
		let lines = l.split('\n')
		result = result.concat(lines)
	})
	return dlg.open(MessageDlg, { lines: result })
}
export function showList(message, list, type) {
	return dlg.open(MessageDlg, { message, list, type })
}
</script>

<style lang="scss">
.messageBox {
	max-width: 640px;
	.inner {
		display: flex;
		align-items: flex-start;
	}
	i {
		font-size: 2em;
		margin-right: 20px;
	}
	p + p,
	p + ul {
		margin-top: 8px;
    li {
      margin-left: 18px;
    }
	}
}
</style>
