<template>
	<!--Translate line on drag-->
	<drag-annotation
		:id="`${id}-line`"
		v-model="position"
		:group-id="groupId"
		:style="groupStyle"
		@drag-start="draggingAll = true"
		@drag-end="draggingAll = false"
	>
		<g v-if="start && end" data-position-ref>
			<!--Display line-->
			<line :x1="start.x" :y1="start.y" :x2="end.x" :y2="end.y" :style="strokeStyle" />
			<!--Transparent hit target line-->
			<line
				:data-id="`${id}-line`"
				:data-group-id="groupId"
				:x1="start.x"
				:y1="start.y"
				:x2="end.x"
				:y2="end.y"
				:style="strokeHitTargetStyle"
			/>
			<!--Start handle-->
			<drag-annotation
				v-model="start"
				:group-id="groupId"
				:color="activeColor"
				show-precision
				@drag-start="drag.start = true"
				@drag-end="drag.start = false"
				@radius-updated="setHandleRadius('start', $event)"
			/>
			<!--End handle-->
			<drag-annotation
				v-model="end"
				:group-id="groupId"
				:color="activeColor"
				show-precision
				drag-on-load
				@drag-start="drag.end = true"
				@drag-end="drag.end = false"
				@radius-updated="setHandleRadius('end', $event)"
			/>
			<!--Distance label-->
			<text-annotation
				v-if="statsVisible"
				:value="statText"
				:group-id="groupId"
				:event-source="textSource"
				:color="activeColor"
			/>
		</g>
	</drag-annotation>
</template>

<script>
import { startEndMixin } from './mixins'
import DragAnnotation from './DragAnnotation'
import TextAnnotation from './TextAnnotation'

export default {
	name: 'LengthAnnotation',
	components: {
		DragAnnotation,
		TextAnnotation,
	},
	mixins: [startEndMixin],
	computed: {
		distance() {
			return this.worldDistanceBetween(this.start, this.end)
		},
		statText() {
			return `${this.$options.filters.formatNumber(this.distance)} mm`
		},
	},
	methods: {
		getTextAnchorData() {
			return {
				points: [this.start, this.pointBetween(this.start, this.end), this.end],
				anchor: this.pointAligned(Object.values(this.handleOffsets), 'right'),
				alignVertical: 'middle',
			}
		},
	},
}
</script>
