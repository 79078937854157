<template>
	<modal-wrapper @close="close">
		<div class="dialog">
			<dlg-header title="On Behalf Of List" :is-loading="isLoading" @close="close()" />
			<div style="overflow-y: auto; border-top: 1px solid var(--secondary-border);" @click.stop>
				<div v-if="!isEditing" class="obo-list" :class="{ 'obo-list-empty': listIsEmpty }">
					<ast-button v-if="!isEditing" type="info" @click.native="addItem">
						Add Contact
					</ast-button>
					<data-table
						v-if="!listIsEmpty"
						:columns="columns"
						:rows="list"
						:results-per-page="20"
						:is-loading="isLoading"
						@row-open="editItem"
						@sort-selected="sortItems"
					>
						<template #row="{ row }">
							<td>{{ row.title }}</td>
							<td>{{ row.firstName }}</td>
							<td>{{ row.lastName }}</td>
							<td>{{ row.organizationName }}</td>
							<td>{{ row.email }}</td>
						</template>
					</data-table>
					<p v-else>No Contacts Found</p>
				</div>
				<form v-else class="obo-form" @click.stop>
					<section>
						<h3>Basic Info</h3>
						<div>
							<div class="input-row">
								<div class="input-group short-input">
									<label>Salutation</label>
									<select v-model="detail.title" class="select">
										<option></option>
										<option>Dr.</option>
										<option>Mr.</option>
										<option>Mrs.</option>
										<option>Ms.</option>
										<option>Miss</option>
										<option>Prof.</option>
									</select>
								</div>
								<div class="input-group">
									<label>
										First Name
										<span class="is-required"></span>
									</label>
									<input
										v-model="detail.firstName"
										v-validate="'required'"
										name="firstName"
										type="text"
										class="input"
									/>
								</div>
							</div>
							<div class="input-row">
								<div class="input-group">
									<label>
										Last Name
										<span class="is-required"></span>
									</label>
									<input
										v-model="detail.lastName"
										v-validate="'required'"
										name="lastName"
										type="text"
										class="input"
									/>
								</div>
								<div class="input-group short-input">
									<label>Suffix</label>
									<input v-model="detail.suffix" type="text" class="input" />
								</div>
							</div>
							<div class="input-row">
								<div class="input-group">
									<label>
										Clinic / Hospital
										<span class="is-required"></span>
									</label>
									<input
										v-model="detail.organizationName"
										v-validate="'required'"
										name="organizationName"
										type="text"
										class="input"
									/>
								</div>
							</div>
							<div class="input-row">
								<div class="input-group">
									<label>Degree</label>
									<input v-model="detail.degree" type="text" class="input" />
								</div>
							</div>
							<div class="input-row">
								<div class="input-group">
									<label>Accreditation</label>
									<input v-model="detail.accreditationNumber" type="text" class="input" />
								</div>
							</div>
						</div>
					</section>
					<section>
						<h3>Contact</h3>
						<div>
							<div class="input-row">
								<div class="input-group">
									<label>
										Email
										<span class="is-required"></span>
									</label>
									<input
										v-model="detail.email"
										v-validate="'required|email'"
										name="email"
										type="text"
										class="input"
									/>
								</div>
							</div>
							<transition name="expand">
								<div v-if="detail.email && hasError('email')" class="input-row">
									<p class="is-danger">
										<svg-icon icon="exclamation-circle" />
										Please enter a valid email address.
									</p>
								</div>
							</transition>
							<div class="input-row">
								<div class="input-group">
									<label>Website</label>
									<input v-model="detail.website" type="text" class="input" />
								</div>
							</div>
							<div class="input-row">
								<div class="input-group">
									<label>
										Phone
										<span class="is-required"></span>
									</label>
									<div class="input-pair">
										<select
											v-model="detail.phoneType"
											v-validate="'required'"
											name="phoneType"
											class="select short-input"
										>
											<option value="0">Office</option>
											<option value="1">Mobile</option>
											<option value="2">Home</option>
											<option value="3">Fax</option>
										</select>
										<input
											v-model="detail.phone"
											type="text"
											placeholder="xxx-xxx-xxxx"
											class="input"
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section>
						<h3>Address</h3>
						<div>
							<div class="input-row">
								<div class="input-group">
									<label>Street</label>
									<span class="is-required"></span>
									<textarea
										v-model="detail.address"
										v-validate="'required'"
										name="address"
										class="input is-address"
									></textarea>
								</div>
							</div>
							<div class="input-row">
								<div class="input-group">
									<label>City</label>
									<span class="is-required"></span>
									<input
										v-model="detail.city"
										v-validate="'required'"
										name="city"
										type="text"
										class="input"
									/>
								</div>
							</div>
							<div class="input-row">
								<div class="input-group short-input">
									<label>State</label>
									<span class="is-required"></span>
									<input
										v-model="detail.state"
										v-validate="'required'"
										name="state"
										type="text"
										class="input"
									/>
								</div>
							</div>
							<div class="input-row">
								<div class="input-group medium-input">
									<label>Zip/Postal Code</label>
									<span class="is-required"></span>
									<input
										v-model="detail.postalCode"
										v-validate="'required'"
										name="postalCode"
										type="text"
										class="input"
									/>
								</div>
							</div>
							<div class="input-row">
								<div class="input-group">
									<label>Country</label>
									<input v-model="detail.country" type="text" class="input" />
								</div>
							</div>
						</div>
					</section>
				</form>
			</div>
			<div v-if="isEditing" class="footer" @click.stop>
				<ast-button type="info" :disabled="anyErrors" @click.native="saveItem">
					{{ detail.id ? 'Save Changes' : 'Save Contact' }}
				</ast-button>
				<ast-button type="default" @click.native="cancelItem">
					Cancel
				</ast-button>
				<span style="flex-grow:1"></span>

				<div class="obo-footer-right">
					<ast-button v-if="isEditing && detail.id" type="danger" @click.native="deleteItem">
						Delete
					</ast-button>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '@components/DataTable'
import AstButton from '@components/Button'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import { ValidatorMixin } from '@mixins/validator'
import { showConfirm } from './ConfirmDlg.vue'

const OnBehalfOfDlg = {
	name: 'OnBehalfOf',
	mixins: [ValidatorMixin],
	components: {
		DataTable,
		AstButton,
		ModalWrapper,
		DlgHeader,
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters({
			isLoading: 'onBehalfOfIsLoading',
			columns: 'onBehalfOfColumns',
			isEditing: 'onBehalfOfIsEditing',
			detail: 'onBehalfOfDetail',
		}),
		list() {
			return this.$store.getters.onBehalfOfList.filter(user => !user.isCurrent)
		},
		listIsEmpty() {
			return !this.list || !this.list.length
		},
	},
	watch: {
		isEditing(val) {
			if (val) {
				this.validator.resume()
				this.validator.validate()
			} else {
				this.validator.pause()
			}
		},
	},
	created() {
		this.getOnBehalfOfList()
	},
	mounted() {
		this.cancelOnBehalfOf()
	},
	methods: {
		...mapActions([
			'getOnBehalfOfList',
			'editOnBehalfOf',
			'saveOnBehalfOf',
			'cancelOnBehalfOf',
			'deleteOnBehalfOf',
		]),
		addItem() {
			this.editOnBehalfOf({
				title: '',
				firstName: '',
				lastName: '',
				suffix: '',
				degree: '',
				accreditationNumber: '',
				organizationName: '',
				email: '',
				website: '',
				phoneType: 0,
				phone: '',
				address: '',
				city: '',
				state: '',
				postalCode: '',
				country: '',
				username: '',
				password: '',
			})
		},
		editItem(item) {
			this.editOnBehalfOf(item)
		},
		saveItem() {
			this.saveOnBehalfOf()
		},
		cancelItem() {
			this.cancelOnBehalfOf()
		},
		async deleteItem() {
			const warning = 'Are you sure you want to delete this contact?'
			if (!(await showConfirm(warning))) return
			this.deleteOnBehalfOf()
		},
		sortItems(e) {
			console.log('sortItems', e)
		},
		close() {
			dlg.close(this)
		},
	},
}

export default OnBehalfOfDlg

export function openOnBehalfOfDlg() {
	return dlg.open(OnBehalfOfDlg)
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.dialog {
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 0;
	width: 100vw;
	height: 100vh;
	height: -webkit-fill-available;
	@media (min-width: $mqSmall) {
		width: 800px;
		max-width: 90vw;
		height: auto;
		max-height: 90vh;
	}
}
.obo-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.obo-main {
	flex-grow: 1;
	padding: 0 20px;
	overflow-y: auto;
}

.obo-list {
	padding: 16px;
	&.obo-list-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		color: var(--tertiary-label);
	}
}

.obo-form {
	display: flex;
	flex-direction: column;
	font-size: 0.9em;
	height: 100%;
	padding: 16px;

	section {
		display: flex;
		padding: 32px 0;
		border-top: 1px solid var(--divider);
		&:first-child {
			padding-top: 20px;
			border-top: none;
		}
		h3 {
			width: 33%;
			max-width: 200px;
			padding-bottom: 24px;
		}
		div {
			flex-grow: 1;
		}
	}

	input,
	select,
	textarea {
		margin-top: 4px;
	}
	select {
		padding-right: 2px; // IE/Edge
	}
	.input-row {
		display: flex;
	}
	.input-row + .input-row {
		margin-top: 16px;
	}
	.input-group {
		flex-grow: 1;
		.input-pair {
			display: flex;
			* + * {
				margin-left: 16px;
			}
		}
	}
	.input-group + .input-group,
	button + button {
		margin-left: 16px;
	}
	.short-input {
		max-width: 6em;
		min-width: 6em;
	}
	.medium-input {
		max-width: 16em;
	}
}

.footer {
	display: flex;
	justify-content: space-between;
	flex-shrink: 0;
	padding: 16px;
	margin-top: 0;
	border-top: 1px solid var(--divider);
}

.obo-footer-left {
	& > * {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

@media (max-width: $mqLarge) {
	.obo-main {
		padding: 0 10px;
	}
	.obo-form section {
		flex-direction: column;
		h3 {
			width: 100%;
			max-width: 100%;
		}
		.input-row {
			flex-direction: column;
		}
		button {
			width: 100%;
		}
		.input-group + .input-group,
		button + button {
			margin-left: 0;
			margin-top: 16px;
		}
	}
}
</style>
