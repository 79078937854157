













































import { detect } from 'detect-browser'
import { mapActions } from 'vuex'
import { uploadData } from '@services/uploads'
import { addNotification } from '@services/notificationService'

export default {
	name: 'UploadForm',
	props: {
		context: {
			type: String,
			default: null,
		},
		dropTarget: {
			type: HTMLDivElement,
			default: undefined,
		},
		isWholeFormClickable: {
			type: Boolean,
			default: true,
		},
		showFolderAndFileUpload: {
			type: Boolean,
			default: false,
		},
		allowAttachments: {
			type: Boolean,
			default: false,
		},
		allowDicoms: {
			type: Boolean,
			default: true,
		},
		allowJpegs: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			uploadData,
			browseMessage: '',
			canUploadFolder: false,
			isDragging: false,
			isParsing: false,
			showFolderDropWarning: false,
			isInternetExplorer: false,
		}
	},
	computed: {
		uploadType() {
			if (!this.allowAttachments) return 'images'
			if (this.allowJpegs) return 'files'
			return 'attachments'
		},
		hasPendingDicoms() {
			return this.uploadData.uploads.some(u => u.pendingBatch && u.context === this.context && u.isDicom)
		},
	},
	watch: {
		dropTarget(dropTarget, previousTarget) {
			if (previousTarget) this.toggleDropListeners(previousTarget, false)
			this.toggleDropListeners(dropTarget, true)
		},
	},
	mounted() {
		this.canUploadFolder = 'webkitdirectory' in HTMLInputElement.prototype
		if (this.canUploadFolder) {
			this.$refs.folderInput.webkitdirectory = true
		}
		if (this.canUploadFolder && this.allowDicoms && !this.allowAttachments) {
			this.browseMessage = 'choose a folder'
			this.$refs.fileInput.webkitdirectory = true
		} else {
			this.browseMessage = 'browse for files'
		}
		this.toggleDropListeners(this.$el, true)
	},
	methods: {
		toggleDropListeners(dropTarget, isAdding) {
			const addOrRemove = isAdding ? 'addEventListener' : 'removeEventListener'
			dropTarget[addOrRemove]('dragover', this.setIsDragging)
			dropTarget[addOrRemove]('dragenter', this.setIsDragging)
			dropTarget[addOrRemove]('dragend', this.setIsNotDragging)
			dropTarget[addOrRemove]('dragleave', this.setIsNotDragging)
			dropTarget[addOrRemove]('drop', this.dropFiles)
			if (isAdding)
				this.$once('hook:beforeDestroy', () => {
					this.toggleDropListeners(dropTarget, false)
				})
		},
		toggleIsDragging(e, isDragging) {
			this.isDragging = isDragging
			if (this.dropTarget) {
				if (isDragging) this.dropTarget.classList.add('is-dragging')
				else this.dropTarget.classList.remove('is-dragging')
			}
			e.preventDefault()
			e.stopPropagation()
		},
		setIsDragging(e) {
			this.toggleIsDragging(e, true)
		},
		setIsNotDragging(e) {
			this.toggleIsDragging(e, false)
		},
		checkForIE() {
			const browser = detect()
			if (browser.name === 'ie') this.isInternetExplorer = true
		},
		clickFileInput() {
			this.$refs.fileInput.click()
		},
		clickFolderInput() {
			this.$refs.folderInput.click()
		},
		/**
		 * Handles a change event on the file input
		 *
		 * @param {Object} e - change event
		 * @param {Object} e.target - the target element
		 */
		async selectFiles({ target }) {
			if (!target.files.length) return
			this.isParsing = true
			await this.queueUploads(target.files)
			this.isParsing = false
		},
		/**
		 * Handles a drop event on the modal
		 *
		 * @param {Object} e - drop event
		 */
		async dropFiles(e) {
			this.toggleIsDragging(e, false)
			if (this.isParsing) return
			if (!e.dataTransfer.files.length) return
			this.isParsing = true
			this.showFolderDropWarning = false
			if (this.canUploadFolder) {
				const fileList = await getFilesFromDroppedItems(e.dataTransfer)
				await this.queueUploads(fileList)
			} else {
				if (includesFolder(e.dataTransfer.files)) {
					this.showFolderDropWarning = true
				} else {
					await this.queueUploads(e.dataTransfer.files)
				}
			}
			this.isParsing = false
		},
		async queueUploads(files) {
			for (let i = 0; i < files.length; i++) {
				let name = files[i].name.toLowerCase()
				if (name.includes('.zip')) {
					addNotification('Zip files are not supported. Please unzip and upload the individual files.', 'error')
					return
				}
			}

			await uploadData.queueFilesForUpload({
				context: this.context,
				files: Array.from(files),
				allowImages: this.allowJpegs,
				allowAttachments: this.allowAttachments,
				allowDicoms: this.allowDicoms,
			})
			if (this.allowDicoms) {
				// start dicoms immediately and/or try to start any pending attachments in community
				// workflow that may be able to inherit the most recent studyInstanceUid
				await uploadData.startUpload(this.context, null, true)
			}
		},
	},
}

async function getFilesFromDroppedItems(dataTransfer) {
	const files = []
	const folderReadQueue = []
	for (let i = 0; i < dataTransfer.items.length; i++) {
		const item = dataTransfer.items[i]
		if (item.kind !== 'file' || !item) continue
		const fileSystemEntries = getEntries(item)
		if (fileSystemEntries) {
			folderReadQueue.push(fileSystemEntries)
		} else {
			const file = item.getAsFile()
			if (file) files.push(file)
		}
	}
	const filesInFolders = await Promise.all(folderReadQueue)
	return files.concat(...filesInFolders)
}

function getEntries(entry) {
	if (!entry) return
	// convert DataTransferItem to FileSystemEntry first if necessary
	if (entry.getAsEntry) return getEntries(entry.getAsEntry())
	if (entry.webkitGetAsEntry) return getEntries(entry.webkitGetAsEntry())
	// return if item is from a browser that does not support webkitGetAsEntry
	if (entry.getAsFile) return
	// Processing directories with more than 100 files:
	// https://github.com/lian-yue/vue-upload-component/commit/9c9d8aafbcef005a2cc598454383ec65205d61ee
	return new Promise(resolve => {
		if (entry.isFile) {
			entry.file(file => resolve([file]))
			return
		}
		if (entry.isDirectory) {
			const files = []
			const entryReader = entry.createReader()
			const readEntries = () => {
				entryReader.readEntries(entries => {
					const iterateEntry = async i => {
						if (!entries[i] && i === 0) return resolve(files)
						if (!entries[i]) return readEntries()
						const entryFiles = await getEntries(entries[i])
						files.push(...entryFiles)
						iterateEntry(i + 1)
					}
					iterateEntry(0)
				})
			}
			readEntries()
		}
		if (!entry.isFile && !entry.isDirectory) resolve([])
	})
}

function includesFolder(files) {
	if (!files.length) return true // if dropping only folders, no files will exist
	for (let i = 0; i < files.length; i++) {
		// A folder has no type and has a size that is a multiple of 4096
		if (!files[i].type && files[i].size % 4096 === 0) return true
	}
	return false
}
