import axios from "axios";

let tinyMCEConf = {
  menubar: false,
  plugins: [],
  toolbar: false,
}

async function getTinyMCEConf() {
  let confFile = '/tiny-mce.json'
  // This needs to be changed to a better way to set the editor in TextMode
  if (window?.editorTextMode) {
    confFile = '/tiny-mce-dev-text-mode.json'
  }
  const confData = await axios.get(confFile)
  return confData.data
}

async function buildTinyMCEConf() {
  const confData = Object.entries(await getTinyMCEConf())
  confData.forEach(conf => {
    switch (conf[0]) {
      case 'menubar':
        tinyMCEConf.menubar = ''
        conf[1].forEach((menu) => {
          tinyMCEConf.menubar = tinyMCEConf.menubar + menu + ' '
        })
        break
      case 'plugins':
        conf[1].forEach((plugin) => {
          tinyMCEConf.plugins.push(plugin)
        })
        break
      case 'toolbar':
        tinyMCEConf.toolbar = ''
        conf[1].forEach((toolbar, index) => {
          toolbar.forEach((tool) => {
            tinyMCEConf.toolbar = tinyMCEConf.toolbar + tool + ' '
          })
          if (index < conf[1].length - 1) {
            tinyMCEConf.toolbar = tinyMCEConf.toolbar + '| '
          }
        })
        break
      default:
        break
    }
  })
  return tinyMCEConf
}

buildTinyMCEConf()
export default tinyMCEConf
