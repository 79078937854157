export function average(values) {
	const sum = values.reduce(function(sum, value) {
		return sum + value
	}, 0)

	return sum / values.length
}

export function standardDeviation(values) {
	const avg = average(values)

	const squareDiffs = values.map(function(value) {
		const diff = value - avg
		return diff * diff
	})

	const avgSquareDiff = average(squareDiffs)

	return Math.sqrt(avgSquareDiff)
}
