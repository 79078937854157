















import { Cell, Panel } from '@reporting/classes'

class Resizer {
	table: Cell
	index: number = 0
	startX: number
	startABPercent: number
	startWidth: number
	panelWidth: number
	mousemoveCB: any
	mouseupCB: any
	el: HTMLElement

	constructor(index: number, table: Cell) {
		this.table = table
		this.index = index
		this.mousemoveCB = this.resize.bind(this)
		this.mouseupCB = this.stopResizing.bind(this)
	}

	get width(): number {
		return this.table.props.cols[this.index]
	}

	set width(value: number) {
		this.cols.splice(this.index, 1, value)
	}

	get rightWidth(): number {
		return this.table.props.cols[this.index + 1]
	}

	set rightWidth(value: number) {
		this.cols.splice(this.index + 1, 1, value)
	}

	get cols(): number[] {
		return this.table.props.cols
	}

	get margin(): number {
		// sum the margins to the left
		let sum = 0
		for (let i = 0; i <= this.index; i++) sum += this.cols[i]
		return sum
	}

	onMouseDown(e: MouseEvent) {
		window.getSelection().empty()
		document.documentElement.style.cursor = 'e-resize'
		this.el = e.target as HTMLElement
		this.el.classList.add('dragging')

		e.stopPropagation()
		e.preventDefault()

		// store initial values for calculating deltas
		this.startABPercent = this.width + this.rightWidth
		this.startX = e.clientX
		this.panelWidth = this.table.el.querySelector('.table').clientWidth
		this.startWidth = (this.width / 100) * this.panelWidth

		document.addEventListener('mousemove', this.mousemoveCB)
		document.addEventListener('mouseup', this.mouseupCB)
	}

	resize(e: MouseEvent) {
		// resize the right column
		const deltaX = this.startX - e.clientX
		const newElWidth = this.startWidth - deltaX
		const newWidth = (newElWidth / this.panelWidth) * 100
		const newRightWidth = this.startABPercent - newWidth

		// enforce minimum width of 10%
		if (newWidth < 10 || newRightWidth < 10) return

		this.rightWidth = newRightWidth
		this.width = newWidth
	}

	stopResizing(e: MouseEvent) {
		document.removeEventListener('mousemove', this.mousemoveCB)
		document.removeEventListener('mouseup', this.mouseupCB)
		document.documentElement.style.cursor = ''
		this.el.classList.remove('dragging')
		if (e === undefined) return

		// snap to nearest whole number
		let finalW = Math.round(this.width)
		this.width = finalW
		this.rightWidth = this.startABPercent - finalW
	}
}

const TableColResizer = {}

export default {
	name: 'ReportTable',
	props: ['cell'],
	data() {
		return {
			columns: [],
		}
	},
	computed: {
		style() {
			let cols = this.cell.props.cols.map(c => `${c}%`).join(' ')
			return {
				display: 'grid',
				gridTemplateColumns: cols,
			}
		},
	},
	watch: {
		'cell.props.cols'() {
			this.setColumns()
		},
	},
	created() {
		this.cell.configureTable()
		this.setColumns()
	},
	methods: {
		setColumns() {
			let result = []
			let cols = this.cell.props.cols.length
			for (let i = 0; i < cols - 1; i++) {
				result.push(new Resizer(i, this.cell))
			}
			this.columns = result
		},
	},
}
