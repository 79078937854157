<template>
	<div v-if="!isRichText" :style="style">{{ display }}</div>
	<div v-else class="html-box" v-html="display"></div>
</template>

<script>
export default {
	name: 'ReportText',
	props: ['cell'],
	computed: {
		isRichText() {
			const {
				props: { binding },
				editorMode,
				bindingValue,
			} = this.cell
			// Binding placeholder (ie. <Patient.Birthdate> is itself rich text)
			if (editorMode && binding && !bindingValue) return false
			// Rich text if the dom parser creates extra nodes
			const doc = new DOMParser().parseFromString(this.display, 'text/html')
			return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
		},
		display() {
			const {
				props: { text, binding },
				editorMode,
				bindingValue,
			} = this.cell
			let result = text || 'Text'

			if (binding) {
				if (editorMode & !bindingValue) result = `<${binding}>`
				else result = bindingValue
			}
			return result
		},
		style() {
			const { size } = this.cell.props
			const fontSizes = [0.9, 1, 1.17, 1.5, 2]
			const fontSize = fontSizes[(size || 2) - 1] + 'em'
			const textAlign = this.cell.panel.props.alignment // inherit alignment of parent panel
			const wordBreak = 'break-all'
			return { fontSize, whiteSpace: 'pre-wrap', textAlign, wordBreak }
		},
	},
}
</script>

<style lang="scss" scoped>
.html-box {
	width: 100%;
	padding: 8px;
	color: #000;
	background-color: #fff;
	border: 1px solid var(--primary-border);
	ol,
	ul {
		padding-left: 16px;
	}
}
</style>
