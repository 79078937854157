<template>
	<div v-if="showTag" class="filter-tag">
		<span><slot></slot></span>
		<template v-if="dateRangeText">
			<template v-if="hasLabel">
				&nbsp;
			</template>
			{{ dateRangeText }}
		</template>
		<button @click="$emit('input', clearValue)">
			<svg-icon icon="close" />
		</button>
	</div>
</template>

<script>
import { dateRangeLabels, getDateRanges } from '@utils/dateUtils'
export default {
	name: 'FilterTag',
	props: {
		value: {
			type: [String, Number, Boolean, Array, Object, Date],
			default: undefined,
		},
		clearValue: {
			type: [String, Number, Boolean, Array, Object, Date],
			default: undefined,
		},
	},
	computed: {
		hasLabel() {
			return !!this.$slots.default
		},
		showTag() {
			return JSON.stringify(this.value) !== JSON.stringify(this.clearValue)
		},
		dateRangeText() {
			if (!this.value || (!this.value.startDate && !this.value.endDate)) return
			if (this.value.startDate && this.value.endDate) {
				const dateRanges = getDateRanges()
				for (const key in dateRanges) {
					const dateRange = dateRanges[key]
					if (!dateRange.startDate || !dateRange.endDate) continue
					if (
						dateRange.startDate.getTime() === new Date(this.value.startDate).getTime() &&
						dateRange.endDate.getTime() === new Date(this.value.endDate).getTime()
					) {
						return dateRangeLabels[key]
					}
				}
			}
			const startDate = this.$options.filters.localizeDate(this.value.startDate, {
				showTime: false,
			})
			const endDate = this.$options.filters.localizeDate(this.value.endDate, { showTime: false })
			if (startDate && endDate) return startDate + '-' + endDate
			if (startDate) return 'From ' + startDate
			return 'To ' + endDate
		},
	},
}
</script>

<style lang="scss">
.filter-tag {
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	align-self: center;
	background: var(--tertiary-bg);
	white-space: nowrap;
	position: relative;
	font-size: 0.8em;
	height: 32px;
	border-radius: 16px;
	user-select: none;
	span {
		padding: 4px 0 4px 12px;
	}
	button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		cursor: pointer;
		border: 0;
		outline: 0;
		opacity: 0.6;
		height: 32px;
		width: 32px;
		&:hover {
			opacity: 1;
		}
	}
}
</style>
