import { synchronizers } from './../../toolManager/synchronizers'
import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import { eventBus } from '@services/eventBus'

export function setStackSynchronizationMethod({ state, commit }, syncMethod) {
	let manual, auto
	switch (syncMethod) {
		case 'manual':
			manual = true
			auto = false
			// Create offsets for each open series
			const offsets = []
			const images = []
			state.canvases.forEach(sourceCanvas => {
				if (sourceCanvas.asterisImageId === null) return
				const sourceMeta = cornerstone.metaData.get('imagePlaneModule', sourceCanvas.asterisImageId)
				if (!sourceMeta || !sourceMeta.imagePositionPatient) return
				sourceMeta.imageId = sourceCanvas.asterisImageId
				sourceMeta.seriesId = sourceCanvas.seriesId
				images.push(sourceMeta)
				offsets.push(...getOffsetsFromMeta(state, sourceMeta))
			})
			commit('ADD_STACK_SCROLL_OFFSETS', offsets)
			commit('SET_STACK_SCROLL_SYNCHRONIZATION_MANUAL')
			eventBus.broadcast(eventBus.type.VUEX_ACTION, {
				type: 'requestImageStackForManualSync',
				payload: { images },
			})

			break
		case 'off':
			manual = false
			auto = false
			commit('SET_STACK_SCROLL_OFFSETS', [])
			commit('SET_STACK_SCROLL_SYNCHRONIZATION_OFF')
			break
		case 'auto':
		default:
			manual = false
			auto = true
			commit('SET_STACK_SCROLL_OFFSETS', [])
			commit('SET_STACK_SCROLL_SYNCHRONIZATION_AUTO')
			break
	}

	synchronizers.stackScrollSynchronizer.enabled = auto
	synchronizers.manualStackScrollSynchronizer.enabled = manual
}

export function requestImageStackForManualSync({ state, dispatch }, { images: externalImages }) {
	dispatch('setImageStackForManualFromExternal', { images: externalImages })
	const images = []
	state.canvases.forEach(sourceCanvas => {
		if (sourceCanvas.asterisImageId === null) return
		const sourceMeta = cornerstone.metaData.get('imagePlaneModule', sourceCanvas.asterisImageId)
		if (!sourceMeta || !sourceMeta.imagePositionPatient) return
		sourceMeta.imageId = sourceCanvas.asterisImageId
		sourceMeta.seriesId = sourceCanvas.seriesId
		images.push(sourceMeta)
	})
	eventBus.broadcast(eventBus.type.VUEX_ACTION, {
		type: 'setImageStackForManualFromExternal',
		payload: { images },
	})
}

export function setImageStackForManualFromExternal({ state, commit }, { images }) {
	const offsets = []
	// Don't set images if we are showing that same image!
	const unMatchedImages = images.filter(
		img => !state.manualStackOffsets.find(offset => offset.sourceSeriesId === img.seriesId)
	)
	unMatchedImages.forEach(metaData => {
		offsets.push(...getOffsetsFromMeta(state, metaData))
	})
	commit('ADD_STACK_SCROLL_OFFSETS', offsets)
}

function getOffsetsFromMeta(state, sourceMeta) {
	const sourceSeriesId = sourceMeta.seriesId
	const sourcePosition = sourceMeta.imagePositionPatient
	return state.canvases.reduce((offsets, targetCanvas) => {
		if (
			sourceSeriesId !== null &&
			targetCanvas.asterisImageId !== null &&
			sourceSeriesId !== targetCanvas.asterisImageId
		) {
			const targetMeta = cornerstone.metaData.get('imagePlaneModule', targetCanvas.asterisImageId)
			if (targetMeta) {
				const { imagePositionPatient: targetPosition } = targetMeta
				offsets.push({
					sourceSeriesId,
					targetSeriesId: targetCanvas.seriesId,
					x: sourcePosition.x - targetPosition.x,
					y: sourcePosition.y - targetPosition.y,
					z: sourcePosition.z - targetPosition.z,
				})
			}
		}
		return offsets
	}, [])
}
