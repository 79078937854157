export default class {
	constructor(options) {
		// The tool's name as an export on `CornerstoneTools`
		this.name = options.name || ''
		// The tool's default name for API calls
		this.alias = options.alias || ''
		this.binding = ''
		this.groups = options.groups || []
		this.isOptionalFeature = options.isOptionalFeature || false
		this.options = options.options || {}
		// Display
		this.label = options.label || options.name
		this.iconName = options.iconName || null
		this.title = options.title || null
		this.disabled = options.disabled || false
		this.hidden = options.hidden || false
		// Command override
		this.command = options.command || null
		// Refresh function
		if (typeof options.refresh === 'function') {
			this.refresh = options.refresh.bind(this)
		}
	}

	// Resolves viewer tool state given the store getters
	resolve(getters) {
		if (this.refresh) {
			this.refresh(getters)
		}
		return this
	}
}
