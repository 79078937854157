<template>
	<div
		v-if="billingCodes.length > 1 || cell.template.editorMode"
		class="input-group"
		style="display: inline-grid; padding: 4px 0; grid-template-columns: auto auto; align-items: center;"
	>
		<label for="billingCode" style="padding-right: 1ch;">
			Billing Code:
			<small v-if="isRequired" class="is-danger">*</small>
		</label>
		<div>
			<span v-if="cell.readOnly && billingCode"> {{ billingCode.name }} ({{ billingCode.value }}) </span>
			<em v-if="cell.readOnly && !billingCode">
				None selected
			</em>
			<v-select
				v-if="!cell.readOnly"
				v-model="value"
				name="billingCode"
				:reduce="billingCode => billingCode.id"
				:options="billingCodes"
				label="name"
				:searchable="false"
				:clearable="false"
        :required="isRequired"
				style="display: inline-block; min-width: 200px;"
			>
				<template #option="c">
					<span v-if="c.id">
						{{ `${c.name} (${c.value})` }}
					</span>
					<span v-else>&nbsp;</span>
				</template>
			</v-select>
		</div>
		<div v-if="showSettingsLink" style="grid-area: 2 / 2 / 2 / 3; text-align: right;">
			<small>
				<a @click="showBillingCodeSettings">
					Manage Billing Codes
				</a>
			</small>
		</div>
	</div>
</template>

<script>
import { Cell } from '../classes'
import { openSettings } from '@dialogs/Settings.vue'
import { userData } from '@services/userData'

export default {
	name: 'BillingCodeSelector',
	components: {},
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			billingCodes: [],
		}
	},
	computed: {
		showSettingsLink() {
			if (this.cell.readOnly) return false
			if (this.cell.report && this.cell.report.canRespond) return true
			if (this.cell.report && this.cell.report.isStandardReport && userData.permissions.serverAdministration)
				return true
			return false
		},
		billingCode() {
			if (!this.cell.report || !this.cell.report.billingCodeId) return
			return this.billingCodes.find(b => b.id === this.cell.report.billingCodeId)
		},
		value: {
			get() {
				return this.cell.report && this.cell.report.billingCodeId
			},
			set(value) {
				if (!this.cell.report) return
				this.cell.report.billingCodeId = value
				this.cell.template.changeValue()
			},
		},
		isRequired() {
			return this.cell.props.isRequired
		},

	},
	watch: {
		'cell.report.billingCodes': {
			handler(billingCodes) {
				if (!billingCodes) return
				this.billingCodes = [{ id: null, name: '', value: '' }, ...billingCodes]
				// set billingCodeId to null if it has since been removed from the consultant's settings
				if (this.billingCodes.every(c => c.id !== this.value)) this.value = null
			},
			immediate: true,
		},
		anyErrors() {
			if (this.cell.report) this.cell.report.validate()
		},
	},
	async created() {

		if (this.cell.editorMode) return ''

		let report = this.cell.report

		this.cell.onValidate = () => {

			let billingcodevalid = (this.cell.props.isRequired && this.value == null)

			if (billingcodevalid)
				report.validationErrors.push('Please select billing code.')

		}
		this.cell.report.validate()

		this.$watch(
			'billingCodeId',
			() => {
				this.cell.template.changeValue()
			},
			{ deep: true }
		)
	},
	methods: {
		showBillingCodeSettings() {
			if (!this.cell.report) return
			let selectConsultantId
			if (!this.cell.report.isStandardReport) {
				if (!this.cell.report.consultant) return
				selectConsultantId = this.cell.report.groupConsultantId || this.cell.report.consultant.id
			}
			openSettings('billing-codes', { selectConsultantId })
		},
	},
}
</script>
