import AstCard from '@components/Card.vue'
import AstPagination from '@components/Pagination'
import DataTable from '@components/DataTable.vue'
import DateRangePicker from '@components/DateRangePicker.vue'
import FilterTag from '@components/FilterTag.vue'
import SearchAndFilter from '@components/SearchAndFilter.vue'
import { Dbounce } from '@/utils/dbounce'

export default {
	components: {
		AstCard,
		AstPagination,
		DataTable,
		DateRangePicker,
		FilterTag,
		SearchAndFilter,
	},
	data() {
		return {
			list: [],
			filter: {},
			filterPresets: {},
			dialogCurrentPage: 0,
			dialogResultsPerPage: 25,
			hasMoreResults: false,
			isLoading: false, // for optionally showing loading indicator in UI
			isRefreshingList: false, // for preventing refresh if already refreshing
		}
	},
	computed: {
		listSort() {
			return {
				orderBy: this.filter.order.by,
				isAscending: this.filter.order.isAscending,
				isSorted: true,
			}
		},
		resultsPerPage: {
			get() {
				if ('isDialog' in this && this.isDialog && this.dialogResultsPerPage) {
					return this.dialogResultsPerPage
				} else if (this.$store.state.ui.resultsPerPage) {
					return this.$store.state.ui.resultsPerPage
				} else {
					return this.defaultResultsPerPage || 25
				}
			},
			set(resultsPerPage) {
				if ('isDialog' in this && this.isDialog) {
					this.dialogResultsPerPage = resultsPerPage
				} else {
					this.$store.commit('SET_RESULTS_PER_PAGE', resultsPerPage)
				}
			},
		},
		currentPage: {
			get() {
				const cachedCurrentPage = this.$route && this.$store.state.ui.currentPage[this.$route.name]
				if ('isDialog' in this && this.isDialog) {
					return this.dialogCurrentPage
				} else if (cachedCurrentPage) {
					return cachedCurrentPage
				} else {
					return 0
				}
			},
			set(page) {
				if ('isDialog' in this && this.isDialog) {
					this.dialogCurrentPage = page
				} else {
					this.$store.commit('SET_CURRENTPAGE', { route: this.$route.name, page })
				}
			},
		},
		selectedStudy: {
			get() {
				const cachedSelectedStudyId = this.$store.state.ui.listSelectedStudyId
				if (!('isDialog' in this && this.isDialog) && cachedSelectedStudyId) {
					return this.list.find(s => s.studyId === cachedSelectedStudyId)
				} else {
					return null
				}
			},
			set(study) {
				if (!('isDialog' in this && this.isDialog)) {
					this.$store.commit('SET_LIST_SELECTED_STUDYID', study?.studyId)
				}
			},
		},
	},
	watch: {
		currentPage() {
			this.refreshList()
			// Scroll to top of embedded list (add more selectors as needed)
			const selectors = ['.table-wrapper']
			const el = selectors.map(s => document.querySelector(s)).find(e => e)
			if (el) {
				// Would be nice to animate this with tween.js or scrollTo
				el.scrollTop = 0
			}
		},
		resultsPerPage() {
			this.currentPage = 0
			this.refreshList()
		},
		filter: {
			handler() {
				if (!this.refreshDbounce) {
					this.refreshList()
				} else this.refreshDbounce.set()
			},
			deep: true,
			immediate: true,
		},
	},
	created() {
		this.refreshDbounce = new Dbounce(100, this.refreshList.bind(this))
	},
	methods: {
		clearFilterDateRange(prefix) {
			this.currentPage = 0
			if (!prefix) {
				this.filter.startDate = ''
				this.filter.endDate = ''
			} else {
				this.filter[prefix + 'StartDate'] = ''
				this.filter[prefix + 'EndDate'] = ''
			}
		},
		updateFilter(newFilter) {
			if (JSON.stringify(newFilter) !== JSON.stringify(this.filter)) this.currentPage = 0
			this.filter = newFilter
		},
		updateFilterComponent(key, value) {
			this.currentPage = 0
			this.filter[key] = value
		},
		handleSortSelected(evt) {
			this.currentPage = 0
			this.filter.order.by = evt.name
			this.filter.order.isAscending = evt.isAscending
		},
		async refreshList(showLoading = true, waitForPreviousRefresh = false) {
			if (waitForPreviousRefresh && this.isRefreshingList) return
			this.isRefreshingList = true
			if (showLoading) this.isLoading = true
			if (this.refreshFilterPresets) this.refreshFilterPresets()
			try {
				await this.fetchList()
			} finally {
				this.isLoading = false
				this.isRefreshingList = false
			}
		},
	},
}
