export default {
	mprActive(state) {
		return state.active
	},
	mprActiveSeries(state) {
		return state.activeSeries
	},
	mprActiveView(state) {
		return state.activeSeries && Object.values(state.activeSeries.viewData).find(v => v.active)
	},
	mprActiveViewKey(state) {
		return (
			state.activeSeries &&
			Object.entries(state.activeSeries.viewData).find(([i, v]) => v.active)[0]
		)
	},
}
