import api from '@services/api'
import { eventBus } from '@services/eventBus'
import _ from 'lodash'

const state = {
	isSaved: false,
	hasDefinedColors: false,
	local: {
		bg: null,
		border: null,
	},
	regional: {
		bg: null,
		border: null,
	},
	community: {
		bg: null,
		border: null,
	},
	global: {
		bg: null,
		border: null,
	},
	standard: {
		bg: null,
		border: null,
	},
	stat: {
		bg: null,
		border: null,
	},
	old: {
		bg: null,
		border: null,
		threshold: 1,
	},
}

const root = document.documentElement

// Mutations Map
const GET_COLOR_SETTINGS = 'GET_COLOR_SETTINGS'
const SET_COLOR_SETTINGS_THEME = 'SET_COLOR_SETTINGS_THEME'
const SET_COLOR_SETTINGS_VALID = 'SET_COLOR_SETTINGS_VALID'
const SET_COLOR_SETTINGS_THRESHOLD = 'SET_COLOR_SETTINGS_THRESHOLD'

const mutations = {
	SET_COLOR_SETTINGS_THEME(state, payload) {
		state.isSaved = payload.status === 200
	},
	GET_COLOR_SETTINGS(state, payload) {
		Object.assign(state, payload)
	},
	SET_COLOR_SETTINGS_VALID(state, payload) {
		state.hasDefinedColors = payload
	},
	SET_COLOR_SETTINGS_THRESHOLD(state, payload) {
		state.old.threshold = payload
	},
}

const actions = {
	// Broadcast Theme from external windows
	setColorsAndBroadcast({ dispatch }, payload) {
		const type = 'saveReportColorSettingsAction'
		dispatch(type, payload)
		eventBus.broadcast(eventBus.type.VUEX_ACTION, { type, payload })
	},
	// Save the colors using the API
	async saveReportColorSettingsAction({ commit, dispatch }, colorSettings) {
		try {
			const response = await api.user.setUserSetting('ColorSettings', 'OmniReport', JSON.stringify(colorSettings))
			commit(SET_COLOR_SETTINGS_THEME, response)
			dispatch('getReportColorSettingsAction')
		} finally {
		}
	},
	// Get the color settings
	async getReportColorSettingsAction({ commit, dispatch }) {
		try {
			const response = await api.user.getUserSetting('ColorSettings', 'OmniReport')
			commit(GET_COLOR_SETTINGS, response.data)
			dispatch('setUpColorSettings', response.data)
		} finally {
		}
	},
	setReportColorSettingsThreshold({ commit }, threshold) {
		commit(SET_COLOR_SETTINGS_THRESHOLD, threshold)
	},
	// It generates and apply the color variables
	setUpColorSettings({ state, commit }, colors) {
		const colorKeys = Object.keys(colors).filter(color => color !== 'isSaved' && color !== 'hasDefinedColors')

		const shadeHexColor = (color, percent) => {
			const f = parseInt(color.slice(1), 16)
			const t = percent < 0 ? 0 : 255
			const p = percent < 0 ? percent * -1 : percent
			const R = f >> 16
			const G = (f >> 8) & 0x00ff
			const B = f & 0x0000ff
			return (
				'#' +
				(
					0x1000000 +
					(Math.round((t - R) * p) + R) * 0x10000 +
					(Math.round((t - G) * p) + G) * 0x100 +
					(Math.round((t - B) * p) + B)
				)
					.toString(16)
					.slice(1)
			)
		}

		// Maps the colors and creates the variable map
		const newColorMap = colorKeys.map(item => {
			return {
				[`table-report-${item}-row-bg`]: colors[item].bg ? colors[item].bg : 'rgba(0, 0, 0, 0)',
				[`table-report-${item}-row-border`]: colors[item].border ? colors[item].border : 'rgba(0, 0, 0, 0)',
				[`table-report-${item}-row-bg-hover`]: colors[item].bg
					? shadeHexColor(colors[item].bg, -0.3)
					: 'rgba(0, 0, 0, 0)',
			}
		})

		const colorMapped = {}
		let hasColor = false

		newColorMap.forEach(color => {
			_.merge(colorMapped, color)
		})

		for (let color in colorMapped) {
			root.style.setProperty(`--${color}`, colorMapped[color])

			if (colorMapped[color] !== 'rgba(0, 0, 0, 0)') {
				hasColor = true
			}
		}

		commit(SET_COLOR_SETTINGS_VALID, hasColor)
	},
}

export default {
	state,
	mutations,
	actions,
}
