import * as cornerstone from 'cornerstone-core'
import { distance2BetweenPoints } from 'vtk.js/Sources/Common/Core/Math'
import { vec3 } from 'gl-matrix'
import camelCase from 'lodash/camelCase'

// Minimum number of images allowed
const minImages = 15
// Dot products should be 0, but sometimes are exponentially small
const maxOrientationDotProduct = 0.001

function parseMetadataValue(value, key) {
	switch (key) {
		case 'ImagePositionPatient':
			return value.split('\\').map(n => Number.parseFloat(n))
		case 'ImageOrientationPatient':
			return value.split('\\').map(n => Number.parseFloat(n))
		default:
			return value
	}
}

export function getImageMetadataValues(image, keys) {
	const values = {}
	const missingKeys = []
	const metadata = cornerstone.metaData.get('raw', image.imageId)
	keys.forEach(key => {
		if (metadata) {
			const entry = metadata.find(md => md.Name === key)
			if (!entry) {
				missingKeys.push(key)
			} else {
				values[camelCase(key)] = parseMetadataValue(entry.Value, key)
			}
		} else {
			missingKeys.push(key)
		}
	})
	if (missingKeys.length) {
		values.missingKeys = missingKeys
	}
	return values
}

export default function validateMPRImages(images) {
	let validImages = []
	let disabledReasons = []
	if (!images || images.length < minImages) {
		disabledReasons.push('minimg')
		return {
			images,
			disabledReasons,
		}
	}

	let lastPosition = null
	let minDistance = Number.MAX_VALUE
	let maxDistance = Number.MIN_VALUE
	const keys = ['ImageType', 'ImagePositionPatient', 'ImageOrientationPatient']

	for (const image of images) {
		const metadata = getImageMetadataValues(image, keys)

		if (metadata.missingKeys) {
			disabledReasons.push('missing')
			continue
		}

		const { imageType, imagePositionPatient, imageOrientationPatient } = metadata

		// Skip LOCALIZER images b/b they have the wrong orientation and position
		if (imageType.includes('LOCALIZER')) {
			continue
		}

		// Check for derived image types
		if (imageType.includes('DERIVED')) {
			disabledReasons.push('derived')
			continue
		}

		// Check for a valid orientation
		// Sometimes the dot product is exponentially small but it should be ~= 0
		const [rx, ry, rz, cx, cy, cz] = imageOrientationPatient
		if (Math.abs(vec3.dot([rx, ry, rz], [cx, cy, cz])) > maxOrientationDotProduct) {
			disabledReasons.push('orientation')
			continue
		}

		// Keep track of the max/min distance from the last position to the current position
		if (lastPosition) {
			const distance = Math.sqrt(distance2BetweenPoints(imagePositionPatient, lastPosition))
			minDistance = Math.min(minDistance, distance)
			maxDistance = Math.max(maxDistance, distance)
		}
		lastPosition = imagePositionPatient

		// Add as valid image
		validImages.push(image)
	}

	// Check for minimum number of valid images
	if (validImages.length < minImages) {
		disabledReasons.push('invalidmin')
	}

	// Any series that has a range more than 25% of the max is rejected b/c
	// it can cause issues with MPR calculations
	// const spacingPercentageOfMaxDistance = 0.25
	// if (maxDistance - minDistance > maxDistance * spacingPercentageOfMaxDistance) {
	// 	disabledReasons.push('spacing')
	// }

	// Use Set to get distinct string keys
	return {
		images: validImages,
		disabledReasons: [...new Set(disabledReasons)],
	}
}
