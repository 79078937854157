<template>
	<vue-select
		ref="vueSelect"
		v-bind="$attrs"
		class="ast-v-select"
		:options="uniqueOptions"
		:class="{ disabled: disabled }"
		:calculate-position="$props.calculatePosition || positionDropdown"
		:append-to-body="$props.appendToBody!==false"
		:tabindex="disabled ? -1 : 0"
		select-on-tab
		v-on="$listeners"
		@keydown.native.esc.stop
	>
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
			<slot :name="slot" v-bind="scope"></slot>
		</template>
	</vue-select>
</template>

<script>
import VueSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

export default {
	name: 'VSelect',
	components: {
		VueSelect,
	},
	props: {
		options: {
			type: Array,
			required: true,
		},
		dropdownMinWidth: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		uniqueOptions() {
			return [...new Set(this.options)]
		},
	},
	methods: {
		positionDropdown(dropdownList, component, { width }) {
			// Override default styles
			dropdownList.style.width = width
			if (this.dropdownMinWidth) dropdownList.style.minWidth = this.dropdownMinWidth
			dropdownList.style.maxHeight = '200px'
			dropdownList.style.zIndex = '10000'

			// Position the dropdownList relative to the $refs.toggle Element.
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom-start',
      })

			// To prevent memory leaks Popper needs to be destroyed.
			// If you return function, it will be called just before dropdown is removed from DOM.
			return () => popper.destroy()
		},
	},
}
</script>

<style lang="scss">
.ast-v-select {
	&.disabled {
		pointer-events: none;
		opacity: 0.7;
	}
}
</style>
