import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { findImageUrlFromImage } from '@utils/urlUtility'
import ViewerTool from '@store/models/viewerTool'
import setActiveTool from './setActiveTool'
import { eventBus } from '@services/eventBus'
import isMobileOS from '@utils/isMobileOS'
import { downloadFile } from '@services/api'
import studyData from '@services/studyData'

const tools = [
	{
		className: 'AstCalibration',
		instanceName: 'AstCalibration',
		isCustom: true,
	},
	{
		className: 'AstRectangleRoi',
		instanceName: 'AstRectangleRoi',
		isCustom: true,
	},
	{
		className: 'AstCircleRoi',
		instanceName: 'AstCircleRoi',
		isCustom: true,
	},
	{
		className: 'AstEllipticalRoi',
		instanceName: 'AstEllipticalRoi',
		isCustom: true,
	},
	{
		className: 'AstCrossPoint',
		instanceName: 'AstCrossPoint',
		isCustom: true,
	},
	{
		className: 'AstEraser',
		instanceName: 'AstEraser',
		isCustom: true,
	},
	{
		className: 'AstHipDysplasia',
		instanceName: 'AstHipDysplasia',
		isCustom: true,
	},
	{
		className: 'AstLengthAngle',
		instanceName: 'AstLengthAngle',
		isCustom: true,
	},
	{
		className: 'AstOrientationMarkers',
		instanceName: 'AstOrientationMarkers',
		isCustom: true,
		config: {
			configuration: {
				drawAllMarkers: true,
			},
		},
	},
	{
		className: 'AstPennHip',
		instanceName: 'AstPennHip',
		isCustom: true,
	},
	{
		className: 'AstTplo',
		instanceName: 'AstTplo',
		isCustom: true,
	},
	{
		className: 'AstTta',
		instanceName: 'AstTta',
		isCustom: true,
	},
	{
		className: 'AstVertebralHeart',
		instanceName: 'AstVertebralHeart',
		isCustom: true,
	},
	{
		className: 'AstText',
		instanceName: 'AstText',
		isCustom: true,
	},
	{
		className: 'AstArrowAnnotate',
		instanceName: 'AstArrowAnnotate',
		isCustom: true,
	},
	{
		className: 'AstAngle',
		instanceName: 'AstAngle',
		isCustom: true,
	},
	{
		className: 'AstProbe',
		instanceName: 'AstProbe',
		isCustom: true,
	},
	{
		className: 'MagnifyTool',
		instanceName: 'Magnify',
		config: {
			configuration: {
				magnifySize: parseInt(350, 10),
				magnificationLevel: 1.5,
			},
		},
	},
	{
		className: 'PanTool',
		instanceName: 'Pan',
	},
	{
		className: 'PanMultiTouchTool',
		instanceName: 'PanMultiTouch',
		config: { configuration: { touchPointers: 2 } },
	},
	{
		className: 'AstReferenceLines',
		instanceName: 'ReferenceLines',
		isCustom: true,
	},
	{
		className: 'AstRotateTool',
		instanceName: 'Rotate',
		isCustom: true,
	},
	{
		className: 'StackScrollTool',
		instanceName: 'StackScroll',
	},
	{
		className: 'StackScrollMouseWheelTool',
		instanceName: 'StackScrollMouseWheel',
	},
	{
		className: 'WwwcTool',
		instanceName: 'Wwwc',
	},
	{
		className: 'ZoomTool',
		instanceName: 'Zoom',
		config: {
			defaultStrategy: 'zoomToCenter',
			configuration: {
				minScale: 0.05,
				maxScale: 20.0,
				invert: true,
				preventZoomOutsideImage: true,
			},
		},
	},
	{
		className: 'ZoomMouseWheelTool',
		instanceName: 'ZoomMouseWheel',
		config: {
			configuration: {
				minScale: 0.05,
				maxScale: 20.0,
			},
		},
	},
	{
		className: 'ZoomTouchPinchTool',
		instanceName: 'ZoomTouchPinch',
		config: {
			configuration: {
				minScale: 0.05,
				maxScale: 20.0,
			},
		},
	},
]

const toolButtons = [
	new ViewerTool({
		name: 'WwwcTool',
		alias: 'Wwwc',
		label: 'Levels',
		title: 'Window Levels',
		groups: ['levels'],
		iconName: 'brightness-6',
	}),
	new ViewerTool({
		name: 'ZoomTool',
		alias: 'Zoom',
		label: 'Zoom',
		title: 'Zoom Image',
		groups: ['zoom'],
		iconName: 'magnify',
	}),
	new ViewerTool({
		name: 'PanTool',
		alias: 'Pan',
		label: 'Pan',
		title: 'Pan Image',
		groups: [],
		iconName: 'arrow-all',
	}),
	new ViewerTool({
		name: 'MagnifyTool',
		alias: 'Magnify',
		label: 'Magnify',
		title: 'Magnify Image',
		groups: ['zoom'],
		iconName: 'eye-plus-outline',
	}),
	new ViewerTool({
		name: 'AstLengthAngle',
		alias: 'AstLengthAngle',
		label: 'Length',
		title: 'Length',
		groups: ['annotation'],
		iconName: 'vector-line',
	}),
	new ViewerTool({
		name: 'AstRectangleRoi',
		alias: 'AstRectangleRoi',
		label: 'Rect',
		title: 'Rectangle',
		groups: ['annotation'],
		iconName: 'vector-rectangle',
	}),
	new ViewerTool({
		name: 'AstCircleRoi',
		alias: 'AstCircleRoi',
		label: 'Circle',
		title: 'Circle',
		groups: ['annotation'],
		iconName: 'vector-circle-variant',
	}),
	new ViewerTool({
		name: 'AstEllipticalRoi',
		alias: 'AstEllipticalRoi',
		label: 'Ellipse',
		title: 'Ellipse',
		groups: ['annotation'],
		iconName: 'vector-ellipse',
	}),
	new ViewerTool({
		name: 'AstAngle',
		alias: 'AstAngle',
		label: 'Angle',
		title: 'Angle',
		groups: ['annotation'],
		iconName: 'angle',
	}),
	new ViewerTool({
		name: 'AstText',
		alias: 'AstText',
		label: 'Text',
		title: 'Text Annotation',
		groups: ['annotation'],
		iconName: 'format-text',
	}),
	new ViewerTool({
		name: 'AstArrowAnnotate',
		alias: 'AstArrowAnnotate',
		label: 'Arrow',
		title: 'Arrow Annotation',
		groups: ['annotation'],
		iconName: 'arrow-bottom-left',
	}),
	new ViewerTool({
		name: 'AstEraser',
		alias: 'AstEraser',
		label: 'Eraser',
		title: 'Eraser',
		groups: ['annotation'],
		iconName: 'eraser',
	}),
	new ViewerTool({
		name: 'AstCalibration',
		alias: 'AstCalibration',
		label: 'Calibrate',
		title: 'Calibration',
		groups: ['annotation'],
		iconName: 'ruler',
	}),
	new ViewerTool({
		name: 'AstVertebralHeart',
		alias: 'AstVertebralHeart',
		label: 'VHS',
		title: 'Vertebral Heart Score',
		groups: ['surgical'],
		iconName: 'vertebral-heart',
	}),
	new ViewerTool({
		name: 'AstTplo',
		alias: 'AstTplo',
		label: 'TPLO',
		title: 'Tibial Plateau Leveling Osteotomy',
		groups: ['surgical'],
		iconName: 'tplo',
	}),
	new ViewerTool({
		name: 'AstTta',
		alias: 'AstTta',
		label: 'TTA',
		title: 'Tibial Tuberosity Advancement',
		groups: ['surgical'],
		iconName: 'tta',
	}),
	new ViewerTool({
		name: 'AstHipDysplasia',
		alias: 'AstHipDysplasia',
		label: 'Hip D',
		title: 'Hip Dysplasia Measurement',
		groups: ['surgical'],
		iconName: 'hip-dysplasia',
	}),
	new ViewerTool({
		name: 'AstPennHip',
		alias: 'AstPennHip',
		label: 'DI',
		title: 'Distraction Index (PennHIP)',
		groups: ['surgical'],
		iconName: 'pennhip',
	}),
	new ViewerTool({
		name: 'AstVPop',
		alias: 'AstVPop',
		label: 'vPop',
		title: 'vPOP Orthopaedic Planning',
		groups: ['surgical'],
		iconName: 'vpop',
		refresh(getters) {
			this.disabled = !getters.activeStudy || !getters.activeStudy.isArchived
			this.hidden = !getters.permissions.vPopExport
		},
		command(activeSeries, activeImage, activeStudy) {
			if (!activeStudy.isArchived) return studyData.showArchivingTip()
			// Build the image url
			const imageUrl = findImageUrlFromImage({ ...activeImage, imageType: 'JpegDicom' }, activeSeries, true)
			const url = `vpop://open-image-url?location=${imageUrl}`
			if (isMobileOS()) {
				window.open(url, '_blank')
			} else {
				downloadFile(url)
			}
		},
	}),
	new ViewerTool({
		name: 'StackScrollTool',
		alias: 'StackScroll',
		label: 'Scroll',
		title: 'Stack Scroll',
		groups: ['scroll'],
		iconName: 'layers',
	}),
	new ViewerTool({
		name: 'AstCrossPoint',
		alias: 'AstCrossPoint',
		label: 'Point',
		title: 'Cross Point',
		groups: ['scroll'],
		iconName: 'crosshairs',
	}),
	new ViewerTool({
		name: 'AstProbe',
		alias: 'AstProbe',
		label: 'Probe',
		title: 'Probe',
		groups: ['scroll'],
		iconName: 'eyedropper-variant',
		refresh({ activeSeries, activeCanvas }) {
			let disabledReason = ''
			if (!activeSeries || !activeSeries.modality || activeSeries.modality.toLowerCase() !== 'ct') {
				disabledReason = 'CT series required'
			} else if (activeCanvas.dom) {
				try {
					const image = cornerstone.getImage(activeCanvas.dom)
					if (image && !image.isDicom) disabledReason = 'DICOM rendering required'
				} catch {}
			}
			this.disabledReason = disabledReason
			this.disabled = !!disabledReason
		},
	}),
	new ViewerTool({
		name: 'RotateTool',
		alias: 'Rotate',
		label: 'Roll',
		title: 'Roll View',
		groups: [],
		iconName: 'roll-tool',
	}),
]

// Could probably Object.assign these to merge.
export default {
	state: {
		tools,
		toolButtons,
		inputDevices: [],
	},
	getters: {
		availableTools(state, getters) {
			const { activeClinic } = getters
			return state.toolButtons
				.map(tool => tool.resolve(getters))
				.filter(tool => {
					if (tool.hidden) return false
					if (!tool.isOptionalFeature) return true
					if (!activeClinic || !activeClinic.optionalFeatures) return false
					return activeClinic.optionalFeatures.includes(tool.name)
				})
		},
		isRightClickHandledByTool(state, getters) {
			const isBoundToRightButton = ({ binding }) => /(^R)|LR|MR/g.test(binding) // excludes L+R, etc.
			const boundTool = getters.availableTools.find(isBoundToRightButton)
			if (!boundTool) return false
			// prettier-ignore
			const toolsWithSingleClickActions = [
				'AstCircleRoi',
				'AstArrowAnnotate',
				'AstEraser',
				'AstCrossPoint',
			]
			return toolsWithSingleClickActions.includes(boundTool.name)
		},
	},
	mutations: {
		ADD_INPUT_DEVICE(state, device) {
			const devices = state.inputDevices.slice()
			devices.push(device)
			state.inputDevices = devices
		},
		UPDATE_TOOL_BINDINGS: state => {
			const toolButtons = state.toolButtons.slice()
			toolButtons.forEach(tool => {
				tool.binding = ''
				const globalTool = cornerstoneTools.store.state.globalTools[tool.alias]
				if (globalTool && globalTool.activeBindings) {
					const availableBindings = globalTool.activeBindings.filter(binding => {
						// Show all bindings if no input devices have been detected yet
						if (!state.inputDevices.length) return true
						// Show mouse bindings if mouse detected; show touch bindings if touch detected
						const deviceMatchesBinding = device => binding.toLowerCase().includes(device)
						return state.inputDevices.some(deviceMatchesBinding)
					})
					tool.binding = abbreviateBinding(availableBindings)
				}
			})
			state.toolButtons = toolButtons
		},
	},
	actions: {
		setActiveToolAndBroadcast({ dispatch }, payload) {
			const type = 'setActiveTool'
			dispatch(type, payload)
			eventBus.broadcast(eventBus.type.VUEX_ACTION, { type, payload })
		},
		setActiveTool,
		listenForInputDevices({ commit }) {
			window.addEventListener('mousemove', onMouseMove)
			window.addEventListener('mousedown', onMouseDown)
			window.addEventListener('touchstart', onTouchStart)

			function onMouseMove(event) {
				if (event.movementX || event.movementY) addMouse()
			}
			function onMouseDown(event) {
				// In case of touch setups with secondary button capabilities of a mouse
				const isMiddleOrRight = [2, 4].includes(event.buttons) || [2, 3].includes(event.which)
				if (isMiddleOrRight) addMouse()
			}
			function addMouse() {
				commit('ADD_INPUT_DEVICE', 'mouse')
				commit('UPDATE_TOOL_BINDINGS')
				window.removeEventListener('mousemove', onMouseMove)
				window.removeEventListener('mousedown', onMouseDown)
			}
			function onTouchStart() {
				commit('ADD_INPUT_DEVICE', 'touch')
				commit('UPDATE_TOOL_BINDINGS')
				window.removeEventListener('touchstart', onTouchStart)
			}
		},
	},
}

function abbreviateBinding(bindings) {
	if (!bindings || !bindings.length) return
	const abbreviations = {
		'Mouse-1': 'L',
		'Mouse-2': 'R',
		'Mouse-3': 'L+R',
		'Mouse-4': 'M',
		'Mouse-5': 'L+M',
		'Mouse-6': 'M+R',
		'Mouse-7': 'L+M+R',
		Touch: ' ',
	}
	return bindings.map(binding => abbreviations[binding]).join('')
}
