<template>
  <div>
    <editor
      :key="componentKey"
      v-model="content"
      :api-key="apiKey"
      :init="initValues"
      :initial-value="initialValue"
    />
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import {mapState} from "vuex"
import {getTinymce} from "@tinymce/tinymce-vue/lib/es2015/main/ts/TinyMCE"
import api from "@services/api"
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { openPromptDlg } from '@dialogs/TextPromptAltDlg.vue'
import axios from "axios";

let tempObj = {}
export default {
  name: "WysiwygEditorTiny",
  components: {
    editor : Editor
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    editPresets: {
      type: Boolean,
      default: false
    },
    toolbarOptions: {
      type: Object,
      default: () => ({
        container: [],
        handlers: {},
      }),
    },
    plainTextMode: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      apiKey: 'no-api-key',
      content: '',
      initialValue: '',
      initValues: {},
      debounce: null,
      presetItems: [],
      TINYMCE_CDN: `${window.config.tinymceCDNUrl}/tinymce.min.js`,
      rteSettings: {
        pasteAsText: window.editorTextMode || false,
        requestFormattingConfirmation: true
      },
      componentKey: 0,
      tinyMCEConf: {},
    }
  },
  computed: {
    ...mapState({
      autoCompleteList: state => state.settings.autoComplete.list,
    }),
  },
  watch: {
    content(n) {
      const tinyMCE = getTinymce()
      const textValue =  tinyMCE.activeEditor.getContent({format: 'text'})
      this.$emit('input', this.content, textValue)
    },
    value(v) {
      this.content = v
    },
    rteSettings() {
      const tinyMCE = getTinymce()
      setTimeout(() => {
        this.initValues.paste_as_text = this.rteSettings.pasteAsText
        this.forceRerender()
        this.$forceUpdate()
      },300)
    }
  },
  created() {
    this.tinyMCEConf = window.tinyMCEConf
    if (this.toolbarOptions.container.length > 0){
      const presets = this.toolbarOptions.container
      presets.forEach(x => {
        if (x !== '') {
          let preset = {
            type: 'menuitem',
            text: x,
            value: x,
          }
          this.presetItems.push(preset)
        }
      })
    }

    api.user.getUserSetting('RichTextEditor', 'OmniReport').then((response) => {
        if (response.data) {
          this.rteSettings = response.data
        }
    })

    this.initialValue = this.value || ''
    this.initValues = {
      height: 300,
      skin: 'jam',
      icons_url: `${window.config.tinymceCDNUrl}/icons/jam/icons.js`,
      icons: 'jam',
      referrer_policy: 'origin',
      theme: "silver",
      base_url: window.config.tinymceCDNUrl,
      branding: false,
      elementpath: false,
      setup: this.setup,
      menubar: this.tinyMCEConf.menubar,
      plugins: this.tinyMCEConf.plugins,
      toolbar: this.tinyMCEConf.toolbar,
      paste_as_text: this.rteSettings.pasteAsText,
      toolbar_mode: 'wrap',
      spellchecker_language: 'en',
      spellchecker_rpc_url: `${window.config.spellingServiceUrl}/ephox-spelling/`,
      autocorrect_service_url: `${window.config.spellingServiceUrl}/ephox-spelling/`,
      autocorrect_capitalize: false,
      content_langs: [
        { title: 'English (US)', code: 'en_US' },
        { title: 'English (US Medical)', code: 'en_US', customCode: 'en_US-medical' },
        { title: 'English (UK)', code: 'en_UK' },
        { title: 'English (UK Medical)', code: 'en_UK', customCode: 'en_UK-medical' },
      ],
      mediaembed_service_url: `${window.config.hyperlinkingServiceUrl}/ephox-hyperlinking`,
      mediaembed_max_width: 450,
      powerpaste_html_import: this.pasteFormattingHandling,
      powerpaste_word_import: this.pasteFormattingHandling,
      powerpaste_googledocs_import: this.pasteFormattingHandling,
      text_patterns_lookup: (ctx) => {
        const words = ctx.text.trim().split(" ");
        const lastWord = words[words.length - 1];
        const replacements = this.createReplacementPatterns()
        const replacement = replacements.find(x => x.start === lastWord)
        let response = []
        if (replacement) {
          response = [ replacement ]
        }
        return response;
      }
    }
  },
  methods: {
    pasteFormattingHandling() {
      window._this = this
      return new Promise(async function (resolve) {
        if (window._this.rteSettings.requestFormattingConfirmation) {
          const params = {
            buttonLabel: 'Yes',
            title: 'Text formatting',
            message: ' It looks like you\'re pasting text with special formatting. Would you like to keep this formatting when pasting into the editor?',
            prompt: 'Don\'t ask again',
            defaultValue: false,
            requireInput: true,
            showCloseButton: true,
            closeText: 'No',
          }
          const response = await openPromptDlg(params)
          if (response.prompt) {
            window._this.rteSettings = Object.assign({}, {
              pasteAsText: !response.action,
              requestFormattingConfirmation: !response.prompt
            })
          }
          await api.user.setUserSetting('RichTextEditor', 'OmniReport', JSON.stringify(window._this.rteSettings))
          if (response.action) {
            resolve('merge');
          } else {
            resolve('clean');
          }
        } else {
          if (!window._this.rteSettings.pasteAsText) {
            resolve('merge');
          } else {
            resolve('clean');
          }
        }
      })
    },
    forceRerender() {
      this.componentKey += 1;
    },
    createReplacementPatterns() {
      // This creates the text_pattern uses to replace the macros with the corresponding text
      // More info: https://www.tiny.cloud/docs/tinymce/6/content-behavior-options/#replacements-patterns
      let textPatterns = []
      this.autoCompleteList.forEach(x => {
        const pattern = {
          start: x.key,
          replacement: x.replacement.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }
        textPatterns.push(pattern)
      })
      return textPatterns
    },
    setup(editor) {
      editor.baseURL = window.config.tinymceCDNUrl
      editor._presets = this.presetItems
      editor._editPresets = this.editPresets
      editor._presetCallback = this.toolbarOptions.handler
      editor._this = this
      if (this.presetItems.length > 0) {
        editor.ui.registry.addMenuButton('presets', {
          text: 'Presets',
          tooltip: 'Presets',
          fetch: function (callback) {
            let items = []
            editor._presets.forEach(x => {
              let item = {
                type: x.type,
                text: x.text,
                onAction: function () {
                  editor._presetCallback(x.text)
                }
              }
              items.push(item)
            })
            callback(items);
          },
        })
      }
      editor.on('ExecCommand', async function (e) {
        if (e.command === 'mceTogglePlainTextPaste') {
          editor._this.togglePasteAsText()
          await api.user.setUserSetting('RichTextEditor', 'OmniReport', JSON.stringify(editor._this.rteSettings))
        }
      })
    },
    update(value) {
      const data = {
        id: this.id,
        textValue: value,
      }
      this.$emit('input', value, data)
    },
    togglePasteAsText() {
      this.rteSettings.pasteAsText = !this.rteSettings.pasteAsText
      this.rteSettings.requestFormattingConfirmation = true
    },
  }
}
</script>

<style>
.ephox-summary-card {
  border: 1px solid #AAA;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  padding: 10px;
  overflow: hidden;
  margin-bottom: 1em;
}

.ephox-summary-card a {
  text-decoration: none;
  color: inherit;
}

.ephox-summary-card a:visited {
  color: inherit;
}

.ephox-summary-card-title {
  font-size: 1.2em;
  display: block;
}

.ephox-summary-card-author {
  color: #999;
  display: block;
  margin-top: 0.5em;
}

.ephox-summary-card-website {
  color: #999;
  display: block;
  margin-top: 0.5em;
}

.ephox-summary-card-thumbnail {
  max-width: 180px;
  max-height: 180px;
  margin-left: 2em;
  float: right;
}

.ephox-summary-card-description {
  margin-top: 0.5em;
  display: block;
}


/* TinyMCE dropdown CSS workaround to show dropdown in a modal */
.tox-tinymce-aux {
  position: relative !important;
  z-index: 7000 !important;
}


</style>
