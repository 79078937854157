const archive = ['7z', 'cab', 'dmg', 'iso', 'jar', 'rar', 'tar', 'tgz', 'zip']
const video = [
	'3g2',
	'3gp',
	'aaf',
	'asf',
	'avchd',
	'avi',
	'drc',
	'flv',
	'm2v',
	'm4p',
	'm4v',
	'mkv',
	'mng',
	'mov',
	'mp2',
	'mp4',
	'mpe',
	'mpeg',
	'mpg',
	'mpv',
	'mxf',
	'nsv',
	'ogg',
	'ogv',
	'ogm',
	'qt',
	'rm',
	'rmvb',
	'roq',
	'srt',
	'svi',
	'vob',
	'webm',
	'wmv',
	'yuv',
]
const system = ['bin', 'dll', 'exe', 'inf', 'js', 'msi', 'sh', 'sql', 'vbs']
const excludedImage = [
	'3dm',
	'3ds',
	'ai',
	'dds',
	'dwg',
	'dxf',
	'eps',
	'gif',
	'gpx',
	'kml',
	'kmz',
	'max',
	'ps',
	'psd',
	'svg',
	'tga',
	'thm',
	'xcf',
	'yuv',
]
const doc = [
	'csv',
	'doc',
	'docx',
	'ebook',
	'ics',
	'log',
	'md',
	'msg',
	'odp',
	'ods',
	'odt',
	'org',
	'pages',
	'pdf',
	'ppt',
	'pptx',
	'rtf',
	'tex',
	'txt',
	'vcf',
	'wpd',
	'wps',
	'xls',
	'xlsx',
]
const web = ['css', 'htm', 'html', 'js', 'jsx', 'less', 'scss', 'wasm']

const nonImageExtensions = [...archive, ...video, ...excludedImage, ...doc, ...system, ...web]

const imageExtensions = ['bmp', 'jpeg', 'jpg', 'png', 'tif', 'tiff']

// From StudyAttachmentScreen in Keystone thick client
const excludedForAttachments = [
	'accde',
	'app',
	'appx',
	'application',
	'as',
	'bas',
	'bat',
	'bin',
	'chm',
	'cmd',
	'com',
	'cpl',
	'crt',
	'dex',
	'dll',
	'docm',
	'dotm',
	'exe',
	'fxp',
	'gadget',
	'hta',
	'inf',
	'ins',
	'inx',
	'ipf',
	'isu',
	'jar',
	'job',
	'js',
	'jse',
	'jsx',
	'lnk',
	'mam',
	'mexw32',
	'msc',
	'msh',
	'msh1',
	'msh1xml',
	'msh2',
	'msh2xml',
	'mshxml',
	'msi',
	'msp',
	'oat',
	'odex',
	'otm',
	'paf',
	'pif',
	'pl',
	'plx',
	'potm',
	'ppam',
	'ppsm',
	'pptm',
	'ps1',
	'ps1xml',
	'ps2',
	'ps2xml',
	'psc1',
	'psc2',
	'py',
	'pyc',
	'pyd',
	'pyo',
	'pyz',
	'reg',
	'rgs',
	'scf',
	'scr',
	'sct',
	'shb',
	'shs',
	'sldm',
	'tlb',
	'u3p',
	'url',
	'vb',
	'vbe',
	'vbs',
	'vbscript',
	'wiz',
	'ws',
	'wsc',
	'wsf',
	'wsh',
	'xap',
	'xbap',
	'xlam',
	'xlm',
	'xlsm',
	'xltm',
]

const getExtension = file =>
	file.name
		.split('.')
		.pop()
		.toLowerCase()

export function hasNonImageExtension(file) {
	const hasNoExtension = !file.name.includes('.')
	if (hasNoExtension) return false
	const extension = getExtension(file)
	return nonImageExtensions.includes(extension)
}

export function hasImageExtension(file) {
	const extension = getExtension(file)
	return imageExtensions.includes(extension)
}

export function hasValidAttachmentExtension(file) {
	const extension = getExtension(file)
	return !excludedForAttachments.includes(extension)
}

export function getVideoExtensions() {
	return video
}

export function getDocumentExtensions() {
	return doc
}
