<template>
	<div class="input-wrapper">
		<label v-if="label" class="input-label" :for="id">{{ label }}</label>
		<input
			v-if="['text', 'password', 'number'].includes(type)"
			:id="id"
			ref="input"
			:type="type"
			:name="id"
			:value="value"
			:disabled="disabled"
			:placeholder="placeholder"
			class="input"
			:class="{ 'has-icon': icon, 'has-error': hasError }"
			:maxlength="maxlength"
			@input="handleInput"
		/>
		<textarea
			v-else-if="type === 'textarea'"
			:id="id"
			ref="input"
			class="input"
			:class="{ 'has-error': hasError }"
			:name="id"
			:value="value"
			:placeholder="placeholder"
			:maxlength="maxlength"
			@input="handleInput"
		></textarea>
		<svg-icon v-if="icon" :icon="icon" class="input-icon" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'debounce'

export default {
	name: 'Input',
	props: {
		// Config
		id: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: 'text',
			validator: function(type) {
				return ['text', 'password', 'textarea', 'number'].indexOf(type) !== -1
			},
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: null,
		},
		debounceMilliseconds: {
			type: Number,
			default: 0,
		},
		handleAutoComplete: {
			type: Boolean,
			default: false,
		},
		value: {
			type: [String, Number],
			default: '',
		},
		maxlength: {
			type: String,
			default: null,
		},
		onlyNumbers: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState({
			autoCompleteList: state => state.settings.autoComplete.list,
		}),
	},
	created() {
		this.handleInput = debounce(this.handleInput, this.debounceMilliseconds)
	},
	methods: {
		handleInput() {
			const input = this.$refs && this.$refs.input
			if (!input) return
			// Initial replacement state
			const numRegex = new RegExp('^[0-9]+$')
			if (this.onlyNumbers) {
				if (!numRegex.test(input.value)) {
					input.value = input.value.slice(0, -1)
				}
			}
			let state = {
				text: input.value,
				position: input.selectionStart,
			}
			if (this.handleAutoComplete) {
				// Update replacement state for each auto-complete entry
				this.autoCompleteList.forEach(({ key, replacement }) => {
					state = this.replaceText({ ...state, key, replacement })
				})
				// Reset cursor position if needed
				if (state.position !== input.selectionStart) {
					this.$nextTick(() => {
						input.setSelectionRange(state.position, state.position)
					})
				}
			}
			this.$emit('input', state.text)
		},
		replaceText({ text, key, replacement, position }) {
			// All keys at beginning of words followed by punctuation, space or newline
			// Capture punctuation and spaces in group 2 for later replacment
			const regex = new RegExp(`\\b${key}(([-,.?;:! ])|\\n)`, 'gi')
			let match
			const matches = []
			// Record all regex matches with index/text data
			while ((match = regex.exec(text)) !== null) {
				matches.push({ index: match.index, text: match[0] })
			}
			// Perform a global text replacement
			text = text.replace(regex, `${replacement}$2`)
			// Calculate the new cursor position
			position = matches.reduce((prev, curr) => {
				return prev + (curr.index < position ? replacement.length - curr.text.length : 0)
			}, position)
			// Return the new replacement state
			return {
				text,
				key,
				replacement,
				position,
				matches,
			}
		},
	},
}
</script>
