export default function(imageFrame, imagePixelModule) {
  const pixels = imageFrame.columns * imageFrame.rows
  const pixelData = imageFrame.pixelData
  const rData = imagePixelModule.redPaletteColorLookupTableData
  const gData = imagePixelModule.greenPaletteColorLookupTableData
  const bData = imagePixelModule.bluePaletteColorLookupTableData
  const len = imagePixelModule.redPaletteColorLookupTableData.length
  let palIndex = 0
  let rgbaIndex = 0

  const start = imagePixelModule.redPaletteColorLookupTableDescriptor[1]
  const shift = imagePixelModule.redPaletteColorLookupTableDescriptor[2] === 8 ? 0 : 8

  const rDataCleaned = convertLUTto8Bit(rData, shift)
  const gDataCleaned = convertLUTto8Bit(gData, shift)
  const bDataCleaned = convertLUTto8Bit(bData, shift)

  for (let i = 0; i < pixels; ++i) {
    let value = pixelData[palIndex++]

    if (value < start) {
      value = 0
    } else if (value > start + len - 1) {
      value = len - 1
    } else {
      value -= start
    }

    imageFrame.pixelData[rgbaIndex++] = rDataCleaned[value]
    imageFrame.pixelData[rgbaIndex++] = gDataCleaned[value]
    imageFrame.pixelData[rgbaIndex++] = bDataCleaned[value]
    imageFrame.pixelData[rgbaIndex++] = 255
  }
}

function convertLUTto8Bit (lut, shift) {
  const numEntries = lut.length
  const cleanedLUT = new Uint8ClampedArray(numEntries)

  for (let i = 0; i < numEntries; ++i) {
    cleanedLUT[i] = lut[i] >> shift;
  }

  return cleanedLUT
}
