import api from '@services/api'

/**
 * 1. Generates a base64 image for the active mpr view w/ annotations
 * 2. Kicks off an API call to add the annotated image to the study
 * 3. Dispatches success notification
 * NOTE: Manually updating ViewModel is not needed due to study synchronization
 *
 * @export
 * @param {*} { getters, commit, dispatch }, { reportId, showNotification }
 */
export default async function({ getters, commit, dispatch }, { reportId, showNotification = true } = {}) {
	// Get component for the active view
	const activeComp = getters.mprActiveView.component
	// Render the viewer to a base64 image
	const imgBase64 = await activeComp.renderImage()

	const { clinicCode } = getters.activeImage.storageLocation || getters.activeSeries.storageLocation
	const params = {
		clinicCode,
		sourceImageId: getters.activeImage.imageId,
		imgBase64,
		reportId,
	}

	// API call to add the annotated image to the study
	const { series } = await api.viewer.renderAnnotatedImage(params)

	// Show notification
	if (showNotification)
		dispatch('addNotification', {
			message: 'New image with burned in annotations successfully created!',
			notificationType: 'info',
		})

	return series
}
