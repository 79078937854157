<template>
	<div style="display: inline-flex; align-items: center;">
		<label for="selectedConsultant" style="padding-right: 1ch;">
			Consultant:
			<small class="is-danger">*</small>
		</label>
		<v-select
			v-if="!cell.readOnly && members.length"
			name="consultant"
			:options="members"
			label="name"
			:searchable="false"
			:clearable="false"
			:value="value"
			@input="consultant => (value = consultant)"
		>
			<template slot="option" slot-scope="option">
				{{ option.name }}
				<p v-if="option.isOutOfOffice" class="out-of-office is-danger">
					<small v-if="option.outOfOfficeMessage">
						{{ option.outOfOfficeMessage }}
					</small>
					<small v-else>Out of office</small>
				</p>
			</template>
		</v-select>
		<span v-else style="padding: 4px 0;">
			{{ (value && value.name) || (cell.report.consultant && cell.report.consultant.name) }}
		</span>
	</div>
</template>

<script>
import { Cell } from '../classes'

export default {
	name: 'ConsultantSelector',
	components: {},
	props: ['cell'],
	computed: {
		members() {
			if (this.cell.editorMode) return []
			return this.cell.report.consultants || []
		},
		value: {
			get() {
				if (this.cell.editorMode) return { name: 'John Doe' }
				return this.cell.report.selectedConsultant
			},
			set(value) {
				if (this.cell.editorMode) return
				this.cell.report.selectedConsultant = value
			},
		},
	},
	created() {
		if (!this.value) {
			this.value = this.members[0]
		}
	},
}
</script>
