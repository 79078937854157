import { openStatWarning } from '@dialogs/statWarningDlg.vue'
import { API } from '@services/api'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { eventBus } from './eventBus'
import { setLongTimeout, clearLongTimeout } from '@utils/timeout'

interface IStatReportInfo {
	claimDate: string
	reportId: string
	lockWarningMinutes: number
	lockRepeatWarningMinutes: number
	description: string
	priority: string
}

class StatItem {
	consultantReportId: string
	claimDate: Date
	timerId = 0
	active = true
	warningMinutes: number
	repeatWarningMinutes: number
	description: string
	priority: string

	constructor(info: IStatReportInfo) {
		this.consultantReportId = info.reportId
		this.claimDate = new Date(info.claimDate + 'Z')
		this.timerId = 0
		this.active = true
		this.warningMinutes = info.lockWarningMinutes
		this.repeatWarningMinutes = info.lockRepeatWarningMinutes
		this.description = info.description
		this.priority = info.priority
	}

	get minutesLocked() {
		let now = new Date()
		let diff = now.getTime() - this.claimDate.getTime()
		return Math.floor(diff / 60 / 1000)
	}
}

class LockService {
	items: StatItem[] = []
	refreshInterval: number = null

	clear(all = true) {
		// disable and remove irrelevant items
		for (let i = this.items.length - 1; i >= 0; i--) {
			let item = this.items[i]
			if (!item.active || all) {
				clearLongTimeout(item.timerId)
				this.items.splice(i, 1)
			}
		}
	}

	async fetchInfo() {
		// fetch all STAT reports claimed by this user
		let newItems: IStatReportInfo[] = await API.get(`/teleconsultation/report-lock-info`).then(r => r.data)

		let existing: { [key: string]: StatItem } = {}
		this.items.forEach(i => {
			existing[i.consultantReportId] = i
			i.active = false
		})

		let now = new Date()

		newItems.forEach(i => {
			let item = existing[i.reportId]
			if (!item) {
				item = new StatItem(i)
				let warningMS = item.warningMinutes * 60 * 1000
				let warnTime = item.claimDate.getTime() + warningMS
				let diff = warnTime - now.getTime()

				item.timerId = setLongTimeout(() => {
					this.giveWarning(item)
				}, diff)

				this.items.push(item)
			} else {
				item.active = true
			}
		})

		this.clear(false)
	}

	startAutoRefreshing() {
		if (this.refreshInterval !== null) return
		const MINUTES = 30
		this.refreshInterval = setInterval(this.fetchInfo.bind(this), MINUTES * 1000 * 60)
		this.fetchInfo()
	}
	stopAutoRefreshing() {
		clearInterval(this.refreshInterval)
	}

	// warn
	async giveWarning(item: StatItem) {
		let keepLock = await openStatWarning(item)
		if (keepLock === true) {
			item.timerId = setLongTimeout(() => {
				this.giveWarning(item)
			}, item.repeatWarningMinutes * 60 * 1000)
		} else {
			await this.unlock(item.consultantReportId, true)
			if (keepLock === undefined) {
				this.showUnlockAlert(item)
			}
		}
	}

	showUnlockAlert(item) {
		let msg = `The report for patient ${item.description} has been locked by you for over ${
			item.minutesLocked
		} minutes. Keystone did not receive a response from you so the report has been released back to the group for completion.`
		showAlert(msg)
	}

	async lock(consultantReportId: string) {
		await API.post(`/teleconsultation/${consultantReportId}/lock`)
		eventBus.post('refresh-report')
		return this.fetchInfo()
	}

	async unlock(consultantReportId: string, isAutoUnlock: boolean) {
		await API.post(`/teleconsultation/${consultantReportId}/unlock/`, null, { params: { isAutoUnlock } })
		eventBus.post('refresh-report')
		return this.fetchInfo()
	}
}

export const lockService = new LockService()
// @ts-ignore
window.lockService = lockService
