import api from '@services/api'
import keyboard from '@services/keyboard'

const state = {
	presetHotkeys: [],
	setLevelMethod: null,
	lastLoadedModalityId: -1,
}
const mutations = {
	bindWindowLevelPresets: (state, { modalityId, presets, setLevelMethod }) => {
		state.setLevelMethod = setLevelMethod
		state.lastLoadedModalityId = modalityId
		// reset bindings
		state.presetHotkeys.forEach(({ key, method }) => keyboard.unbind(key, method))
		state.presetHotkeys = []

		presets.forEach(p => {
			if (p.key !== null) {
				let key = p.key
				let method = setLevelMethod.bind(this, p.windowWidth, p.windowCenter)
				state.presetHotkeys.push({ key: key, method: method })
				keyboard.bind(key, method)
			}
		})
	},
	resetLastLoadedModalityPreset(state) {
		state.lastLoadedModalityId = -1
	},
}

const actions = {
	async refreshWindowLevelPresetsForAuthenticatedUser(store) {
		const modalityId = getModalityIdFromStore(store)
		// If the setLevelMethod isn't set, we can't do anything, so bail
		if (modalityId && state.setLevelMethod) {
			const response = await api.user.getWindowLevelPresets(modalityId)
			const presets = response.data.data

			store.commit('bindWindowLevelPresets', { presets, setLevelMethod: store.state.setLevelMethod })
		}
	},
	setWindowLevelPresets({ rootGetters, dispatch }, setLevelMethod) {
		if (rootGetters.isAuthenticated) {
			dispatch('setWindowLevelPresetsForAuthenticatedUser', setLevelMethod)
		} else {
			dispatch('setWindowLevelPresetsForUnAuthenticatedUser', setLevelMethod)
		}
	},
	async setWindowLevelPresetsForAuthenticatedUser(store, setLevelMethod) {
		const modalityId = getModalityIdFromStore(store)
		if (!modalityId || modalityId === store.state.lastLoadedModalityId) return
		const response = await api.user.getWindowLevelPresets(modalityId)
		const presets = response.data.data

		store.commit('bindWindowLevelPresets', { modalityId, presets, setLevelMethod })
	},
	setWindowLevelPresetsForUnAuthenticatedUser({ commit }, setLevelMethod) {
		const presets = getDefaultPresets()
		commit('bindWindowLevelPresets', { presets, setLevelMethod })
	},
}

function getModalityIdFromStore({ rootGetters, rootState }) {
	const { activeSeries, activeStudy } = rootGetters
	const modalities = rootState.static.modalities
	try {
		return (
			(activeSeries && activeSeries.modalityId) ||
			(activeStudy && modalities.find(m => m.label === activeStudy.modality).value)
		)
	} catch {}
}

function getDefaultPresets() {
	let defaultPresets = [
		{
			key: '1',
			modality: 'CT',
			modalityId: 8,
			windowCenter: 50,
			windowWidth: 350,
		},
		{
			key: '2',
			modality: 'CT',
			modalityId: 8,
			windowCenter: 500,
			windowWidth: 2500,
		},
		{
			key: '3',
			modality: 'CT',
			modalityId: 8,
			windowCenter: 40,
			windowWidth: 400,
		},
		{
			key: '4',
			modality: 'CT',
			modalityId: 8,
			windowCenter: 50,
			windowWidth: 300,
		},
		{
			key: '5',
			modality: 'CT',
			modalityId: 8,
			windowCenter: 40,
			windowWidth: 120,
		},
		{
			key: '6',
			modality: 'CT',
			modalityId: 8,
			windowCenter: -500,
			windowWidth: 1500,
		},
		{
			key: '7',
			modality: 'CT',
			modalityId: 8,
			windowCenter: 80,
			windowWidth: 1550,
		},
	]

	return defaultPresets
}

export default {
	state,
	mutations,
	actions,
}
