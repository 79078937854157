import baseMixin from './baseMixin'

export default {
	mixins: [baseMixin],
	data() {
		return {
			points: {
				p1: null,
				p2: null,
				p3: null,
				p4: null,
			},
			worldCoords: {
				p1: null,
				p2: null,
				p3: null,
				p4: null,
			},
		}
	},
	computed: {
		p1: {
			get() {
				return this.points.p1
			},
			set(val) {
				this.points.p1 = val
			},
		},
		p2: {
			get() {
				return this.points.p2
			},
			set(val) {
				this.points.p2 = val
			},
		},
		p3: {
			get() {
				return this.points.p3
			},
			set(val) {
				this.points.p3 = val
			},
		},
		p4: {
			get() {
				return this.points.p4
			},
			set(val) {
				this.points.p4 = val
			},
		},
	},
	created() {
		this.$watch(
			function() {
				return `${this.worldCoords.p1},${this.worldCoords.p3}`
			},
			function() {
				const { p2, p4 } = this.getRotatedPoints(this.points)
				this.p2 = p2
				this.p4 = p4
			},
			{
				deep: true,
			}
		)
	},
	methods: {
		onInteractionBegin() {
			this.p1 = { ...this.pointerPosition }
			this.p2 = { ...this.pointerPosition }
			this.p3 = { ...this.pointerPosition }
			this.p4 = { ...this.pointerPosition }
		},
		getRotatedPoints(points) {
			const { p1, p3 } = points
			const effRot = this.effectiveRotationRads
			if (p1 && p3) {
				// Rotate points to offset effective rotation
				const p1Rotate = this.rotatePoint(p1, -effRot)
				const p3Rotate = this.rotatePoint(p3, -effRot)
				// Calculate the 2nd pair of opposite points
				const p2 = this.rotatePoint({ x: p3Rotate.x, y: p1Rotate.y, z: 0 }, effRot)
				const p4 = this.rotatePoint({ x: p1Rotate.x, y: p3Rotate.y, z: 0 }, effRot)
				return { p2, p4 }
			}
		},
		getDisplayBoundsVectors() {
			return {
				origin: {
					x: this.p1.x,
					y: this.p1.y,
				},
				horizontal: {
					x: this.p2.x,
					y: this.p2.y,
				},
				vertical: {
					x: this.p4.x,
					y: this.p4.y,
				},
			}
		},
		getTextAnchorData() {
			const midPoints = [
				this.pointBetween(this.p1, this.p2),
				this.pointBetween(this.p2, this.p3),
				this.pointBetween(this.p3, this.p4),
				this.pointBetween(this.p4, this.p1),
			]
			const points = [this.p1, this.p2, this.p3, this.p4, ...midPoints]
			const cornerPoints = this.getCornerPoints(midPoints)
			const anchor = cornerPoints.right
			return {
				points,
				anchor,
				alignVertical: 'middle',
			}
		},
	},
}
