interface IContextMeta {
	sets?: string[]
	templates?: string[]
	layouts?: string[]
	items?: { [key: string]: IContextMeta }
	sample?: any
}
export const ContextMeta: { [key: string]: IContextMeta } = {
	Addendum: {
		items: {
			AddedBy: { sample: 'Jane User' },
			Message: { sample: '<p>This is an addendum.</p>' },
			DateTime: { sample: '2020-03-02T19:33:50.358Z' },
		},
		layouts: ['Addendum'],
	},
	BillingCode: {
		items: {
			Name: { sample: 'CODE' },
			Value: { sample: 'Billing Code Value' },
		},
	},
	CompletionDate: { sample: '2020-03-02T19:33:50.358Z' },
	DraftedBy: {
		items: {
			Label: { sample: 'Intern:' },
			Name: { sample: 'Bob Drafter' },
		},
		sets: ['Teleconsultation'],
	},
	Consultant: {
		items: {
			Address: { sample: '123 Sesame St' },
			City: { sample: 'New York' },
			State: { sample: 'NY' },
			Email: { sample: 'example@domain.com' },
			Name: { sample: 'Dr. Getwell' },
			Organization: { sample: 'Sample Clinic' },
			Phone: { sample: '123-456-7890' },
			Website: { sample: 'https://www.google.com/' },
		},
		sets: ['Teleconsultation'],
	},
	FinalizedBy: {
		items: {
			FullName: { sample: 'Dr. Robert Staywell' },
			FirstName: { sample: 'Robert' },
			LastName: { sample: 'Staywell' },

			Address: { sample: '456 Fake St' },
			City: { sample: 'Beverly Hills' },
			State: { sample: 'CA' },
			Zip: { sample: '90210' },
			Country: { sample: 'United States' },

			Email: { sample: 'example@domain.com' },
			Organization: { sample: 'Sample Clinic' },
			Phone: { sample: '123-456-7890' },

			Website: { sample: 'domain.com' },
			Title: { sample: 'Dr.' },
			Degree: { sample: 'Degree' },
			Suffix: { sample: 'Jr.' },
		},
		sets: ['Report'],
	},
	ImageComment: {
		items: {
			AcquisitionDate: { sample: '2020-03-02T19:33:50.358Z' },
			Anatomy: { sample: 'Tarsus' },
			Comment: { sample: 'This is a comment.' },
			EditDateTime: { sample: '2020-03-02T19:33:50.358Z' },
			Limb: { sample: 'Left Leg' },
			View: { sample: 'LEG_VIEW' },
		},
		layouts: ['Image Comment'],
	},
	Patient: {
		items: {
			Age: { sample: '15 years' },
			Birthdate: { sample: '2020-03-02T00:00:00.000' },
			Breed: { sample: 'Lab' },
			Gender: { sample: 'Male' },
			Name: { sample: 'Spike' },
			Owner: { sample: 'Bob Smith' },
			PatientId: { sample: '123' },
			Species: { sample: 'Avian' },
			Weight: { sample: 13.3 },
			WeightUnit: { sample: 'LBS' },
		},
	},
	PDF: {
		items: {
			PageNumber: { sample: '1' },
			TotalPages: { sample: '1' },
		},
		templates: ['PDF Report', 'Standard PDF Report'],
	},
	ReferredBy: {
		items: {
			Address: { sample: '456 Fake St' },
			City: { sample: 'Beverly Hills' },
			State: { sample: 'CA' },
			PostalCode: { sample: '90210' },
			Email: { sample: 'example@domain.com' },
			Name: { sample: 'Dr. Staywell' },
			Organization: { sample: 'Sample Clinic' },
			Phone: { sample: '123-456-7890' },
		},
	},
	Study: {
		items: {
			StudyDate: { sample: '2020-03-02T19:33:50.358' },
			Modality: { sample: 'DX' },
		},
	},
}

export function getPreviewContext(exclude?: string[]) {
	const result = {}
	for (const key in ContextMeta) {
		if (exclude && exclude.includes(key)) continue
		result[key] = getMetaSample(ContextMeta[key])
	}
	return result
}

function getMetaSample(metaItem) {
	if (metaItem.sample) return metaItem.sample
	if (metaItem.items) {
		const items = {}
		for (const key in metaItem.items) {
			items[key] = getMetaSample(metaItem.items[key])
		}
		return items
	}
}
