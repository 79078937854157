import mapValues from 'lodash/mapValues'
import colors from './colors'
import asteris from './asteris'
import vsp from './vsp'

function fillColors(variables, accentColor) {
	variables = mapValues(variables, v => {
		v = v.toString()
		// Replace 'primary' with color name
		v = v.replace('accent', accentColor)
		// Replace color names with values
		colors.forEach(c => {
			v = v.replace(`{${c.name}}`, c.color)
		})
		return v
	})
	return variables
}

let themes = {
	asteris,
	vsp,
}

// Fill in css variables for light and dark modes
themes = mapValues(themes, v => {
	v.cssVariables.light = fillColors(v.cssVariables.light, v.accentColor)
	v.cssVariables.dark = fillColors(v.cssVariables.dark, v.accentColor)
	return v
})

export default themes
