import { API } from '@services/api'
import { eventBus } from '@services/eventBus'

class Folders {
	folders: IStudyFolder[] = []
	dragStudyId: string = null

	load() {
		return API.get(`/study/study-folders`).then(r => (this.folders = r && r.data))
	}
	clear() {
		this.folders = []
		this.dragStudyId = null
	}
	save(folders: IStudyFolder[]) {
		return API.put(`/study/study-folders`, folders).then(r => {
			this.load()
			return r.data
		})
	}
	delete(folderId: string) {
		return API.delete(`/study/study-folders/${folderId}`).then(() => {
			this.folders = this.folders.filter(f => {
				if (f.id === folderId) return false
				if (f.parentFolderId === folderId) return false
				return true
			})
		})
	}
	getFolderIdsForStudy(studyId: string) {
		return API.get(`/study/study-folders-for-study/${studyId}`).then(r => r.data)
	}
	addStudyToFolder(studyId: string, folderId: string) {
		return API.put(`/study/study-folders-for-study/${studyId}/${folderId}`).then(() => {
			eventBus.post('add-study-to-folder', studyId, folderId)
		})
	}
	updateFolderIdsForStudy(studyId: string, folderIds: string[]) {
		return API.post(`/study/study-folders-for-study/${studyId}`, folderIds)
	}
}

export const studyFolders = new Folders()
// @ts-ignore
window.studyFolders = studyFolders
