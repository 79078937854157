import getValue from './../internals/getValue.js'

// DBTODO: The Dicom `getImagePixelModule` does a lot to correct/validate
// the data for PalleteColorLut and Smallest/Largest Pixel Values
// We may need to add that correction here.
export default function(dicomTags) {
  return {
    samplesPerPixel: getValue(dicomTags, 'SAMPLES_PER_PIXEL'),
    photometricInterpretation: getValue(
      dicomTags,
      'PHOTOMETRIC_INTERPRETATION'
    ),
    rows: getValue(dicomTags, 'ROWS'),
    columns: getValue(dicomTags, 'COLUMNS'),
    bitsAllocated: getValue(dicomTags, 'BITS_ALLOCATED'),
    bitsStored: getValue(dicomTags, 'BITS_STORED'),
    highBit: getValue(dicomTags, 'HIGH_BIT'),
    pixelRepresentation: getValue(dicomTags, 'PIXEL_REPRESENTATION'), // 0 = unsigned,
    planarConfiguration: getValue(dicomTags, 'PLANAR_CONFIGURATION'),

    pixelAspectRatio: getValue(dicomTags, 'PIXEL_ASPECT_RATIO'),
    smallestPixelValue: getValue(dicomTags, 'SMALLEST_PIXEL_VALUE'),
    largestPixelValue: getValue(dicomTags, 'LARGEST_PIXEL_VALUE'),
    redPaletteColorLookupTableDescriptor: getValue(
      dicomTags,
      'RED_PALETTE_COLOR_LOOKUP_TABLE_DESCRIPTOR'
    ),
    greenPaletteColorLookupTableDescriptor: getValue(
      dicomTags,
      'GREEN_PALETTE_COLOR_LOOKUP_TABLE_DESCRIPTOR'
    ),
    bluePaletteColorLookupTableDescriptor: getValue(
      dicomTags,
      'BLUE_PALETTE_COLOR_LOOKUP_TABLE_DESCRIPTOR'
    ),
    redPaletteColorLookupTableData: getValue(
      dicomTags,
      'RED_PALETTE_COLOR_LOOKUP_TABLE_DATA'
    ),
    greenPaletteColorLookupTableData: getValue(
      dicomTags,
      'GREEN_PALETTE_COLOR_LOOKUP_TABLE_DATA'
    ),
    bluePaletteColorLookupTableData: getValue(
      dicomTags,
      'BLUE_PALETTE_COLOR_LOOKUP_TABLE_DATA'
    ),
  }
}
