<template>
	<span class="radio">
		<input :id="uid" v-model="intModel" type="radio" :value="value" :name="name" />
		<label :for="uid">
			<slot></slot>
		</label>
	</span>
</template>
<script>
export default {
	model: {
		prop: 'model',
		event: 'input',
	},
	props: {
		id: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		model: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: undefined,
		},
	},
	computed: {
		uid() {
			return `${this._uid}-${this.id}`
		},
		intModel: {
			get: function() {
				return this.model
			},
			set: function(newval) {
				this.$emit('input', newval)
			},
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
@import '@styles/components/_radio.scss';
</style>
