<template>
	<div class="search-and-filter">
		<!-- Search -->
		<div class="search-filters-tags">
			<slot name="left"></slot>
			<ast-input
				v-if="!keywordSearch"
				v-model="_term"
				:debounce-milliseconds="500"
				style="width: auto; margin-right: 4px; min-width: 210px"
				:placeholder="searchPlaceholder"
				icon="magnify"
			/>
			<ast-input
				v-if="keywordSearch"
				v-model="keywordString"
				:debounce-milliseconds="500"
				style="width: auto; margin-right: 4px; min-width: 210px"
				placeholder="Keywords"
				icon="magnify"
			/>

			<modality-select
				v-if="mq.small && showModalityNearSearch && !showConsignerSelect"
				v-model="filter.modalityIds"
				multiple
				placeholder="Modalities"
				@input="$emit('reset-current-page')"
			/>

			<slot name="middle"></slot>

			<button
				class="btn"
				:class="{ 'btn-success': isModalOpen, 'btn-default': !isModalOpen }"
				@click="isModalOpen = !isModalOpen"
			>
				<svg-icon icon="tune" />
				Filters
			</button>
			<!-- Tags -->
			<slot name="tags" :filter="filter"></slot>
		</div>

		<!-- Filter Drawer -->
		<modal-wrapper
			v-if="isModalOpen"
			class="modal-wrapper"
			@hook:mounted="isDrawerOpen = true"
			@close="isDrawerOpen = false"
		>
			<transition name="slide-in-left" @leave="isModalOpen = false">
				<div v-if="isDrawerOpen" class="modal-drawer">
					<ast-toolbar :is-primary="true" style="align-items: center; padding-left: 16px;">
						<h3 style="font-weight: normal;">Filters</h3>
						<toolbar-button icon="close" @click.native="isDrawerOpen = false" />
					</ast-toolbar>
					<div class="filters">
						<!-- Filters -->
						<!-- Top filter slot (so Status filters can be above modalities) -->
						<slot name="top" :filter="filter"></slot>
						<!-- Modalities -->
						<div>
							<label>{{ filterDropdownTitle }}</label>
							<modality-select
								v-if="!showConsignerSelect"
								v-model="filter.modalityIds"
								multiple
								@input="$emit('reset-current-page')"
							/>
							<consigner-select
								v-if="showConsignerSelect"
								v-model="filter.consignerIds"
								multiple
								@input="$emit('reset-current-page')"
							/>
						</div>
						<!-- Default filter slot -->
						<slot :filter="filter"></slot>
					</div>
					<div class="drawer-footer">
						<button v-if="showResetButton" class="btn btn-default" style="min-width: 64px;" @click="$emit('reset')">
							<svg-icon icon="close" />
							Reset Filters
						</button>
						<button class="btn btn-primary close" style="min-width: 64px;" @click="isDrawerOpen = false">
							Close
						</button>
					</div>
				</div>
			</transition>
		</modal-wrapper>
	</div>
</template>
<script>
import AstInput from '@components/Input'
import ModalWrapper from '@components/ModalWrapper'
import AstToolbar from '@components/Toolbar'
import ToolbarButton from '@components/ToolbarButton'
import ModalitySelect from '@components/ModalitySelect.vue'
import ConsignerSelect from '@components/ConsignerSelect.vue'

export default {
	name: 'SearchAndFilter',
	components: {
		AstInput,
		ModalWrapper,
		AstToolbar,
		ToolbarButton,
		ModalitySelect,
		ConsignerSelect,
	},
	model: {
		prop: 'filter',
		event: 'input',
	},
	props: {
		filter: {
			type: Object,
			required: true,
		},
		keywordSearch: {
			type: Boolean,
			default: false,
		},
		filterPresets: {
			type: Object,
			default: () => ({}),
		},
		isDialog: {
			type: Boolean,
			default: false,
		},
		showResetButton: {
			type: Boolean,
			default: false,
		},
		showModalityNearSearch: {
			type: Boolean,
			default: false,
		},
		showConsignerSelect: {
			type: Boolean,
			default: false,
		},
		filterDropdownTitle: {
			type: String,
			default: 'Modalities',
		},
		searchPlaceholder: {
			type: String,
			default: 'Patient Name, ID, Owner...',
		},
	},
	data() {
		return {
			isDrawerOpen: false, // both needed to have modal/drawer transitions work
			isModalOpen: false,
		}
	},
	computed: {
		_term: {
			get() {
				return this.filter.term
			},
			set(term) {
				this.$emit('reset-current-page')
				this.$emit('input', { ...this.filter, term })
			},
		},
		keywordString: {
			get() {
				return this.filter.keywordString
			},
			set(keywordString) {
				this.$emit('input', { ...this.filter, keywordString })
			},
		},
	},
	watch: {
		'$route.query'(value, oldValue) {
			// Update filter if filter-preset query param changes
			if (value['filter-preset'] !== oldValue['filter-preset']) {
				this.updateFilter()
			}
		},
		filterPresets(value, oldValue) {
			// Compare objects to prevent repetitive update loops when filterPresets is recalculated
			if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
				this.updateFilter()
			}
		},
		filter: {
			// update filter-preset query param if filter changes
			handler() {
				if (!this.$route || !this.$route.query || this.isDialog) return
				let matchingPreset
				for (const preset in this.filterPresets) {
					if (JSON.stringify(this.filter) === JSON.stringify(this.filterPresets[preset])) {
						matchingPreset = preset
					}
				}
				const query = { ...this.$route.query }
				if (!matchingPreset) {
					delete query['filter-preset']
				} else {
					query['filter-preset'] = matchingPreset
				}
				this.$router.replace({ query })
			},
			deep: true,
		},
	},
	mounted() {
		this.updateFilter()
	},
	methods: {
		updateFilter() {
			if (this.isDialog) return
			const query = this.$route.query
			if (!query) return
			const preset = query['filter-preset']
			const filter = this.filterPresets[preset]
			if (preset && filter) {
				this.$emit('input', filter)
			}
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
.search-and-filter {
	padding-bottom: 8px;
	.search-filters-tags {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: stretch;
		margin: -4px;
		& > * {
			margin: 4px;
		}
	}
	.filters {
		padding: 0 16px;
		overflow-y: auto;
		overflow-x: hidden;
		background: var(--primary-bg);
		border-bottom: 1px solid var(--primary-border);
		flex-grow: 1;
		& > * {
			padding: 12px 0;
		}
		& > * > label {
			display: block;
			user-select: none;
			font-size: 0.9em;
			font-weight: 400;
			padding-bottom: 4px;
		}
		.radio-group {
			display: flex;
			flex-wrap: wrap;
			@media (max-width: $mqSmall) {
				flex-direction: column;
				align-items: flex-start;
			}
			span {
				padding-bottom: 8px;
			}
		}
	}
	.drawer-footer {
		margin: -4px;
		padding: 16px;
		button {
			margin: 4px;
		}
		button.close {
			display: none;
		}
		@media (max-width: $mqSmall) {
			display: flex;
			flex-direction: column;
			button.close {
				display: inline-flex;
			}
		}
	}
	.modal-drawer {
		display: flex;
		flex-direction: column;
		position: fixed;
		min-width: 90vw;
		max-width: 520px;
		top: 0;
		right: 0;
		height: 100vh;
		height: -webkit-fill-available;
		background: var(--modal-drawer-bg);
		@media (min-width: $mqSmall) {
			min-width: 300px;
		}
	}
}
</style>
