<template>
	<drag-annotation
		:id="`${id}-ellipse`"
		v-model="position"
		:group-id="groupId"
		@drag-start="draggingAll = true"
		@drag-end="draggingAll = false"
	>
		<g v-if="p1 && p2 && p3 && p4" :style="groupStyle" data-position-ref>
			<!--Display ellipse-->
			<path :style="strokeStyle" :d="path" />
			<!--Transparent hit target ellipse-->
			<path :data-id="`${id}-ellipse`" :data-group-id="groupId" :style="strokeHitTargetStyle" :d="path" />
			<!--Center crosshairs-->
			<crosshairs-annotation :id="`${id}-ellipse`" :group-id="groupId" :origin="center" />
			<!--Start handle-->
			<drag-annotation
				v-model="p1"
				:group-id="groupId"
				:color="activeColor"
				@drag-start="drag.p1 = true"
				@drag-end="drag.p1 = false"
			/>
			<!--End handle-->
			<drag-annotation
				v-model="p3"
				:group-id="groupId"
				:color="activeColor"
				drag-on-load
				@drag-start="drag.p3 = true"
				@drag-end="drag.p3 = false"
			/>
			<!--Data label-->
			<text-annotation
				v-if="statsVisible"
				:value="statText"
				:group-id="groupId"
				:event-source="textSource"
				:color="activeColor"
			/>
		</g>
	</drag-annotation>
</template>

<script>
import DragAnnotation from './DragAnnotation'
import TextAnnotation from './TextAnnotation'
import CrosshairsAnnotation from './CrosshairsAnnotation'
import { quadMixin } from './mixins'

export default {
	name: 'EllipseAnnotation',
	components: {
		DragAnnotation,
		TextAnnotation,
		CrosshairsAnnotation,
	},
	mixins: [quadMixin],
	data() {
		return {
			statTemplate: `Area: {area}\nDx: {diameterX}, Dy: {diameterY}`,
		}
	},
	computed: {
		path() {
			const start = this.pointBetween(this.p1, this.p4)
			const end = this.pointBetween(this.p2, this.p3)
			const radiusX = this.displayDistanceBetween(this.p1, this.p2) / 2
			const radiusY = this.displayDistanceBetween(this.p2, this.p3) / 2
			return `
				M ${start.x} ${start.y}
				A ${radiusX} ${radiusY} ${this.effectiveRotation} 0 0 ${end.x} ${end.y}
				A ${radiusX} ${radiusY} ${this.effectiveRotation} 0 0 ${start.x} ${start.y}
				`
		},
		crosshairStyle() {
			return {
				cursor: 'pointer',
				stroke: this.activeColor,
				strokeWidth: '1.5px',
			}
		},
		area() {
			return Math.PI * this.worldRadiusX * this.worldRadiusY
		},
	},
	methods: {
		// https://math.stackexchange.com/questions/76457/check-if-a-point-is-within-an-ellipse
		pointInsideBounds(width, height, x, y) {
			const rx = width / 2
			const ry = height / 2
			if (!rx || !ry) return false
			return Math.pow(x - rx, 2) / Math.pow(rx, 2) + Math.pow(y - ry, 2) / Math.pow(ry, 2) <= 1
		},
	},
}
</script>
