import insertSlice from './data/insertSlice.js'
import { loadAndCacheImage } from 'cornerstone-core/dist/cornerstone'
import { getCornerstoneImageId } from '@/imageLoader'

// TODO: If we attempt to load multiple imageDataObjects at once this will break.
export default function loadImageDataProgressively(imageDataObject, validImages, series, renderFrequencyPct = 5) {
	if (imageDataObject.loaded || imageDataObject.isLoading) {
		// Returning instantly resolved promise as good to go.
		// Returning promise to be resolved by other process as loading.
		return
	}

	const { imageIds, vtkImageData, metaDataMap, sortedDatasets } = imageDataObject
	const loadImagePromises = validImages.map(image => {
		return loadAndCacheImage(getCornerstoneImageId(series, image))
	})

	imageDataObject.isLoading = true

	// This is straight up a hack: vtkjs cries when you feed it data with a range of zero.
	// So lets set the first voxel to 1, which will be replaced when the first image comes in.
	const scalars = vtkImageData.getPointData().getScalars()
	const scalarData = scalars.getData()

	scalarData[0] = 1

	const range = {
		max: Number.NEGATIVE_INFINITY,
		min: Number.POSITIVE_INFINITY,
	}

	const numberOfFrames = imageIds.length
	let numberProcessed = 0

	const reRenderFraction = numberOfFrames * (renderFrequencyPct / 100)
	let reRenderTarget = reRenderFraction

	const insertPixelData = image => {
		return new Promise(resolve => {
			const { imagePositionPatient } = metaDataMap.get(image.asterisImageId)

			const sliceIndex = sortedDatasets.findIndex(
				dataset => dataset !== null && dataset.imagePositionPatient === imagePositionPatient
			)

			const { max, min } = insertSlice(vtkImageData, sliceIndex, image)

			if (max > range.max) {
				range.max = max
			}

			if (min < range.min) {
				range.min = min
			}

			const dataArray = vtkImageData.getPointData().getScalars()

			dataArray.setRange(range, 1)
			numberProcessed++

			if (numberProcessed > reRenderTarget) {
				reRenderTarget += reRenderFraction
				vtkImageData.modified()
			}
			resolve(numberProcessed)
		})
	}

	const insertPixelDataPromises = []

	// get VOI from first image
	Promise.any(loadImagePromises).then(async image => {
		if (!image.isDicom) {
			// jpeg loader copies original dicom voi to custom objects
			const { dicomWindowWidth: windowWidth, dicomWindowCenter: windowCenter } = image
			imageDataObject.voi = { windowWidth, windowCenter }
		} else {
			const { windowWidth, windowCenter } = image
			imageDataObject.voi = { windowWidth, windowCenter }
		}
		return image
	})

	loadImagePromises.forEach(promise => insertPixelDataPromises.push(promise.then(insertPixelData)))

	Promise.all(insertPixelDataPromises).then(() => {
		imageDataObject.isLoading = false
		imageDataObject.loaded = true

		vtkImageData.modified()
	})

	imageDataObject.insertPixelDataPromises = insertPixelDataPromises
}
