import Vue from 'vue'

const maxDelayForDoubleClick = 350
const events = ['mousedown', 'touchstart']

// This v-dblclick directive works the same as @dblclick but works on mobile.
// It also does not hijack dragstart events, unlike hammer.js.
Vue.directive('dblclick', {
	bind(el, binding) {
		el.onClick = e => {
			if (el.isDoubleClicking) {
				binding.value(e)
			} else {
				el.isDoubleClicking = true
				setTimeout(() => (el.isDoubleClicking = false), maxDelayForDoubleClick)
			}
		}
		events.forEach(event => {
			if (`on${event}` in el) {
				el.addEventListener(event, el.onClick)
			}
		})
	},
	unbind(el) {
		events.forEach(event => {
			if (`on${event}` in el) {
				el.removeEventListener(event, el.onClick)
			}
		})
		delete el.onClick
	},
})
