import axios from 'axios'
import api from '@services/api'
import { eventBus } from '@services/eventBus'
import { findImageUrlFromImage } from '@utils/urlUtility'
import shouldLoadDicom from '@/utils/shouldLoadDicom'
import { imageLoader } from '@/imageLoader'
import imageCache from '@/imageLoader/imageCache'
import omniDesktop from '@/electron/omniDesktop'

const STUDY_QUERY_INTERVAL = 1 * 60 * 1000
const MAX_FETCHES = 3
const FetchAxios = axios.create({
	responseType: 'arraybuffer',
	transformRequest: [
		(data, headers) => {
			delete headers.common['Authorization']
			return data
		},
	],
})
let studyQueryInterval

class ImagePrecacheService {
	activeFetches = 0
	queue = []
	registeredImages = {}

	constructor() {
		this.stopImagePrecache = this.stopImagePrecache.bind(this)
		eventBus.on('login', async () => {
			// Only enable for desktop and main window
			if (!omniDesktop.isConnected) return
			if (!(await omniDesktop.request('isWorklistWindow'))) return
			this.startImagePrecache()
		})
		eventBus.on('logout', this.stopImagePrecache)
	}

	async startImagePrecache() {
		clearInterval(studyQueryInterval)
		// Perform fetch at configured interval
		studyQueryInterval = setInterval(() => this.fetchStudies(), STUDY_QUERY_INTERVAL)
		// Perform initial fetch
		setTimeout(() => this.fetchStudies(), 500)
	}

	stopImagePrecache() {
		clearInterval(studyQueryInterval)
		this.queue = []
		this.registeredImages = {}
	}

	// Setting that could change during the current session
	get enabled() {
		return imageCache.isActive && imageCache.isPrecacheActive && !imageLoader.activeFetches
	}

	async fetchStudies() {
		if (!this.enabled) return
		const studyIds = await this.fetchStudyIds()
		if (!studyIds.length) return
		const BATCH_SIZE = 20
		while (studyIds.length) {
			// Fetch image data in batches to avoid url limits
			let batchStudyIds = studyIds.splice(0, BATCH_SIZE)
			// Get image data for the fetched studies
			const studyData = await api.viewer.getStudy({ ids: batchStudyIds })
			studyData?.studies?.forEach(study => {
				study.imageData?.series?.forEach(series => {
					series.images.forEach(image => {
						const isDicom = shouldLoadDicom(series.modality)
						const scheme = isDicom ? 'dicom' : 'jpeg'
						// Patch imageType if needed
						image.imageType = image.imageType || isDicom ? 'DicomImage' : 'PreviewImage'
						const imageUrl = findImageUrlFromImage(image, series)
						// Don't need to prefetch registered images
						if (imageUrl in this.registeredImages) return
						// Register the imageUrl
						this.registeredImages[imageUrl] = true
						// Queue the image up for prefetch (if needed)
						this.queue.push({
							clinicCode: study.clinicCode,
							studyId: study.studyId,
							studyUid: study.studyUid,
							seriesId: series.seriesId,
							seriesUid: series.uid,
							scheme,
							imageId: image.imageId,
							instanceUid: image.sopInstanceUid,
							frameIndex: image.frameIndex,
							imageUrl,
						})
					})
				})
			})
		}
		this.startFetching()
	}

	async fetchStudyIds() {
		const studyIds = []
		let page = 0
		while (true) {
			// Get studies that have a StudyDateTime from start of today
			const startOfToday = new Date()
			startOfToday.setHours(0, 0, 0, 0)
			const data = await api.study.getList(
				{
					'order[by]': 'StudyDateTime',
					'order[isAscending]': false,
					startDate: startOfToday,
					page: page++,
					results: 25,
				},
				false // Don't cancel pending requests
			)
			// This request can get cancelled by other study requests
			if (!data || !data.results) break
			studyIds.push(...data.results.map(study => study.studyId))
			// No more studies to fetch
			if (!data.hasMoreResults) break
		}
		return studyIds
	}

	async fetchImage(imageData) {
		return FetchAxios.get(imageData.imageUrl).then(r => r.data)
	}

	async startFetching() {
		while (this.enabled && this.queue.length && this.activeFetches < MAX_FETCHES) {
			this.activeFetches++
			const imageData = this.queue.shift()
			try {
				// Asking cache to loadImage will only fetch the image if it
				// isn't already cached. This will only have to hit the disk
				// once per session since we store the imageUrl in memory
				await imageCache.loadImage(imageData, this.fetchImage)
			} catch {
			} finally {
				this.activeFetches--
			}
			this.startFetching()
		}
	}
}

export const imagePrecacheService = new ImagePrecacheService()
window.imagePrecache = imagePrecacheService
