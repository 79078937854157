import omniDesktop from '@/electron/omniDesktop'
import { ImageLoader, ImageData } from './index'
import { validateImageArrayBuffer } from '../index'

interface DesktopImageCacheMeta {
	isActive: boolean
	isPrecacheActive: boolean
	directoryPath: string
	size: number
	allocatedSize: number
	availableSize: number
}

class DesktopImageCache {
	meta: DesktopImageCacheMeta = {
		isActive: false,
		isPrecacheActive: false,
		directoryPath: '',
		size: 0,
		allocatedSize: 0,
		availableSize: 0,
	}
	loadingCount: number = 0

	constructor() {
		this.refreshMeta()
		this.clear = this.clear.bind(this)
		this.trim = this.trim.bind(this)
		this.browseDirectory = this.browseDirectory.bind(this)
	}

	get isActive() {
		return this.meta.isActive
	}

	set isActive(active: boolean) {
		this.meta.isActive = active
		omniDesktop.request('setViewerImageCacheActive', active)
	}

	get isPrecacheActive() {
		return this.meta.isPrecacheActive
	}

	set isPrecacheActive(active: boolean) {
		this.meta.isPrecacheActive = active
		omniDesktop.request('setViewerImageCachePrecacheActive', active)
	}

	get isLoading() {
		return this.loadingCount > 0
	}

	get directoryPath() {
		return this.meta.directoryPath
	}

	get size() {
		return this.meta.size
	}

	get allocatedSize() {
		return this.meta.allocatedSize
	}

	set allocatedSize(bytes) {
		this.meta.allocatedSize = bytes
		omniDesktop.request('setViewerImageCacheAllocatedSize', bytes)
	}

	get availableSize() {
		return this.meta.availableSize
	}

	async refreshMeta() {
		this.meta = (await omniDesktop.request('getViewerImageCacheMeta')) as DesktopImageCacheMeta
	}

	loadImage(imageData: ImageData, imageLoader: ImageLoader): Promise<ArrayBuffer> {
		if (!this.isActive) return imageLoader(imageData)
		return new Promise((resolve, reject) => {
			this.loadingCount++
			this.getImage(imageData)
				.then(image => resolve(image))
				.catch(() => {
					imageLoader(imageData)
						.then((image: ArrayBuffer) => {
							resolve(image)
							this.setImage(imageData, image)
						})
						.catch(reject)
				})
		}).finally(() => {
			this.loadingCount--
		}) as Promise<ArrayBuffer>
	}

	getImage(imageData: ImageData): Promise<ArrayBuffer> {
		return new Promise((resolve, reject) => {
			omniDesktop
				.request('getViewerImageInCache', imageData)
				.then((data: ArrayBuffer | null) => {
					if (data && validateImageArrayBuffer(data)) {
						resolve(data.slice(0))
					} else {
						reject(new Error('Bad image returned from cache'))
					}
				})
				.catch(reject)
		})
	}

	setImage(imageData: ImageData, image: ArrayBuffer): Promise<boolean> {
		return new Promise((resolve, reject) => {
			omniDesktop.request('setViewerImageInCache', { imageData, image }).then((result: boolean) => {
				resolve(result)
			})
		})
	}

	clear() {
		return new Promise((resolve, reject) => {
			omniDesktop.request('clearViewerImageCache').then((result: boolean) => {
				resolve(result)
				this.refreshMeta()
			})
		})
	}

	trim() {
		return new Promise((resolve, reject) => {
			omniDesktop.request('trimViewerImageCache').then(() => {
				resolve(true)
				this.refreshMeta()
			})
		})
	}

	browseDirectory() {
		return new Promise((resolve, reject) => {
			omniDesktop.request('browseViewerImageCacheDirectory').then((result: string) => {
				resolve(result)
				this.refreshMeta()
			})
		})
	}
}

export default DesktopImageCache
