/**
 * Based on the vtk.js's MPR Slice interactor Style, but with improvements.
 */
import macro from 'vtk.js/Sources/macro'
import vtkMath from 'vtk.js/Sources/Common/Core/Math'
import vtkMatrixBuilder from 'vtk.js/Sources/Common/Core/MatrixBuilder'
import vtkInteractorStyleManipulator from 'vtk.js/Sources/Interaction/Style/InteractorStyleManipulator'
import vtkMouseRangeManipulator from 'vtk.js/Sources/Interaction/Manipulators/MouseRangeManipulator'

import { toWindowLevel, toLowHighRange } from '../lib/windowLevelRangeConverter'
import clamp from '../lib/math/clamp'

// ----------------------------------------------------------------------------
// vtkInteractorStyleMPRSlice methods
// ----------------------------------------------------------------------------

function vtkInteractorStyleMPRSlice(publicAPI, model) {
	// Set our className
	model.classHierarchy.push('vtkInteractorStyleMPRSlice')

	model.scrollManipulator = vtkMouseRangeManipulator.newInstance({
		scrollEnabled: true,
		dragEnabled: false,
	})

	// cache for sliceRange
	const cache = {
		sliceNormal: [0, 0, 0],
		sliceRange: [0, 0],
		sliceCenter: [], // intentionally left empty for first-run check
	}

	publicAPI.updateScrollManipulator = () => {
		const range = publicAPI.getSliceRange()
		model.scrollManipulator.removeScrollListener()
		// The Scroll listener has min, max, step, and getValue setValue as params.
		// Internally, it checks that the result of the GET has changed, and only calls SET if it is new.
		model.scrollManipulator.setScrollListener(
			range[0],
			range[1],
			1,
			publicAPI.getSlice,
			publicAPI.scrollToSlice
		)
	}

	function setManipulators() {
		publicAPI.removeAllMouseManipulators()
		publicAPI.addMouseManipulator(model.scrollManipulator)
		publicAPI.updateScrollManipulator()
	}

	publicAPI.init = () => {
		setManipulators()
	}

	let cameraSub = null
	let interactorSub = null
	const superSetInteractor = publicAPI.setInteractor
	publicAPI.setInteractor = interactor => {
		superSetInteractor(interactor)
		if (cameraSub) {
			cameraSub.unsubscribe()
			cameraSub = null
		}

		if (interactorSub) {
			interactorSub.unsubscribe()
			interactorSub = null
		}

		if (interactor) {
			const renderer = interactor.getCurrentRenderer()
			const camera = renderer.getActiveCamera()

			cameraSub = camera.onModified(() => {
				publicAPI.updateScrollManipulator()
				publicAPI.modified()
			})

			// Always reset the camera clipping to ensure it doesn't get moved
			interactorSub = interactor.onAnimation(() => {
				camera.setThicknessFromFocalPoint(model.slabThickness)
			})
		}
	}

	publicAPI.setVolumeActor = volume => {
		model.volumeActor = volume
		if (model.interactor) {
			const renderer = model.interactor.getCurrentRenderer()
			const camera = renderer.getActiveCamera()
			if (volume) {
				publicAPI.updateScrollManipulator()
				// prevent zoom manipulator from messing with our focal point
				camera.setFreezeFocalPoint(true)

				// NOTE: Disabling this because it makes it more difficult to switch
				// interactor styles. Need to find a better way to do this!
				// publicAPI.setSliceNormal(...publicAPI.getSliceNormal());
			} else {
				camera.setFreezeFocalPoint(false)
			}
		}
	}

	publicAPI.getSlice = () => {
		if (model.interactor) {
			const renderer = model.interactor.getCurrentRenderer()
			const camera = renderer.getActiveCamera()
			const sliceNormal = publicAPI.getSliceNormal()
			const fp = camera.getFocalPoint()

			// Get rotation matrix from normal to +X (since bounds is aligned to XYZ)
			const transform = vtkMatrixBuilder
				.buildFromDegree()
				.rotateFromDirections(sliceNormal, [1, 0, 0])
			transform.apply(fp)

			return fp[0]
		}
		if (model.debug) console.log('getslice called without interactor set')
		return 0
	}
	// Only run the onScroll callback if called from scrolling,
	// preventing manual setSlice calls from triggering the CB.
	publicAPI.scrollToSlice = slice => {
		// FIXME: the offset here is used only when scrollToSlice is called, which hack-fixes an issue with this offset logic
		// Which is that if the camera position is changed outside of the Interactor,
		// and is no longer coplaner with the sliceNormal and cached sliceCenter, the offset is wildly incorrect.
		// Calculating in scrollToSlice only reduces the liklihood of using a bad offset.
		// Should ideally check for coplaner / convert to coplaner point before using.
		let cameraOffset = [0, 0, 0]
		if (cache.sliceCenter.length) {
			const renderer = model.interactor.getCurrentRenderer()
			const oldPos = renderer.getActiveCamera().getFocalPoint()
			vtkMath.subtract(oldPos, cache.sliceCenter, cameraOffset)
		}
		const slicePoint = publicAPI.setSlice(slice, cameraOffset)
		// run Callback
		const onScroll = publicAPI.getOnScroll()
		if (onScroll) onScroll(slicePoint)
	}

	publicAPI.setSlice = (slice, cameraOffset = [0, 0, 0]) => {
		const renderer = model.interactor.getCurrentRenderer()
		const camera = renderer.getActiveCamera()

		if (model.volumeActor) {
			const range = publicAPI.getSliceRange()
			const bounds = model.volumeActor.getMapper().getBounds()

			const clampedSlice = clamp(slice, ...range)

			const center = [
				(bounds[0] + bounds[1]) / 2.0,
				(bounds[2] + bounds[3]) / 2.0,
				(bounds[4] + bounds[5]) / 2.0,
			]

			const distance = camera.getDistance()
			const dop = camera.getDirectionOfProjection()
			vtkMath.normalize(dop)

			const midPoint = (range[1] + range[0]) / 2.0
			const zeroPoint = [
				center[0] - dop[0] * midPoint,
				center[1] - dop[1] * midPoint,
				center[2] - dop[2] * midPoint,
			]
			const slicePoint = [
				zeroPoint[0] + dop[0] * clampedSlice,
				zeroPoint[1] + dop[1] * clampedSlice,
				zeroPoint[2] + dop[2] * clampedSlice,
			]
			const cameraPos = [
				slicePoint[0] - dop[0] * distance,
				slicePoint[1] - dop[1] * distance,
				slicePoint[2] - dop[2] * distance,
			]

			cache.sliceCenter = [...slicePoint]

			vtkMath.add(slicePoint, cameraOffset, slicePoint)
			vtkMath.add(cameraPos, cameraOffset, cameraPos)

			camera.setPosition(...cameraPos)
			camera.setFocalPoint(...slicePoint)
			return cache.sliceCenter
		}
	}

	publicAPI.getSliceRange = () => {
		if (model.volumeActor) {
			const sliceNormal = publicAPI.getSliceNormal()

			if (
				sliceNormal[0] === cache.sliceNormal[0] &&
				sliceNormal[1] === cache.sliceNormal[1] &&
				sliceNormal[2] === cache.sliceNormal[2]
			) {
				if (model.debug) console.log('returned cached range', cache.sliceRange)
				return cache.sliceRange
			}

			const points = boundsToCorners(model.volumeActor.getMapper().getBounds())
			// Get rotation matrix from normal to +X (since bounds is aligned to XYZ)
			const transform = vtkMatrixBuilder
				.buildFromDegree()
				.rotateFromDirections(sliceNormal, [1, 0, 0])
			points.forEach(pt => transform.apply(pt))

			// range is now maximum X distance
			let minX = Infinity
			let maxX = -Infinity
			for (let i = 0; i < 8; i++) {
				const x = points[i][0]
				if (x > maxX) {
					maxX = x
				}
				if (x < minX) {
					minX = x
				}
			}

			cache.sliceNormal = sliceNormal
			cache.sliceRange = [minX, maxX]
			if (model.debug) console.log('returned new range', cache.sliceRange)
			return cache.sliceRange
		}
		if (model.debug) console.log('called getSliceRange, no volumeActor...')
		return [0, 0]
	}

	// Slice normal is just camera DOP, accounting for the volume's coordinate space
	publicAPI.getSliceNormal = () => {
		if (model.interactor) {
			const renderer = model.interactor.getCurrentRenderer()
			const camera = renderer.getActiveCamera()
			return camera.getDirectionOfProjection()
		}
		if (model.debug) console.log('called getSliceNormal, but no interactor set')
		return [0, 0, 0]
	}

	/**
	 * Move the camera to the given slice normal and viewup direction. Viewup can be used to rotate the display of the image around the direction of view.
	 * Vectors are provided in world space, but are converted into the volume's coordinate space
	 */
	publicAPI.setSliceNormal = (normal, viewUp = [0, 1, 0]) => {
		if (model.debug) console.log('setSliceNormal', normal, viewUp)
		const renderer = model.interactor.getCurrentRenderer()
		const camera = renderer.getActiveCamera()

		// Copy original arguments to the model, so they can be GET-ed later, independent of being changed outside of the interactor
		model.sliceNormal = [...normal]
		model.viewUp = [...viewUp]

		// Modify vectors to the volume's coords, using copied arrays so we don't cause sideeffects
		const _normal = [...normal]
		const _viewUp = [...viewUp]

		if (model.volumeActor) {
			vtkMath.normalize(_normal)
			vtkMath.normalize(_viewUp)

			let center = camera.getFocalPoint()
			let dist = camera.getDistance()
			let angle = camera.getViewAngle()

			// Initial camera positioning, defaults to the volume center
			if (Number.isNaN(dist) || dist === undefined) {
				const bounds = model.volumeActor.getMapper().getBounds()
				// diagonal will be used as "width" of camera scene
				const diagonal = Math.sqrt(
					vtkMath.distance2BetweenPoints(
						[bounds[0], bounds[2], bounds[4]],
						[bounds[1], bounds[3], bounds[5]]
					)
				)

				// center will be used as initial focal point
				center = [
					(bounds[0] + bounds[1]) / 2.0,
					(bounds[2] + bounds[3]) / 2.0,
					(bounds[4] + bounds[5]) / 2.0,
				]

				// use a VERY tiny view angle to approximate parallel projection
				angle = 1

				// distance from camera to focal point
				dist = diagonal / (2 * Math.tan((angle / 360) * Math.PI))
			}

			const cameraPos = [
				center[0] - _normal[0] * dist,
				center[1] - _normal[1] * dist,
				center[2] - _normal[2] * dist,
			]

			camera.setPosition(...cameraPos)
			camera.setDistance(dist)
			// should be set after pos and distance
			camera.setDirectionOfProjection(..._normal)
			camera.setViewUp(..._viewUp)
			camera.setViewAngle(angle)
			camera.setThicknessFromFocalPoint(model.slabThickness)

			publicAPI.setCenterOfRotation(center)
		} else {
			if (model.debug) console.log('attempted to set slice normal while volumeActor was missing')
		}
	}

	publicAPI.resetCameraPosition = () => {
		const renderer = model.interactor.getCurrentRenderer()
		renderer.resetCamera()
	}

	publicAPI.setSlabThickness = slabThickness => {
		model.slabThickness = slabThickness

		// Update the camera clipping range if the slab
		// thickness property is changed
		const renderer = model.interactor.getCurrentRenderer()
		const camera = renderer.getActiveCamera()
		camera.setThicknessFromFocalPoint(slabThickness)
	}

	/**
	 * Set the window level from any inherited interactor, so you don't have to go manually edit the volumeActor elsewhere
	 */
	publicAPI.getWindowLevel = () => {
		const range = model.volumeActor
			.getProperty()
			.getRGBTransferFunction(0)
			.getMappingRange()
			.slice()
		return toWindowLevel(...range)
	}
	publicAPI.setWindowLevel = (windowWidth, windowCenter) => {
		const lowHigh = toLowHighRange(windowWidth, windowCenter)

		model.volumeActor
			.getProperty()
			.getRGBTransferFunction(0)
			.setMappingRange(lowHigh.lower, lowHigh.upper)
	}

	publicAPI.init()
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
	slabThickness: 0.1,
	debug: false,
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
	Object.assign(model, DEFAULT_VALUES, initialValues)

	// Inheritance
	vtkInteractorStyleManipulator.extend(publicAPI, model, initialValues)

	macro.setGet(publicAPI, model, ['onScroll', 'debug'])
	macro.get(publicAPI, model, ['volumeActor', 'slabThickness', 'viewUp', 'classHierarchy'])

	// Object specific methods
	vtkInteractorStyleMPRSlice(publicAPI, model)
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkInteractorStyleMPRSlice')

// ----------------------------------------------------------------------------

export default Object.assign({ newInstance, extend })

// ----------------------------------------------------------------------------
// Global methods
// ----------------------------------------------------------------------------

/**
 * @param {Number[]} bounds formatted as `[xmin,xmax,ymin,ymax,zmin,zmax]`
 * @returns {Array[]} xyz positions for all 8 corners
 */
function boundsToCorners(bounds) {
	return [
		[bounds[0], bounds[2], bounds[4]],
		[bounds[0], bounds[2], bounds[5]],
		[bounds[0], bounds[3], bounds[4]],
		[bounds[0], bounds[3], bounds[5]],
		[bounds[1], bounds[2], bounds[4]],
		[bounds[1], bounds[2], bounds[5]],
		[bounds[1], bounds[3], bounds[4]],
		[bounds[1], bounds[3], bounds[5]],
	]
}
