






































import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { reportService } from '@services/reportService'
import { workflow } from '@services/workflow'
import { Cell, TemplateSet } from '../classes'
import router from '@router' // for dialogs
import { eventBus } from '@services/eventBus'
import { userData } from '@services/userData'

const ImageCommentLayoutDlg = {
	name: 'ImageCommentLayoutDlg',
	props: ['cell'],
	data() {
		return {
			columns: 1,
			items: [],
			draggingIndex: null,
		}
	},
	components: {
		DlgHeader,
		ModalWrapper,
	},
	computed: {
		gridStyle() {
			let gridTemplateColumns = ''
			for (let i = 0; i < this.columns; i++) {
				gridTemplateColumns += 'auto '
			}

			return {
				display: 'grid',
				gridTemplateColumns,
			}
		},
	},
	async created() {
		let images: IImageComment[] = this.cell.report.imageComments.slice(0)

		let cell: Cell = this.cell
		this.columns = cell.template.imageCommentColumns || 1
		if (cell.value) {
			let layout: IImageCommentsLayout = JSON.parse(cell.value)
			this.columns = layout.columns || 1

			images.sort((a, b) => {
				let aIndex = layout.imageReportIds.indexOf(a.reportImageId)
				let bIndex = layout.imageReportIds.indexOf(b.reportImageId)
				return aIndex - bIndex
			})
		}

		this.items = images
	},
	methods: {
		moveItem({ from, to }) {
			this.items.splice(to, 0, this.items.splice(from, 1)[0])
		},
		onDragOver(e, i) {
			e.dataTransfer.dropEffect = 'move'
		},
		onDrop(e, i) {
			this.moveItem({ from: this.draggingIndex, to: i })
		},
		onDragStart(e, i) {
			this.draggingIndex = i
		},
		save() {
			let images: IImageComment[] = this.items
			let layout: IImageCommentsLayout = {
				imageReportIds: images.map(i => i.reportImageId),
				columns: this.columns,
			}

			dlg.close(this, true, layout)
		},
		close() {
			dlg.close(this)
		},
	},
}

export default ImageCommentLayoutDlg

export function openImageCommentLayoutDlg(cell: Cell): Promise<IImageCommentsLayout> {
	return dlg.open(ImageCommentLayoutDlg, { cell }, false)
}
