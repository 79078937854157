<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="annotation-container"
		style="cursor:pointer"
		:width="width"
		:height="height"
		:viewBox="viewBox"
	>
		<!--Filter referenced by svg elements with stroke (lines, text)-->
		<filter id="shadow" filterUnits="userSpaceOnUse" height="130%" width="130%">
			<feDropShadow dx="1" dy="1" stdDeviation="0.3" flood-color="#000" flood-opacity="0.7" />
		</filter>
		<component
			:is="annotation.component"
			v-for="annotation in annotations"
			v-show="annotation.slice === slice"
			:key="annotation.groupId"
			ref="annotations"
			:rotation="rotation"
			v-bind="annotation.props"
		/>
	</svg>
</template>

<script>
import { distance2BetweenPoints } from 'vtk.js/Sources/Common/Core/Math'
import LengthAnnotation from './LengthAnnotation'
import RectAnnotation from './RectAnnotation'
import CircleAnnotation from './CircleAnnotation'
import EllipseAnnotation from './EllipseAnnotation'
import AngleAnnotation from './AngleAnnotation'
import TextAnnotation from './TextAnnotation'
import ArrowAnnotation from './ArrowAnnotation'
import ProbeAnnotation from './ProbeAnnotation'
import { eventBus } from '@services/eventBus'
import { consts } from '@vtk'
import uuid from 'uuid/v4'
import round from 'lodash/round'
import first from 'lodash/first'
import uniq from 'lodash/uniq'

export default {
	name: 'AnnotationManager',
	inject: ['mprManager'],
	provide() {
		return {
			manager: this,
		}
	},
	props: {
		width: {
			type: Number,
			default: 0,
		},
		height: {
			type: Number,
			default: 0,
		},
		active: Boolean,
		renderer: {
			type: Object,
			required: true,
		},
		volumeData: {
			type: Object,
			required: true,
		},
		slice: {
			type: Number,
			required: true,
		},
		sliceThickness: {
			type: Number,
			default: 0,
		},
		rotation: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			renderWindow: null,
			interactor: null,
			subscribedEvents: [],
			pointerPosition: null,
			activeHandleId: null,
			activeAnnotation: null,
			hoveredAnnotation: null,
			annotations: [],
			tools: [
				{
					type: consts.LENGTH_TOOL,
					component: LengthAnnotation,
				},
				{
					type: consts.RECT_TOOL,
					component: RectAnnotation,
				},
				{
					type: consts.CIRCLE_TOOL,
					component: CircleAnnotation,
				},
				{
					type: consts.ELLIPSE_TOOL,
					component: EllipseAnnotation,
				},
				{
					type: consts.ANGLE_TOOL,
					component: AngleAnnotation,
				},
				{
					type: consts.TEXT_TOOL,
					component: TextAnnotation,
				},
				{
					type: consts.ARROW_TOOL,
					component: ArrowAnnotation,
				},
				{
					type: consts.PROBE_TOOL,
					component: ProbeAnnotation,
				},
			],
		}
	},
	computed: {
		viewBox() {
			return `0 0 ${this.width} ${this.height}`
		},
		// Return true to disable stats without reason
		// or string to disable and display reason
		statsDisabled() {
			if (this.sliceThickness >= 1) {
				return 'Stats Disabled\nw/ Slice Thickness'
			}
			return this.mprManager.isJpeg
		},
		modalityUnit() {
			return !this.isMobileOS && !this.statsDisabled && this.$store.state.mpr.activeSeries.modality === 'CT'
				? ' HU'
				: ''
		},
	},
	watch: {
		width() {
			this.onViewportModified()
		},
		height() {
			this.onViewportModified()
		},
		pointerPosition(val) {
			const point = val && { ...val }
			this.$emit('pointerMove', point)
			this.refreshHoveredAnnotation()
		},
		hoveredAnnotation(val, oldVal) {
			if (oldVal && oldVal.children) {
				oldVal.children.forEach(ca => (ca.hovered = false))
			}
			if (val && val.children) {
				val.children.forEach(ca => (ca.hovered = true))
			}
		},
		async activeAnnotation(val, oldVal) {
			if (oldVal) {
				const { groupId, componentRef } = oldVal
				// Delete annotation if it has no reported size and it can't be configured from a point
				// or the user somehow canceled the configuration process
				if (!componentRef.hasSize && (!componentRef.configureFromPoint || !(await componentRef.configureFromPoint()))) {
					this.deleteAnnotation(groupId)
				}
			}
		},
	},
	mounted() {
		this.renderWindow = this.renderer.getRenderWindow()
		this.interactor = this.renderWindow.getInteractor()
		this.subscribeToEvents()
		this.$once('hook:beforeDestroy', () => {
			this.unsubscribeFromEvents()
		})
	},
	methods: {
		subscribeToEvents() {
			// Handle camera adjustments
			this.subscribedEvents.push(this.renderer.getActiveCamera().onModified(this.onCameraModified))
			// Handle interactor mouse moves
			this.subscribedEvents.push(this.interactor.onMouseMove(this.onInteractorMouseMove))
		},
		unsubscribeFromEvents() {
			while (this.subscribedEvents.length) {
				this.subscribedEvents.pop().unsubscribe()
			}
		},
		createAnnotation(type, component, props) {
			const id = uuid()
			const groupId = uuid()
			const annotation = {
				id,
				groupId,
				type,
				component,
				slice: this.slice,
				props: {
					...props,
					id,
					groupId,
				},
				children: [],
			}
			this.annotations.push(annotation)
			return annotation
		},
		deleteAnnotation(groupId) {
			const annotation = this.annotations.find(a => a.groupId === groupId)
			if (annotation) {
				annotation.children.forEach(ca => this.deregisterAnnotationEvents(ca))
				if (this.activeAnnotation === annotation) {
					this.activeAnnotation = null
				}
				this.annotations = this.annotations.filter(a => a.groupId !== groupId)
				eventBus.post(eventBus.type.MPR_ANNOTATION_REMOVED, annotation)
			}
		},
		addAnnotationToGroup(annotation, groupId) {
			const annotationGroup = this.annotations.find(a => a.groupId === groupId)
			if (annotationGroup) {
				this.registerAnnotationEvents(annotation)
				annotationGroup.children.push(annotation)
			}
		},
		removeAnnotationFromGroup(annotation, groupId) {
			const annotationGroup = this.annotations.find(a => a.groupId === groupId)
			if (annotationGroup) {
				this.deregisterAnnotationEvents(annotation)
				annotationGroup.children = annotationGroup.children.filter(c => c !== annotation)
			}
		},
		clearAnnotations() {
			this.annotations = []
			eventBus.post(eventBus.type.MPR_ANNOTATIONS_CLEARED)
		},
		refreshHoveredAnnotation() {
			this.hoveredAnnotation = this.activeAnnotation || first(this.getAnnotationsAtPoint(this.pointerPosition))
		},
		onCameraModified() {
			this.onViewportModified()
		},
		onViewportModified() {
			this.$emit('viewport-modified')
		},
		onInteractorMouseMove({ position }) {
			this.pointerPosition = this.canvasToSvg(position)
			this.$emit('interaction-update', { ...this.pointerPosition })
		},
		onInteractionBegin(tool, position) {
			// Update pointer position (needed for touch devices)
			this.pointerPosition = this.canvasToSvg(position)
			// Get first interactable annotation under cursor
			const annotation = this.getAnnotationsAtPoint(this.pointerPosition, {
				children: true,
			}).find(a => a.onInteractionBegin)
			let result = false
			this.interactionTool = tool
			// Check for eraser first
			if (tool === consts.ERASER_TOOL) {
				if (annotation) {
					// Delete group annotation
					this.deleteAnnotation(annotation.groupId)
				}
			} else if (annotation) {
				result = true
				// Start interaction for existing child annotation
				annotation.onInteractionBegin()
			} else if (tool !== consts.PRE_TOOL) {
				result = true
				// Create new annotation for the tool
				const { type, component } = this.tools.find(t => t.type === tool)
				const annotation = this.createAnnotation(type, component)
				// DOM elements won't exist until next tick
				this.$nextTick(() => {
					this.refreshHoveredAnnotation()
					// Start interaction for new annotation
					annotation.componentRef = this.$refs.annotations.find(a => a.id === annotation.id)
					if (annotation.componentRef) {
						annotation.componentRef.onInteractionBegin()
					}
				})
			}
			return result
		},
		onInteractionUpdate(tool, position) {
			// Right now this gets handled by a private event handler b/c
			// we always need pointer updates for hover detection,
			// not just when a certain tool is active
			// this.$emit('interaction-update', { ...this.pointerPosition })
		},
		onInteractionEnd(tool, position) {
			this.$emit('interaction-end', { ...this.pointerPosition })
			return !!this.activeAnnotation && this.interactionTool === consts.PRE_TOOL
		},
		onAnnotationDraggingAny({ groupId }, dragging) {
			const annotation = this.annotations.find(a => a.groupId === groupId)
			if (dragging) {
				this.activeAnnotation = annotation
			} else if (annotation && annotation === this.activeAnnotation) {
				this.activeAnnotation = null
			}
		},
		onAnnotationDraggingAll({ groupId }, dragging) {
			if (dragging) {
				this.annotationClick = () => {
					const annotation = this.annotations.find(a => a.groupId === groupId)
					eventBus.post(eventBus.type.MPR_ANNOTATION_CLICK, annotation)
				}
				setTimeout(() => (this.annotationClick = null), 180)
			} else {
				if (this.annotationClick) this.annotationClick()
			}
		},
		onAnnotationDelete(e) {
			this.deleteAnnotation(e.groupId)
		},
		getAnnotationsAtPoint(dp, { type = '', children = false } = {}) {
			const annotations = []
			const { x, y } = this.displayToViewport(dp)
			let elements = null
			if (document.elementsFromPoint) {
				elements = document.elementsFromPoint(x, y)
			} else if (document.msElementsFromPoint) {
				elements = document.msElementsFromPoint(x, y)
			}
			const nodesArray = Array.prototype.slice.call(elements)
			for (var n of nodesArray) {
				const id = n.getAttribute('data-id')
				const groupId = n.getAttribute('data-group-id')
				if (!groupId) continue
				const groupAnnotation = this.annotations.find(a => a.groupId === groupId)
				if (!groupAnnotation || (type && type !== groupAnnotation.type)) {
					continue
				}
				if (children) {
					annotations.push(...groupAnnotation.children.filter(c => c.id === id))
				} else {
					annotations.push(groupAnnotation)
				}
			}
			return uniq(annotations)
		},
		displayToViewport({ x, y } = {}) {
			const rect = this.$el.getBoundingClientRect()
			x += rect.left + window.pageXOffset
			y += rect.top + window.pageYOffset
			return { x, y }
		},
		registerAnnotationEvents(annotation) {
			this.registerEvent(this, 'viewport-modified', annotation.onViewportModified)
			this.registerEvent(this, 'interaction-begin', annotation.onInteractionBegin)
			this.registerEvent(this, 'interaction-update', annotation.onInteractionUpdate)
			this.registerEvent(this, 'interaction-end', annotation.onInteractionEnd)
			this.registerEvent(annotation, 'dragging-any', this.onAnnotationDraggingAny)
			this.registerEvent(annotation, 'dragging-all', this.onAnnotationDraggingAll)
			this.registerEvent(annotation, 'delete', this.onAnnotationDelete)
		},
		deregisterAnnotationEvents(annotation) {
			this.deregisterEvent(this, 'viewport-modified', annotation.onViewportModified)
			this.deregisterEvent(this, 'interaction-begin', annotation.onInteractionBegin)
			this.deregisterEvent(this, 'interaction-update', annotation.onInteractionUpdate)
			this.deregisterEvent(this, 'interaction-end', annotation.onInteractionEnd)
			this.deregisterEvent(annotation, 'dragging-any', this.onAnnotationDraggingAny)
			this.deregisterEvent(annotation, 'dragging-all', this.onAnnotationDraggingAll)
			this.deregisterEvent(annotation, 'delete', this.onAnnotationDelete)
		},
		registerEvent(source, eventName, method) {
			if (source && eventName && typeof method === 'function') {
				source.$on(eventName, method)
			}
		},
		deregisterEvent(source, eventName, method) {
			if (source && eventName && typeof method === 'function') {
				source.$off(eventName, method)
			}
		},
		canvasToSvg(position) {
			const dp = { ...position }
			const ratio = window.devicePixelRatio || 1
			dp.x /= ratio
			dp.y /= ratio
			dp.z /= ratio
			dp.y = this.height - dp.y
			return dp
		},
		/**
		 * @param {Object} inputCoords
		 * @param {number} inputCoords.x
		 * @param {number} inputCoords.y
		 * @param {number} inputCoords.z Should always be 0, there is no depth in 2d screen space
		 * @return xyz object
		 */
		displayToWorld({ x = 0, y = 0, z = 0 } = {}) {
			x = x || 0
			y = y || 0
			z = z || 0
			if (!this.renderer) {
				return null
			}
			// Flip Y axis to match vtk 0 starting at the bottom of the screen
			y = this.height - y
			// Adjust for device pixel ratio
			const ratio = window.devicePixelRatio || 1
			x *= ratio
			y *= ratio
			z *= ratio
			let [wx, wy, wz] = this.interactor.getView().displayToWorld(x, y, z, this.renderer)
			return { x: wx, y: wy, z: wz }
		},
		/**
		 * @param {Object} inputCoords
		 * @param {number} inputCoords.x
		 * @param {number} inputCoords.y
		 * @param {number} inputCoords.z
		 * @return xyz object
		 */
		worldToDisplay({ x = 0, y = 0, z = 0 } = {}) {
			if (!this.renderer) {
				return null
			}
			// Adjust for device pixel ratio
			const ratio = window.devicePixelRatio || 1
			let [dx, dy] = this.interactor.getView().worldToDisplay(x, y, z, this.renderer)
			dx /= ratio
			dy /= ratio
			// Flip y axis to match svg 0 starting at the top of the screen
			dy = this.height - dy
			return { x: dx, y: dy, z: 0 }
		},
		worldDistanceToDisplay(d) {
			// Return distance divided by a 2D length === 1
			return d / this.worldDistanceBetween({ x: 0, y: 0 }, { x: 1, y: 0 }, 6)
		},
		// Computes the display distance between 2 display points
		displayDistanceBetween(d1, d2, digits = 2) {
			if (!d1 || !d2) return NaN
			const { x: x1, y: y1 } = d1
			const { x: x2, y: y2 } = d2
			let distance = Math.sqrt(Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2))
			return round(distance, digits)
		},
		// Computes the world distance between 2 display points
		worldDistanceBetween(d1, d2, digits = 2) {
			if (!d1 || !d2) return NaN
			const world1 = this.displayToWorld(d1)
			const world2 = this.displayToWorld(d2)
			const distance = Math.sqrt(distance2BetweenPoints([world1.x, world1.y, world1.z], [world2.x, world2.y, world2.z]))
			return round(distance, digits)
		},
		// Computes a world distance from a display distance
		worldDistance(d, digits = 2) {
			return this.worldDistanceBetween({ x: 0, y: 0 }, { x: d, y: 0 }, digits)
		},
		/**
		 * @param {Number} mx the mouse X position in screen coordinates
		 * @param {Number} my the mouse Y position in screen coordinates
		 * @return {Number|NaN} the corresponding pixel's scalar value
		 */
		getVoxelValueFromScreenCoord(mx, my) {
			return this.getVoxelValueFromIndex(this.getVoxelIndexFromScreenCoord(mx, my))
		},
		getVoxelValueFromIndex(voxelIndex) {
			const len = this.volumeData
				.getPointData()
				.getScalars()
				.getData().length
			// More expensive checks
			if (!isNaN(voxelIndex) && voxelIndex >= 0 && voxelIndex < len) {
				return this.volumeData
					.getPointData()
					.getScalars()
					.getData()[voxelIndex]
			} else {
				// console.warn('index is outside the scope', voxelIndex, len)
				// vtkErrorMacro(`GetScalarPointer: Pixel ${index} is not in memory. Current extent = ${e}`)
				return NaN
			}
		},
		/**
		 * @param {Number} mx the mouse X position in screen coordinates
		 * @param {Number} my the mouse Y position in screen coordinates
		 * @return {Number|NaN} the corresponding pixel's index in the scalar array
		 */
		getVoxelIndexFromScreenCoord(mx, my) {
			const { x, y, z } = this.displayToWorld({ x: mx, y: my })
			// TODO: Account for thickness by grabbing every voxel in the thickness layer
			// Using the camera's DOP to move the XYZ position,
			// and using the active blend mode function to choose the "right" pixel
			return this.getVoxelIndexFromWorldCoord([x, y, z])
		},
		/**
		 * @param {Number[]} xyz the [x,y,z] Array in world coordinates
		 * @return {Number|NaN} the corresponding pixel's index in the scalar array
		 */
		getVoxelIndexFromWorldCoord(xyz) {
			let index = []
			this.volumeData.worldToIndex(xyz, index)
			// Round to integer values because we're talking array indices here
			index = index.map(v => Math.round(v))

			// Grab the dimensions of the array data
			// https://vtk.org/Wiki/VTK/Tutorials/Extents
			const e = this.volumeData.getExtent()

			// Confirm normalized x,y,z coords are within the bounds of the volume
			// Checking here doesn't help find the closest world coord spot though...
			for (let idx = 0; idx < 3; ++idx) {
				if (index[idx] < e[idx * 2] || index[idx] > e[idx * 2 + 1]) {
					// console.warn('pixel xyz is not within the extent', index, e)
					// vtkErrorMacro(`GetScalarPointer: Pixel ${index} is not in memory. Current extent = ${e}`)
					return NaN
				}
			}

			/**
			 * @param {Extent} e the volume's extent array
			 * @param {Number[]} indexXYZ the localized pixel array position in 3d. Make sure it is integer values.
			 * @param {Number} length if the scalars have multiple values, IE RGB vs single color
			 * @return {Number} the corresponding pixel's index in the scalar array
			 */
			function computeArrayIndex(e, [ix, iy, iz], length = 1) {
				const incs = []
				let incr = length

				// Calculate array increment offsets based on c++ vtk
				// vtkImageData::ComputeIncrements
				for (var idx = 0; idx < 3; ++idx) {
					incs[idx] = incr
					incr *= e[idx * 2 + 1] - e[idx * 2] + 1
				}
				// Use the array increments to find the pixel index
				// vtkImageData::GetArrayPointer
				// Math.floor to catch "practically 0" e^-15 scenarios.
				return Math.floor((ix - e[0]) * incs[0] + (iy - e[2]) * incs[1] + (iz - e[4]) * incs[2])
			}

			const voxelIndex = computeArrayIndex(e, index)

			// Assumed the index here is within 0 <-> pixels.length, but doesn't hurt to check upstream
			return voxelIndex
		},
	},
}
</script>

<style lang="scss" scoped>
.annotation-container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}
</style>
