import Vm from '@store/models/base/Vm'
import MetaColumn from '@store/models/base/TableMetaColumn'

export default class extends Vm {
	constructor() {
		var detail = null
		var columns = [
			MetaColumn({ name: 'Title', columnName: 'title', sortName: 'title' }),
			MetaColumn({ name: 'First', columnName: 'firstName', sortName: 'firstName' }),
			MetaColumn({ name: 'Last', columnName: 'lastName', sortName: 'lastName' }),
			MetaColumn({
				name: 'Clinic/Hospital',
				columnName: 'organizationName',
				sortName: 'OrganizationName',
			}),
			MetaColumn({ name: 'Email', columnName: 'email', sortName: 'email' }),
		]

		// Construct
		super(detail, columns)
	}
}
