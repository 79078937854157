<template>
	<v-date-picker
		:value="date"
		:attributes="attributes"
		:input-props="$attrs"
		:popover="{ visibility }"
		:masks="masks"
		:locale="locale"
		:max-date="maxDate"
		:is-dark="themeIsDark"
		:is-required="!allowClear"
		@input="onInput"
	>
		<template #default="{ inputProps, inputEvents }">
			<slot :input-props="inputEvents">
				<div class="dp-wrapper">
					<svg class="dp-calendar" viewBox="0 0 20 20">
						<path
							d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
						/>
					</svg>
					<input
						ref="input"
						class="input"
						:style="{ paddingLeft: '25px' }"
						:value="inputProps.value"
						v-bind="inputProps"
						v-on="inputEvents"
					/>
					<span v-if="date && !inputProps.disabled && allowClear" class="dp-clear">
						<svg viewBox="0 0 10 10" @click.stop="clearDate">
							<path
								d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
							/>
						</svg>
					</span>
				</div>
			</slot>
		</template>
	</v-date-picker>
</template>

<script>
import VDatePicker from 'v-calendar/lib/components/date-picker.umd'
import { getTomorrow } from '@utils/dateUtils'
import { mapGetters } from 'vuex'
import { getLocale } from '@utils/locale'

export default {
	name: 'DatePicker',
	components: {
		VDatePicker,
	},
	inheritAttrs: false,
	props: {
		value: {
			type: [Date, String],
			default: null,
		},
		allowClear: {
			type: Boolean,
			default: true,
		},
		allowFutureDates: {
			type: Boolean,
			default: false,
		},
		endOfDay: {
			type: Boolean,
			default: false,
		},
		visibility: {
			type: String,
			default: 'focus',
		},
	},
	data() {
		return {
			attributes: [
				{
					key: 'today',
					dates: new Date(),
					highlight: {
						color: 'yellow',
						fillMode: 'light',
					},
				},
			],
			masks: {
				weekdays: 'WW',
			},
			maxDate: null,
		}
	},
	computed: {
		...mapGetters(['themeIsDark']),
		locale() {
			return getLocale()
		},
		date() {
			if (this.value && typeof this.value === 'string') return new Date(this.value)
			return this.value
		},
	},
	watch: {
		allowFutureDates() {
			this.refreshMaxDate()
		},
	},
	mounted() {
		// Keep maxDate up-to-date
		this.refreshMaxDate()
		const fiveMinutes = 5 * 60 * 1000
		const refreshInterval = setInterval(this.refreshMaxDate, fiveMinutes)
		this.$once('hook:beforeDestroy', () => {
			clearInterval(refreshInterval)
		})
	},
	methods: {
		refreshMaxDate() {
			this.maxDate = this.allowFutureDates ? null : getTomorrow()
		},
		onInput(e) {
			if (e && this.endOfDay) {
				e.setHours(23, 59, 59, 999)
			}
			this.$emit('input', e)
		},
		clearDate() {
			this.$emit('input', null)
			this.$refs.input.focus()
		},
	},
}
</script>

<style lang="scss">
.dp-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	max-width: 350px;
	input:disabled {
		opacity: 0.7;
	}
}
.dp-calendar {
	position: absolute;
	left: 5px;
	width: 16px;
	height: 16px;
	fill: var(--tertiary-label);
	pointer-events: none;
}
.dp-clear {
	position: absolute;
	right: 0;
	padding: 2px 8px 4px 8px;
	cursor: pointer;
	& svg {
		width: 10px;
		height: 10px;
		fill: var(--secondary-label);
	}
	&:hover {
		opacity: 0.5;
	}
	&.disabled {
		opacity: 0.3;
	}
}
</style>
