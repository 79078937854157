import macro from 'vtk.js/Sources/macro'
import vtkCompositeCameraManipulator from 'vtk.js/Sources/Interaction/Manipulators/CompositeCameraManipulator'
import vtkCompositeMouseManipulator from 'vtk.js/Sources/Interaction/Manipulators/CompositeMouseManipulator'

// ----------------------------------------------------------------------------
// vtkStackScrollManipulator methods
// ----------------------------------------------------------------------------

function vtkStackScrollManipulator(publicAPI, model) {
	// Set our className
	model.classHierarchy.push('vtkStackScrollManipulator')

	publicAPI.onButtonDown = (interactor, renderer, position) => {
		model.previousPosition = position
	}

	publicAPI.onMouseMove = (interactor, renderer, position) => {
		const MPRSliceInteractor = publicAPI.getMPRSliceInteractor()
		if (!position || !MPRSliceInteractor || !MPRSliceInteractor.isA('vtkInteractorStyleMPRSlice')) {
			return
		}

		const scale = window.devicePixelRatio || 1
		// This arbitrary scale seems to work well for value differences
		const dy = (model.previousPosition.y - position.y) / (2 * scale)
		const slice = MPRSliceInteractor.getSlice()
		MPRSliceInteractor.scrollToSlice(slice + dy)

		model.previousPosition = position
	}
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
	MPRSliceInteractor: null,
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
	Object.assign(model, DEFAULT_VALUES, initialValues)

	// Inheritance
	macro.obj(publicAPI, model)
	vtkCompositeMouseManipulator.extend(publicAPI, model, initialValues)
	vtkCompositeCameraManipulator.extend(publicAPI, model, initialValues)

	macro.setGet(publicAPI, model, ['MPRSliceInteractor'])

	// Object specific methods
	vtkStackScrollManipulator(publicAPI, model)
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkStackScrollManipulator')

// ----------------------------------------------------------------------------

export default { newInstance, extend }
