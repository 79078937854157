import add from './addMetadata.js'
import update from './updateMetadata.js'
import provider from './provider.js'
import api from '@services/api'
import { updatePrecachedCornerstoneImage } from '@/imageLoader'

//
import metadata from './internals/metadataLookup.js'
import store from '@store/'
/**
 * Clear all cached metadata
 *
 * @public
 * @function clear
 *
 * @returns {undefined}
 */
const clear = () => metadata.lookup.clear()

/**
 * Return the metadata for the given imageId
 *
 * @public
 * @function get
 *
 * @param {String} imageId The Cornerstone ImageId
 * @returns {*} image metadata
 */
const get = imageId => {
	return metadata.lookup.get(imageId.toLowerCase())
}

/**
 * Returns true if the imageId exists
 *
 * @public
 * @function has
 *
 * @param {String} imageId The Cornerstone ImageId
 * @returns {boolean} - True if imageId exists
 */
const has = imageId => metadata.lookup.has(imageId.toLowerCase())

/**
 * Removes the metadata for the given imageId from the lookup
 *
 * @public
 * @function remove
 *
 * @param {String} imageId The Cornerstone ImageId
 * @returns {boolean} - True if found and deleted; false if not found
 */
const remove = imageId => metadata.lookup.delete(imageId.toLowerCase())

// Named
export { provider }

// Default
export default {
	add,
	clear,
	get,
	has,
	remove,
	update,
}

export function loadMetadata(series) {
	if (series.images.every(i => has(i.imageId))) return
	if (series && !series.isFakeSeries) {
		return api.viewer.getMetadata(series).then(addSeriesMetadata)
	} else {
		return api.viewer.getMetaByImage(series).then(responses => addSeriesMetadata([].concat(...responses)))
	}
}

function addSeriesMetadata(seriesMetadata) {
	const promises = seriesMetadata.map(
		data =>
			new Promise(resolve => {
				if (!data.ImageId) return resolve()
				const imageId = data.ImageId.toLowerCase()
				add(imageId, data.DicomTags)
				updatePrecachedCornerstoneImage(imageId)
				const calibration = store.state.viewer.calibrations && store.state.viewer.calibrations[imageId]
				if (calibration) {
					// apply custom calibration sent from another window
					const { oldLength, oldPixelSpacing, newLength } = calibration
					store.dispatch('updateCalibration', { imageId, oldLength, oldPixelSpacing, newLength })
				}
				resolve()
			})
	)
	return Promise.all(promises)
}
