import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '@store'
import { eventBus } from '@services/eventBus'
import storage from '@services/storage'

Vue.use(Router)

const router = new Router({
	routes,
	linkActiveClass: 'active',
	linkExactActiveClass: 'exact-active',
	// https://router.vuejs.org/en/advanced/scroll-behavior.html
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach((to, from, next) => {
	next()
})

router.afterEach((to, from) => {
	// Close modal navigation drawer
	store.commit('TOGGLE_MODAL_DRAWER', false)
	if (to.meta.themeSettings !== from.meta.themeSettings) store.dispatch('applyTheme', to.meta.themeSettings)

	// Update title
	let isCommunity = store.getters.isCommunityUser
	if (isCommunity === undefined) {
		isCommunity = storage.getItem('lastLogin') === 'community'
	}
	const OmniOrCommunity = isCommunity ? 'Community' : 'Omni'
	document.title = `${to.meta.title ? to.meta.title + ' - ' : ''}Keystone ${OmniOrCommunity}`

	eventBus.post('routechanged')
})

export default router
