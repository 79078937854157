import { reset, getEnabledElement } from 'cornerstone-core/dist/cornerstone.js'

export default function({ commit, dispatch, getters }) {
	try {
		const canvas = getters.activeCanvas
		if (!canvas.seriesId && !canvas.imageId) return
		const enabledElement = getEnabledElement(canvas.dom)
		const imageId = enabledElement.image ? enabledElement.image.asterisImageId : null

		reset(canvas.dom)
		canvas.vue.isAnyImageLevelAdjusted = false
		commit('CLEAR_SERIES_VIEWPORT_CACHE', canvas.seriesId)

		if (imageId) {
			dispatch('clearCalibrationAndBroadcast', { imageId })
		}
	} catch (Exception) {
		console.warn(Exception)
		console.warn('Unable to reset active canvas')
	}
}
