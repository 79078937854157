<template>
	<drag-annotation
		:id="`${id}-circle`"
		v-model="position"
		:group-id="groupId"
		@drag-start="draggingAll = true"
		@drag-end="draggingAll = false"
	>
		<g v-if="p1 && p3" :style="groupStyle" data-position-ref>
			<!--Display circle-->
			<circle :cx="center.x" :cy="center.y" :r="radius" :style="strokeStyle" />
			<!--Transparent hit target circle-->
			<circle
				:data-id="`${id}-circle`"
				:data-group-id="groupId"
				:cx="center.x"
				:cy="center.y"
				:r="radius"
				:style="strokeHitTargetStyle"
			/>
			<!--Center crosshairs-->
			<crosshairs-annotation :id="`${id}-circle`" :group-id="groupId" :origin="center" />
			<!--Start handle-->
			<drag-annotation
				:group-id="groupId"
				:value="p1"
				:color="activeColor"
				@input="updateStart"
				@drag-start="drag.p1 = true"
				@drag-end="drag.p1 = false"
			/>
			<!--End handle-->
			<drag-annotation
				:group-id="groupId"
				:value="p3"
				:color="activeColor"
				drag-on-load
				@input="updateEnd"
				@drag-start="drag.p3 = true"
				@drag-end="drag.p3 = false"
			/>
			<text-annotation
				v-if="statsVisible"
				:value="statText"
				:group-id="groupId"
				:event-source="textSource"
				:color="activeColor"
			/>
		</g>
	</drag-annotation>
</template>

<script>
import DragAnnotation from './DragAnnotation'
import TextAnnotation from './TextAnnotation'
import CrosshairsAnnotation from './CrosshairsAnnotation'
import { quadMixin } from './mixins'
import { openPromptDlg } from '@dialogs/TextPromptDlg'

export default {
	name: 'CircleAnnotation',
	components: {
		DragAnnotation,
		TextAnnotation,
		CrosshairsAnnotation,
	},
	mixins: [quadMixin],
	data() {
		return {
			statTemplate: 'Area: {area}\nDiameter: {diameter}',
		}
	},
	computed: {
		crosshairStyle() {
			return {
				cursor: 'pointer',
				stroke: this.activeColor,
				strokeWidth: '1.5px',
			}
		},
		area() {
			return Math.PI * this.worldRadius * this.worldRadius
		},
	},
	methods: {
		async configureFromPoint() {
			return this.promptForDiameter()
		},
		async promptForDiameter(defaultDiameter = this.worldDiameter) {
			const result = await openPromptDlg({
				prompt: 'Enter circle diameter [mm]:',
				defaultValue: defaultDiameter ? defaultDiameter.toString() : '',
			})
			return this.updateDiameter(result)
		},
		validateDiameter(diameter) {
			diameter = Number.parseFloat(diameter)
			if (diameter <= 0) return NaN
			return diameter
		},
		updateDiameter(diameter) {
			diameter = this.validateDiameter(diameter)
			if (Number.isNaN(diameter)) return false
			// Keep the same normalized center
			const center = { ...this.centerNorm }
			// Get the diameter in 2D coordinates
			const displayDiameter = this.worldDistanceToDisplay(diameter)
			const displayRadius = displayDiameter / 2
			// Create new points and rotate back to effective rotation
			const [p1, p2, p3, p4] = [
				{
					x: center.x - displayRadius,
					y: center.y - displayRadius,
				},
				{
					x: center.x + displayRadius,
					y: center.y - displayRadius,
				},
				{
					x: center.x + displayRadius,
					y: center.y + displayRadius,
				},
				{
					x: center.x - displayRadius,
					y: center.y + displayRadius,
				},
			].map(p => this.rotatePoint(p, this.effectiveRotationRads))
			this.points = { p1, p2, p3, p4 }
			this.$nextTick(() => {
				this.refreshStats()
			})
			return true
		},
		updateStart(pnt) {
			const effRot = this.effectiveRotationRads
			const p1 = this.rotatePoint(pnt, -effRot)
			const p3 = this.rotatePoint(this.p3, -effRot)
			const diameterX = Math.abs(p1.x - p3.x)
			const diameterY = Math.abs(p1.y - p3.y)
			const diameter = Math.min(diameterX, diameterY)
			this.p1 = this.rotatePoint(
				{
					x: p3.x + diameter * (p1.x > p3.x ? 1 : -1),
					y: p3.y + diameter * (p1.y > p3.y ? 1 : -1),
				},
				effRot
			)
		},
		updateEnd(pnt) {
			const effRot = this.effectiveRotationRads
			const p1 = this.rotatePoint(this.p1, -effRot)
			const p3 = this.rotatePoint(pnt, -effRot)
			const diameterX = Math.abs(p1.x - p3.x)
			const diameterY = Math.abs(p1.y - p3.y)
			const diameter = Math.min(diameterX, diameterY)
			this.p3 = this.rotatePoint(
				{
					x: p1.x + diameter * (p3.x > p1.x ? 1 : -1),
					y: p1.y + diameter * (p3.y > p1.y ? 1 : -1),
				},
				effRot
			)
		},
		// https://math.stackexchange.com/questions/76457/check-if-a-point-is-within-an-ellipse
		pointInsideBounds(width, height, x, y) {
			const r = (width + height) / 4 // Remove tiny variance from width/height
			if (!r) return false
			return Math.pow(x - r, 2) / Math.pow(r, 2) + Math.pow(y - r, 2) / Math.pow(r, 2) <= 1
		},
	},
}
</script>
