<template>
	<modal-wrapper :close-on-escape="false" :close-on-overlay-click="false" @close="$emit('close')">
		<div class="modal-dialog">
			<dlg-header :title="title" :show-close="showCloseHeader" @close="$emit('close', false)">
				<slot name="header"></slot>
			</dlg-header>
			<div class="modal-body" :class="{ scroll: scroll, 'no-header': !title }">
				<slot></slot>
			</div>

			<div v-if="showFooter" class="footer right">
				<template v-for="action in actions">
					<!-- BUTTON -->
					<ast-button
						v-if="action.type === 'button'"
						:key="action.name"
						ref="buttons"
						:type="actions.length === 1 ? 'primary' : 'default'"
						style="min-width: 64px;"
						:disabled="action.isDisabled"
						@click.native="$emit(action.emit)"
					>
						<svg-icon v-if="action.icon" :icon="action.icon" />
						{{ action.name }}
					</ast-button>
				</template>
				<ast-button @click.native="$emit('close', true)">
					{{ closeText }}
				</ast-button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import AstButton from '@components/Button.vue'

export default {
	name: 'ModalDialog',
	components: {
		ModalWrapper,
		AstButton,
		DlgHeader,
	},
	props: {
		scroll: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			required: true,
		},
		actions: {
			type: Array,
			default: () => [],
			required: false,
		},
		closeText: {
			type: String,
			default: 'Close',
		},
		showCloseHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
	},
	mounted() {
		// autofocus first button
		if (this.$refs.buttons && this.$refs.buttons.length) this.$refs.buttons[0].$el.focus()
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.modal-dialog {
	display: flex;
	flex-direction: column;
	padding: 0;
	max-width: 700px;
	background: var(--secondary-bg);
	max-height: 100%;
	width: 100%;
	@media (min-width: $mqSmall) {
		max-height: 90%;
		width: auto;
	}

	h4 {
		margin: 10px 0;
		line-height: 1.5;
		font-size: 18px;
		font-weight: 300;
	}

	p,
	li {
		line-height: 1.6;
		margin-top: 0;
	}
	ul, ol {
		padding-inline-start: 25px;
		margin-block-start: 1rem;
		margin-block-end: 1rem;
	}
	li {
		margin-bottom: 0.5rem;
	}
	p:not(:last-child) {
		margin-bottom: 1rem;
	}

	.modal-body {
		height: 100%;
		width: 100%;
		min-height: 0;
		padding: 16px;
		&.scroll {
			overflow-y: auto;
			overflow-x: hidden;
			border-bottom-width: 1px;
			border-top-width: 1px;
			border-top-style: solid;
			border-bottom-style: solid;
			border-color: var(--secondary-border);
		}
		&.no-header {
			padding-top: 0;
		}
	}
	.footer {
		padding: 0 16px 16px 16px;
		display: flex;
		flex-shrink: 0;
		margin: 0;
		&.right {
			justify-content: flex-end;
		}
	}
	.modal-body.scroll + .footer {
		padding-top: 16px;
	}
}
</style>
