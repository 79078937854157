<template>
	<div :class="{ stack: cell.props['label position'] == 'Left' }" style="padding: 4px 0;">
		<label v-if="cell.props.label" :style="labelStyle">{{ cell.props.label }}</label>
		<datepicker
			v-model="value"
			:allow-future-dates="cell.props['allow future dates']"
			:disabled="cell.editorMode || cell.readOnly"
			:tabindex="cell.editorMode ? -1 : 0"
		/>
	</div>
</template>

<script>
import Datepicker from '@components/DatePicker.vue'

export default {
	name: 'Date',
	components: {
		Datepicker,
	},
	props: ['cell'],
	computed: {
		value: {
			get() {
				return this.cell.value
			},
			set(value) {
				if (!value || !this.cell.value || value.toString() !== this.cell.value.toString()) {
					this.cell.value = value
				}
			},
		},
		labelStyle() {
			if (this.cell.props['label position'] === 'Top') {
				return {
					display: 'block',
					marginBottom: '8px',
				}
			}
			return {}
		},
	},
	created() {
		if (!(this.cell.value instanceof Date) && this.cell.value) {
			this.cell.userValue = new Date(this.cell.value)
		}
	},
}
</script>
