import api from '@services/api'
// Functions/Services
import consultations from '@services/consultationService'
import { studyFolders } from '@services/folders'
import { menu } from '@services/menu'
import Vue from 'vue'
import Vuex from 'vuex'
// Modules
// import analytics from './modules/analytics'
import auth from './modules/authentication'
import mpr from './modules/mpr'
import reportColorSettings from './modules/reportColorSettings.js'
import repository from './modules/repository'
import repositoryReview from './modules/repositoryReview.js'
import saleHipStore from './modules/saleHipStore.js'
import saleSubmission from './modules/saleSubmission'
import sentry from './modules/sentry'
import settings from './modules/settings'
import theme from './modules/theme'
import toolManager from './modules/toolManager'
import ui from './modules/ui'
import viewer from './modules/viewer'
import viewerHotkeys from './modules/viewerHotkeys'
import viewerMouseBindings from './modules/viewerMouseBindings'
import windowLevelPresets from './modules/windowLevelPresets'
import windows from './modules/windows.js'
import plugins from './plugins'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		// analytics,
		auth,
		repository,
		sentry,
		theme,
		toolManager,
		viewer,
		viewerHotkeys,
		viewerMouseBindings,
		windowLevelPresets,
		saleSubmission,
		settings,
		mpr,
		ui,
		windows,
		reportColorSettings,
		repositoryReview,
		saleHipStore,
	},
	state: {
		applicationRootUrl: window.config.applicationRootUrl,
		restApplicationRootUrl: window.config.restApplicationRootUrl,
		keystoneRootUrl: window.config.keystoneRootUrl,
		marketingRootUrl: window.config.marketingRootUrl,
		isUserDataLoaded: false,
		isLoadingData: false,
		static: {
			clinics: [],
			consultants: [], // consultants that the user can send to
			consultantsForAdmin: [], // consultants within local clinic
			permissions: {},
			modalities: [],
			consignerNames: [],
		},
		consultant: {
			clientClinics: [],
			groupMembers: [],
			regions: [],
			requestTypes: [],
		},
	},
	getters: {
		activeClinic(state) {
			const activeClinicId = state.auth.claims.activeClinicId
			return state.static.clinics.find(clinic => clinic.id === activeClinicId)
		},
		permissions(state) {
			return state.static.permissions
		},
		modalityNamesById: state => ids => {
			const modalities = ids.map(id => state.static.modalities.find(m => m.id === id))
			return modalities.map(m => m.name)
		},
		consignerNamesById: state => ids => {
			const consigners = ids.map(id => state.static.consignerNames.find(m => m.id === id))
			return consigners.map(m => m.name)
		},
		isLoadingData(state) {
			return state.isLoadingData
		},
	},
	actions: {
		async getPermissions({ commit }) {
			commit('SET_PERMISSIONS', {})
			const { data } = await api.user.getPermissions()
			commit('SET_PERMISSIONS', data.data)
		},
		async getConsultants({ commit }) {
			try {
				const data = await consultations.getConsultants()
				if (data && data.results) commit('SET_CONSULTANTS', data.results)
			} finally {
			}
		},
		async getClinics({ state, dispatch }) {
			if (!state.auth.claims || !state.auth.claims.userId) return
			const response = await api.clinic.getList()
			const clinics = response.data.data
			dispatch('setClinics', clinics)
		},
		async getModalities({ commit }) {
			const response = await api.clinic.getModalities()
			const responseData = response.data
			commit('SET_MODALITIES', responseData)
		},
		async getAutoCompleteList({ commit }) {
			const response = await api.autoComplete.getAutoCompleteList()
			const responseData = response.data
			commit('SET_AUTOCOMPLETE_LIST', responseData)
		},
		setClinics({ commit }, clinics) {
			if (!clinics || !clinics.length) return
			commit('SET_USER_CLINICS', clinics)
		},
		async getConsultantsForAdmin({ commit }) {
			const consultantsForAdmin = await consultations.getConsultantsForAdmin()
			commit('SET_CONSULTANTS_FOR_ADMIN', consultantsForAdmin)
		},
		async getConsultantData({ state, commit }) {
			if (!state.auth.claims || !state.auth.claims.userId) return
			const { userId } = state.auth.claims
			const responses = await Promise.all([
				consultations.getConsultantGroupMembers(userId),
				consultations.getConsultantRegions(userId),
				consultations.getConsultantClientClinics(userId),
				consultations.getConsultantRequestTypes(userId),
			])
			commit('SET_CONSULTANT_GROUP_MEMBERS', responses[0])
			commit('SET_CONSULTANT_REGIONS', responses[1])
			commit('SET_CONSULTANT_CLIENT_CLINICS', responses[2])
			commit('SET_CONSULTANT_REQUEST_TYPES', responses[3])
		},
		async getConsignerNames({ commit }, { consultantId }) {
			const response = await api.clinic.getConsignerNames(consultantId)
			commit('SET_CONSIGNER_NAMES', response.data)
		},
		async initStatics({ state, dispatch, commit }) {
			const actions = [
				'getClinics',
				'getConsultants',
				'getConsultantsForAdmin',
				'getPermissions',
				'getModalities',
				'getAutoCompleteList',
				'fetchThemeForUser',
				'fetchOpenStudiesInNewWindow',
				'fetchLoadSeriesFromMiddle',
				'fetchViewerToolbarLocation',
        'fetchViewerSettings',
				'checkLastViewedVersion',
				'preloadViewerWindows',
				'getDesktopUpdateInfo',
				'getReportColorSettingsAction',
        'fetchStudyListHeaderSettings',
        'fetchTeleconsultationListHeaderSettings',
			]
			if (state.auth.claims.isConsultantUser) actions.push('getConsultantData')
			if (state.auth.claims.isRepositoryUser) actions.push('getRepositoryData')
			// TODO: FIX ME
			// eslint-disable-next-line no-constant-condition
			if (state.auth.claims.isSaleSubmissionUser || true) actions.push('getSaleSubmissionData')

			const promises = actions.map(action => dispatch(action))
			if (!state.auth.claims.isCommunityUser && !state.auth.claims.isRepositoryUser) {
				promises.push(menu.fetchMenuSettings())
				promises.push(studyFolders.load())
			}
			await Promise.all(promises)
			commit('SET_IS_USER_DATA_LOADED', true)
		},
		setIsLoadingDataAction({ commit }, status) {
			commit('SET_IS_LOADING_DATA', status)
		},
	},
	mutations: {
		SET_IS_USER_DATA_LOADED(state, isUserDataLoaded) {
			state.isUserDataLoaded = isUserDataLoaded
		},
		CLEAR_STATICS(state) {
			state.static = {
				clinics: [],
				consultants: [],
				consultantsForAdmin: [],
				permissions: {},
				modalities: [{ value: 'empty', label: 'empty' }],
			}
		},
		SET_PERMISSIONS(state, permissions) {
			state.static.permissions = permissions || {}
		},
		SET_CONSULTANTS(state, consultants) {
			state.static.consultants = consultants
		},
		SET_CONSULTANTS_FOR_ADMIN(state, consultantsForAdmin) {
			state.static.consultantsForAdmin = consultantsForAdmin
		},
		SET_USER_CLINICS(state, clinics) {
			state.static.clinics = clinics
		},
		SET_MODALITIES(state, modalities) {
			state.static.modalities = modalities
		},
		SET_CONSULTANT_CLIENT_CLINICS(state, clientClinics) {
			state.consultant.clientClinics = clientClinics
		},
		SET_CONSULTANT_GROUP_MEMBERS(state, groupMembers) {
			state.consultant.groupMembers = groupMembers
		},
		SET_CONSULTANT_REGIONS(state, regions) {
			state.consultant.regions = regions
		},
		SET_CONSULTANT_REQUEST_TYPES(state, requestTypes) {
			state.consultant.requestTypes = requestTypes
		},
		SET_IS_LOADING_DATA(state, payload) {
			state.isLoadingData = payload
		},
		SET_CONSIGNER_NAMES(state, payload) {
			state.static.consignerNames = payload
		},
	},
	plugins,
})
