<template>
	<div class="pagination">
		<div class="results-per-page">
			Results per page:
			<select class="select is-inline" @change="setResultsPerPage">
				<option
					v-for="value in perPageOptions"
					:key="value"
					:value="value"
					:selected="+resultsPerPage === value"
				>
					{{ value }}
				</option>
			</select>
		</div>
		<loading-spinner v-if="mq.small" :is-loading="isLoading" />
		<loading-spinner v-if="!mq.small" is-right is-bottom="3px" :is-loading="isLoading" is-small />
		<div class="page-buttons">
			<button type="button" :disabled="currentPage === 0" @click="goToPreviousPage">
				<span class="symbol">&lsaquo;</span>
				Prev
			</button>
			<span class="range">{{ startingRow }} &ndash; {{ endingRow }}</span>
			<!-- TODO: add total results count once API provides this -->
			<button type="button" :disabled="!hasMoreResults" @click="goToNextPage">
				Next
				<span class="symbol">&rsaquo;</span>
			</button>
		</div>
	</div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner'
export default {
	name: 'Pagination',
	components: {
		LoadingSpinner,
	},
	props: {
		currentPage: {
			type: Number,
			required: true,
		},
		resultsPerPage: {
			type: [Number, String],
			required: true,
		},
		resultsLength: {
			type: Number,
			required: true,
		},
		perPageOptions: {
			type: Array,
			default() {
				return [10, 25, 50]
			},
		},
		hasMoreResults: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		startingRow() {
			return this.currentPage * this.resultsPerPage + 1
		},
		endingRow() {
			return this.currentPage * this.resultsPerPage + this.resultsLength
		},
	},
	created() {
		if (!this.perPageOptions.includes(+this.resultsPerPage)) {
			this.resultsPerPage = this.perPageOptions[0]
		}
	},
	methods: {
		setResultsPerPage(event) {
			this.$emit('set-results-per-page', event.target.value)
		},
		goToPreviousPage() {
			if (this.currentPage === 0) return
			this.$emit('set-current-page', this.currentPage - 1)
		},
		goToNextPage() {
			if (!this.hasMoreResults) return
			this.$emit('set-current-page', this.currentPage + 1)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.pagination {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 0.9rem;
	margin: 8px -8px 4px -8px;
	user-select: none;
}

.results-per-page {
	text-align: center;
	padding: 0 8px;
	.select {
		padding: 4px;
		margin-left: 4px;
	}
}

.page-buttons {
	margin: 8px 8px 0 8px;
	display: flex;
	justify-content: center;
	align-items: baseline;
	.range {
		color: var(--primary-label);
		font-size: 0.9em;
		opacity: 0.75;
		font-weight: 500;
		padding: 0.5em;
	}
	button {
		display: inline-flex;
		justify-content: center;
		align-items: baseline;
		color: var(--link);
		font-weight: 500;
		font-size: 1em;
		background: transparent;
		border: 0;
		cursor: pointer;
		& .symbol {
			font-size: 20px;
			padding: 0 5px;
		}
		&[disabled] {
			color: var(--tertiary-label);
			pointer-events: none;
		}
		&:hover:not([disabled]) {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

@media (min-width: $mqSmall) {
	.pagination {
		flex-direction: row;
		justify-content: space-between;
		margin: 8px -8px 8px -8px;
	}
	.page-buttons {
		margin: 0 8px;
	}
}
</style>
