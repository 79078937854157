import CinePlayer from './CanvasCinePlayer'

export default class {
	constructor({ index, seriesId, asterisImageId, frameIndex, cinePlayer, imageView, dom, vue, isInitializing }) {
		this.index = index
		this.seriesId = seriesId || null
		this.asterisImageId = asterisImageId || null
		this.frameIndex = nullSafeParse(frameIndex)
		this.cinePlayer = cinePlayer || new CinePlayer()
		this.imageView = imageView
		this.vue = vue || null
		this.dom = dom || null
		this.isInitializing = isInitializing || false
	}
}

function nullSafeParse(x) {
	var parsed = parseInt(x)
	if (Number.isNaN(parsed)) {
		return 0
	}
	return parsed
}
