import { API } from '@services/api'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { eventBus } from './eventBus'

const DICOM_STATUS_TIME = 1000 * 2
const MAX_UPDATES = 500

class DicomSendItem {
	constructor(item) {
		this.queueItemId = item.queueItemId
		this.status = item.status
		this.imagesSent = item.imagesSent
		this.totalImages = item.totalImages
		this.dateQueued = item.dateQueued
		this.logs = item.logs
		this.studyId = item.studyId
		this.studyDate = item.studyDate
		this.patientId = item.patientId
		this.patientName = item.patientName
		this.modality = item.modality
	}

	get isError() {
		return this.status === 4 || this.status === 5
	}

	get isSuccess() {
		return this.status === 3
	}

	get canCancel() {
		return this.status <= 2
	}

	get statusName() {
		switch (this.status) {
			case 1:
				return 'Queued'
			case 2:
				return 'Sending'
			case 3:
				return 'Successful'
			case 4:
				return 'Error'
			case 5:
				return 'Cancelled'
		}
	}
}

class DicomSend {
	constructor() {
		this.items = [] // DicomSendItem[]
		this.interval = 0

		eventBus.on('login', this.getUserItems.bind(this))
		this.updateCount = 0
		this.forceUpdates = false
	}

	get studyItems() {
		return this.items.filter(i => i.studyId)
	}

	get sentCount() {
		let count = 0
		this.items.forEach(s => {
			count += s.imagesSent
		})
		return count
	}

	get pendingCount() {
		return this.items.filter(i => i.status <= 2).length
	}

	get totalImages() {
		let count = 0
		this.items.forEach(s => {
			count += s.totalImages
		})
		return count
	}

	enabledUpdates(force = false) {
		this.forceUpdates = force

		if (this.pendingCount > 0 && this.interval === 0) {
			this.interval = setInterval(this.updateList.bind(this), DICOM_STATUS_TIME)
		}
		this.updateCount = 0
	}

	async updateList() {
		this.updateCount++

		for (let i = this.items.length - 1; i >= 0; i--) {
			let item = this.items[i]
			if (item.status > 2) continue

			let info = await this.getSendItemInfo(item.queueItemId)
			if (info) {
				Object.assign(item, info)
			} else {
				this.items.splice(i, 1)
			}
		}

		let stopUpdates = this.pendingCount === 0
		if (this.updateCount > MAX_UPDATES && !this.forceUpdates) {
			stopUpdates = true
		}

		if (stopUpdates) {
			clearInterval(this.interval)
			this.interval = 0
			this.updateCount = 0
		}
	}

	validateDevice(device) {
		function validateAeTitle(str) {
			return /^[^\\]{1,16}$/.test(str)
		}

		let errors = []

		if (device.description !== undefined && !device.description) {
			errors.push('Required Description')
		}

		if (device.aeTitle) {
			if (!validateAeTitle(device.aeTitle)) {
				errors.push('AE Title must be 1-16 characters without backslashes')
			}
		} else {
			errors.push('Required AE Title')
		}

		if (device.userAeTitle && !validateAeTitle(device.userAeTitle)) {
			errors.push('User AE Title must be 1-16 characters without backslashes')
		}

		if (!device.port > 0) {
			errors.push('Required Hostname')
		}

		if (!device.hostname) {
			errors.push('Required Port number')
		}

		if (errors.length > 0) {
			showAlert(errors.join('. '))
		}
		return errors.length === 0
	}

	getDevices() {
		return API.get(`/dicomsend/devices`).then(r => r.data)
	}

	saveDevice(device) {
		return API.post(`/dicomsend/device`, device)
	}

	deleteDevice(device) {
		return API.delete(`/dicomsend/device/${device.id}`)
	}

	sendImages(device, studyId, series, images) {
		return API.post(`/dicomsend/send-images`, { device, studyId, series, images }).then(r => {
			this.getUserItems()
			return r.data
		})
	}

	sendEcho(device) {
		return API.post(`/dicomsend/echo`, device).then(r => {
			this.getUserItems()
			return r.data.queueItemId
		})
	}

	getSendItemInfo(queueItemId) {
		return API.get(`/dicomsend/info/${queueItemId}`).then(r => r.data)
	}

	getUserItems() {
		return API.get(`/dicomsend/user-items`).then(r => {
			this.items = []
			r.data.forEach(i => {
				this.items.push(new DicomSendItem(i))
			})

			this.enabledUpdates()
			return this.items
		})
	}

	cancelItem(queueItemId) {
		return API.get(`/dicomsend/cancel/${queueItemId}`).then(r => this.updateList())
	}
}

export const dicomSend = new DicomSend()
window.dicomSend = dicomSend
