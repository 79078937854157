/**
 * Compares two version strings `"1.7.100.1234"`
 * Only compares the first 3 number values
 * @param {String} a
 * @param {String} b
 */
export function compareVersions(a, b) {
	let aVer = a.split('.')
	if (aVer.length > 3) aVer = aVer.slice(0, 3)
	const bVer = b.split('.')

	for (let i = 0; i < aVer.length; i++) {
		try {
			if (Number(aVer[i]) !== Number(bVer[i])) {
				return Number(aVer[i]) - Number(bVer[i])
			}
		} catch (err) {
			console.error(err)
			throw new Error('Formatting Error')
		}
	}
	return 0
}
