import _baseMixin from './baseMixin'
import _startEndMixin from './startEndMixin'
import _startMiddleEndMixin from './startMiddleEndMixin'
import _quadMixin from './quadMixin'

export const baseMixin = _baseMixin
export const startEndMixin = _startEndMixin
export const startMiddleEndMixin = _startMiddleEndMixin
export const quadMixin = _quadMixin

export default {
	baseMixin,
	startEndMixin,
	startMiddleEndMixin,
	quadMixin,
}
