import { detect } from 'detect-browser'

export default function getBrowserIssues(vtkView) {
	const issues = {}
	if (vtkView) {
		const glRW = vtkView.getOpenGLRenderWindow()
		const allInfo = glRW.getGLInformations()
		const { UNMASKED_RENDERER, UNMASKED_VENDOR, WEBGL_VERSION } = allInfo

		if (WEBGL_VERSION.value < 2) {
			issues.webglVersion = WEBGL_VERSION.value
		}

		const strToTest = `${UNMASKED_VENDOR.value} / ${UNMASKED_RENDERER.value}`.toLowerCase()
		if (strToTest.indexOf('intel') !== -1) {
			issues.integratedGPU = UNMASKED_RENDERER.value
		}

		let browser = detect()
		if (browser.name === 'safari') {
			issues.browser = 'Safari'
		} else if (browser.name === 'edge') {
			// Note: New edge is detected as Chrome, so we're good there.
			issues.browser = 'Microsoft Edge'
		}
		// TODO: add any other browsers that have issues here

		if (Object.keys(issues).length) issues.hasIssues = true
	}
	return issues
}
