export default {
	key: 'asteris',
	favicon: '',
	icons: {
		download: '',
	},
	companyName: 'Asteris Inc.',
	accentColor: 'blue',
	cssVariables: {
		light: {
			// Backgrounds
			'primary-bg': '{gray-200}',
			'primary-border': '{gray-300}',
			'secondary-bg': '{white}',
			'secondary-border': '{gray-300}',
			'tertiary-bg': '{gray-300}',
			'tertiary-border': '{gray-400}',
			// Toolbar
			'primary-toolbar-logo': '{accent-light}',
			'primary-toolbar-logo-community': '{green-light}',
			'primary-toolbar-bg': '{accent-darkest}',
			'primary-toolbar-border': '{accent-darkest}',
			'primary-toolbar-color': '{white}',
			'toolbar-bg': '{white}',
			'toolbar-button-bg-hover': '{gray-300}',
			'toolbar-button-color-hover': '{gray-800}',
			'toolbar-button-bg-active': '{gray-400}',
			'toolbar-button-color-active': '{gray-800}',
			// Dividers
			divider: '{gray-400}',
			scrollbar: '{gray-400}',
			'scrollbar-hover': '{gray-500}',
			'scrollbar-bg': '{gray-300}',
			// Labels
			'primary-label': '{gray-900}',
			'secondary-label': '{gray-700}',
			'tertiary-label': '{gray-500}',
			'disabled-label': '{gray-300}',
			'primary-contrast-label': '{white}',
			// Links
			link: '{accent-dark}',
			// Navigation
			'nav-link': '{accent-darkest}',
			'nav-link-hover': '{gray-200}',
			'nav-link-active': '{green-dark}',
			// Icons
			'icon-default': '{gray-500}',
			'icon-info': '{blue}',
			'icon-success': '{green-dark}',
			'icon-warning': '{yellow}',
			'icon-danger': '{red}',
			// Cards
			'card-bg': '{white}',
			'card-border': '{gray-300}',
			'card-header-default-bg': '{gray-300}',
			'card-header-default-color': '{gray-800}',
			'card-header-info-bg': '{blue-darkest}',
			'card-header-info-color': '{gray-100}',
			// Tables
			'table-color': '{gray-800}',
			'table-color-hover': '{gray-700}',
			'table-row-border': '{gray-300}',
			'table-row-odd-bg': '{gray-100}',
			'table-row-hover-bg': '{gray-300}',
			'table-row-selected-bg': '{gray-300}',
			'table-row-selected-border': '{gray-300}',
			'table-row-selected-color': '{gray-900}',
			'table-header-shadow': '{gray-400}',
			// Badges
			'primary-badge-bg': '{accent-darker}',
			'primary-badge-color': '{white}',
			// Range Indicators
			'range-indicator-bg': '{gray-300}',
			'range-indicator-value-bg': '{accent-dark}',
			'range-indicator-value-border': '{accent-darker}',
			// Menus
			'menu-bg': '{white}',
			'menu-color': '{gray-800}',
			'menu-item-hover-bg': '{gray-300}',
			'menu-item-active-bg': '{gray-400}',
			// Viewer Menu
			'viewer-menu-bg': '{white}',
			'viewer-menu-border': '{gray-400}',
			'viewer-menu-item-hover-bg': '{gray-300}',
			// Navigation Drawers
			'modal-drawer-bg': '{white}',
			// Inputs
			'input-bg': '{white}',
			'input-border': '{gray-400}',
			'input-error-border': '{red}',
			'input-border-focus': '{accent}',
			// Vue Select
			'v-select-selected-bg': '{gray-200}',
			'v-select-selected-border': '{gray-400}',
			'v-select-selected-color': '{gray-900}',
			'v-select-selected-deselect': '{gray-800}',
			// Datepicker
			'date-picker-color': '{gray-900}',
			'date-picker-bg': '{white}',
			'date-picker-border': '{gray-400}',
			'date-picker-button-color': '{gray-800}',
			'date-picker-button-bg-hover': '{gray-300}',
			'date-picker-day-bg-hover': '{gray-300}',
			'date-picker-day-bg-selected': '{accent-darker}',
			'date-picker-day-color-hover': '{gray-900}',
			'date-picker-day-color-selected': '{white}',
			/* =========================== */
			/*           Buttons           */
			/* =========================== */
			// Primary Button
			'button-primary-color': '{white}',
			'button-primary-bg': '{accent-dark}',
			'button-primary-color-hover': '{gray-200}',
			'button-primary-bg-hover': '{accent}',
			// Default Button
			'button-default-color': '{gray-800}',
			'button-default-bg': '{gray-300}',
			'button-default-color-hover': '{gray-700}',
			'button-default-bg-hover': '{gray-400}',
			// Info Button
			'button-info-color': '{white}',
			'button-info-bg': '{accent-dark}',
			'button-info-color-hover': '{gray-200}',
			'button-info-bg-hover': '{accent}',
			// Success Button
			'button-success-color': '{white}',
			'button-success-bg': '{green-dark}',
			'button-success-color-hover': '{gray-100}',
			'button-success-bg-hover': '{green}',
			// Warning Button
			'button-warning-color': '{yellow-darkest}',
			'button-warning-bg': '{yellow-lighter}',
			'button-warning-color-hover': '{yellow-darkest}',
			'button-warning-bg-hover': '{yellow-light}',
			// Danger Button
			'button-danger-color': '{white}',
			'button-danger-bg': '{red-dark}',
			'button-danger-color-hover': '{gray-100}',
			'button-danger-bg-hover': '{red}',
			// All Line Buttons
			'button-line-bg': '{gray-300}',
			'button-line-color': '{gray-800}',
			'button-line-color-hover': '{gray-100}',
			// Primary Line Button
			'button-primary-line-border': '{accent-dark}',
			// Default Line Button
			'button-default-line-border': '{gray-600}',
			// Info Line Button
			'button-info-line-border': '{blue-dark}',
			// Success Line Button
			'button-success-line-border': '{green-dark}',
			// Warning Line Button
			'button-warning-line-border': '{yellow}',
			// Danger Line Button
			'button-danger-line-border': '{red-dark}',
			// Checkboxes
			'checkbox-unchecked-bg': '{white}',
			'checkbox-unchecked-border': '{gray-400}',
			'checkbox-checked-bg': '{accent-dark}',
			'checkbox-checked-border': '{accent-dark}',
			// Hotkeys
			'hotkey-indicator-bg': '{gray-300}',
			'hotkey-indicator-border': '{gray-400}',
			'hotkey-indicator-color': '{gray-900}',
			// Notifications
			'notification-info-bg': '{blue-light}',
			'notification-info-icon': '{blue-lightest}',
			'notification-success-bg': '{green-light}',
			'notification-success-icon': '{green-lightest}',
			'notification-warning-bg': '{yellow-light}',
			'notification-warning-icon': '{yellow-lightest}',
			'notification-danger-bg': '{red-light}',
			'notification-danger-icon': '{red-lightest}',
			'notification-question-bg': '{teal-light}',
			'notification-question-icon': '{teal-lightest}',
			// Banner
			'banner-info-bg': '{blue-lightest}',
			'banner-info-color': '{blue-darkest}',
			'banner-info-border': '{blue-lighter}',
			// Report Editor
			'editor-border-inactive': '{gray-500}',
			'editor-border-hover': '{yellow}',
			'editor-border-active': '{blue}',
			'editor-border-fixed': '{red}',
			'editor-panel-border': 'rgba(0,0,0,.25)',
			'editor-corner-bg-hover': '{yellow-lighter}',
			'editor-corner-color-hover': '{gray-900}',
			'editor-corner-bg-active': '{blue-dark}',
			'editor-corner-color-active': '{white}',
			'editor-corner-bg-fixed': '{red-dark}',
			'editor-corner-color-fixed': '{white}',
			'editor-binding-method': '{teal-dark}',
			'editor-binding-value': '{blue}',
			'editor-binding-constant': '{yellow-dark}',
			// Reports
			'report-bg-primary': '{gray-200}',
			'report-bg-secondary': '{white}',
			'report-bg-tertiary': '{gray-300}',
			'report-bg-info': '{blue-lighter}',
			'report-bg-danger': '{red-lighter}',
			'report-bg-warning': '{yellow-lighter}',
			'report-bg-success': '{green-lighter}',
			'report-text-primary': '{gray-900}',
			'report-text-secondary': '{gray-700}',
			'report-text-tertiary': '{gray-500}',
			'report-text-info': '{blue-dark}',
			'report-text-danger': '{red-dark}',
			'report-text-warning': '{yellow-dark}',
			'report-text-success': '{green-dark}',
			'report-draft-bg': '{yellow-lighter}',
			'report-legacy-bg': '{gray-200}',
			'report-published-bg': '{green-lighter}',
			'report-retired-bg': '{accent-lighter}',
			'report-draft-text': '{yellow-darkest}',
			'report-legacy-text': '{gray-600}',
			'report-published-text': '{green-darkest}',
			'report-retired-text': '{accent-darkest}',
			// Update banner
			'banner-update-bg': '{gray-700}',
			'banner-update-color': '{white}',
			'banner-update-border': '{gray-600}',
			'banner-update-btn-color': '{blue-lightest}',
			// Toggle
			'toggle-bg': 'rgba(0,0,0,0.25)',
			'toggle-bg-active': '{green}',
			'toggle-handle': '{white}',
			// Schedule
			'schedule-started': '{yellow-lightest}',
			'schedule-started-icon': '{yellow}',
			'schedule-imaging-complete': '{green-lighter}',
			'schedule-imaging-complete-icon': '{green}',
			'schedule-report-pending-approval': '{blue-lightest}',
			'schedule-report-pending-approval-icon': '{blue}',
			'schedule-report-complete': '{purple-lightest}',
			'schedule-report-complete-icon': '{purple}',
			'schedule-billed': '{yellow-lighter}',
			'schedule-current-time': '{yellow}',
			// Tabs
			'tab-item-bg': '{gray-300}',
			'tab-item-hover-bg': '{gray-400}',
			'tab-item-color': '{gray-600}',
			'tab-item-active-bg': '{white}',
			'tab-item-active-color': '{gray-800}',
			'tab-item-low-contrast-active-bg': '{white}',
			'tab-item-low-contrast-active-color': '{gray-900}',
			'tab-item-drop-bg': '{gray-400}',
			'tab-item-drop-color': '{gray-900}',
			'tab-item-accent-bg': '{gray-300}',
			'tab-item-accent-hover-bg': '{gray-400}',
			'tab-item-accent-color': '{gray-800}',
			'tab-item-accent-active-bg': '{green-dark}',
			'tab-item-accent-active-color': 'white',
		},
		dark: {
			// Backgrounds
			'primary-bg': '{gray-900}',
			'primary-border': '{gray-700}',
			'secondary-bg': '{gray-800}',
			'secondary-border': '{gray-700}',
			'tertiary-bg': '{gray-700}',
			'tertiary-border': '{gray-500}',
			// Toolbar
			'primary-toolbar-logo': '{accent-light}',
			'primary-toolbar-logo-community': '{green-light}',
			'primary-toolbar-bg': '{accent-darkest}',
			'primary-toolbar-border': '{gray-600}',
			'primary-toolbar-color': '{white}',
			'toolbar-bg': '{gray-900}',
			'toolbar-button-bg-hover': '{gray-700}',
			'toolbar-button-color-hover': '{gray-100}',
			'toolbar-button-bg-active': '{gray-700}',
			'toolbar-button-color-active': '{gray-100}',
			// Dividers
			divider: '{gray-600}',
			scrollbar: '{gray-600}',
			'scrollbar-hover': '{gray-500}',
			'scrollbar-bg': '{gray-700}',
			// Labels
			'primary-label': '{gray-100}',
			'secondary-label': '{gray-300}',
			'tertiary-label': '{gray-500}',
			'disabled-label': '{gray-700}',
			'primary-contrast-label': '{white}',
			// Links
			link: '{accent-lighter}',
			// Navigation
			'nav-link': '{gray-400}',
			'nav-link-hover': '{gray-700}',
			'nav-link-active': '{green-light}',
			// Icons
			'icon-default': '{gray-500}',
			'icon-info': '{blue-light}',
			'icon-success': '{green-light}',
			'icon-warning': '{yellow-light}',
			'icon-danger': '{red-lighter}',
			// Cards
			'card-bg': '{gray-800}',
			'card-border': '{gray-700}',
			'card-header-default-bg': '{gray-700}',
			'card-header-default-color': '{gray-200}',
			'card-header-info-bg': '{blue-darkest}',
			'card-header-info-color': '{gray-100}',
			// Tables
			'table-color': '{gray-200}',
			'table-color-hover': '{gray-200}',
			'table-row-border': '{gray-700}',
			'table-row-odd-bg': '{gray-700}',
			'table-row-hover-bg': '{gray-900}',
			'table-row-selected-bg': '{gray-900}',
			'table-row-selected-border': '{gray-800}',
			'table-row-selected-color': '{gray-100}',
			'table-header-shadow': '{gray-600}',
			// Badges
			'primary-badge-bg': '{accent-lighter}',
			'primary-badge-color': '{accent-darkest}',
			// Range Indicators
			'range-indicator-bg': '{gray-700}',
			'range-indicator-value-bg': '{accent-lighter}',
			'range-indicator-value-border': '{accent}',
			// Menus
			'menu-bg': '{gray-800}',
			'menu-color': '{gray-100}',
			'menu-item-hover-bg': '{gray-700}',
			'menu-item-active-bg': '{gray-900}',
			// Viewer Menu
			'viewer-menu-bg': '{gray-700}',
			'viewer-menu-border': '{gray-600}',
			'viewer-menu-item-hover-bg': '{gray-600}',
			// Navigation Drawers
			'modal-drawer-bg': '{gray-900}',
			// Inputs
			'input-bg': '{gray-700}',
			'input-border': '{gray-600}',
			'input-error-border': '{red-light}',
			'input-border-focus': '{accent-lighter}',
			// Vue Select
			'v-select-selected-bg': '{accent-light}',
			'v-select-selected-border': '{accent-light}',
			'v-select-selected-deselect': '{gray-100}',
			'v-select-selected-color': '{white}',
			// Datepicker
			'date-picker-color': '{white}',
			'date-picker-bg': '{gray-900}',
			'date-picker-border': '{gray-700}',
			'date-picker-button-color': '{gray-200}',
			'date-picker-button-bg-hover': '{gray-800}',
			'date-picker-day-bg-hover': '{gray-700}',
			'date-picker-day-color-hover': '{white}',
			'date-picker-day-bg-selected': '{accent}',
			'date-picker-day-color-selected': '{white}',
			/* =========================== */
			/*           Buttons           */
			/* =========================== */
			// Primary Button
			'button-primary-color': '{white}',
			'button-primary-bg': '{accent}',
			'button-primary-color-hover': '{gray-100}',
			'button-primary-bg-hover': '{accent-dark}',
			// Default Button
			'button-default-color': '{white}',
			'button-default-bg': '{gray-700}',
			'button-default-color-hover': '{white}',
			'button-default-bg-hover': '{gray-600}',
			// Info Button
			'button-info-color': '{accent-darkest}',
			'button-info-bg': '{accent-light}',
			'button-info-color-hover': '{gray-100}',
			'button-info-bg-hover': '{accent}',
			// Success Button
			'button-success-color': '{green-darkest}',
			'button-success-bg': '{green}',
			'button-success-color-hover': '{gray-900}',
			'button-success-bg-hover': '{green-light}',
			// Warning Button
			'button-warning-color': '{yellow-lightest}',
			'button-warning-bg': '{yellow-darker}',
			'button-warning-color-hover': '{white}',
			'button-warning-bg-hover': '{yellow-dark}',
			// Danger Button
			'button-danger-color': '{gray-100}',
			'button-danger-bg': '{red}',
			'button-danger-color-hover': '{white}',
			'button-danger-bg-hover': '{red-light}',
			// All Line Buttons
			'button-line-bg': '{gray-700}',
			'button-line-color': '{white}',
			'button-line-color-hover': '{gray-100}',
			// Primary Line Button
			'button-primary-line-border': '{accent}',
			// Default Line Button
			'button-default-line-border': '{gray-600}',
			// Info Line Button
			'button-info-line-border': '{blue-dark}',
			// Success Line Button
			'button-success-line-border': '{green-dark}',
			// Warning Line Button
			'button-warning-line-border': '{yellow}',
			// Danger Line Button
			'button-danger-line-border': '{red}',
			// Checkboxes
			'checkbox-unchecked-bg': '{gray-700}',
			'checkbox-unchecked-border': '{gray-600}',
			'checkbox-checked-bg': '{accent-light}',
			'checkbox-checked-border': '{accent-light}',
			// Hotkeys
			'hotkey-indicator-bg': '{gray-700}',
			'hotkey-indicator-border': '{gray-600}',
			'hotkey-indicator-color': '{gray-100}',
			// Notifications
			'notification-info-bg': '{blue-light}',
			'notification-info-icon': '{blue-lightest}',
			'notification-success-bg': '{green-light}',
			'notification-success-icon': '{green-lightest}',
			'notification-warning-bg': '{yellow-light}',
			'notification-warning-icon': '{yellow-lightest}',
			'notification-danger-bg': '{red-light}',
			'notification-danger-icon': '{red-lightest}',
			'notification-question-bg': '{teal-light}',
			'notification-question-icon': '{teal-lightest}',
			// Banner
			'banner-info-bg': '{blue-dark}',
			'banner-info-color': '{gray-100}',
			'banner-info-border': '{blue}',
			// Report Editor
			'editor-border-inactive': '{gray-600}',
			'editor-border-hover': '{yellow-dark}',
			'editor-border-active': '{blue-light}',
			'editor-border-fixed': '{red}',
			'editor-panel-border': 'rgba(255,255,255,.25)',
			'editor-corner-bg-hover': '{yellow-light}',
			'editor-corner-color-hover': '{gray-900}',
			'editor-corner-bg-active': '{blue}',
			'editor-corner-color-active': '{white}',
			'editor-corner-bg-fixed': '{red-dark}',
			'editor-corner-color-fixed': '{white}',
			'editor-binding-method': '{teal-light}',
			'editor-binding-value': '{blue-lighter}',
			'editor-binding-constant': '{yellow-light}',
			// Reports
			'report-bg-primary': '{gray-900}',
			'report-bg-secondary': '{gray-800}',
			'report-bg-tertiary': '{gray-700}',
			'report-bg-info': '{blue-darkest}',
			'report-bg-danger': '{red-darkest}',
			'report-bg-warning': '{yellow-darkest}',
			'report-bg-success': '{green-darkest}',
			'report-text-primary': '{gray-100}',
			'report-text-secondary': '{gray-300}',
			'report-text-tertiary': '{gray-500}',
			'report-text-info': '{blue-light}',
			'report-text-danger': '{red-light}',
			'report-text-warning': '{yellow-light}',
			'report-text-success': '{green-light}',
			'report-draft-bg': '{yellow-darker}',
			'report-legacy-bg': '{gray-700}',
			'report-published-bg': '{green-darker}',
			'report-retired-bg': '{accent-darker}',
			'report-draft-text': '{yellow-lightest}',
			'report-legacy-text': '{gray-400}',
			'report-published-text': '{green-lightest}',
			'report-retired-text': '{accent-lightest}',
			// Update banner
			'banner-update-bg': '{gray-400}',
			'banner-update-color': '{gray-900}',
			'banner-update-border': '{gray-500}',
			'banner-update-btn-color': '{blue-darker}',
			// Toggle
			'toggle-bg': 'rgba(255,255,255,0.25)',
			'toggle-bg-active': '{green-light}',
			'toggle-handle': '{gray-100}',
			// Schedule
			'schedule-started': '{yellow-darker}',
			'schedule-started-icon': '{yellow-light}',
			'schedule-imaging-complete': '{green-darker}',
			'schedule-imaging-complete-icon': '{green-light}',
			'schedule-report-pending-approval': '{blue-darker}',
			'schedule-report-pending-approval-icon': '{blue-light}',
			'schedule-report-complete': '{purple-darker}',
			'schedule-report-complete-icon': '{purple-light}',
			'schedule-billed': '{yellow-dark}',
			'schedule-current-time': '{yellow-light}',
			// Tabs
			'tab-item-bg': '{gray-800}',
			'tab-item-hover-bg': '{gray-700}',
			'tab-item-color': '{gray-400}',
			'tab-item-active-bg': '{gray-700}',
			'tab-item-active-color': 'white',
			'tab-item-low-contrast-active-bg': '{gray-800}',
			'tab-item-low-contrast-active-color': '{white}',
			'tab-item-drop-bg': '{gray-500}',
			'tab-item-drop-color': '{gray-900}',
			'tab-item-accent-bg': '{gray-700}',
			'tab-item-accent-hover-bg': '{gray-600}',
			'tab-item-accent-color': '{gray-300}',
			'tab-item-accent-active-bg': '{green}',
			'tab-item-accent-active-color': '{green-darkest}',
		},
	},
}
