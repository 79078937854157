import valueTypes from './dicomTagValueTypeEnum.js'

// https://github.com/OHIF/Viewers/blob/e55e2ad05ad784ce3899378d438fbc198e6cf2da/Packages/ohif-viewerbase/client/lib/DICOMTagDescriptions.js
export default {
	// PATIENT
	PATIENT_NAME: { tag: '00100010', type: valueTypes.STRING },
	PATIENT_ID: { tag: '00100020', type: valueTypes.STRING },
	PATIENT_BIRTH_DATE: { tag: '00100030', type: valueTypes.STRING },
	PATIENT_SEX: { tag: '00100040', type: valueTypes.STRING },
	PATIENT_AGE: { tag: '00101010', type: valueTypes.NUMBER },
	PATIENT_SIZE: { tag: '00101020', type: valueTypes.NUMBER },
	PATIENT_WEIGHT: { tag: '00101030', type: valueTypes.NUMBER },
	// SERIES
	MODALITY: { tag: '00080060', type: valueTypes.STRING },
	SERIES_INSTANCE_UID: { tag: '0020000e', type: valueTypes.STRING },
	SERIES_NUMBER: { tag: '00200011', type: valueTypes.NUMBER },
	SERIES_DESCRIPTION: { tag: '0008103e', type: valueTypes.STRING },
	// STUDY
	STUDY_INSTANCE_UID: { tag: '0020000d', type: valueTypes.STRING },
	// IMAGE PIXEL MODULE
	SAMPLES_PER_PIXEL: { tag: '00280002', type: valueTypes.NUMBER },
	PHOTOMETRIC_INTERPRETATION: { tag: '00280004', type: valueTypes.STRING },
	ROWS: { tag: '00280010', type: valueTypes.NUMBER },
	COLUMNS: { tag: '00280011', type: valueTypes.NUMBER },
	BITS_ALLOCATED: { tag: '00280100', type: valueTypes.NUMBER },
	BITS_STORED: { tag: '00280101', type: valueTypes.NUMBER },
	HIGH_BIT: { tag: '00280102', type: valueTypes.STRING },
	PIXEL_REPRESENTATION: { tag: '00280103', type: valueTypes.NUMBER },
	PLANAR_CONFIGURATION: { tag: '00280006', type: valueTypes.NUMBER },
	PIXEL_ASPECT_RATIO: { tag: '00280034', type: valueTypes.STRING },
	SMALLEST_PIXEL_VALUE: { tag: '00280106', type: valueTypes.NUMBER },
	LARGEST_PIXEL_VALUE: { tag: '00280107', type: valueTypes.NUMBER },
	RED_PALETTE_COLOR_LOOKUP_TABLE_DESCRIPTOR: {
		tag: '00281101',
		type: valueTypes.NUMBER,
	},
	GREEN_PALETTE_COLOR_LOOKUP_TABLE_DESCRIPTOR: {
		tag: '00281102',
		type: valueTypes.NUMBER,
	},
	BLUE_PALETTE_COLOR_LOOKUP_TABLE_DESCRIPTOR: {
		tag: '00281103',
		type: valueTypes.NUMBER,
	},
	RED_PALETTE_COLOR_LOOKUP_TABLE_DATA: {
		tag: '00281201',
		type: valueTypes.NUMBER,
	},
	GREEN_PALETTE_COLOR_LOOKUP_TABLE_DATA: {
		tag: '00281202',
		type: valueTypes.NUMBER,
	},
	BLUE_PALETTE_COLOR_LOOKUP_TABLE_DATA: {
		tag: '00281203',
		type: valueTypes.NUMBER,
	},
	// IMAGE PLANE MODULE
	IMAGE_ORIENTATION_PATIENT: { tag: '00200037', type: valueTypes.STRING },
	IMAGE_POSITION_PATIENT: { tag: '00200032', type: valueTypes.STRING },
	FRAME_OF_REFERENCE_UID: { tag: '00200052', type: valueTypes.STRING },
	SLICE_THICKNESS: { tag: '00180050', type: valueTypes.NUMBER },
	SLICE_LOCATION: { tag: '00201041', type: valueTypes.STRING },
	// ALL THE PIXEL SPACINGS
	AST_CALIBRATED_PIXEL_SPACING: {
		tag: '51802000',
		type: valueTypes.NUMBERS,
		options: {
			minimumLength: 2,
		},
	},
	PIXEL_SPACING: {
		tag: '00280030',
		type: valueTypes.NUMBERS,
		options: {
			minimumLength: 2,
		},
	},
	IMAGER_PIXEL_SPACING: {
		tag: '00181164',
		type: valueTypes.NUMBERS,
		options: {
			minimumLength: 2,
		},
	},
	NOMINAL_SCANNED_PIXEL_SPACING: {
		tag: '00182010',
		type: valueTypes.NUMBERS,
		options: {
			minimumLength: 2,
		},
	},
	// PET ISOTOPE MODULE
	RADIOPHARMACEUTICAL_INFORMATION_SEQ: {
		tag: '00540016',
		type: valueTypes.STRING,
	},
	RADIONUCLIDE_TOTAL_DOSE: { tag: '00181074', type: valueTypes.NUMBER },
	RADIONUCLIDE_HALF_LIFE: { tag: '00181075', type: valueTypes.NUMBER },
	// MODALITY LUT MODULE
	RESCALE_INTERCEPT: { tag: '00281052', type: valueTypes.NUMBER },
	RESCALE_SLOPE: { tag: '00281053', type: valueTypes.NUMBER },
	RESCALE_TYPE: { tag: '00281054', type: valueTypes.STRING },
	// GENERAL IMAGE MODULE
	IMAGE_TYPE: { tag: '00080008', type: valueTypes.STRING },
	// SOP COMMON MODULE
	SOP_CLASS_UID: { tag: '00080016', type: valueTypes.STRING },
	SOP_INSTANCE_UID: { tag: '00080018', type: valueTypes.STRING },
	// VOI LUT MODULE
	WINDOW_CENTER: {
		tag: '00281050',
		type: valueTypes.NUMBERS,
		options: { minimumLength: 1 },
	},
	WINDOW_WIDTH: {
		tag: '00281051',
		type: valueTypes.NUMBERS,
		options: { minimumLength: 1 },
	},
	// MULTI FRAME MODULE
	NUMBER_OF_FRAMES: { tag: '00280008', type: valueTypes.NUMBER },
	FRAME_INCREMENT_POINTER: { tag: '00280009', type: valueTypes.STRING },
	FRAME_TIME: { tag: '00181063', type: valueTypes.NUMBER },
	FRAME_TIME_VECTOR: {
		tag: '00181065',
		type: valueTypes.NUMBERS,
		options: { minimumLength: 1 },
	},
	//
	//   IMAGE_ORIENTATION_PATIENT: {
	//     tag: '',
	//     type: valueTypes,
	//   },
}
