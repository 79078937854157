import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { getCornerstoneImageIdParts } from '@/imageLoader'
import isSameOrientation from '@/cornerstone/_shared/isSameOrientation.js'
import isLocalizerImage from '@/cornerstone/_shared/isLocalizerImage.js'
import store from '@/store'

const scrollToIndex = cornerstoneTools.import('util/scrollToIndex')

export default function(synchronizer, sourceElement, targetElement, eventData = {}) {
	if (targetElement === sourceElement && !eventData.virtual) return

	const {
		isLocalizerImage: isSourceLocalizerImage,
		imageId: sourceImageId,
		...sourceImagePlane
	} = store.state.viewer.activeImageMetadata

	// do not trigger scroll sync from a localizer image [ch4638]
	if (isSourceLocalizerImage) {
		return
	}

	if (!sourceImagePlane || !sourceImagePlane.imagePositionPatient) return
	const stackToolState = cornerstoneTools.getToolState(targetElement, 'stack')
	if (!stackToolState) return
	const stackData = cornerstoneTools.getToolState(targetElement, 'stack').data[0]
	let shortestDistance = Number.MAX_VALUE
	let newImageIdIndex

	for (let i = 0; i < stackData.imageIds.length; i++) {
		const cornerstoneImageId = stackData.imageIds[i]
		const { imageId } = getCornerstoneImageIdParts(cornerstoneImageId)
		if (imageId === sourceImageId) {
			newImageIdIndex = i
			break
		}
		const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId)
		if (!imagePlane || !imagePlane.imagePositionPatient) continue
		if (!isSameOrientation(imagePlane, sourceImagePlane)) continue
		// do not scroll to a localizer image [ch4638]
		if (isLocalizerImage(stackData.imageIds, cornerstoneImageId, imagePlane)) continue
		const distance = getDistance(imagePlane.imagePositionPatient, sourceImagePlane.imagePositionPatient)
		if (distance < shortestDistance) {
			shortestDistance = distance
			newImageIdIndex = i
		}
	}
	if (newImageIdIndex === undefined || newImageIdIndex === stackData.currentImageIdIndex) return

	scrollToIndex(targetElement, newImageIdIndex)
}

function getDistance(a, b) {
	const dx = a.x - b.x
	const dy = a.y - b.y
	const dz = a.z - b.z
	return dx * dx + dy * dy + dz * dz
}
