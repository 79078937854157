import * as Sentry from '@sentry/browser'
import createImageFromEnabledElementAsync from './_shared/createImageFromEnabledElementAsync.js'
import copyImageToClipboard from '@utils/copyImageToClipboard'

export default async function({ state, dispatch, rootGetters }, canvasIndex) {
	// Provided canvasIndex or default to activeCanvas
	const targetCanvas = canvasIndex === undefined ? rootGetters.activeCanvas : state.canvases[canvasIndex]

	if (!targetCanvas) {
		return
	}
	if (!targetCanvas.seriesId && !targetCanvas.imageId) return

	try {
		// Create base64 image
		const imgBase64 = await createImageFromEnabledElementAsync(targetCanvas.dom)

		// Copy to the clipboard
		await copyImageToClipboard(imgBase64, dispatch)
	} catch (err) {
		const sentryEventId = Sentry.captureException(err)

		dispatch('addNotification', {
			message: 'Unable to create a copyable image.',
			notificationType: 'warn',
			sentryEventId,
		})
	}
}
