import { showAlert } from '@dialogs/MessageDlg.vue'
import { API, serializeDate } from '@services/api'
import store from '@store'
import { hasImageExtension, hasNonImageExtension, hasValidAttachmentExtension } from '../upload/extensions.js'
import parseDicom from '../upload/parseDicoms'

import * as Sentry from '@sentry/browser'

// @ts-ignore
import UploadWorker from 'worker-loader!../upload/upload.worker.js' // eslint-disable-line

class UploadBatch {
	batchId?: string
	context: string
	studyInstanceUid?: string
	study: IUploadStudy
	items: Upload[] = []
}

class Upload {
	context: string = null
	file: File = null
	singleFileBatch = false
	isFailedUpload = false
	isUploaded = false
	isUploading = false

	isInvalid = false
	isAttachment = false
	isDicom = false
	isImage = false
	excludeJpegs = false

	attempts = 0
	batchId?: string = null
	sopInstanceUid?: string = null
	studyInstanceUid?: string = null

	description: string = null
	imageViewId: string = null

	// view/rename form data
	parseWarning: string = null
	modality: string = null
	patientId: string = null
	patientName: string = null
	ownerName: string = null
	studyDescription: string = null
	accessionNumber: string = null
	institutionName: string = null
	referringPhysiciansName: string = null
	studyDateTime: Date = null
	acquisitionDate: Date = null
	reportTemplateImageViewId: string = null

	_dicomParsePromise: Promise<any>

	get pendingBatch(): boolean {
		return !this.batchId && !this.isInvalid
	}

	get pendingUpload(): boolean {
		return this.batchId && !this.isUploading && !this.isUploaded && !this.isFailedUpload
	}

	get key(): string {
		if (this.singleFileBatch) {
			return Math.random().toString()
		} else {
			return `${this.context}_${this.studyInstanceUid || 'DEFAULT'}`
		}
	}

	parse(treatImageAsAttachment = false) {
		if (this._dicomParsePromise) {
			return this._dicomParsePromise
		}

		if (hasImageExtension(this.file)) {
			if (treatImageAsAttachment) {
				this.isAttachment = true
			} else {
				this.isImage = true
			}
			this._dicomParsePromise = Promise.resolve()
		} else if (hasNonImageExtension(this.file)) {
			this.isAttachment = true
			this._dicomParsePromise = Promise.resolve()
		} else {
			this._dicomParsePromise = parseDicom(this.file).then(info => {
				if (info.isDicom) {
					this.isDicom = true
					this.parseWarning = info.parseWarning
					this.modality = info.modality
					this.patientId = info.patientId
					this.patientName = info.patientName
					this.ownerName = info.ownerName
					this.studyDescription = info.studyDescription
					this.accessionNumber = info.accessionNumber
					this.institutionName = info.institutionName
					this.referringPhysiciansName = info.referringPhysiciansName
					this.sopInstanceUid = info.sopInstanceUid
					this.studyDateTime = info.studyDateTime
					this.acquisitionDate = info.acquisitionDate
					this.studyInstanceUid = info.studyInstanceUid
				} else {
					this.isAttachment = true
				}
			})
		}
		return this._dicomParsePromise
	}
}

interface IQueueRequest {
	context: string
	files: File[]

	studyInstanceUid?: string
	description?: string
	imageViewId?: string
	reportTemplateImageViewId?: string

	allowImages?: boolean
	allowAttachments?: boolean
	allowDicoms?: boolean
}

interface IStopUploadOptions {
	context?: string
	sopInstanceUid?: string
	batchId?: string
}

interface IUploadState {
	sopInstanceUid?: string
	isUploading?: boolean
	isUploaded?: boolean
	isFailedUpload?: boolean
}

const maxImagesPerStudy = 100
const maxWorkers = navigator.hardwareConcurrency || 4
const workerPool: UploadWorker[] = new Array(maxWorkers)
let uploadQueue: Upload[] = []

class UploadData {
	MAX_ATTACHMENT_SPACE = 1024 * 1024 * 512 // study attachments cannot exceed 512MB total
	MAX_FREE_ATTACHMENT_SPACE = 1024 * 1024 * 10 // a fee is charged once study attachments exceed 10MB

	excludedFiles: Upload[] = []
	uploads: Upload[] = []

	getPendingUploads(context?: string): Upload[] {
		return this.uploads.filter(u => u.pendingBatch && (!context || u.context === context))
	}

	haveUploadsForContext(context: string) {
		return this.uploads.some(upload => upload.context === context)
	}

	isUploading(context?: string) {
		const uploads = this.uploads.filter(upload => !context || upload.context === context)
		if (uploads.some(upload => upload.isUploading)) return true
		const isPending = upload => !upload.isFailedUpload && !upload.isUploading && !upload.isUploaded
		return uploads.some(isPending)
	}

	isUploadError(context: string) {
		const uploads = this.uploads.filter(upload => !context || upload.context === context)
		return uploads.some(upload => upload.isFailedUpload)
	}

	uploadPercent(context: string) {
		const uploads = this.uploads.filter(upload => !context || upload.context === context)
		const done = uploads.filter(upload => upload.isUploaded).length
		const didNotFail = upload => !upload.isFailedUpload
		const total = uploads.filter(didNotFail).length
		return Math.floor((done / total) * 100)
	}

	CANCEL_UPLOAD(sopInstanceUid: string, file?: File) {
		if (sopInstanceUid) {
			this.uploads = this.uploads.filter(upload => upload.sopInstanceUid !== sopInstanceUid)
		}
		if (file) {
			this.uploads = this.uploads.filter(upload => upload.file !== file)
		}
	}

	CANCEL_PENDING_UPLOADS(context?: string) {
		const isNotPending = upload => {
			const isDifferentContext = context && upload.context !== context
			return isDifferentContext || upload.sopInstanceUid
		}
		this.uploads = this.uploads.filter(isNotPending)
	}

	CLEAR_UPLOAD_EXCLUDED_FILES(context?: string) {
		if (!context) this.excludedFiles = []
		else this.excludedFiles = this.excludedFiles.filter(file => file.context !== context)
	}

	CHANGE_UPLOAD_CONTEXT({ from, to }) {
		const uploads = this.uploads.slice()
		const uploadsToChange = uploads.filter(upload => upload.context === from)
		uploadsToChange.forEach(upload => (upload.context = to))
		this.uploads = uploads
	}

	UPDATE_UPLOAD(state: IUploadState) {
		const upload = this.uploads.find(upload => upload.sopInstanceUid === state.sopInstanceUid)
		if (!upload) return

		if (state.isUploading) upload.isUploading = true
		if (state.isUploaded || state.isFailedUpload) upload.isUploading = false
		if (state.isUploaded) upload.isUploaded = true
		if (state.isFailedUpload) {
			let attempts = (upload.attempts ? upload.attempts : 0) + 1
			upload.attempts = attempts
			if (attempts < 3) {
				upload.isFailedUpload = false
				upload.isUploading = false
			} else {
				upload.isFailedUpload = true
			}
		}
	}

	async queueFilesForUpload(params: IQueueRequest) {
		let items: Upload[] = []
		params.files.forEach(f => {
			if (f.name.toUpperCase() === 'DICOMDIR') return
			let upload = new Upload()
			upload.file = f
			upload.context = params.context
			upload.singleFileBatch = params.allowAttachments && !params.allowImages && !params.allowDicoms
			upload.studyInstanceUid = params.studyInstanceUid
			upload.description = params.description
			upload.imageViewId = params.imageViewId
			upload.reportTemplateImageViewId = params.reportTemplateImageViewId
			items.push(upload)
		})

		// if jpeg is dropped onto attachment uploader, assume it's an attachment
		let treatImageAsAttachment = params.allowAttachments && !params.allowImages

		await Promise.all(
			items.map(u =>
				u.parse(treatImageAsAttachment).then(() => {
					u.isInvalid =
						!!u.parseWarning ||
						!hasValidAttachmentExtension(u.file) ||
						(!params.allowDicoms && u.isDicom) ||
						(!params.allowAttachments && u.isAttachment) ||
						(!params.allowImages && u.isImage)
				})
			)
		)

		items.forEach(u => {
			if (u.sopInstanceUid) {
				let isDupe = this.uploads.find(i => i.sopInstanceUid === u.sopInstanceUid)
				if (isDupe) return
			}

			if (u.isInvalid) {
				this.excludedFiles.push(u)
			} else {
				this.uploads.push(u)
			}
		})
	}

	async startUpload(context: string, study: IUploadStudy = null, applyLastStudyUidToAttachments: boolean = false) {
		let batches: UploadBatch[] = []

		let pendingUploads = this.uploads.filter(u => u.pendingBatch && u.context === context)

		let images = pendingUploads.filter(u => u.isImage).length
		if (!checkImageLimit(images)) {
			return
		}

		let batchMap: { [key: string]: UploadBatch } = {}

		for (let i = 0; i < pendingUploads.length; i++) {
			let pendingUpload = pendingUploads[i]

			if (applyLastStudyUidToAttachments && pendingUpload.isAttachment && !pendingUpload.studyInstanceUid) {
				pendingUpload.studyInstanceUid = this._getMostRecentStudyUid()
			}
			if (study) {
				pendingUpload.modality = study.modalityId
				pendingUpload.patientId = study.patientId
				pendingUpload.patientName = study.patientName
				pendingUpload.ownerName = study.ownerName
				pendingUpload.studyDescription = study.studyDescription
				pendingUpload.accessionNumber = study.accessionNumber
				pendingUpload.institutionName = study.institutionName
				pendingUpload.referringPhysiciansName = study.referringPhysiciansName
				pendingUpload.studyDateTime = new Date(study.studyDateTime)
				pendingUpload.studyInstanceUid = study.studyInstanceUid
			}

			const hasRequiredInfo = u => u.studyInstanceUid || (u.patientName && u.patientId && u.ownerName)
			if (!hasRequiredInfo(pendingUpload)) continue // do not batch items waiting on required info

			let batch = batchMap[pendingUpload.key]
			if (!batch) {
				batch = new UploadBatch()

				if (pendingUpload.studyInstanceUid) {
					// look for previous existing batch
					let found = this.uploads.find(
						u =>
							u.batchId &&
							u.isAttachment === pendingUpload.isAttachment &&
							u.studyInstanceUid === pendingUpload.studyInstanceUid
					)
					if (found) batch.batchId = found.batchId
				}

				if (study) {
					batch.study = JSON.parse(JSON.stringify(study))
					if (study.studyDateTime) {
						batch.study.studyDateTime = serializeDate(study.studyDateTime)
					}
				}
				batch.studyInstanceUid = pendingUpload.studyInstanceUid
				batchMap[pendingUpload.key] = batch
				batches.push(batch)
			}
			batch.items.push(pendingUpload)
		}

		await Promise.all(batches.map(b => createUploadBatch(b)))

		setUploadQueue()
		const workersNeeded = Math.min(maxWorkers, uploadQueue.length)
		if (workersNeeded) {
			window.addEventListener('beforeunload', confirmNavigation)
			runWorkers(workersNeeded)
		}
	}

	getUploadRenameForm(studyInstanceUid: string) {
		return API.get('/study/upload-rename-form', { params: { studyInstanceUid } }).then(r => r.data)
	}

	getUploadStatus(batchId: string) {
		return API.get(`/study/upload-status`, {
			params: { batchId },
		}).then(r => r.data)
	}

	clear(context?: string) {
		if (!context) {
			this.excludedFiles = []
			this.uploads = []
		} else {
			this.excludedFiles = this.excludedFiles.filter(file => file.context !== context)
			this.uploads = this.uploads.filter(upload => upload.context !== context)
		}
	}

	stopUploads(options?: IStopUploadOptions) {
		if (!options) {
			this.clear()
			stopUploadWorkers()
			return
		}
		if (options.context && !options.sopInstanceUid && !options.batchId) this.clear(options.context)
		if (options.batchId) {
			const isInBatch = upload => upload.batchId === options.batchId && upload.sopInstanceUid
			const sopInstanceUids = uploadData.uploads.filter(isInBatch).map(upload => upload.sopInstanceUid)
			sopInstanceUids.forEach(sopInstanceUid => this.stopUploads({ context: options.context, sopInstanceUid }))
			return
		}
		if (options.sopInstanceUid) uploadData.CANCEL_UPLOAD(options.sopInstanceUid)
		stopUploadWorkers(options.context, options.sopInstanceUid)
	}

	_getMostRecentStudyUid(): string {
		for (let i = this.uploads.length - 1; i >= 0; i--) {
			const studyInstanceUid = this.uploads[i].studyInstanceUid
			if (studyInstanceUid) return studyInstanceUid
		}
		return null
	}

	_getExistingBatchIdForStudy(studyInstanceUid): string | undefined {
		if (!studyInstanceUid) return undefined
		const existingUploadInStudy = this.uploads.find(
			upload => upload.studyInstanceUid === studyInstanceUid && !upload.isAttachment
		)
		if (existingUploadInStudy) return existingUploadInStudy.batchId
	}
}

async function createUploadBatch(batch: UploadBatch) {
	let form: IUploadBatchForm = {
		batchId: batch.batchId,
		studyInstanceUid: batch.studyInstanceUid,
		study: batch.study,
		files: batch.items.map(i => {
			return {
				imageViewId: i.imageViewId,
				filename: i.file.name,
				description: i.description,
				sopInstanceUid: i.sopInstanceUid,
			}
		}),
	}

	try {
		const r: IUploadBatchResult = await API.post('/study/upload-batch-start', form).then(r => r.data)
		for (let i = 0; i < batch.items.length; i++) {
			let item = batch.items[i]
			item.sopInstanceUid = r.sopInstanceUids[i]
			item.studyInstanceUid = r.studyInstanceUid
			item.batchId = r.batchId
		}
	} catch {
		batch.items.forEach(i => {
			i.isFailedUpload = true
		})
	}
}

function runWorkers(workersNeeded) {
	let workersRunning = 0
	for (let i = 0; i < workerPool.length; i++) {
		if (workersRunning === workersNeeded) break
		if (!workerPool[i]) runWorker(i, uploadQueue.length) // (re)start worker if not running
		workersRunning++
	}
}

function runWorker(i: number, queueSize?: number) {
	const upload = uploadQueue.shift()
	// send next upload to worker
	if (upload) {
		const { context, sopInstanceUid } = upload
		uploadData.UPDATE_UPLOAD({ sopInstanceUid, isUploading: true })
		if (!workerPool[i]) workerPool[i] = createWorker(i)
		workerPool[i].context = context
		workerPool[i].sopInstanceUid = sopInstanceUid
		const concurrentUploads = Math.min(uploadQueue.length, workerPool.filter(w => !!w).length)
		setTimeout(() => {
			workerPool[i].worker.postMessage(createMessageForWorker(upload))
		}, getThrottleTime(queueSize, concurrentUploads))
		// if no uploads are left, terminate worker
	} else {
		if (workerPool[i]) {
			workerPool[i].worker.terminate()
			workerPool[i] = undefined
		}
		const isAnyWorkerRunning = workerPool.some(worker => !!worker)
		if (!isAnyWorkerRunning) {
			window.removeEventListener('beforeunload', confirmNavigation)
		}
	}
}

function setUploadQueue() {
	uploadQueue = uploadData.uploads.filter(u => u.pendingUpload)
}

function getThrottleTime(queueSize: number = 0, concurrentUploads: number): number {
	let delay = 0
	if (queueSize >= 1000) delay = 1000
	else if (queueSize >= 250) delay = 500
	else if (queueSize >= 100) delay = 250
	else if (queueSize >= 50) delay = 100
	return delay * concurrentUploads
}

function createWorker(i: number) {
	const worker = new UploadWorker()
	// whenever an upload is done, update upload status and handle any errors
	worker.onmessage = ({ data }) => {
		const { status, response, ...upload } = data
		uploadData.UPDATE_UPLOAD(upload)
		setUploadQueue()
		if (data.isFailedUpload) handleUploadError(data)
		let queueSize = 0
		try {
			if (response) queueSize = JSON.parse(response).queueSize
		} catch {} // in case response is not JSON for some reason
		runWorker(i, queueSize)
	}
	return { worker }
}

function createMessageForWorker(upload: Upload) {
	let url = `${API.defaults.baseURL}/study/upload-file` // passed to upload workers
	return {
		batchId: upload.batchId,
		context: upload.context,
		doNotCompress: !upload.isDicom,
		originalFile: upload.file,
		sopInstanceUid: upload.sopInstanceUid,
		token: store.state.auth.token,
		url,
	}
}

function handleUploadError(data) {
	if (data.status === 401) {
		store.dispatch('logOut')
	} else {
		const errorDescription = 'An upload failed during import'
		// @ts-ignore
		Sentry.captureException(new Error(errorDescription), { extra: data })
	}
}

function stopUploadWorkers(context?: string, sopInstanceUid?: string) {
	for (let i = 0; i < workerPool.length; i++) {
		if (!workerPool[i]) continue
		if (context && workerPool[i].context !== context) continue
		if (sopInstanceUid && workerPool[i].sopInstanceUid !== sopInstanceUid) continue
		workerPool[i].worker.terminate()
		workerPool[i] = undefined
	}
	const isAnyWorkerRunning = workerPool.some(worker => !!worker)
	if (!isAnyWorkerRunning) {
		window.removeEventListener('beforeunload', confirmNavigation)
	}
}

function confirmNavigation(e) {
	// Custom warning will not be displayed in Chrome
	// TODO if (api.file.isDownloadingFile) return
	const warning = 'You are still uploading files.  Are you sure you want to ' + 'leave and cancel these uploads?'
	e.returnValue = warning
	return warning
}

function checkImageLimit(totalImages) {
	if (totalImages <= maxImagesPerStudy) return true
	const warning =
		`Studies created from non-DICOM image files are ` +
		`limited to ${maxImagesPerStudy} images.  This restriction prevents ` +
		`performance and usability problems.  You can either upload the ` +
		`images as separate studies, or request DICOM files from your ` +
		`image provider.`
	showAlert(warning)
	return false
}

export const uploadData = new UploadData()
// @ts-ignore
window.uploadData = uploadData
