import { imageCache } from 'cornerstone-core/dist/cornerstone'

// Chromium memory limits:
// https://source.chromium.org/chromium/chromium/src/+/master:sandbox/policy/win/sandbox_win.cc;l=607;drc=f9368f7738775f0e005f07b5135875668f75439f

function increaseCornerstoneCache() {
	try {
		if (!window.ElectronInterface || window.ElectronInterface.is.x86()) return
		const memoryInfo = window.ElectronInterface.process.getSystemMemoryInfo()
		const { total: totalMemoryKB } = memoryInfo
		const totalMemoryGB = totalMemoryKB / 1024 / 1024
		let cacheMaxGB = 2
		if (totalMemoryGB > 8) cacheMaxGB = 7 // leave 1GB+ for rest of app
		if (totalMemoryGB > 16) cacheMaxGB = 15
		imageCache.setMaximumSizeBytes(cacheMaxGB * 1024 * 1024 * 1024)
	} catch (err) {
		// do not increase cache if we fail to get all the necessary system info
	}
}

increaseCornerstoneCache()
