import { metaData } from 'cornerstone-core/dist/cornerstone.js'
import isSameOrientation from './isSameOrientation'

export default function isLocalizerImage(imageIds, imageId, targetImagePlane) {
	const targetImageMeta = metaData.get('generalImageModule', imageId)
	const imageType = targetImageMeta && targetImageMeta.imageType
	if (!imageType) return false
	if (imageType.toUpperCase().includes('LOCALIZER')) return true

	targetImagePlane = targetImagePlane || metaData.get('imagePlaneModule', imageId)
	return (
		imageType.toUpperCase().includes('DERIVED') && !isSameOrientation(targetImagePlane, getMiddleImagePlane(imageIds))
	)
}

function getMiddleImagePlane(imageIds) {
	const middleIndex = Math.floor(imageIds.length / 2)
	const middleImageId = imageIds[middleIndex]
	return metaData.get('imagePlaneModule', middleImageId)
}
