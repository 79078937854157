const replaceFirstLetters = (str, fn) => str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, fn)

export function toCamelCase(str) {
	return replaceFirstLetters(str, (firstLetter, i) => {
		if (/\s+/.test(firstLetter)) return '' // remove whitespace
		return i === 0 ? firstLetter.toLowerCase() : firstLetter.toUpperCase()
	})
}

export function toPascalCase(str) {
	return replaceFirstLetters(str, firstLetter => {
		if (/\s+/.test(firstLetter)) return '' // remove whitespace
		return firstLetter.toUpperCase()
	})
}

export function formatList(arr) {
	const terms = arr.slice()
	const oxfordComma = arr.length > 2 ? ',' : ''
	const last = terms.pop()
	return terms.join(', ') + oxfordComma + ' and ' + last
}
