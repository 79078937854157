import * as cornerstoneMath from 'cornerstone-math'
import getValue from './../internals/getValue.js'

/**
 * [Image Plane]{@link https://dicom.innolitics.com/ciods/ct-image/image-plane}
 *
 * @export
 * @param {*} dicomTags
 * @returns
 */
export default function(dicomTags) {
	let rowCosines, columnCosines
	let imageOrientationPatient = getValue(dicomTags, 'IMAGE_ORIENTATION_PATIENT')
	let imagePositionPatient = getValue(dicomTags, 'IMAGE_POSITION_PATIENT')

	if (imageOrientationPatient) {
		imageOrientationPatient = imageOrientationPatient.split('\\')
		rowCosines = new cornerstoneMath.Vector3(
			parseFloat(imageOrientationPatient[0]),
			parseFloat(imageOrientationPatient[1]),
			parseFloat(imageOrientationPatient[2])
		)
		columnCosines = new cornerstoneMath.Vector3(
			parseFloat(imageOrientationPatient[3]),
			parseFloat(imageOrientationPatient[4]),
			parseFloat(imageOrientationPatient[5])
		)
	}
	if (imagePositionPatient) {
		imagePositionPatient = imagePositionPatient.split('\\')
		imagePositionPatient = new cornerstoneMath.Vector3(
			parseFloat(imagePositionPatient[0]),
			parseFloat(imagePositionPatient[1]),
			parseFloat(imagePositionPatient[2])
		)
	}

	const { pixelSpacing, isPixelSpacingDefined } = _getPixelSpacing(dicomTags)
	const [rowPixelSpacing, columnPixelSpacing] = pixelSpacing

	return {
		frameOfReferenceUID: getValue(dicomTags, 'FRAME_OF_REFERENCE_UID'),
		rows: getValue(dicomTags, 'ROWS'),
		columns: getValue(dicomTags, 'COLUMNS'),
		rowCosines,
		columnCosines,
		imagePositionPatient,
		pixelSpacing,
		rowPixelSpacing,
		columnPixelSpacing,
		isPixelSpacingDefined,
		imageOrientationPatient,
		sliceThickness: getValue(dicomTags, 'SLICE_THICKNESS'),
		sliceLocation: getValue(dicomTags, 'SLICE_LOCATION'),
	}
}

/**
 *
 *
 * @param {*} dicomTags
 * @returns
 */
function _getPixelSpacing(dicomTags) {
	const isBadValue = x => x === 0 || x > 10
	let isPixelSpacingDefined = true
	let spacing =
		getSpacingValues(dicomTags, 'AST_CALIBRATED_PIXEL_SPACING') ||
		getSpacingValues(dicomTags, 'PIXEL_SPACING') ||
		getSpacingValues(dicomTags, 'IMAGER_PIXEL_SPACING') ||
		getSpacingValues(dicomTags, 'NOMINAL_SCANNED_PIXEL_SPACING') ||
		_getUltrasoundPixelSpacing(dicomTags)
	if (!spacing || spacing.some(isBadValue)) {
		spacing = [1.0, 1.0]
		isPixelSpacingDefined = false
	}
	return { pixelSpacing: spacing.map(Math.abs), isPixelSpacingDefined }

	function getSpacingValues(metaData, tag) {
		const spacing = getValue(metaData, tag)
		if (spacing && spacing.some(isBadValue)) return
		return spacing
	}
}

/**
 *
 * @private
 * @function _getUltrasoundPixelSpacing
 *
 * @param {*} metaData
 * @returns
 */
function _getUltrasoundPixelSpacing(metaData) {
	// x00186011
	const sequenceOfUltrasoundRegions = metaData.find(x => x.Group === 24 && x.Element === 24593)
	if (!sequenceOfUltrasoundRegions || !sequenceOfUltrasoundRegions.Children) return

	let regionValues = {}
	sequenceOfUltrasoundRegions.Children.forEach(tag => {
		regionValues[tag.Name] = regionValues[tag.Name] || [] // create a sequence array for each tag
		regionValues[tag.Name].push(tag.Value)
	})
	const region2DIndex = _get2DRegionIndex(regionValues)
	const get2DRegionValue = tagName => parseFloat(regionValues[tagName][region2DIndex])
	let rowSpacing = get2DRegionValue('PhysicalDeltaX')
	let columnSpacing = get2DRegionValue('PhysicalDeltaY')
	if (!rowSpacing || !columnSpacing) return

	const isCentimeters = tagName => get2DRegionValue(tagName) === 3
	if (isCentimeters('PhysicalUnitsXDirection')) rowSpacing *= 10
	if (isCentimeters('PhysicalUnitsYDirection')) columnSpacing *= 10

	return [rowSpacing, columnSpacing]
}

/**
 *
 * @private
 * @function _get2DRegionIndex
 *
 * @param {*} regionValues
 * @returns
 */
function _get2DRegionIndex(regionValues) {
	let region2DIndex
	if (regionValues.RegionSpatialFormat && regionValues.RegionSpatialFormat.length > 1) {
		const valueFor2D = 1 // 1=2D, 2=M-Mode, 3=Spectral, 4=Waveform, 5=Graphics
		region2DIndex = regionValues.RegionSpatialFormat.findIndex(value => parseInt(value) === valueFor2D)
	}
	return region2DIndex > 0 ? region2DIndex : 0 // fallback to first region
}
