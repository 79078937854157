import { API, paramsSerializer } from '@services/api'

class CommunityService {
	getFolderCounts() {
		// @ts-ignore
		return API.get(`/community/folder-counts`, { cancelPending: true }).then(r => r && r.data)
	}

	getStudiesById(studyIds) {
		return API.get('/community/studies', {
			params: { studyIds },
			paramsSerializer,
		}).then(r => r && r.data)
	}

	getStudies(
		uploadStartDate,
		uploadEndDate,
		studyStartDate,
		studyEndDate,
		modalityIds,
		patientIdNameOwner,
		notSent,
		page,
		resultsPerPage
	) {
		return API.get('/community/studies', {
			// @ts-ignore
			cancelPending: true,
			params: {
				uploadStartDate,
				uploadEndDate,
				studyStartDate,
				studyEndDate,
				modalityId: modalityIds,
				patientIdNameOwner,
				notSent,
				page,
				resultsPerPage,
			},
			paramsSerializer,
		}).then(r => r && r.data)
	}
}

export const communityService = new CommunityService()
