<template>
	<div id="app">
		<svg-sprite-map />
		<ast-notifications />
		<!-- route outlet -->
		<!-- component matched by the route will render here -->
		<router-view />
		<loading />
	</div>
</template>

<script>
import { events as csEvents } from 'cornerstone-core/dist/cornerstone.js'

import SvgSpriteMap from '@components/SvgSpriteMap.vue'
import Loading from '@components/Loading.vue'
import { eventBus } from './services/eventBus'
import storage from '@services/storage'
import featureFlags from '@featureFlags'
import tinyMCEConf from '@/services/tinyMCEConf'

export default {
	name: 'App',
	components: {
		SvgSpriteMap,
		Loading,
		AstNotifications: () => import(/* webpackChunkName: "pluginVueNotifications" */ './plugins/VueNotifications.vue'),
	},
	created() {
		// Set featureFlags as global variable
		if (!window.featureFlags) window.featureFlags = featureFlags

		// Setup Feature Flags
		window.featureFlags.cleanDeprecatedFeatureFlags()
		window.featureFlags.setFeatureFlags()

		// Grab this once to set a clientId in OmniDesktop
		if (window.ElectronInterface && !localStorage.getItem('ga:clientId')) {
			this.$ga.query(function(tracker) {
				const clientId = tracker.get('clientId')
				localStorage.setItem('ga:clientId', clientId)
			})
		}

		Promise.all([
			this.$store.dispatch('applyTheme'),
			this.$store.dispatch('initAuth'),
			this.$store.dispatch('listenForInputDevices'),
		]).then(() => {
			// Deeplink opened Omni Desktop
			// Wait until the app has loaded before checking if we need to navigate somewhere
			// const deeplink = window.deeplink
			// if (deeplink) {
			// 	const { path, query } = deeplink
			// 	// TODO: ch10454 - check if path is viewer && open in new window setting, open in new window if so
			// 	const route = this.$router.resolve({ path, query })
			// 	delete window.deeplink
			// }
		})

    window.tinyMCEConf = tinyMCEConf

		// Deeplink events while Desktop is running
		eventBus.on('deeplink', async deeplinkObject => {
			const { token, path, query } = deeplinkObject
			await this.$store.dispatch('logOut', { changeRoute: false, forceLogout: true })
			this.$store.commit('setAuthentication', { token, expiration: 0 })
			await this.$store.dispatch('refreshToken', true)
			if (this.$store.getters.isAuthenticated) {
				storage.clearTokenUses()
				storage.addTokenUse()
			}
			this.$router.replace({ path: '/' })
			// TODO: ch10454 - check if path is viewer && open in new window setting, open in new window if so
			// router.push({path, query})
		})
	},
}
</script>

<style lang="scss">
@import '~@styles/_main.scss';
.app {
	position: relative;
}
html {
	background-color: var(--primary-bg);
}
</style>
