import api from '@services/api'
import themes from './themes'
import { eventBus } from '@services/eventBus'
import storage from '@services/storage'

// Mutation types
const SET_ACTIVE_THEME = 'SET_ACTIVE_THEME'

const defaultThemeSettings = {
	company: 'asteris',
	mode: 'light',
}
const cachedThemeSettings = JSON.parse(storage.getItem('themeSettings')) || {}

const state = {
	company: cachedThemeSettings.company || defaultThemeSettings.company,
	mode: cachedThemeSettings.mode || defaultThemeSettings.mode,
	theme: {},
}

const getters = {
	themeIsDark: state => state.mode === 'dark',
}

const actions = {
	// Fetches and applies the theme settings from the server
	async fetchThemeForUser({ dispatch }) {
		// Fetch the theme settings
		const response = await api.user.getThemeSettings()
		const theme = response.data
		// Resolve the theme settings
		const company = theme.company || defaultThemeSettings.company
		const mode = theme.mode || defaultThemeSettings.mode
		// Assign the theme values
		dispatch('setTheme', { company, mode, applyNow: false })
	},
	setThemeAndBroadcast({ dispatch }, payload) {
		const type = 'setTheme'
		dispatch(type, payload)
		eventBus.broadcast(eventBus.type.VUEX_ACTION, { type, payload })
	},
	// Update the current user theme
	async setTheme({ commit, dispatch, state, rootGetters }, { company, mode, applyNow = true } = {}) {
		company = company || state.company
		mode = mode || state.mode
		if (!themes[company]) return
		commit(SET_ACTIVE_THEME, { company, mode, theme: themes[company] })
		if (applyNow) dispatch('applyTheme', { company, mode })
		storage.setItem('themeSettings', JSON.stringify({ company, mode }))
		if (rootGetters.isAuthenticated) await api.user.updateThemeSettings({ company, mode })
	},
	// Apply theme to css variables
	applyTheme({ state }, { company, mode, root, unsetTheme = false } = {}) {
		company = company || state.company
		mode = mode || state.mode
		root = root || document.documentElement
		const theme = themes[company]
		const isDark = mode === 'dark'
		const { light, dark } = theme.cssVariables
		let variables = isDark ? dark : light
		// Mix-in the default variables if needed
		const defaultTheme = themes[defaultThemeSettings.company]
		if (theme !== defaultTheme) {
			const { light: defLight, dark: defDark } = defaultTheme.cssVariables
			const defVariables = isDark ? defDark : defLight
			variables = { ...defVariables, ...variables }
		}
		for (const variable in variables) {
			if (!unsetTheme) {
				root.style.setProperty('--' + variable, variables[variable])
			} else {
				root.style.removeProperty('--' + variable)
			}
		}
		document.body.classList.remove('dark')
		document.body.classList.remove('light')
		document.body.classList.add(mode)
	},
}

const mutations = {
	[SET_ACTIVE_THEME](state, { company, mode, theme }) {
		state.company = company
		state.mode = mode === 'dark' ? 'dark' : 'light'
		state.theme = theme
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
