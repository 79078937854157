import * as cornerstone from 'cornerstone-core'

import { getImageData, validateMPRImages, loadImageData } from '@vtk/index.js'
import wait from '@/utils/wait'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import shouldLoadDicom from '@/utils/shouldLoadDicom.js'

export default async function loadVolumeFromSeries({ commit, dispatch, state }, { series, completion }) {
	commit('SET_MPR_LOADING', true)
	commit('SET_MPR_LOAD_PROGRESS', 0)

	// let the loading stuff paint before locking the UI thread with VTK
	await wait(400)

	const validImages = validateMPRImages(series.images).images
	const imageIds = validImages.map(i => i.imageId)

	const useJpeg = !(shouldLoadDicom(validImages[0].modality) || shouldLoadDicom(series.modality))

	// TODO: track this in analytics somewhere?
	// const startProcess = new Date().getTime()

	// Get vtk image data for the series image data
	try {
		const imageData = getImageData(imageIds, series.seriesId, cornerstone.metaData.get, useJpeg)

		if (imageData.hasMissingSlices) {
			dispatch('addNotification', {
				message: `<strong>Warning:</strong><br>
				This Series has irregular spacing between some images.<br>
				Missing slices have been skipped. Banding may occur.`,
				notificationType: 'warn',
				id: 'missingSlices',
				duration: 30000,
			})
		}

		if (imageData.hasOverlap) {
			dispatch('addNotification', {
				message: `<strong>Warning:</strong><br>
				This Series has overlapping slices, which may indicate a multi-phase study.<br>
				The first image for a given <code>imagePositionPatient</code> has been used.`,
				notificationType: 'warn',
				id: 'extraSlices',
				duration: 30000,
			})
		}

		let renderFrequencyPct = 3
		if (imageIds.length > 500) {
			renderFrequencyPct = 7
		} else if (imageIds.length > 250) {
			renderFrequencyPct = 5
		}

		// Note: Modifies imageData object!
		loadImageData(imageData, validImages, series, renderFrequencyPct)

		// Let MPR load after the first loaded image has been processed
		// Enables Quick MPR loading & faster render, but still is loading the volume in the BG.
		// NOTE: to require MPR having the images loaded before opening the render view,
		//       move this logic into the Promise.all section
		Promise.any(imageData.insertPixelDataPromises).then(num => {
			commit('SET_MPR_VOLUME_DIRECTION', imageData.direction)
			commit('SET_MPR_VOLUME_SLICE_DIRECTIONALITY', imageData.sliceDirectionality)
			commit('SET_MPR_VOLUME_DATA', imageData.vtkImageData)
			commit('SET_MPR_DEFAULT_WINDOW_LEVEL', imageData.voi)
			commit('SET_MPR_VOLUME_IS_JPEG', useJpeg)
			commit('UPDATE_AVAILABLE_TOOLS')

			// let the caller know we're ready to start image stuff
			completion()

			return num
		})

		const total = imageData.insertPixelDataPromises.length
		let lastpercent = 0
		imageData.insertPixelDataPromises.forEach(promise => {
			try {
				promise.then(num => {
					const pct = Math.floor((num / total) * 100)
					if (pct > lastpercent + 1) {
						lastpercent = pct
						if (state.activeSeries) commit('SET_MPR_LOAD_PROGRESS', pct)
					}
				})
			} catch (e) {}
		})

		Promise.all(imageData.insertPixelDataPromises).then(() => {
			// TODO: track this in analytics somewhere?
			// const duration = new Date().getTime() - startProcess
			// console.log(`Conversion Duration: ${duration}ms`)
			if (state.activeSeries) {
				commit('SET_MPR_LOAD_PROGRESS', -1)
				commit('SET_MPR_LOADING', false)
			}
		})
	} catch (e) {
		if (e instanceof RangeError) {
			console.error(e)
			showConfirm(
				`<p>Keystone Omni is not able to generate MPR Views when there are too many images in the series, or if the images are too big.</p>
				 <p>Your browser can handle around 900 images with a width and height of 512 pixels, or 200 images with width and height of 1024 pixels. The number of images may be smaller on a Mobile device or Tablet.</p>
				 <p>Please try a smaller study.</p>`,
				{ title: 'Error Generating MPR Volume' }
			)
		} else {
			console.error(e)
			showConfirm(
				`<p>There was an issue generating the MPR volume.</p>
				 <p>If you continue to see this error, please contact the Asteris Support Team.</p>`,
				{ title: 'Error Initializing MPR' }
			)
		}
		// Cleanup MPR related things
		commit('SET_MPR_LOADING', false)
		commit('SET_MPR_ACTIVE', false)
	}
}
