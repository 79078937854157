















import { Annex } from '@services/api'

export default {
	name: 'ReportImage',
	props: ['cell'],
	data() {
		return {}
	},
	computed: {
		isImageComment() {
			return this.cell.props.source === '[Image Comment]'
		},
		isSignature() {
			return this.cell.props.source === '[Signature]'
		},
		isLogo() {
			return this.cell.props.source === '[Logo]'
		},
		isUserImage() {
			let src: string = this.cell.props.source
			return src && src.startsWith('[Image:')
		},
		url() {
			let source = this.cell.props.source

			if (!this.isSignature && !this.isLogo && !this.isImageComment) return source

			if (this.cell.editorMode) return

			if (this.isImageComment) {
				return this.cell.context.ImageComment.url
			}

			let url = ''
			let base = Annex.defaults.baseURL
			let cc = this.$store.state.auth.claims.activeClinicCode

			if (this.isSignature) {
				url = `${base}/logo.aspx?ClinicCode=${cc}&Type=signature`
				if (this.cell.report.Consultant) url += `&ConsultantId=${this.cell.report.Consultant.id}`
				else if (this.cell.report.finalizedBy) url += `&UserId=${this.cell.report.finalizedBy.id}`
			} else if (this.isLogo) {
				url = `${base}/logo.aspx?ClinicCode=${cc}&Type=logo`
				if (this.cell.report.Consultant) url += `&ConsultantId=${this.cell.report.Consultant.id}`
			}

			return url
		},
	},
	methods: {
		onImageClick() {
			if (!this.isImageComment) return
			this.$store.dispatch('openStudy', {
				reportId: this.cell.report.reportId,
				imageId: this.cell.context.ImageComment.imageId,
			})
		},
	},
}
