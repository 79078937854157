// @ts-ignore
import DicomParseWorker from 'worker-loader!./parse.worker.js' // eslint-disable-line

const maxWorkers = navigator.hardwareConcurrency || 4
const workerPool = new Array(maxWorkers)
const parseQueue = []

function runWorker(i) {
	let worker = workerPool[i]
	let item = null

	function start() {
		if (parseQueue.length > 0) {
			item = parseQueue.shift()
			worker.postMessage(item.file)
		} else {
			worker.terminate()
			workerPool[i] = undefined
		}
	}

	if (!worker) {
		workerPool[i] = worker = new DicomParseWorker()
		worker.onmessage = ({ data }) => {
			item.resolve(data)
			start()
		}
	}

	start()
}

function startWorkers() {
	for (let i = 0; i < workerPool.length; i++) {
		if (parseQueue.length === 0) break
		if (!workerPool[i]) {
			runWorker(i)
		}
	}
}

export default function parseDicom(file) {
	return new Promise(resolve => {
		parseQueue.push({ file, resolve })
		startWorkers()
	})
}
