import { import as toolImport, getToolState } from 'cornerstone-tools/dist/cornerstoneTools.js'
const scrollToIndex = toolImport('util/scrollToIndex')

export default {
  nextImage ({ rootGetters }) {
    goToIndex(rootGetters.activeCanvas, currentIndex => currentIndex + 1)
  },
  previousImage ({ rootGetters }) {
    goToIndex(rootGetters.activeCanvas, currentIndex => currentIndex - 1)
  },
  firstImage ({ rootGetters }) {
    goToIndex(rootGetters.activeCanvas, () => 0)
  },
  lastImage ({ rootGetters }) {
    goToIndex(rootGetters.activeCanvas, (currentIndex, lastIndex) => lastIndex)
  },
}

/**
 * Scrolls to a target index returned by the passed-in function
 *
 * @param {object} canvas - active canvas
 * @param {Function} indexFn - a function that returns the target index
 */
function goToIndex(canvas, indexFn) {
  if (!canvas.seriesId && !canvas.imageId) return
  const toolData = getToolState(canvas.dom, 'stack')
  if (!toolData || !toolData.data || !toolData.data.length) return
  const { currentImageIdIndex } = toolData.data[0]
  const lastIndex = toolData.data[0].imageIds.length - 1
  const targetIndex = indexFn(currentImageIdIndex, lastIndex)
  if (targetIndex < 0 || targetIndex > lastIndex) return
  if (targetIndex === currentImageIdIndex) return
  scrollToIndex(canvas.dom, targetIndex)
}
