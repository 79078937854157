import { Cell, Template } from './classes'
import { ContextMeta } from './context'
import { toPascalCase } from '@utils/stringUtils'
import { localizeDate } from '@/mixins/localization'
import { formatNumber } from '@utils/numberUtils'

const parseAndFilterNumbers = (a: any[]) => {
	const html = /(<([^>]+)>)/gi
	const nonDigits = /[^\d.-]/g
	const parse = p =>
		p &&
		parseFloat(
			p
				.toString()
				.replace(html, '')
				.replace(nonDigits, '')
		)
	return a.map(parse).filter(p => !isNaN(p))
}

function add(params: any[]): number {
	let args = parseAndFilterNumbers(params)
	let value = 0
	args.forEach(p => (value += p))
	return value
}

function subtract(params: any[]): number {
	let args = parseAndFilterNumbers(params)
	if (args.length < 2) return null
	let value
	args.forEach(p => (value = value === undefined ? p : value - p))
	return value
}

function multiply(params: any[]): number {
	let args = parseAndFilterNumbers(params)
	if (args.length < 2) return 0
	let value
	args.forEach(p => (value = value === undefined ? p || 0 : value * (p || 0)))
	return value
}

function divide(params: any[]): number {
	let args = parseAndFilterNumbers(params)
	if (args.length < 2) return null
	if (args.some((a, i) => i > 0 && a === 0)) throw new Error('Cannot divide by zero.')
	let value
	args.forEach((p, i) => {
		if (i === 0) value = p || 0
		else value = value / p
	})
	return value
}

function average(params: any[]): number {
	let args = parseAndFilterNumbers(params)
	if (args.length === 0) return null
	let value = 0
	args.forEach(p => (value += p))
	return value / args.length
}

function pow(params: any[]): number {
	let args = parseAndFilterNumbers(params)
	if (args.length !== 2) return null
	return Math.pow(args[0], args[1])
}

function sqrt(params: any[]): number {
	let args = parseAndFilterNumbers(params)
	if (args.length !== 1) return null
	return Math.sqrt(args[0])
}

function ln(params: any[]): number {
	let args = parseAndFilterNumbers(params)
	if (args.length !== 1) return null
	return Math.log(args[0])
}

function concat(params: any[]): string {
	const formatAnyNumbers = p => (typeof p === 'number' ? formatNumber(p) : p)
	return params
		.map(formatAnyNumbers)
		.join('')
		.trim()
}

export const METHODS = {
	ADD: {
		fn: add,
		description: 'Adds two or more values',
	},
	SUM: {
		fn: add,
		description: 'Adds two or more values',
	},
	SUB: {
		fn: subtract,
		description: 'Subtracts values in order',
	},
	MULT: {
		fn: multiply,
		description: 'Multiplies two or more values',
	},
	DIV: {
		fn: divide,
		description: 'Divides values in order',
	},
	AVG: {
		fn: average,
		description: 'Averages two or more values',
	},
	POW: {
		fn: pow,
		description: 'Returns the first value to the power of the second',
	},
	LN: {
		fn: ln,
		description: 'Returns the natural logarithm of a value',
	},
	SQRT: {
		fn: sqrt,
		description: 'Returns the square root of a value',
	},
	CONCAT: {
		fn: concat,
		description: 'Concatenates two or more values',
	},
}

export enum ExpType {
	Method = 'Method',
	ControlValue = 'Control Value',
	ContextValue = 'Context Value',
	Constant = 'Constant',
	Literal = 'Literal',
}

export class Binding {
	expression: Expression
	dependencyMap = {}
	cell: Cell
	_prop: string
	valid = true
	errors: string[] = []
	text: string = null

	constructor(cell: Cell, prop = 'binding') {
		this.cell = cell
		this._prop = prop
		this.parse()
	}

	parse(text?: string) {
		this.text = text === undefined ? this.cell.props[this._prop] : text
		this.expression = new Expression(this.cell, this.text)
		this.expression.getDependentPaths(this.dependencyMap)
		this.validate()
	}

	_parse(str: string) {
		this.text = str
		this.expression = new Expression(this.cell, str)
		this.expression.getDependentPaths(this.dependencyMap)
		this.validate()
	}

	hasDependency(path: string): boolean {
		return this.dependencyMap[path] !== undefined
	}

	replaceDependency(oldPath: string, newPath: string) {
		const termRegex = new RegExp('(^|,|\\()\\s?' + oldPath + '(?=\\s?($|,|\\)))', 'gi')
		const newText = this.text.replace(termRegex, match => match.replace(oldPath, newPath))
		this._parse(newText)
		this.cell.props.binding = newText
	}

	getValue(): any {
		const value = this.expression.getValue(this.cell)
		if (typeof value === 'number') return formatNumber(value)
		return value
	}

	validate(): boolean {
		if (!this.text) return
		// verify that the template value paths valid
		// verify that only methods have arguments
		this.expression.validate()
		const getExpressionErrors = e => e.errors.concat(...e.args.map(getExpressionErrors))
		this.errors = Array.from(new Set(getExpressionErrors(this.expression))) // deduped flat array
		const getCharCount = (char: string) => {
			let text = this.text.replace(/(["|']).*?\1/g, '""') // ignore string literals
			return (text.match(new RegExp('\\' + char, 'g')) || []).length
		}
		if (getCharCount('(') !== getCharCount(')'))
			this.errors.push('There is an unmatched parenthesis.')
		this.valid = this.errors.length === 0
		return this.valid
	}
}

export class Expression {
	term: string
	args?: Expression[] = []
	cell: Cell
	calcErrors: string[] = []
	errors: string[] = []

	// ex: POW(2,SQRT(AVG(SUM(widget1.textbox1,[patient.age]),textbox2))))
	constructor(cell: Cell, str: string = '') {
		this.cell = cell
		str = str.trim()
		const hasNoArgs = !str.includes('(') || /^['"]/.test(str)
		if (hasNoArgs) {
			this.term = str
			return
		}
		const firstParenIndex = str.indexOf('(')
		this.term = str.substr(0, firstParenIndex).trim()
		let parenLevel = 0
		let arg = ''
		const end = str.length - 1
		let isInSingleQuote = false
		let isInDoubleQuote = false
		for (let i = firstParenIndex + 1; i <= end; i++) {
			const char = str[i]
			if (!isInDoubleQuote && char === "'") isInSingleQuote = !isInSingleQuote
			if (!isInSingleQuote && char === '"') isInDoubleQuote = !isInDoubleQuote
			const isInQuote = isInDoubleQuote || isInSingleQuote
			if (isInQuote) {
				arg += char
				if (i !== end) continue
			} else {
				if (char === '(') parenLevel++
				if (char === ')') parenLevel--
			}
			if (((!isInQuote && char === ',') || i === end) && parenLevel <= 0) {
				if (!isInQuote && i === end && char !== ')') arg += char
				if (arg.length > 0) {
					this.args.push(new Expression(cell, arg))
				}
				arg = ''
				continue
			}

			arg += char
		}
	}

	/* get noBrackets(): string {
		let result = this.term
		if (result[0] === '[') result = result.substr(1)
		if (result[result.length - 1] === ']') result = result.substr(0, result.length - 1)
		return result
	} */

	getDependentPaths(map: any) {
		if (this.type !== ExpType.Method && this.type !== ExpType.Constant) {
			map[this.term] = true
		}
		this.args.forEach(s => s.getDependentPaths(map))
	}

	get type(): ExpType {
		if (ContextMeta[this.prefix] !== undefined) return ExpType.ContextValue
		// for top-level context values (i.e. CompletionDate):
		if (!this.prefix && ContextMeta[this.termNoPrefix]) return ExpType.ContextValue
		else if (this.method !== undefined) return ExpType.Method
		else if (!isNaN(parseFloat(this.term))) return ExpType.Constant
		else if (/^['"]/.test(this.term)) return ExpType.Literal
		else return ExpType.ControlValue
	}

	get classList(): string[] {
		let classes = []
		switch (this.type) {
			case ExpType.Method:
				classes.push('is-method')
				break
			case ExpType.ControlValue:
			case ExpType.ContextValue:
				classes.push('is-value')
				break
			default:
				classes.push('is-constant')
		}
		if (this.errors.length) classes.push('has-errors')
		return classes
	}

	get method(): any {
		return METHODS[this.term.toUpperCase()]
	}

	getControlValueMap(cell: Cell): ValueMap {
		let t = this.getControlTemplate(cell)
		return t ? t.values : {}
	}

	getControlTemplate(cell: Cell): Template {
		return cell.context[this.prefix] || cell.template
	}

	get prefix(): string {
		const prefixes = ['Report', 'Request', 'Response', ...Object.keys(ContextMeta)]
		return prefixes.find(p => this.term.startsWith(p + '.'))
	}

	get termNoPrefix(): string {
		return this.term.replace(this.prefix + '.', '')
	}

	validate() {
		this.errors = []
		const NotFoundError = `${this.term} was not found or is not bindable.`
		if (this.type === ExpType.Method) {
			// verify method has arguments
			if (this.args.length === 0) {
				this.errors.push(`The ${this.term} function requires arguments.`)
			}
			// validate sub-expressions
			this.args.forEach(a => a.validate())
		} else if (this.type === ExpType.ControlValue) {
			// verify term is not just a prefix
			if (this.prefix && !this.termNoPrefix) this.errors.push(NotFoundError)
			let template = this.getControlTemplate(this.cell)
			// verify control is not binding to itself
			const isBindingToSelf =
				this.cell.path === this.termNoPrefix &&
				(!this.prefix || this.prefix.includes(this.cell.template.type))
			if (isBindingToSelf) {
				this.errors.push(`${this.cell.path}: control binding cannot reference itself.`)
				// verify control path is valid
			} else if (this.termNoPrefix && !template.hasBindablePath(this.termNoPrefix)) {
				this.errors.push(NotFoundError)
				// verify arguments are not passed to a control
			} else if (template.hasBindablePath(this.termNoPrefix) && this.args.length) {
				this.errors.push(`${this.term} cannot have arguments.`)
			}
		} else if (this.type === ExpType.ContextValue) {
			// verify report value path is valid
			const paths = this.term.split('.').filter(p => p !== '')
			const contextValue = paths.reduce((a: any, i) => a.items[toPascalCase(i)], {
				items: ContextMeta,
			}) // resolve dot notation
			if (contextValue === undefined) this.errors.push(NotFoundError)
			else if (this.args.length) this.errors.push(`${this.term} cannot have arguments.`)
		} else if (this.args.length) {
			// verify arguments are not passed to a report value or constant
			this.errors.push(`${this.term} cannot have arguments.`)
		}
	}

	getValue(cell: Cell): any {
		function finalizeValue(value: any) {
			if (value instanceof Date) {
				let showTime = value.getMilliseconds() > 0
				value = localizeDate(value.toString(), { showTime })
			} else if (isNaN(value)) {
				let d = Date.parse(value)
				if (!isNaN(d)) value = localizeDate(value)
			}
			return value
		}

		switch (this.type) {
			case ExpType.Method:
				try {
					this.calcErrors = []
					return this.method.fn.bind(this)(this.args.map(s => s.getValue(cell)))
				} catch (err) {
					this.calcErrors.push(err.message)
					return undefined
				}
			case ExpType.Constant:
			case ExpType.Literal:
				if (/^['"]/.test(this.term)) return this.term.replace(/^["|'](.+(?=["|']$))["|']$/, '$1')
				return this.term
			case ExpType.ContextValue:
				return finalizeValue(cell.getContextValue(this.term))
			case ExpType.ControlValue: {
				const template = this.getControlTemplate(cell)
				return finalizeValue(template.getValue(this.termNoPrefix))
			}
			default:
				return undefined
		}
	}
}
