import { CancelToken } from 'axios'

const cancelTokens = []

function install(axiosInstance) {
  axiosInstance.interceptors.request.use(requestInterceptor)
  axiosInstance.interceptors.response.use(responseInterceptor)
}

function requestInterceptor(config) {
  if (!config.cancelPending) return config
  const { url } = config
  // cancel any existing pending requests for this url
  const pendingRequest = cancelTokens.find(x => x.url === url)
  if (pendingRequest) {
    pendingRequest.cancel()
    deleteTokenForUrl(url)
  }
  // store a new cancel token for this request
  config.cancelToken = new CancelToken(cancel => {
    cancelTokens.push({ url, cancel })
  })
  return config
}

function responseInterceptor(response) {
  if (!response) return
  const { url, cancelPending } = response.config
  // delete cancel token for completed request
  if (cancelPending) deleteTokenForUrl(url)
  return response
}

function cancelAllPendingRequests() {
  while (cancelTokens.length > 0) {
    const token = cancelTokens.shift()
    token.cancel()
  }
}

function deleteTokenForUrl (url) {
  const token = cancelTokens.find(x => x.url === url)
  cancelTokens.splice(cancelTokens.indexOf(token), 1)
}

export default {
  cancelAllPendingRequests,
  cancelTokens,
  install,
}
