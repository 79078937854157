<template>
	<div style="padding: 16px;">
		<p>The following email addresses will be notified when the status of your report changes:</p>
		<p v-if="cell.readOnly" style="padding: 8px 0;">
			{{ report.emails.join(', ') }}
		</p>
		<email-select
			v-else
			ref="emails"
			v-model="report.emails"
			:show-suggestions="!cell.editorMode"
			:disabled="cell.editorMode"
			style="margin-top: 8px;"
		/>
	</div>
</template>

<script>
import consultations from '@services/consultationService'
import EmailSelect from '@components/EmailSelect.vue'

export default {
	name: 'Notifications',
	components: {
		EmailSelect,
	},
	props: ['cell'],
	computed: {
		report() {
			return this.cell.editorMode ? { emails: [] } : this.cell.report
		},
	},
	watch: {
		'cell.report.client.email': {
			handler(newValue, oldValue) {
				if (this.cell.editorMode || this.cell.readOnly) return
				let report = this.cell.report
				// Add client email to notification list
				const oldIndex = report.emails.indexOf(oldValue)
				if (oldIndex >= 0) report.emails.splice(oldIndex, 1)
				if (newValue && !report.emails.includes(newValue)) report.emails.push(newValue)
			},
			immediate: true,
		},
	},
}
</script>
