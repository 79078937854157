<template>
	<div class="patient-info">
		<table>
			<tbody>
				<tr>
					<th style="border-top: none;">Owner:</th>
					<td style="border-top: none;" :class="{ unspecified: !patient.owner }">{{ patient.owner }}</td>
				</tr>
				<tr>
					<th>Patient ID:</th>
					<td :class="{ unspecified: !patient.id }">{{ patient.id }}</td>
				</tr>
				<tr>
					<th>Patient Name:</th>
					<td :class="{ unspecified: !patient.name }">{{ patient.name }}</td>
				</tr>
				<tr>
					<th>Gender:</th>
					<td :class="{ unspecified: !patient.gender }">{{ patient.gender }}</td>
				</tr>
				<tr>
					<th>Birthdate:</th>
					<td :class="{ unspecified: !patient.birthdate }">
						{{ patient.birthdate | localizeDate({ showTime: false }) }}
					</td>
				</tr>
				<tr>
					<th>Species:</th>
					<td :class="{ unspecified: !patient.species }">{{ patient.species }}</td>
				</tr>
				<tr>
					<th>Breed:</th>
					<td :class="{ unspecified: !patient.breed }">{{ patient.breed }}</td>
				</tr>
				<tr>
					<th>Weight:</th>
					<td :class="{ unspecified: !patient.weight }">
						<span v-if="patient.weight">
							{{ Number(patient.weight) | formatNumber }}
							{{ patient.weightUnit || 'KGS' }}
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { Cell } from '../classes'
import { ContextMeta } from '../context'
import { toCamelCase } from '@utils/stringUtils'

export default {
	name: 'PatientInfo',
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	computed: {
		patient() {
			if (this.cell.editorMode) {
				// use ContextMeta sample data
				let client = {}
				Object.keys(ContextMeta.Patient.items).forEach(k => {
					client[toCamelCase(k)] = ContextMeta.Patient.items[k].sample
				})
				return client
			}
			return this.cell.report.patient || {}
		},
	},
}
</script>

<style lang="scss">
.patient-info {
	padding: 16px;

	.unspecified::after {
		content: '-';
		opacity: 0.5;
	}

	table {
		border: 0;
		th {
			font-weight: 400;
			white-space: nowrap;
			vertical-align: top;
		}
		td {
			word-break: break-all;
		}
	}
}
</style>
