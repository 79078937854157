







import { getPreviewContext } from '../context'
export default {
	name: 'LayoutWrapper',
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	created() {
		const layout = this.cell.layout
		if (layout) {
			this.cell.subLayouts = []
			let sub = layout.createInstance()
			sub.readOnly = true
			sub.forcePath = layout.name
			sub.previewInEditor = true
			this.cell.addSubLayout(sub, getPreviewContext())
		}
	},
}
