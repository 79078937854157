























import TeleconsultationThumbnails from '@components/TeleconsultationThumbnails.vue'
import { Cell } from '../classes'

export default {
	name: 'ImagesAttachments',
	components: {
		TeleconsultationThumbnails,
	},
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	computed: {
		numImages() {
			return this.images.length
		},
		images() {
			let cell: Cell = this.cell
			if (cell.editorMode) {
				return []
			}
			let result: IThumbnail[] = cell.report.images.map(i => {
				return {
					imageId: i.id,
					seriesId: i.fakeSeriesId || i.seriesId,
					seriesNumber: i.seriesNumber,
					simpleName: i.simpleName,
					numberOfImages: i.numberOfImages,
					storageLocation: i.storageLocation,
				}
			})
			return result
		},
		attachments() {
			let cell: Cell = this.cell
			if (cell.editorMode) return []
			let result: IAttachment2[] = cell.report.attachments.map(i => {
				return {
					imageId: i.imageId,
					seriesId: i.seriesId,
					description: i.description,
					fileExtension: i.fileExtension,
					storageLocation: i.storageLocation,
				}
			})
			return result
		},
	},
}
