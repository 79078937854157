export default {
	allSeries({ studies }) {
		return [].concat(...studies.map(study => study.imageData.series))
	},
	loadedStudyIds({ studies }) {
		return [...new Set(studies.filter(study => !study.reportId).map(study => study.studyId))]
	},
	loadedReportIds({ studies }) {
		return [...new Set(studies.filter(study => study.reportId).map(study => study.reportId))]
	},
	relatedStudies({ studies }) {
		let relatedStudies = []
			.concat(...studies.map(study => study.relatedStudies || []))
			.filter((study, idx, self) => self.findIndex(s => s.studyId === study.studyId) === idx)
		let unopenedStudies = relatedStudies.filter(study => {
			return !studies.some(openStudy => openStudy.studyId === study.studyId)
		})
    const studiesList = studies.concat(unopenedStudies)
		return (studiesList.length) ? studiesList.sort((a,b) => {
      if (!a || !b) return false
      const dateA = new Date(a.studyDateTime).getTime()
      const dateB = new Date(b.studyDateTime).getTime()
      return dateB - dateA // Date descending sort
    }) : studiesList
	},
	visibleCanvases({ canvases, canvasLayout }) {
		return canvases.slice(0, canvasLayout.numCanvases)
	},
	canvasColors(state, { visibleCanvases }) {
		let canvasColors = {}
		const colorMap = [
			'#22C5FC', // Blue
			'#8EA604', // Green
			'#FEC62E', // Yellow
			'#693668', // Purple
			'#F86624', // Orange
			'#FC0707', // Red
			'#59CD90', // Seafoam
			'#08A4BD', // Teal
			'#FC157E', // Pink

			'#003366', // Dark Blue
			'#a52a2a', // Dark Red/Brown
			'#e6d7ff', // Lilac
			'#adff2f', // Lime
			'#eee8aa', // Pale Goldenrod
			'#ffc0cb', // Light Pink
			'#065535', // Dark Green
			'#b2622d', // Light Brown
			'#ff00ff', // Magenta
			'#c0d6e4', // Ghost
			'#cbbeb5', // Tan
		]
		let colorCount = 0
		for (let i = 0; i < visibleCanvases.length; i++) {
			const visibleCanvas = visibleCanvases[i]
			if (!canvasColors[visibleCanvas.seriesId]) {
				canvasColors[visibleCanvas.seriesId] = colorMap[colorCount]
				colorCount++
			}
		}
		return canvasColors
	},
	activeCanvas: state => (state.activeCanvasIndex >= 0 ? state.canvases[state.activeCanvasIndex] : null),
	activeSeries(_, { allSeries, activeCanvas }) {
		if (!activeCanvas) return null
		return allSeries.find(s => s.seriesId === activeCanvas.seriesId) || {}
	},
	hasActiveSeries(_, { activeSeries }) {
		return !!activeSeries && !!activeSeries.seriesId
	},
	activeStudy(state, { activeSeries }) {
		if (!activeSeries) return null
		return state.studies.find(s => s.studyId === activeSeries.studyId)
	},
	activeReportId(_, { activeStudy }) {
		return activeStudy && activeStudy.reportId
	},
	allActiveSeriesIds(state, { visibleCanvases }) {
		let result = []
		for (let i = 0; i < visibleCanvases.length; i++) {
			result.push(visibleCanvases[i].seriesId)
		}
		return result
	},
	activeImage(state, { activeSeries, activeCanvas }) {
		if (!activeSeries || !activeSeries.images) return

		const matchedImage = activeSeries.images.find(image => {
			return image.imageId === activeCanvas.asterisImageId && image.frameIndex === activeCanvas.frameIndex
		})

		return matchedImage
	},
	activeDisplaySet(state) {
		if (!state.hangingProtocol || !state.hangingProtocol.displaySets) return
		return state.hangingProtocol.displaySets[state.activeDisplaySetIndex].name
	},
}
