const grays = [
	{
		name: 'gray-100',
		color: '#F7FAFC',
	},
	{
		name: 'gray-200',
		color: '#EDF2F7',
	},
	{
		name: 'gray-300',
		color: '#E2E8F0',
	},
	{
		name: 'gray-400',
		color: '#CBD5E0',
	},
	{
		name: 'gray-500',
		color: '#A0AEC0',
	},
	{
		name: 'gray-600',
		color: '#718096',
	},
	{
		name: 'gray-700',
		color: '#4A5568',
	},
	{
		name: 'gray-800',
		color: '#2D3748',
	},
	{
		name: 'gray-900',
		color: '#1A202C',
	},
]

const blues = [
	{
		name: 'blue-darkest',
		color: '#072E54',
	},
	{
		name: 'blue-darker',
		color: '#15407B',
	},
	{
		name: 'blue-dark',
		color: '#26599F',
	},
	{
		name: 'blue',
		color: '#3F7BC7',
	},
	{
		name: 'blue-light',
		color: '#609DE8',
	},
	{
		name: 'blue-lighter',
		color: '#8EC1F9',
	},
	{
		name: 'blue-lightest',
		color: '#C7E1FF',
	},
	{
		name: 'blue-white',
		color: '#F6FAFF',
	},
]

const teals = [
	{
		name: 'teal-darkest',
		color: '#074437',
	},
	{
		name: 'teal-darker',
		color: '#107662',
	},
	{
		name: 'teal-dark',
		color: '#159B80',
	},
	{
		name: 'teal',
		color: '#27BC9F',
	},
	{
		name: 'teal-light',
		color: '#47DCBE',
	},
	{
		name: 'teal-lighter',
		color: '#82F4DD',
	},
	{
		name: 'teal-lightest',
		color: '#CAFFF4',
	},
]

const reds = [
	{
		name: 'red-darkest',
		color: '#4D1309',
	},
	{
		name: 'red-darker',
		color: '#882412',
	},
	{
		name: 'red-dark',
		color: '#BB321A',
	},
	{
		name: 'red',
		color: '#E14228',
	},
	{
		name: 'red-light',
		color: '#F06148',
	},
	{
		name: 'red-lighter',
		color: '#FB8B77',
	},
	{
		name: 'red-lightest',
		color: '#FFC7BD',
	},
]

const yellows = [
	{
		name: 'yellow-darkest',
		color: '#493805',
	},
	{
		name: 'yellow-darker',
		color: '#7A5E09',
	},
	{
		name: 'yellow-dark',
		color: '#AA830F',
	},
	{
		name: 'yellow',
		color: '#D4A71F',
	},
	{
		name: 'yellow-light',
		color: '#F2C438',
	},
	{
		name: 'yellow-lighter',
		color: '#FBD970',
	},
	{
		name: 'yellow-lightest',
		color: '#FFEFC1',
	},
]
const purples = [
	{
		name: 'purple-darkest',
		color: '#562299',
	},
	{
		name: 'purple-darker',
		color: '#6834ad',
	},
	{
		name: 'purple-dark',
		color: '#7e4ec2',
	},
	{
		name: 'purple',
		color: '#9a70d6',
	},
	{
		name: 'purple-light',
		color: '#aa85e0',
	},
	{
		name: 'purple-lighter',
		color: '#ba9beb',
	},
	{
		name: 'purple-lightest',
		color: '#ccb3f5',
	},
]

const greens = [
	{
		name: 'green-darkest',
		color: '#19430A',
	},
	{
		name: 'green-darker',
		color: '#307517',
	},
	{
		name: 'green-dark',
		color: '#4BA22B',
	},
	{
		name: 'green',
		color: '#6AC649',
	},
	{
		name: 'green-light',
		color: '#8CE36C',
	},
	{
		name: 'green-lighter',
		color: '#ADF991',
	},
	{
		name: 'green-lightest',
		color: '#D6FEC8',
	},
]

export default [
	{
		name: 'black',
		color: '#080A0E',
	},
	{
		name: 'white',
		color: '#FCFCFC',
	},
	...grays,
	...blues,
	...teals,
	...reds,
	...yellows,
	...greens,
	...purples,
]
