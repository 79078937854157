/*
	Description:
		Formats a number according to a specific locale or the runtime detected locale if none provided

	Parameters:
		number: Number to format
		options: Reference https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
		{
			`locales`: Must be either a string holding a [BCP 47 language tag](http://tools.ietf.org/html/rfc5646), or an array of such language tags.
				If the `locales` argument is not provided or is undefined, the runtime's default locale is used

			`style`: The formatting style to use. Possible values are "decimal" for plain number formatting, "currency" for currency formatting, and "percent" for percent formatting; the default is "decimal".
			`currency`: The currency to use in currency formatting. Possible values are the ISO 4217 currency codes, such as "USD" for the US dollar, "EUR" for the euro, or "CNY" for the Chinese RMB — see the Current currency & funds code list. There is no default value; if the style is "currency", the currency property must be provided.
			`currencyDisplay`: How to display the currency in currency formatting. Possible values are "symbol" to use a localized currency symbol such as €, "code" to use the ISO currency code, "name" to use a localized currency name such as "dollar"; the default is "symbol".
			`useGrouping`: Whether to use grouping separators, such as thousands separators or thousand/lakh/crore separators. Possible values are true and false; the default is true.

			The following properties fall into two groups:
			`minimumIntegerDigits`, `minimumFractionDigits`, and `maximumFractionDigits` in one group,
			`minimumSignificantDigits` and `maximumSignificantDigits` in the other.
			If at least one property from the second group is defined, then the first group is ignored.

			`minimumIntegerDigits`: The minimum number of integer digits to use. Possible values are from 1 to 21; the default is 1.
			`minimumFractionDigits`: The minimum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number and percent formatting is 0; the default for currency formatting is the number of minor unit digits provided by the ISO 4217 currency code list (2 if the list doesn't provide that information).
			`maximumFractionDigits`: The maximum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number formatting is the larger of minimumFractionDigits and 3; the default for currency formatting is the larger of minimumFractionDigits and the number of minor unit digits provided by the ISO 4217 currency code list (2 if the list doesn't provide that information); the default for percent formatting is the larger of minimumFractionDigits and 0.

			`minimumSignificantDigits`: The minimum number of significant digits to use. Possible values are from 1 to 21; the default is 1.
			`maximumSignificantDigits`: The maximum number of significant digits to use. Possible values are from 1 to 21; the default is 21.
		}
*/
export const formatNumber = (number, opts = {}) => {
	if (typeof number !== 'number') return ''
	return number.toLocaleString(opts.locales, opts)
}

/*
	Description:
		Parses a number according to a specific locale or the runtime detected locale if none provided
		This only works supports decimal type numbers (no currency or percentages)

	Parameters:
		string: Number string to parse
		options:
		{
			`locales`: Must be either a string holding a [BCP 47 language tag](http://tools.ietf.org/html/rfc5646), or an array of such language tags.
				If the `locales` argument is not provided or is undefined, the runtime's default locale is used
			`fallback`: Number used as a fallback if the first argument is invalid or is NaN
		}
*/
export const parseNumber = (string, { locales, fallback = 0 } = {}) => {
	if (typeof string !== 'string') return fallback

	const numerals = [
		...new Intl.NumberFormat(locales, { useGrouping: false }).format(9876543210),
	].reverse()
	const numeral = new RegExp(`[${numerals.join('')}]`, 'g')
	const index = d => new Map(numerals.map((d, i) => [d, i])).get(d)

	const separators = getSeparators(locales)

	string = string
		.trim()
		.replace(new RegExp(`[${separators.group}]`, 'g'), '')
		.replace(new RegExp(`[${separators.decimal}]`), '.')
		.replace(numeral, index)
	let number = +string
	for (let i = string.length - 1; i > 0 && isNaN(number); i--) {
		string = string.substring(0, i)
		number = +string
	}
	return isNaN(number) ? fallback : number
}

function getSeparators(locales) {
	let decimal = '.'
	let group = ','
	const numberFormat = new Intl.NumberFormat(locales)
	if (numberFormat.formatToParts) {
		const parts = new Intl.NumberFormat(locales).formatToParts(12345.6)
		decimal = parts.find(d => d.type === 'decimal').value
		group = parts.find(d => d.type === 'group').value
	} else {
		// for Safari 12 or earlier, which does not implement formatToParts
		decimal = (1.1).toLocaleString(locales).substring(1, 2)
		let potentialGroup = (1000).toLocaleString(locales).substring(1, 2)
		if (potentialGroup !== '0') group = potentialGroup
	}
	return { decimal, group }
}
