<template>
	<div style="padding: 16px;">
		<p style="margin-bottom: 8px;">
			{{ client.name }}
		</p>
		<p>
			<span class="clinic">{{ client.organization }}</span>
			<br />
			<span v-if="client.address" v-html="client.address"></span>
			<template v-if="cityStateZip">
				<br />
				<span>{{ cityStateZip }}</span>
			</template>
		</p>
		<p style="margin-top: 8px;">
			<span v-if="client.phone">
				<a :href="`tel:${client.phone}`" target="hidden-iframe">{{ client.phone }}</a>
				<br />
			</span>
			<a :href="`mailto:${client.email}`" target="hidden-iframe">{{ client.email }}</a>
		</p>
	</div>
</template>

<script>
import { Cell } from '../classes'
import { ContextMeta } from '../context'
import { toCamelCase } from '@utils/stringUtils'

export default {
	name: 'ReferredBy',
	props: ['cell'],
	computed: {
		client() {
			if (this.cell.template.editorMode) {
				// use ContextMeta sample data
				let client = {}
				Object.keys(ContextMeta.ReferredBy.items).forEach(k => {
					client[toCamelCase(k)] = ContextMeta.ReferredBy.items[k].sample
				})
				return client
			}
			return this.cell.report.client
		},
		cityStateZip() {
			let cityStateZip = this.client.city
			if (this.client.city && this.client.state) cityStateZip += ', '
			cityStateZip += `${this.client.state} ${this.client.postalCode}`
			return cityStateZip.trim()
		},
	},
}
</script>
