<template>
	<g>
		<text-annotation :group-id="groupId" :anchor="pointerPosition" :value="hoveredVoxelValue" />
	</g>
</template>

<script>
import TextAnnotation from './TextAnnotation'
import { baseMixin } from './mixins'

export default {
	name: 'ProbeAnnotation',
	components: {
		TextAnnotation,
	},
	mixins: [baseMixin],
	computed: {
		hoveredVoxelValue() {
			const { x, y } = this.pointerPosition
			const vox = this.manager.getVoxelValueFromScreenCoord(x, y)
			return `${isNaN(vox) ? '' : `${this.$options.filters.formatNumber(vox)} HU`}`
		},
	},
	methods: {
		onInteractionBegin() {
			this.drag.origin = true
		},
		onInteractionEnd() {
			this.drag.origin = false
			this.$emit('delete', this)
		},
		getTextAnchorData() {
			return {
				points: [this.pointerPosition],
				anchor: this.pointerPosition,
				alignVertical: 'middle',
			}
		},
	},
}
</script>
