import Canvas from '@store/models/state/Canvas'

const maxCanvases = 20

export const defaultInitCanvases = function() {
	let canvases = []
	for (let i = 0; i < maxCanvases; i++) {
		canvases.push(new Canvas({ index: i }))
	}
	return canvases
}
export const newConsultationRequest = function() {
	return {
		subject: '',
		sentBy: '',
		sentOn: '',
		message: '',
		fromEmail: '',
		toEmail: '',
		keystoneEndpointBaseUrl: '',
		hasDeletedStudies: false,
		studies: [],
		reports: [],
	}
}
