import macro from 'vtk.js/Sources/macro'
import vtkCompositeMouseManipulator from 'vtk.js/Sources/Interaction/Manipulators/CompositeMouseManipulator'

// ----------------------------------------------------------------------------
// vtkRotateSliceManipulator methods
// ----------------------------------------------------------------------------

function vtkRotateSliceManipulator(publicAPI, model) {
	// Set our className
	model.classHierarchy.push('vtkRotateSliceManipulator')

	publicAPI.onButtonDown = (interactor, renderer, position) => {
		model.previousPosition = position
	}

	publicAPI.onMouseMove = (interactor, renderer, position) => {
		if (!position || !model.previousPosition) {
			return
		}

		const onRotateCallback = publicAPI.getOnRotateCallback()

		if (onRotateCallback) {
			const screenDensity = window.devicePixelRatio || 1
			// This arbitrary scale seems to work well for value differences
			const dx = (model.previousPosition.x - position.x) / (model.arbitraryScale * screenDensity)
			const dy = (model.previousPosition.y - position.y) / (model.arbitraryScale * screenDensity)

			onRotateCallback({ x: dx, y: dy })
		}

		model.previousPosition = position
	}
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
	arbitraryScale: 3,
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
	Object.assign(model, DEFAULT_VALUES, initialValues)

	// Inheritance
	macro.obj(publicAPI, model)
	vtkCompositeMouseManipulator.extend(publicAPI, model, initialValues)

	macro.setGet(publicAPI, model, ['onRotateCallback', 'arbitraryScale'])

	// Object specific methods
	vtkRotateSliceManipulator(publicAPI, model)
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkRotateSliceManipulator')

// ----------------------------------------------------------------------------

export default { newInstance, extend }
