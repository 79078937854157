import router from '@router'
import store from '@store'
import { uploadData } from '@services/uploads'

class Workflow {
	canGoNext = false
	isLoading = false
	nextRoute = null
	lastRoute = null
	consultantId: string = null
	studyIds: string[]
	emails = []
	submitCB: () => Promise<boolean>

	reset() {
		this.consultantId = null
		this.studyIds = []
		this.emails = []
		this.submitCB = undefined
		this.lastRoute = null
	}

	get consultants(): IConsultant[] {
		return store.state.static.consultants
	}

	get isInProgress(): boolean {
		if (uploadData.haveUploadsForContext('teleconsultation-request')) return true
		if (router.currentRoute.path.includes('teleconsultation-request')) {
			const { query } = router.currentRoute
			return query && query.consultantId && !!query.studyId
		}
		return false
	}

	get consultant(): IConsultant {
		return this.consultants.find(c => c.id === this.consultantId)
	}
}

export const workflow = new Workflow()
export default workflow
// @ts-ignore
window.workflow = workflow
