import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { eventBus } from '@services/eventBus'
import { waitForEnabledElementImageToLoad } from '@/utils/wait.js'
import { syncActions, syncViewerActions } from '@store/plugins/vuexSyncPlugin'

const actionsToToggle = Object.keys(syncViewerActions)

// Set up Cornerstone Tools based on the current store state
// This is required because if the current browser window was launched
//  from another window, then our state was sourced from the other store
//  and the default Cornerstone settings may not agree with the current store
export async function startImageViewerSync({ state, dispatch, commit }) {
	const {
		overlayText,
		toolbarLocation,
		isImageOrientationMarkersEnabled,
		isLengthAnglesEnabled,
		isReferenceLineSynchronizationEnabled,
		isStackScrollSynchronizationEnabled,
		isManualStackScrollSynchronizationEnabled,
	} = state.settingsPanel

	await Promise.all(cornerstoneTools.store.state.enabledElements.map(waitForEnabledElementImageToLoad))

	commit('SET_OVERLAY_TEXT', overlayText)
	commit('SET_VIEWER_TOOLBAR_LOCATION', toolbarLocation)
	eventBus.on(eventBus.type.VIEWER_TOOLSTATE_SEND, sendExternalToolState)
	requestExternalToolState()
	dispatch('setImageOrientationMarkers', isImageOrientationMarkersEnabled)
	dispatch('setLengthAngles', isLengthAnglesEnabled)
	dispatch('setReferenceLineSynchronization', isReferenceLineSynchronizationEnabled)

	let stackSynchronizationMethod = 'auto'
	if (!isStackScrollSynchronizationEnabled && isManualStackScrollSynchronizationEnabled) {
		stackSynchronizationMethod = 'manual'
	} else if (!isStackScrollSynchronizationEnabled && !isManualStackScrollSynchronizationEnabled) {
		stackSynchronizationMethod = 'off'
	}
	dispatch('setStackSynchronizationMethod', stackSynchronizationMethod)
	dispatch('setImgActiveTools')
	// Start tracking actions from other windows
	actionsToToggle.forEach(key => {
		syncActions[key] = true
	})
}

export function stopImageViewerSync() {
	// Stop tracking actions from other windows to prevents errors
	actionsToToggle.forEach(key => {
		syncActions[key] = false
	})
}

export function calibrateFromExternalImage({ dispatch }, { imageId, oldLength, oldPixelSpacing, newLength }) {
	dispatch('updateCalibration', { imageId, oldLength, oldPixelSpacing, newLength })
	// Custom image object because we don't have it, but these are the items it needs
	const image = { imageId, ...oldPixelSpacing }
	cornerstoneTools.store.state.enabledElements.forEach(async element => {
		// Force cached stats update for all tool data
		const tools = cornerstoneTools.store.state.tools.filter(tool => tool.updateCachedStats)

		tools.forEach(tool => {
			const toolState = cornerstoneTools.getToolState(element, tool.name)
			if (toolState && toolState.data) {
				toolState.data.forEach(d => {
					tool.updateCachedStats(image, element, d)
				})
			}
		})
		cornerstone.updateImage(element)
	})
}

function requestExternalToolState() {
	eventBus.on(eventBus.type.VIEWER_TOOLSTATE_UPDATE, updateExternalToolState)
	eventBus.broadcast(eventBus.type.VIEWER_TOOLSTATE_SEND)
	// clean up in case no window responds
	setTimeout(eventBus.off, 200, eventBus.type.VIEWER_TOOLSTATE_UPDATE, updateExternalToolState)
}

function sendExternalToolState() {
	const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState()
	if (!toolState || !Object.keys(toolState).length) return // do not send empty state
	eventBus.broadcast(eventBus.type.VIEWER_TOOLSTATE_UPDATE, toolState)
}

function updateExternalToolState(toolState) {
	eventBus.off(eventBus.type.VIEWER_TOOLSTATE_UPDATE, updateExternalToolState)
	cornerstoneTools.globalImageIdSpecificToolStateManager.restoreToolState(toolState)
	cornerstoneTools.store.state.enabledElements.forEach(async element => {
		const isEnabled = await waitForEnabledElementImageToLoad(element)
		if (isEnabled) {
			activateToolsInToolState(toolState, element) // annotations will only appear if tool is active
			cornerstone.updateImage(element)
		}
	})
}

function activateToolsInToolState(toolState, element) {
	const toolsInEachImageToolState = Object.values(toolState).map(i => Object.keys(i))
	const toolsToActive = [...new Set(...[].concat(toolsInEachImageToolState))] // flatten and dedupe
	toolsToActive.forEach(toolName => {
		const tool = cornerstoneTools.getToolForElement(element, toolName)
		tool.mode = 'active'
	})
}

export function updateExternalToolStateForId({ state }, { imageId, toolName, toolState }) {
	// NOTE: once CornerstoneTools includes PR#1341, we can replace this with one line `replaceImageIdToolState()`
	const globalState = cornerstoneTools.globalImageIdSpecificToolStateManager.saveImageIdToolState(imageId) || {}
	globalState[toolName] = toolState
	cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(imageId, globalState)
	// redraw canvases
	cornerstone.getEnabledElementsByImageId(imageId).forEach(enabledElement => {
		cornerstone.updateImage(enabledElement.element)
	})
}
