<template>
	<button :type="type" :class="buttonClass" v-bind="$attrs" @click="$emit('click', $event)">
		<slot></slot>
	</button>
</template>

<script>
export default {
	name: 'Button',
	props: {
		type: {
			type: String,
			default: 'default',
		},
	},
  computed: {
    buttonClass() {
      let type= this.type
      if (type === 'button') {
        type = 'default'
      }
      return `btn btn-${type}`
    },
  },
}
</script>
