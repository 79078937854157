// setTimeout that supports durations longer than Int32 max via recursion

const MAX_INT32 = 2147483647
let currentId = 1
const fakeToRealIdMap = {}

export function setLongTimeout(callback: Function, ms: number, fakeId?: number): number {
	fakeId = fakeId || currentId++
	let realId: number
	if (ms < MAX_INT32) {
		// use native setTimeout for callback, and then clear this long timer's fakeId
		realId = setTimeout(() => {
			callback()
			clearLongTimeout(fakeId)
		}, ms)
	} else {
		// in MAX_INT32 milliseconds, start another timer for the remaining duration
		realId = setTimeout(() => {
			setLongTimeout(callback, ms - MAX_INT32, fakeId)
		}, MAX_INT32)
	}
	// store/update realId for our long timer's fakeId
	fakeToRealIdMap[fakeId.toString()] = realId
	return fakeId
}

export function clearLongTimeout(fakeId: number) {
	const realId: number = fakeToRealIdMap[fakeId.toString()]
	if (realId != null) {
		clearTimeout(realId)
		delete fakeToRealIdMap[fakeId.toString()]
	}
}
