<script>
import { mapMutations } from 'vuex'
import ModalWrapper from '@components/ModalWrapper'
import * as Sentry from '@sentry/browser'

export default {
	name: 'VideoPlayer',
	components: {
		ModalWrapper,
	},
	props: {
		src: {
			type: String,
			required: true,
		},
		imageId: {
			type: String,
			default: '',
		},
		isMuted: {
			// iOS requires muted for autoplay
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			videoLogId: '',
			viewingInterval: null,
		}
	},
	mounted() {
		this.startVideoViewing()

		this.$refs.video.addEventListener('playing', () => {
			this.setIsPlayingVideo(true)
		})
		this.$refs.video.addEventListener('pause', () => {
			this.setIsPlayingVideo(false)
		})
		this.$refs.video.addEventListener('ended', () => {
			window.dispatchEvent(new Event('mousemove')) // restart idle timer
			this.setIsPlayingVideo(false)
		})
		this.$refs.video.addEventListener('error', this.handleError)
	},
	beforeDestroy() {
		this.$refs.video.removeEventListener('error', this.handleError)
		this.$refs.video.pause()
		this.$refs.video.setAttribute('src', '') // close connection
		this.setIsPlayingVideo(false)

		clearInterval(this.viewingInterval)
		this.updateVideoViewingLog()
	},
	methods: {
		...mapMutations(['setIsPlayingVideo']),
		handleError(err) {
			Sentry.captureException(this.$refs.video.error || err)
			this.$notifications.addError('An error occurred while loading the video.  Please try again later.')
			this.$emit('close')
		},
		async startVideoViewing() {
			if (this.imageId && this.$store.state.repository.viewingId) {
				const response = await this.$api.repository.startVideoViewing({
					sessionLogId: this.$store.state.repository.viewingId,
					imageId: this.imageId,
          endoscopyDisclosure: this.$store.state.repository.endoscopyDisclosure
				})
				this.videoLogId = response.data
				this.viewingInterval = setInterval(this.updateVideoViewingLog, 5000)
			}
		},
		async updateVideoViewingLog() {
			if (!this.$store.state.repository.viewingId || this.$route.name !== 'consigner-studies-viewer') {
				clearInterval(this.viewingInterval)
				return
			}
			if (this.videoLogId) {
				await this.$api.repository.updateVideoViewing({
					imageViewLogId: this.videoLogId,
				})
			}
		},
	},
	render(createElement) {
		return createElement(
			ModalWrapper,
			{
				on: {
					close: () => this.$emit('close'),
				},
			},
			[
				createElement(
					'div',
					{
						class: {
							'video-content': true,
						},
					},
					[
						createElement('video', {
							ref: 'video',
							attrs: {
								autoplay: true,
								playsinline: true,
								controls: true,
								controlsList: 'nodownload',
								muted: this.isMuted,
								poster:
									'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB4AAAAQ4AQMAAADSHVMAAAAAA1BMVEUAAACnej3aAAABRUlEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGD24EAAAAAAAMj/tRFUVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVYU9OBAAAAAAAPJ/bQRVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVWEPDgQAAAAAgPxfG0FVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVdqDAxIAAAAAQf9f9yNUAAAAAAAAAAAAAAAAAACAqQD45QABkNb8FAAAAABJRU5ErkJggg==',
								src: this.src,
							},
						}),
					]
				),
			]
		)
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.video-content {
	video {
		max-width: 90vw;
		max-height: 90vh;
	}
}
</style>
