<template>
	<div
		v-if="services.length || cell.template.editorMode"
		class="input-group"
		style="display: inline-flex; align-items: center; padding: 4px 0;"
	>
		<label for="service" style="padding-right: 1ch;">
			Service:
			<small class="is-danger">*</small>
		</label>
		<span v-if="cell.readOnly">
			{{ value }}
		</span>
		<v-select
			v-else
			v-model="value"
			name="service"
			label="name"
			:reduce="service => service.id"
			:options="services"
			:searchable="false"
			:clearable="false"
			style="display: inline-block; min-width: 150px;"
		/>
	</div>
</template>

<script>
import { Cell } from '../classes'

export default {
	name: 'ServiceSelector',
	components: {},
	props: ['cell'],
	computed: {
		services() {
			return (this.cell.report && this.cell.report.services) || []
		},
		value: {
			get() {
				return this.cell.report && this.cell.report.serviceId
			},
			set(value) {
				this.cell.report.serviceId = value
			},
		},
	},
	created() {
		if (this.services.length > 0) {
			this.cell.report.serviceId = this.services[0].id
		}
	},
}
</script>
