import reportService from '@services/reportService'
import workflow from '@services/workflow'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { getActiveDownloads } from '@components/DesktopDownloader.vue'

export default async function() {
	if (workflow.isInProgress) {
		const warning = 'Your submission is not complete.  Are you sure you want to discard your progress?'
		if (!(await showConfirm(warning))) return false
	}
	if (reportService.hasUnsavedChanges) {
		const warning = 'You have unsaved changes.  Are you sure you want to leave without saving?'
		if (!(await showConfirm(warning))) return false
	}
	if (window.ElectronInterface) {
		const activeDownloads = getActiveDownloads()
		if (activeDownloads > 0) {
			const warning =
				`Your study ` +
				`${activeDownloads === 1 ? 'download' : 'downloads'} will be cancelled. ` +
				`Are you sure want to leave?`
			if (!(await showConfirm(warning))) return false
		}
	}
	return true
}
