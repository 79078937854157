<template>
	<g>
		<line
			v-if="showConnectorLine"
			:x1="nearestPositionOnRect.x"
			:y1="nearestPositionOnRect.y"
			:x2="end.x"
			:y2="end.y"
			:style="lineStyle"
		/>
		<drag-annotation
			:id="id"
			:value="start"
			:group-id="groupId"
			@input="onDragInput"
			@drag-start="onDragStart"
			@drag-end="onDragEnd"
		>
			<svg :x="textPosition.x" :y="textPosition.y" style="overflow:visible" :style="groupStyle" data-position-ref>
				<rect :x="0" :y="0" rx="2" ry="2" :width="textWidth" :height="textHeight" :style="rectStyle" />
				<rect
					v-dblclick="e => onDoubleClick(e)"
					:data-id="`${id}`"
					:data-group-id="groupId"
					:x="0"
					:y="0"
					rx="2"
					ry="2"
					:width="textWidth"
					:height="textHeight"
					:style="fillHitTargetStyle"
				/>
				<text
					v-for="(tv, i) in textValues"
					:key="i"
					ref="textValues"
					:x="5"
					:y="`${i * lineHeight + 16}px`"
					:style="textStyle"
					v-html="tv"
				/>
			</svg>
		</drag-annotation>
	</g>
</template>

<script>
import DragAnnotation from './DragAnnotation'
import { startEndMixin } from './mixins'
import isArray from 'lodash/isArray'
import clamp from '@vtk/lib/math/clamp'
import { openPromptDlg } from '@dialogs/TextPromptDlg'

export default {
	name: 'TextAnnotation',
	components: {
		DragAnnotation,
	},
	mixins: [startEndMixin],
	props: {
		value: {
			type: null,
			default: '',
		},
		anchor: {
			type: Object,
			default: () => null,
		},
		eventSource: {
			type: Object,
			default: () => null,
		},
		lineHeight: {
			type: Number,
			default: 22,
		},
		editable: Boolean,
	},
	data() {
		return {
			text: this.value,
			textHasPrompted: false,
			textWidth: 0,
			textHeight: 0,
			detached: false,
		}
	},
	computed: {
		eventSource_() {
			return this.eventSource || this
		},
		isFree() {
			return this.eventSource_ === this
		},
		hasSize() {
			// Need to override so that annotation manager doesn't delete when free
			return true
		},
		textPosition() {
			let point = { x: 0, y: 0 }
			if (this.start.x && this.start.y) {
				point = { ...this.start }
			}
			const { alignVertical = 'middle' } = this.eventSource_?.getTextAnchorData() || {}
			switch (alignVertical) {
				case 'top':
					point.x += 5
					point.y -= 8
					break
				case 'middle':
					point.x += 5
					point.y -= this.textHeight / 2.0
					break
				case 'bottom':
					point.x += 5
					point.y -= this.textHeight
					break
			}
			return point
		},
		nearestPositionOnRect() {
			return this.getNearestPositionOnRect(this.end)
		},
		textValues() {
			if (isArray(this.text)) {
				return this.text
			}
			if (this.text) {
				return this.text.toString().split('\n')
			}
			return []
		},
		showConnectorLine() {
			if (!this.nearestPositionOnRect || !this.end || !this.detached) return false
			return this.displayDistanceBetween(this.nearestPositionOnRect, this.end) > 10
		},
		rectStyle() {
			return {
				fill: 'black',
				opacity: 0.7,
			}
		},
		lineStyle() {
			return {
				...this.strokeStyle,
				'stroke-dasharray': '5 3',
			}
		},
	},
	watch: {
		value(val) {
			this.text = val
		},
		text(val) {
			this.$emit('input', val)
		},
		anchor: {
			immediate: true,
			handler(val) {
				this.start = { ...val }
				this.end = { ...val }
			},
		},
		eventSource_: {
			immediate: true,
			handler(val, oldVal) {
				if (oldVal) {
					val.$off('translate', this.onSourceTranslate)
					val.$off('resize', this.onSourceResize)
				}
				if (val) {
					val.$on('translate', this.onSourceTranslate)
					val.$on('resize', this.onSourceResize)
				}
			},
		},
		lineHeight() {
			this.refreshDimensions()
		},
		textValues() {
			this.refreshDimensions()
		},
		'drag.start'(val) {
			if (val && !this.isFree) {
				this.detached = true
			}
		},
	},
	mounted() {
		this.refreshDimensions()
		this.adjustToAnchorPoints()
		if (!this.text && this.isFree) this.promptForAnnotationText()
	},
	methods: {
		onDragStart() {
			if (this.detached) {
				this.drag.start = true
			} else {
				this.draggingAll = true
			}
		},
		onDragEnd() {
			if (this.detached) {
				this.drag.start = false
			} else {
				this.draggingAll = false
			}
		},
		onDragInput(val) {
			this.start = val
			this.adjustToAnchorPoints()
		},
		onSourceTranslate({ deltaX, deltaY }) {
			const { x, y } = this.position
			this.position = { x: x + deltaX, y: y + deltaY }
		},
		onSourceResize() {
			this.adjustToAnchorPoints()
		},
		adjustToAnchorPoints() {
			const { points, anchor } = this.eventSource_.getTextAnchorData()
			if (this.detached) {
				const point = this.getPointClosestToRect(points)
				if (point) {
					this.end = point
				}
			} else if (anchor) {
				this.start = anchor
				this.end = anchor
			}
		},
		getPointClosestToRect(points) {
			return points.reduce(
				(prev, curr) => {
					const point = this.getNearestPositionOnRect(curr)
					const distance = this.displayDistanceBetween(curr, point)
					if (!prev.point || !prev.distance || distance < prev.distance) {
						return { point: curr, distance }
					}
					return prev
				},
				{ point: null, distance: 0 }
			).point
		},
		getNearestPositionOnRect(
			fromPoint,
			rectPosition = this.textPosition,
			rectWidth = this.textWidth,
			rectHeight = this.textHeight
		) {
			if (!fromPoint || !this.textPosition) {
				return { x: 0, y: 0 }
			}
			let x = clamp(fromPoint.x, rectPosition.x, rectPosition.x + rectWidth)
			let y = clamp(fromPoint.y, rectPosition.y, rectPosition.y + rectHeight)
			if (x === rectPosition.x || x === rectPosition.x + rectWidth) {
				y = rectPosition.y + 0.5 * rectHeight
			}
			if (y === rectPosition.y || y === rectPosition.y + rectHeight) {
				x = rectPosition.x + 0.5 * rectWidth
			}
			return { x, y }
		},
		refreshDimensions() {
			this.$nextTick(() => {
				let textWidth = 0
				let textHeight = 0
				if (this.$refs.textValues && this.$refs.textValues.length) {
					textHeight = this.textValues.length * this.lineHeight
					this.$refs.textValues.forEach(t => {
						const bbox = t.getBBox()
						textWidth = Math.max(textWidth, bbox.width)
					})
				}
				this.textWidth = textWidth + 10
				this.textHeight = textHeight
			})
		},
		onDoubleClick() {
			this.detached = false
			this.adjustToAnchorPoints()
		},
		async promptForAnnotationText() {
			const input = await openPromptDlg({ title: 'Annotation', prompt: 'Enter your annotation:' })
			if (input) {
				this.text = input
			}
		},
	},
}
</script>
