<template>
	<div class="dialog">
		<modal-dialog
			:actions="actions"
			:is-open="true"
			:title="title"
			:close-text="cancelText"
			:show-close-header="showCloseHeader"
			data-test="confirm-dialog"
			@close="close"
			@confirm="confirm"
		>
			<div v-html="message"></div>
		</modal-dialog>
	</div>
</template>

<script>
import ModalDialog from '@components/ModalDialog.vue'
import AstInput from '@components/Input.vue'
import { dlg } from '@utils/dlgUtils'

const ConfirmDlg = {
	name: 'ConfirmDlg',
	components: {
		AstInput,
		ModalDialog,
	},
	props: {
		confirmText: {
			type: String,
			default: 'OK',
		},
		cancelText: {
			type: String,
			default: 'Cancel',
		},
		message: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
		showCloseHeader: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		actions() {
			if (!this.confirmText) return []
			return [
				{
					type: 'button',
					name: this.confirmText,
					emit: 'confirm',
				},
			]
		},
	},
	methods: {
		close() {
			dlg.close(this, true, false)
		},
		confirm() {
			dlg.close(this, true, true)
		},
	},
}

export default ConfirmDlg

/**
 * Returns a promise that resolves to true/false for confirm and close, respectively
 * @returns Promise<boolean>
 */
export function showConfirm(message, { confirmText, cancelText, title, showCloseHeader } = {}) {
	return dlg.open(ConfirmDlg, { confirmText, cancelText, message, title, showCloseHeader }, false)
}
</script>
