import api from '@services/api'
import clone from '@utils/deepClone'

const emptyState = {
	search: {
		lotNumber: '',
		saleId: '',
		label: '',
		status: '',
	},
	sort: {
		order: {
			by: 'lotNumber',
			isAscending: true,
		},
		currentPage: 0,
		resultsPerPage: 50,
	},
	activeSales: [],
}

const state = clone(emptyState)

const mutations = {
	updateSaleSubmissionSort(state, changes) {
		if (!('currentPage' in changes)) changes.currentPage = 0
		state.sort = Object.assign(state.sort, changes)
	},
	updateSaleSubmissionSearch(state, changes) {
		state.search = Object.assign(state.search, changes)
	},
	updateSaleSubmissionData(state, data) {
		state = Object.assign(state, data)
	},
	CLEAR_SALESUBMISSION_DATA(state) {
		state.activeSales = []
		state.sort = clone(emptyState.sort)
		state.search = clone(emptyState.search)
	},
}

const actions = {
	async getSaleSubmissionData({ commit, dispatch }) {
		const activeSales = await api.saleSubmission.getSales()
		if (activeSales) {
			commit('updateSaleSubmissionData', { activeSales })
			dispatch('initializeSearch')
		}
	},
	initializeSearch({ commit, state }) {
		if (!state.activeSales.length) return
		// default to first sale if no sale is selected, or if selected saleId is invalid
		if (!state.search.saleId || !state.activeSales.find(x => x.id === state.search.saleId)) {
			commit('updateSaleSubmissionSearch', { saleId: state.activeSales[0].id })
		}
	},
}

export default {
	state,
	mutations,
	actions,
}
