// prettier-ignore
import {
	BLEND_MIP,
	LEVEL_TOOL,
	PAN_TOOL,
	ZOOM_TOOL,
	BUTTONS,
	TOP,
	LEFT,
	FRONT,
} from '@vtk/VTKViewport/consts'
import tools from './tools.js'
import layouts from './layouts.json'

const generateSingleViewData = options => ({
	color: '#bbbbbb',
	slicePlaneNormal: [0, 0, 1],
	sliceViewUp: [0, -1, 0],
	slicePlaneXRotation: 0,
	slicePlaneYRotation: 0,
	flipH: false,
	flipV: false,
	flipRX: false,
	flipRY: false,
	viewRotation: 0,
	sliceThickness: 0.1,
	blendMode: BLEND_MIP,
	window: {
		width: 0,
		center: 0,
	},
	active: false,
	visible: true,
	position: 1,
	image: null,
	...options,
})

const defaultViewData = JSON.stringify({
	[TOP]: generateSingleViewData({
		color: '#F8B42C',
		slicePlaneNormal: [0, 0, 1],
		sliceViewUp: [0, -1, 0],
		position: 1,
		active: true,
	}),
	[LEFT]: generateSingleViewData({
		color: '#A62CF8',
		slicePlaneNormal: [-1, 0, 0],
		sliceViewUp: [0, -1, 0],
		position: 2,
		flipRX: true,
	}),
	[FRONT]: generateSingleViewData({
		color: '#2C92F8',
		slicePlaneNormal: [0, -1, 0],
		sliceViewUp: [0, 0, -1],
		position: 3,
		flipRY: true,
	}),
})

export const getDefaultViewData = () => JSON.parse(defaultViewData)

const defaultSeriesData = JSON.stringify({
	seriesId: '',
	modality: '',
	active: false,
	switchModeActive: false,
	isLoading: false,
	loadProgress: -1,
	layout: layouts[0],
	blendMode: BLEND_MIP,
	viewData: getDefaultViewData(),
	volumeData: null,
	isJpeg: false,
	volumeDirection: [1, 0, 0, 0, 1, 0, 0, 0, 1],
	sliceDirectionality: 1,
	defaultVoi: {
		windowWidth: 0,
		windowCenter: 0,
	},
	// Action Callback funcs for toolbar or keyboard binding.
	onReset: null,
	onInvert: null,
	onMoveSlice: null,
})

export const getDefaultSeriesData = () => JSON.parse(defaultSeriesData)

const state = {
	active: false,
	activeSeries: null,
	showOverlayText: true,
	showImageOrientationMarkers: true,
	syncWindowLevels: false,
	showAxisOverlay: true,
	tools,
	availableTools: tools,
	activeTools: {
		[BUTTONS.LEFT_MKEY]: LEVEL_TOOL,
		[BUTTONS.MIDDLE_MKEY]: PAN_TOOL,
		[BUTTONS.RIGHT_MKEY]: ZOOM_TOOL,
	},
	issues: {},
	layouts,
}

export default state
