<script>
import { baseMixin } from './mixins'

const dragState = {
	ready: 'READY',
	dragging: 'DRAGGING',
}

const _handles = {
	desktop: {
		default: {
			displayRadius: '6px',
			hitRadius: '15px',
		},
		precision: {
			displayRadius: '50px',
			hitRadius: '54px',
		},
	},
	mobile: {
		default: {
			displayRadius: '6px',
			hitRadius: '25px',
		},
		precision: {
			displayRadius: '50px',
			hitRadius: '56px',
		},
	},
}

export default {
	mixins: [baseMixin],
	props: {
		value: {
			type: Object,
			default: () => null,
		},
		dragOnLoad: Boolean,
		minDragTime: {
			type: Number,
			default: 300,
		},
		minDragDistance: {
			type: Number,
			default: 5,
		},
		showPrecision: Boolean,
	},
	data() {
		return {
			state: dragState.ready,
			origin: null,
			originDateTime: null,
			start: null,
			end: null,
			precisionActive: false,
			draggingOnLoad: this.dragOnLoad,
		}
	},
	computed: {
		handleActive() {
			return this.manager.activeHandleId === this.id
		},
		isReady() {
			return this.state === dragState.ready
		},
		isDragging() {
			return this.state === dragState.dragging
		},
		xDragDistance() {
			if (!this.start || !this.end) return 0
			return this.end.x - this.start.x
		},
		yDragDistance() {
			if (!this.start || !this.end) return 0
			return this.end.y - this.start.y
		},
		handleStyle() {
			return {
				cursor: 'pointer',
				stroke: this.color,
				strokeWidth: '1.5px',
				fill: 'transparent',
			}
		},
		handles() {
			let handles = this.isMobileOS ? _handles.mobile : _handles.desktop
			return this.precisionActive ? handles.precision : handles.default
		},
		displayRadius() {
			return this.handles.displayRadius
		},
		hitRadius() {
			return this.handles.hitRadius
		},
	},
	watch: {
		handleActive(val) {
			if (!val) {
				this.precisionActive = false
			}
		},
		displayRadius: {
			immediate: true,
			handler(val) {
				this.$emit('radius-updated', val)
			},
		},
	},
	mounted() {
		if (this.dragOnLoad) {
			this.$nextTick(() => {
				this.startDragging()
			})
		}
	},
	methods: {
		onInteractionBegin(e) {
			this.startDragging()
		},
		onInteractionUpdate() {
			switch (this.state) {
				case dragState.dragging:
					this.updateDrag()
					break
			}
		},
		onInteractionEnd() {
			switch (this.state) {
				case dragState.dragging:
					this.endDrag()
					break
			}
		},
		startDragging() {
			this.state = dragState.dragging
			this.start = { ...this.pointerPosition }
			this.end = { ...this.pointerPosition }
			this.origin = { ...this.value }
			this.originDateTime = new Date().getTime()
			this.manager.activeHandleId = this.id
			this.$emit('drag-start', { ...this.start })
		},
		updateDrag() {
			this.end = { ...this.pointerPosition }
			this.$emit('input', {
				x: this.origin.x + this.xDragDistance,
				y: this.origin.y + this.yDragDistance,
			})
		},
		endDrag() {
			if (this.isReady) return
			this.$emit('drag-end', { ...this.end })
			this.state = dragState.ready
			if (this.start.x === this.end.x && this.start.y === this.end.y && !this.draggingOnLoad) {
				this.togglePrecisionActive()
			}
			this.start = null
			this.end = null
			this.origin = null
			this.originDateTime = null
			this.draggingOnLoad = false
		},
		draggedMinDistance() {
			const dragDistance = this.displayDistanceBetween(this.start, this.end)
			return dragDistance >= this.minDragDistance
		},
		draggedMinTime() {
			const now = new Date().getTime()
			return now - this.originDateTime >= this.minDragTime
		},
		togglePrecisionActive() {
			this.precisionActive = this.showPrecision && !this.precisionActive
		},
	},
	render(h) {
		if (!this.value) {
			return undefined
		}
		const renderHandle = () => {
			return h('g', [
				// Display circle
				h('circle', {
					style: {
						...this.strokeStyle,
						'stroke-dasharray': '',
					},
					attrs: {
						'data-group-id': this.groupId,
						cx: this.value.x,
						cy: this.value.y,
						r: this.displayRadius,
					},
				}),
				// Hit target circle
				h('circle', {
					style: this.fillHitTargetStyle,
					attrs: {
						'data-id': this.id,
						'data-group-id': this.groupId,
						cx: this.value.x,
						cy: this.value.y,
						r: this.hitRadius,
					},
				}),
			])
		}

		const children = []
		const hasSlot = !!this.$scopedSlots.default
		if (hasSlot) {
			children.push(
				this.$scopedSlots.default({
					dragId: this.id,
					position: this.value,
					isDragging: this.isDragging,
					xDragDistance: this.xDragDistance,
					yDragDistance: this.yDragDistance,
				})
			)
		}
		if (!hasSlot || (this.showPrecision && this.precisionActive)) {
			children.push(renderHandle())
		}

		return h('g', children)
	},
}
</script>
