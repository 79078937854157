<template>
	<div class="dialog">
		<modal-dialog
			:actions="actions"
			:is-open="true"
			:title="title"
			close-text="Cancel"
			@close="cancel"
			@focus="action('focus')"
			@add="action('add')"
			@replace="action('replace')"
			@new="action('new')"
		>
			<template v-if="forExistingStudy">
				<div>
					<p>An Image Viewer window with this study is already open.</p>
					<p>
						Would you like to view this study in the existing Window or open this study in a new Viewer Window?
					</p>
				</div>
			</template>
			<template v-else>
				<div>
					<p>An Image Viewer window is already open.</p>
					<p>
						Would you like to add this study to the last opened Window, close all Viewer Windows before opening, or open
						this study in a new Viewer Window?
					</p>
				</div>
			</template>
		</modal-dialog>
	</div>
</template>

<script>
import ModalDialog from '@components/ModalDialog.vue'
import AstInput from '@components/Input.vue'
import { dlg } from '@utils/dlgUtils'

const OpenStudyOptionsDlg = {
	name: 'OpenStudyOptionsDlg',
	components: {
		AstInput,
		ModalDialog,
	},
	props: {
		forExistingStudy: { type: Boolean, default: false },
		forReport: { type: Boolean, default: false },
	},
	computed: {
		title() {
			const existing = this.forExistingStudy ? 'Existing ' : ''
			return this.forReport ? `Open ${existing}Report Study` : `Open ${existing}Study`
		},
		actions() {
			if (this.forExistingStudy) {
				return [
					{
						type: 'button',
						name: 'Focus Existing Viewer',
						emit: 'focus',
					},
					{
						type: 'button',
						name: 'Open New Viewer',
						emit: 'new',
					},
				]
			}
			return [
				{
					type: 'button',
					name: 'Add to Open Viewer',
					emit: 'add',
				},
				{
					type: 'button',
					name: 'Close Existing Studies',
					emit: 'replace',
				},
				{
					type: 'button',
					name: 'Open New Viewer',
					emit: 'new',
				},
			]
		},
	},
	methods: {
		cancel() {
			dlg.close(this, true, false)
		},
		action(a) {
			dlg.close(this, true, a)
		},
	},
}

export default OpenStudyOptionsDlg

export function showStudyOptions(props) {
	return dlg.open(OpenStudyOptionsDlg, props, false)
}
</script>
