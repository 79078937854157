<template>
	<modal-wrapper @close="close()">
		<div id="copy-image-dlg" class="dialog">
			<div class="directions"
				>Right Click and Select Copy Image<span style="flex-grow:1"></span>
				<svg-icon style="cursor:pointer" icon="close" @click.native="close()" />
			</div>
			<div style="flex; flex-grow:1; overflow:hidden; justify-content:center; width: 100%;text-align: center;">
				<img :src="imageSrc" />
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'

const CopyImageDlg = {
	name: 'CopyImageDlg',
	components: {
		ModalWrapper,
	},
	props: ['imageSrc'],
	methods: {
		close() {
			dlg.close(this)
		},
	},
}

export default CopyImageDlg

export function OpenCopyImageDlg(imageSrc) {
	return dlg.open(CopyImageDlg, { imageSrc })
}
</script>

<style lang="scss">
#copy-image-dlg {
	width: 90%;
	height: 90%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 0;
	align-items: center;
	background: #111;

	.directions {
		display: flex;
		background: yellow;
		padding: 1em;
		width: 100%;
		flex-shrink: 0;
	}

	img {
		height: 100%;
	}
}
</style>
