<template>
	<div v-if="volumeData" class="viewer2d" :class="{ active: isActive }">
		<div
			ref="container"
			v-dblclick="onDblClick"
			class="container2d"
			@mousedown="onMouseDown"
			@touchstart="onTouchStart"
			@click="onClick"
		>
			<annotation-manager
				v-if="renderer"
				ref="annotationManager"
				:width="width"
				:height="height"
				:active="isActive"
				:renderer="renderer"
				:volume-data="volumeData"
				:slice="currentSlice"
				:slice-thickness="sliceThickness"
				:rotation="viewRotation"
			/>
		</div>
		<m-p-r-interactor
			v-if="showAxis"
			:width="width"
			:height="height"
			:x-axis="xAxis"
			:y-axis="yAxis"
			:flip-h="flipH"
			:flip-v="flipV"
			:view-rotation="viewRotation"
			:point="screenCoordSliceIntersection"
			:should-animate="shouldAnimate"
			@rotate="onRotate"
			@thickness="onThickness"
			@moveaxis="onMoveAxis"
			@wheel.native.prevent="onWheel"
		/>
		<viewport-overlay
			:active="isActive"
			:color="viewColor"
			:show-overlay-text="showOverlayText"
			:show-image-orientation-markers="showImageOrientationMarkers"
			@toggle-fullscreen="onToggleFullscreen"
		>
			<template #top-left>
				<ul>
					<li>Slice: {{ stackSliceDisplay }}</li>
					<li>View: {{ overlayInformation.fullName || overlayInformation.simpleName }}</li>
				</ul>
			</template>
			<template #top-right>
				<ul>
					<li>Name: {{ overlayInformation.patientName }}</li>
					<li>Id: {{ overlayInformation.patientId }}</li>
					<li>Owner: {{ overlayInformation.ownerName }}</li>
					<li>
						Institution:
						{{ overlayInformation.institutionName }}
					</li>
					<li>
						Study Date:
						{{ overlayInformation.studyDate | localizeDate({ forceUTC: false }) }}
					</li>
					<li v-if="overlayInformation.acquisitionTime">
						Acq Time: {{ overlayInformation.acquisitionTime | localizeDate({ forceUTC: false }) }}
					</li>
				</ul>
			</template>
			<template #bottom-left>
				<ul>
					<li v-if="sliceThickness >= 1">Slice Thickness: {{ sliceThickness | round | formatNumber }} mm</li>
					<li>{{ wwwc }}</li>
				</ul>
			</template>
			<template #bottom-right>
				Rotation Angle: {{ viewRotation }}&deg;
			</template>
			<template #top>
				{{ compass.top }}
			</template>
			<template #right>
				{{ compass.right }}
			</template>
			<template #bottom>
				{{ compass.bottom }}
			</template>
			<template #left>
				{{ compass.left }}
			</template>
		</viewport-overlay>
	</div>
</template>

<script>
/**
 * This view is a simple wrapper for the VTK canvas, returning the VTK canvas component information through a callback during the `mounted` event.
 */

import vtkGenericRenderWindow from 'vtk.js/Sources/Rendering/Misc/GenericRenderWindow'
import vtkCoordinate from 'vtk.js/Sources/Rendering/Core/Coordinate'
import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume'
import vtkVolumeMapper from 'vtk.js/Sources/Rendering/Core/VolumeMapper'
import { quat, vec3 } from 'gl-matrix'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import round from 'lodash/round'

// Use new single MPRSlice interactor with Manipulators
import vtkInteractorStyleMPR from './vtkInteractorStyleMPR'

import ViewportOverlay from '../ViewportOverlay/ViewportOverlay.vue'
import AnnotationManager from '../Annotations/AnnotationManager.vue'
import MPRInteractor from '../ViewportOverlay/MPRInteractor.vue'

import { BLEND_MIP, BLEND_MINIP, BLEND_AVG, BLEND_NONE, TOP, LEFT, FRONT } from './consts'
import { createSub } from '../lib/createSub.js'
import { degrees2radians } from '../lib/math/angles.js'

export default {
	name: 'View2dMpr',
	components: { ViewportOverlay, AnnotationManager, MPRInteractor },
	filters: {
		round(val) {
			return round(val, 1)
		},
	},
	props: {
		volumeData: { type: Object, required: true },
		volumeDirection: {
			type: Array,
			required: true,
		},
		views: { type: Object, required: true },
		parallel: { type: Boolean, default: true },
		// Front, Side, Top, etc, for which view data to use
		index: { type: String, required: true },
		sliceIntersection: {
			type: Array,
			default() {
				return [0, 0, 0]
			},
		},
		onCreated: { type: Function, default: () => null },
		onDestroyed: { type: Function, default: () => null },
		overlayInformation: { type: Object, required: true },
		shouldAnimate: {
			type: Boolean,
			default: true,
		},
		showAxis: {
			type: Boolean,
			default: true,
		},
		showOverlayText: {
			type: Boolean,
			default: true,
		},
		showImageOrientationMarkers: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			width: 0,
			height: 0,
			renderer: null,
			volumes: [],
			subs: {
				interactor: createSub(),
				data: createSub(),
			},
			compass: {
				top: '',
				right: '',
				bottom: '',
				left: '',
			},
			currentSlice: 0,
			stackSliceDisplay: '',
			xAxis: { color: '', rotation: 0, thickness: 0 },
			yAxis: { color: '', rotation: 0, thickness: 0 },
			screenCoordSliceIntersection: false,
		}
	},
	computed: {
		title() {
			return `temp: ${this.index}`
		},
		// Cribbed from the index and views
		slicePlaneNormal() {
			return this.views[this.index].slicePlaneNormal
		},
		slicePlaneXRotation() {
			return this.views[this.index].slicePlaneXRotation
		},
		slicePlaneYRotation() {
			return this.views[this.index].slicePlaneYRotation
		},
		sliceViewUp() {
			return this.views[this.index].sliceViewUp
		},
		viewRotation() {
			return this.views[this.index].viewRotation
		},
		sliceThickness() {
			return this.views[this.index].sliceThickness
		},
		window() {
			return this.views[this.index].window
		},
		blendMode() {
			return this.views[this.index].blendMode
		},
		viewColor() {
			return this.views[this.index].color
		},
		isActive() {
			return this.views[this.index].active
		},
		flipV() {
			return this.views[this.index].flipV
		},
		flipH() {
			return this.views[this.index].flipH
		},
		rotationFlipped() {
			return { x: this.views[this.index].flipRX, y: this.views[this.index].flipRY }
		},
		wwwc() {
			return `W/L: ${this.window.width.toFixed(0)}/${this.window.center.toFixed(0)}`
		},
		allAxesData() {
			// This is a hacky way to bind reactivity to the bits that change, without deep-watching the root `views` object
			// The structure of the object is meaningless, as long as it recalculates when the dependencies change.
			return {
				[TOP]: [
					this.views[TOP].sliceThickness,
					this.views[TOP].slicePlaneXRotation,
					this.views[TOP].slicePlaneYRotation,
				],
				[LEFT]: [
					this.views[LEFT].sliceThickness,
					this.views[LEFT].slicePlaneXRotation,
					this.views[LEFT].slicePlaneYRotation,
				],
				[FRONT]: [
					this.views[FRONT].sliceThickness,
					this.views[FRONT].slicePlaneXRotation,
					this.views[FRONT].slicePlaneYRotation,
				],
			}
		},
	},
	watch: {
		allAxesData() {
			this.recalculateAxes()
		},
		volumeData(data) {
			this.setupVolumeFromData(data)
			this.updateVolumesForRendering(this.volumes)
		},
		// Calculate the new normals after applying rotations to the untouched originals
		slicePlaneNormal() {
			this.updateSlicePlane()
		},
		slicePlaneXRotation() {
			this.updateSlicePlane()
			this.clearAnnotations()
		},
		slicePlaneYRotation() {
			this.updateSlicePlane()
			this.clearAnnotations()
		},
		sliceViewUp() {
			this.updateSlicePlane()
		},
		viewRotation() {
			this.updateSlicePlane()
		},
		flipH() {
			this.updateSlicePlane()
		},
		flipV() {
			this.updateSlicePlane()
		},
		sliceThickness(thickness) {
			const istyle = this.renderWindow.getInteractor().getInteractorStyle()
			// set thickness if the current interactor has it (it should, but just in case)
			istyle.setSlabThickness && istyle.setSlabThickness(thickness)
			this.updateBlendMode(thickness)
		},
		blendMode() {
			this.updateBlendMode(this.sliceThickness)
		},
		parallel(p) {
			this.renderer.getActiveCamera().setParallelProjection(p)
			this.renderer.resetCamera()
			this.updateSlicePlane()
			this.updateSliceDisplay()
			this.updateCompass()
		},
		sliceIntersection() {
			this.updateScreenCoordIntersection()
		},
	},
	created() {
		this.genericRenderWindow = null
		this.cachedSlicePlane = []
		this.cachedSliceViewUp = []

		this.recalculateAxes()
	},
	mounted() {
		// cache the view vectors so we can apply the rotations without modifying the original value
		this.cachedSlicePlane = [...this.slicePlaneNormal]
		this.cachedSliceViewUp = [...this.sliceViewUp]

		this.genericRenderWindow = vtkGenericRenderWindow.newInstance({
			background: [0, 0, 0],
		})

		this.genericRenderWindow.setContainer(this.$refs.container)
		this.renderWindow = this.genericRenderWindow.getRenderWindow()
		this.renderer = this.genericRenderWindow.getRenderer()

		// update view node tree so that vtkOpenGLHardwareSelector can access the vtkOpenGLRenderer instance.
		const oglrw = this.genericRenderWindow.getOpenGLRenderWindow()
		oglrw.buildPass(true)

		this.setupVolumeFromData(this.volumeData)

		const istyle = vtkInteractorStyleMPR.newInstance()
		const inter = this.renderWindow.getInteractor()
		istyle.setInteractor(inter)
		inter.setInteractorStyle(istyle)

		istyle.setVolumeActor(this.volumes[0])

		// Add the current volumes to the vtk renderer
		this.updateVolumesForRendering(this.volumes)

		this.updateSlicePlane()

		// Start with the volume center slice
		const range = istyle.getSliceRange()
		istyle.setSlice((range[0] + range[1]) / 2)

		// Force the initial draw to set the canvas to the parent bounds.
		this.onResize()

		this.subs.interactor.sub(
			this.renderer.getActiveCamera().onModified(() => {
				// Listen for camera changes to update the x/y stack info
				this.updateSliceDisplay()
				// Update intersection point in screenspace when zoomed or panned
				this.updateScreenCoordIntersection()
				// Make sure the thickness is adjusted when the camera changes
				this.recalculateAxes()
			})
		)
		// Listen to changes on the volume, and rerender
		this.subs.data.sub(this.volumeData.onModified(() => this.renderWindow.render()))
		this.updateSliceDisplay()

		if (this.parallel) {
			this.renderer.getActiveCamera().setParallelProjection(this.parallel)
			this.renderer.resetCamera()
		}

		this.renderWindow.render()
		this.updateScreenCoordIntersection()

		if (this.onCreated) {
			/**
			 * Note: The contents of this Object are
			 * considered part of the API contract
			 * we make with consumers of this component.
			 */
			this.onCreated({
				genericRenderWindow: this.genericRenderWindow,
				container: this.$refs.container,
				volumes: this.volumes,
				annotationInterface: {
					onButtonDown: (position, toolName) => {
						return this.$refs.annotationManager.onInteractionBegin(toolName, position)
					},
					onButtonUp: (position, toolName) => {
						return this.$refs.annotationManager.onInteractionEnd(toolName, position)
					},
					onMouseMove: (position, toolName) => {
						return this.$refs.annotationManager.onInteractionUpdate(toolName, position)
					},
				},
				_component: this,
			})
		}
	},
	beforeDestroy() {
		// Delete the render context
		if (this.genericRenderWindow) {
			this.genericRenderWindow.delete()
			delete this.genericRenderWindow
		}

		Object.keys(this.subs).forEach(k => {
			this.subs[k].unsubscribe()
		})

		// notify if prop defined
		if (this.onDestroyed) {
			this.onDestroyed()
		}
	},
	methods: {
		onWheel(e) {
			// Dispatch a new event to container to avoid errors
			const event = new WheelEvent(e.type, e)
			this.$refs.container.dispatchEvent(event)
		},
		// Pass events to parent, including the view they came from
		onMouseDown(e) {
			this.$emit('mousedown', this.index)
		},
		onTouchStart(e) {
			this.$emit('touchstart', this.index)
		},
		onClick(e) {
			this.$emit('click', this.index)
		},
		onDblClick(e) {
			this.$emit('dblclick', this.index)
		},
		onToggleFullscreen() {
			this.$emit('toggle-fullscreen', this.index)
		},
		onRotate(axis, angle) {
			this.$emit('rotate', this.index, axis, angle)
		},
		onThickness(axis, screenThickness) {
			if (screenThickness === 0) {
				// Emit the default world value if snapped to 0
				this.$emit('thickness', this.index, axis, 0.1)
				return
			}

			// Convert screen pixel thickness into world value
			let [x, y] = this.screenCoordSliceIntersection
			y += screenThickness

			const wPos = vtkCoordinate.newInstance()
			wPos.setCoordinateSystemToDisplay()
			wPos.setValue(this.screenCoordSliceIntersection)
			const p1 = wPos.getComputedWorldValue(this.renderer)

			wPos.setValue([x, y])
			const p2 = wPos.getComputedWorldValue(this.renderer)

			const thickness = vec3.distance(p1, p2) * (window.devicePixelRatio || 1)
			this.$emit('thickness', this.index, axis, thickness)
		},
		onMoveAxis(pos) {
			const wPos = vtkCoordinate.newInstance()
			wPos.setCoordinateSystemToDisplay()
			wPos.setValue(pos[0], pos[1], 0)
			const worldPos = wPos.getComputedWorldValue(this.renderer)

			this.$emit('pointSelected', { worldPos, index: this.index })
		},
		// This function is called by parent component to sync with other views
		onResize() {
			this.genericRenderWindow.resize()
			const [width, height] = [this.$refs.container.offsetWidth, this.$refs.container.offsetHeight]
			this.width = width || 0
			this.height = height || 0
		},
		setupVolumeFromData(volumeData) {
			const volumeActor = vtkVolume.newInstance()
			const volumeMapper = vtkVolumeMapper.newInstance()
			volumeMapper.setInputData(volumeData)
			volumeActor.setMapper(volumeMapper)

			const spacing = volumeData.getSpacing()
			// Set the sample distance to half the mean length of one side. This is where the divide by 6 comes from.
			// https://github.com/Kitware/VTK/blob/6b559c65bb90614fb02eb6d1b9e3f0fca3fe4b0b/Rendering/VolumeOpenGL2/vtkSmartVolumeMapper.cxx#L344
			const sampleDistance = (spacing[0] + spacing[1] + spacing[2]) / 6
			volumeMapper.setSampleDistance(sampleDistance)
			// Be generous to surpress warnings, as the logging really hurts performance.
			// TODO: maybe we should auto adjust samples to 1000.
			volumeMapper.setMaximumSamplesPerRay(4000)

			this.volumes = [volumeActor]
		},
		updateVolumesForRendering(volumes) {
			if (volumes && volumes.length) {
				this.renderer.removeAllVolumes()
				volumes.forEach(volume => {
					if (!volume.isA('vtkVolume')) {
						console.warn('Data to <Vtk2D> is not vtkVolume data')
					} else {
						this.renderer.addVolume(volume)
					}
				})
			} else {
				this.renderer.removeAllVolumes()
			}
			// this.renderWindow.render()
		},
		updateSlicePlane() {
			// TODO: optimize so you don't have to calculate EVERYTHING every time?

			// Copy values so we can modify them without side-effects
			const _sliceNormal = [...this.slicePlaneNormal]
			const _viewUp = [...this.sliceViewUp]

			// rotate around the vector of the cross product of the plane and viewup as the X component
			let sliceXRotVector = []
			vec3.cross(sliceXRotVector, _viewUp, _sliceNormal)
			vec3.normalize(sliceXRotVector, sliceXRotVector)

			// rotate the viewUp vector as the Y component
			let sliceYRotVector = _viewUp

			const FUDGE = 0.00001
			const rotX = this.slicePlaneXRotation === 0 ? 0 : this.slicePlaneXRotation + FUDGE
			const rotY = this.slicePlaneYRotation === 0 ? 0 : this.slicePlaneYRotation + FUDGE
			const dirX = this.rotationFlipped.x ? -1 : 1
			const dirY = this.rotationFlipped.y ? -1 : 1

			const xQuat = quat.create()
			quat.setAxisAngle(xQuat, sliceXRotVector, degrees2radians(rotX * dirX))
			const yQuat = quat.create()
			quat.setAxisAngle(yQuat, sliceYRotVector, degrees2radians(rotY * dirY))

			quat.multiply(xQuat, xQuat, yQuat)
			quat.normalize(xQuat, xQuat)

			vec3.transformQuat(_sliceNormal, _sliceNormal, xQuat)
			vec3.transformQuat(_viewUp, _viewUp, xQuat)

			// Flip views if needed, *after* the rotation so the flip actually mirrors
			if (this.flipV) vec3.scale(_viewUp, _viewUp, -1)
			// flipV requires flipping H as well, so only if one or the other is set; flipV will also flipH with this logic
			if ((!this.flipV && this.flipH) || (this.flipV && !this.flipH)) vec3.scale(_sliceNormal, _sliceNormal, -1)

			this.cachedSlicePlane = [..._sliceNormal]
			this.cachedSliceViewUp = [..._viewUp]

			// Rotate the viewUp in 90 degree increments
			if (this.viewRotation) {
				const viewRotQuat = quat.create()
				// Use negative degrees since the axis of rotation should really be the direction of projection, which is the negative of the plane normal
				// Add a fractional degree during rotation to potentially fix ch6744
				quat.setAxisAngle(viewRotQuat, this.cachedSlicePlane, degrees2radians(-this.viewRotation - FUDGE))
				quat.normalize(viewRotQuat, viewRotQuat)
				vec3.transformQuat(this.cachedSliceViewUp, this.cachedSliceViewUp, viewRotQuat)
			}

			// update the view's slice
			// FIXME: Store/remember the slice currently looked at, so you rotate around that location instead of the volume center
			const renderWindow = this.genericRenderWindow.getRenderWindow()
			renderWindow
				.getInteractor()
				.getInteractorStyle()
				.setSliceNormal(this.cachedSlicePlane, this.cachedSliceViewUp)

			this.updateCompass()

			renderWindow.render()
		},
		updateCompass() {
			const { invertOrientationString } = cornerstoneTools.orientation
			// Get the new direction after the normal has been modified to the volume coordinates
			let cameraDir = this.renderer.getActiveCamera().getDirectionOfProjection()
			// Get the orthogonal viewUp because it isn't updated automatically when set
			this.renderer.getActiveCamera().orthogonalizeViewUp()
			let cameraUp = this.renderer.getActiveCamera().getViewUp()

			// translate the camera position to the volume's directionality
			vec3.transformMat3(cameraUp, cameraUp, this.volumeDirection)
			vec3.transformMat3(cameraDir, cameraDir, this.volumeDirection)

			const cameraRight = []
			vec3.cross(cameraRight, cameraUp, cameraDir)

			// TODO: wait until cornerstone is updated to v4.6.1, then replace vector2RAS with getOrientationString again
			const topString = vector2RAS(cameraUp)
			const rightString = vector2RAS(cameraRight)

			const bottomString = invertOrientationString(topString)
			const leftString = invertOrientationString(rightString)

			this.compass = {
				top: topString,
				right: rightString,
				bottom: bottomString,
				left: leftString,
			}
		},
		updateSliceDisplay() {
			const istyle = this.renderWindow.getInteractor().getInteractorStyle()
			const range = istyle.getSliceRange()
			const slice = istyle.getSlice()

			this.currentSlice = Math.round(slice - range[0]) + 1
			const maxSlice = Math.round(range[1] - range[0]) + 1
			this.stackSliceDisplay = `${this.currentSlice} / ${maxSlice}`
		},
		updateBlendMode(thickness) {
			if (thickness >= 1) {
				switch (this.blendMode) {
					case BLEND_MIP:
						this.volumes[0].getMapper().setBlendModeToMaximumIntensity()
						break
					case BLEND_MINIP:
						this.volumes[0].getMapper().setBlendModeToMinimumIntensity()
						break
					case BLEND_AVG:
						this.volumes[0].getMapper().setBlendModeToAverageIntensity()
						break
					case BLEND_NONE:
					default:
						this.volumes[0].getMapper().setBlendModeToComposite()
						break
				}
			} else {
				this.volumes[0].getMapper().setBlendModeToComposite()
			}
			this.renderWindow.render()
		},
		clearAnnotations() {
			this.$refs.annotationManager.clearAnnotations()
		},
		async renderImage(format = 'image/png') {
			const container = this.$refs.container
			const { width: origWidth, height: origHeight } = container.style
			const fromSvg = this.$refs.annotationManager.$el

			const ratio = window.devicePixelRatio || 1
			const canvas = document.createElement('canvas')
			const context = canvas.getContext('2d')

			// Resize for canvas image and annotations
			// 800px may seem random but it should be large enough to capture
			// canvas image and annotation details while keeping image size reasonable.
			// It is also the size used when an image is opened in a popup window for copy/print.
			container.style.width = '800px'
			container.style.height = '800px'
			this.onResize()

			// Match output canvas to updated container size
			canvas.width = container.clientWidth * ratio
			canvas.height = container.clientHeight * ratio

			// Initialize the volume image source
			const fromCanvasImageSource = await this.captureCanvasImageSource(format)
			if (!fromCanvasImageSource) return null
			// Load the volume image
			const fromCanvasImage = await this.loadImage(fromCanvasImageSource, 'volume')
			// Draw the volume image in the output context
			context.drawImage(fromCanvasImage, 0, 0)

			// Resize back to original size
			container.style.width = origWidth
			container.style.height = origHeight
			this.onResize()

			// Initialize the annotations image source
			const fromSvgImageSource = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(fromSvg.outerHTML)))
			// Load the annotations image
			const fromSvgImage = await this.loadImage(fromSvgImageSource, 'annotations')
			// Scale the context for device display ratio
			context.scale(ratio, ratio)
			// Draw the annotations image in the output context
			context.drawImage(fromSvgImage, 0, 0)

			// Return the canvas data url
			return canvas.toDataURL()
		},
		// In WebGL environments, we can't just read the canvas buffer b/c it will be cleared once rendered.
		// Here, we utilize a promise that captures the next image for the current render window.
		// Then, we trigger a render and return the captured image source.
		async captureCanvasImageSource(format) {
			return new Promise((resolve, reject) => {
				const capturePromises = this.renderWindow.captureImages(format)
				if (capturePromises.length > 0) {
					capturePromises[0]
						.then(imgSrc => {
							resolve(imgSrc)
						})
						.catch(err => {
							reject(err)
						})
					// Trigger render to capture
					this.renderWindow.render()
				} else {
					reject(new Error('No views found to capture images for.'))
				}
			})
		},
		async loadImage(src, type) {
			return new Promise((resolve, reject) => {
				const img = new Image()
				// Initialize error handler
				img.onerror = function onImageError(err) {
					reject(new Error(`Failed to load ${type} image.`))
				}
				// Initialize load handler
				img.onload = function onImageLoad() {
					resolve(img)
				}
				// Load the image
				img.src = src
			})
		},
		recalculateAxes() {
			this.xAxis = this.generateXAxis()
			this.yAxis = this.generateYAxis()
		},
		generateXAxis() {
			switch (this.index) {
				case 'top':
				default:
					return {
						color: this.views.front.color,
						rotation: this.views.front.slicePlaneYRotation,
						thickness: this.convertToScreenThickness(this.views.front.sliceThickness),
					}
				case 'left':
					return {
						color: this.views.front.color,
						rotation: this.views.front.slicePlaneXRotation,
						thickness: this.convertToScreenThickness(this.views.front.sliceThickness),
					}
				case 'front':
					return {
						color: this.views.top.color,
						rotation: this.views.top.slicePlaneYRotation,
						thickness: this.convertToScreenThickness(this.views.top.sliceThickness),
					}
			}
		},
		generateYAxis() {
			switch (this.index) {
				case 'top':
				default:
					return {
						color: this.views.left.color,
						rotation: this.views.left.slicePlaneXRotation,
						thickness: this.convertToScreenThickness(this.views.left.sliceThickness),
					}
				case 'left':
					return {
						color: this.views.top.color,
						rotation: this.views.top.slicePlaneXRotation,
						thickness: this.convertToScreenThickness(this.views.top.sliceThickness),
					}
				case 'front':
					return {
						color: this.views.left.color,
						rotation: this.views.left.slicePlaneYRotation,
						thickness: this.convertToScreenThickness(this.views.left.sliceThickness),
					}
			}
		},
		updateScreenCoordIntersection() {
			const point3d = this.sliceIntersection
			if (this.renderer) {
				const wPos = vtkCoordinate.newInstance()
				wPos.setCoordinateSystemToWorld()
				wPos.setValue(point3d)
				const canvasCoords = wPos.getComputedDisplayValue(this.renderer)
				this.screenCoordSliceIntersection = canvasCoords
			} else {
				this.screenCoordSliceIntersection = false
			}
		},
		convertToScreenThickness(worldThickness) {
			if (!this.renderer) {
				return worldThickness
			}
			// Don't show a thickness less than 1mm
			else if (worldThickness < 1) return 0

			// Create a coplaner point that is the correct distance away from a known point
			const origin = this.sliceIntersection
			let newPoint = vec3.scale([], this.sliceViewUp, worldThickness)
			vec3.add(newPoint, newPoint, origin)

			// Convert to screen coords to get the pixel distance
			const wPos = vtkCoordinate.newInstance()
			wPos.setCoordinateSystemToWorld()
			wPos.setValue(newPoint)
			const p1 = wPos.getComputedDisplayValue(this.renderer)

			wPos.setValue(origin)
			const p2 = wPos.getComputedDisplayValue(this.renderer)

			const thickness = vec3.distance([...p1, 0], [...p2, 0]) / (window.devicePixelRatio || 1)
			return thickness
		},
	},
}

// TODO: eventually replace with updated Cornerstone tools, v4.
function vector2RAS(vector) {
	const [x, y, z] = vector
	const vec3 = { x, y, z }

	let orientation = ''
	const orientationX = vec3.x < 0 ? 'R' : 'L'
	const orientationY = vec3.y < 0 ? 'A' : 'P'
	const orientationZ = vec3.z < 0 ? 'F' : 'H'

	const abs = {}
	for (const key in vec3) {
		abs[key] = Math.abs(vec3[key])
	}

	const MIN = 0.0001

	for (let i = 0; i < 3; i++) {
		if (abs.x > MIN && abs.x > abs.y && abs.x > abs.z) {
			orientation += orientationX
			abs.x = 0
		} else if (abs.y > MIN && abs.y > abs.x && abs.y > abs.z) {
			orientation += orientationY
			abs.y = 0
		} else if (abs.z > MIN && abs.z > abs.x && abs.z > abs.y) {
			orientation += orientationZ
			abs.z = 0
		} else if (abs.x > MIN && abs.y > MIN && abs.x === abs.y) {
			orientation += orientationX + orientationY
			abs.x = 0
			abs.y = 0
		} else if (abs.x > MIN && abs.z > MIN && abs.x === abs.z) {
			orientation += orientationX + orientationZ
			abs.x = 0
			abs.z = 0
		} else if (abs.y > MIN && abs.z > MIN && abs.y === abs.z) {
			orientation += orientationY + orientationZ
			abs.y = 0
			abs.z = 0
		} else {
			break
		}
	}

	return orientation
}
</script>

<style lang="scss" scoped>
.viewer2d,
.container2d {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 0;
	&.active {
		z-index: 10;
	}
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: left;
}
</style>
