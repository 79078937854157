import ViewerTool from '@store/models/viewerTool'
import consts from '@vtk/VTKViewport/consts'

export default [
	new ViewerTool({
		alias: consts.LEVEL_TOOL,
		name: consts.LEVEL_TOOL,
		label: 'Levels',
		title: 'Window Levels',
		groups: ['levels'],
		iconName: 'brightness-6',
	}),
	new ViewerTool({
		alias: consts.ZOOM_TOOL,
		name: consts.ZOOM_TOOL,
		label: 'Zoom',
		title: 'Zoom',
		groups: [],
		iconName: 'magnify',
	}),
	new ViewerTool({
		alias: consts.PAN_TOOL,
		name: consts.PAN_TOOL,
		label: 'Pan',
		title: 'Pan',
		groups: [],
		iconName: 'arrow-all',
	}),
	new ViewerTool({
		alias: consts.LENGTH_TOOL,
		name: consts.LENGTH_TOOL,
		label: 'Length',
		title: 'Length',
		groups: ['annotation'],
		iconName: 'vector-line',
	}),
	new ViewerTool({
		alias: consts.RECT_TOOL,
		name: consts.RECT_TOOL,
		label: 'Rect',
		title: 'Rect',
		groups: ['annotation'],
		iconName: 'vector-rectangle',
	}),
	new ViewerTool({
		alias: consts.CIRCLE_TOOL,
		name: consts.CIRCLE_TOOL,
		label: 'Circle',
		title: 'Circle',
		groups: ['annotation'],
		iconName: 'vector-circle-variant',
	}),
	new ViewerTool({
		alias: consts.ELLIPSE_TOOL,
		name: consts.ELLIPSE_TOOL,
		label: 'Ellipse',
		title: 'Ellipse',
		groups: ['annotation'],
		iconName: 'vector-ellipse',
	}),
	new ViewerTool({
		alias: consts.ANGLE_TOOL,
		name: consts.ANGLE_TOOL,
		label: 'Angle',
		title: 'Angle',
		groups: ['annotation'],
		iconName: 'angle',
	}),
	new ViewerTool({
		alias: consts.TEXT_TOOL,
		name: consts.TEXT_TOOL,
		label: 'Text',
		title: 'Text Annotation',
		groups: ['annotation'],
		iconName: 'format-text',
	}),
	new ViewerTool({
		alias: consts.ARROW_TOOL,
		name: consts.ARROW_TOOL,
		label: 'Arrow',
		title: 'Arrow Annotation',
		groups: ['annotation'],
		iconName: 'arrow-bottom-left',
	}),
	new ViewerTool({
		alias: consts.ERASER_TOOL,
		name: consts.ERASER_TOOL,
		label: 'Eraser',
		title: 'Eraser',
		groups: ['annotation'],
		iconName: 'eraser',
	}),
	new ViewerTool({
		alias: consts.SCROLL_TOOL,
		name: consts.SCROLL_TOOL,
		label: 'Scroll',
		title: 'Stack Scroll',
		groups: ['scroll'],
		iconName: 'layers',
	}),
	new ViewerTool({
		alias: consts.SELECT_TOOL,
		name: consts.SELECT_TOOL,
		label: 'Point',
		title: 'Cross Point',
		groups: ['scroll'],
		iconName: 'crosshairs',
	}),
	new ViewerTool({
		alias: consts.PLANE_TOOL,
		name: consts.PLANE_TOOL,
		label: 'Plane',
		title: 'Rotate Slice Plane',
		groups: [],
		iconName: 'rotate-tool',
	}),
	new ViewerTool({
		alias: consts.ROLL_TOOL,
		name: consts.ROLL_TOOL,
		label: 'Roll',
		title: 'Roll View',
		groups: [],
		iconName: 'roll-tool',
	}),
	new ViewerTool({
		alias: consts.PROBE_TOOL,
		name: consts.PROBE_TOOL,
		label: 'Probe',
		title: 'Probe',
		groups: ['scroll'],
		iconName: 'eyedropper-variant',
		refresh({ activeSeries }) {
			let disabledReason = ''
			if (!activeSeries || !activeSeries.modality || activeSeries.modality.toLowerCase() !== 'ct') {
				disabledReason = 'CT series required'
			} else if (activeSeries.isJpeg) {
				disabledReason = 'DICOM rendering required'
			}
			this.disabledReason = disabledReason
			this.disabled = !!disabledReason
		},
	}),
]
