<template>
	<div v-if="initialized" class="mpr-layout" :style="layout.container">
		<div
			v-for="(view, key) in viewData"
			:key="key"
			class="mpr-view-wrapper-rel"
			:style="{
				...layout[view.position],
				...(view.active && {
					...(switchModeActive && {
						pointerEvents: 'none',
					}),
				}),
			}"
			@click="() => onClick(key)"
		>
			<div class="mpr-view-wrapper-abs">
				<!--Switch view overlay button-->
				<button
					v-if="switchModeActive && !view.active"
					class="mpr-switch-overlay"
					@click.stop="onSwitch(key)"
				>
					<p>Click to switch</p>
				</button>
				<!--Mpr View-->
				<view-2d-mpr
					:volume-data="volumeData"
					:slice-intersection="sliceIntersection"
					:views="viewData"
					:on-created="saveComponentRefGenerator(key)"
					:index="key"
					:should-animate="shouldAnimate"
					:overlay-information="overlayInformation"
					:show-axis="showAxis"
					:show-overlay-text="showOverlayText"
					:show-image-orientation-markers="showImageOrientationMarkers"
					:volume-direction="volumeDirection"
					@mousedown="onMouseDown"
					@touchstart="onTouchStart"
					@click="onClick"
					@dblclick="onDblClick"
					@toggle-fullscreen="onToggleFullscreen"
					@rotate="onRotate"
					@thickness="onThickness"
					@pointSelected="onCrosshairPointSelected"
				/>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * Component emits the following, to tell the parent to update the `viewData` object
 * @emits "rotate"
 * @emits "thickness"
 * @emits "activity"
 * @emits "windowLevels"
 */
import vtkMatrixBuilder from 'vtk.js/Sources/Common/Core/MatrixBuilder'
import throttle from 'lodash/throttle'

import View2dMPR from './2DMPRView.vue'
import getPlaneIntersection from '../lib/math/planeIntersections'
import { toLowHighRange, toWindowLevel } from '../lib/windowLevelRangeConverter'
import invertVolume from '../lib/invertVolume'

import getBrowserIssues from './BrowserIssues'
import { FRONT, TOP, LEFT } from './consts'
import { getDefaultViewData } from '@store/modules/mpr/state'
import layouts from '@store/modules/mpr/state/layouts'
import wait from '@/utils/wait'
import { scaleJpegVoiToDicom, scaleDicomPresetToJpeg } from '@/utils/wwwc.js'

export default {
	components: {
		'view-2d-mpr': View2dMPR,
	},
	props: {
		activeTools: {
			type: Object,
			required: true,
		},
		syncWindowLevels: {
			type: Boolean,
			default: false,
		},
		windowLevelScale: {
			type: Number,
			default: 1,
		},
		volumeData: {
			type: Object,
			required: true,
		},
		isJpeg: {
			type: Boolean,
			default: false,
		},
		volumeDirection: {
			type: Array,
			required: true,
		},
		sliceDirectionality: {
			type: Number,
			default: 1,
		},
		defaultVoi: {
			type: Object,
			default: () => ({
				windowWidth: 0,
				windowCenter: 0,
			}),
		},
		initialSlice: {
			type: Number,
			default: 1,
		},
		initialVoi: {
			type: Object,
			default: undefined,
		},
		sampleDistance: {
			type: Number,
			default: 1,
		},
		layout: {
			type: Object,
			default: () => layouts[0],
		},
		switchModeActive: {
			type: Boolean,
			default: false,
		},
		showAxis: {
			type: Boolean,
			default: true,
		},
		showOverlayText: {
			type: Boolean,
			default: true,
		},
		showImageOrientationMarkers: {
			type: Boolean,
			default: true,
		},
		// the parent of this component will need to handle events that change these values
		// the structure of this viewData is very important.
		viewData: {
			type: Object,
			default: () => getDefaultViewData(),
		},
		overlayInformation: { type: Object, required: true },
	},
	provide() {
		return {
			mprManager: this,
		}
	},
	data() {
		return {
			// Holds the volumeActors that hold the volume data
			// volumes: [],
			// Holds the data from each of the 2dMPRViews
			components: [],
			sliceIntersection: [0, 0, 0],
			initialized: false,
			shouldAnimate: true,
		}
	},
	computed: {
		activeViewIndex() {
			return Object.entries(this.viewData).find(([key, value]) => value.active)[0]
		},
	},
	watch: {
		activeTools: {
			deep: true,
			handler(tools) {
				Object.values(this.components).forEach(component => {
					const renderWindow = component.genericRenderWindow.getRenderWindow()
					const istyle = renderWindow.getInteractor().getInteractorStyle()

					istyle.setTools({ ...tools })
				})
			},
		},
		volumeData() {
			this.init()
		},
		layout() {
			this.updateLayout()
		},
	},
	mounted() {
		// Check general browser compatibilities for MPR
		this.checkBrowserCompatibility()
		// Register key down/up for view switching views
		this.registerSwitchKeyHandlers()
		// Register resize handlers
		this.registerResizeHandlers()
		this.init()
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize)
	},
	methods: {
		checkBrowserCompatibility() {
			let errors = []
			// Check `elementsFromPoint` needed for annotations
			if (!document.elementsFromPoint && !document.msElementsFromPoint) {
				errors.push('This browser is missing key functions needed to support annotations.')
			}
			// Post a notification for each error
			errors.forEach(error => {
				this.$store.dispatch('addNotification', {
					message: error,
					notificationType: 'error',
				})
			})
		},
		registerSwitchKeyHandlers() {
			const switchKey = 'Alt'
			const handleSwitchKeydown = e => {
				if (e.key === switchKey) {
					this.$emit('update:switchModeActive', true)
				}
			}
			const handleSwitchKeyup = e => {
				if (e.key === switchKey) {
					this.$emit('update:switchModeActive', false)
				}
			}
			const clearOnFocusLoss = () => handleSwitchKeyup({ key: switchKey })
			document.addEventListener('keydown', handleSwitchKeydown)
			document.addEventListener('keyup', handleSwitchKeyup)
			window.addEventListener('blur', clearOnFocusLoss)
			this.$once('hook:beforeDestroy', () => {
				document.removeEventListener('keydown', handleSwitchKeydown)
				document.removeEventListener('keyup', handleSwitchKeyup)
				window.removeEventListener('blur', clearOnFocusLoss)
			})
		},
		registerResizeHandlers() {
			window.removeEventListener('resize', this.onResize)
			window.addEventListener('resize', this.onResize)
		},
		async updateLayout() {
			// Disable animation while adjusting layout
			this.shouldAnimate = false
			await wait(10)
			// Call resize to adjust for new layout
			this.onResize()
			await wait(10)
			// Enable animation again
			this.shouldAnimate = true
		},
		onResize: throttle(
			function() {
				// Call resize on each child view
				Object.values(this.components).forEach(c => {
					c._component.onResize()
				})
				// Do this at the Manager level to prevent running 3x on camera changes
				this.updateSliceIntersection()
			},
			100,
			{ leading: true, trailing: true }
		),
		async onSwitch(index) {
			this.$emit('switch', { fromIndex: this.activeViewIndex, toIndex: index })
			this.$emit('update:switchModeActive', false)
			this.updateLayout()
		},
		onMouseDown(index) {
			this.$emit('activity', index)
		},
		onTouchStart(index) {
			this.$emit('activity', index)
		},
		onClick(index) {
			this.$emit('click', index)
		},
		onDblClick(index) {
			const { windowWidth, windowCenter } = this.getDefaultVoi()
			this.manuallySetWindowLevels(windowWidth, windowCenter, index)
		},
		onToggleFullscreen(index) {
			this.$emit('activity', index)
			this.$emit('toggle-fullscreen', index)
		},
		onRotate(index, axis, angle) {
			// Match the source axis to the associated plane
			const data = { index: '', plane: '', angle }
			switch (index) {
				case TOP:
					if (axis === 'x') {
						data.index = FRONT
						data.plane = 'y'
					} else if (axis === 'y') {
						data.index = LEFT
						data.plane = 'x'
					}
					break
				case LEFT:
					if (axis === 'x') {
						data.index = FRONT
						data.plane = 'x'
					} else if (axis === 'y') {
						data.index = TOP
						data.plane = 'x'
					}
					break
				case FRONT:
					if (axis === 'x') {
						data.index = TOP
						data.plane = 'y'
					} else if (axis === 'y') {
						data.index = LEFT
						data.plane = 'y'
					}
					break
			}
			this.$emit('rotate', data)
			this.$emit('activity', index)
		},
		onThickness(index, axis, thickness) {
			const data = { index: '', thickness }
			switch (index) {
				case TOP:
					if (axis === 'x') data.index = FRONT
					else if (axis === 'y') data.index = LEFT
					break
				case LEFT:
					if (axis === 'x') data.index = FRONT
					else if (axis === 'y') data.index = TOP
					break
				case FRONT:
					if (axis === 'x') data.index = TOP
					else if (axis === 'y') data.index = LEFT
					break
			}
			this.$emit('thickness', data)
			this.$emit('activity', index)

			// TODO: add logic to the vuex handler that:
			// if (thickness >= 1 && view.blendMode === BLEND_NONE) view.blendMode = BLEND_MIP;
			// else if(!shouldBeMIP) {
			//   view.blendMode = "none"
			// }
		},
		onScrolled(index) {
			this.updateSliceIntersection()
			this.$emit('activity', index)
		},
		updateSliceIntersection() {
			let planes = []
			Object.values(this.components).forEach(component => {
				const camera = component.genericRenderWindow.getRenderer().getActiveCamera()

				planes.push({
					position: camera.getFocalPoint(),
					normal: camera.getDirectionOfProjection(),
				})
			})
			const newPoint = getPlaneIntersection(...planes)
			if (!Number.isNaN(newPoint)) {
				this.sliceIntersection = newPoint
			}
		},
		onCrosshairPointSelected({ index, worldPos }) {
			Object.entries(this.components)
				.filter(([key]) => key !== index)
				.forEach(([viewportIndex, component]) => {
					// We are basically doing the same as getSlice but with the world coordinate
					// that we want to jump to instead of the camera focal point.
					// I would rather do the camera adjustment directly but I keep
					// doing it wrong and so this is good enough for now.
					// ~ swerik
					const renderWindow = component.genericRenderWindow.getRenderWindow()

					const istyle = renderWindow.getInteractor().getInteractorStyle()
					const sliceNormal = istyle.getSliceNormal()
					const transform = vtkMatrixBuilder
						.buildFromDegree()
						.rotateFromDirections(sliceNormal, [1, 0, 0])

					const mutatedWorldPos = worldPos.slice()
					transform.apply(mutatedWorldPos)
					const slice = mutatedWorldPos[0]

					istyle.setSlice(slice)

					renderWindow.render()
				})
			this.updateSliceIntersection()
			this.$emit('activity', index)
		},
		updateLevels({ index, windowCenter, windowWidth }) {
			// emit the values because VTK changed them, only to update the shown text
			// Levels must be manually set in VTK

			// Level Scale from VTK may be jpeg, so convert back to Dicom scale for display
			const { windowWidth: scaledWW, windowCenter: scaledWC } = this.scaleJpegVoiIfJpeg({
				windowCenter,
				windowWidth,
			})
			this.$emit('windowLevels', { index, windowCenter: scaledWC, windowWidth: scaledWW })
			this.$emit('activity', index)

			if (this.syncWindowLevels) {
				Object.entries(this.components)
					.filter(([key]) => key !== index)
					.forEach(([key, component]) => {
						// Keep vtk's internal levels
						component.genericRenderWindow
							.getInteractor()
							.getInteractorStyle()
							.setWindowLevel(windowWidth, windowCenter)
						component.genericRenderWindow.getRenderWindow().render()
						// Emit scaled values for display, if vtk has a jpeg volume
						this.$emit('windowLevels', {
							index: key,
							windowCenter: scaledWC,
							windowWidth: scaledWW,
						})
					})
			}
		},
		saveComponentRefGenerator(viewportIndex) {
			// generate a function that captures references to the given view component, keeping the index scope
			return component => {
				this.components[viewportIndex] = component

				const volumeActor = component.volumes[0]

				// On First Load, use provided seed/initial Voi, (falling back to defaults)
				const { windowWidth, windowCenter } = this.getInitialVoi()
				let { windowWidth: scaledWW, windowCenter: scaledWC } = this.scaleDicomVoiIfJpeg({
					windowWidth,
					windowCenter,
				})
				const { lower, upper } = toLowHighRange(scaledWW, scaledWC)
				const rgbTransferFunction = volumeActor.getProperty().getRGBTransferFunction(0)
				rgbTransferFunction.setMappingRange(lower, upper)
				this.$emit('windowLevels', { index: viewportIndex, windowWidth, windowCenter })

				const renderWindow = component.genericRenderWindow.getRenderWindow()
				// We are assuming the old style is always extended from the MPRSlice style
				const istyle = renderWindow.getInteractor().getInteractorStyle()

				istyle.setOnScroll(() => this.onScrolled(viewportIndex))
				istyle.setOnLevelsChanged(levels => {
					this.updateLevels({ ...levels, index: viewportIndex })
				})
				istyle.setOnPointSelected(({ worldPos }) =>
					this.onCrosshairPointSelected({ worldPos, index: viewportIndex })
				)
				istyle.setOnSliceRotated(({ x, y }) => {
					this.$emit('rotate', {
						index: viewportIndex,
						plane: 'x',
						angle: x + this.viewData[viewportIndex]['slicePlaneXRotation'],
					})
					this.$emit('rotate', {
						index: viewportIndex,
						plane: 'y',
						angle: y + this.viewData[viewportIndex]['slicePlaneYRotation'],
					})
					this.$emit('activity', viewportIndex)
				})
				istyle.setOnViewRolled(({ y }) => {
					// sometimes the angle is < 1, and ceil on a negative decimal is 0, not -1
					let degrees = y > 0 ? Math.ceil(y) : Math.floor(y)
					degrees = degrees % 360 // don't rotate more than 359 degrees at a time (VERY fast mouse movement?)
					// Invert the angle so up rotates cw, down rotates ccw, relative to the existing rotation
					let angle = this.viewData[viewportIndex]['viewRotation'] - degrees
					// lock to 360 rotation
					if (angle < 0) angle += 360
					if (angle >= 360) angle -= 360
					this.$emit('roll', {
						index: viewportIndex,
						angle,
					})
				})
				istyle.setLevelScale(this.windowLevelScale)

				istyle.setAnnotationInterface(component.annotationInterface)

				// NO idea why I need to reset the thickness, but it doesn't set the clipping properly otherwise.
				istyle.setSlabThickness(istyle.getSlabThickness())

				// Enable the currently active tools
				istyle.setTools({ ...this.activeTools })

				if (viewportIndex === TOP) {
					// Set Top to defined slice
					const [min, max] = istyle.getSliceRange()
					// initialSlice is a % of the image range. Map to the VTK range,
					// Using sliceDirectionality to flip which side of the volume it grabs from, to match Viewer's image
					let slice = this.sliceDirectionality === -1 ? 1 - this.initialSlice : this.initialSlice

					istyle.setSlice(min + Math.round((max - min) * slice))
					this.$nextTick(this.updateSliceIntersection)

					// One time check for Performance problems
					const issues = getBrowserIssues(component.genericRenderWindow)
					this.$emit('performance-issues', issues)

					// Set a listener on the canvas for context errors, show a message when they happen.
					// Not great, but better than nothing.
					const oglCanvas = component.genericRenderWindow.getOpenGLRenderWindow().getCanvas()
					const emitWebGLError = event => {
						this.$emit('webglcontextlost', event)
						event.preventDefault()
					}
					oglCanvas.addEventListener('webglcontextlost', emitWebGLError, false)
					this.$once('hook:beforeDestroy', () => {
						oglCanvas.removeEventListener('webglcontextlost', emitWebGLError, false)
					})
				}

				renderWindow.render()
			}
		},
		init() {
			this.sliceIntersection = getVolumeCenter(this.volumeData)
			this.initialized = true
		},
		getInitialVoi() {
			// Used for the first load to pass in seed values, if provided. Falls back to Dicom value otherwise.
			if (!this.initialVoi) return this.getDefaultVoi()
			const { windowWidth, windowCenter } = this.initialVoi
			if (windowWidth === 0 && windowCenter === 0) return this.getDefaultVoi()
			return { windowWidth, windowCenter }
		},
		getDefaultVoi() {
			let { windowWidth, windowCenter } = this.defaultVoi
			if (windowWidth === 0 && windowCenter === 0) {
				const initialRange = this.volumeData
					.getPointData()
					.getScalars()
					.getRange()
				return toWindowLevel(...initialRange)
			}
			return { windowWidth, windowCenter }
		},
		reset() {
			let { windowWidth, windowCenter } = this.getDefaultVoi()
			let { windowWidth: scaledWW, windowCenter: scaledWC } = this.scaleDicomVoiIfJpeg({
				windowWidth,
				windowCenter,
			})

			// Wait until the MPRView has got it's updated stuff?
			Object.entries(this.components).forEach(([index, component]) => {
				try {
					const istyle = component.genericRenderWindow
						.getRenderWindow()
						.getInteractor()
						.getInteractorStyle()
					if (istyle) {
						// Reset camera zoom before setting slice
						istyle.resetCameraPosition()
						this.$nextTick(() => {
							const [min, max] = istyle.getSliceRange()
							istyle.setSlice((min + max) / 2)
							istyle.setWindowLevel(scaledWW, scaledWC)
							this.$emit('windowLevels', { index, windowWidth, windowCenter })
							component.genericRenderWindow.getRenderWindow().render()
						})
					}
				} catch (err) {
					// do nothing, move on
				}
			})
			this.updateSliceIntersection()
		},
		scaleDicomVoiIfJpeg(voi) {
			const { windowWidth, windowCenter } = this.getDefaultVoi()
			return this.isJpeg
				? scaleDicomPresetToJpeg(voi, {
						dicomWindowWidth: windowWidth,
						dicomWindowCenter: windowCenter,
						windowWidth: 255,
						windowCenter: 128,
				  })
				: voi
		},
		scaleJpegVoiIfJpeg(voi) {
			const { windowWidth, windowCenter } = this.getDefaultVoi()
			return this.isJpeg
				? scaleJpegVoiToDicom(voi, {
						dicomWindowWidth: windowWidth,
						dicomWindowCenter: windowCenter,
						windowWidth: 255,
						windowCenter: 128,
				  })
				: voi
		},
		manuallySetWindowLevels(windowWidth, windowCenter, activeWindow = false) {
			const { windowWidth: scaledWW, windowCenter: scaledWC } = this.scaleDicomVoiIfJpeg({
				windowWidth,
				windowCenter,
			})
			Object.entries(this.components).forEach(([index, component]) => {
				try {
					const istyle = component.genericRenderWindow
						.getRenderWindow()
						.getInteractor()
						.getInteractorStyle()
					const shouldChange =
						activeWindow === false || index === activeWindow || this.syncWindowLevels
					if (istyle && shouldChange) {
						istyle.setWindowLevel(scaledWW, scaledWC)
						// Emit the initial/unscaled values for display
						this.$emit('windowLevels', { index, windowWidth, windowCenter })
						component.genericRenderWindow.getRenderWindow().render()
					}
				} catch (err) {
					// do nothing, move on
				}
			})
		},
		invertVolumeForActiveView() {
			const activeWindowKey = Object.keys(this.viewData).find(k => this.viewData[k].active)
			if (activeWindowKey)
				invertVolume(this.components[activeWindowKey].volumes[0], this.manuallyReRender)
		},
		moveSliceForActiveView(sliceStep = 0) {
			const activeWindowKey = Object.keys(this.viewData).find(k => this.viewData[k].active)
			if (activeWindowKey) {
				const istyle = this.components[activeWindowKey].genericRenderWindow
					.getRenderWindow()
					.getInteractor()
					.getInteractorStyle()
				const [min, max] = istyle.getSliceRange()
				const newSlice = istyle.getSlice() + sliceStep
				if (newSlice >= min && newSlice <= max) {
					istyle.scrollToSlice(newSlice)
				}
			}
		},
		manuallyReRender() {
			Object.values(this.components).forEach(component =>
				component.genericRenderWindow.getRenderWindow().render()
			)
		},
	},
}

function getVolumeCenter(volumeData) {
	const bounds = volumeData.getBounds()
	return [
		(bounds[0] + bounds[1]) / 2.0,
		(bounds[2] + bounds[3]) / 2.0,
		(bounds[4] + bounds[5]) / 2.0,
	]
}
</script>

<style lang="scss" scoped>
.mpr-layout {
	display: grid;
	height: 100%;
	background: rgba(255, 255, 255, 0.1);
	padding: 2px;
	grid-gap: 2px;
	overflow: hidden;
}
.mpr-view-wrapper-rel {
	position: relative;
	overflow: hidden;
}
.mpr-view-wrapper-abs {
	position: absolute;
	width: 100%;
	height: 100%;
}
.mpr-switch-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	cursor: pointer;
	background: rgba(255, 255, 255, 0.2);
	& p {
		background: white;
		color: #2d3748;
		font-size: 1.2rem;
		padding: 6px 12px;
		border-radius: 8px;
		user-select: none;
	}
}
</style>
