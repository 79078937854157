import Vue, { VNode } from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import { validator, IRule } from '@utils/validation'

const CONFIRM_RULE = 'confirmed'

class VField {
	name: string
	rules: IRule[]
	dependencies: VField[] = []
	errors: string[] = []
	getter: () => any = null

	get value() {
		return this.getter()
	}

	get valid(): boolean {
		return this.errors.length === 0
	}
}

class VueValidator {
	fields: { [key: string]: VField } = {}
	paused = false

	hasError(name: string): boolean {
		let f = this.fields[name]
		return f && !f.valid
	}

	anyErrors(): boolean {
		for (let prop in this.fields) {
			if (!this.fields[prop].valid) return true
		}
		return false
	}

	addField(name: string, getter: () => any, rules: string) {
		if (!name) {
			throw new Error('validator field does not have a name')
		}

		let field: VField = null
		let ruleList = this.getRuleList(rules)
		if (rules.length >= 0) {
			field = new VField()
			field.name = name
			field.getter = getter
			this.applyRules(field, ruleList)
			Vue.set(this.fields, field.name, field)
		}
		return field
	}

	applyRules(field: VField, ruleList: IRule[]) {
		field.rules = ruleList
		let confirm = ruleList.find(r => r.name === CONFIRM_RULE)
		if (confirm) {
			let other = this.fields[confirm.arg]
			if (other) {
				other.dependencies.push(field)
			}
		}
	}

	getRuleList(rules) {
		let ruleStrings = rules.split('|')
		let ruleList = ruleStrings.map(r => validator.parseRule(r)).filter(r => r.name)
		return ruleList
	}

	addHtmlField(el: HTMLElement, rules: string): VField {
		let input = el as HTMLInputElement
		return this.addField(input.name, () => input.value, rules)
	}

	resume() {
		this.paused = false
	}

	pause() {
		this.paused = true
	}

	validate() {
		Vue.nextTick(() => {
			for (let prop in this.fields) {
				let field = this.fields[prop]
				this.validateField(field)
			}
		})
	}

	validateField(field: VField) {
		if (this.paused) return

		field.errors = []

		for (let i = 0; i < field.rules.length; i++) {
			let r = field.rules[i]
			let valid = true
			if (r.name === CONFIRM_RULE) {
				let other = this.fields[r.arg]
				valid = other && other.value === field.value
			} else {
				valid = validator.validateRule(field.value, r.name, r.arg)
			}
			if (!valid) {
				field.errors.push(validator.getError(field.name, r.name, r.arg))
			}
		}

		field.dependencies.forEach(d => this.validateField(d))
	}
}

export const ValidatorMixin = {
	data: function() {
		return {
			validator: new VueValidator(),
		}
	},
	methods: {
		hasError(field: string) {
			return this.validator.hasError(field)
		},
	},
	computed: {
		anyErrors() {
			return this.validator.anyErrors()
		},
	},
}

Vue.directive('validate', {
	bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		let v: VueValidator = vnode.context['validator']
		if (binding.value instanceof Object) {
			let vmodel = vnode.data['model']['expression']
			if (!vmodel) throw new Error('invalid validation field')

			let parts = vmodel.split('.')
			let getter = () => {
				let value = vnode.context
				parts.forEach(p => {
					value = value == null ? null : value[p]
				})
				return value
			}
			let field = v.addField(binding.value.name, getter, binding.value.rules)
			vnode.context.$watch(vmodel, () => v.validateField(field))
		} else {
			let field = v.addHtmlField(el, binding.value)
			if (!field) return

			el.addEventListener('change', () => v.validateField(field))
			if (el.tagName.toUpperCase() !== 'SELECT') {
				el.addEventListener('input', () => v.validateField(field))
			}
		}
	},
	componentUpdated(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		let v: VueValidator = vnode.context['validator']
		let fname = null
		let rules = null
		if (binding.value instanceof Object) {
			fname = binding.value.name
			rules = binding.value.rules
		} else {
			fname = (<HTMLInputElement>el).name
			rules = binding.value
		}
		let field = v.fields[fname]
		if (field) {
			let ruleList = v.getRuleList(rules)
			if (JSON.stringify(ruleList) === JSON.stringify(field.rules)) return
			v.applyRules(field, ruleList)
		}
	},
})
