import { API } from '@services/api'
import store from '@store'
import { getDateRanges } from '@utils/dateUtils'
import clone from '@utils/deepClone'
import Vue from 'vue'
import { eventBus } from './eventBus'

const defaultMenuSettings = {
	root: {
		// only affects the display order
		menuItems: [],
	},
	teleconsultation: {
		menuItems: [
			'ConsultantOpenAll',
			'ConsultantMyReports',
			'ConsultantRegionalReports',
			'ConsultantSTAT',
			'ConsultantUrgent',
			'ConsultantToday',
			'ConsultantYesterday',
			'ConsultantPastWeek',
			'ConsultantAll',
      'ConsultantPastMonth',
			// ConsultantDraft is hidden by default
		],
		filterPresets: [],
	},
}

const MenuTypes = {
	root: 'Root',
	teleconsultation: 'ConsultantTelemedicineMenu',
}

const defaultFilter = {
	term: '',
	order: {
		by: 'requestDate',
		isAscending: false,
	},
	modalityIds: [],
	startDate: '',
	endDate: '',
	studyStartDate: '',
	studyEndDate: '',
	responseStartDate: '',
	responseEndDate: '',
	isComplete: 'Open',
	isDraft: 'Any',
	requestType: '00000000-0000-0000-0000-000000000000',
	consultantId: '',
	claimedByConsultantId: '',
	region: '',
	clinicCode: '',
	species: '',
	breed: '',
	gender: '',
	studyDescription: '',
	draftedById: '',
}
const defaultFilterString = JSON.stringify(defaultFilter)

function extendDefaultFilter(options = {}) {
	return {
		...JSON.parse(defaultFilterString),
		...options,
	}
}

class MenuService {
	constructor() {
		this._menuSettings = {}
		this.reset()
		eventBus.on('clear-all-data', this.reset.bind(this))
	}

	reset() {
		this.presetFilters = {}
		this.menuSettings = clone(defaultMenuSettings)
	}

	get menuSettings() {
		return this._menuSettings
	}

	set menuSettings(value) {
		this._menuSettings = value
		this.refreshFilterPresets()
	}

	get requestTypes() {
		if (!store || !store.state) return []
		return Object.entries(store.state.consultant.requestTypes).sort((a, b) => {
			return a[1] < b[1] ? -1 : 1
		})
	}

	get consultants() {
		if (!store || !store.state) return []
		return store.state.static.consultants
	}

	get groupMembers() {
		if (!store || !store.state) return []
		return store.state.consultant.groupMembers
	}

	get userId() {
		return store && store.state.auth.claims.userId
	}

	get modalityOptions() {
		if (!store || !store.state) return []
		return store.state.static.modalities
	}

	get clientClinics() {
		if (!store || !store.state) return []
		return store.state.consultant.clientClinics
	}

	get regions() {
		if (!store || !store.state) return []
		const regions = Object.entries(store.state.consultant.regions).map(([name, id]) => ({
			name,
			id,
		}))
		regions.unshift({ name: 'My Regions', id: '00000000-0000-0000-0000-000000000000' })
		return regions
	}

	extendDefaultFilter() {
		return extendDefaultFilter()
	}

	getMenuSettings(menuType) {
		return API.get(`user/menu-settings/${menuType}`).then(r => r.data)
	}

	async getMenuItems() {
		const r = await API.get('user/menu-settings-with-permissions')
		return r.data.menuItems
	}

	_saveMenuSettings(menuType, settings) {
		return API.post(`user/menu-settings/${menuType}`, settings)
	}

	get teleconsultationMenuItems() {
		// add consultant services as menu items (excluding built-in priorities)
		const priorities = ['STANDARD', 'ANY', 'STAT', 'URGENT']
		const consultantServices = Object.entries(store.state.consultant.requestTypes).filter(
			([name, guid]) => !priorities.includes(name)
		)
		const consultantServiceMenuItems = consultantServices.map(this._createMenuItemFromConsultantService)
		let defaults = this._getDefaultTeleconsultationMenuItems()
		defaults.push(...consultantServiceMenuItems)
		let menuItems = []
		// build menu items from user settings
		for (let i = 0; i < this.menuSettings.teleconsultation.menuItems.length; i++) {
			const settingName = this.menuSettings.teleconsultation.menuItems[i]
			const menuItem = this._createMenuItemFromSetting(settingName, defaults)
			if (menuItem) menuItems.push(menuItem)
		}
		// add hidden (but otherwise available) menu items, which can be unhidden by user
		const hiddenMenuItems = defaults.filter(m => m.isAvailable && !m.isVisible)
		return menuItems.concat(hiddenMenuItems)
	}

	get topTeleconsultationMenuItem() {
		return this.teleconsultationMenuItems.length && this.teleconsultationMenuItems[0]
	}

	_getDefaultTeleconsultationMenuItems() {
		return [
			{
				name: 'Open',
				to: '/teleconsultations/?filter-preset=ConsultantOpenAll',
				folder: 'teleconsultations_ConsultantOpenAll',
				isAvailable: true,
				settingName: 'ConsultantOpenAll',
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'My Reports',
				to: '/teleconsultations/?filter-preset=ConsultantMyReports',
				folder: 'teleconsultations_ConsultantMyReports',
				isAvailable: store.state.auth.claims.isConsultantMember,
				settingName: 'ConsultantMyReports',
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'Region',
				to: '/teleconsultations/?filter-preset=ConsultantRegionalReports',
				folder: 'teleconsultations_ConsultantRegionalReports',
				isAvailable: store.state.static.permissions.showTeleconsultationScheduleFiltering,
				settingName: 'ConsultantRegionalReports',
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'STAT',
				to: '/teleconsultations/?filter-preset=ConsultantSTAT',
				folder: 'teleconsultations_ConsultantSTAT',
				isAvailable: store.state.static.permissions.showTeleconsultationScheduleFiltering,
				settingName: 'ConsultantSTAT',
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'Urgent',
				to: '/teleconsultations/?filter-preset=ConsultantUrgent',
				folder: 'teleconsultations_ConsultantUrgent',
				isAvailable: store.state.static.permissions.showTeleconsultationScheduleFiltering,
				settingName: 'ConsultantUrgent',
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'Pending Review',
				to: '/teleconsultations/?filter-preset=ConsultantDraft',
				folder: 'teleconsultations_ConsultantDraft',
				isAvailable: store.state.auth.claims.isConsultantApprovalSupported,
				settingName: 'ConsultantDraft',
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'Today',
				to: '/teleconsultations/?filter-preset=ConsultantToday',
				folder: 'teleconsultations_ConsultantToday',
				settingName: 'ConsultantToday',
				isAvailable: true,
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'Yesterday',
				to: '/teleconsultations/?filter-preset=ConsultantYesterday',
				folder: 'teleconsultations_ConsultantYesterday',
				settingName: 'ConsultantYesterday',
				isAvailable: true,
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'Past Week',
				to: '/teleconsultations/?filter-preset=ConsultantPastWeek',
				folder: 'teleconsultations_ConsultantPastWeek',
				settingName: 'ConsultantPastWeek',
				isAvailable: true,
				isVisible: false,
				isCustomPreset: false,
			},
			{
				name: 'All',
				to: '/teleconsultations/?filter-preset=ConsultantAll',
				folder: 'teleconsultations_ConsultantAll',
				settingName: 'ConsultantAll',
				isAvailable: false,
				isVisible: false,
				isCustomPreset: false,
			},
      {
        name: 'Past Month',
        to: '/teleconsultations/?filter-preset=ConsultantPastMonth',
        folder: 'teleconsultations_ConsultantPastMonth',
        settingName: 'ConsultantPastMonth',
        isAvailable: true,
        isVisible: false,
        isCustomPreset: false,
      },
		]
	}

	_createMenuItemFromSetting(settingName, defaults) {
		const defaultMenuItem = defaults.find(m => m.settingName === settingName)
		if (defaultMenuItem && defaultMenuItem.isAvailable) {
			defaultMenuItem.isVisible = true
			return defaultMenuItem
		}
		const customFilterPresets = this.menuSettings.teleconsultation.filterPresets
		const customFilterPreset = customFilterPresets.find(p => p.id === settingName)
		if (customFilterPreset) {
			return {
				name: customFilterPreset.name,
				to: `/teleconsultations/?filter-preset=${customFilterPreset.id}`,
				folder: `teleconsultations_${settingName}`,
				settingName,
				isAvailable: true,
				isVisible: true,
				isCustomPreset: true,
			}
		}
	}

	_createMenuItemFromConsultantService(consultantService) {
		return {
			name: consultantService[0],
			to: `/teleconsultations/?filter-preset=${consultantService[1]}`,
			folder: `teleconsultations_${consultantService[0]}`,
			settingName: consultantService[0],
			isAvailable: true,
			isVisible: false,
			isCustomPreset: false,
			id: consultantService[1],
		}
	}

	get menuItems() {
		return {
			root: this.menuSettings.root.menuItems,
			teleconsultation: this.teleconsultationMenuItems,
		}
	}

	async fetchMenuSettings() {
		let menuItemsAllowed = await this.getMenuItems()
		const fetchMenuSettingsForType = ([menu, menuType]) =>
			this.getMenuSettings(menuType).then(async settings => {

				if (settings && menuItemsAllowed.length) {
					const menuItemsAllowedFiltered = menuItemsAllowed.filter(menuItem => menuItem !== 'Repository Review')
					let filteredSettings = {
						filterPresets: settings.filterPresets,
						menuItems: [...menuItemsAllowedFiltered],
					}
					this.menuSettings[menu] = filteredSettings
				}

				if (settings && menuType !== 'Root') this.menuSettings[menu] = settings
			})
		await Promise.all(Object.entries(MenuTypes).map(fetchMenuSettingsForType))
	}

	async saveMenuSettings(menu, menuItems, filterPresets) {
		const newMenuSettings = clone(this.menuSettings)
		if (menuItems.some(m => typeof m === 'object')) {
			// convert menuItems to array of setting names
			const settingNames = menuItems.filter(m => m.isVisible).map(m => m.settingName)
			newMenuSettings[menu] = { menuItems: settingNames }
		} else {
			// menuItems is already an array of strings (or is empty)
			newMenuSettings[menu] = { menuItems }
		}
		if (filterPresets) newMenuSettings[menu].filterPresets = filterPresets
		this.menuSettings = newMenuSettings
		await this._saveMenuSettings(MenuTypes[menu], newMenuSettings[menu])
	}

	refreshFilterPresets() {
    const today = new Date()
		let result = {
      ConsultantPastMonth: extendDefaultFilter({
        isComplete: 'Any',
        ...getDateRanges().Month,
      }),
			ConsultantOpenAll: extendDefaultFilter({
				claimedByConsultantId: this.groupMembers.length ? '00000000-0000-0000-0000-000000000001' : '',
        ...getDateRanges().Month,
			}),
			complete: extendDefaultFilter({ isComplete: 'Complete' }),
			ConsultantMyReports: extendDefaultFilter({ claimedByConsultantId: this.userId }),
			ConsultantDraft: extendDefaultFilter({ isDraft: 'Yes' }),
			ConsultantRegionalReports: extendDefaultFilter({
				region: '00000000-0000-0000-0000-000000000000',
			}),
			ConsultantUrgent: extendDefaultFilter({
				requestType: this.getRequestTypeIdByName('URGENT'),
			}),
			ConsultantSTAT: extendDefaultFilter({
				requestType: this.getRequestTypeIdByName('STAT'),
			}),
			ConsultantToday: extendDefaultFilter({
				...getDateRanges().Today,
				isComplete: 'Any',
			}),
			ConsultantYesterday: extendDefaultFilter({
				...getDateRanges().Yesterday,
				isComplete: 'Any',
			}),
			ConsultantPastWeek: extendDefaultFilter({
				...getDateRanges().Week,
				isComplete: 'Any',
			}),
			ConsultantAll: extendDefaultFilter({
				isComplete: 'Any',
			}),
			...this.getConsultantServicePresets(),
			...this.getCustomFilterPresets(),
		}
		this.presetFilters = result
		return result
	}

	getConsultantServicePresets() {
		// create presets for consultant services, excluding priorities that already have built-in presets
		const priorities = ['STANDARD', 'ANY', 'STAT', 'URGENT']
		const consultantServices = this.requestTypes.filter(([name, guid]) => !priorities.includes(name))
		const consultantServicePresets = {}
		consultantServices.forEach(([name, guid]) => {
			consultantServicePresets[guid] = extendDefaultFilter({ requestType: guid })
		})
		return consultantServicePresets
	}

	getRequestTypeIdByName(name) {
		const service = this.requestTypes.find(s => s[0] === name)
		if (service) return service[1]
	}

	getRequestTypeNameById(guid) {
		const service = this.requestTypes.find(s => s[1] === guid)
		if (service) return service[0]
	}

	getConsultantNameById(id) {
		const consultant = this.consultants.find(c => c.id === id)
		if (consultant) return consultant.name
	}

	getGroupMemberNameById(id) {
		if (id === this.userId) return 'me'
		let groupMember = this.groupMembers.find(c => c.id === id)
		if (!groupMember) groupMember = this.consultants.find(c => c.id === id)
		if (groupMember) return groupMember.name
	}

	getClinicNameFromClinicCode(clinicCode) {
		const clinic = this.clientClinics.find(c => c.clinicCode === clinicCode)
		if (clinic) return clinic.name
	}

	getRegionNameById(id) {
		const region = this.regions.find(c => c.id === id)
		if (region) return region.name
	}

	getCustomFilterPresets() {
		if (!this.menuSettings.teleconsultation || !this.menuSettings.teleconsultation.filterPresets) return {}
		const customPresets = {}
		this.menuSettings.teleconsultation.filterPresets.forEach(preset => {
			const allDates = getDateRanges().all
			const requestDates = getDateRanges()[preset.requestDateRange] || allDates
			const studyDates = getDateRanges()[preset.studyDateRange] || allDates
			const responseDates = getDateRanges()[preset.responseDateRange] || allDates
			const modalityIds = this.modalityOptions.filter(o => o.name === preset.modality).map(o => o.id)
			customPresets[preset.id] = extendDefaultFilter({
				modalityIds,
				startDate: requestDates.startDate,
				endDate: requestDates.endDate,
				studyStartDate: studyDates.startDate,
				studyEndDate: studyDates.endDate,
				responseStartDate: responseDates.startDate,
				responseEndDate: responseDates.endDate,
				isComplete: preset.isComplete,
				isDraft: preset.isDraft,
				requestType: preset.requestType,
				region: preset.region,
				claimedByConsultantId: preset.claimedBy,
				clinicCode: preset.requestedBy,
			})
		})
		return customPresets
	}
}

export const menu = new MenuService()

/* eslint-disable no-new */
new Vue({
	data: {
		menu,
	},
	watch: {
		'menu.requestTypes'() {
			menu.refreshFilterPresets()
		},
	},
})
