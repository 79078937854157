





















import ModalWrapper from '@components/ModalWrapper.vue'
import PdfViewer from '@components/PdfViewer.vue'
import VideoPlayer from '@components/VideoPlayer.vue'
import { FileUtils } from '@utils/fileUtils'
import { findAttachmentUrl } from '@utils/urlUtility'
import { dlg } from '@utils/dlgUtils'

const AttachmentDlg = {
	name: 'AttachmentDlg',
	components: {
		ModalWrapper,
		PdfViewer,
		VideoPlayer,
	},
	props: {
		activePdfUrl: {
			type: String,
			default: null,
		},
		activeVideoUrl: {
			type: String,
			default: null,
		},
		imageId: {
			type: String,
			required: false,
			default: '',
		},
		isMuted: {
			type: Boolean,
			default: false,
		},
		showReviewButton: {
			type: Boolean,
			default: false,
		},
		showDownloadButton: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		close(data) {
			dlg.close(this, true, data || {})
		},
	},
}

export default AttachmentDlg

export function openAttachmentDlg(file: IViewerAttachment, isMuted: boolean = false, showPdfDownloadButton = true) {
	const fileUrl = findAttachmentUrl({
		imageId: file.imageId,
		clinicCode: file.storageLocation.clinicCode,
		endpointBaseUrl: file.storageLocation.endpointBaseUrl,
	})
	if (FileUtils.isPDF(file.fileExtension)) {
		dlg.open(AttachmentDlg, { activePdfUrl: fileUrl, showDownloadButton: showPdfDownloadButton })
	} else if (FileUtils.isVideo(file.fileExtension)) {
		dlg.open(AttachmentDlg, { activeVideoUrl: fileUrl, isMuted, imageId: file.imageId })
	} else {
		window.open(fileUrl)
	}
}

export function openPdfDlg(
	url: string,
	showReviewButton = false,
	showDownloadButton = true
): Promise<IPdfViewerResult> {
	return dlg.open(AttachmentDlg, {
		activePdfUrl: url,
		showReviewButton,
		showDownloadButton,
	})
}
