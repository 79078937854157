import { metaData } from 'cornerstone-core/dist/cornerstone.js'

/**
 *
 * @param {Object} voi
 * @param {Number} voi.windowWidth
 * @param {Number} voi.windowCenter
 * @param {Object} image
 * @param {Number} image.dicomWindowWidth
 * @param {Number} image.dicomWindowCenter
 * @param {Number} image.windowWidth
 * @param {Number} image.windowCenter
 * @param {String} image.imageId
 */
export function scaleJpegVoiToDicom(voi, image) {
	const from = {
		windowWidth: image.windowWidth,
		windowCenter: image.windowCenter,
	}
	const to = {
		windowWidth: image.dicomWindowWidth,
		windowCenter: image.dicomWindowCenter,
	}
	return scaleWwwc(voi, from, to, image.imageId)
}

/**
 *
 * @param {Object} voi
 * @param {Number} voi.windowWidth
 * @param {Number} voi.windowCenter
 * @param {Object} image
 * @param {Number} image.dicomWindowWidth
 * @param {Number} image.dicomWindowCenter
 * @param {Number} image.windowWidth
 * @param {Number} image.windowCenter
 * @param {String?} image.imageId
 */
export function scaleDicomPresetToJpeg(voi, image) {
	const from = {
		windowWidth: image.dicomWindowWidth,
		windowCenter: image.dicomWindowCenter,
	}
	const to = {
		windowWidth: image.windowWidth,
		windowCenter: image.windowCenter,
	}
	return scaleWwwc(voi, from, to, image.imageId)
}

function scaleWwwc(voi, from, to, imageId) {
	// If `voi` vales are same as `from` values, return default `to` values
	const isUnchanged = voi.windowWidth === from.windowWidth && voi.windowCenter === from.windowCenter
	if (isUnchanged)
		return {
			windowWidth: to.windowWidth,
			windowCenter: to.windowCenter,
		}
	// Calculate scale of width change
	const widthScale = voi.windowWidth / from.windowWidth
	// Calculate center delta as a percent of default width
	let centerChangePercent = (from.windowCenter - voi.windowCenter) / from.windowWidth
	// Negate center delta if photometric interpretation is not MONOCHROME1
	const imagePixelModule = (imageId && metaData.get('imagePixelModule', imageId)) || {}
	const { photometricInterpretation } = imagePixelModule
	if (photometricInterpretation !== 'MONOCHROME1') centerChangePercent *= -1
	return {
		windowWidth: to.windowWidth * widthScale,
		windowCenter: to.windowCenter + to.windowWidth * centerChangePercent,
	}
}
