import Vue from 'vue'

Vue.directive('focus', {
	inserted(el, binding) {
		if (binding.value === false) return // if passed an expression that evals to false, do not focus
		// if element is not an input, find its first child input instead
		if (el.tagName.toLowerCase() !== 'input') el = el.querySelector('input')
		el && el.focus()
	},
})
