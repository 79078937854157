import saveImage from '@utils/saveImage'

export default async function saveMprImageAsync({ getters, dispatch }) {
	// Get component for the active view
	const activeComp = getters.mprActiveView.component

	// Render the viewer to a base64 image
	const imgBase64 = await activeComp.renderImage()
	const fileName = `kso-${getters.activeImage.imageId}`
	await saveImage(imgBase64, fileName, dispatch)
}
