import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'

/**
 * Sits on top of cornerstone tool's `setToolActive` to allow us to
 * tack on some additional analytics
 *
 * @export @public @method
 * @param {*} { state, commit }
 * @param {String} { name }
 */
export default function({ commit }, { alias, options }) {
	const defaultOptions = {
		mouseButtonMask: 1,
		isTouchActive: true,
	}
	options = Object.assign({}, defaultOptions, options)
	// Clear the previous tool binding
	cornerstoneTools.setToolActive(alias, { mouseButtonMask: [] })
	// Set the new binding
	cornerstoneTools.setToolActive(alias, options)
	commit('UPDATE_TOOL_BINDINGS')
	// ga.event('Viewer', 'Set Active Tool', name)
}
