function setItem(key, value) {
	try {
		localStorage.setItem(key, value)
	} catch (err) {
		sessionStorage.setItem(key, value)
	}
}

function removeItem(key) {
	sessionStorage.removeItem(key)
	try {
		localStorage.removeItem(key)
	} catch (err) {}
}

function getItem(key) {
	try {
		const item = localStorage.getItem(key)
		if (item === null) return sessionStorage.getItem(key)
		return item
	} catch (err) {
		return sessionStorage.getItem(key)
	}
}

function getTokenUseCount() {
	return (getTokenUses() || []).length
}

function addTokenUse() {
	// We cannot simply set a tokenUseCount in localStorage because when multiple windows are opened within
	// the same second, they will all set the tokenUseCount to the same value.
	// Instead, we'll add a unique key to localStorage for every window to prevent collisions.
	setItem('token-use-' + (Date.now() + Math.floor(Math.random() * 10000)), '')
}

function removeTokenUse() {
	const tokenUses = getTokenUses()
	if (tokenUses.length) removeItem(tokenUses[0])
}

function clearTokenUses() {
	getTokenUses().forEach(removeItem)
}

function getTokenUses() {
	// Count the number of keys in storage that are prefixed with "token-use"
	const getTokenUseKeys = storage => Object.keys(storage).filter(k => k.startsWith('token-use'))
	try {
		return getTokenUseKeys(localStorage)
	} catch (err) {
		return getTokenUseKeys(sessionStorage)
	}
}

function isLocalStorageSupported() {
	try {
		localStorage.setItem('test', 'test')
		localStorage.removeItem('test')
		return true
	} catch (err) {
		return false
	}
}

export default {
	setItem,
	removeItem,
	getItem,
	getTokenUseCount,
	addTokenUse,
	removeTokenUse,
	clearTokenUses,
	isLocalStorageSupported,
}
