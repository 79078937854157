import isMobileOS from '@utils/isMobileOS'
import minWidths from '@styles/_vars.scss'

let mq = {
	ipadSize: screen.width >= 768, // should not change
}
// iterate over exported scss variables (small, medium, large, xLarge)
for (const key in minWidths) {
	const mql = window.matchMedia(`(min-width: ${minWidths[key]}), print`)
	mq[key] = mql.matches // e.g. mq.medium = true if viewport width is $mqMedium or wider
	mql.addListener(e => (mq[key] = e.matches))
}

export default {
	data() {
		return {
			mq,
			isMobileOS: isMobileOS(),
		}
	},
}
