import _ from "lodash/fp";

export default function(options) {
	// No Name
	let name = options.name || options || 'No Name'
	// NoName
	let sortName = options.sortName || name.replace(/\s+/g, '')
	// noName
	let columnName = options.columnName || sortName.charAt(0).toLowerCase() + sortName.slice(1)

	let isSortable = options.isSortable === undefined || options.isSortable

  let dataColumnName = options.dataColumnName || ''

  let isVisible = _.isUndefined(options.isVisible)? true : options.isVisible

  let showInSelector = _.isUndefined(options.showInSelector)? true : options.showInSelector

  let classStr = options.classStr || ''

  let styleStr = options.styleStr || ''

  let dataColumnType = options.dataColumnType || 'text'

  let Id = options.id

  let isVisibleCondition = options.isVisibleCondition || null

  let showInSelectorCondition = options.showInSelectorCondition || null

	return {
    Id,
		name,
		sortName,
		columnName,
		isSortable,
    dataColumnName,
    isVisible,
    showInSelector,
    classStr,
    styleStr,
    dataColumnType,
    isVisibleCondition,
    showInSelectorCondition
	}
}
