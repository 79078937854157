const isISOString = str => /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/.test(str)

export function stringToDate(dateString, forceUTC = true) {
	if (!dateString) return ''
	if (isISOString(dateString)) {
		const hasTimeZone = /T(.*)(-|\+|Z)/.test(dateString)
		if (!hasTimeZone && forceUTC) dateString += 'Z' // set missing offset to UTC
		if (!hasTimeZone && !forceUTC) dateString += getTimeZoneOffset() // set offset to local
	}
	if (/\d{8}/.test(dateString)) {
		const YYYY = dateString.substring(0, 4)
		const MM = dateString.substring(4, 6)
		const DD = dateString.substring(6, 8)
		dateString = `${YYYY}-${MM}-${DD}T00:00`
	}
	const date = new Date(dateString)
	// if we're trying to force a local date, remove any daylight savings compensation applied by the Date constructor
	if (!forceUTC) {
		const offsetDifference = new Date().getTimezoneOffset() - date.getTimezoneOffset()
		date.setMinutes(date.getMinutes() - offsetDifference)
	}
	return date
}

function getTimeZoneOffset() {
	const offset = -new Date().getTimezoneOffset()
	const sign = offset >= 0 ? '+' : '-'
	const toTwoDigits = num => ('0' + Math.abs(num)).slice(-2)
	return sign + toTwoDigits(Math.floor(offset / 60)) + ':' + toTwoDigits(offset % 60)
}

export function getDateRanges() {
	const today = new Date()
	today.setHours(0, 0, 0, 0)

	const oneYearAgo = new Date()
	oneYearAgo.setFullYear(today.getFullYear() - 1)
	oneYearAgo.setHours(0, 0, 0, 0)

	const oneMonthAgo = new Date()
	oneMonthAgo.setMonth(today.getMonth() - 1)
	oneMonthAgo.setHours(0, 0, 0, 0)

	const startOfLastMonth = new Date()
	startOfLastMonth.setMonth(today.getMonth() - 1)
	startOfLastMonth.setDate(1)
	startOfLastMonth.setHours(0, 0, 0, 0)

	const endOfLastMonth = new Date()
	endOfLastMonth.setDate(0)
	endOfLastMonth.setHours(23, 59, 59, 999)

	const startOfThisMonth = new Date()
	startOfThisMonth.setDate(1)
	startOfThisMonth.setHours(0, 0, 0, 0)

	const endOfThisMonth = new Date()
	endOfThisMonth.setMonth(today.getMonth() + 1)
	endOfThisMonth.setDate(0)
	endOfThisMonth.setHours(23, 59, 59, 999)

	const oneWeekAgo = new Date()
	oneWeekAgo.setDate(today.getDate() - 7)
	oneWeekAgo.setHours(0, 0, 0, 0)

	const startOfLastWeek = new Date()
	startOfLastWeek.setDate(today.getDate() - today.getDay() - 7)
	startOfLastWeek.setHours(0, 0, 0, 0)

	const endOfLastWeek = new Date()
	endOfLastWeek.setDate(today.getDate() - today.getDay() - 1)
	endOfLastWeek.setHours(23, 59, 59, 999)

	const startOfThisWeek = new Date()
	startOfThisWeek.setDate(today.getDate() - today.getDay())
	startOfThisWeek.setHours(0, 0, 0, 0)

	const endOfThisWeek = new Date()
	endOfThisWeek.setDate(today.getDate() - today.getDay() + 6)
	endOfThisWeek.setHours(23, 59, 59, 999)

	const startOfYesterday = new Date()
	startOfYesterday.setDate(today.getDate() - 1)
	startOfYesterday.setHours(0, 0, 0, 0)

	const endOfYesterday = new Date()
	endOfYesterday.setDate(today.getDate() - 1)
	endOfYesterday.setHours(23, 59, 59, 999)

	const startOfTomorrow = new Date()
	startOfTomorrow.setDate(today.getDate() + 1)
	startOfTomorrow.setHours(0, 0, 0, 0)

	const endOfTomorrow = new Date()
	endOfTomorrow.setDate(today.getDate() + 1)
	endOfTomorrow.setHours(23, 59, 59, 999)

	const startOfToday = new Date()
	startOfToday.setHours(0, 0, 0, 0)

	const endOfToday = new Date()
	endOfToday.setHours(23, 59, 59, 999)

	return {
		All: {
			startDate: '',
			endDate: '',
		},
		Year: {
			startDate: oneYearAgo,
			endDate: endOfToday,
		},
		Month: {
			startDate: oneMonthAgo,
			endDate: endOfToday,
		},
		LastMonth: {
			startDate: startOfLastMonth,
			endDate: endOfLastMonth,
		},
		ThisMonth: {
			startDate: startOfThisMonth,
			endDate: endOfThisMonth,
		},
		Week: {
			startDate: oneWeekAgo,
			endDate: endOfToday,
		},
		LastWeek: {
			startDate: startOfLastWeek,
			endDate: endOfLastWeek,
		},
		ThisWeek: {
			startDate: startOfThisWeek,
			endDate: endOfThisWeek,
		},
		Yesterday: {
			startDate: startOfYesterday,
			endDate: endOfYesterday,
		},
		Tomorrow: {
			startDate: startOfTomorrow,
			endDate: endOfTomorrow,
		},
		Today: {
			startDate: startOfToday,
			endDate: endOfToday,
		},
	}
}

export const dateRangeLabels = {
	All: 'All Time',
	Year: 'Past Year',
	Month: 'Past Month',
	LastMonth: 'Last Month',
	ThisMonth: 'This Month',
	Week: 'Past Week',
	LastWeek: 'Last Week',
	ThisWeek: 'This Week',
	Yesterday: 'Yesterday',
	Today: 'Today',
}

export function getTomorrow() {
	const tomorrow = new Date()
	tomorrow.setDate(new Date().getDate() + 1)
	return tomorrow
}

export function isSameDate(a, b) {
	if (a === b) return true
	if (!a || !b) return false
	if (!a.getDate || !b.getDate) return false
	return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate()
}

export const dateDifferenceInMonths = (startDate, endDate) => {
  return Math.max(
    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
    endDate.getMonth() -
    startDate.getMonth(),
    0
  )
}

export const addMonths = (dt, n) => {
  return new Date(new Date(dt).setMonth(dt.getMonth() + n));
}
