<template>
	<div class="card" :class="{ 'is-hoverable': isHoverable }">
		<!-- eslint-disable-next-line vue/no-v-html -->
		<div v-if="hasHeader" class="card-heading" :class="type" v-html="header">
			Placeholder Heading
		</div>
		<div class="card-wrapper" aria-expanded="true">
			<div class="card-body">
				<slot></slot>
			</div>
		</div>

		<transition name="loading-fade">
			<div v-if="isLoading">
				<div class="card-overlay">
					<svg-icon icon="spinner" pulse />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'Card',
	props: {
		header: {
			type: String,
			required: false,
			default: '',
		},
		type: {
			type: String,
			required: false,
			default: 'default',
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isHoverable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		hasHeader() {
			// not ( null/undefined OR empty )
			return !(!this.header || this.header.length === 0)
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.card {
	position: relative;
	margin-bottom: 16px;
	background: var(--card-bg);
	border: 1px solid var(--card-border);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

	.card-wrapper {
		position: relative;
	}

	.card-heading {
		font-weight: 600;
		padding: 12px 16px;
		&.default {
			background: var(--card-header-default-bg);
			color: var(--card-header-default-color);
		}
		&.info {
			background: var(--card-header-info-bg);
			color: var(--card-header-info-color);
		}
	}

	.card-body {
		padding: 16px;
	}

	@media (max-width: $mqMedium) {
		.card-heading,
		.card-body {
			padding: 8px;
		}
	}

	&.is-hoverable:hover {
		cursor: pointer;
		transform: translateY(-3px);
		box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
	}
}

/*
 * Overlay
 *
*/
.card-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3em;
	position: absolute;
	background: var(--secondary-bg);
	border: none;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.6;
	cursor: wait;
}
</style>
