<template>
	<v-select
		v-model="selection"
		class="consigner-select"
		:options="options"
		label="name"
		:searchable="false"
		:reduce="o => o.id"
		:placeholder="placeholder"
		:clear-search-on-select="false"
		:multiple="multiple"
		style="min-width: 140px;"
		dropdown-min-width="300px"
		:clearable="false"
	>
		<template #option="consigner">
			{{ consigner.name }}
			<span style="padding-left: 4px; opacity: 0.8; font-size: 0.9em;">
				{{ consigner.description }}
			</span>
		</template>
	</v-select>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'ConsignerSelect',
	props: {
		value: {
			type: [Array, Number],
			default() {
				return this.multiple ? [] : null
			},
		},
		showAll: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: null,
		},
		nullable: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState({
			consignerNames: state => state.static.consignerNames,
		}),
		selection: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		options() {
			const options = this.consignerNames || []
			if (this.nullable && !this.multiple)
				// add null option to list
				options.unshift({
					id: null,
					name: ' ',
				})
			return options
		},
	},
}
</script>
