import { eventBus } from '@services/eventBus'
import { get, set } from 'lodash'
import Vue from 'vue'

// prettier-ignore
const {
	VUEX_STATE_INIT_REQ,
	VUEX_STATE_INIT_RES,
	VUEX_STATE_UPDATE,
	VUEX_ACTION,
	VUEX_MUTATION,
} = eventBus.type

// Set of state paths to sync with other windows
const syncState = [
	'viewer.calibrations',
	'viewer.settingsPanel.overlayText',
	'viewer.settingsPanel.toolbarLocation',
	'viewer.settingsPanel.isImageOrientationMarkersEnabled',
	'viewer.settingsPanel.isLengthAnglesEnabled',
	'viewer.settingsPanel.isReferenceLineSynchronizationEnabled',
	'viewer.settingsPanel.isStackScrollSynchronizationEnabled',
	'viewer.settingsPanel.isManualStackScrollSynchronizationEnabled',
	'reportColorSettings',
	'mpr.showOverlayText',
	'mpr.showImageOrientationMarkers',
	'mpr.showAxisOverlay',
	'mpr.syncWindowLevels',
]

// Sync Cornerstone/Viewer events. These are off by default, but turned on when the viewer is loaded
export const syncViewerActions = {
	setImageOrientationMarkers: false,
	setLengthAngles: false,
	setReferenceLineSynchronization: false,
	setStackSynchronizationMethod: false,
	virtualNewImage: false,
	virtualStackScroll: false,
	requestImageStackForManualSync: false,
	setImageStackForManualFromExternal: false,
	updateExternalToolStateForId: false,
	clearAnnotations: false,
}
// Set of actions & mutations that can be synced with other windows
// Action and mutation sync can be enabled/disabled externally
export const syncActions = {
	calibrateFromExternalImage: true,
	clearCalibration: true,
	setActiveTool: true,
	setHotkeysForAuthenticatedUser: true,
	refreshWindowLevelPresetsForAuthenticatedUser: true,
	refreshToken: true,
	logOut: true,
	setTheme: true,
	saveReportColorSettingsAction: true,
	...syncViewerActions,
}
const syncMutations = {
	SET_MPR_ACTIVE_TOOL: true,
	SET_OPEN_STUDIES_NEW_WINDOW: true,
}
let _globalSyncEnabled = false

// This plugin will synchronize a whitelisted set of state paths, actions
// and mutations between different windows
export default function vuexStoragePlugin(store) {
	// Handle state init requests from other windows
	eventBus.on(VUEX_STATE_INIT_REQ, () => {
		const state = {}
		syncState.forEach(path => {
			state[path] = get(store.state, path)
		})
		execProtected(() => eventBus.broadcast(VUEX_STATE_INIT_RES, state))
		// Start syncing if we got this request
		startSync()
	})

	// Handle state init responses from requests that we send
	eventBus.on(VUEX_STATE_INIT_RES, state => {
		// No need to initialize if we're already synchronizing
		if (_globalSyncEnabled) return
		execProtected(() => {
			Object.entries(state).forEach(([path, value]) => {
				set(store.state, path, value)
			})
			// Start syncing if we got this response
			startSync()
		})
	})

	// If we were launched from another window...
	if (window.opener || window.parent) {
		// Request that external store seed our state
		eventBus.broadcast(VUEX_STATE_INIT_REQ)
	}

	// Watch and broadcast events for each sync state path...
	syncState.forEach(path =>
		store.watch(
			state => get(state, path),
			value => {
				if (!_globalSyncEnabled) return
				execProtected(() => eventBus.broadcast(VUEX_STATE_UPDATE, { path, value }))
			}
		)
	)

	// Handle state updates broadcast from other windows
	eventBus.on(VUEX_STATE_UPDATE, ({ path, value } = {}) => {
		if (!_globalSyncEnabled || !syncState.includes(path)) return
		execProtected(() => set(store.state, path, value))
	})
	// Handle actions broadcast from other windows
	eventBus.on(VUEX_ACTION, ({ type, payload } = {}) => {
		if (!_globalSyncEnabled || !syncActions[type]) return
		execProtected(() => {
			store.dispatch(type, payload)
		})
	})
	// Handle mutations broadcast from other windows
	eventBus.on(VUEX_MUTATION, ({ type, payload } = {}) => {
		if (!_globalSyncEnabled || !syncMutations[type]) return
		execProtected(() => store.commit(type, payload))
	})
}

function startSync() {
	Vue.nextTick(() => (_globalSyncEnabled = true))
}

// This function prevents repetitive update cycles
// between all browser windows
let ignoreUpdates = false
function execProtected(fn) {
	if (ignoreUpdates) return
	ignoreUpdates = true
	fn()
	Vue.nextTick(() => {
		ignoreUpdates = false
	})
}
