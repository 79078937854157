import copyImageToClipboard from '@utils/copyImageToClipboard'

export default async function copyMprImageAsync({ getters, dispatch }) {
	// Get component for the active view
	const activeComp = getters.mprActiveView.component

	// Render the viewer to a base64 image
	const imgBase64 = await activeComp.renderImage()

	// Copy image to clipboard (with notifications)
	await copyImageToClipboard(imgBase64, dispatch)
}
