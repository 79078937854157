import Vue from 'vue'

interface ISortableElement extends HTMLElement {
	sortable: any
}

function Sortable(el: ISortableElement, binding, vnode) {
	el.sortable = this
	var list = []

	var dragElement = null
	var origIndex = 0
	var newIndex = 0
	var dropped = false

	doUpdate(binding, vnode)
	this.update = doUpdate

	function doUpdate(binding, vnode) {
		if (list !== binding.value) {
			list = binding.value

			var children = el.children
			for (var i = 0; i < children.length; i++) {
				var child = children[i] as HTMLElement
				child.draggable = true
				child.ondragstart = dragStart
				child.ondragenter = dragEnter
				child.ondragover = dragOver
				child.ondragend = dragEnd
				child.ondrop = drop
			}
		}
	}

	function dragStart(event) {
		dropped = false
		dragElement = event.target
		origIndex = newIndex = getTargetIndex(dragElement)
		event.dataTransfer.setData('Text', this.id)
		event.cancelBubble = true
	}

	function getTargetIndex(target) {
		var result = -1
		var cur = target
		while (cur) {
			if (cur.parentElement === el) {
				result = Array.prototype.indexOf.call(el.children, cur)
				break
			} else {
				cur = cur.parentElement
			}
		}
		return result
	}

	function dragOver(event) {
		if (dragElement && getTargetIndex(event.target) >= 0) {
			event.preventDefault()
		}
	}

	function dragEnter(event) {
		var targetIndex = getTargetIndex(event.target)
		if (dragElement && targetIndex >= 0) {
			moveItem(newIndex, targetIndex)
			newIndex = targetIndex
			event.preventDefault()
		}
	}

	function moveItem(fromIndex, toIndex) {
		if (fromIndex !== toIndex) {
			var item = list[fromIndex]
			list.splice(fromIndex, 1)
			list.splice(toIndex, 0, item)
			if (vnode.context.onItemMoved) {
				vnode.context.onItemMoved(list, fromIndex, toIndex)
			}
		}
	}

	function drop(ev) {
		if (dragElement) {
			dropped = true
		}
	}

	function dragEnd(event) {
		if (dragElement) {
			if (!dropped) {
				moveItem(newIndex, origIndex)
			}
			dragElement = null
		}
	}
}

Vue.directive('sortable', {
	bind: function(el, binding, vnode) {
		el.sortable = new Sortable(el, binding, vnode)
	},
	componentUpdated: function(el: ISortableElement, binding, vnode) {
		if (el.sortable) {
			el.sortable.update(binding, vnode)
		} else {
			el.sortable = new Sortable(el, binding, vnode)
		}
	},
})
