import { renderWebImage } from 'cornerstone-core/dist/cornerstone'

export const jpegCanvas = document.createElement('canvas')
const jpegContext = jpegCanvas.getContext('2d')

export default async function decodeJpeg(arrayBuffer: ArrayBuffer): Promise<any> {
	const imageElement = await convertArrayBufferToImageElement(arrayBuffer)
	const pixelData = getPixelDataForJpeg(imageElement)
	return {
		imageElement,
		pixelData,
		render: renderWebImage,
		rows: imageElement.naturalHeight,
		columns: imageElement.naturalWidth,
		isColor: true,
		rgba: false,
		minPixelValue: 0,
		maxPixelValue: 255,
		windowCenter: 128,
		windowWidth: 255,
		slope: 1,
		intercept: 0,
		invert: false,
	}
}

function convertArrayBufferToImageElement(arrayBuffer: ArrayBuffer): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const image = new Image()
		const arrayBufferView = new Uint8Array(arrayBuffer)
		const blob = new Blob([arrayBufferView])
		const urlCreator = window.URL || window.webkitURL
		const imageUrl = urlCreator.createObjectURL(blob)

		image.src = imageUrl
		image.onload = () => {
			resolve(image)
			urlCreator.revokeObjectURL(imageUrl)
		}

		image.onerror = error => {
			urlCreator.revokeObjectURL(imageUrl)
			reject(error)
		}
	})
}

function getPixelDataForJpeg(imageElement: HTMLImageElement): Uint8ClampedArray {
	jpegCanvas.height = imageElement.naturalHeight
	jpegCanvas.width = imageElement.naturalWidth
	jpegContext.drawImage(imageElement, 0, 0)
	const imageData = jpegContext.getImageData(0, 0, imageElement.naturalWidth, imageElement.naturalHeight)
	return imageData.data
}
