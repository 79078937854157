import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { synchronizers } from '../../toolManager/synchronizers'
import { waitForElementToBeEnabled, waitForEnabledElementImageToLoad } from '@/utils/wait.js'
import { eventBus } from '@services/eventBus'

/**
 * Turns reference line synchronization on for all canvases. Uses
 * the currently active canvas as the "source" canvas.
 *
 * @public
 * @function toggleReferenceLineSynchronization
 * @param {*} { state, commit }
 * @returns {undefined}
 */
export function toggleReferenceLineSynchronization({ state, dispatch }) {
	dispatch(
		'setReferenceLineSynchronization',
		!state.settingsPanel.isReferenceLineSynchronizationEnabled
	)
}

export function setReferenceLineSynchronization({ state, commit }, value) {
	// Update Cornerstone Synchronizer
	if (value) {
		setSynchronizerSource(state.canvases, state.activeCanvasIndex)
		cornerstoneTools.setToolEnabled('ReferenceLines', {
			synchronizationContext: synchronizers.referenceLinesSynchronizer,
		})
	} else {
		cornerstoneTools.setToolDisabled('ReferenceLines')
	}
	synchronizers.referenceLinesSynchronizer.enabled = value
	commit('SET_REFERENCE_LINE_SYNCHRONIZATION', value)
	eventBus.broadcast(eventBus.type.VUEX_ACTION, {
		type: 'setReferenceLineSynchronization',
		payload: value,
	})
}

/**
 * Finds the active canvas and updates the referenceLinesSynchronizer to
 * use it as the source image.
 *
 * @private
 * @param {*} canvases
 * @param {*} activeCanvasIndex
 * @returns {undefined}
 */
async function setSynchronizerSource(canvases, activeCanvasIndex) {
	// Remove all
	synchronizers.referenceLinesSynchronizer.destroy()

	if (activeCanvasIndex === -1) return

	// Setup
	const canvas = canvases[activeCanvasIndex]
	const isEnabled = await waitForElementToBeEnabled(canvas.dom)
	const hasLoadedImage = await waitForEnabledElementImageToLoad(canvas.dom)
	const hasImage = canvas.imageId || canvas.seriesId

	if (isEnabled && hasLoadedImage && canvas.dom && hasImage)
		synchronizers.referenceLinesSynchronizer.addSource(canvas.dom)
}
