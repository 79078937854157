import storage from './storage'

const featureFlags = [
	{
		key: 'HANGING_PROTOCOLS_IS_ACTIVE',
		defaultValue: 0,
		status: 'deprecated',
	},
	{
		key: 'UseHangingProtocolsV2',
		defaultValue: 0,
		status: 'active',
	},
]

function cleanDeprecatedFeatureFlags() {
	featureFlags.forEach(featureFlag => {
		if (featureFlag.status === 'deprecated') storage.removeItem(featureFlag.key)
	})
}

function setFeatureFlags() {
	featureFlags.forEach(featureFlag => {
		if (!storage.getItem(featureFlag.key) && featureFlag.status !== 'deprecated') {
			storage.setItem(featureFlag.key, JSON.stringify(featureFlag.defaultValue))
		}
	})
}

function setFeatureFlagValue(featureFlag, value) {
	const key = storage.getItem(featureFlag)
	if (key) {
		storage.setItem(featureFlag, JSON.stringify(value))
		return console.info(`The Feature Flag: "${featureFlag}" was set to: "${value}"`)
	}
	return console.error(
		`The Feature Flag: "${featureFlag}", does not exists, check the documentation to see the featureFlags available`
	)
}

function checkFeatureFlagValues() {
	const currentFeatureFlags = featureFlags.map(featureFlag => {
		return {
			key: featureFlag.key,
			value: storage.getItem(featureFlag.key) ? storage.getItem(featureFlag.key) : featureFlag.defaultValue,
		}
	})

	return console.info(currentFeatureFlags)
}

function getFeatureFlag(featureFlag) {
	const key = storage.getItem(featureFlag)
	if (key) {
		return parseInt(storage.getItem(featureFlag))
	}
	console.error(`The Feature Flag: "${featureFlag}" does not exist`)
}

export default {
	cleanDeprecatedFeatureFlags,
	setFeatureFlags,
	setFeatureFlagValue,
	checkFeatureFlagValues,
	getFeatureFlag,
}
