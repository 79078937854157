import Vue from 'vue'
import { formatNumber } from '@utils/numberUtils'

export const fileSize = bytes => {
	if (typeof bytes !== 'number' || isNaN(bytes)) return ''
	const units = ['B', 'KB', 'MB', 'GB', 'TB']
	if (Math.abs(bytes) < 1024) return formatNumber(bytes) + ' B'
	const powerOfTen = Math.min(Math.floor(Math.log(bytes) / Math.log(1000)), units.length - 1)
	bytes = (bytes / Math.pow(1024, powerOfTen)).toFixed(2) * 1
	bytes = formatNumber(bytes)
	const unit = units[powerOfTen]
	return bytes + ' ' + unit
}

const msToDuration = function(ms) {
	let term = ''
	let value = 0

	let minutes = ms / 1000 / 60
	if (minutes > 60) {
		let hours = minutes / 60
		if (hours > 24) {
			value = hours / 24
			term = 'day'
		} else {
			value = hours
			term = 'hour'
		}
	} else {
		value = minutes
		term = 'minute'
	}

	value = Math.round(value)
	return `${value} ${term}${value > 1 || value === 0 ? 's' : ''}`
}

const url = value => {
	if (value.substring(0, 4).toLowerCase() !== 'http') {
		value = 'http://' + value
	}
	return value
}

const capitalize = str => {
	return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}

Vue.filter('msToDuration', msToDuration)
Vue.filter('fileSize', fileSize)
Vue.filter('url', url)
Vue.filter('capitalize', capitalize)
