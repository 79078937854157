/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
	// Still keeping the service worker around until we can nix PWA architecture
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.info('Controller loaded')
		},
		cached() {
			console.info('Content has been cached for offline use.')
		},
		updated() {
			console.info('New content is available.')
		},
		offline() {
			console.warn('No internet connection found. App is running in offline mode.')
		},
		error(error) {
			console.error('Error during service worker registration:', error)
		},
	})
}
