import * as Sentry from '@sentry/browser'
import createImageFromEnabledElementAsync from './_shared/createImageFromEnabledElementAsync.js'
import printImage from '@utils/printImage'

export default async function({ state, dispatch, rootGetters }, canvasIndex) {
	// Provided canvasIndex or default to activeCanvas
	const targetCanvas =
		canvasIndex === undefined ? rootGetters.activeCanvas : state.canvases[canvasIndex]

	if (!targetCanvas) {
		return
	}
	if (!targetCanvas.seriesId && !targetCanvas.imageId) return

	try {
		const imgBase64 = await createImageFromEnabledElementAsync(targetCanvas.dom)
		printImage(imgBase64, dispatch)
	} catch (err) {
		const sentryEventId = Sentry.captureException(err)
		dispatch('addNotification', {
			message: 'Unable to create a printable image.',
			notificationType: 'warn',
			sentryEventId,
		})
	}
}
