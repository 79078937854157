<template>
  <div class="progress">
    <div
      class="progress-bar striped animated"
      role="progressbar"
      :aria-valuenow="width"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="barInlineStyle"
    >
      <span class="sr-only">{{ width }}% Complete</span>
    </div>

    <div class="text-overlay-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    width: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    barInlineStyle() {
      return { width: this.width.toString() + '%' }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@styles/_vars.scss";

$default-bar-height: 20px;
$default-bar-color: #03a9f3;
$bar-color-success: #63b345;

.progress {
  position: relative;
  height: $default-bar-height;
  background-color: rgba(120, 130, 140, 0.28);
  overflow: hidden;
}

.progress-bar,
.text-overlay-container {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  line-height: $default-bar-height;
}

.progress-bar {
  background-color: $default-bar-color;

  float: left;
  height: 100%;
  text-align: center;
  transition: width 0.2s ease;

  &.progress-bar-success {
    background: $bar-color-success;
  }

  &.striped {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 40px 40px;
  }

  &.striped.animated {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
  }
}

.text-overlay-container {
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
</style>
