<template>
	<modal-wrapper @close="close">
		<!-- Entire modal is drop target if only image uploads are available -->
		<div ref="uploadContainer" class="upload-container" :class="{ 'expand-height': uploads.length }">
			<dlg-header class="upload-header" :title="title" @close="close" />

			<div class="upload-body">
				<!-- Image Uploads -->
				<div
					v-if="showImageUpload"
					ref="imageDrop"
					class="drop-target"
					:class="{ 'sole-upload-form': !showAttachmentUpload }"
				>
					<upload-form
						class="modal-upload-form"
						:context="context"
						:allow-attachments="false"
						:allow-jpegs="true"
						:allow-dicoms="true"
						:drop-target="imageDrop"
					/>
					<div v-if="images.length || excludedImages.length" class="upload-items">
						<!-- Excluded Files -->
						<transition name="expand">
							<ol v-if="excludedImages.length" class="excluded-files">
								<li is="UploadItemFile" v-for="(file, i) in excludedImages" :key="i" :upload="file" />
								<!-- Clear excluded files button -->
								<ast-button class="close" @click.native="clearExcludedFiles(context)">
									<svg-icon icon="close" />
								</ast-button>
							</ol>
						</transition>
						<!-- Studies -->
						<transition-group name="expand">
							<upload-item-batch
								v-for="batchId in imageBatchIds"
								:key="batchId ? batchId : 'jpeg-form'"
								:uploads="getUploadsInBatch(batchId)"
								:show-view-button="showViewButton"
								@remove-batch="removeBatch"
								@study-complete="addStudyId"
							/>
						</transition-group>
					</div>
				</div>

				<!-- Attachment Uploads -->
				<div v-if="showAttachmentUpload" ref="attachmentDrop" class="drop-target">
					<upload-form
						class="modal-upload-form"
						:context="context"
						:allow-attachments="true"
						:allow-jpegs="false"
						:allow-dicoms="true"
						:drop-target="attachmentDrop"
					/>
					<div v-if="attachments.length || excludedAttachments.length" class="upload-items">
						<!-- Excluded Files -->
						<transition name="expand">
							<ol v-if="excludedAttachments.length" class="excluded-files">
								<li is="UploadItemFile" v-for="(file, i) in excludedAttachments" :key="i" :upload="file" />
								<!-- Clear excluded files button -->
								<ast-button class="close" @click.native="clearExcludedFiles(context)">
									<svg-icon icon="close" />
								</ast-button>
							</ol>
						</transition>
						<!-- Attachment Study Metadata Form -->
						<transition name="expand">
							<div v-if="showAttachmentStudyForm" class="attachment-study-form">
								<upload-study-form :context="context" :is-attachment="true" />
							</div>
						</transition>
						<!-- Attachments -->
						<transition-group name="expand">
							<upload-item-batch
								v-for="batchId in attachmentBatchIds"
								:key="batchId ? batchId : 'attachment-form'"
								:uploads="getUploadsInBatch(batchId)"
								@remove-batch="removeBatch"
								@study-complete="addStudyId"
							/>
						</transition-group>
					</div>
				</div>
			</div>
			<div v-if="uploads.length && cancelOnClose" class="upload-footer">
				<ast-button type="primary" :class="{ disabled: isUploading || isProcessing }" @click.native="close">
					<span>OK</span>
				</ast-button>
				<ast-button v-if="isUploading || isProcessing" @click.native="close">
					Cancel
				</ast-button>
				<em v-if="isUploading">
					Please wait while your files are uploaded ...
				</em>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import store from '@store'

import AstButton from '@components/Button'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper'
import UploadForm from '@components/UploadForm'

import { dlg } from '@utils/dlgUtils'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { uploadData } from '@services/uploads'

const UploadDlg = {
	name: 'UploadDlg',
	components: {
		AstButton,
		DlgHeader,
		ModalWrapper,
		UploadForm,
		UploadItemBatch: () => import(/* webpackChunkName: "componentUploadItemBatch" */ '@components/UploadItemBatch'),
		UploadItemFile: () => import(/* webpackChunkName: "componentUploadItemFile" */ '@components/UploadItemFile'),
		UploadStudyForm: () => import(/* webpackChunkName: "componentUploadStudyForm" */ '@components/UploadStudyForm'),
	},
	props: {
		context: {
			type: String,
			required: true,
		},
		showImageUpload: {
			type: Boolean,
			default: true,
		},
		showAttachmentUpload: {
			type: Boolean,
			default: false,
		},
		showViewButton: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			default: '',
		},
		cancelOnClose: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			attachmentDrop: undefined,
			imageDrop: undefined,
			uploadedBatchIds: [],
			studyIds: [],
			uploadData,
		}
	},
	computed: {
		excludedFiles() {
			return uploadData.excludedFiles.filter(upload => upload.context === this.context)
		},
		uploads() {
			return uploadData.uploads.filter(upload => upload.context === this.context)
		},
		excludedImages() {
			return this.excludedFiles.filter(upload => !upload.isAttachment)
		},
		excludedAttachments() {
			return this.excludedFiles.filter(upload => upload.isAttachment)
		},
		images() {
			return this.uploads.filter(upload => !upload.isAttachment)
		},
		attachments() {
			return this.uploads.filter(upload => upload.isAttachment)
		},
		batchIds() {
			return [...new Set(this.uploads.map(upload => upload.batchId).filter(Boolean))]
		},
		imageBatchIds() {
			return [...new Set(this.images.map(upload => upload.batchId))]
		},
		attachmentBatchIds() {
			// excludes any blank/null batchIds to prevent extra study metadata forms
			return [...new Set(this.attachments.map(upload => upload.batchId).filter(Boolean))]
		},
		pendingJpegUploadCount() {
			return this.uploadData.getPendingUploads(this.context).filter(u => u.isImage).length
		},
		pendingAttachmentUploadCount() {
			return this.uploadData.getPendingUploads(this.context).filter(u => u.isAttachment).length
		},
		isUploading() {
			return this.uploadData.isUploading(this.context)
		},
		isProcessing() {
			return this.batchIds.length !== this.uploadedBatchIds.length
		},
		showAttachmentStudyForm() {
			return (
				this.pendingAttachmentUploadCount && // have pending attachments
				!this.pendingJpegUploadCount && // and not showing jpeg form
				// and cannot copy metadata from existing study (hides form while getting batchId)
				!this.uploads.some(upload => !!upload.studyInstanceUid)
			)
		},
	},
	mounted() {
		if (!this.showAttachmentUpload) {
			this.imageDrop = this.$refs.uploadContainer
		} else {
			this.imageDrop = this.$refs.imageDrop
			this.attachmentDrop = this.$refs.attachmentDrop
		}
	},
	methods: {
		clearExcludedFiles() {
			uploadData.CLEAR_UPLOAD_EXCLUDED_FILES(this.context)
		},
		getUploadsInBatch(batchId) {
			return this.uploads.filter(upload => upload.batchId === batchId || (!upload.batchId && !batchId))
		},
		addStudyId({ batchId, studyId }) {
			if (batchId && !this.uploadedBatchIds.includes(batchId)) {
				this.uploadedBatchIds.push(batchId)
			}
			if (studyId && !this.studyIds.includes(studyId)) this.studyIds.push(studyId)
		},
		async removeBatch({ studyId, batchId }) {
			const warning = 'Are you sure you want to remove this upload?'
			if (this.cancelOnClose && !(await showConfirm(warning))) return
			this.studyIds.splice(this.studyIds.indexOf(studyId), 1)
			uploadData.stopUploads({ batchId })
		},
		async close() {
			if (this.cancelOnClose) {
				if (
					(this.isUploading || this.isProcessing) &&
					!(await showConfirm('Are you sure you want to cancel uploading?'))
				)
					return
				uploadData.stopUploads({ context: this.context })
			}
			dlg.close(this, true, this.studyIds)
		},
	},
}

export default UploadDlg

export function openUploadDlg({
	context,
	cancelOnClose,
	showViewButton,
	showAttachmentUpload,
	showImageUpload,
	title,
}) {
	return dlg.open(UploadDlg, {
		context,
		cancelOnClose,
		showViewButton,
		showAttachmentUpload,
		showImageUpload,
		title,
	})
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.upload-container {
	display: flex;
	position: relative;
	flex-direction: column;
	background: var(--card-bg);
	border: 1px solid var(--card-border);
	width: 90vw;
	max-width: 1200px;
	height: 50vh;
	overflow: hidden;
	transition: height 0.2s ease;
	&.expand-height {
		height: 90vh;
	}
	@media (max-width: $mqSmall) {
		height: 100vh;
		height: -webkit-fill-available;
		width: 100vw;
	}
}
.upload-header {
	flex-shrink: 0;
	border-bottom: 1px solid var(--card-border);
}
.upload-body {
	flex-grow: 1;
	background: var(--primary-bg);
	overflow: auto;
	display: flex;
	flex-direction: column;
}
.upload-footer {
	border-top: 1px solid var(--card-border);
	display: flex;
	align-items: center;
	button {
		margin: 16px;
	}
	button:first-child {
		width: 116px;
	}
	button + button {
		margin-left: -8px;
	}
}
.upload-progress {
	text-align: center;
	font-size: 1.5em;
	padding-bottom: 1em;
	font-weight: 400;
	.upload-percent {
		text-align: center;
		font-weight: 300;
	}
}
.drop-target {
	padding: 8px;
}
.modal-upload-form {
	padding: 40px;
}
.attachment-study-form {
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	padding: 15px 30px 15px 15px;
}
.upload-container:not(.expand-height) .drop-target {
	flex-grow: 1;

	.modal-upload-form {
		height: 100%;
	}
	& + .drop-target {
		padding-top: 4px;
	}
}
.upload-container:not(.expand-height) .sole-upload-form.drop-target {
	height: 100%;
}
.upload-items {
	padding: 30px;
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	min-height: 0;
	background: var(--primary-bg);
	.study {
		margin: 15px auto;
	}
	ol {
		list-style-type: none;
		margin: auto;
		position: relative;
		button.close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 15px;
			background: transparent;
			border: 0;
		}
	}
}
</style>
