<template>
	<section class="toolbar" :class="{ primary: isPrimary, 'scroll-hide': scrollHide }">
		<slot></slot>
	</section>
</template>

<script>
export default {
	name: 'Toolbar',
	props: {
		isPrimary: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			prevScrollTop: 0,
			scrollHide: false,
		}
	},
	mounted() {
		document.addEventListener('tablescroll', this.onTableScroll)
	},
	beforeDestroy() {
		document.removeEventListener('tablescroll', this.onTableScroll)
	},
	methods: {
		onTableScroll({ srcEvent }) {
			const table = srcEvent.srcElement
			const { scrollTop, clientHeight, scrollHeight } = table
			// Add some checks to prevent weird glitches in Mobile Safari
			if (scrollHeight > clientHeight + scrollTop && scrollTop > 0) {
				// Hide toolbar if scrollTop is increasing
				this.scrollHide = this.prevScrollTop < scrollTop
				this.prevScrollTop = scrollTop
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

/*
 * STYLES
 *
*/
.toolbar {
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	align-items: stretch;
	justify-content: space-between;
	user-select: none;
	background-color: var(--toolbar-bg);
	color: var(--toolbar-font-color);
	&.primary {
		background-color: var(--primary-toolbar-bg);
		border-bottom: 1px solid var(--primary-toolbar-border);
		color: var(--primary-toolbar-color);
	}
	@media (max-width: $mqSmall) {
		transition: margin-top 0.2s ease-in-out;
		&.scroll-hide {
			margin-top: -50px;
		}
	}
}

.toolbar-label {
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;

	p {
		padding: 12px;
	}
}
</style>
