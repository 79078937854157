export default function(imageFrame) {
  const pixels = imageFrame.pixelData.length / 3
  let ybrIndex = 0
  let rgbaIndex = 0
  const newPixelData = new Uint8Array(pixels * 4)

  for (let i = 0; i < pixels; i++) {
    const y = imageFrame.pixelData[ybrIndex++]
    const cb = imageFrame.pixelData[ybrIndex++]
    const cr = imageFrame.pixelData[ybrIndex++]

    const r = y + (1.40200 * (cr - 128))
    const g = y - (0.34414 * (cb - 128)) - (0.71414 * (cr - 128))
    const b = y + (1.77200 * (cb - 128))

    newPixelData[rgbaIndex++] = constrain(r)
    newPixelData[rgbaIndex++] = constrain(g)
    newPixelData[rgbaIndex++] = constrain(b)
    newPixelData[rgbaIndex++] = 255
  }

  function constrain(value) {
    if (value < 0) return 0
    if (value > 255) return 255
    return value
  }

  imageFrame.pixelData = newPixelData
}
