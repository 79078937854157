import createImageFromEnabledElementAsync from './_shared/createImageFromEnabledElementAsync.js'
import saveImage from '@utils/saveImage'

/**
 *
 *
 * @export
 * @param {*} { rootGetters, rootState }
 * @param {(Number)} canvasIndex
 */
export default async function({ state, dispatch, rootGetters }, canvasIndex) {
	// Provided canvasIndex or default to activeCanvas
	const targetCanvas = canvasIndex === undefined ? rootGetters.activeCanvas : state.canvases[canvasIndex]

	if (!targetCanvas) {
		return
	}
	if (!targetCanvas.seriesId && !targetCanvas.imageId) return
	const { patientName, patientId } = rootGetters.activeSeries.overlayInformation
	const fileName = patientName || patientId || targetCanvas.imageId || targetCanvas.asterisImageId
	const imgBase64 = await createImageFromEnabledElementAsync(targetCanvas.dom)
	await saveImage(imgBase64, fileName, dispatch)
}
