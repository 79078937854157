<template>
	<div
		v-if="priorities.length || cell.template.editorMode"
		class="input-group"
		style="display: inline-flex; align-items: center; padding: 4px 0;"
	>
		<label for="priority" style="padding-right: 1ch;">
			Priority:
			<small class="is-danger">*</small>
		</label>
		<span v-if="cell.readOnly">
			{{ value }}
		</span>
		<v-select
			v-else
			v-model="value"
			name="priority"
			label="name"
			:reduce="priority => priority.value"
			:options="priorities"
			:searchable="false"
			:clearable="false"
			style="display: inline-block; min-width: 150px;"
		/>
	</div>
</template>

<script>
import { Cell } from '../classes'

export default {
	name: 'PrioritySelector',
	components: {},
	props: ['cell'],
	computed: {
		priorities() {
			return (this.cell.report && this.cell.report.priorities) || []
		},
		value: {
			get() {
				return this.cell.report && this.cell.report.priority
			},
			set(value) {
				this.cell.report.priority = value
			},
		},
	},
}
</script>
