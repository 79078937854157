import getValue from './../internals/getValue.js'

/**
 * This function extracts miltiframe information from a dicomParser.DataSet object.
 *
 * @param dataSet {Object} An instance of dicomParser.DataSet object where multiframe information can be found.
 * @return {Object} An object containing multiframe image metadata (frameIncrementPointer, frameTime, frameTimeVector, etc).
 */
export default function(dicomTags) {
  const imageInfo = {
    isMultiframeImage: false,
    frameIncrementPointer: null,
    numberOfFrames: 0,
    frameTime: 0,
    frameTimeVector: null,
    averageFrameRate: 0, // backwards compatibility only... it might be useless in the future
  }

  let frameTime
  const numberOfFrames = getValue(dicomTags, 'NUMBER_OF_FRAMES', -1)

  if (numberOfFrames > 0) {
    // set multi-frame image indicator
    imageInfo.isMultiframeImage = true
    imageInfo.numberOfFrames = numberOfFrames

    const frameIncrementPointer = _getFrameIncrementPointer(dicomTags)
    if (frameIncrementPointer === 'frameTimeVector') {
      // Frame Increment Pointer points to Frame Time Vector (0018,1065) field
      const frameTimeVector = getValue(dicomTags, 'FRAME_TIME_VECTOR')
      if (frameTimeVector instanceof Array && frameTimeVector.length > 0) {
        imageInfo.frameIncrementPointer = frameIncrementPointer
        imageInfo.frameTimeVector = frameTimeVector
        imageInfo.frameTime =
          frameTimeVector.reduce((a, b) => a + b) / frameTimeVector.length
        imageInfo.averageFrameRate = 1000 / imageInfo.frameTime
      }
    } else {
      frameTime = getValue(dicomTags, 'FRAME_TIME')
      if (frameTime > 0) {
        imageInfo.frameIncrementPointer = frameIncrementPointer
        imageInfo.frameTime = frameTime
        imageInfo.averageFrameRate = 1000 / frameTime
      }
    }
  }

  return imageInfo
}

/**
 * TODO: `frameIncrementPointer` tells us which tag to use for frameTime
 * TODO: For now, we check both tags, and use whichever has a value
 *
 * @private
 * @param {*} dicomTags
 * @returns
 */
function _getFrameIncrementPointer(dicomTags) {
  //   const frameInstancePointerNames = {
  //     x00181063: 'frameTime',
  //     x00181065: 'frameTimeVector',
  //   }

  // const frameIncrementPointer = getValue(dicomTags, 'FRAME_INCREMENT_POINTER')
  const frameTime = getValue(dicomTags, 'FRAME_TIME')
  const frameTimeVector = getValue(dicomTags, 'FRAME_TIME_VECTOR')

  if (frameTimeVector) {
    return 'frameTimeVector'
  }
  if (frameTime) {
    return 'frameTime'
  }

  return ''
}
