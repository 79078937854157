import metadata from '@/cornerstone/metadata'
import { eventBus } from '@services/eventBus'
import { showAlert } from '@dialogs/MessageDlg.vue'
import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'

const GROUP = 20864 // x5180
const ELEMENT = 8192 // x2000

export function updateCalibration({ commit }, { imageId, oldLength, oldPixelSpacing, newLength }) {
	if (!newLength) return
	const isNumber = !isNaN(parseFloat(newLength))
	if (!isNumber) {
		showAlert('The provided measurement is not a number.')
		return
	}
	commit('SET_CALIBRATION', { imageId, oldLength, oldPixelSpacing, newLength })
	// Grab current meta
	const imageMeta = metadata.get(imageId)
	if (!imageMeta) return // image is not loaded
	// Remove old meta tag if it exists
	const index = imageMeta.raw.findIndex(tag => tag.Group === GROUP && tag.Element === ELEMENT)
	if (index > -1) imageMeta.raw.splice(index, 1)
	// Create new meta
	const oldScaleFactor = oldPixelSpacing.colPixelSpacing || oldPixelSpacing.rowPixelSpacing
	const lengthInPixels = oldLength / oldScaleFactor
	const newScaleFactor = newLength / lengthInPixels

	const astCalibratedPixelSpacing = {
		Group: GROUP,
		Element: ELEMENT,
		Name: 'AstCalibratedPixelSpacing',
		Value: `${newScaleFactor}\\${newScaleFactor}`,
	}
	imageMeta.raw.push(astCalibratedPixelSpacing)
	// Replace metadata
	metadata.add(imageId, imageMeta.raw)
}

export function clearCalibration({ commit }, { imageId }) {
	commit('CLEAR_CALIBRATION', imageId)
	const imageMeta = metadata.get(imageId)
	if (!imageMeta) return
	// Remove meta tag if it exists
	const index = imageMeta.raw.findIndex(tag => tag.Group === GROUP && tag.Element === ELEMENT)
	if (index > -1) imageMeta.raw.splice(index, 1)
	// Replace metadata
	metadata.add(imageId, imageMeta.raw)
	cornerstoneTools.store.state.enabledElements.forEach(async element => {
		// Force cached stats update for all tool data
		const image = cornerstone.getImage(element)
		if (image.asterisImageId !== imageId) return
		const tools = cornerstoneTools.store.state.tools.filter(tool => tool.updateCachedStats)
		tools.forEach(tool => {
			const toolState = cornerstoneTools.getToolState(element, tool.name)
			if (toolState && toolState.data) {
				toolState.data.forEach(d => {
					tool.updateCachedStats(image, element, d)
				})
			}
		})
		cornerstone.updateImage(element)
	})
}

export function clearCalibrationAndBroadcast({ dispatch }, payload) {
	const type = 'clearCalibration'
	dispatch(type, payload)
	eventBus.broadcast(eventBus.type.VUEX_ACTION, { type, payload })
}
