<template>
	<modal-wrapper @close="close">
		<div id="user-studies-dlg" class="dialog flex large">
			<dlg-header title="Select a study." @close="close" />
			<user-study-table :is-dialog="true" @select-study="close" />
		</div>
	</modal-wrapper>
</template>

<script>
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import UserStudyTable from '@components/UserStudiesTable.vue'

const UserStudiesDlg = {
	name: 'UserStudiesDlg',
	components: {
		DlgHeader,
		ModalWrapper,
		UserStudyTable,
	},
	props: ['message'],
	methods: {
		close(study) {
			dlg.close(this, true, study)
		},
	},
}

export default UserStudiesDlg

export function openUserStudiesDlg() {
	return dlg.open(UserStudiesDlg)
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

#user-studies-dlg {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	height: -webkit-fill-available;
	padding: 0;
	overflow: hidden;
	@media (min-width: $mqSmall) {
		height: 90vh;
		width: 90vw;
	}
	#user-studies-table {
		border-top: 1px solid var(--secondary-border);
		flex-grow: 1;
		padding: 16px 16px 8px 16px;
	}
}
</style>
