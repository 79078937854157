<template>
	<v-select
		ref="emails"
		v-model="addresses"
		:disabled="disabled"
		:options="emailList"
		:select-on-key-codes="$options.selectOnKeyCodes"
		searchable
		taggable
		multiple
		@search:blur="onEmailSearchBlur"
		@search="term => getEmailList(term)"
	/>
</template>

<script>
import consultations from '@services/consultationService'
import emailIsValid from '@utils/emailIsValid'

export default {
	name: 'Notifications',
	selectOnKeyCodes: [
		13, // enter
		32, // space
		186, // semicolon
		188, // comma
	],
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		showSuggestions: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			emailList: [],
		}
	},
	computed: {
		addresses: {
			get() {
				return this.value
			},
			set(addresses) {
				addresses = [...new Set(addresses.map(v => v.trim()).filter(emailIsValid))]
				this.$emit('input', addresses)
			},
		},
	},
	mounted() {
		if (this.showSuggestions) this.getEmailList()
	},
	methods: {
		onEmailSearchBlur() {
			this.$nextTick(() => {
				// attempt to add any remaining text in the search box as an address
				const remainingEmail = this.$refs.emails.$refs.vueSelect.search
				if (!remainingEmail || !remainingEmail.trim() || this.addresses.includes(remainingEmail.trim())) return
				this.addresses = [...this.addresses, remainingEmail.trim()]
				this.$refs.emails.$refs.vueSelect.search = ''
			})
		},
		async getEmailList(searchTerm = '') {
			if (!this.showSuggestions) return
			try {
				const response = await consultations.getEmail({ searchTerm })
				if (response) this.emailList = response.data.results
			} catch (err) {
				this.emailList = []
			}
		},
	},
}
</script>
