<template>
	<modal-wrapper @close="close">
		<div id="dicom-service-log-dlg" class="dialog flex">
			<dlg-header title="DICOM Services Logs" style="background: var(--secondary-bg);" @close="close()" />
			<div class="scroll-body">
				<div v-if="service" class="service-info">
					<p>
						<strong>AE Title:</strong>
						{{ service.dicomAeTitle }}
					</p>
					<p v-if="service.hostName">
						<strong>Hostname:</strong>
						{{ service.hostName }}
					</p>
					<p>
						<strong>Port:</strong>
						{{ service.dicomPort }}
					</p>
				</div>
				<search-and-filter v-if="!studyId" v-model="filter" is-dialog>
					<template #tags>
						<filter-tag :value="filter.modalityIds" :clear-value="[]" @input="filter.modalityIds = []">
							{{ $store.getters.modalityNamesById(filter.modalityIds).join(' / ') }}
						</filter-tag>
						<filter-tag :value="filter.logType" :clear-value="null" @input="filter.logType = null">
							{{ filter.logType }} Logs
						</filter-tag>
					</template>
					<div>
						<label>Log Type</label>
						<select v-model="filter.logType" class="select" style="padding: 4px">
							<option :value="null">All</option>
							<option value="Error">Error</option>
							<option value="Info">Info</option>
						</select>
					</div>
				</search-and-filter>
				<div v-if="studyId" style="padding-bottom: 16px;">
					<p style="font-size: 0.9em; padding-bottom: 8px;">
						Log Type
					</p>
					<select v-model="filter.logType" class="select" style="padding: 4px">
						<option :value="null">All</option>
						<option value="Error">Error</option>
						<option value="Info">Info</option>
					</select>
				</div>
				<div class="results">
					<pagination
						:current-page="currentPage"
						:results-per-page="resultsPerPage"
						:results-length="logs.length"
						:has-more-results="hasMoreResults"
						style="max-width: 100vw;"
						@set-results-per-page="
							num => {
								resultsPerPage = num
								currentPage = 0
							}
						"
						@set-current-page="page => (currentPage = page)"
					/>
					<data-table v-if="isLoading || hasLogs" class="results-table table--striped">
						<thead>
							<tr>
								<th>Date</th>
								<th v-if="!service">Hostname</th>
								<th>Message</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="log in logs" :key="log.id">
								<td>{{ log.logDateTime | localizeDate({ showSeconds: true }) }}</td>
								<td v-if="!service">{{ log.hostName }}</td>
								<td style="max-width: 600px; word-break: break-word;">
									<span v-if="log.logType === 'Error'" class="error-tag">Error</span>
									<span>{{ log.message }}</span>
									<div v-if="log.patientName" class="studyInfo">
										{{ log.patientName }} ({{ log.patientId }})&nbsp;&nbsp;&nbsp;Modality:
										{{ log.modality }}&nbsp;&nbsp;&nbsp;Study Date:
										{{ log.studyDateTime | localizeDate({ forceUTC: false }) }}&nbsp;&nbsp;&nbsp;UID:
										{{ log.imageUid }}
									</div>
									<a v-if="log.details" class="blue" @click="showDetails(log)">&nbsp;[Details]</a>
								</td>
							</tr>
						</tbody>
					</data-table>
					<div v-else-if="!hasLogs" style="padding: 16px; font-style: italic;">
						No logs found
					</div>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { dicomServiceData } from '@services/dicomServiceData'
import { openMessageDlg } from '@dialogs/MessageDlg.vue'
import DataTable from '@components/DataTable.vue'
import Pagination from '@components/Pagination.vue'
import DlgHeader from '@components/DlgHeader.vue'
import FilterTag from '@components/FilterTag.vue'
import SearchAndFilter from '@components/SearchAndFilter.vue'

const DicomServiceLogDlg = {
	name: 'DicomServiceLogDlg',
	components: {
		ModalWrapper,
		Pagination,
		DataTable,
		DlgHeader,
		FilterTag,
		SearchAndFilter,
	},
	computed: {
		hasLogs() {
			return this.logs.length > 0
		},
	},
	props: ['service', 'studyId'],
	data() {
		return {
			currentPage: 0,
			resultsPerPage: 25,
			hasMoreResults: true,
			logs: [],
			filter: {
				term: '',
				modalityIds: [],
				startDate: '',
				endDate: '',
				dateRange: null,
				logType: null,
			},
		}
	},
	created() {
		this.$watch('resultsPerPage', this.fetch)
		this.$watch('currentPage', this.fetch)
		this.$watch('logType', () => {
			this.currentPage = 0
			this.fetch()
		})
		this.$watch(
			'filter',
			() => {
				this.currentPage = 0
				this.fetch()
			},
			{ deep: true }
		)
		this.fetch()
	},
	methods: {
		async fetch() {
			this.isLoading = true
			let offset = this.currentPage * this.resultsPerPage
			this.logs = await dicomServiceData.getLogs({
				serviceId: this.service && this.service.id,
				studyId: this.studyId,
				offset,
				max: this.resultsPerPage + 1,
				logType: this.filter.logType,
				modalities: this.filter.modalityIds,
				studyDateStart: this.filter.startDate,
				studyDateEnd: this.filter.endDate,
				searchTerm: this.filter.term,
			})
			let diff = this.logs.length - this.resultsPerPage
			this.hasMoreResults = diff > 0
			if (diff > 0) {
				this.logs.splice(this.resultsPerPage, diff)
			}
			this.isLoading = false
		},
		close() {
			dlg.close(this)
		},
		showDetails(log) {
			openMessageDlg(log.details)
		},
	},
}

export default DicomServiceLogDlg

export function openDicomServiceLogDlg(service, studyId) {
	return dlg.open(DicomServiceLogDlg, { service, studyId })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

#dicom-service-log-dlg {
	display: flex;
	flex-direction: column;
	padding: 0;
	overflow-x: hidden;
	background: var(--primary-bg);
	@media (min-width: $mqSmall) {
		width: 90vw;
		height: 90vh;
	}

	.service-info {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 16px;
		p {
			flex-basis: 1;
			padding-right: 16px;
		}
	}
	.scroll-body {
		height: 100%;
		flex-shrink: 1;
		overflow-y: auto;
		border-top: 1px solid var(--secondary-border);
		& > * {
			margin: 16px;
		}
	}
	.results {
		background: var(--secondary-bg);
		border: 1px solid var(--secondary-border);
		min-width: 500px;
		@media (min-width: $mqSmall) {
			padding: 16px;
		}
		.error-tag {
			display: inline-flex;
			align-items: center;
			min-height: 1em;
			font-size: 0.75em;
			padding: 0 4px;
			font-weight: bold;
			color: var(--icon-danger);
			border: 1px solid var(--icon-danger);
			margin-right: 8px;
		}
		.studyInfo {
			color: var(--nav-link-active);
		}
	}
}
</style>
