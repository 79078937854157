/* eslint-disable promise/param-names */
import storage from '@services/storage'
import { ImageLoader, ImageData } from './index'
import { fileSize } from '@/filters'

declare global {
	interface Window {
		requestFileSystem?: Function
		webkitRequestFileSystem?: Function
		PERSISTENT: string
	}
	interface Navigator {
		persistentStorage?: any
		webkitPersistentStorage?: any
	}
}

enum FileError {
	NOT_FOUND_ERR = 1,
	SECURITY_ERR,
	NOT_READABLE_ERR = 4,
	ENCODING_ERR,
	NO_MODIFICATION_ALLOWED_ERR,
	INVALID_STATE_ERR,
	INVALID_MODIFICATION_ERR = 9,
	QUOTA_EXCEEDED_ERR,
	TYPE_MISMATCH_ERR,
	PATH_EXISTS_ERR,
}

type Callback<T> = (param: T) => any
type FileErrorCallback = Callback<FileError>

interface FileSystem {
	name: string
	root: FileSystemDirectoryEntry
}

interface FileSystemEntry {
	filesystem: FileSystem
	fullPath: string
	isDirectory: boolean
	isFile: boolean
	name: string
	getMetadata: (callback: Callback<FileSystemEntryMetadata>) => void
}

interface FileSystemFlags {}

interface FileSystemDirectoryReader {
	readEntries: (success: (entries: Array<FileSystemEntry>) => void, callback: FileErrorCallback) => void
}

interface FileSystemDirectoryEntry extends FileSystemEntry {
	getDirectory: (
		path?: string,
		options?: FileSystemFlags,
		callback?: Callback<FileSystemDirectoryEntry>,
		errorCallback?: FileErrorCallback
	) => void
	getFile: Function
	createReader: () => FileSystemDirectoryReader
	removeRecursively: (success: Function, failure: (fileError: FileError) => void) => void
}

interface FileSystemFileEntry extends FileSystemEntry {
	file: Function
	createWriter: Function
}

interface FileSystemEntryMetadata {
	modificationTime: Date
	size: number
}

class BrowserImageCache {
	size: number = 0
	allocatedSize: number = 0
	availableSize: number = 0
	asterisDir: FileSystemDirectoryEntry = null
	requestedBytes: number = 1024 * 1024 * 1024 * 5 // 5GB
	imageCacheSizeKey: string = 'browserImageCacheBytes'
	imageCacheActiveKey: string = 'browserImageCacheActive'
	imageCachePrecacheActiveKey: string = 'browserImageCachePrecacheActive'

	constructor() {
		window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem
		navigator.persistentStorage = navigator.persistentStorage || navigator.webkitPersistentStorage
	}

	get isActive() {
		return storage.getItem(this.imageCacheActiveKey) === 'true'
	}

	set isActive(active) {
		storage.setItem(this.imageCacheActiveKey, active)
		if (active) this.init()
	}

	get isPrecacheActive() {
		return storage.getItem(this.imageCachePrecacheActiveKey) === 'true'
	}

	set isPrecacheActive(active) {
		storage.setItem(this.imageCachePrecacheActiveKey, active)
	}

	async refreshMeta() {
		if (!(await this.init())) return
		this.size = await this.getDirectorySize(this.asterisDir)
	}

	getDirectorySize(dir: FileSystemDirectoryEntry): Promise<number> {
		return new Promise(resolve => {
			const reader = dir.createReader()
			reader.readEntries(
				(entries: Array<FileSystemEntry>) => {
					const promises: Array<Promise<number>> = []
					entries.forEach(entry => {
						if (entry.isFile) {
							promises.push(
								new Promise(res => {
									entry.getMetadata(({ size: fileSize }) => {
										res(fileSize)
									})
								})
							)
						} else {
							promises.push(this.getDirectorySize(entry as FileSystemDirectoryEntry))
						}
					})
					Promise.allSettled(promises).then(sizePromises => {
						let size = 0
						sizePromises.forEach(sp => {
							if (sp.status === 'fulfilled') {
								size += sp.value
							}
						})
						resolve(size)
					})
				},
				() => {
					resolve(0)
				}
			)
		})
	}

	init(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (!this.isActive) {
				return resolve(false)
			}
			if (this.asterisDir) {
				return resolve(true)
			}
			// Recall the last number of requested bytes
			const lastRequestedBytes = +storage.getItem(this.imageCacheSizeKey)
			if (lastRequestedBytes > 0) {
				this.requestedBytes = Math.min(lastRequestedBytes, this.requestedBytes)
			}
			// Request persistent file system storage
			navigator.persistentStorage.requestQuota(this.requestedBytes, (grantedBytes: number) => {
				// Opening a file system with temporary storage
				window.requestFileSystem(window.PERSISTENT, grantedBytes, (fs: FileSystem) => {
					// Save the latest number of granted bytes to minimize dialog appearances
					this.allocatedSize = grantedBytes
					this.availableSize = grantedBytes
					storage.setItem(this.imageCacheSizeKey, grantedBytes)
					fs.root.getDirectory(
						'Asteris',
						{ create: true },
						(dir: FileSystemDirectoryEntry) => {
							this.asterisDir = dir
							console.log(`Initialized Asteris directory with ${fileSize(this.allocatedSize)} allowed.`)
							resolve(true)
						},
						err => {
							reject(err)
						}
					)
				})
			})
		})
	}

	getImageDirPath({ clinicCode, studyUid, seriesUid }) {
		return `${clinicCode.toUpperCase()}/DICOM/${studyUid}/${seriesUid}`
	}

	getImageFileName(imageData: ImageData) {
		const ext = imageData.scheme.toLowerCase() === 'dicom' ? 'dcm' : 'jpg'
		const frameIndex = imageData.frameIndex ? '.' + imageData.frameIndex : ''
		return `${imageData.instanceUid}${frameIndex}.${ext}`
	}

	loadImage(imageData: ImageData, imageLoader: ImageLoader): Promise<ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const fetchImage = (shouldCache: boolean) => {
				imageLoader(imageData)
					.then((image: ArrayBuffer) => {
						resolve(image)
						if (shouldCache) {
							this.setImage(imageData, image)
						}
					})
					.catch((e: any) => {
						reject(e)
					})
			}
			if (!this.isActive) {
				return fetchImage(false)
			}
			this.getImage(imageData)
				.then(image => {
					// This shouldn't ever happen, but if it does...
					if (image.byteLength === 0) {
						// Just fetch the image normally
						fetchImage(true)
					} else {
						resolve(image)
					}
				})
				.catch(() => {
					return fetchImage(true)
				})
		})
	}

	getImage(imageData: ImageData): Promise<ArrayBuffer> {
		return new Promise((resolve, reject) => {
			this.init().then(() => {
				this.asterisDir.getDirectory(
					this.getImageDirPath(imageData),
					null,
					(dir: FileSystemDirectoryEntry) => {
						dir.getFile(
							this.getImageFileName(imageData),
							null,
							(fileEntry: FileSystemFileEntry) => {
								fileEntry.file((file: Blob) => {
									file.arrayBuffer().then(buffer => {
										resolve(buffer)
									})
								})
							},
							(fileError: FileError) => {
								reject(fileError)
							}
						)
					},
					(fileError: FileError) => {
						reject(fileError)
					}
				)
			})
		})
	}

	setImage(imageData: ImageData, image: ArrayBuffer): Promise<boolean> {
		return new Promise((resolve, reject) => {
			// Weird things happen later if we don't copy the array buffer
			image = image.slice(0)
			if (image.byteLength === 0) {
				return reject(new Error('Image array buffer has zero length'))
			}
			this.init().then(() => {
				this.asterisDir.getDirectory(
					this.getImageDirPath(imageData),
					{ create: true },
					(dir: FileSystemDirectoryEntry) => {
						dir.getFile(
							this.getImageFileName(imageData),
							{ create: true },
							(fileEntry: FileSystemFileEntry) => {
								fileEntry.createWriter((fileWriter: any) => {
									// Resolve promise when file is written
									fileWriter.onwriteend = () => {
										resolve(true)
									}
									// Reject promise on file write errors
									fileWriter.onerror = (e: Error) => {
										reject(e)
									}
									// Write image array buffer to file
									fileWriter.write(new Blob([image]))
								})
							},
							(fileError: FileError) => {
								reject(fileError)
							}
						)
					},
					(fileError: FileError) => {
						reject(fileError)
					}
				)
			})
		})
	}

	clear() {
		return new Promise((resolve, reject) => {
			this.init().then(result => {
				if (!result) resolve(false)
				this.asterisDir.removeRecursively(
					() => {
						resolve(true)
						this.refreshMeta()
					},
					(fileError: FileError) => {
						reject(fileError)
					}
				)
			})
		})
	}
}

export default BrowserImageCache
