<template>
	<div class="report-page-break">
		<div class="dashes"></div>
		<div class="icon-circle"><svg-icon icon="format-page-break"/></div>
	</div>
</template>

<script>
export default {
	name: 'PageBreak',
}
</script>

<style lang="scss">
.report-page-break {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	width: 100%;
	min-width: 100px;
	.dashes {
		position: absolute;
		top: 26px;
		left: 0;
		width: 100%;
		height: 0;
		border-top: 4px dashed var(--tertiary-bg);
	}
	.icon-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		font-size: 24px;
		background: var(--tertiary-bg);
		width: 36px;
		height: 36px;
		border-radius: 50%;
	}
}
</style>
