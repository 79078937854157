import Vue from 'vue'

Vue.directive('draggable', {
	bind(el, binding) {
		let handleElements = [el]
		if (binding?.value?.handleSelector) {
			const customHandleEls = el.querySelectorAll(binding.value.handleSelector)
			if (customHandleEls) {
				handleElements = customHandleEls
				handleElements.forEach(el => {
					el.style.cursor = 'move'
				})
			}
		}
		el.style.position = 'absolute'
		var startX, startY, initialMouseX, initialMouseY

		function move(e) {
			let isTouch = e.type === 'touchmove' && e.touches.length > 0
			let evtData = isTouch ? e.touches[0] : e

			var dx = evtData.clientX - initialMouseX
			var dy = evtData.clientY - initialMouseY
			el.style.top = startY + dy + 'px'
			el.style.left = startX + dx + 'px'
			return false
		}

		function end() {
			document.removeEventListener('mousemove', move)
			document.removeEventListener('mouseup', end)
			document.removeEventListener('touchmove', move)
			document.removeEventListener('touchend', end)
			el.dispatchEvent(new CustomEvent('drag'))
		}

		function start(e) {
			let isTouch = e.type === 'touchstart' && e.touches.length > 0
			let evtData = isTouch ? e.touches[0] : e

			startX = el.offsetLeft
			startY = el.offsetTop
			initialMouseX = evtData.clientX
			initialMouseY = evtData.clientY

			document.addEventListener('mousemove', move)
			document.addEventListener('mouseup', end)
			document.addEventListener('touchmove', move)
			document.addEventListener('touchend', end)
			return false
		}
		handleElements.forEach(el => {
			el.addEventListener('mousedown', start)
			el.addEventListener('touchstart', start)
		})
	},
})
