import metadata from './internals/metadataLookup.js'

/**
 * Updates a set of metadata given a specific imageId, type, and dataset
 *
 * @public
 * @function updateMetadata
 *
 * @param {string} imageId - string image id
 * @param {string} type - (e.g. series, instance, tagDisplay)
 * @param {*} data
 * @returns {boolean} - true if data was updated; false if image wasn't found
 */
export default function(imageId, type, data) {
  let imageMetaUpdated = false
  imageId = imageId.toLowerCase()

  if (metadata.lookup.has(imageId)) {
    const deltaMeta = {}
    deltaMeta[type] = data

    const oldMeta = metadata.lookup.get(imageId)
    const updatedMeta = Object.assign({}, oldMeta, deltaMeta)

    metadata.lookup.set(imageId, updatedMeta)
    imageMetaUpdated = true
  }

  return imageMetaUpdated
}
