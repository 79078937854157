<template>
	<div style="padding: 16px;">
		<p style="margin-bottom: 8px;">{{ consultant.name }}</p>
		<p>
			<span class="clinic">{{ consultant.organization }}</span>
			<br />
			<span v-if="consultant.address" v-html="consultant.address"></span>
		</p>
		<p style="margin-top: 8px;">
			<span v-if="consultant.phone">
				<a :href="`tel:${consultant.phone}`" target="hidden-iframe">{{ consultant.phone }}</a>
				<br />
			</span>
			<span v-if="consultant.email">
				<a :href="`mailto:${consultant.email}`" target="hidden-iframe">{{ consultant.email }}</a>
				<br />
			</span>
			<span v-if="consultant.website">
				<a :href="consultant.website | url" target="_blank">
					{{ consultant.website }}
				</a>
				<br />
			</span>
		</p>
		<p v-if="drafter" style="margin-top: 8px">
			<label>Drafted By: {{ drafter.name }}</label>
		</p>
	</div>
</template>

<script>
import { Cell } from '../classes'
import { ContextMeta } from '../context'
import { toCamelCase } from '@utils/stringUtils'

export default {
	name: 'Consultant',
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	computed: {
		drafter() {
			return this.cell.report && this.cell.report.drafter
		},
		consultant() {
			if (this.cell.editorMode) {
				// use ContextMeta sample data
				let client = {}
				Object.keys(ContextMeta.Consultant.items).forEach(k => {
					client[toCamelCase(k)] = ContextMeta.Consultant.items[k].sample
				})
				return client
			}
			return this.cell.report.consultant
		},
	},
}
</script>
