


























































































































































































import DataTable from '@components/DataTable.vue'
import BirthdateAgeInputs from '@components/BirthdateAgeInputs.vue'
import api from '@services/api'
import { ValidatorMixin } from '@mixins/validator'
import { validator } from '@utils/validation'
import { formatNumber } from '../../utils/numberUtils'

export default {
	name: 'PatientForm',
	components: {
		DataTable,
		BirthdateAgeInputs,
	},
	mixins: [ValidatorMixin],
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			genderList: ['Male', 'Male Castrated', 'Female', 'Female Spayed'],
			weightUnitList: ['KGS', 'LBS'],
			speciesList: [],
			speciesNames: [],
			breedList: [],
		}
	},
	computed: {
		weight: {
			get() {
				return formatNumber(this.patient.weight)
			},
			set(value) {
				this.patient.weight = value === '' ? value : this.$options.filters.parseNumber(value)
			},
		},
		isValidWeight() {
			const weight = this.$options.filters.parseNumber(this.weight)
			return weight >= 0
		},
		patient() {
			if (this.cell.editorMode) return {}
			return this.cell.report.patient
		},
		requiredFields() {
			return this.cell.props.requiredFields || []
		},
		restrictSpeciesList() {
			if (!this.cell.report || !this.cell.report.consultant) return false
			return this.cell.report.consultant.isAntechEnabled
		},
	},
	watch: {
		'patient.species': {
			handler(species) {
				this.getBreedList()
			},
		},
		breedList() {
			this.patient.breed = findMatchingOption(this.patient.breed, this.breedList) || this.patient.breed
		},
		anyErrors() {
			if (this.cell.report) this.cell.report.validate()
		},
	},
	async created() {
		if (this.cell.editorMode) return

		let reqFieldMap = {
			PatientName: 'name',
			OwnerName: 'owner',
			PatientId: 'id',
			Gender: 'gender',
			Species: 'species',
			Breed: 'breed',
			Birthdate: 'birthdate',
			Weight: 'weight',
		}

		let reqFields = this.cell.props.requiredFields.map(r => reqFieldMap[r])
		let report = this.cell.report

		this.cell.onValidate = () => {
			if (!validator.hasRequired(this.patient, reqFields)) {
				report.validationErrors.push('Please complete all required fields.')
			}
			if (!this.isValidWeight) {
				report.validationErrors.push('The entered patient weight is invalid.')
			}
		}

		const consultant: IConsultantInfo = this.cell.report.consultant
		this.speciesList = await api.patient.getSpecies(consultant ? consultant.id : null)
		this.speciesNames = this.speciesList.map(s => s.name)
		if (this.patient.birthDate) this.patient.birthDate = new Date(this.patient.birthDate)
		this.patient.gender = findMatchingOption(this.patient.gender, this.genderList)
		this.patient.weightUnit = findMatchingOption(this.patient.weightUnit, this.weightUnitList) || this.weightUnitList[0]
		if (this.patient.species && this.restrictSpeciesList && !this.speciesNames.includes(this.patient.species)) {
			this.patient.species = null
		}
		this.validator.validate()
		this.getBreedList()

		this.$watch(
			'patient',
			() => {
				this.cell.template.changeValue()
			},
			{ deep: true }
		)
	},
	methods: {
		async getBreedList() {
			let s = this.speciesList.find(s => s.name === this.patient.species)
			if (!s) {
				this.breedList = []
				return
			}

			const list = await this.$api.patient.getBreeds(s.id)
			this.breedList = [...new Set(list.map(breed => breed.name))]
		},
		isRequired(field) {
			return this.requiredFields.includes(field)
		},
		validateField(field) {
			if (this.cell.editorMode) return ''

			let validations = []
			if (this.isRequired(field)) validations.push('required')
			return validations.join('|')
		},
	},
}

function findMatchingOption(value, options, key = undefined) {
	if (!value) return
	const isMatch = (a, b) => a.toLowerCase().trim() === b.toLowerCase().trim()
	if (!key) {
		return options.find(option => isMatch(option, value))
	} else {
		return options.find(option => option[key] && isMatch(option[key], value))
	}
}
