<template>
	<div class="dialog">
		<modal-dialog :is-open="true" title="MPR Performance Check" @close="close">
			<template v-if="issues.hasIssues">
				<p>
					You may experience slower rendering speeds or poor quality rendering because of the
					following reason(s):
				</p>
				<ul>
					<li v-if="issues.webglVersion">
						Detected WebGL v{{ issues.webglVersion }}. For best performance, please use a computer
						and/or browser with at least WebGL v2.
					</li>
					<li v-if="issues.integratedGPU">
						Using GPU "{{ issues.integratedGPU }}". A dedicated GPU would offer better performance.
						<a
							rel="noopener noreferrer"
							target="_blank"
							href="https://wevideo.zendesk.com/hc/en-us/articles/225259448-How-to-enable-WebGL"
						>
							More info on enabling dedicated GPUs in browsers.
						</a>
					</li>
					<li v-if="issues.browser">
						Using Browser: "{{ issues.browser }}". Volume rendering performs best on Chrome and
						Firefox.
					</li>
				</ul>
			</template>
			<template v-else>
				<p>
					There are no performance impacting issues that we could detect with your system. You
					should experience good MPR performance!
				</p>
			</template>
		</modal-dialog>
	</div>
</template>

<script>
import ModalDialog from '@components/ModalDialog.vue'
import { dlg } from '@utils/dlgUtils'

const MprPerfDlg = {
	name: 'MprPerformanceDlg',
	components: {
		ModalDialog,
	},
	props: {
		issues: {
			type: Object,
			default() {
				return {}
			},
		},
	},
	methods: {
		close() {
			dlg.close(this, true, false)
		},
	},
}

export default MprPerfDlg

export function showMprPerfDialog(issues = {}) {
	return dlg.open(MprPerfDlg, { issues }, false)
}
</script>
<style lang="scss" scoped>
ul {
	margin-block-start: 1em;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 24px;
	> li:not(:last-child) {
		margin-bottom: 8px;
	}
}
</style>
