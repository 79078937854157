import { API } from '@services/api'

class BillingCodes {
	get(consultantId?: string): Promise<IBillingCode[]> {
		return API.get(`/billingcodes`, {
			params: { consultantId },
		}).then(r => r.data)
	}

	save(codes: IBillingCode[], consultantId?: string) {
		return API.post(`/billingcodes`, codes, {
			params: { consultantId },
		})
	}
}

export const billingCodes = new BillingCodes()
// @ts-ignore
window.billingCodes = billingCodes
