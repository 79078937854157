import { synchronizers } from '@store/modules/toolManager/synchronizers.js'
import { updateImage } from 'cornerstone-core/dist/cornerstone.js'
import { waitForEnabledElementImageToLoad } from '@/utils/wait.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { eventBus } from '@services/eventBus'
/**
 *
 * @function setActiveCanvas
 *
 * @param {object} { state, commit }
 * @param {object} data
 * @param {number} data.canvasIndex
 * @param {boolean} [data.force=false] - Used to force synchronizer update
 * @returns {undefined}
 */
export default async function({ state, commit }, { canvasIndex, force = false }) {
	// Short-circuit
	if (state.activeCanvasIndex === canvasIndex && !force) {
		return
	}

	commit('SET_ACTIVE_CANVAS_INDEX', canvasIndex)

	// Remove all
	if (synchronizers.referenceLinesSynchronizer) synchronizers.referenceLinesSynchronizer.destroy()

	// Setup
	const canvas = state.canvases[canvasIndex]
	const hasImage = canvas.imageId || canvas.seriesId
	if (canvas.dom && hasImage) {
		synchronizers.referenceLinesSynchronizer.addSource(canvas.dom)
	}

	// Force all canvases to redraw
	cornerstoneTools.store.state.enabledElements.forEach(async element => {
		try {
			const imageLoaded = await waitForEnabledElementImageToLoad(element)
			if (imageLoaded) updateImage(element)
		} catch (err) {
			// ignore "element not enabled" errors
		}
	})
}
