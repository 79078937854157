export class Dbounce {
	_timer: number
	_delay: number
	_handler: Function

	constructor(delayMS: number, handler?: Function) {
		this._delay = delayMS
		this._handler = handler
	}

	set(handler?: Function, force = false) {
		if (handler) this._handler = handler
		else handler = this._handler

		this.clear()
		this._timer = setTimeout(handler, force ? 0 : this._delay)
	}

	setp<T>(handler?: () => Promise<T>, force = false): Promise<T> {
		this.clear()
		return new Promise<T>(resolve => {
			setTimeout(resolve, force ? 0 : this._delay)
		}).then(() => handler())
	}

	clear() {
		clearTimeout(this._timer)
		this._timer = 0
	}
}
