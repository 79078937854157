import metadata from './internals/metadataLookup.js'
import { getCornerstoneImageIdParts } from '@/imageLoader'

/**
 * Looks up metadata given a specified module and imageId
 * A module may be, e.g. 'study', or 'patient', or 'imagePlane'. These types
 * are keys in the stored metadata objects. Modules are loosely based on DICOM
 * modules: [ImagePixelModule - Example]{@link http://dicom.nema.org/medical/dicom/2016e/output/chtml/part03/sect_C.7.6.3.html}
 *
 * @public
 * @function provider
 *
 * @param {string} moduleName - The name/subset of information to locate
 * @param {string} imageId - A unique identifier for an image
 * @returns {Object} Relevant metadata of the specified type
 */
export default function(moduleName, imageId) {
	const isCornerstoneImageId = imageId.includes(':')
	if (isCornerstoneImageId) {
		const idParts = getCornerstoneImageIdParts(imageId)
		imageId = idParts.imageId
	}

	imageId = imageId.toLowerCase()
	const imageMetadata = metadata.lookup.get(imageId)

	if (!imageMetadata) {
		return
	}

	if (imageMetadata.hasOwnProperty(moduleName)) {
		return imageMetadata[moduleName]
	}
}
