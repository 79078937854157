import { salesService } from '@services/salesService'
import _ from 'lodash'

const state = {
	attachments: [],
}

// Mutations Map
const GET_ATTACHMENTS = 'GET_ATTACHMENTS'
const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS'

const mutations = {
	GET_ATTACHMENTS(state, payload) {
		Object.assign(state.attachments, payload)
	},
	CLEAR_ATTACHMENTS(state, payload) {
		state.attachments = payload
	},
}

const actions = {
	async getAttachmentsAction({ commit }, { consultantId, saleId, hipNumber, studyIds }) {
		const response = await salesService._getTemplate({
			consultantId,
			saleId,
			hipNumber,
			saleEntryId: hipNumber,
			studyIds,
		})
		commit(GET_ATTACHMENTS, response.attachments)
	},
	async clearAttachmentsAction({ commit }) {
		commit(CLEAR_ATTACHMENTS, [])
	},
}

export default {
	state,
	mutations,
	actions,
}
