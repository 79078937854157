<template>
	<div class="dialog">
		<modal-dialog
			:actions="actions"
			title="New Update Available!"
			close-text="Dismiss"
			:show-close-header="true"
			@close="resolve(false)"
			@confirm="resolve(true)"
		>
			<template #header>
				<svg-icon icon="inbox-download" size="1.3rem" />
			</template>
			You can choose to {{ actionText }} now to get the latest version, or dismiss and update at a
			later time.
		</modal-dialog>
	</div>
</template>

<script>
import ModalDialog from '@components/ModalDialog.vue'
import SvgIcon from '@components/SvgIcon'
import { dlg } from '@utils/dlgUtils'
import { omniDesktop } from '@/electron/omniDesktop'
import { mapState, mapActions } from 'vuex'

const UpdateAppDlg = {
	name: 'UpdateAppDlg',
	components: {
		SvgIcon,
		ModalDialog,
	},
	computed: {
		...mapState({
			isVisible: state => state.ui.updateAppBannerVisible,
		}),
		actionText() {
			return omniDesktop.isConnected ? 'Install & Relaunch' : 'Refresh'
		},
		actions() {
			return [
				{
					type: 'button',
					name: this.actionText,
					emit: 'confirm',
				},
			]
		},
	},
	watch: {
		isVisible(v) {
			if (!v) dlg.close(this, true, null, false)
		},
	},
	methods: {
		resolve(result) {
			this.$store.dispatch('handleUpdateAppBannerAction', result)
		},
	},
}

export default UpdateAppDlg

export function showAppUpdateAvailableDlg() {
	return dlg.open(UpdateAppDlg, {}, false)
}
</script>

<style lang="scss" scoped></style>
