import store from '@store'
import { API } from '@services/api'

class UserData {
	get claims(): IClaims {
		return store.state.auth.claims
	}

	get permissions(): IPermissions {
		return store.state.static.permissions
	}

	get isAuthenticated(): boolean {
		return !!store.state.auth.token
	}

	get queryToken(): string {
		return `${this.claims.activeClinicCode}_${this.claims.sessionId}`
	}

	get canCreateScheduleItem() {
		return this.claims.isSchedulingEnabled
	}

	getPermissionMeta(): Promise<IPermissionMeta> {
		return API.get(`/user/permission-meta`).then(r => r.data)
	}

	getRegions(): Promise<any> {
		return API.get(`/user/regions`).then(r => r.data)
	}

	getAllUsers(): Promise<IBasicUser> {
		return API.get(`/user/all-users`).then(r => r.data)
	}

	getUserDetails(userId?: string): Promise<IUserAccount> {
		return API.get(`/user/get-user-details`, { params: { userId } }).then(r => r.data)
	}

	getUserDefaults(): Promise<IUserAccount> {
		return API.get(`/user/user-defaults`).then(r => r.data)
	}

	updateUser(data: IUserAccount) {
		return API.post(`/user/update-user`, data)
	}

	createUser(data: IUserAccount) {
		return API.post(`/user/create-user`, data).then(r => {
			data.userId = r.data
		})
	}

	updateUserDefaults(data: IUserAccount) {
		return API.post(`/user/user-defaults`, data)
	}

	deleteUser(userId: string) {
		return API.delete(`/user/${userId}`)
	}

	enableUser(enabled: boolean, userId: string) {
		return API.put(`/user/enable`, null, { params: { enabled, userId } })
	}

	updateSignature(userId: string, file: File) {
		var formData = new FormData()
		formData.append('image', file)
		return API.post(`/user/signature/${userId}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	}

	updateLogo(userId: string, file: File) {
		var formData = new FormData()
		formData.append('image', file)
		return API.post(`/user/logo/${userId}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	}

	getCommunityUserAccounts(email) {
		return API.get(`/user/get-community-user-accounts?email=${email}`).then(r => r.data)
	}

	getClinicImages(): Promise<IClinicImage[]> {
		return API.get(`/clinic/image`).then(r => r.data)
	}

	saveClinicImage(image: IClinicImage, file: File) {
		var formData = new FormData()
		formData.append('image', file)
		return API.post(`/clinic/image/${image.id}`, formData).then(r => {
			image.id = r.data.id
			image.url = r.data.url
		})
	}

	deleteClinicImage(id: string) {
		return API.delete(`/clinic/image/${id}`)
	}
}

export const userData = new UserData()
