import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { getCornerstoneImageId } from '@/imageLoader'
import { eventBus } from '@services/eventBus'

export default function clearAnnotations({ state }, { activeImage, activeSeries } = {}) {
	if (!activeImage) {
		// Clear all image annotations
		clearToolData()
	} else {
		const activeCornerstoneImageId = getCornerstoneImageId(activeSeries, activeImage)

		// Clear specific image's annotations
		clearToolData(activeCornerstoneImageId)

		// Refresh updated canvases
		// checks each image's cornerstone `imageId` property
		cornerstone.getEnabledElementsByImageId(activeCornerstoneImageId).forEach(enabledElement => {
			cornerstone.updateImage(enabledElement.element)
		})
	}
}

function clearToolData(cornerstoneImageId) {
	const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState()
	if (!toolState) return
	Object.keys(toolState).forEach(imageId => {
		if (!cornerstoneImageId || cornerstoneImageId === imageId) delete toolState[imageId]
	})
	cornerstoneTools.globalImageIdSpecificToolStateManager.restoreToolState(toolState)
	eventBus.post(eventBus.type.ANNOTATIONS_CLEARED)
}
