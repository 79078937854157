<template>
	<svg
		v-if="hasData"
		ref="container"
		:viewBox="viewBox"
		:width="width"
		:height="height"
		:class="svgClass"
		@mousemove="onMove"
		@mouseup="endMove"
		@touchmove.prevent="onMove"
		@touchend="endMove"
	>
		<circle
			:cx="x"
			:cy="y"
			:r="isTouch ? 22 : 10"
			style="fill: transparent; cursor: grab;"
			class="captureMouse"
			@mousedown="e => startAction(e, 'moveAxis')"
			@touchstart="e => startAction(e, 'moveAxis')"
		/>
		<g :transform="viewTransform">
			<!-- Y line -->
			<g :transform="yTransform" :style="`color: ${yAxis.color}; fill: currentColor;`">
				<!-- Axis & rotation interactors -->
				<line
					:x1="x"
					:y1="y - maxLength"
					:x2="x"
					:y2="y + maxLength"
					style="stroke: currentColor; stroke-width:2"
				/>
				<circle
					v-dblclick="e => resetAxisRotation(e, 'Y')"
					:cx="x"
					:cy="y + circlePos"
					r="6"
					:class="{
						'hover rotateCursor': true,
						active: mousedown && action === 'rotateY' && !invertAngle,
						touch: isTouch,
					}"
					@mousedown="e => startAction(e, 'rotateY')"
					@touchstart="e => startAction(e, 'rotateY')"
				/>
				<circle
					v-dblclick="e => resetAxisRotation(e, 'Y')"
					:cx="x"
					:cy="y - circlePos"
					r="6"
					:class="{
						'hover rotateCursor y-top': true,
						active: mousedown && action === 'rotateY' && invertAngle,
						touch: isTouch,
					}"
					@mousedown="e => startAction(e, 'rotateY', true)"
					@touchstart="e => startAction(e, 'rotateY', true)"
				/>
				<!-- Thickness interactors -->
				<rect
					:x="x - 4 + yThicknessPixels"
					:y="y + squarePos"
					width="8"
					height="8"
					class="hover thicknessCursor"
					:class="{ touch: isTouch }"
					@mousedown="e => startAction(e, 'thicknessY')"
					@touchstart="e => startAction(e, 'thicknessY')"
				/>
				<rect
					:x="x - 4 + yThicknessPixels"
					:y="y - squarePos - 8"
					width="8"
					height="8"
					class="hover thicknessCursor y-top"
					:class="{ touch: isTouch }"
					@mousedown="e => startAction(e, 'thicknessY')"
					@touchstart="e => startAction(e, 'thicknessY')"
				/>
				<g v-if="yAxis.thickness >= 1">
					<rect
						:x="x - 4 - yThicknessPixels"
						:y="y + squarePos"
						width="8"
						height="8"
						class="hover thicknessCursor"
						:class="{ touch: isTouch }"
						@mousedown="e => startAction(e, 'thicknessY')"
						@touchstart="e => startAction(e, 'thicknessY')"
					/>
					<rect
						:x="x - 4 - yThicknessPixels"
						:y="y - squarePos - 8"
						width="8"
						height="8"
						class="hover thicknessCursor"
						:class="{ touch: isTouch }"
						@mousedown="e => startAction(e, 'thicknessY')"
						@touchstart="e => startAction(e, 'thicknessY')"
					/>
					<g style="stroke: currentColor; stroke-width:1; stroke-dasharray: 4;">
						<line
							:x1="x - yThicknessPixels"
							:y1="y - maxLength"
							:x2="x - yThicknessPixels"
							:y2="y + maxLength"
						/>
						<line
							:x1="x + yThicknessPixels"
							:y1="y - maxLength"
							:x2="x + yThicknessPixels"
							:y2="y + maxLength"
						/>
					</g>
				</g>
			</g>

			<!-- X line -->
			<g :transform="xTransform" :style="`color: ${xAxis.color}; fill: currentColor;`">
				<!-- Axis & rotation interactors -->
				<line
					:x1="x - maxLength"
					:y1="y"
					:x2="x + maxLength"
					:y2="y"
					style="stroke: currentColor; stroke-width:2"
				/>
				<circle
					v-dblclick="e => resetAxisRotation(e, 'X')"
					:cx="x + circlePos"
					:cy="y"
					r="6"
					:class="{
						'hover rotateCursor': true,
						active: mousedown && action === 'rotateX' && !invertAngle,
						touch: isTouch,
					}"
					@mousedown="e => startAction(e, 'rotateX')"
					@touchstart="e => startAction(e, 'rotateX')"
				/>
				<circle
					v-dblclick="e => resetAxisRotation(e, 'X')"
					:cx="x - circlePos"
					:cy="y"
					r="6"
					:class="{
						'hover rotateCursor': true,
						active: mousedown && action === 'rotateX' && invertAngle,
						touch: isTouch,
					}"
					@mousedown="e => startAction(e, 'rotateX', true)"
					@touchstart="e => startAction(e, 'rotateX')"
				/>
				<!-- Thickness interactors -->
				<rect
					:x="x + squarePos"
					:y="y - 4 - xThicknessPixels"
					width="8"
					height="8"
					class="hover thicknessCursor"
					:class="{ touch: isTouch }"
					@mousedown="e => startAction(e, 'thicknessX')"
					@touchstart="e => startAction(e, 'thicknessX')"
				/>
				<rect
					:x="x - squarePos - 8"
					:y="y - 4 - xThicknessPixels"
					width="8"
					height="8"
					class="hover thicknessCursor"
					:class="{ touch: isTouch }"
					@mousedown="e => startAction(e, 'thicknessX')"
					@touchstart="e => startAction(e, 'thicknessX')"
				/>
				<g v-if="xAxis.thickness >= 1">
					<rect
						:x="x + squarePos"
						:y="y - 4 + xThicknessPixels"
						width="8"
						height="8"
						class="hover thicknessCursor"
						:class="{ touch: isTouch }"
						@mousedown="e => startAction(e, 'thicknessX')"
						@touchstart="e => startAction(e, 'thicknessX')"
					/>
					<rect
						:x="x - squarePos - 8"
						:y="y - 4 + xThicknessPixels"
						width="8"
						height="8"
						class="hover thicknessCursor"
						:class="{ touch: isTouch }"
						@mousedown="e => startAction(e, 'thicknessX')"
						@touchstart="e => startAction(e, 'thicknessX')"
					/>
					<g style="stroke: currentColor; stroke-width:1; stroke-dasharray: 4;">
						<line
							:x1="x - maxLength"
							:y1="y - xThicknessPixels"
							:x2="x + maxLength"
							:y2="y - xThicknessPixels"
						/>
						<line
							:x1="x - maxLength"
							:y1="y + xThicknessPixels"
							:x2="x + maxLength"
							:y2="y + xThicknessPixels"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
import { radians2degrees } from '../lib/math/angles.js'
import { vec2, glMatrix } from 'gl-matrix'

export default {
	name: 'MPRInteractor',
	props: {
		width: { type: Number, default: 0 },
		height: { type: Number, default: 0 },
		// the intersection point
		point: {
			// eslint-disable-next-line vue/require-prop-type-constructor
			type: Array | Boolean,
			default: false,
		},
		lockAxis: {
			type: Boolean,
			default: true,
		},
		shiftToUnlockAxis: {
			type: Boolean,
			default: true,
		},
		viewRotation: {
			type: Number,
			default: 0,
		},
		xAxis: {
			type: Object,
			default: () => ({
				color: 'red',
				rotation: 0,
				thickness: 0,
			}),
		},
		yAxis: {
			type: Object,
			default: () => ({
				color: 'blue',
				rotation: 0,
				thickness: 0,
			}),
		},
		flipH: Boolean,
		flipV: Boolean,
		shouldAnimate: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			mousedown: false,
			invertAngle: false,
			axisOffset: 0,
			action: '',
		}
	},
	computed: {
		isTouch() {
			return this.$store.state.toolManager.inputDevices.includes('touch')
		},
		hasData() {
			return (
				this.point &&
				!Number.isNaN(this.x) &&
				!Number.isNaN(this.y) &&
				this.height >= 0 &&
				this.width >= 0
			)
		},
		xTransform() {
			return `rotate(${this.xAxis.rotation}, ${this.x}, ${this.y})`
		},
		yTransform() {
			return `rotate(${this.yAxis.rotation}, ${this.x}, ${this.y})`
		},
		viewTransform() {
			return `rotate(${this.viewRotation}, ${this.x}, ${this.y}) ${this.scale}`
		},
		scale() {
			// Scales from 0 otherwise, so move to the intersection, scale, them move back
			return (
				`translate(${this.x}, ${this.y}) ` +
				`scale(${this.flipH ? -1 : 1}, ${this.flipV ? -1 : 1}) ` +
				`translate(${this.x * -1}, ${this.y * -1})`
			)
		},
		yStyle() {
			return `color: ${this.yAxis.color}; stroke: currentColor; stroke-width:2`
		},
		xThicknessPixels() {
			// divide by 2 so width is centered on the axis
			return this.xAxis.thickness >= 1 ? this.xAxis.thickness / 2 : 0
		},
		yThicknessPixels() {
			// divide by 2 so width is centered on the axis
			return this.yAxis.thickness >= 1 ? this.yAxis.thickness / 2 : 0
		},

		maxLength() {
			// Helps ensure axis lines are drawn to the edge, even when zoomed in and rotated odd angles
			return (this.width > this.height ? this.width : this.height) * 10
		},
		minLength() {
			// used to make sure everything is drawn inside the canvas
			return this.width < this.height ? this.width : this.height
		},
		circlePos() {
			return Math.floor(this.minLength / 2.5)
		},
		squarePos() {
			return Math.floor(this.minLength / 6)
		},

		viewBox() {
			return `0 0 ${this.width} ${this.height}`
		},

		x() {
			// Scale to window pixels if the screen is high density
			if (window.devicePixelRatio) {
				return this.point[0] / window.devicePixelRatio
				// math.floor?
				// vtkMath.multiplyScalar(canvasCoords, 1/window.devicePixelRatio)
			}
			return this.point[0]
		},
		y() {
			if (window.devicePixelRatio) {
				return this.height - this.point[1] / window.devicePixelRatio
			}
			// 0 is bottom left in vtk land vs canvas/svg
			return this.height - this.point[1]
		},
		svgClass() {
			return {
				captureMouse: this.mousedown,
				rotateCursor: this.mousedown && this.action.startsWith('rotate'),
				thicknessCursor: this.mousedown && this.action.startsWith('thickness'),
				axisCursor: this.mousedown && this.action === 'moveAxis',
				animate: this.shouldAnimate,
			}
		},
	},
	methods: {
		onMove(event) {
			// Recast a new event if the target is on a child node, since offsetX/Y will be "wrong" in that case
			// https://stackoverflow.com/questions/59725292/why-does-event-offsetx-y-provide-different-values-in-firefox-edge-vs-chrome
			/*
			if (event.target !== event.currentTarget) {
				// create a synthetic event
				const synth = new MouseEvent('mousemove', {
					clientX: event.clientX,
					clientY: event.clientY,
					shiftKey: event.shiftKey,
				})
				// dispatch it *synchronously* on the intended node
				event.currentTarget.dispatchEvent(synth)
				return
			}
			*/

			if (this.mousedown) {
				const shiftKey = event.shiftKey
				const isX = this.action.endsWith('X')

				// Both layerX and layerY are not available on touch targets
				//  but this method should work on both touch and desktop
				const rect = this.$refs.container.getBoundingClientRect()
				const { pageX, pageY } = this.isTouch ? event.targetTouches[0] : event
				const mouseX = pageX - rect.left
				const mouseY = pageY - rect.top
				const newPos = [mouseX, mouseY]

				// Account for view modifications by "undoing" them, modifying mouse position as if there was no transformation
				if (this.flipH) newPos[0] = 2 * this.x - newPos[0]
				if (this.flipV) newPos[1] = 2 * this.y - newPos[1]
				if (this.viewRotation)
					vec2.rotate(newPos, newPos, [this.x, this.y], -glMatrix.toRadian(this.viewRotation))

				if (this.action.startsWith('rotate')) {
					// calculate the rotation angle from mouse to center [x, y]
					const nx = newPos[0] - this.x
					const ny = newPos[1] - this.y

					let angle = Math.floor(radians2degrees(Math.atan2(ny, nx)))

					if (this.invertAngle) {
						// if positive, subtract 180, if negative, add 180, to get the same value as the right handle
						angle += 180 * (angle < 0 ? 1 : -1)
					}
					if (!isX) {
						// account for Y axis difference
						angle -= 90
					}

					// Lock to 360 rotation
					if (angle >= 360) angle -= 360
					else if (angle <= -360) angle += 360

					// emit the rotation
					this.$emit('rotate', isX ? 'x' : 'y', angle)
					if (this.lockAxis && !(this.shiftToUnlockAxis && shiftKey)) {
						this.$emit('rotate', !isX ? 'x' : 'y', angle - this.axisOffset)
					}
				} else if (this.action.startsWith('thickness')) {
					// adjust for the rotation of the plane to compare as if the axis wasn't rotated
					vec2.rotate(
						newPos,
						newPos,
						[this.x, this.y],
						-glMatrix.toRadian(isX ? this.xAxis.rotation : this.yAxis.rotation)
					)
					let dist = Math.floor(isX ? Math.abs(newPos[1] - this.y) : Math.abs(newPos[0] - this.x))

					// Have a deadzone so it can snap to "nothing".
					const DEADZONE = 3 // 3 pixel deadzone on either side of the axis
					if (dist <= DEADZONE) dist = 0
					// Multiply by 2 since the thickness is split between the axis
					this.$emit('thickness', isX ? 'x' : 'y', dist * 2)
				} else if (this.action === 'moveAxis') {
					let [x, y] = [mouseX, mouseY]
					if (window.devicePixelRatio) {
						x *= window.devicePixelRatio
						y = this.height - y
						y *= window.devicePixelRatio
					}
					this.$emit('moveaxis', [x, y])
				}
			}
		},
		startAction(event, action, invertAngle = false) {
			this.action = action
			this.mousedown = true
			if (action.startsWith('rotate')) {
				this.invertAngle = invertAngle
				this.axisOffset = action.endsWith('X')
					? this.xAxis.rotation - this.yAxis.rotation
					: this.yAxis.rotation - this.xAxis.rotation
			}
		},
		endMove(event) {
			this.mousedown = false
		},
		resetAxisRotation(event, axis) {
			if (event.shiftKey) {
				const isX = axis === 'X'
				const rot = (isX ? this.xAxis : this.yAxis).rotation
				this.$emit('rotate', !isX ? 'x' : 'y', rot)
			} else {
				this.$emit('rotate', 'x', 0)
				this.$emit('rotate', 'y', 0)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
svg {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	&.animate {
		transition: width 0.2s ease, height 0.2s ease;
	}
}
.captureMouse {
	pointer-events: all;
	.captureMouse {
		// If nested, ignore children with capturemouse class
		pointer-events: none;
	}
}

svg .hover {
	pointer-events: all;
	/* Increase the mouse hover area */
	stroke: transparent;
	stroke-width: 6;
	&.touch {
		stroke-width: 22px;
	}
}
svg .hover:hover,
svg .hover.active {
	stroke: currentColor;
}

.axisCursor {
	cursor: grabbing;
}
.thicknessCursor {
	cursor: col-resize;
}
/* prettier-ignore */
.rotateCursor {
  cursor: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyNC4yNDUiPjxwYXRoIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxIiBkPSJNMTAgMjMuNzQ2Yy01LjIzOCAwLTkuNS00LjI2Mi05LjUtOS41YTkuOCA5LjggMCAwIDEgLjAxNC0uNTI3bC4wMjYtLjQ3M0g0LjU2NGwtLjA0NS41NDJBNS41MDcgNS41MDcgMCAwIDAgMTAgMTkuNzQ2YzMuMDMzIDAgNS41LTIuNDY3IDUuNS01LjUgMC0yLjg2NS0yLjItNS4yMjUtNS01LjQ3OHYzLjYwMWwtLjgzNS0uNzUxLTQuOTk5LTQuNS0uNDEzLS4zNzIuNDEzLS4zNzIgNS00LjUuODM0LS43NXYzLjYzNWM1LjAwNy4yNiA5IDQuNDE2IDkgOS40ODcgMCA1LjIzOC00LjI2MiA5LjUtOS41IDkuNXoiLz48L3N2Zz4=) 10 12, pointer;
}
.crosshairCursor {
	cursor: crosshair;
}
</style>
