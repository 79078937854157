




















import { Cell } from '../classes'
import { ContextMeta } from '@reporting/context'
import reportService from '@services/reportService'
import store from '@store'
import Button from '@/components/Button.vue'
import { openImageCommentLayoutDlg } from '../dialogs/ImageCommentLayoutDlg.vue'
import GoogleAnalytics from '@services/analyticsService'

export default {
	name: 'ImageComments',
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	computed: {
		canSetLayout() {
			return !this.cell.editorMode && !this.cell.readOnly && !this.cell.template.isLegacy
		},
		pdfColumns() {
			let layout: IImageCommentsLayout = JSON.parse(this.cell.value)
			return layout && layout.columns
		},
		claims() {
			return this.$store.state.auth.claims
		},
		isReadOnly() {
			return true
		},
		images() {
			return this.cell.editorMode ? [] : this.cell.report.imageComments
		},
		areImageCommentsViewable() {
			if (this.cell.template.editorMode) return true
			if (this.cell.report.isStandardReport) return true

			let report = this.cell.report
			if (!report.canViewResponse) return false
			if (!report.canRespond && report.images.length === 0) return false
			return true
		},
	},
	created() {
		this.createSubLayouts()
		if (this.cell.template.editorMode)
			// maintain sample image comment in editor when undoing/redoing
			this.$watch('cell.report', this.createSubLayouts, { deep: true })
		else this.$watch('cell.report.imageComments', this.createSubLayouts)
	},
	methods: {
		createSubLayouts() {
			let cell: Cell = this.cell
			cell.onSave = this.saveValues
			let t = cell.template
			let images: IImageComment[] = []
			if (t.editorMode) {
				// use ContextMeta sample data
				let stuff = ContextMeta.ImageComment.items
				let image: IImageComment = {
					htmlValue: stuff.Comment.sample,
					textValue: '',
					editDateTime: stuff.EditDateTime.sample,
					reportImageId: '',
					imageId: '',
					order: 0,
					acquisitionDate: stuff.AcquisitionDate.sample,
					anatomy: stuff.Anatomy.sample,
					limb: stuff.Limb.sample,
					url: '',
					view: stuff.View.sample,
				}
				images = [image]
			} else {
				images = cell.report.imageComments
			}
			cell.subLayouts = []

			images.forEach(i => {
				let layout = t.createLayoutInstance('Image Comment')

				layout.previewInEditor = t.editorMode
				layout.template = t
				layout.readOnly =
					(cell.report && cell.report.isComplete) ||
					(cell.report && !cell.report.canRespond && !cell.report.isStandardReport)
				let context = {
					...this.cell.context,
					ImageComment: {
						// Comment: i.htmlValue,
						EditDateTime: i.editDateTime,
						reportImageId: i.reportImageId,
						imageId: i.imageId,
						AcquisitionDate: i.acquisitionDate,
						Anatomy: i.anatomy,
						Limb: i.limb,
						url: i.url,
						View: i.view,
					},
				}
				cell.addSubLayout(layout, context)

				let valueMap = layout.template.values
				layout.controls.forEach(c => {
					if (c.props.binding === 'ImageComment.Comment') {
						valueMap[c.path] = i.htmlValue || null
					}
				})
				layout.hydrate()
			})

			if (cell.value) {
				let layout: IImageCommentsLayout = JSON.parse(cell.value)
				this.sort(layout)
			}
		},
		saveValues(valueMap) {
			let cell: Cell = this.cell

			// save the Image Comment Layout
			valueMap[cell.path] = cell.value

			// iterate the sublayouts
			cell.subLayouts.forEach(l => {
				let orig = cell.report.imageComments.find(i => i.imageId === l.context.ImageComment.imageId)
				l.controls.forEach(c => {
					if (c.props.binding === 'ImageComment.Comment') {
						// if control is bound to the comment, check to see if value has changed
						if (c.userValue !== orig.htmlValue) {
							orig.changed = true
							orig.htmlValue = c.userValue
							delete valueMap[c.path]
						}
					}
				})
			})
		},
		openViewer() {
			try {
				GoogleAnalytics.sendGAEvent('Teleconsultation', {
					event_category: 'Launch',
					event_label: 'Teleconsultation Viewer'
				})
			}
			catch (err) {
				console.log(err)
			}
			const route = this.$router.resolve({
				name: 'report-viewer',
				params: { id: this.cell.report.reportId, clinicCode: this.claims.activeClinicCode },
			})
			window.open(route.href, '_blank', 'resizable,scrollbars,status')
		},
		sort(layout: IImageCommentsLayout) {
			let cell: Cell = this.cell
			cell.subLayouts.sort((a, b) => {
				let aReportImageId = a.context.ImageComment.reportImageId
				let bReportImageId = b.context.ImageComment.reportImageId
				let aIndex = layout.imageReportIds.indexOf(aReportImageId)
				let bIndex = layout.imageReportIds.indexOf(bReportImageId)
				return aIndex - bIndex
			})
		},
		async editLayout() {
			let layout = await openImageCommentLayoutDlg(this.cell)
			if (layout) {
				this.cell.value = JSON.stringify(layout)
				this.sort(layout)
			}
		},
	},
}
