<template>
	<transition name="fade" appear @after-enter="$emit('appear')">
		<div
			ref="modalOverlay"
			:class="{ clickable: closeOnOverlayClick && !noOverlay, 'no-overlay': noOverlay }"
			class="modal-overlay"
			@mousedown="onOverlayClick($event)"
		>
			<slot></slot>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'ModalWrapper',
	props: {
		closeOnEscape: {
			type: Boolean,
			default: true,
		},
		closeOnOverlayClick: {
			type: Boolean,
			default: true,
		},
		noOverlay: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		const handleEscape = e => {
			if (e.key !== 'Escape' || !this.closeOnEscape) return
			this.$emit('close')
		}

		document.addEventListener('keydown', handleEscape)
		document.body.style.overflow = 'hidden'

		this.$once('hook:destroyed', () => {
			document.removeEventListener('keydown', handleEscape)
			document.body.style.overflow = 'auto'
		})
	},
	mounted() {
		this.$emit('show')
	},
	methods: {
		onOverlayClick(e) {
			if (this.closeOnOverlayClick && e.target === this.$refs.modalOverlay && e.button === 0) {
				this.$emit('close')
			}
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.modal-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: map-get($zindex, modal);
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.45);
	-webkit-tap-highlight-color: transparent;
	& > * {
		box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.2), 0 0 32px 24px rgba(0, 0, 0, 0.1);
	}
	&.no-overlay {
		pointer-events: none;
		background: transparent;
		& > * {
			pointer-events: all;
			box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
			border: 1px solid var(--tertiary-border);
		}
	}
}
.modal-overlay.clickable {
	cursor: pointer;
}
.modal-container {
	cursor: default;
	max-height: 100%;
	display: flex;
}
</style>
