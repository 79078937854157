<template>
	<div>
		<template v-if="cell.widgets.length > 1">
			<div style="font-style: italic; margin-bottom: 8px;">
				Please select a request type.
			</div>
			<v-select
				v-model="cell.value"
				:options="cell.widgets"
				:clearable="false"
				label="name"
				:reduce="o => o.name"
				style="margin-bottom: 16px;"
				:tabindex="cell.template.editorMode ? -1 : 0"
				@input="setSubLayout"
			/>
		</template>
		<layout v-for="(layout, index) in cell.subLayouts" :key="index" :layout="layout" />
		<div v-if="cell.widgets.length === 0 && cell.template.editorMode" class="editor-placeholder">
			<svg-icon icon="exclamation-triangle" class="is-warning" inline fixed />
			No forms are available for this selector. Use the
			<strong style="font-size: 0.9em;">Add New Form</strong> button in the properties pane to add
			one.
		</div>
	</div>
</template>

<script>
import { Cell } from '../classes'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'

export default {
	name: 'RequestSelector',
	props: ['cell'],
	watch: {
		'cell.widgets': {
			handler(widgets, oldWidgets) {
				if (widgets.length === 1) this.cell.value = widgets[0].name
				else if (this.cell.editorMode) {
					this.cell.value = null
					this.cell.subLayouts = []
				}
				this.setSubLayout()
			},
			immediate: true,
		},
	},
	methods: {
		async setSubLayout() {
			let cell = this.cell
			const isChanging = cell.subLayout && cell.subLayout.name !== cell.value
			if (isChanging && !cell.editorMode && cell.widgets.length > 1) {
				let msg =
					'Are you sure you want to change the request type? This will clear any data already entered into the Background fields.'
				let confirmed = await showConfirm(msg)
				if (!confirmed) {
					cell.value = cell.subLayout.name // restore previous selector value
					return
				}
			}

			let layout = cell.layout.template.getLayout(cell.value)
			if (layout) {
				cell.subLayouts = []
				let sub = layout.createInstance()
				sub.readOnly = this.cell.readOnly
				sub.forcePath = layout.name
				cell.addSubLayout(sub)
				if (cell.report) cell.report.validate()
			}
		},
	},
}
</script>
