import api from '@services/api'
import saveAs from 'file-saver'

export default async function saveImage(imgBase64, fileName, dispatch) {
	try {
		// Pure client-side (this will fail if `new Blob` is not supported which is only for very old browsers)
		saveAs(imgBase64, fileName)
	} catch (e) {
		// Fallback to network request
		const params = {
			fileName,
			imgBase64,
		}

		const { data } = await api.file.uploadTemporaryImage(params)
		if (data) {
			const url = api.file.getDownloadTemporaryImageUrl({
				fileName: data,
			})

			// https://stackoverflow.com/a/2917/1867984
			const win = window.open(url)
			if (!win || win.closed || typeof win.closed === 'undefined') {
				dispatch('addNotification', {
					message: 'Unable to save image. Pop-up was blocked.',
					notificationType: 'warn',
				})
			}
		}
	}
}
