import api from '@services/api'

// Actions
import clearAnnotations from './clearAnnotations'
import copyCanvasAsync from './copyCanvasAsync'
import createAnnotationRenderingAsync from './createAnnotationRenderingAsync'
import imageNavigation from './imageNavigation'
import printCanvasAsync from './printCanvasAsync'
import resetActiveCanvas from './resetActiveCanvas'
import saveCanvasAsync from './saveCanvasAsync'
import setActiveCanvas from './setActiveCanvas'
import setCanvasInfo from './setCanvasInfo'
import {
	setStackSynchronizationMethod,
	requestImageStackForManualSync,
	setImageStackForManualFromExternal,
} from './setStackSynchronizationMethod'
import viewportActions from './viewportActions'
import { virtualStackScroll, virtualNewImage } from './virtualCornerstoneEvents'
import { toggleImageOrientationMarkers, setImageOrientationMarkers } from './imageOrientationMarkers'
import { toggleLengthAngles, setLengthAngles } from './lengthAngles'
import { toggleReferenceLineSynchronization, setReferenceLineSynchronization } from './referenceLineSynchronization'
import {
	startImageViewerSync,
	stopImageViewerSync,
	calibrateFromExternalImage,
	updateExternalToolStateForId,
} from './imageViewerSync'
import { updateCalibration, clearCalibration, clearCalibrationAndBroadcast } from './calibration'
import setActiveImageMetaDataFromElement from './setActiveImageMetaDataFromElement'
import studyData from '@services/studyData'
import { eventBus } from '@services/eventBus'
import storage from '@services/storage'
import { omniDesktop } from '@/electron/omniDesktop'

const viewer = {
	CONSIGNER: 'consigner',
	CONSULTATION_REQUEST: 'consulation_request',
	REPORT: 'report',
	STUDY: 'study',
	STUDY_PARTNER: 'study_partner',
}

export default {
	...viewportActions,
	clearAnnotations,
	syncClearAnnotations(state, { activeImage, activeSeries }) {
		clearAnnotations(state, { activeImage, activeSeries })
		eventBus.broadcast(eventBus.type.VUEX_ACTION, {
			type: 'clearAnnotations',
			payload: { activeImage, activeSeries },
		})
	},
	copyCanvasAsync,
	createAnnotationRenderingAsync,
	...imageNavigation,
	printCanvasAsync,
	resetActiveCanvas,
	saveCanvasAsync,
	setActiveCanvas,
	setCanvasInfo,
	toggleImageOrientationMarkers,
	setImageOrientationMarkers,
	toggleLengthAngles,
	setLengthAngles,
	toggleReferenceLineSynchronization,
	setReferenceLineSynchronization,
	virtualStackScroll,
	virtualNewImage,
	setActiveImageMetaDataFromElement,
	setStackSynchronizationMethodAndBroadcast({ dispatch }, payload) {
		const type = 'setStackSynchronizationMethod'
		dispatch(type, payload)
		eventBus.broadcast(eventBus.type.VUEX_ACTION, { type, payload })
	},
	setStackSynchronizationMethod,
	requestImageStackForManualSync,
	setImageStackForManualFromExternal,
	startImageViewerSync,
	stopImageViewerSync,
	updateCalibration,
	clearCalibration,
	clearCalibrationAndBroadcast,
	calibrateFromExternalImage,
	updateExternalToolStateForId,
	toggleStackSynchronizationMethod({ state, dispatch }, method) {
		switch (method) {
			case 'auto':
				dispatch(
					'setStackSynchronizationMethod',
					state.settingsPanel.isStackScrollSynchronizationEnabled ? 'off' : 'auto'
				)
				break
			case 'manual':
				dispatch(
					'setStackSynchronizationMethod',
					state.settingsPanel.isManualStackScrollSynchronizationEnabled ? 'off' : 'manual'
				)
				break
		}
	},
	async getConsignerStudyViewerVmAsync({ state, commit, dispatch }, { id }) {
		const apiMethod = api.viewer.getConsignerStudy
		const postData = { id }
		return getViewerViewModel({ state, commit, dispatch }, { apiMethod, postData }, viewer.CONSIGNER)
	},
	async getConsultationRequestViewerVmAsync({ state, commit, dispatch }, { id, clinicCode, recipientId }) {
		const apiMethod = api.viewer.getConsultationRequest
		const postData = { id, clinicCode, recipientId }
		return getViewerViewModel({ state, commit, dispatch }, { apiMethod, postData }, viewer.CONSULTATION_REQUEST)
	},
	async getReportViewerVmAsync(
		{ state, commit, dispatch },
		{ id, initialSeriesId, initialSeriesIds, initialImageId, fillCanvases }
	) {
		const apiMethod = api.study.getReportStudies
		const postData = id
		return getViewerViewModel(
			{ state, commit, dispatch },
			{ apiMethod, postData, initialSeriesId, initialImageId, initialSeriesIds, fillCanvases },
			viewer.REPORT
		)
	},
	async getStudyViewerVmAsync(
		{ state, commit, dispatch },
		{ ids, initialSeriesId, initialImageId, initialSeriesIds, fillCanvases }
	) {
		const apiMethod = api.viewer.getStudy
		const postData = { ids }
		return getViewerViewModel(
			{ state, commit, dispatch },
			{ apiMethod, postData, initialSeriesId, initialImageId, initialSeriesIds, fillCanvases },
			viewer.STUDY
		)
	},
	async getStudyPartnerViewerVmAsync(
		{ state, commit, dispatch },
		{ ids, clinicCode, initialSeriesId, initialImageId }
	) {
		const apiMethod = api.viewer.getPartnerStudy
		const postData = { ids, clinicCode }
		return getViewerViewModel(
			{ state, commit, dispatch },
			{ apiMethod, postData, initialSeriesId, initialImageId },
			viewer.STUDY_PARTNER
		)
	},
	async removeStudy({ commit, dispatch, state }, { studyId, reportId }) {
		commit('REMOVE_STUDY', studyId)
		if (omniDesktop.isConnected) omniDesktop.request('removeStudy', { studyId, reportId })
		// TODO: will need to sync with other windows!!! (Remove from other windows, shuffle all open windows)
		const series = [].concat(...state.studies.map(study => study.imageData.series))
		commit('FILL_CANVASES', series)
		if (state.hangingProtocol) dispatch('rebuildRemnantDisplaySets')
	},
	async fetchLoadSeriesFromMiddle({ commit }) {
		const loadSeriesFromMiddle = await api.user.getUserSetting('LoadSeriesFromMiddle', 'Viewer').then(r => r && r.data)
		if ([true, false].includes(loadSeriesFromMiddle)) commit('SET_LOAD_SERIES_FROM_MIDDLE', loadSeriesFromMiddle)
	},
	async setLoadSeriesFromMiddle({ commit }, loadSeriesFromMiddle) {
		commit('SET_LOAD_SERIES_FROM_MIDDLE', loadSeriesFromMiddle)
		api.user.setUserSetting('LoadSeriesFromMiddle', 'Viewer', loadSeriesFromMiddle)
	},
	async fetchViewerToolbarLocation({ commit }) {
		const location = await api.user.getUserSetting('ToolbarLocation', 'Viewer').then(r => r && r.data)
		if (['top', 'right', 'bottom', 'left'].includes(location)) commit('SET_VIEWER_TOOLBAR_LOCATION', location)
	},
	async setViewerToolbarLocation({ commit }, location) {
		commit('SET_VIEWER_TOOLBAR_LOCATION', location)
		api.user.setUserSetting('ToolbarLocation', 'Viewer', location)
	},
	async getUserViewerSettings({ commit, dispatch }) {
		// const { data } = await api.user.getViewerSettings() // TODO
		// commit('SET_USER_SETTINGS', data)
		const id = storage.getItem('hangingProtocolId')
		if (id) dispatch('updateHangingProtocol', { id })
	},
	async updateUserViewerSettings({ state, commit }, data) {
		if (!data) return

		commit('SET_USER_SETTINGS', data)
		if (state.hangingProtocol) {
			storage.setItem('hangingProtocolId', state.hangingProtocol.id)
		} else {
			storage.removeItem('hangingProtocolId')
		}
		// await api.user.updateViewerSettings(data) // TODO
	},
	async getHangingProtocols({ commit, dispatch }) {
		const { data } = await api.clinic.getHangingProtocols()
		commit('SET_HANGING_PROTOCOLS', data)
	},
	async updateHangingProtocol({ state, commit, dispatch, getters }, { id }) {
		const isValidId = state.hangingProtocols.some(protocol => protocol.value === id)
		if (!id || !isValidId) {
			commit('SET_HANGING_PROTOCOL', undefined)
			return dispatch('updateUserViewerSettings', { hangingProtocol: null })
		}

		try {
			commit('SET_HANGING_PROTOCOL_LOADING', true)
			const { data } = await api.clinic.getHangingProtocol(id)
			const remnantDisplaySets = createRemnantDisplaySets(data.displaySets, getters.allSeries)
			data.displaySets = data.displaySets.concat(remnantDisplaySets)
			commit('SET_ACTIVE_DISPLAYSET_INDEX', 0)
			commit('SET_HANGING_PROTOCOL', data)
			dispatch('setHangingProtocolDisplaySet', 0)
			// HACK: Set initial displayset again in 100ms to win race condition
			// with debounced update in ImageViewer
			// TODO: FILL_CANVASES after any potential hanging protocol loading?
			setTimeout(() => {
				dispatch('setHangingProtocolDisplaySet', 0)
			}, 100)
			dispatch('updateUserViewerSettings', { hangingProtocol: id })
		} catch (ex) {
			dispatch('updateHangingProtocol', null)
		} finally {
			commit('SET_HANGING_PROTOCOL_LOADING', false)
		}
	},
	rebuildRemnantDisplaySets({ commit, dispatch, getters, state }) {
		let displaySets = state.hangingProtocol.displaySets.filter(displaySet => !displaySet.name.includes('Remnant'))
		const remnantDisplaySets = createRemnantDisplaySets(displaySets, getters.allSeries)
		displaySets = displaySets.concat(remnantDisplaySets)
		commit('SET_ACTIVE_DISPLAYSET_INDEX', 0)
		commit('SET_HANGING_PROTOCOL', { ...state.hangingProtocol, displaySets })
		dispatch('setHangingProtocolDisplaySet', 0)
	},
	setHangingProtocolDisplaySet({ commit, dispatch, state }, index) {
		if (!state.hangingProtocol || !state.hangingProtocol.displaySets) return
		const displaySet = state.hangingProtocol.displaySets[index]
		if (!displaySet) return
		dispatch('setActiveCanvas', { canvasIndex: 0 })
		const columns = displaySet.rows[0].length
		commit('SET_CANVAS_LAYOUT', {
			columns,
			numCanvases: columns * displaySet.rows.length,
		})
		dispatch('updateDisplaySetCanvases', displaySet)
		commit('SET_ACTIVE_DISPLAYSET_INDEX', index)
	},
	updateDisplaySetCanvases({ dispatch, getters }, { rows }) {
		let canvasIndex = 0
		rows.forEach(imageViews => {
			imageViews.forEach(imageView => {
				const series = getters.allSeries.find(currentSeries => {
					if (currentSeries.imageViewId === imageView.id) return true
					if (currentSeries.seriesId === imageView.id) return true
					if (currentSeries.images[0].imageViewId === imageView.id) return true
					return false
				})
				dispatch('updateCanvas', {
					canvasIndex,
					seriesId: series ? series.seriesId : null,
					imageView,
				})
				canvasIndex++
			})
		})
	},
	async switchSeries({ state, dispatch }, { reportId, studyId, seriesId, imageId, clinicCode }) {
		const isStudyLoaded = studyId && state.studies.some(s => s.studyId === studyId)
		const isReportLoaded = reportId && state.studies.some(s => s.reportId === reportId)
		if (studyId && !isStudyLoaded) {
			if (clinicCode) {
				// From partner viewer only
				await dispatch('getStudyPartnerViewerVmAsync', {
					ids: studyId,
					clinicCode,
					fillCanvases: false,
				})
			} else {
				await dispatch('getStudyViewerVmAsync', {
					ids: studyId,
					fillCanvases: false,
				})
			}
		}
		if (reportId && !isReportLoaded) {
			await dispatch('getReportViewerVmAsync', {
				id: reportId,
				fillCanvases: false,
			})
		}
		if (reportId || studyId || seriesId || imageId) {
			dispatch('updateCanvas', { studyId, seriesId, asterisImageId: imageId })
		}
	},
	async updateCanvas(
		{ state, commit, getters, dispatch },
		{ canvasIndex, studyId, seriesId, asterisImageId, frameIndex, imageView }
	) {
		canvasIndex = isNaN(canvasIndex) ? state.activeCanvasIndex : canvasIndex
		const canvas = state.canvases[canvasIndex]

		if (asterisImageId && !seriesId) {
			const series = getters.allSeries.find(series => series.images.some(i => i.imageId === asterisImageId))
			if (series) {
				studyId = series.studyId
				seriesId = series.seriesId
			}
		} else if (seriesId && !studyId) {
			const series = getters.allSeries.find(
				series => series.seriesId === seriesId || series.originalSeriesId === seriesId
			)
			if (series) {
				studyId = series.studyId
				seriesId = series.seriesId // in case image.seriesId is the originalSeriesId
			}
		}
		// Cache previous series if already set/modified
		if (studyId && seriesId && canvas.seriesId !== seriesId) {
			if (canvas.seriesId) dispatch('cacheSeriesViewport', canvas)
			commit('SET_CANVAS_ACTIVE_SERIES', {
				canvasIndex,
				studyId,
				seriesId,
				imageView,
			})
		}
		if (!seriesId && imageView !== undefined) {
			if (canvas.seriesId) dispatch('cacheSeriesViewport', canvas)
			commit('SET_CANVAS_ACTIVE_SERIES', { canvasIndex, imageView })
		}
		if (asterisImageId !== undefined) {
			commit('SET_CANVAS_ACTIVE_IMAGE', {
				canvasIndex,
				asterisImageId,
				frameIndex,
			})
		}
	},
	previousSeries: ({ dispatch, getters }) => {
		const seriesIndex =
			getters.activeStudy.imageData.series.findIndex(s => s.seriesId === getters.activeCanvas.seriesId) - 1
		const series = getters.activeStudy.imageData.series[seriesIndex]
		if (series)
			dispatch('updateCanvas', {
				studyId: series.studyId,
				seriesId: series.seriesId,
			})
	},
	nextSeries: ({ dispatch, getters }) => {
		const seriesIndex =
			getters.activeStudy.imageData.series.findIndex(s => s.seriesId === getters.activeCanvas.seriesId) + 1
		const series = getters.activeStudy.imageData.series[seriesIndex]
		if (series)
			dispatch('updateCanvas', {
				studyId: series.studyId,
				seriesId: series.seriesId,
			})
	},
	previousDisplaySet: ({ state, dispatch }) => {
		if (state.hangingProtocol) {
			dispatch('setHangingProtocolDisplaySet', state.activeDisplaySetIndex - 1)
		} else {
			dispatch('previousSeries')
		}
	},
	nextDisplaySet: ({ state, dispatch }) => {
		if (state.hangingProtocol) {
			dispatch('setHangingProtocolDisplaySet', state.activeDisplaySetIndex + 1)
		} else {
			dispatch('nextSeries')
		}
	},
	toggleCanvasFullscreen({ state, commit }) {
		const { canvasLayout, prevCanvasLayout } = state
		const { canvasesPerRow, numCanvases } = canvasLayout
		// Initialize fullscreen status for current and previous layouts
		const currFullscreen = canvasesPerRow === 1 && numCanvases === 1
		const prevNonFullscreen =
			prevCanvasLayout && (prevCanvasLayout.canvasesPerRow !== 1 || prevCanvasLayout.numCanvases !== 1)
		// Default to fullscreen layout
		const newLayout = { columns: 1, numCanvases: 1 }
		// Only apply non-fullscreen layout if we're in fullscreen and previous layout was not fullscreen
		if (currFullscreen && prevNonFullscreen) {
			newLayout.columns = prevCanvasLayout.canvasesPerRow
			newLayout.numCanvases = prevCanvasLayout.numCanvases
		}
		// Don't do anything if layout didn't change
		if (newLayout.columns === canvasesPerRow && newLayout.numCanvases === numCanvases) {
			return
		}
		// Order of operations matters if we're in fullscreen or not
		if (currFullscreen) {
			// Set new layout first so we can perform switch
			commit('SET_CANVAS_LAYOUT', newLayout)
			// Switch active canvas to the previous position
			commit('SWITCH_CANVASES', {
				fromIndex: 0,
				toIndex: state.prevActiveCanvasIndex,
			})
			// Set active canvas to the previous position
			commit('SET_ACTIVE_CANVAS_INDEX', state.prevActiveCanvasIndex)
		} else {
			// Move active canvas to first position
			commit('SWITCH_CANVASES', {
				fromIndex: state.activeCanvasIndex,
				toIndex: 0,
			})
			// Switch active canvas to the first position
			commit('SET_ACTIVE_CANVAS_INDEX', 0)
			// Set the fullscreen layout
			commit('SET_CANVAS_LAYOUT', newLayout)
		}
	},
	async setCanvasLayoutForModalityAndSpread({ commit, rootGetters }, { modality, numSeries }) {
		if (rootGetters.isAuthenticated) {
			const MAX_IMAGES_PER_MONITOR = 2
			try {
				const response = await api.user.getLayoutPreset(modality)
				if (response.status === 200) {
					const { seriesRows, seriesColumns } = response.data
					if (numSeries < seriesRows * seriesColumns && numSeries <= MAX_IMAGES_PER_MONITOR) {
						// show in vertical orientation if the window is taller than width
						let isVertical = window.innerWidth < window.innerHeight
						commit('SET_CANVAS_LAYOUT', {
							columns: isVertical ? 1 : numSeries,
							numCanvases: numSeries,
						})
					} else {
						commit('SET_CANVAS_LAYOUT', {
							columns: seriesColumns,
							numCanvases: seriesColumns * seriesRows,
						})
					}
					return
				}
				// Response could be null if never set, means default
				else if (response.status === 204) {
					if (numSeries <= MAX_IMAGES_PER_MONITOR) {
						// show in vertical orientation if the window is taller than width
						let isVertical = window.innerWidth < window.innerHeight
						commit('SET_CANVAS_LAYOUT', {
							columns: isVertical ? 1 : numSeries,
							numCanvases: numSeries,
						})
						return
					}
				}
			} catch (err) {
				// do nothing, error handling is already provided by API class
			}
		}
		// Unauthenticated users always start with 1x1
		commit('SET_CANVAS_LAYOUT', {
			columns: 1,
			numCanvases: 1,
		})
	},
  async fetchViewerSettings({commit}) {
    await api.user.getUserSetting('ViewerSettings', 'OmniReport').then(
      (success) => {
        if (success.data) {
          commit('SET_OVERLAY_TEXT', success.data.overlayTextEnabled)
          commit('SET_IMAGE_ORIENTATION_MARKERS', success.data.imageOrientationMarkersEnabled)
          commit('SET_LENGTH_ANGLES', success.data.lengthAnglesEnabled)
          commit('SET_REFERENCE_LINE_SYNCHRONIZATION', success.data.referenceLineSynchronizationEnabled)
        }
      }
    )
  },
  async fetchStudyListHeaderSettings({commit}) {
    await api.user.getUserSetting('ViewerSettings', 'StudyListHeader').then(
      (success) => {
        if (success.data) {
          commit('SET_STUDY_LIST_HEADER', success.data.tableHeaders)
        }
      }
    )
  },
  async fetchTeleconsultationListHeaderSettings({commit}) {
    await api.user.getUserSetting('ViewerSettings', 'TeleconsultationListHeader').then(
      (success) => {
        if (success.data) {
          commit('SET_TELECONSULTATION_LIST_HEADER', success.data.tableHeaders)
        }
      }
    )
  },
}

function createRemnantDisplaySets(displaySets, series) {
	const imageViewIds = getImageViewIds(displaySets)
	const remnantSeries = series.filter(series => {
		const seriesImageViewId = series.imageVewId || series.images[0].imageViewId
		return !imageViewIds.includes(seriesImageViewId)
	})
	const remnantImageViews = remnantSeries.map(series => ({
		id: series.imageViewId || series.seriesId,
	}))
	const imageViewsPerRow = 2
	const rowsPerDisplaySet = 2
	let rows = []
	while (remnantImageViews.length) {
		rows.push(remnantImageViews.splice(0, imageViewsPerRow))
	}
	let remnantDisplaySets = []
	let displaySetIndex = 1
	while (rows.length) {
		remnantDisplaySets.push({
			name: 'Remnant Images ' + displaySetIndex,
			rows: rows.splice(0, rowsPerDisplaySet),
		})
		displaySetIndex++
	}
	return remnantDisplaySets
}

function getImageViewIds(displaySets) {
	let imageViewIds = []
	displaySets.forEach(displaySet => {
		displaySet.rows.forEach(row => {
			row.forEach(imageView => {
				imageViewIds.push(imageView.id)
			})
		})
	})
	return imageViewIds
}

const getViewerViewModel = async (
	{ commit, dispatch, state },
	{ apiMethod, postData, initialSeriesId, initialImageId, initialSeriesIds = [], fillCanvases = true },
	source
) => {
	const request = apiMethod(postData)

	try {
		commit('SET_VIEWER_LOADING', true)
		const data = await request
		if (data === undefined) return
		if (data.deletedStudies && data.deletedStudies.length) return
		if (data.studiesNotFound && data.studiesNotFound.length) {
			commit('SET_STUDIES_NOT_FOUND', data.studiesNotFound)
			return
		}
		commit('SET_CONSULTATION_REQUEST', data)

		// TODO: THESE SHOULD COME DOWN IN A CONSISTENT FORMAT
		// WE SHOULDN'T NEED TO DO THIS KIND OF CHECKING IF RECEIVING A CONSISTENT VM STRUCTURE
		let studies = data
		if (studies && !studies.length) {
			// Repository
			if (studies.imageData) studies = [studies]
			// Consultation request
			else if (studies.studies) studies = studies.studies
		}
		commit('ADD_STUDIES', { studies, reportId: source === viewer.REPORT && postData })
		if (fillCanvases) {
			let series = [].concat(...state.studies.map(study => study.imageData.series))
			if (initialSeriesIds && initialSeriesIds.length) {
				series = series.filter(series => {
					return initialSeriesIds.includes(series.seriesId)
				})
			}
			commit('FILL_CANVASES', series)
		}
		if (state.hangingProtocol) dispatch('rebuildRemnantDisplaySets')
		if (initialSeriesId) {
			dispatch('switchSeries', { seriesId: initialSeriesId })
		}
		if (initialImageId) {
			dispatch('switchSeries', { imageId: initialImageId })
		}
		let showReports = true // Show reports by default
		let reportIds = [] // Empty array designates all reports
		switch (source) {
			case viewer.CONSIGNER:
				// Don't show any reports
				showReports = false
				break
			case viewer.CONSULTATION_REQUEST:
				// Only show reports selected in the consultation request
				reportIds = data.reports.map(r => r.reportId)
				showReports = reportIds.length
				break
			default:
				// Show all reports by default
				break
		}
		if (showReports) {
			state.studies.forEach(s => {
				studyData.getRelatedStudiesAndReports(s.studyId, postData.clinicCode, false).then(r => {
					if (reportIds.length) {
						s.imageData.reports = r.reports.filter(
							rp => reportIds.includes(rp.reportId) || reportIds.includes(rp.consultantReportId)
						)
					} else {
						s.imageData.reports = r.reports
					}
				})
			})
		}
	} catch (err) {
		commit('SET_VIEWER_HIDE_LOADING_TEXT')
	} finally {
		commit('SET_VIEWER_LOADING', false)
	}
}
