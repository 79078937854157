<template>
	<label
		class="checkbox-label"
		:class="{ 'label-left': labelPosition !== 'right' }"
		:disabled="disabled"
	>
		<input v-model="wrappedModel" type="checkbox" :disabled="disabled" :value="arrayValue" />
		<div class="checkbox-box"></div>
		<span><slot></slot></span>
	</label>
</template>
<script>
export default {
	props: {
		value: {
			type: [Array, Boolean],
			default: false,
		},
		arrayValue: {
			type: [String, Number],
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		labelPosition: {
			type: String,
			default: 'right',
		},
	},
	computed: {
		wrappedModel: {
			get() {
				return this.value
			},
			set(newval) {
				this.$emit('input', newval)
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.checkbox-label {
	display: flex !important;
	align-items: center;
	margin-bottom: 15px;
	min-height: 22px;
	user-select: none;
	position: relative;
	cursor: pointer;
	input[type='checkbox'] {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	.checkbox-box {
		margin-right: 8px;
		margin-left: 0;
		position: relative;
		height: 20px;
		width: 20px;
		background: var(--checkbox-unchecked-bg);
		border: 1px solid var(--checkbox-unchecked-border);
		&:after {
			content: '';
			position: absolute;
			display: none;
			left: 7px;
			top: 3px;
			width: 3px;
			height: 8px;
			border: solid white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}
	input[type='checkbox']:checked ~ .checkbox-box {
		background: var(--checkbox-checked-bg);
		border: 1px solid var(--checkbox-checked-border);
		&:after {
			display: block;
		}
	}
	input[type='checkbox']:disabled ~ .checkbox-box {
		background: var(--disabled-label);
		opacity: 0.4;
		&:after {
			border-color: var(--tertiary-label);
		}
	}

	&.label-left {
		flex-direction: row-reverse;
		justify-content: flex-end;
		.checkbox-box {
			margin-right: 0;
			margin-left: 8px;
		}
	}
}
</style>
