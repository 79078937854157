<template>
	<!--Translate angle on drag-->
	<drag-annotation
		:id="`${id}-angle`"
		v-model="position"
		:group-id="groupId"
		@drag-start="draggingAll = true"
		@drag-end="draggingAll = false"
	>
		<g v-if="start && middle" :style="groupStyle" data-position-ref>
			<!--Display line 1-->
			<line :x1="start.x" :y1="start.y" :x2="middle.x" :y2="middle.y" :style="lineStyleSM" />
			<!--Transparent hit target line 1-->
			<line
				:data-id="`${id}-angle`"
				:data-group-id="groupId"
				:x1="start.x"
				:y1="start.y"
				:x2="middle.x"
				:y2="middle.y"
				:style="strokeHitTargetStyle"
			/>
			<!--Display line 2-->
			<line v-if="end" :x1="middle.x" :y1="middle.y" :x2="end.x" :y2="end.y" :style="lineStyleME" />
			<!--Transparent hit target line 2-->
			<line
				v-if="end"
				:data-id="`${id}-angle`"
				:data-group-id="groupId"
				:x1="middle.x"
				:y1="middle.y"
				:x2="end.x"
				:y2="end.y"
				:style="strokeHitTargetStyle"
			/>
			<!--Arc-->
			<path v-if="arcRadius" :style="strokeStyle" :d="arcPath" />
			<!--Transparent hit target arc-->
			<path
				v-if="arcRadius"
				:data-id="`${id}-angle`"
				:data-group-id="groupId"
				:style="strokeHitTargetStyle"
				:d="arcPath"
			/>
			<!--Start handle-->
			<drag-annotation
				v-model="start"
				:group-id="groupId"
				:color="activeColor"
				show-precision
				@drag-start="drag.start = true"
				@drag-end="drag.start = false"
				@radius-updated="setHandleRadius('start', $event)"
			/>
			<!--Middle handle-->
			<drag-annotation
				v-model="middle"
				:group-id="groupId"
				:color="activeColor"
				show-precision
				drag-on-load
				@drag-start="drag.middle = true"
				@drag-end="onMiddleDragEnd"
				@radius-updated="setHandleRadius('middle', $event)"
			/>
			<!--End handle-->
			<drag-annotation
				v-if="end"
				ref="end"
				v-model="end"
				:group-id="groupId"
				:color="activeColor"
				:drag-on-load="!isMobileOS"
				show-precision
				@drag-start="drag.end = true"
				@drag-end="drag.end = false"
				@radius-updated="setHandleRadius('end', $event)"
			/>
			<!--Angle label-->
			<text-annotation
				v-if="end && statsVisible"
				:value="statText"
				:group-id="groupId"
				:event-source="textSource"
				:color="activeColor"
			/>
		</g>
	</drag-annotation>
</template>

<script>
import { startMiddleEndMixin } from './mixins'
import DragAnnotation from './DragAnnotation'
import TextAnnotation from './TextAnnotation'

export default {
	name: 'AngleAnnotation',
	components: {
		DragAnnotation,
		TextAnnotation,
	},
	mixins: [startMiddleEndMixin],
	computed: {
		arcRadius() {
			if (!this.start || !this.middle || !this.end) return 0
			const padding = 6
			const minRadius = 6
			const maxRadius = 18
			// Get the shortest distance (start -> middle, middle -> end)
			let distanceStartMiddle = this.displayDistanceBetween(this.start, this.middle)
			let distanceMiddleEnd = this.displayDistanceBetween(this.middle, this.end)
			let radius = Math.min(distanceStartMiddle, distanceMiddleEnd)
			// Add some padding
			radius -= padding
			// Check against the minimum radius
			if (radius < minRadius) {
				return 0
			}
			// Cap at a max radius
			radius = Math.min(radius, maxRadius)
			// Return the radius
			return radius
		},
		arcPath() {
			return `
				M${this.arcStart.x},${this.arcStart.y} A${this.arcRadius},${this.arcRadius} 0 ${this.angle < 180 ? '0,1' : '0,0'} ${
				this.arcEnd.x
			},${this.arcEnd.y}
				`
		},
		vectorMS() {
			if (!this.start || !this.middle) return null
			return { x: this.start.x - this.middle.x, y: this.start.y - this.middle.y }
		},
		vectorME() {
			if (!this.end || !this.middle) return null
			return { x: this.end.x - this.middle.x, y: this.end.y - this.middle.y }
		},
		arcStart() {
			if (this.arcRadius === 0) return null
			return this.getCollinearPoint(this.middle, this.start, this.arcRadius)
		},
		arcEnd() {
			if (this.arcRadius === 0) return null
			return this.getCollinearPoint(this.middle, this.end, this.arcRadius)
		},
		angle() {
			const v1 = this.vectorMS
			const v2 = this.vectorME
			if (!v1 || !v2) return NaN
			// Calculate angle between vectors
			const radians = Math.atan2(v2.y, v2.x) - Math.atan2(v1.y, v1.x)
			// Convert radians to degrees
			let degrees = (radians * 180) / Math.PI
			if (degrees < 0) {
				degrees = degrees + 360
			}
			// Return degrees
			return degrees
		},
		smallAngle() {
			return this.angle <= 180 ? this.angle : 360 - this.angle
		},
		statText() {
			return `${this.$options.filters.formatNumber(this.round(this.smallAngle, 2))}°`
		},
		lineStyleSM() {
			return {
				...this.strokeStyle,
				'stroke-dasharray': this.drag.start || this.drag.middle ? '5 3' : '',
			}
		},
		lineStyleME() {
			return {
				...this.strokeStyle,
				'stroke-dasharray': this.drag.middle || this.drag.end ? '5 3' : '',
			}
		},
	},
	methods: {
		getTextAnchorData() {
			return {
				points: [
					this.start,
					this.pointBetween(this.start, this.middle),
					this.middle,
					this.pointBetween(this.middle, this.end),
					this.end,
				],
				anchor: this.pointAligned(Object.values(this.handleOffsets), 'right'),
				alignVertical: 'middle',
			}
		},
		onMiddleDragEnd() {
			this.drag.middle = false
			if (!this.end) {
				const end = { ...this.middle }
				if (this.isMobileOS) {
					end.x += 50
					end.y += 50
				}
				this.end = end
			}
		},
	},
}
</script>
