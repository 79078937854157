import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { getCornerstoneImageIdParts } from '@/imageLoader'
import isSameOrientation from './../_shared/isSameOrientation.js'
import store from '@/store'

const scrollToIndex = cornerstoneTools.import('util/scrollToIndex')

export default function(synchronizer, sourceElement, targetElement, eventData = {}) {
	if (targetElement === sourceElement && !eventData.virtual) return

	const {
		isLocalizerImage: isSourceLocalizerImage,
		imageId: sourceImageId,
		...sourceImagePlane
	} = store.state.viewer.activeImageMetadata

	if (!sourceImagePlane || !sourceImagePlane.imagePositionPatient) return
	// do not trigger scroll sync from a localizer image [ch4638]
	if (isSourceLocalizerImage) {
		return
	}

	const stackToolState = cornerstoneTools.getToolState(targetElement, 'stack')
	if (!stackToolState) return
	const stackData = cornerstoneTools.getToolState(targetElement, 'stack').data[0]
	// Default shortest distance to the max possible value
	let shortestDistance = Number.MAX_VALUE
	const offsets = store.state.viewer.manualStackOffsets

	// Find the associated series IDs from the given imageIDs
	// This might seem excessively loop-ey, but is the only option and seems to run in sub-ms times.
	const imageMatch = matchImageID => image => image.imageId === matchImageID
	const hasImage = matchImageID => series => series.images.some(imageMatch(matchImageID))
	const { seriesId: sourceSeriesId } = store.getters.allSeries.find(hasImage(sourceImageId))
	// We can use the first image from the target stack to get the series, since that stack should always be the same series
	const { seriesId: targetSeriesId } = store.getters.allSeries.find(
		hasImage(getCornerstoneImageIdParts(stackData.imageIds[0]).imageId)
	)

	let newImageIdIndex

	for (let i = 0; i < stackData.imageIds.length; i++) {
		const cornerstoneImageId = stackData.imageIds[i]
		const { imageId } = getCornerstoneImageIdParts(cornerstoneImageId)
		if (imageId === sourceImageId) {
			newImageIdIndex = i
			break
		}
		const targetImagePlane = cornerstone.metaData.get('imagePlaneModule', imageId)
		if (!targetImagePlane || !targetImagePlane.imagePositionPatient) continue
		if (!isSameOrientation(targetImagePlane, sourceImagePlane)) continue

		// NOTE: unlike thick client, does not store or use FrameOfReferences to match offsets.
		// If there is no direct match but both images are in the same plane, then it will sync 1:1
		const offset = offsets.find(findOffsetByID(sourceSeriesId, targetSeriesId))

		const distance = getOffsetDistance(
			sourceImagePlane.imagePositionPatient,
			targetImagePlane.imagePositionPatient,
			offset || { x: 0, y: 0, z: 0 }
		)
		if (distance < shortestDistance) {
			shortestDistance = distance
			newImageIdIndex = i
		}
	}
	if (newImageIdIndex === undefined || newImageIdIndex === stackData.currentImageIdIndex) return

	scrollToIndex(targetElement, newImageIdIndex)
}

function getOffsetDistance(source, target, offset) {
	const dx = source.x - target.x - offset.x
	const dy = source.y - target.y - offset.y
	const dz = source.z - target.z - offset.z
	return dx * dx + dy * dy + dz * dz
}

// Function Generator to create a filter function for finding offsets by ID
const findOffsetByID = (sourceSeriesId, targetSeriesId) => offset =>
	offset.sourceSeriesId === sourceSeriesId && offset.targetSeriesId === targetSeriesId

/*
Offset data format
{
  sourceSeriesID,
  targetSeriesID,
  x,
  y,
  z,
}
*/
