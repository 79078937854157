import IdleTimer from '@services/idle'
import api from '@services/api'
import clone from '@utils/deepClone'

const emptyState = {
	search: {
		lotNumber: '',
		saleId: '',
		label: '',
	},
	sort: {
		order: {
			by: 'lotNumber',
			isAscending: true,
		},
		currentPage: 0,
		resultsPerPage: 50,
	},
	idleTimer: undefined,
	isPlayingVideo: false,
	translationCulture: 'default',
	idleTimeout: 0,
	requireClientName: false,
	clientName: null,
	viewingId: null,
	viewingInterval: null,
	sales: [],
  endoscopyDisclosure: null,
}

const state = clone(emptyState)

const mutations = {
	updateConsignerStudySort(state, changes) {
		if (!('currentPage' in changes)) changes.currentPage = 0
		state.sort = Object.assign(state.sort, changes)
	},
	updateConsignerStudySearch(state, changes) {
		state.search = Object.assign(state.search, changes)
	},
	updateRepositoryData(state, data) {
		state = Object.assign(state, data)
	},
	storeIdleTimer(state, idleTimer) {
		state.idleTimer = idleTimer
	},
	setIsPlayingVideo(state, isPlayingVideo) {
		state.isPlayingVideo = isPlayingVideo
	},
	setClientName(state, clientName) {
		state.clientName = clientName
	},
	setViewingId(state, viewingId) {
		state.viewingId = viewingId
	},
	storeViewingInterval(state, viewingInterval) {
		state.viewingInterval = viewingInterval
	},
	CLEAR_REPOSITORY_DATA(state) {
		state.sales = []
		state.sort = clone(emptyState.sort)
		state.search = clone(emptyState.search)
	},
  setEndoscopyDisclosure(state, endoscopyDisclosure) {
    state.endoscopyDisclosure = endoscopyDisclosure
  }
}

const actions = {
	async getRepositoryData({ commit, dispatch, rootState }, clinicCode) {
		const { data } = await api.repository.get(clinicCode || rootState.auth.claims.activeClinicCode)
		if (data) {
			commit('updateRepositoryData', data)
			dispatch('initializeSearch')
		}
	},
	initializeSearch({ commit, state }) {
		if (!state.sales.length) return
		// default to first sale if no sale is selected, or if selected saleId is invalid
		if (!state.search.saleId || !state.sales.find(x => x.id === state.search.saleId)) {
			commit('updateConsignerStudySearch', { saleId: state.sales[0].id })
		}
	},
	async startRepositoryViewing({ commit, dispatch, rootState }, { clientName, consignerStudyId }) {
		const response = await api.repository.startViewing({
			consignerStudyId,
			clientName,
			startTime: new Date().toLocaleString(),
		})
		const viewingId = response.data
		commit('setClientName', clientName)
		commit('setViewingId', viewingId)
		dispatch('startViewingPolling')
	},
	startViewingPolling({ commit, state }) {
		if (!state.viewingId) return
		if (state.viewingInterval) clearInterval(state.viewingInterval)
		const viewingInterval = setInterval(() => {
			api.repository.updateViewing(state.viewingId)
		}, 15000)
		commit('storeViewingInterval', viewingInterval)
	},
	endRepositoryViewing({ commit, state }) {
		if (!state.viewingId) return
		clearInterval(state.viewingInterval)
		commit('storeViewingInterval', null)
		api.repository.updateViewing(state.viewingId)
		commit('setClientName', null)
		commit('setViewingId', null)
	},
	startIdleTimer({ commit, dispatch, getters, state }) {
		if (!getters.isRepositoryUser) return
		if (!state.idleTimeout) return
		commit(
			'storeIdleTimer',
			new IdleTimer({
				idleTimeout: state.idleTimeout * 60 * 1000,
				onIdle() {
					dispatch('logOutOnIdle')
				},
			})
		)
		state.idleTimer.start()
	},
	stopIdleTimer({ state }) {
		if (state.idleTimer) state.idleTimer.stop()
	},
	logOutOnIdle({ dispatch, getters, state }) {
		if (!getters.isAuthenticated || state.isPlayingVideo) return
		const message = 'You have been logged out due to inactivity.  Please log in again.'
		dispatch('addNotification', { message, notificationType: 'error' })
		dispatch('logOut')
	},
}

export default {
	state,
	mutations,
	actions,
}
