<template>
	<div v-if="client" class="referred-by-form">
		<data-table :is-striped="false" :is-hoverable="false" :is-condensed="true">
			<tbody>
				<tr v-if="!cell.readOnly">
					<th style="border-top: none; vertical-align: middle;">
						Send on Behalf of
					</th>
					<td :style="{ borderTop: 'none', paddingBottom: isCommunityUser ? '0' : '8px' }">
						<v-select v-model="value" label="name" :options="users" :clear-search-on-select="true" :clearable="false" />
					</td>
				</tr>
				<tr v-if="isCommunityUser">
					<th style="border-top: none; padding-top: 3px"></th>
					<td style="border-top: none; text-align: right; padding-top: 3px">
						<small>
							<a @click="showOnBehalfOfSettings">
								Manage Contacts
							</a>
						</small>
					</td>
				</tr>
			</tbody>
			<tbody v-show="isReferredByLocked">
				<tr>
					<th>Name:</th>
					<td>{{ client.name }}</td>
				</tr>
				<tr>
					<th>Clinic:</th>
					<td>{{ client.organization }}</td>
				</tr>
				<tr>
					<th>Address:</th>
					<td>
						<div style="white-space: pre;">{{ client.address }}</div>
						{{ (client.city || '') + (client.city && client.state ? ',' : '') }}
						{{ client.state }} {{ client.postalCode }}
					</td>
				</tr>
				<tr>
					<th>Phone:</th>
					<td>{{ client.phone }}</td>
				</tr>
				<tr>
					<th>Email:</th>
					<td>{{ client.email }}</td>
				</tr>
			</tbody>
			<tbody v-show="!isReferredByLocked" class="unlocked">
				<tr>
					<th>
						Name:
						<small class="is-danger">*</small>
					</th>
					<td>
						<input v-model.trim="client.name" v-validate="'required'" type="text" name="client.name" class="input" />
						<small v-if="hasError('client.name')" class="error is-danger">
							Name is required.
						</small>
					</td>
				</tr>
				<tr>
					<th>
						Clinic:
						<small class="is-danger">*</small>
					</th>
					<td>
						<input v-model.trim="client.organization" v-validate="'required'" type="text" name="clinic" class="input" />
						<small v-if="hasError('client.organization')" class="error is-danger">
							Clinic is required.
						</small>
					</td>
				</tr>
				<tr>
					<th>Address:</th>
					<td>
						<textarea v-model.trim="client.address" class="input is-address"></textarea>
					</td>
				</tr>
				<tr>
					<th>City:</th>
					<td>
						<input v-model.trim="client.city" type="text" class="input" />
					</td>
				</tr>
				<tr>
					<th>State:</th>
					<td>
						<input v-model.trim="client.state" type="text" class="input" />
					</td>
				</tr>
				<tr>
					<th>Zip/Postal Code:</th>
					<td>
						<input v-model.trim="client.postalCode" type="text" class="input" />
					</td>
				</tr>
				<tr>
					<th>Phone:</th>
					<td>
						<input v-model.trim="client.phone" type="tel" class="input" />
					</td>
				</tr>
				<tr>
					<th>Email:</th>
					<td>
						<input v-model.trim="client.email" v-validate="'email'" name="client.email" type="email" class="input" />
						<small v-if="hasError('client.email')" class="error is-danger">
							This email address is invalid.
						</small>
					</td>
				</tr>
			</tbody>
		</data-table>
		<div v-if="!cell.readOnly && isReferredByLocked" style="padding: 8px;">
			<button class="btn btn-default" @click="isReferredByLocked = false">
				<svg-icon icon="edit" fixed />
				Edit
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '@components/DataTable'
import { openOnBehalfOfDlg } from '@dialogs/OnBehalfOf.vue'
import sortBy from 'lodash/sortBy'
import { ValidatorMixin } from '@mixins/validator'

export default {
	name: 'ReferredByForm',
	components: {
		DataTable,
	},
	mixins: [ValidatorMixin],
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isReferredByLocked: true,
		}
	},
	computed: {
		...mapGetters(['isCommunityUser']),
		client() {
			if (this.cell.template.editorMode) return {}
			return this.cell.report.client
		},
		users() {
			return sortBy(
				this.$store.getters.onBehalfOfList.map(user => ({
					...user,
					organization: user.organizationName,
					name: `${user.firstName} ${user.lastName}`.trim(),
				})),
				user => user.name
			)
		},
		value: {
			get() {
				if (!this.cell.report) return
				return this.cell.report.client
			},
			set(value) {
				this.cell.report.client = value
			},
		},
	},
	watch: {
		client: {
			handler() {
				this.cell.template.changeValue()
			},
			deep: true,
		},
		// Validate even when on-behalf-of select is used
		value: {
			handler() {
				this.validator.validate()
			},
			immediate: true,
		},
		anyErrors() {
			if (this.cell.editorMode) return
			if (this.anyErrors) this.isReferredByLocked = false
			this.cell.report.validate()
		},
	},
	methods: {
		showOnBehalfOfSettings() {
			openOnBehalfOfDlg()
		},
	},
}
</script>

<style lang="scss">
.referred-by-form {
	.data-table {
		th,
		td {
			font-size: 1em;
			border-top: 0;
		}
		th {
			width: 0.1%;
			white-space: nowrap;
		}

		.unlocked th {
			padding-top: 16px;
		}
	}
	padding: 8px;
	.table-container {
		overflow: visible;
	}
	small.error {
		display: block;
	}
}
</style>
