<template>
	<div class="attachment-list">
		<h4 v-if="attachments.length">Attachments</h4>
		<ul>
			<transition-group name="fade">
				<li
					v-for="file in attachments"
					:key="file.imageId"
					:class="{ excluded: seriesToExclude.includes(file.seriesId) }"
				>
					<svg-icon :icon="findFileIconName(file.fileExtension)" inline />
					<a @click="openAttachment(file)">
						{{ file.description ? file.description : file.friendlyName }}
					</a>
				</li>
			</transition-group>
		</ul>
	</div>
</template>

<script>
import { FileUtils } from '@utils/fileUtils'
import { openAttachmentDlg } from '@dialogs/attachmentDlg.vue'
import { openRequiredFieldsDlg } from '@dialogs/RequiredFieldsDlg.vue'
import api from "@services/api";

export default {
	name: 'AttachmentList',
	props: {
		attachments: {
			type: Array,
			required: true,
		},
		openOnClick: {
			type: Boolean,
			default: true,
		},
		seriesToExclude: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		findFileIconName: FileUtils.findFileIconName,
		async openAttachment(file) {
			this.$emit('select', file.seriesId)
      const clinicCode = this.$store.state.auth.claims.activeClinicCode
			if (this.openOnClick) {
				const isRepository = this.$route.name === 'consigner-studies-viewer'
        let clinicInfo = {}
        if (isRepository) {
          clinicInfo = await api.repository.get(clinicCode)
        }
        if (file.description === 'Endoscopic Video' && isRepository && clinicInfo?.data?.requireEndoscopicDisclosure)
          openRequiredFieldsDlg( file, isRepository )
        else
          openAttachmentDlg(file, isRepository, !isRepository)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
ul {
	list-style-type: none;
}

li {
	word-break: break-word;
	padding-top: 8px;

	&.excluded {
		opacity: 0.5;
		text-decoration: line-through;
	}

	.icon {
		padding-right: 5px;
	}
}
</style>
