import * as Sentry from '@sentry/browser'
import store from '@store'
import listToArray from '@utils/listToArray'

let redirectToAfterLogin // the route the user tried to reach before logging in

const redirectToLogin = next => {
	store.dispatch('addNotification', { message: 'Please log in.' })
	next('/')
}
const ifAuthenticated = (to, from, next) => {
	if (store.getters.isAuthenticated) {
		window.localStorage.removeItem('inactivityTimeout')
		//initializeInactivityTimeout()
		return next()
	}
	if (to.meta.canRedirectToAfterLogin) redirectToAfterLogin = to
	redirectToLogin(next)
}
const ifAuthenticatedAsRepository = (to, from, next) => {
	if (!store.getters.isAuthenticated) return redirectToLogin(next)
	if (!store.getters.isRepositoryUser) {
		store.dispatch('addNotification', {
			message: 'You must be logged in with your Repository credentials.',
			notificationType: 'error',
		})
		return next(from.name ? false : '/') // Go to login if navigation did not come from another route in the app.
	}
	//initializeInactivityTimeout()
	next()
}

const ifAuthenticatedWithSaleSubmissionPermissions = (to, from, next) => {
	const guidRegex = new RegExp('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$')
	if (!store.getters.isAuthenticated) return redirectToLogin(next)
	if (!store.getters.isSaleSubmissionUser) {
		store.dispatch('addNotification', {
			message: 'Your Account does not have Repository Submission credentials.',
			notificationType: 'error',
		})
		return next(from.name ? false : '/') // Go to login if navigation did not come from another route in the app.
	} else {
		if (to.params.id && !guidRegex.test(to.params.id)) {
			store.dispatch('addNotification', {
				message: 'Your Review ID is not valid!',
				notificationType: 'error',
			})
			return next(from.name ? false : '/')
		}
	}
	//initializeInactivityTimeout()
	next()
}

function addConsultantIfLoggedIn(to, from, next) {
	if (!store.getters.isAuthenticated) return next()
	next(from && from.name ? false : '/') // if app already open, keep current route; otherwise, open default route
	store.dispatch('addConsultant', {
		consultantId: to.params.consultantId,
	})
}

const getStudyIds = route => listToArray(route.query.studyId)

export const statisticsUserRoutes = {
	repository: ['statistics-summaries', 'statistics-viewing-log', 'statistics-submissions'],
	consigner: ['statistics-summaries', 'statistics-viewing-log', 'statistics-consigner-clients'],
	user: ['statistics-viewing-log'],
	client: ['statistics-summaries', 'statistics-viewing-log'],
}
const ifValidStatisticsUser = (to, from, next) => {
	// @HACK: Force ARQANA to correct DNS
	const isArqana = to.params.clinicCode && to.params.clinicCode.toUpperCase() === 'ARQANA'
	const isCosAnnex =
		(window.location.hostname &&
			window.location.hostname.toUpperCase().includes('keystone.asteris.com'.toUpperCase())) ||
		window.location.hostname.toUpperCase().includes('keystone-beta.asteris.com'.toUpperCase())

	if (isCosAnnex && isArqana) {
		window.location = `https://keystone-eu.asteris.com/#${to.fullPath}`
	}

	const hasMatchingUserType = to.params.userType in statisticsUserRoutes
	if (!hasMatchingUserType) return next('/')
	const hasValidRouteForUserType = statisticsUserRoutes[to.params.userType].includes(to.name)
	if (hasValidRouteForUserType) return next()
	// If no valid route is specified, go to the default route for the user type
	const defaultRoute = statisticsUserRoutes[to.params.userType][0]
	next({ name: defaultRoute, params: to.params, query: to.query })
}

const restartRequestOnReload = (to, from, next) => {
	// if user reloads/lands at this step in teleconsultation request, make them start workflow over
	if (!from.name) return next('/teleconsultation-request')
	next()
}

// https://router.vuejs.org/en/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/
// https://vuejsdevelopers.com/2017/07/08/vue-js-3-ways-code-splitting-webpack/
// Consigner Study
const ConsignerStudyList = () =>
	import(/* webpackChunkName: "consignerStudyList" */ '@router/views/ConsignerStudyList.vue')
const ConsignerStudyViewer = () =>
	import(/* webpackChunkName: "consignerStudyViewer" */ '@router/views/ConsignerStudyViewer')
// Consultation Request
const ConsultationRequestViewer = () =>
	import(/* webpackChunkName: "consultationRequestViewer" */ '@router/views/ConsultationRequestViewer')
const ListLayout = () => import(/* webpackChunkName: "listLayout" */ '@router/layouts/List')
// Login
const Login = () => import(/* webpackChunkName: "login" */ '@router/views/Login.vue')
// Statistics
const Statistics = () => import(/* webpackChunkName: "statistics" */ '@router/views/Statistics.vue')
const StatisticsConsignerClients = () =>
	import(/* webpackChunkName: "statisticsConsignerClients" */ '@router/views/StatisticsConsignerClients.vue')
const StatisticsSubmissions = () =>
	import(/* webpackChunkName: "statisticsSubmissions" */ '@router/views/StatisticsSubmissions.vue')
const StatisticsSummaries = () =>
	import(/* webpackChunkName: "statisticsSummaries" */ '@router/views/StatisticsSummaries.vue')
const StatisticsViewingLog = () =>
	import(/* webpackChunkName: "statisticsViewingLog" */ '@router/views/StatisticsViewingLog.vue')
// Study
const StudyList = () => import(/* webpackChunkName: "studyList" */ '@router/views/StudyList.vue')
const SignalPET = () => import(/* webpackChunkName: "studyList" */ '@router/views/SignalPET.vue')
const SwitchingClinics = () => import(/* webpackChunkName: "studyList" */ '@router/views/SwitchingClinics.vue')
const Schedule = () => import(/* webpackChunkName: "studyList" */ '@router/views/Schedule.vue')
const ReleaseNotesFull = () => import(/* webpackChunkName: "studyList" */ '@router/views/ReleaseNotesFull.vue')
const StdReport = () => import(/* webpackChunkName: "teleconsultationDetail" */ '@router/views/StdReport.vue')
// Teleconsultation
const TeleconsultationDetail = () =>
	import(/* webpackChunkName: "teleconsultationDetail" */ '@router/views/TeleconsultationDetail.vue')
const SaleSubmission = () => import(/* webpackChunkName: "SaleSubmission" */ '@router/views/SaleSubmission.vue')
const ImageOnlyReport = () => import(/* webpackChunkName: "SaleSubmission" */ '@router/views/ImageOnlyReport.vue')
const TeleconsultationList = () =>
	import(/* webpackChunkName: "teleconsultationList" */ '@router/views/TeleconsultationList.vue')
const TeleconsultationRequest = () =>
	import(/* webpackChunkName: "teleconsultationsRequest" */ '@router/views/TeleconsultationRequest.vue')
const TeleconsultationRequestConsultants = () =>
	import(/* webpackChunkName: "teleconsultationsRequestConsultants" */ '@router/views/TeleconsultationRequest/Consultants.vue')
const TeleconsultationRequestSaleHip = () =>
	import(/* webpackChunkName: "teleconsultationsRequestSaleHip" */ '@router/views/TeleconsultationRequest/SaleHip.vue')
const TeleconsultationRequestUploadConsultation = () =>
	import(/* webpackChunkName: "teleconsultationsRequestUploadConsultation" */ '@router/views/TeleconsultationRequest/UploadConsultation.vue')
const TeleconsultationImageUploads = () =>
	import(/* webpackChunkName: "teleconsultationsRequestUploadConsultation" */ '@router/views/ImageUploads.vue')
const TeleconsultationRequestUploadSale = () =>
	import(/* webpackChunkName: "teleconsultationsRequestUploadSale" */ '@router/views/TeleconsultationRequest/UploadSale.vue')
const TeleconsultationRequestMatchImages = () =>
	import(/* webpackChunkName: "teleconsultationsRequestMatchImages" */ '@router/views/TeleconsultationRequest/MatchImages.vue')
const TeleconsultationRequestSubmitImageOnly = () =>
	import(/* webpackChunkName: "teleconsultationsRequestSubmitImageOnly" */ '@router/views/TeleconsultationRequest/SubmitImageOnly.vue')
const TeleconsultationRequestSubmitReport = () =>
	import(/* webpackChunkName: "teleconsultationsRequestSubmitReport" */ '@router/views/TeleconsultationRequest/SubmitReport.vue')
const TeleconsultationRequestSubmitSale = () =>
	import(/* webpackChunkName: "teleconsultationsRequestSubmitSale" */ '@router/views/TeleconsultationRequest/SubmitSale.vue')
const PostDicomServiceInstall = () =>
	import(/* webpackChunkName: "PostDicomServiceInstall" */ '@router/views/PostDicomServiceInstall.vue')
const ReportEditor = () => import(/* webpackChunkName: "ReportEditor" */ '@/reporting/ReportEditor.vue')
const Viewer = () => import(/* webpackChunkName: "Viewer" */ '@router/views/StudyAndReportViewer.vue')
const ReportDeepSearch = () => import(/* webpackChunkName: "ReportDeepSearch" */ '@router/views/ReportDeepSearch.vue')
// Repository Review
const RepositoryReviewList = () =>
	import(/* webpackChunkName: "repositoryReviewList" */ '@router/views/RepositoryReview/ReviewList.vue')
const RepositoryReviewDetails = () =>
	import(/* webpackChunkName: "repositoryReviewDetails" */ '@router/views/RepositoryReview/ReviewDetails.vue')
const RepositoryReviewViewer = () =>
	import(/* webpackChunkName: "repositoryReviewViewer" */ '@router/views/RepositoryReview/ReviewViewer.vue')
const RepositoryReviewDashboard = () =>
	import(/* webpackChunkName: "repositoryReviewDashboard" */ '@router/views/RepositoryReview/ReviewDashboard.vue')

export default [
	{
		path: '/',
		redirect() {
			if (!store.getters.isAuthenticated) return '/login'
			if (redirectToAfterLogin) {
				const route = redirectToAfterLogin
				redirectToAfterLogin = null
				return route
			}
			if (store.getters.isCommunityUser) {
				return '/teleconsultation-request'
			}
			if (store.getters.isRepositoryUser) return '/consigner-studies'
			if (store.getters.isConsultantUser) return '/teleconsultations'
			return '/studies'
		},
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			title: 'Asteris',
			themeSettings: { mode: 'light' },
		},
	},
	{
		path: '/login/forgot-password',
		name: 'forgot-password',
		component: Login,
		props: { isForgotPasswordFormVisible: true },
		meta: {
			title: 'Asteris',
			themeSettings: { mode: 'light' },
		},
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: Login,
		props: route => ({
			isResetPasswordFormVisible: true,
			username: route.query.user,
			token: route.query.token,
			enforcePasswordComplexity: !!route.query.epc,
		}),
		meta: {
			title: 'Asteris',
			themeSettings: { mode: 'light' },
		},
	},
	{
		path: '/community',
		name: 'community-login',
		component: Login,
		meta: {
			title: 'Asteris',
			themeSettings: { mode: 'light' },
		},
	},
	{
		path: '/community/forgot-password',
		name: 'community-forgot-password',
		component: Login,
		props: { isForgotPasswordFormVisible: true },
		meta: {
			title: 'Asteris',
			themeSettings: { mode: 'light' },
		},
	},
	{
		path: '/community/:consultantId/register/:themeCompany?',
		name: 'community-register',
		component: Login,
		props: route => ({
			consultantId: route.params.consultantId,
			isRegistrationFormVisible: true,
			themeCompany: route.params.themeCompany,
		}),
		meta: {
			title: 'Asteris',
			themeSettings: { mode: 'light' },
		},
		beforeEnter: addConsultantIfLoggedIn,
	},
	{
		path: '/community/:consultantId/forgot-password/:themeCompany?',
		name: 'community-forgot-password',
		component: Login,
		props: route => ({
			isForgotPasswordFormVisible: true,
			themeCompany: route.params.themeCompany,
		}),
		meta: {
			title: 'Asteris',
			themeSettings: { mode: 'light' },
		},
	},
	{
		path: '/community/:consultantId/:themeCompany?',
		name: 'community-referral-login',
		component: Login,
		props: true,
		meta: {
			title: 'Asteris',
			themeSettings: { mode: 'light' },
		},
		beforeEnter: addConsultantIfLoggedIn,
	},
	{
		path: '',
		component: ListLayout,
		children: [
			// Repository Review / Hagyard
			{
				path: '/repository-review',
				name: 'repository-review',
				component: RepositoryReviewList,
				meta: { title: 'Repository Submission Review' },
				beforeEnter: ifAuthenticatedWithSaleSubmissionPermissions,
			},
			{
				path: '/repository-review/dashboard',
				name: 'repository-submission-dashboard',
				component: RepositoryReviewDashboard,
				meta: { title: 'Repository Submission Dashboard' },
				beforeEnter: ifAuthenticatedWithSaleSubmissionPermissions,
			},
			{
				path: '/repository-review/:id',
				name: 'repository-review-details',
				component: RepositoryReviewDetails,
				meta: {
					title: 'Repository Submission Details',
					showBack: true,
					hideMenu: true,
				},
				beforeEnter: ifAuthenticatedWithSaleSubmissionPermissions,
			},
			// Consigner Study List
			{
				path: '/consigner-studies',
				name: 'consigner-studies',
				component: ConsignerStudyList,
				meta: {
					title: 'Repository Studies',
				},
				beforeEnter: ifAuthenticatedAsRepository,
			},
			// Image Only Report
			{
				path: '/image-only-report/:id',
				name: 'image-only-report',
				component: ImageOnlyReport,
				props: route => ({ id: route.params.id, viewOnOpen: route.params.viewOnOpen }),
				meta: {
					title: 'Image Submission',
				},
				beforeEnter: ifAuthenticated,
			},
			// Report Deep Search ("Teleconsultation" Reports)
			{
				path: '/teleconsultations/deep-search',
				name: 'teleconsultations-deep-search',
				component: ReportDeepSearch,
				meta: {
					title: 'Teleconsultation',
				},
				beforeEnter: ifAuthenticated,
			},
			// Report Deep Search ("Standard" Reports)
			{
				path: '/reports/deep-search',
				name: 'reports-deep-search',
				component: ReportDeepSearch,
				props: { isTeleconsultation: false },
				meta: {
					title: 'Reports',
				},
				beforeEnter: ifAuthenticated,
			},
			// Sale Submission
			{
				path: '/sale-submissions/:id',
				name: 'sale-submission',
				component: SaleSubmission,
				props: route => ({ id: route.params.id, viewOnOpen: route.params.viewOnOpen }),
				meta: {
					disableSwitchClinics: true,
					title: 'Sale Submission',
					showBack: true,
				},
				beforeEnter: ifAuthenticated,
			},
			// Schedule
			{
				path: '/schedule',
				name: 'schedule',
				component: Schedule,
				beforeEnter: ifAuthenticated,
				meta: {
					title: 'Schedule',
				},
			},
			// Study List
			{
				path: '/studies',
				name: 'studies',
				components: {
					default: StudyList,
				},
				meta: {
					title: 'Studies',
				},
				beforeEnter: ifAuthenticated,
			},
			// Standard Report
			{
				path: '/report/:id',
				name: 'standard-report',
				component: StdReport,
				props: route => ({
					id: route.params.id,
					keywords: route.params.keywords,
					viewOnOpen: route.params.viewOnOpen,
				}),
				meta: {
					disableSwitchClinics: true,
					showBack: true,
					title: 'Report',
				},
				beforeEnter: ifAuthenticated,
			},
			// Teleconsultation List
			{
				path: '/teleconsultations',
				name: 'teleconsultations',
				component: TeleconsultationList,
				meta: {
					title: 'Teleconsultation',
				},
				beforeEnter: ifAuthenticated,
			},
			// Teleconsultation Detail
			{
				path: '/teleconsultations/:id',
				name: 'teleconsultation',
				component: TeleconsultationDetail,
				props: route => ({
					id: route.params.id,
					keywords: route.params.keywords,
					viewOnOpen: route.params.viewOnOpen,
				}),
				meta: {
					disableSwitchClinics: true,
					title: 'Teleconsultation Report',
					showBack: true,
				},
				beforeEnter: ifAuthenticated,
			},
			// Teleconsultation Image Uploads
			{
				path: '/teleconsultation-image-uploads',
				name: 'teleconsultation-image-uploads',
				component: TeleconsultationImageUploads,
				beforeEnter: ifAuthenticated,
				meta: {
					title: 'Image Uploads',
				},
			},
			// Teleconsultation Request
			{
				path: '/teleconsultation-request',
				component: TeleconsultationRequest,
				beforeEnter: ifAuthenticated,
				children: [
					{
						path: '',
						name: 'request-consultants',
						component: TeleconsultationRequestConsultants,
						props: route => ({
							ids: getStudyIds(route),
						}),
						meta: {
							disableSwitchClinics: true,
							title: 'Request Teleconsultation',
							showBack: !store.getters.isCommunityUser,
						},
					},
					{
						path: 'sale-hip',
						name: 'request-sale-hip',
						component: TeleconsultationRequestSaleHip,
						beforeEnter: restartRequestOnReload,
						props: route => ({
							consultantId: route.query.consultantId,
							ids: getStudyIds(route),
						}),
						meta: {
							disableSwitchClinics: true,
							title: 'Request Teleconsultation',
						},
					},
					{
						path: 'upload-consultation',
						name: 'request-upload-consultation',
						component: TeleconsultationRequestUploadConsultation,
						props: route => ({
							consultantId: route.query.consultantId,
							ids: getStudyIds(route),
						}),
						meta: {
							disableSwitchClinics: true,
							showServicePromo: true,
							title: 'Request Teleconsultation',
						},
					},
					{
						path: 'upload-sale',
						name: 'request-upload-sale',
						component: TeleconsultationRequestUploadSale,
						beforeEnter: restartRequestOnReload,
						props: route => ({
							consultantId: route.query.consultantId,
							ids: getStudyIds(route),
						}),
						meta: {
							disableSwitchClinics: true,
							showServicePromo: true,
							title: 'Request Teleconsultation',
						},
					},
					{
						path: 'match-images',
						name: 'request-match-images',
						component: TeleconsultationRequestMatchImages,
						beforeEnter: restartRequestOnReload,
						props: route => ({
							consultantId: route.query.consultantId,
							ids: getStudyIds(route),
						}),
						meta: {
							disableSwitchClinics: true,
							title: 'Request Teleconsultation',
						},
					},
					{
						path: 'submit-image-only',
						name: 'request-submit-image-only',
						component: TeleconsultationRequestSubmitImageOnly,
						props: route => ({
							consultantId: route.query.consultantId,
							ids: getStudyIds(route),
						}),
						meta: {
							disableSwitchClinics: true,
							title: 'Request Teleconsultation',
						},
					},
					{
						path: 'submit-report',
						name: 'request-submit-report',
						component: TeleconsultationRequestSubmitReport,
						props: route => ({
							consultantId: route.query.consultantId,
							ids: getStudyIds(route),
						}),
						meta: {
							disableSwitchClinics: true,
							title: 'Request Teleconsultation',
						},
					},
					{
						path: 'submit-sale',
						name: 'request-submit-sale',
						component: TeleconsultationRequestSubmitSale,
						beforeEnter: restartRequestOnReload,
						props: route => ({
							consultantId: route.query.consultantId,
							ids: getStudyIds(route),
						}),
						meta: {
							disableSwitchClinics: true,
							title: 'Request Teleconsultation',
						},
					},
				],
			},
		],
	},
	{
		path: '/signalpet/:clinicCode/:studyId',
		name: 'signalpet',
		component: SignalPET,
		meta: {
			title: 'SignalPET',
		},
	},
	{
		path: '/switching-clinics',
		name: 'switching-clinics',
		component: SwitchingClinics,
	},
	{
		path: '/release-notes',
		name: 'release-notes',
		component: ReleaseNotesFull,
		meta: {
			title: 'Release Notes',
		},
	},
	{
		path: '/viewer',
		name: 'viewer',
		component: Viewer,
		props: route => ({
			clinicCode: route.query.clinicCode,
			studyIds: listToArray(route.query.studyIds),
			reportIds: listToArray(route.query.reportIds),
			initialSeriesId: route.query.sid,
			initialImageId: route.query.imageId,
			initialSeriesIds: listToArray(route.query.sids, null),
		}),
		meta: {
			title: 'Viewer',
			allowHangingProtocol: true,
			canRedirectToAfterLogin: true,
		},
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/viewer/studies/:id?',
		name: 'studies-viewer',
		redirect: to => {
			const { params, query } = to
			query.studyIds = params.id
			return { name: 'viewer', query, replace: true }
		},
	},
	{
		path: '/viewer/:clinicCode/report/:id',
		name: 'report-viewer',
		redirect: to => {
			const { params, query } = to
			query.clinicCode = query.clinicCode || params.clinicCode
			query.reportIds = params.id
			return { name: 'viewer', query, replace: true }
		},
	},
	{
		path: '/partner/viewer/:clinicCode/:id',
		name: 'partner-study-viewer',
		component: Viewer,
		props: route => ({
			// combine studyIds from route and query params (adding a related study results in query param)
			studyIds: [...new Set(listToArray([route.params.id, route.query.studyIds].join(',')))],
			clinicCode: route.params.clinicCode,
			initialSeriesId: route.query.sid,
			initialImageId: route.query.imageId,
		}),
		meta: {
			title: 'Study',
		},
		beforeEnter(to, from, next) {
			// if authenticated, redirect to full viewer (unless user opted for partner viewer)
			const optedForPartnerViewer = to.query && to.query.optedForPartnerViewer
			if (store.getters.isAuthenticated && !optedForPartnerViewer) {
				next({
					name: 'studies-viewer',
					params: to.params,
					query: { clinicCode: to.params.clinicCode },
				})
			} else {
				next()
			}
		},
	},
	// ezyVet Legacy Route (redirects to other partner study viewer route)
	{
		path: '/partner/viewer/:clinicCode',
		redirect: to => {
			const { params, query } = to
			to.params.id = to.query.studyId
			delete to.query.studyId
			return { name: 'partner-study-viewer', params, query, replace: true }
		},
	},
	{
		path: '/viewer/consigner-studies/:id',
		name: 'consigner-studies-viewer',
		component: ConsignerStudyViewer,
		props: route => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Repository Study',
		},
		beforeEnter(to, from, next) {
			if (!store.state.repository.viewingId) return next('/consigner-studies')
			ifAuthenticatedAsRepository(to, from, next)
		},
	},
	{
		path: '/share/:clinicCode/share-request/:shareRequestId/share-recipient/:shareRecipientId',
		name: 'email-share-consultant',
		component: ConsultationRequestViewer,
		props: route => ({
			id: route.params.shareRequestId,
			clinicCode: route.params.clinicCode,
			recipientId: route.params.shareRecipientId,
		}),
		meta: {
			title: 'Your Medical Images',
		},
	},
	{
		path: '/dicomservice/:token',
		name: 'dicom-service',
		component: PostDicomServiceInstall,
	},
	{
		path: '/statistics/:clinicCode/:userType/:id/',
		component: Statistics,
		beforeEnter: ifValidStatisticsUser,
		props: true,
		children: [
			{
				path: 'summaries',
				name: 'statistics-summaries',
				component: StatisticsSummaries,
				meta: {
					title: 'Summary - Repository Statistics',
					themeSettings: { mode: 'light' },
				},
				props: true,
			},
			{
				path: 'viewing-log',
				name: 'statistics-viewing-log',
				component: StatisticsViewingLog,
				meta: {
					title: 'Full Viewing Log - Repository Statistics',
					themeSettings: { mode: 'light' },
				},
				props: true,
			},
			{
				path: 'submissions',
				name: 'statistics-submissions',
				component: StatisticsSubmissions,
				meta: {
					title: 'Submission Report - Repository Statistics',
					themeSettings: { mode: 'light' },
				},
				props: true,
			},
			{
				path: 'consigner-clients',
				name: 'statistics-consigner-clients',
				component: StatisticsConsignerClients,
				meta: {
					title: 'Clients - Repository Statistics',
					themeSettings: { mode: 'light' },
				},
				props: true,
			},
			{
				path: 'consigner-clients/manage',
				name: 'statistics-consigner-clients-manage',
				component: StatisticsConsignerClients,
				meta: {
					title: 'Clients - Repository Statistics',
					themeSettings: { mode: 'light' },
				},
				props: route => ({
					...route.params,
					showClientManager: true,
				}),
			},
		],
	},
	{
		path: '/reporting/:setId',
		name: 'report-editor',
		component: ReportEditor,
		props: route => ({
			setId: route.params.setId,
		}),
		beforeEnter: ifAuthenticated,
	},
	// Hagyard Detail pages
	{
		path: '/viewer/review-studies/:id',
		name: 'repository-studies-viewer',
		component: RepositoryReviewViewer,
		props: route => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Repository Study',
		},
	},
	{
		path: '*',
		beforeEnter(to, from, next) {
			const sentryEventId = Sentry.captureException(new Error('Path was not found: ' + to.path))
			store.dispatch('addNotification', {
				message: `
				<p>The requested location was not found:</p>
				<samp>${to.path}</samp>`,
				notificationType: 'error',
				sentryEventId,
			})
			// if coming from an Omni route, cancel navigation.  Otherwise, redirect to home
			next(from && from.name ? false : '/')
		},
	},
	// optionally include icon tester page
	...(process.env.NODE_ENV !== 'production'
		? [
				{
					path: '/icons',
					component: () => import(/* webpackChunkName: "IconTester" */ '@/router/views/IconTester'),
				},
		  ]
		: []),
]
