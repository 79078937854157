import api from '@services/api'
import AutoCompleteVm from '@store/models/state/autoCompleteVm'
import OnBehalfOfVm from '@/store/models/state/onBehalfOfVm'

const state = {
	autoComplete: new AutoCompleteVm(),
	onBehalfOf: new OnBehalfOfVm(),
}

const getters = {
	onBehalfOfList: state => state.onBehalfOf.list,
	onBehalfOfColumns: state => state.onBehalfOf.columns,
	onBehalfOfDetail: state => state.onBehalfOf.detail,
	onBehalfOfIsEditing: state => !!state.onBehalfOf.detail,
	onBehalfOfIsLoading: state => state.onBehalfOf.isListLoading || state.onBehalfOf.isDetailLoading,
}

const mutations = {
	SET_ON_BEHALF_OF_LOADING(state, loading) {
		state.isListLoading = loading
	},
	SET_ON_BEHALF_OF_LIST(state, list) {
		state.onBehalfOf.list = list
	},
	EDIT_ON_BEHALF_OF(state, item) {
		state.onBehalfOf.detail = { ...item }
	},
	SET_ON_BEHALF_OF(state, item) {
		const list = state.onBehalfOf.list
		const index = list.findIndex(i => i.id === item.id)
		if (index !== -1) {
			list.splice(index, 1, item)
		}
	},
	CLEAR_ON_BEHALF_OF(state) {
		state.onBehalfOf.detail = null
	},
	ADD_ON_BEHALF_OF(state, item) {
		state.onBehalfOf.list.push(item)
	},
	DELETE_ON_BEHALF_OF(state, item) {
		state.onBehalfOf.list = state.onBehalfOf.list.filter(i => i.id !== item.id)
	},
	SET_AUTOCOMPLETE_LIST(state, autoCompleteList) {
		state.autoComplete.list = autoCompleteList
	},
	SET_AUTOCOMPLETE_LOADING(state, loading) {
		state.isListLoading = loading
	},
	SET_AUTOCOMPLETE(state, autoComplete) {
		const index = state.autoComplete.list.findIndex(i => i.id === autoComplete.id)
		if (index !== -1) {
			state.autoComplete.list.splice(index, 1, autoComplete)
		} else {
			state.autoComplete.list.unshift(autoComplete)
		}
	},
	DELETE_AUTOCOMPLETE(state, autoComplete) {
		let index = state.autoComplete.list.findIndex(a => a.id === autoComplete.id)
		state.autoComplete.list.splice(index, 1)
	},
}

const actions = {
	async getOnBehalfOfList({ commit }) {
		commit('SET_ON_BEHALF_OF_LOADING', true)
		try {
			const list = await api.onBehalfOf.getList()
			commit('SET_ON_BEHALF_OF_LIST', list)
		} finally {
			commit('SET_ON_BEHALF_OF_LOADING', false)
		}
	},
	async editOnBehalfOf({ commit }, onBehalfOf) {
		commit('EDIT_ON_BEHALF_OF', onBehalfOf)
	},
	async saveOnBehalfOf({ commit, state, dispatch }) {
		try {
			// Get the detail item
			const detail = state.onBehalfOf.detail
			if (!detail) return
			// Keep track if this is a new record
			const isNew = detail.id === undefined
			// Save the detail and assign the id
			const response = await api.onBehalfOf.save(detail)
			detail.id = response.data
			if (isNew) {
				// Add entity if it was new
				commit('ADD_ON_BEHALF_OF', detail)
				// Dispatch add notification
				dispatch('addNotification', {
					message: 'Contact added!',
					notificationType: 'success',
				})
			} else {
				// Reset existing entity
				commit('SET_ON_BEHALF_OF', detail)
				// Dispatch update notification
				dispatch('addNotification', {
					message: 'Contact updated!',
					notificationType: 'success',
				})
			}
			commit('CLEAR_ON_BEHALF_OF')
		} finally {
		}
	},
	async cancelOnBehalfOf({ commit }) {
		commit('CLEAR_ON_BEHALF_OF')
	},
	async deleteOnBehalfOf({ commit, state, dispatch }) {
		try {
			// Get the detail item
			const detail = state.onBehalfOf.detail
			if (!detail || !detail.id) return
			// Delete the item by id
			await api.onBehalfOf.delete(detail.id)
			commit('DELETE_ON_BEHALF_OF', detail)
			// Dispatch delete notification
			dispatch('addNotification', {
				message: 'Contact deleted!',
				notificationType: 'success',
			})
			commit('CLEAR_ON_BEHALF_OF')
		} finally {
		}
	},
	async getAutoCompleteList({ commit }) {
		commit('SET_AUTOCOMPLETE_LOADING', true)
		try {
			const response = await api.autoComplete.getAutoCompleteList()
			const autoCompleteList = response.data
			commit('SET_AUTOCOMPLETE_LIST', autoCompleteList)
		} finally {
			commit('SET_AUTOCOMPLETE_LOADING', false)
		}
	},
	async saveAutoComplete({ commit }, autoComplete) {
		try {
			const response = await api.autoComplete.saveAutoCompleteEntry(autoComplete)
			const autoCompleteEntry = response.data.data
			commit('SET_AUTOCOMPLETE', autoCompleteEntry)
		} finally {
		}
	},
	async deleteAutoComplete({ commit }, autoComplete) {
		try {
			await api.autoComplete.deleteAutoCompleteEntry(autoComplete)
			commit('DELETE_AUTOCOMPLETE', autoComplete)
		} finally {
		}
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
