/**
 * 
 * @typedef imagePlane
 * @property {vector3} rowCosines
 * @property {vector3} columnCosines
 */

/** 
 * A 3-dimensional vector
 *
 * @typedef vector3
 * @property {number} x
 * @property {number} y
 * @property {number} z
 */


const isApproximatelyOne = num => num > 0.85 && num < 1.15

/**
 * Determines if two image planes have roughly the same orientation
 *
 * @export @public @function
 * @param {imagePlane} a
 * @param {imagePlane} b
 * @returns boolean
 */
export default function (a, b) {
	if (!a || !b || !a.rowCosines || !b.rowCosines) return false
	const rowDotProduct =
		a.rowCosines.x * b.rowCosines.x +
		a.rowCosines.y * b.rowCosines.y +
		a.rowCosines.z * b.rowCosines.z
	if (!isApproximatelyOne(rowDotProduct)) return false
	const columnDotProduct =
		a.columnCosines.x * b.columnCosines.x +
		a.columnCosines.y * b.columnCosines.y +
		a.columnCosines.z * b.columnCosines.z
	return isApproximatelyOne(columnDotProduct)
}