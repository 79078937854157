import api from '@services/api'
import { eventBus } from '@services/eventBus'
import { addNotification } from '@services/notificationService'
import _ from 'lodash'
const state = {
	currentParams: {
		consultantId: null,
		status: null,
		saleId: null,
	},
	reviews: {
		results: [],
		numResults: 0,
		hasMoreResults: false,
	},
	reviewsTotal: {
		saleId: '',
		saleName: '',
		saleCode: '',
		rejected: 0,
		approved: 0,
		awaitingreview: 0,
		rejectedbysale: 0,
		submitted: 0,
	},
	sort: {
		order: {
			by: 'hipNumber',
			isAscending: true,
		},
		currentPage: 0,
		resultsPerPage: 50,
	},
	reportImages: [],
	attachments: [],
	attachmentsTemplates: [],
	studyImages: [], // status for images: "NotMatched", "Matched" and "Rejected"
	reviewSubmissionId: '',
	reviewSubmissionStatus: '', /// "Awaiting Review", "Rejected", "Approved", "Rejected By Sale" and "Submitted"
	consultantId: '',
	hipNumber: '',
	saleId: '',
	saleName: '',
	saleClinicCode: '',
	saleEntryId: '',
	submitResponse: {},
	updateReviewData: {
		reportTemplateImageViewId: null,
		imageId: null,
		selectedThumbnail: null,
		studyId: null,
	},
	patientId: '',
}

// Mutations Map
const CHECK_IF_TEMPLATES_MATCHED = 'CHECK_IF_TEMPLATES_MATCHED'

const CLEAR_TOTAL_REVIEWS_STATUS = 'CLEAR_TOTAL_REVIEWS_STATUS'
const CLEAR_SALES_REVIEW_LIST = 'CLEAR_SALES_REVIEW_LIST'
const CLEAR_SORT_SALES_REVIEW_LIST = 'CLEAR_SORT_SALES_REVIEW_LIST'
const CLEAR_FOR_INITIAL_REVIEW_SUBMISSION = 'CLEAR_FOR_INITIAL_REVIEW_SUBMISSION'
const GET_REPOSITORY_IMAGES_TEMPLATES = 'GET_REPOSITORY_IMAGES_TEMPLATES'
const GET_REPOSITORY_REVIEW_SUBMISSION_DETAILS = 'GET_REPOSITORY_REVIEW_SUBMISSION_DETAILS'
const GET_SALES_REVIEW_LIST = 'GET_SALES_REVIEW_LIST'
const GET_DASHBOARD_REVIEW_LIST = 'GET_DASHBOARD_REVIEW_LIST'

const SELECT_REPORT_TEMPLATE_IMAGEVIEW_ID_ACTION = 'SELECT_REPORT_TEMPLATE_IMAGEVIEW_ID_ACTION'
const SELECT_THUMBNAIL_ACTION = 'SELECT_THUMBNAIL_ACTION'
const SET_REVIEW_PARAMS = 'SET_REVIEW_PARAMS'
const SET_TOTAL_REVIEWS_STATUS = 'SET_TOTAL_REVIEWS_STATUS'
const SUBMIT_APPROVED_REVIEW_LIST = 'SUBMIT_APPROVED_REVIEW_LIST'

const UNASSIGN_TEMPLATE_ACTION = 'UNASSIGN_TEMPLATE_ACTION'
const UPDATE_SORT_SALES_REVIEW_LIST = 'UPDATE_SORT_SALES_REVIEW_LIST'
const UPDATE_REVIEW_DATA = 'UPDATE_REVIEW_DATA'
const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT'

const mutations = {
	CHECK_IF_TEMPLATES_MATCHED(state) {
		const matches = state.studyImages.filter(image => image.status === 'Matched')
		matches.forEach(matchTemplate => {
			const index = state.reportImages.findIndex(report => report.id === matchTemplate.reportTemplateImageViewId)
			if (index > -1) {
				state.reportImages[index].imageId = matchTemplate.imageId
				state.reportImages[index].studyId = matchTemplate.studyId
				state.reportImages[index].thumbnailHidden = state.reportImages[index].thumbnail
				state.reportImages[index].thumbnail = null
			} else {
				// This will check if the extraimage it's all ready on this array
				const index = state.reportImages.findIndex(report => report.imageId === matchTemplate.imageId)
				if (index === -1) {
					state.reportImages.push({
						imageId: matchTemplate.imageId,
						studyId: matchTemplate.studyId,
						thumbnail: null,
					})
				}
			}
		})

		const attachmentsMatches = state.attachments.filter(attachment => attachment.status === 'Matched')
		attachmentsMatches.forEach(attachment => {
			const index = state.attachmentsTemplates.findIndex(
				attachmentTemplate => attachmentTemplate.id === attachment.reportTemplateImageViewId
			)
			if (index > -1) {
				state.attachmentsTemplates[index].imageId = attachment.imageId
				state.attachmentsTemplates[index].studyId = attachment.studyId
				state.attachmentsTemplates[index].thumbnailHidden = state.attachments[index].thumbnail
				state.attachmentsTemplates[index].thumbnail = null
				state.attachmentsTemplates[index].templateName = `${state.attachmentsTemplates[index].templateName}`
			} else {
				const index = state.attachmentsTemplates.findIndex(
					attachmentTemplate => attachmentTemplate.imageId === attachment.imageId
				)
				if (index === -1) {
					state.attachments.push({
						imageId: attachment.imageId,
						studyId: attachment.studyId,
						thumbnail: null,
					})
				}
			}
		})
		state.attachmentsTemplates = [...state.attachmentsTemplates]
	},

	CLEAR_TOTAL_REVIEWS_STATUS(state) {
		Object.assign(state.reviewsTotal, {
			saleId: '',
			saleName: '',
			saleCode: '',
			rejected: 0,
			approved: 0,
			awaitingreview: 0,
			rejectedbysale: 0,
			submitted: 0,
		})
	},

	CLEAR_SALES_REVIEW_LIST(state) {
		Object.assign(state, {
			reviews: {
				results: [],
				numResults: 0,
				hasMoreResults: false,
			},
			sort: {
				order: {
					by: 'hipNumber',
					isAscending: true,
				},
				currentPage: 0,
				resultsPerPage: 50,
			},
		})
	},

	CLEAR_SORT_SALES_REVIEW_LIST(state) {
		Object.assign(state.sort, {
			order: {
				by: 'hipNumber',
				isAscending: true,
			},
			currentPage: 0,
			resultsPerPage: 50,
		})
	},

	// #region 		For Review Submission

	CLEAR_FOR_INITIAL_REVIEW_SUBMISSION(state) {
		Object.assign(state, {
			...state,
			reportImages: [],
			attachments: [],
			attachmentsTemplates: [],
			studyImages: [],
			reviewSubmissionStatus: '',
			consultantId: '',
			hipNumber: '',
			saleId: '',
			saleName: '',
			saleClinicCode: '',
			saleEntryId: '',
			submitResponse: {},
			updateReviewData: {
				reportTemplateImageViewId: null,
				imageId: null,
				selectedThumbnail: null,
				studyId: null,
			},
			patientId: '',
		})
	},

	GET_REPOSITORY_IMAGES_TEMPLATES(state, payload) {
		state.reportImages = [...payload['imageReportTemplateImageViews']]
		state.attachmentsTemplates = [...payload['attachmentReportTemplateImageViews']]
	},

	GET_REPOSITORY_REVIEW_SUBMISSION_DETAILS(state, payload) {
		state.studyImages = []
		Object.assign(state, {
			...state,
			reviewSubmissionId: payload?.reviewSubmissionId,
			reviewSubmissionStatus: payload?.reviewSubmissionStatus, // 'Rejected' | 'Approve ',
			consultantId: payload?.consultantId,
			hipNumber: payload?.hipNumber,
			saleId: payload?.saleId,
			saleName: payload?.saleName,
			saleClinicCode: payload?.saleClinicCode,
			saleEntryId: payload?.saleEntryId,
			attachments: payload?.attachments ?? [],
			studyImages: payload.imageData,
			patientId: payload.saleCode + '-' + payload.hipNumber,
		})
	},

	GET_SALES_REVIEW_LIST(state, payload) {
		Object.assign(state.reviews, payload)
	},

	GET_DASHBOARD_REVIEW_LIST(state, payload) {
		Object.assign(state.reviews, payload)
	},

	SELECT_THUMBNAIL_ACTION(state, { thumbnail }) {
		const selectedIndex = state.studyImages.findIndex(image => image?.imageId === thumbnail?.imageId)
		let updateReviewData = state.updateReviewData
		updateReviewData = {
			...updateReviewData,
			selectedThumbnail: state.studyImages[selectedIndex],
			reportTemplateImageViewId: thumbnail?.reportTemplateImageViewId,
			imageId: thumbnail?.imageId,
			studyId: thumbnail?.studyId,
		}
		state.updateReviewData = { ...updateReviewData }
	},

	SET_REVIEW_PARAMS(state, payload) {
		Object.assign(state.currentParams, payload)
	},

	SELECT_REPORT_TEMPLATE_IMAGEVIEW_ID_ACTION(state, { reportTemplateImageViewId }) {
		let updateReviewData = state.updateReviewData
		updateReviewData.reportTemplateImageViewId = reportTemplateImageViewId
		state.updateReviewData = { ...updateReviewData }
	},

	SET_TOTAL_REVIEWS_STATUS(state, payload) {
		delete Object.assign(payload, { awaitingreview: payload['awaitingReview'] })['awaitingReview']
		delete Object.assign(payload, { rejectedbysale: payload['rejectedBySale'] })['rejectedBySale']
		Object.assign(state.reviewsTotal, payload)
	},

	SUBMIT_APPROVED_REVIEW_LIST(state, payload) {
		Object.assign(state.submitResponse, payload)
	},

	CHECK__IF_TEMPLATES_MATCHED(state) {
		// TODO CHANGE WITH ENUM
		const matches = state.studyImages.filter(image => image.status === 'Matched')
		matches.forEach(matchTemplate => {
			const index = state.reportImages.findIndex(report => report.id === matchTemplate.reportTemplateImageViewId)
			if (index > -1) {
				state.reportImages[index].imageId = matchTemplate.imageId
				state.reportImages[index].studyId = matchTemplate.studyId
				state.reportImages[index].thumbnailHidden = state.reportImages[index].thumbnail
				state.reportImages[index].thumbnail = null
			} else {
				// This will check if the extraimage it's all ready on this array
				const index = state.reportImages.findIndex(report => report.imageId === matchTemplate.imageId)
				if (index === -1) {
					state.reportImages.push({
						imageId: matchTemplate.imageId,
						studyId: matchTemplate.studyId,
						thumbnail: null,
					})
				}
			}
		})
		state.reportImages = [...state.reportImages]
	},

	UNASSIGN_TEMPLATE_ACTION(state, index) {
		if (!state.reportImages[index].reportTemplateId) {
			const arrayWithoutIndex = state.reportImages.filter(image => image.imageId !== state.reportImages[index].imageId)
			state.reportImages = _.cloneDeep(arrayWithoutIndex)
		} else {
			state.reportImages[index].imageId = null
			state.reportImages[index].thumbnail = state.reportImages[index].thumbnailHidden
			state.reportImages[index].thumbnailHidden = null
		}
	},

	UPDATE_SORT_SALES_REVIEW_LIST(state, payload) {
		if (!('currentPage' in payload)) payload.currentPage = 0
		Object.assign(state.sort, payload)
	},

	UPDATE_REVIEW_DATA(state, payload) {
		const updateReviewData = {
			...state.updateReviewData,
			reportTemplateImageViewId: payload.reportTemplateImageViewId,
			imageId: payload.imageId,
		}
		state.updateReviewData = { ...updateReviewData }
	},

	DELETE_ATTACHMENT(state, payload) {
		const attachmentsTemplates = state.attachmentsTemplates
		const index = attachmentsTemplates.findIndex(template => template.id === payload.id)
		if (index > -1) {
			const attachmentsTemplates = state.attachmentsTemplates
			attachmentsTemplates[index] = payload
			state.attachmentsTemplates = [...attachmentsTemplates]
		}
	},
	// #endregion
}

const actions = {
	// Broadcast Theme from external windows
	getReviewListBroadcast({ dispatch }, payload) {
		const type = 'getReviewListAction'
		dispatch(type, payload)
		eventBus.broadcast(eventBus.type.VUEX_ACTION, { type, payload })
	},

	// Get the Review List
	async getSalesReviewListAction({ commit, dispatch }, { consultantId, params }) {
		dispatch('setIsLoadingDataAction', true)
		commit(CLEAR_TOTAL_REVIEWS_STATUS)
		try {
			const response = await api.repositoryReview.getList({ consultantId, params: _.pickBy(params, _.identity) })
			commit(GET_SALES_REVIEW_LIST, response)
			await dispatch('getTotalReviewsByStatusAction', { consultantId, saleId: params.saleId })
			dispatch('setIsLoadingDataAction', false)
		} finally {
		}
	},
	async getTotalReviewsByStatusAction({ commit }, { consultantId, saleId }) {
		try {
			const response = await api.repositoryReview.getDashboard({ consultantId })
			const reviews = response.filter(item => item.saleId === saleId)[0]
			if (!reviews) return
			commit(SET_TOTAL_REVIEWS_STATUS, reviews)
		} finally {
		}
	},
	async getDashboardReviewsAction({ commit, dispatch }, { consultantId, params }) {
		dispatch('setIsLoadingDataAction', true)
		commit(CLEAR_TOTAL_REVIEWS_STATUS)
		try {
			const response = await api.repositoryReview.getDashboardList({
				consultantId,
				params: _.pickBy(params, _.identity),
			})
			commit(GET_DASHBOARD_REVIEW_LIST, response)
			dispatch('setIsLoadingDataAction', false)
		} finally {
		}
	},

	updateSortAction({ commit }, payload) {
		commit(UPDATE_SORT_SALES_REVIEW_LIST, payload)
	},

	async clearData({ commit }) {
		await commit(CLEAR_TOTAL_REVIEWS_STATUS)
		await commit(CLEAR_SALES_REVIEW_LIST)
		await commit(CLEAR_SORT_SALES_REVIEW_LIST)
	},

	// Get the Report Images / Template
	async getReportImagesAction({ commit, dispatch }) {
		dispatch('setIsLoadingDataAction', true)
		try {
			const consultantId = sessionStorage.getItem('consultantId') || state.currentParams.consultantId
			let response = await api.repositoryReview.getTemplateImageViews(consultantId, state.saleEntryId)
			dispatch('setIsLoadingDataAction', false)
			commit(GET_REPOSITORY_IMAGES_TEMPLATES, response)
			dispatch('checkIfTemplateMatched')
		} catch (e) {
			console.log('error', e)
		}
	},

	// Get the Study Images
	async getReviewSubmissionDetailsAction({ commit, dispatch }, reviewSubmissionId) {
		dispatch('setIsLoadingDataAction', true)
		try {
			const consultantId = sessionStorage.getItem('consultantId') || state.currentParams.consultantId
			let response = await api.repositoryReview.getReviewSubmissionDetails(consultantId, reviewSubmissionId)
			dispatch('setIsLoadingDataAction', false)
			commit(GET_REPOSITORY_REVIEW_SUBMISSION_DETAILS, response)
			dispatch('checkIfTemplateMatched')
		} catch (e) {
			console.log('error', e)
		}
	},

	async updateReviewSubmissionImageAction({ commit, dispatch }) {
		dispatch('setIsLoadingDataAction', true)
		try {
			await api.repositoryReview.updateReviewSubmissionImage(
				state.reviewSubmissionId,
				state.updateReviewData.imageId,
				state.updateReviewData.reportTemplateImageViewId,
				state.consultantId
			)
			dispatch('setIsLoadingDataAction', false)
			dispatch('getReviewSubmissionDetailsAction', state.reviewSubmissionId)
		} catch (e) {
			console.log('error', e)
		}
	},

	selectThumbnailAction({ commit }, { thumbnail }) {
		commit(SELECT_THUMBNAIL_ACTION, {
			thumbnail,
		})
	},

	selectReportTemplateImageViewIdAction({ commit, dispatch }, { reportTemplateImageViewId }) {
		commit(SELECT_REPORT_TEMPLATE_IMAGEVIEW_ID_ACTION, { reportTemplateImageViewId })
		dispatch('updateReviewSubmissionImageAction')
	},

	checkIfTemplateMatched({ commit }) {
		commit(CHECK_IF_TEMPLATES_MATCHED)
	},

	async unassignTemplateAction({ dispatch, commit }, index) {
		try {
			const template = state.reportImages[index]
			await api.repositoryReview.deleteReviewSubmisssionImage(
				state.reviewSubmissionId,
				template.imageId,
				state.consultantId
			)
			commit(UNASSIGN_TEMPLATE_ACTION, index)
			dispatch('getReviewSubmissionDetailsAction', state.reviewSubmissionId) // TODO: remove this and do a manual update base on payload
		} catch (e) {}
	},

	async deleteAttachmentAction({ dispatch, commit }, imageId) {
		try {
			const response = await api.repositoryReview.deleteReviewSubmisssionImage(
				state.reviewSubmissionId,
				imageId,
				state.consultantId
			)
			commit(DELETE_ATTACHMENT, response)
		} catch (e) {}
	},

	setReviewParamsAction({ commit }, { consultantId, saleId, status }) {
		sessionStorage.setItem('status', status)
		sessionStorage.setItem('consultantId', consultantId)
		sessionStorage.setItem('saleId', saleId)
		commit(SET_REVIEW_PARAMS, { consultantId, saleId, status })
	},

	async sendReviewSubmissionAction(
		{ dispatch },
		{ type, reason } // approve, reject, approveSubmit
	) {
		if (type === 'approve') {
			dispatch('setIsLoadingDataAction', true)
			try {
				const consultantId = sessionStorage.getItem('consultantId') || state.currentParams.consultantId
				await api.repositoryReview.approveReviewSubmisssion(consultantId, state.reviewSubmissionId)
				dispatch('setIsLoadingDataAction', false)
				addNotification('Approved', 'success')
			} catch (e) {
				throw e
			}
		} else if (type === 'reject') {
			dispatch('setIsLoadingDataAction', true)
			try {
				const consultantId = sessionStorage.getItem('consultantId') || state.currentParams.consultantId
				await api.repositoryReview.rejectReviewSubmisssion(consultantId, state.reviewSubmissionId, reason)
				dispatch('setIsLoadingDataAction', false)
				addNotification('Rejected', 'success')
			} catch (e) {
				throw e
			}
		} else if (type === 'approveSubmit') {
			dispatch('setIsLoadingDataAction', true)
			try {
				const consultantId = sessionStorage.getItem('consultantId') || state.currentParams.consultantId
				await api.repositoryReview.approveAndSubmitReviewSubmisssion(consultantId, state.reviewSubmissionId)
				dispatch('setIsLoadingDataAction', false)
				// TODO FUTURE WORK WITH BE INTEGRETION
				// dispatch('submitSaleEntryAction')
				addNotification('Approved & Submitted', 'success')
			} catch (e) {
				throw e
			}
		}
	},
	async submitApprovedReviewListAction({ commit, dispatch }, approvedReviews) {
		dispatch('setIsLoadingDataAction', true)
		try {
			const consultantId = sessionStorage.getItem('consultantId') || state.currentParams.consultantId
			const response = await api.repositoryReview.submitApprovedReviewList(
				consultantId,
				approvedReviews.approvedReviews
			)
			commit(SUBMIT_APPROVED_REVIEW_LIST, response)
			dispatch('setIsLoadingDataAction', false)
		} catch (e) {
			console.log('error', e)
		}
	},
	async syncStudies({ commit, dispatch }, selectedDate) {
		console.log('calling sync studies')
		dispatch('setIsLoadingDataAction', true)
		commit(CLEAR_TOTAL_REVIEWS_STATUS)
		try {
			const response = await api.repositoryReview.syncStudies(
				selectedDate
			)
			commit(GET_SALES_REVIEW_LIST, response)
			dispatch('setIsLoadingDataAction', false)
		} catch (e) {
			console.log('error', e)
		}
	},
	// async submitSaleEntryAction({ commit, dispatch }) {
	// 	dispatch('setIsLoadingDataAction', true)
	// 	let studyIds = []
	// 	const reportImagesMatches = state.reportImages.filter(reportImage => {
	// 		if (reportImage.imageId) {
	// 			const index = studyIds.indexOf(study => study.id === reportImage.studyId)
	// 			if (index === -1) {
	// 				studyIds.push(reportImage.studyId)
	// 			}
	// 			return reportImage
	// 		}
	// 	})

	// 	const saleEntry = {
	// 		consultantId: state.consultantId,
	// 		images: reportImagesMatches,
	// 		notificationEmails: [],
	// 		saleEntryId: state.saleEntryId,
	// 		saleId: state.saleId,
	// 		studyIds: studyIds,
	// 	}
	// 	try {
	// 		//	const response = await api.sales.submitSaleEntry(saleEntry) // TODO FUTURE WORK WITH BE INTEGRETION
	// 		dispatch('setIsLoadingDataAction', false)
	// 	} catch (e) {
	// 		console.log('error', e)
	// 	}
	// },

	async addAsExtraImageAction({ commit, dispatch }) {
		dispatch('setIsLoadingDataAction', true)
		const images = _.cloneDeep(state.studyImages.filter(image => image.status === 'NotMatched'))
		let imagesIds = []
		images.forEach(async thumbnail => {
			imagesIds.push(thumbnail.imageId)
		})
		await api.repositoryReview.updateReviewSubmissionImageAsExtra(
			state.reviewSubmissionId,
			imagesIds,
			state.consultantId
		)
		dispatch('setIsLoadingDataAction', false)
		dispatch('getReviewSubmissionDetailsAction', state.reviewSubmissionId)
	},

	async uploadAttachmentAction({ dispatch, commit }, { studyId, imageId, reportTemplateImageViewId }) {
		try {
			dispatch('setIsLoadingDataAction', true)
			await api.repositoryReview.saveReviewSubmissionStudy(state.reviewSubmissionId, studyId, state.consultantId)
			dispatch('setIsLoadingDataAction', false)
			commit(UPDATE_REVIEW_DATA, { imageId, reportTemplateImageViewId })
			dispatch('updateReviewSubmissionImageAction')
		} catch (e) {}
	},

	async addStudyToReviewSubmissionAction({ dispatch }, { studyId }) {
		try {
			dispatch('setIsLoadingDataAction', true)
			await api.repositoryReview.saveReviewSubmissionStudy(state.reviewSubmissionId, studyId, state.consultantId)
			dispatch('getReviewSubmissionDetailsAction', state.reviewSubmissionId)
			dispatch('setIsLoadingDataAction', false)
		} catch (e) {}
	},

	clearInitialStateReviewSubmissionAction({ commit }) {
		commit(CLEAR_FOR_INITIAL_REVIEW_SUBMISSION)
	},
}

const getters = {
	patientId: state => state.patientId,
	reportImages: state => state.reportImages,
	attachmentsTemplates: state => state.attachmentsTemplates,
	studyImages: state => state.studyImages,
	selectedStudyImage: state => state.selectedStudyImage,
	submissionStatus: state => state.reviewSubmissionStatus,
	consultantId: state => state.consultantId,
	saleId: state => state.saleId,
	saleName: state => state.saleName,
	hipNumber: state => state.hipNumber,
	studyIds: state => {
		const studyIds = []
		state.studyImages.forEach(studyImage => {
			const index = studyIds.indexOf(study => study.id === studyImage.studyId)
			if (index === -1) {
				studyIds.push(studyImage.studyId)
			}
		})
	},
}

export default {
	state,
	mutations,
	actions,
	getters,
}
