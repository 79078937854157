// @ts-ignore
import DecodeWorker from 'worker-loader!./DecodeWorker' // eslint-disable-line
import convertColorSpace from './lib/convertColorSpace'
import getMinMax from './lib/getMinMax'
import { metaData as csMetaData } from 'cornerstone-core/dist/cornerstone'
import { getCornerstoneImageIdParts } from '../'

const MAX_WORKERS = navigator.hardwareConcurrency || 4

type DecodeTask = {
	cornerstoneImageId: string
	frameIndex: number
	arrayBuffer: ArrayBuffer
}

class DecodeWorkerInfo {
	worker: DecodeWorker = undefined
	task: DecodeTask = null
	terminateDebounce: number = null
}

class DicomDecoder {
	decodeQueue: DecodeTask[] = []
	decodeDeferreds: Map<string, DeferredPromise> = new Map()
	workerPool: DecodeWorkerInfo[] = new Array(MAX_WORKERS)

	decodeDicom(cornerstoneImageId: string, arrayBuffer: ArrayBuffer): Promise<any> {
		const { frameIndex } = getCornerstoneImageIdParts(cornerstoneImageId)
		const task: DecodeTask = {
			cornerstoneImageId,
			frameIndex,
			arrayBuffer,
		}
		this.decodeQueue.push(task)
		const workersNeeded = Math.min(MAX_WORKERS, this.decodeQueue.length)
		if (workersNeeded) this.runWorkers(workersNeeded)
		return new Promise((resolve, reject) => {
			const deferred: DeferredPromise = {
				resolve,
				reject,
			}
			this.decodeDeferreds.set(cornerstoneImageId, deferred)
		})
	}

	runWorkers(workersNeeded: number) {
		let workersRunning = 0
		for (let i = 0; i < this.workerPool.length; i++) {
			if (workersRunning === workersNeeded) break
			if (!this.workerPool[i] || !this.workerPool[i].task) this.runWorker(i) // (re)start worker if not running
			workersRunning++
		}
	}

	runWorker(i: number) {
		if (!this.workerPool[i]) this.workerPool[i] = this.createWorker(i)
		clearTimeout(this.workerPool[i].terminateDebounce)
		if (this.decodeQueue.length) {
			const nextTask = this.decodeQueue.shift()
			this.workerPool[i].task = nextTask
			this.workerPool[i].worker.postMessage(nextTask, [nextTask.arrayBuffer])
		} else {
			this.workerPool[i].terminateDebounce = setTimeout(() => {
				if (this.workerPool[i]) this.workerPool[i].worker.terminate()
				this.workerPool[i] = undefined
			}, 3000)
		}
	}

	createWorker(i: number): DecodeWorkerInfo {
		const workerInfo = new DecodeWorkerInfo()
		workerInfo.worker = new DecodeWorker()
		workerInfo.worker.onmessage = ({ data }) => {
			workerInfo.task = null
			const { cornerstoneImageId, decodedImage, decodeError } = data
			if (this.decodeDeferreds.has(cornerstoneImageId)) {
				const deferred = this.decodeDeferreds.get(cornerstoneImageId)
				if (!decodeError && decodedImage) {
					deferred.resolve(getTypedArray(cornerstoneImageId, decodedImage))
				} else {
					const errorMessage = decodeError?.message || ''
					deferred.reject(new Error('The requested image failed to decode. ' + errorMessage))
				}
				this.decodeDeferreds.delete(cornerstoneImageId)
			}
			this.runWorker(i)
		}
		return workerInfo
	}

	isDecoding(cornerstoneImageId: string) {
		return this.workerPool.some(d => d && d.task && d.task.cornerstoneImageId === cornerstoneImageId)
	}

	stop(cornerstoneImageId?: string) {
		// kill worker(s)
		for (let i = 0; i < this.workerPool.length; i++) {
			if (!this.workerPool[i]) continue
			if (
				cornerstoneImageId &&
				this.workerPool[i].task &&
				this.workerPool[i].task.cornerstoneImageId !== cornerstoneImageId
			)
				continue
			clearTimeout(this.workerPool[i].terminateDebounce)
			this.workerPool[i].worker.terminate()
			this.workerPool[i] = undefined
		}
		// remove queued task(s) and deferred promise(s)
		if (cornerstoneImageId) {
			const taskIndex = this.decodeQueue.findIndex(t => t.cornerstoneImageId === cornerstoneImageId)
			if (taskIndex >= 0) this.decodeQueue.splice(taskIndex, 1)
			this.decodeDeferreds.delete(cornerstoneImageId)
		} else {
			this.decodeQueue = []
			this.decodeDeferreds.clear()
		}
	}
}

function getTypedArray(cornerstoneImageId: string, decoded) {
	createTypedArray(decoded)
	// swap endianness if big-endian
	if (decoded.isBigEndian) {
		for (let i = 0; i < decoded.pixelData.length; i++) {
			decoded.pixelData[i] = swap16(decoded.pixelData[i])
		}
	}
	// calculate min and max values if missing
	if (!decoded.minPixelValue || !decoded.maxPixelValue) {
		const { min, max } = getMinMax(decoded.pixelData)
		decoded.minPixelValue = min
		decoded.maxPixelValue = max
	}
	// convert to RGB if necessary
	const { imageId: asterisImageId } = getCornerstoneImageIdParts(cornerstoneImageId)
	const imagePixelModule = csMetaData.get('imagePixelModule', asterisImageId) || {}
	decoded.isColor = isColorImage(decoded.photometricInterpretation)
	if (decoded.isColor) convertColorSpace(decoded, imagePixelModule)
	decoded.isDicom = true
	return decoded
}

function createTypedArray(decoded) {
	const isSigned = decoded.pixelRepresentation === 1
	if (decoded.bitsAllocated !== 32) {
		// 8-bit / 16-bit
		let TypedArray
		if (decoded.bitsAllocated < 16) TypedArray = Uint8Array
		else if (decoded.bitsAllocated === 16 && isSigned) TypedArray = Int16Array
		else if (decoded.bitsAllocated === 16 && !isSigned) TypedArray = Uint16Array
		decoded.pixelData = new TypedArray(decoded.pixelData, decoded.byteOffset, decoded.length)
	} else {
		// 32-bit (requires rescaling)
		decoded.pixelData = new Float32Array(decoded.pixelData, decoded.byteOffset, decoded.length)
		const range = Math.abs(decoded.maxPixelValue - decoded.minPixelValue)
		const INT_RANGE = 65535
		const slope = range / INT_RANGE
		const intercept = decoded.minPixelValue
		const newPixelData = new Uint16Array(decoded.pixelData.length)
		let newMinPixelValue = 65535
		let newMaxPixelValue = 0
		for (let i = 0; i < newPixelData.length; i++) {
			const rescaledPixel = Math.floor((decoded.pixelData[i] - intercept) / slope)
			newPixelData[i] = rescaledPixel
			newMinPixelValue = Math.min(newMinPixelValue, rescaledPixel)
			newMaxPixelValue = Math.max(newMaxPixelValue, rescaledPixel)
		}
		decoded.pixelData = newPixelData
		decoded.minPixelValue = newMinPixelValue
		decoded.maxPixelValue = newMaxPixelValue
		decoded.slope = slope
		decoded.intercept = intercept
	}
}

function isColorImage(photometricInterpretation: string) {
	return [
		'RGB',
		'PALETTE COLOR',
		'YBR_FULL',
		'YBR_FULL_422',
		'YBR_PARTIAL_422',
		'YBR_PARTIAL_420',
		'YBR_RCT',
		'YBR_ICT',
	].includes(photometricInterpretation)
}

function swap16(val) {
	return ((val & 0xff) << 8) | ((val >> 8) & 0xff)
}

export const dicomDecoder = new DicomDecoder()
