import Vue from 'vue'

Vue.directive('resizable', {
	bind(el) {
		el.style.position = 'absolute'
		let left, top, width, height, startX, startY, handleEls, activeHandle

		addHandles()
		el.addEventListener('mousedown', start)
		el.addEventListener('touchstart', start)
		el.onMouseDown = start // for removing the event listeners in unbind

		function addHandles() {
			const handles = ['t', 'r', 'b', 'l', 'tr', 'br', 'bl', 'tl']
			handleEls = handles.map(createHandleEl)
			handleEls.forEach(handleEl => el.appendChild(handleEl))
		}

		function start(e) {
			if (!handleEls.includes(e.target)) return

			const isTouch = e.type === 'touchstart' && e.touches.length > 0
			const evtData = isTouch ? e.touches[0] : e
			startX = evtData.clientX
			startY = evtData.clientY
			left = el.offsetLeft
			top = el.offsetTop
			width = el.clientWidth
			height = el.clientHeight

			document.documentElement.style.cursor = getCursor(e.target.dataset.handle)
			activeHandle = e.target.dataset.handle

			document.addEventListener('mousemove', move)
			document.addEventListener('mouseup', end)
			document.addEventListener('touchmove', move)
			document.addEventListener('touchend', end)
			e.preventDefault()
		}

		function move(e) {
			const isTouch = e.type === 'touchmove' && e.touches.length > 0
			const evtData = isTouch ? e.touches[0] : e
			const dx = evtData.clientX - startX
			const dy = evtData.clientY - startY
			const min = 300 // we can eventually make this configurable if necessary

			if (activeHandle.includes('t')) {
				const newHeight = Math.max(min, height - dy)
				if (newHeight >= min) {
					el.style.height = newHeight + 'px'
					el.style.top = top + dy + 'px'
				}
			}
			if (activeHandle.includes('b')) {
				const newHeight = Math.max(min, height + dy)
				if (newHeight >= min) {
					el.style.height = newHeight + 'px'
					el.style.top = top + 'px'
				}
			}
			if (activeHandle.includes('l')) {
				const newWidth = Math.max(min, width - dx)
				if (newWidth >= min) {
					el.style.width = newWidth + 'px'
					el.style.left = left + dx + 'px'
				}
			}
			if (activeHandle.includes('r')) {
				const newWidth = Math.max(min, width + dx)
				if (newWidth >= min) {
					el.style.width = newWidth + 'px'
					el.style.left = left + 'px'
				}
			}
			el.dispatchEvent(new CustomEvent('resize'))
			e.preventDefault()
		}

		function end() {
			document.documentElement.style.cursor = ''
			activeHandle = null

			document.removeEventListener('mousemove', move)
			document.removeEventListener('mouseup', end)
			document.removeEventListener('touchmove', move)
			document.removeEventListener('touchend', end)
		}
	},
	unbind(el) {
		document.documentElement.style.cursor = ''
		el.removeEventListener('mousedown', el.onMouseDown)
		el.removeEventListener('touchstart', el.onMouseDown)
	},
})

function createHandleEl(handle) {
	const handleWidth = '12px'
	const handleOffset = '-6px'
	const handleSideZIndex = 90
	const handleCornerZIndex = 91

	const handleEl = document.createElement('div')
	handleEl.dataset.handle = handle
	handleEl.style.position = 'absolute'
	handleEl.style.touchAction = 'none'
	handleEl.style.userSelect = 'none'
	handleEl.style.zIndex = handle.length === 1 ? handleSideZIndex : handleCornerZIndex
	handleEl.style.cursor = getCursor(handle)
	if (handle.includes('t')) {
		handleEl.style.top = handleOffset
		handleEl.style.height = handleWidth
		if (handle === 't') {
			handleEl.style.left = 0
			handleEl.style.width = '100%'
		}
	}
	if (handle.includes('b')) {
		handleEl.style.bottom = handleOffset
		handleEl.style.height = handleWidth
		if (handle === 'b') {
			handleEl.style.left = 0
			handleEl.style.width = '100%'
		}
	}
	if (handle.includes('r')) {
		handleEl.style.right = handleOffset
		handleEl.style.width = handleWidth
		if (handle === 'r') {
			handleEl.style.top = 0
			handleEl.style.height = '100%'
		}
	}
	if (handle.includes('l')) {
		handleEl.style.left = handleOffset
		handleEl.style.width = handleWidth
		if (handle === 'l') {
			handleEl.style.top = 0
			handleEl.style.height = '100%'
		}
	}
	return handleEl
}

function getCursor(handle) {
	// e.g. 'bl' => 'sw-resize'
	const cursorDirection = {
		t: 'n',
		r: 'e',
		b: 's',
		l: 'w',
	}
	return (
		handle
			.split('')
			.map(l => cursorDirection[l])
			.join('') + '-resize'
	)
}
