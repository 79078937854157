<template>
	<ast-card v-if="hipNumber" class="hip" :header="hipNumber">
		<p v-if="saleEntry.motherName">Dam: {{ saleEntry.motherName }}</p>
		<p v-if="saleEntry.fatherName">Sire: {{ saleEntry.fatherName }}</p>
		<p v-if="saleEntry.consignerName"> Consigner: {{ saleEntry.consignerName }} </p>
	</ast-card>
</template>

<script>
import AstCard from '@components/Card.vue'

export default {
	name: 'HipNumber',
	components: {
		AstCard,
	},
	props: ['cell'],
	computed: {
		saleEntry() {
			return this.cell.report.saleEntry
		},
		hipNumber() {
			if (!this.cell.report.isSalesEntry || !this.saleEntry.patientId) return
			return this.translate('consignerStudy') + ' ' + this.saleEntry.patientId
		},
	},
}
</script>

<style lang="scss">
.report-drop-down {
	position: relative;
	.overlay {
		position: absolute;
		background: transparent;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}
</style>
