import { updateImage } from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { waitForEnabledElementImageToLoad } from '@/utils/wait.js'
import { eventBus } from '@services/eventBus'

export function toggleLengthAngles({ state, dispatch }) {
	const type = 'setLengthAngles'
	const payload = !state.settingsPanel.isLengthAnglesEnabled
	dispatch(type, payload)
	eventBus.broadcast(eventBus.type.VUEX_ACTION, { type, payload })
}

export function setLengthAngles({ commit }, value) {
	const firstLengthAngleTool = cornerstoneTools.store.state.tools.find(tool => tool.name === 'AstLengthAngle')
	if (firstLengthAngleTool) {
		const toolName = firstLengthAngleTool.name
		const toolMode = firstLengthAngleTool.mode.charAt(0).toUpperCase() + firstLengthAngleTool.mode.slice(1)
		cornerstoneTools[`setTool${toolMode}`](toolName, {
			showAngles: value,
		})
		cornerstoneTools.store.state.enabledElements.forEach(async element => {
			const hasLoadedImage = await waitForEnabledElementImageToLoad(element)
			if (hasLoadedImage) {
				updateImage(element)
			}
		})
	}

	commit('SET_LENGTH_ANGLES', value)
}
