import { vec3 } from 'gl-matrix'
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData'
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray'

import buildMetadata from './data/buildMetadata.js'
import imageDataCache from './data/imageDataCache.js'
import sortDatasetsByImagePosition from './data/sortDatasetsByImagePosition.js'

/**
 *
 * @param {*} imageIds
 * @param {*} displaySetInstanceUid
 * @param {*} metaDataProvider - This needs to follow the cornerstone core's metaData.get() method.
 */
export default function getImageData(imageIds, displaySetInstanceUid, metaDataProvider, useJpeg) {
	const cachedImageDataObject = imageDataCache.get(displaySetInstanceUid)

	if (cachedImageDataObject) {
		return cachedImageDataObject
	}

	// NOTE: register cornerstone Metadata provider first!
	const { metaData0, metaDataMap, imageMetaData0 } = buildMetadata(imageIds, metaDataProvider)

	const { rowCosines, columnCosines } = metaData0
	const rowCosineVec = vec3.fromValues(rowCosines.x, rowCosines.y, rowCosines.z)
	const colCosineVec = vec3.fromValues(columnCosines.x, columnCosines.y, columnCosines.z)
	const scanAxisNormal = vec3.cross([], colCosineVec, rowCosineVec)

	const {
		spacing,
		origin,
		hasMissingSlices,
		hasOverlap,
		directionality,
		sortedDatasets,
	} = sortDatasetsByImagePosition(scanAxisNormal, metaDataMap)

	const xSpacing = metaData0.columnPixelSpacing
	const ySpacing = metaData0.rowPixelSpacing
	const zSpacing = spacing
	const xVoxels = metaData0.columns
	const yVoxels = metaData0.rows
	const zVoxels = sortedDatasets.length
	const signed = imageMetaData0.pixelRepresentation === 1
	// Note: Asteris doesn't seem to use this metadata item
	const multiComponent = metaData0.numberOfComponents > 1

	// TODO: Support numberOfComponents = 3 for RGB?
	if (multiComponent) {
		throw new Error('Multi component image not supported by this plugin.')
	}

	let pixelArray
	if (useJpeg) {
		pixelArray = new Uint8Array(xVoxels * yVoxels * zVoxels)
	} else {
		// bitsAllocated will always be 16 even if jpeg data is returned.
		switch (imageMetaData0.bitsAllocated) {
			case 8:
				if (signed) {
					throw new Error('8 Bit signed images are not yet supported by this plugin.')
				} else {
					throw new Error('8 Bit unsigned images are not yet supported by this plugin.')
				}
			case 16:
				// VTK only supports Float32 and Uint8... so we'll always use float for faster results
				pixelArray = new Float32Array(xVoxels * yVoxels * zVoxels)
		}
	}

	const scalarArray = vtkDataArray.newInstance({
		name: 'Pixels',
		numberOfComponents: 1,
		values: pixelArray,
	})

	const imageData = vtkImageData.newInstance()

	const direction = [...rowCosineVec, ...colCosineVec, ...scanAxisNormal]

	imageData.setDimensions(xVoxels, yVoxels, zVoxels)
	imageData.setSpacing(xSpacing, ySpacing, zSpacing)
	imageData.setOrigin(origin.x, origin.y, origin.z)
	// Don't set the direction on the volume, keeping it in default coordinates.
	// We will use the direction manually later for the compass
	// imageData.setDirection(direction)
	imageData.getPointData().setScalars(scalarArray)

	const imageDataObject = {
		imageIds,
		metaData0,
		imageMetaData0,
		dimensions: [xVoxels, yVoxels, zVoxels],
		spacing: [xSpacing, ySpacing, zSpacing],
		origin,
		direction,
		sliceDirectionality: directionality,
		vtkImageData: imageData,
		metaDataMap,
		sortedDatasets,
		hasMissingSlices,
		hasOverlap,
		loaded: false,
		isJpeg: useJpeg,
	}

	imageDataCache.set(displaySetInstanceUid, imageDataObject)

	return imageDataObject
}
