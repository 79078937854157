import {
	convertRGBColorByPixel,
	convertRGBColorByPlane,
	convertYBRFullByPixel,
	convertYBRFullByPlane,
	convertPALETTECOLOR,
} from '../colorSpaceConverters'

function convertRGB(imageFrame) {
	if (imageFrame.planarConfiguration === 0) {
		convertRGBColorByPixel(imageFrame)
	} else {
		convertRGBColorByPlane(imageFrame)
	}
}

function convertYBRFull(imageFrame) {
	if (imageFrame.planarConfiguration === 0) {
		convertYBRFullByPixel(imageFrame)
	} else {
		convertYBRFullByPlane(imageFrame)
	}
}

export default function convertColorSpace(imageFrame, imagePixelModule) {
	// convert based on the photometric interpretation

	if (imageFrame.photometricInterpretation === 'RGB') {
		convertRGB(imageFrame)
	} else if (imageFrame.photometricInterpretation === 'YBR_RCT') {
		convertRGB(imageFrame)
	} else if (imageFrame.photometricInterpretation === 'YBR_ICT') {
		convertRGB(imageFrame)
	} else if (imageFrame.photometricInterpretation === 'PALETTE COLOR') {
		convertPALETTECOLOR(imageFrame, imagePixelModule)
	} else if (imageFrame.photometricInterpretation === 'YBR_FULL_422') {
		convertYBRFull(imageFrame)
	} else if (imageFrame.photometricInterpretation === 'YBR_FULL') {
		convertYBRFull(imageFrame)
	} else {
		throw new Error(`No color space conversion for photometric interpretation ${imageFrame.photometricInterpretation}`)
	}
}
