// https://docs.sentry.io/learn/context/?platform=browser
import * as Sentry from '@sentry/browser'

const state = {}
const getters = {}
const mutations = {}

let actions = {
	/**
	 * Sets the user for exception tracking
	 *
	 * @param {*} { commit }
	 * @param {*} { id, clinicCode, clinicId }
	 */
	SET_USER: (store, { id, clinicId, clinicCode, username, isCommunity }) => {
		Sentry.configureScope(scope => {
			scope.setUser({ id, clinicId, clinicCode, username, isCommunity })
		})
	},
	/**
	 * Clears the set user for exception tracking
	 */
	UNSET_USER: () =>
		Sentry.configureScope(scope => {
			scope.setUser({})
		}),
}

export default {
	state,
	getters,
	mutations,
	actions,
}
