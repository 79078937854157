<template>
	<div class="toolbar-button">
		<div style="position:relative" @click="event => disabled && event.stopPropagation()">
			<button
				:disabled="disabled"
				:class="[
					{
						active: (isDropdownOpen && hasDropdown) || active,
						'has-caret': hasDropdown,
						'has-icon': !!icon,
					},
					className,
				]"
				:title="title"
				@contextmenu.prevent
				@click="toggleDropdown({ isOpen: !isDropdownOpen })"
			>
				<p
					v-if="hasDropdown"
					class="caret"
					:class="{
						down: dropdownLocation === 'bottom',
						up: dropdownLocation === 'top',
						right: dropdownLocation === 'right',
						left: dropdownLocation === 'left',
					}"
				></p>

				<slot name="icon">
					<svg-icon v-if="icon" :icon="icon" />
					<div v-if="badge" class="badge">{{ badge }}</div>
					<div v-if="hasNotification" class="notify"><p class="pulse"></p></div>
				</slot>
				<slot name="label">
					<p v-if="label" class="label no-break">{{ label }}</p>
				</slot>
			</button>
		</div>
		<div v-show="isDropdownOpen" ref="dropdown" class="dropdown">
			<slot name="dropdown" :hide="delay => toggleDropdown({ isOpen: false, delayClosing: delay })"></slot>
		</div>
	</div>
</template>

<script>
import { eventBus } from '@services/eventBus'
import { kebabCase } from 'lodash'
import { createPopper } from '@popperjs/core'

let popper

export default {
	name: 'ToolbarButton',
	props: {
		icon: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		badge: {
			type: String,
			default: '',
		},
		active: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		dropdownLocation: {
			type: String,
			default: 'bottom',
		},
		hasNotification: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isDropdownOpen: false,
			debounceTimer: undefined,
		}
	},
	computed: {
		className() {
			return `btn-${kebabCase(this.title)}`
		},
		hasDropdown() {
			return !!this.$scopedSlots.dropdown
		},
		hasLabel() {
			return !!(this.label || this.$scopedSlots.label)
		},
	},
	mounted() {
		eventBus.on('resize', this.placeDropdown)
		document.addEventListener('click', this.closeOnOutsideClick)
	},
	beforeDestroy() {
		eventBus.off('resize', this.placeDropdown)
		document.removeEventListener('click', this.closeOnOutsideClick)
	},
	methods: {
		closeOnOutsideClick(e) {
			if (!this.$el.contains(e.target)) this.toggleDropdown({ isOpen: false })
		},
		toggleDropdown({ isOpen, delayClosing }) {
			if (!this.hasDropdown) return
			clearTimeout(this.debounceTimer)
			if (delayClosing && !isOpen) {
				const msDelay = typeof delayClosing === 'number' ? delayClosing : 200
				// Delay closing dropdown to allow multi-click events
				this.debounceTimer = setTimeout(() => {
					this.isDropdownOpen = isOpen
				}, msDelay)
			} else {
				this.isDropdownOpen = isOpen
			}
			this.$nextTick(this.placeDropdown)
		},
		placeDropdown() {
			const dropdown = this.$refs.dropdown
			if (!this.isDropdownOpen || !dropdown) return
			const windowCenter = window.innerWidth / 2
			const buttonCenter = this.$el.offsetLeft + this.$el.clientWidth / 2
			const isNearCenter = Math.abs(windowCenter - buttonCenter) < windowCenter * 0.1
			const isInLeftHalfOfWindow = !isNearCenter && this.$el.getBoundingClientRect().left < windowCenter
			if (popper) popper.destroy()
			let placement = isNearCenter ? 'bottom' : isInLeftHalfOfWindow ? 'bottom-start' : 'bottom-end'
			if (['left', 'right'].includes(this.dropdownLocation)) {
				placement = isInLeftHalfOfWindow ? 'right-start' : 'right-start'
			}
			popper = createPopper(this.$el, dropdown, {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 2],
						},
					},
				],
				placement,
			})
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.toolbar-button button:hover,
.primary .dropdown button:hover {
	color: var(--toolbar-button-color-hover);
	background: var(--toolbar-button-bg-hover);
}

.toolbar-button button:active,
.toolbar-button button.active,
.primary .dropdown button:active,
.primary .dropdown button.active {
	color: var(--toolbar-button-color-active);
	background: var(--toolbar-button-bg-active);
}

.primary .toolbar-button button:hover {
	color: var(--toolbar-button-color-hover);
	background: var(--toolbar-button-bg-hover);
}

.primary .toolbar-button button:active,
.primary .toolbar-button button.active {
	color: var(--toolbar-button-color-active);
	background: var(--toolbar-button-bg-active);
}

.toolbar-button {
	display: flex;
	flex-direction: column;
	button {
		width: 100%;
	}
	button {
		display: grid;
		grid-template-columns: 0 1fr auto 0;
		grid-template-rows: auto 1fr;
		grid-template-areas:
			'caret-left icon label-right caret-right'
			'caret-left label-bottom label-right caret-right';
		align-items: center;
		min-width: 48px;
		min-height: 48px;
		max-height: 54px;
		padding: 4px 2px;
		overflow: hidden;
		color: inherit;
		cursor: pointer;
		background: transparent;
		border: 0;
		outline: 0;

		&.has-icon {
			grid-template-rows: 1fr auto;
		}

		&.has-caret {
			grid-template-columns: 11px 1fr auto 11px;
		}

		&.has-caret:not(.has-icon) {
			padding-right: 8px;
		}

		&:disabled {
			opacity: 0.4;
			cursor: not-allowed;
		}

		.icon {
			display: inline-block;
			grid-area: icon;
			font-size: 24px;
		}
		.badge {
			position: absolute;
			top: 2px;
			right: calc(50% - 24px);
			font-size: 8px;
		}
		.label {
			font-size: 9px;
			grid-area: label-bottom;
			letter-spacing: 0.05em;
			padding: 0 2px;
			text-transform: uppercase;
		}

		.caret {
			grid-area: caret-right;
			opacity: 0.7;
			justify-self: end;
			&.left {
				grid-area: caret-left;
				justify-self: start;
			}
			&.down::before {
				content: '\25be';
			}
			&.up::before {
				content: '\25b4';
			}
			&.left::before {
				content: '\25c2';
			}
			&.right::before {
				content: '\25b8';
			}
		}
		.notify,
		.pulse {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			background: var(--icon-danger);

			.pulse {
				position: absolute;
				top: -3px;
				right: -3px;
				width: 12px;
				height: 12px;
				border-radius: 100%;
				animation: pulse 1s ease-out infinite;
			}
		}

		&.introjs-showElement {
			background: var(--primary-bg);
			color: var(--primary-label);
			pointer-events: none;
		}
	}

	&.label-right button {
		padding-right: 8px;
		&:not(.has-caret) {
			padding-left: 8px;
		}
		.label {
			grid-area: label-right;
			padding-left: 4px;
			font-size: 0.8em;
		}
	}
	.dropdown {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: map-get($zindex, modal);
	}

	.no-break {
		white-space: nowrap;
	}
}

@keyframes pulse {
	0% {
		-moz-transform: scale(0);
		opacity: 0;
	}
	25% {
		-moz-transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		-moz-transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		-moz-transform: scale(0.8);
		opacity: 0.5;
	}
	100% {
		-moz-transform: scale(1);
		opacity: 0;
	}
}
</style>
