import api from '@services/api'
import isMobileOS from '@utils/isMobileOS'
import { omniDesktop } from '@/electron/omniDesktop'
import router from '@router/index'
import { showStudyOptions } from '@dialogs/OpenStudyOptionsDlg.vue'
import { eventBus } from '@services/eventBus'
import listToArray from '@utils/listToArray'
import storage from '@services/storage'

const isElectron = omniDesktop.isConnected
const PRIMARY_VIEWER = 'mainViewer'

const state = {
	openStudiesInNewWindow: JSON.parse(storage.getItem('temp:openInNewWindow')) || false,
	openedStudyWindowReferences: [],
}

const getters = {
	allowOpenStudiesInNewWindow: (s, g, rootState) => !rootState.auth.claims.isRepositoryUser,
}

const actions = {
	async saveOpenStudiesInNewWindow({ commit }, newWindow) {
		// API Call
		const response = await api.user.setOpenStudiesWindowSetting(newWindow)
		if (response.status === 200) {
			const type = 'SET_OPEN_STUDIES_NEW_WINDOW'
			commit(type, newWindow)
			eventBus.broadcast(eventBus.type.VUEX_MUTATION, { type, payload: newWindow })
		}
	},
	async fetchOpenStudiesInNewWindow({ commit }) {
		const response = await api.user.getOpenStudiesWindowSetting()
		if (response.status === 200) {
			commit('SET_OPEN_STUDIES_NEW_WINDOW', response.data || false)
		}
		// Setting is undefined, user has not specified.
		// Don't show prompt on mobile, even though they can still set it in settings.
		if (response.status === 204) {
			commit('SET_OPEN_STUDIES_NEW_WINDOW', null)
		}
	},
	openStudy(store, routeObject) {
		if (isElectron) {
			return electron.openStudy(store, routeObject)
		}
		return browser.openStudy(store, routeObject)
	},
	async preloadViewerWindows() {
		if (isElectron && !router.currentRoute.path.includes('viewer') && (await omniDesktop.request('isWorklistWindow'))) {
			omniDesktop.request('preloadPrimaryViewers')
		}
	},
	// Duplicate action from Authentication module, listening for window management
	clearAllData() {
		if (isElectron) omniDesktop.request('closeAllViewers')
	},
}

const browser = {
	// Main Action, forwarded from actions
	async openStudy(store, { studyId, reportId, initialSeriesId, initialSeriesIds, imageId }) {
		const query = {}
		const routeConfig = { name: 'viewer', query }
		if (studyId) {
			query.studyIds = studyId
		}
		if (reportId) {
			query.reportIds = reportId
		}
		if (initialSeriesId) {
			query.sid = initialSeriesId
		}
		if (initialSeriesIds) {
			query.sids = initialSeriesIds
		}
		if (imageId) {
			query.imageId = imageId
		}
		const { href } = router.resolve(routeConfig)

		if ((reportId || state.openStudiesInNewWindow) && !isMobileOS()) {
			await browser.pruneClosedWindows(store)
			// NOTE: We pass in the href/url here because IF the window is not already open, it will get opened in the process
			const { window, isNewWindow } = browser.getMainViewerWindowReference(store, href)
			if (window && window.isShowingStudyOrReport && window.isShowingStudyOrReport({ studyId, reportId })) {
				const action = await showStudyOptions({ forReport: !!reportId, forExistingStudy: true })
				if (action === 'focus') {
					window.onAddToViewer({
						sid: initialSeriesId,
						initialSeriesIds,
					})
					// - Focus study in existing viewer
					window.focus()
				}
				if (action === 'new') {
					// - Open New Viewer - Open a new viewer window for the study.
					browser.openNewStudyWindow(store, href)
				}
			} else if (!isNewWindow) {
				// in the supposedly impossible event that no window was found but wasn't created either.
				if (!window) {
					browser.openNewStudyWindow(store, href)
				} else {
					const isEmptyViewer = window.location.hash === '#/viewer/' || !window.onAddToViewer
					const action = isEmptyViewer ? 'replace' : await showStudyOptions({ forReport: !!reportId })

					if (action === 'add' && window.onAddToViewer) {
						// - Add to Viewer - Adds the study to an existing viewer window.
						window.onAddToViewer({ studyId, reportId, initialSeriesId, initialSeriesIds, imageId })
						window.focus()
					}
					if (action === 'replace') {
						// - Close Study - Close the study in the existing viewer window and open the new study instead.
						window.location.replace(href)
						window.focus()
					}
					if (action === 'new') {
						// - Open New Viewer - Open a new viewer window for the study.
						browser.openNewStudyWindow(store, href)
					}
				}
			}
		} else {
			// Open in existing window
			router.push(routeConfig)
		}
	},
	openNewStudyWindow({ commit, state }, href) {
		let windowName = '_blank'
		let newWindow
		let wasOpen = false
		if (!state.openedStudyWindowReferences.length) {
			// Create a named window we can retreive later if the main window is refreshed.
			windowName = PRIMARY_VIEWER
			newWindow = window.open('', windowName, 'resizable,scrollbars,status')
			if (newWindow.location.href === 'about:blank') {
				// Create new window
				newWindow = window.open(href, windowName, 'resizable,scrollbars,status')
			} else {
				// Got the existing window!
				newWindow.location.replace(href)
				newWindow.focus()
				wasOpen = true
			}
		} else {
			newWindow = window.open(href, windowName, 'resizable,scrollbars,status')
		}
		commit('ADD_OPEN_STUDY_WINDOW', newWindow)
		newWindow.onload = () => {
			// Sometimes the window can be undefined already?
			if (newWindow && !newWindow.closed) {
				newWindow.onunload = () => {
					delete newWindow.onunload
					// If the window was closed programmatically (CLOSE_ALL_STUDY_WINDOWS), then we don't need to try to remove it again
					!newWindow.closed && commit('REMOVE_OPEN_STUDY_WINDOW', newWindow)
				}
			}
		}
		return { newWindow, wasOpen }
	},
	// Used to cleanup windows that were opened and registered, but closed before the onLoad event, leaving them in a stuck position.
	// Could also be windows that were opened but logged out, meaning they no longer have the onAddtoViewer function attached.
	pruneClosedWindows({ commit, state }) {
		state.openedStudyWindowReferences.forEach(w => {
			if (w.closed || !w.onAddToViewer) commit('REMOVE_OPEN_STUDY_WINDOW', w)
		})
	},
	getMainViewerWindowReference({ commit, state }, href) {
		let isNewWindow = false
		let windowRef = null
		if (!state.openedStudyWindowReferences.length) {
			// Create a named window we can retreive later if the main window is refreshed.
			const openedWindow = window.open('', PRIMARY_VIEWER, 'resizable,scrollbars,status')
			if (openedWindow.location.href !== 'about:blank') {
				// Got the existing window, reattach the reference!
				commit('ADD_OPEN_STUDY_WINDOW', openedWindow)
				openedWindow.onload = () => {
					// Sometimes the window can be undefined already?
					if (openedWindow && !openedWindow.closed) {
						openedWindow.onunload = () => {
							delete openedWindow.onunload
							// If the window was closed programmatically (CLOSE_ALL_STUDY_WINDOWS), then we don't need to try to remove it again
							!openedWindow.closed && commit('REMOVE_OPEN_STUDY_WINDOW', openedWindow)
						}
					}
				}
				windowRef = openedWindow
				isNewWindow = false
			} else {
				// Note: a blank window has been created at this point, but it is named. We will reuse this window in openNewStudyWindow!
				const { newWindow } = browser.openNewStudyWindow({ commit, state }, href)
				windowRef = newWindow
				isNewWindow = true
			}
		} else {
			// If the viewer window list is not pruned properly, it could still have windows that are referenced but not initialized to the state we need.
			windowRef = state.openedStudyWindowReferences.find(
				window => !window.closed && window.isShowingStudyOrReport && window.onAddToViewer
			)
			isNewWindow = false
		}
		return {
			window: windowRef,
			isNewWindow,
		}
	},
}

const electron = {
	async openStudy(store, { studyId, reportId, initialSeriesId, imageId, modality }) {
		if ((reportId || state.openStudiesInNewWindow) && !isMobileOS()) {
			let seriesIds
			if (initialSeriesId) {
				seriesIds = [initialSeriesId]
			} else if (studyId || reportId) {
				const studyIds = listToArray(studyId)
				seriesIds = await fetchSeriesIds(studyIds, reportId)
			}
			const viewerData = {
				studyId,
				reportId,
				seriesIds,
				imageId,
			}

			const layoutRequest = await api.user.getLayoutPreset(modality)
			if (layoutRequest.data) {
				viewerData.modalityLayout = layoutRequest.data
			}
			const { areWindowsOpen, isViewerEmpty, primaryStudyIds, primaryReportIds } = await electron.getMainViewerState()
			const isViewerAlreadyShowingStudy =
				(!!studyId && primaryStudyIds.includes(studyId)) || (!!reportId && primaryReportIds.includes(reportId))
			if (areWindowsOpen) {
				if (isViewerEmpty) {
					omniDesktop.request('replaceInPrimaryViewer', viewerData)
					return
				}
				const action = await showStudyOptions({ forReport: !!reportId, forExistingStudy: isViewerAlreadyShowingStudy })
				if (action === 'focus' || action === 'add') {
					// - Add to Viewer - Adds the study to an existing viewer window.
					omniDesktop.request('addToPrimaryViewer', viewerData)
				} else if (action === 'replace') {
					// - Close Study - Close the study in the existing viewer window and open the new study instead.
					omniDesktop.request('replaceInPrimaryViewer', viewerData)
				}
				if (action === 'new') {
					// - Open New Viewer - Open a new viewer window for the study.
					omniDesktop.request('openNewSecondaryViewerWindow', viewerData)
				}
			} else {
				// Else, get # of images in study & default layout for modality, to pass to Electron to determine how best to open
				omniDesktop.request('openPrimaryViewer', viewerData)
			}
		} else {
			// Open in existing window
			const query = {}
			if (studyId) query.studyIds = studyId
			if (reportId) query.reportIds = reportId
			if (initialSeriesId) query.sid = initialSeriesId
			if (imageId) query.imageId = imageId
			router.push({ name: 'viewer', query })
		}
	},
	getMainViewerState() {
		return omniDesktop.request('getPrimaryViewerState')
	},
	async checkIfMainViewerWindowIsOpen() {
		const isMainViewerOpen = await omniDesktop.request('isPrimaryViewerOpen')
		if (isMainViewerOpen) return true
		return false
	},
	async checkIfMainViewerShowing(studyId, reportId) {
		const isViewerShowing = await omniDesktop.request('isViewerShowing', { studyId, reportId })
		if (isViewerShowing) return true
		return false
	},
}

const mutations = {
	SET_OPEN_STUDIES_NEW_WINDOW(state, newWindow) {
		state.openStudiesInNewWindow = newWindow
		storage.setItem('temp:openInNewWindow', JSON.stringify(newWindow))
	},
	ADD_OPEN_STUDY_WINDOW({ openedStudyWindowReferences }, openedWindow) {
		openedStudyWindowReferences.push(openedWindow)
	},
	REMOVE_OPEN_STUDY_WINDOW(state, openedWindow) {
		const filtered = state.openedStudyWindowReferences.filter(w => {
			const matches = w !== openedWindow && !w.closed
			return matches
		})
		state.openedStudyWindowReferences = filtered
	},
	CLOSE_ALL_STUDY_WINDOWS({ openedStudyWindowReferences }) {
		let windowRef = openedStudyWindowReferences.pop()
		while (windowRef) {
			windowRef.close()
			windowRef = openedStudyWindowReferences.pop()
		}
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}

async function fetchSeriesIds(studyIds, reportId) {
	let seriesIds = []
	// Get study series
	if (studyIds.length) {
		const data = await api.viewer.getStudy({ ids: studyIds })
		if (data && data.studies && data.studies.length) {
			seriesIds = seriesIds.concat(...data.studies.map(study => study.imageData.series)).map(series => series.seriesId)
		}
	}
	// Get report series
	if (reportId) {
		const data = await api.study.getReportStudies(reportId)
		if (data && data.length) {
			data.forEach(r => {
				if (r.imageData && r.imageData.series && r.imageData.series.length) {
					r.imageData.series.forEach(s => seriesIds.push(s.seriesId))
				}
			})
		}
	}
	return seriesIds
}

/**
 * Sorts screens left->right, and top-bottom if in the same horizontal position.
 * @returns {Electron.Display[]}
 */
// function getSortedScreens() {
// 	return screen.getAllDisplays().sort((a, b) => {
// 		const x = a.bounds.x - b.bounds.x
// 		if (x === 0) {
// 			return a.bounds.y - b.bounds.y
// 		}
// 		return x
// 	})
// }
