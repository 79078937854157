// Vue
import Vue from 'vue'
import App from './App.vue'
import router from '@router'
import store from '@store'
// Exception Tracking
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
// Plugins
import VueCompositionApi from '@vue/composition-api'
import VueGtag from "vue-gtag";
import VueNotifications from './plugins/VueNotifications'
// Need to import CSS for Vue Select since v3.x
import 'vue-select/dist/vue-select.css'
import SvgIcon from '@components/SvgIcon.vue'
import VSelect from '@components/VSelect.vue'

// Mixins
import localization from './mixins/localization'
// Custom
import api from '@services/api'
import './filters'
import './directives/resizable'
import './directives/focus'
import './directives/sortable'
import './registerServiceWorker'
import mediaMixin from '@/mixins/MediaMixin.js'
import { gaMap } from '@store/modules/analytics'

import Layout from '@reporting/components/Layout.vue'
import Cell from '@reporting/components/Cell.vue'
import storage from '@services/storage'

// initalize global services
import '@services/dicomSend'
import '@services/menu'
import '@services/billingCodes'
import '@/electron/omniDesktop'
import '@services/imagePrecache'

// @ts-ignore
Vue.component('layout', Layout)
// @ts-ignore
Vue.component('cell', Cell)

// clean up old localforage indexedDB database to avoid QuotaExceededError
indexedDB.deleteDatabase('localforage') // TODO: we can remove this after a while

// Exception Tracking
// https://docs.sentry.io/clients/javascript/usage/
const errorMessageCounts: Map<string, number> = new Map()
const isErrorMessage = (e: string | Error, m?: string) => {
	if (e && typeof e === 'object' && 'message' in e) {
		if (!m || e.message.includes(m)) return true
	}
	return false
}
Sentry.init({
	release: window.config.release,
	debug: window.config.environmentName === 'Development',
	dsn: window.config.sentryDSN,
	environment: window.config.environmentName,
	integrations: [new Integrations.Vue({ Vue })],
	beforeSend(event, { originalException }) {
		const isNetworkError =
			originalException &&
			typeof originalException === 'object' &&
			'config' in originalException &&
			'request' in originalException
		if (isNetworkError) return null
		// ignore Chrome bug https://bugs.chromium.org/p/chromium/issues/detail?id=809574
		if (isErrorMessage(originalException, 'ResizeObserver loop limit exceeded')) return null
		else if (isErrorMessage(originalException)) {
			// limit events to 100 of the same error message
			const { message } = originalException as Error
			const errorMessageCount = errorMessageCounts.get(message) || 0
			if (errorMessageCount >= 100) {
				const ONE_WEEK = 1000 * 60 * 24 * 7
				setTimeout(() => errorMessageCounts.set(message, 0), ONE_WEEK) // reset count after a week
				return null // do not send to Sentry
			} else {
				errorMessageCounts.set(message, errorMessageCount + 1)
			}
		}
		return event
	},
})

// Polyfills
require('canvas-toBlob') // Edge

// Directives
require('./directives/draggable.js')
require('./directives/dblclick.js')
require('./directives/scrollNav.js')

// Desktop Builds:
// `window.ElectronInterface` will come from Electron's preload script,
// and `vue-electron` will be loaded from that interface
const ElectronInterface = window.ElectronInterface

// Can be configured further:
// https://github.com/MatteoGabriele/vue-analytics

Vue.use(VueGtag, generateAnalyticsConfigObject())

Vue.use(VueCompositionApi)
Vue.use(VueNotifications, { store })

Vue.component('v-select', VSelect)
Vue.component('svg-icon', SvgIcon)

Vue.mixin(localization)
Vue.mixin(mediaMixin)

Vue.prototype.$api = api
Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV !== 'production'

Vue.prototype.$IDEXXAPI = 'IDEXXVMSAPI'
Vue.prototype.$VetCTAPI = 'VETCTAPIINTEGRATION'
Vue.prototype.$VetsChoice_ClinicCode = 'VTSCHOAPIINTEGRATION'
Vue.prototype.$TELERADIOLOGY_INTEGRATION_CLINIC_CODES = [
	Vue.prototype.$IDEXXAPI,
	Vue.prototype.$VetCTAPI,
	Vue.prototype.$VetsChoice_ClinicCode,
]

start()

function start() {
	/* eslint-disable no-new */
	const app = new Vue({
		store,
		router,
		render: createEle => createEle(App),
	}).$mount('#app')

	// If running inside Cypress
	if (window.Cypress) {
		// Attach the app to the window, which can be useful
		// for manually setting state in Cypress commands
		// such as `cy.logIn()`
		window.__app__ = app
	}
}

function generateAnalyticsConfigObject() {
	const isElectron = !!ElectronInterface
	const set = [{ field: gaMap.OMNI_VERSION, value: window.config.release }]
	const fields: any = {}
	const isProduction = process.env.NODE_ENV === 'production'
	if (isElectron) {
		set.push({ field: 'checkProtocolTask', value: null })
		fields.storage = 'none'
		const clientId = localStorage.getItem('ga:clientId')
		if (clientId) fields.clientId = clientId
	}
	return {
		config: { id: storage.getItem('ga:tokenId') || window.config.gaPropertyTrackingId || 'G-Y1X3JJKQG2' },
    fields: fields,
    set: set,
    autoTracking: {
      exception: true,
    },
    debug: {
      enabled: !isProduction && process.env.VUE_APP_GA_ENABLED,
      sendHitTask: isProduction,
    }
	}
}
