import { defaultInitCanvases, newConsultationRequest } from '@store/modules/viewer/_shared/index.js'

export default {
	studies: [],
	studiesNotFound: [], // studies from API that may belong to another clinic
	isViewerLoading: false,
	consultationRequest: newConsultationRequest(),
	hideLoadingText: false,
	draggingFromThumbnailList: false,
	activeCanvasIndex: 0,
	prevActiveCanvasIndex: 0,
	activeDisplaySetIndex: 0,
	canvasLayout: {
		canvasesPerRow: 1,
		numCanvases: 1,
	},
	prevCanvasLayout: null,
	canvases: defaultInitCanvases(),
	seriesViewportCache: {},
	manualStackOffsets: [],
	activeImageMetadata: null,
	hangingProtocols: [],
	hangingProtocol: undefined,
	isHangingProtocolLoading: false,
	loadSeriesFromMiddle: true,
	userSettings: {
		hangingProtocol: '',
	},
	calibrations: {},
	settingsPanel: {
		overlayText: true,
		toolbarLocation: 'top',
		isImageOrientationMarkersEnabled: true,
		isLengthAnglesEnabled: true,
		isReferenceLineSynchronizationEnabled: true,
		isStackScrollSynchronizationEnabled: true,
		isManualStackScrollSynchronizationEnabled: false,
	},
  tableHeadersSettings: {
    studyListHeader: [],
    teleconsultationListHeader: [],
  },
}
