import { detect } from 'detect-browser'

const { os } = detect()
const mobileOperatingSystems = ['ios', 'android os', 'blackberry os', 'windows mobile']

export default function isMobileOS() {
	// iPadOS 13 disguises itself as a mac run Safari so we need to
	// be a little more clever with our second check
	// https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
	return (
		mobileOperatingSystems.includes(os.toLowerCase()) ||
		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
	)
}
