<template>
	<g :style="{ filter: 'url(#shadow)' }">
		<line :style="lineStyle" :x1="origin.x - 5" :y1="origin.y" :x2="origin.x + 5" :y2="origin.y" />
		<line :style="lineStyle" :x1="origin.x" :y1="origin.y - 5" :x2="origin.x" :y2="origin.y + 5" />
		<rect
			:data-id="`${id}`"
			:data-group-id="groupId"
			:style="strokeHitTargetStyle"
			:x="origin.x - 5"
			:y="origin.y - 5"
			width="10"
			height="10"
		/>
	</g>
</template>

<script>
import { baseMixin } from './mixins'

export default {
	mixins: [baseMixin],
	props: {
		origin: {
			type: Object,
			default: () => ({ x: 0, y: 0 }),
		},
	},
	computed: {
		lineStyle() {
			return {
				...this.strokeStyle,
				filter: '',
			}
		},
	},
}
</script>
