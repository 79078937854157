











































































import state from '@services/reportService'
import { Cell } from '../classes'
import layout from './Layout.vue'
import LayoutWrapper from './LayoutWrapper.vue'
import RequestInfo from '@reporting/primitives/RequestInfo.vue'
import ResponseInfo from '@reporting/primitives/ResponseInfo.vue'
import ReferredBy from '@reporting/primitives/ReferredBy.vue'
import Consultant from '@reporting/primitives/Consultant.vue'
import ImageComments from '@reporting/primitives/ImageComments.vue'
import PatientInfo from '@reporting/primitives/PatientInfo.vue'
import Addendums from '@reporting/primitives/Addendums.vue'
import RelatedReports from '@reporting/primitives/RelatedReports.vue'
import RequestSelector from '@reporting/primitives/RequestSelector.vue'
import ResponseSelector from '@reporting/primitives/ResponseSelector.vue'
import RichText from '@reporting/primitives/RichText.vue'
import ReferredByForm from '@reporting/primitives/ReferredByForm.vue'
import PatientForm from '@reporting/primitives/PatientForm.vue'
import ImagesAttachments from '@reporting/primitives/ImagesAttachments.vue'
import Thumbnails from '@reporting/primitives/Thumbnails.vue'
import ConsultantSelector from '@reporting/primitives/ConsultantSelector.vue'
import PrioritySelector from '@reporting/primitives/PrioritySelector.vue'
import ServiceSelector from '@reporting/primitives/ServiceSelector.vue'
import BillingCodeSelector from '@reporting/primitives/BillingCodeSelector.vue'
import Notifications from '@reporting/primitives/Notifications.vue'
import Dropdown from '@reporting/primitives/Dropdown.vue'
import RadioGroup from '@reporting/primitives/RadioGroup.vue'
import CheckboxGroup from '@reporting/primitives/CheckboxGroup.vue'
import Textbox from '@reporting/primitives/Textbox.vue'
import Checkbox from '@reporting/primitives/Checkbox.vue'
import ReportDate from '@reporting/primitives/ReportDate.vue'
import ReportText from '@reporting/primitives/ReportText.vue'
import ReportImage from '@reporting/primitives/ReportImage.vue'
import ReportDivider from '@reporting/primitives/Divider.vue'
import PageBreak from '@reporting/primitives/PageBreak.vue'
import HipNumber from '@reporting/primitives/HipNumber.vue'
import ReportTable from '@reporting/primitives/ReportTable.vue'
import Panel from '@reporting/primitives/Panel.vue'
import PanelView from '@reporting/primitives/PanelView.vue'

export default {
	name: 'Cell',
	components: {
		layout,
		LayoutWrapper,
		RequestInfo,
		ResponseInfo,
		Consultant,
		ReferredBy,
		ImageComments,
		PatientInfo,
		Addendums,
		RelatedReports,
		RequestSelector,
		ResponseSelector,
		RichText,
		ReferredByForm,
		PatientForm,
		ImagesAttachments,
		Thumbnails,
		ConsultantSelector,
		PrioritySelector,
		ServiceSelector,
		BillingCodeSelector,
		Notifications,
		Dropdown,
		RadioGroup,
		CheckboxGroup,
		Textbox,
		Checkbox,
		ReportText,
		ReportDate,
		ReportImage,
		ReportDivider,
		PageBreak,
		HipNumber,
		ReportTable,
		Panel,
		PanelView,
	},
	props: {
		cell: {
			type: Cell,
			required: true,
		},
	},
	data() {
		return {
			state,
		}
	},
	computed: {
		cellWarning() {
			const warnings = [this.cell.validationWarning]
			if (this.cell.binding) {
				const getExpressionErrors = e => e.calcErrors.concat(...e.args.map(getExpressionErrors))
				warnings.push(...new Set(getExpressionErrors(this.cell.binding.expression))) // deduped flat array
			}
			return warnings.join(' ')
		},
		showCornerName() {
			let cell = this.cell
			if (!cell.template.editorMode) return false
			if (['Page Header', 'Page Footer'].includes(cell.layout.name) && cell.type === 'Layout Wrapper') return true
			return this.state.activeCell === this.cell || this.state.selCell === this.cell || this.adjusting
		},
		style() {
			let cell = this.cell
			let props = cell.props
			let padding = props.padding
			let justifyContent = 'flex-start'
			// note that "stretch" verticalAlignment is handled by Panel.vue
			if (cell.type === 'Panel' && props.verticalAlignment === 'middle') justifyContent = 'center'
			if (cell.type === 'Panel' && props.verticalAlignment === 'bottom') justifyContent = 'flex-end'
			// ensure empty panels in editor have enough rendered padding to allow dropping into them
			const isEmptyPanel = cell.type === 'Panel' && !cell.cells.length
			const EMPTY_MIN_PADDING = 16
			if (padding && cell.template.editorMode && isEmptyPanel)
				padding = padding.map(p => Math.max(p, EMPTY_MIN_PADDING))
			let result: any = {
				justifyContent,
				color: props.color && `var(--report-text-${props.color})`,
				fontWeight: props.bold ? 'bold' : 'normal',
				fontStyle: props.italic ? 'italic' : 'normal',
				textDecoration: props.underline ? 'underline' : 'none',
				background: props.background && `var(--report-bg-${props.background})`,
				padding: padding ? padding.map(p => p + 'px').join(' ') : undefined,
				height: props.height ? `${props.height}px` : undefined,
			}
			if (['Text', 'Table', 'Panel'].includes(cell.type)) {
				result.alignSelf = 'stretch' // panels are always full-width, as well as text background
			}
			if (props.maxwidth !== null) {
				result.maxWidth = props.maxwidth + '%'
			}

			if (cell.isTableCell) {
				let r = props.row + 1
				let r2 = r + props.rowspan

				let c = props.col + 1
				let c2 = c + props.colspan
				result.gridArea = `${r} / ${c} / ${r2} / ${c2}`
			}

			if (cell.panel && !cell.panel.vertical && cell.panel.props.alignment === 'justify') {
				result.flexGrow = 1
			}
			if (['Divider', 'Page Break'].includes(cell.type)) result.alignSelf = 'stretch'
			const useCellBorders =
				(cell.isTableCell && cell.panel.props.border && cell.panel.props.border !== 'none') ||
				(cell.type !== 'Table' && !cell.isTableCell && cell.props.borderColor)
			const showDashedBorder =
				(cell.isPanel && cell.template.editorMode && !cell.layout.previewInEditor) || cell.type === 'Layout Wrapper'
			if (useCellBorders) {
				Object.assign(result, cell.borders)
			} else if (showDashedBorder) {
				result.borderColor = 'var(--editor-panel-border)'
				result.borderStyle = 'dashed'
			}
			return result
		},
		toolIcon() {
			const tool = state.tools.find(t => t.name === this.cell.type)
			if (!tool) return
			return tool.icon
		},
	},
	watch: {
		cell() {
			this.cell.el = this.$el
		},
	},
	created() {
		let cell = this.cell

		// create widigit is not primitive type
		let layout = cell.template.getLayout(cell.type)
		if (layout) {
			// if editor mode render always use the same instance
			if (cell.editorMode) {
				cell.addSubLayout(layout)
			} else {
				cell.addSubLayout(layout.createInstance())
			}
		}
	},
	mounted() {
		this.cell.el = this.$el
		if (
			this.cell.template.editorMode &&
			state.activeCell === this.cell &&
			this.$nextTick(() => this.selectPropInput('text'))
		) {
			// if a new Text control was added, focus the text prop textarea
			this.selectPropInput('text')
		} else if (this.cell.template.editorMode && state.activeCell === this.cell && 'label' in this.cell.props) {
			// if a form field was added, focus the label prop input
			this.$nextTick(() => this.selectPropInput('label'))
		}
	},
	methods: {
		onDragEnter() {
			state.selCell = this.cell
		},
		onMouseOver() {
			state.selCell = this.cell
		},
		onDragStart(e: DragEvent) {
			if (this.cell.isRoot || this.cell.isTableCell || this.cell.type === 'Layout Wrapper') return e.preventDefault()
			e.dataTransfer.setData('cell', this.cell.path)
			state.dragCell = this.cell
			return false
		},
		onDblClick() {
			if (!this.cell || !this.cell.template.editorMode) return
			if (this.cell.props.binding) this.selectPropInput('binding')
			else if (this.cell.editWidget) this.selectPropInput('widget')
			else if (this.cell.subLayouts && this.cell.subLayouts.length === 1) this.selectPropInput('widgets-0')
			else if ('text' in this.cell.props) this.selectPropInput('text')
			else if ('label' in this.cell.props) this.selectPropInput('label')
			else this.selectPropInput('name')
		},
		selectPropInput(propName) {
			const propInput: any = document.querySelector(`[data-prop-name=${propName}]`)
			if (propInput) {
				propInput.type === 'button' ? propInput.click() : propInput.select()
			}
		},
	},
}
