import Canvas from '@store/models/state/Canvas'
import clone from '@utils/deepClone'

// Mutations
import settings from './settings'

import ADD_STUDIES from './ADD_STUDIES.js'
import CLEAR_VIEWER from './CLEAR_VIEWER.js'

const shouldLoadFromMiddle = (state, series, study) => {
	if (state.loadSeriesFromMiddle === false) return false
	let modality = series && series.modality
	modality = modality || (study && study.modality)
	return ['CT', 'PT', 'MR'].includes(modality)
}

export default {
	ADD_STUDIES,
	CLEAR_VIEWER,
	...settings,
	SET_VIEWER_LOADING: (state, isLoading) => {
		state.isViewerLoading = isLoading
	},
	SET_DRAGGING_FROM_THUMBNAIL_LIST: (state, dragging) => {
		state.draggingFromThumbnailList = dragging
	},
	SET_CONSULTATION_REQUEST: (state, consultationRequest) => {
		for (let key in state.consultationRequest) {
			state.consultationRequest[key] = consultationRequest[key]
		}
	},
	SET_STUDIES_NOT_FOUND: (state, studiesNotFound) => {
		state.studiesNotFound = studiesNotFound
	},
	REMOVE_STUDY: (state, studyId) => {
		const studyIndex = state.studies.findIndex(study => study.studyId === studyId)
		state.studies.splice(studyIndex, 1)
	},
	REMOVE_REPORT_STUDIES: (state, reportId) => {
		state.studies = state.studies.filter(study => study.reportId !== reportId)
	},
	REFRESH_STUDY: (state, study) => {
		const studyIndex = state.studies.findIndex(s => s.studyId === study.studyId)
		const foundStudy = Object.assign({}, state.studies[studyIndex])

		if (foundStudy) {
			for (let prop in study) {
				foundStudy[prop] = study[prop]
			}
		}

		state.studies.splice(studyIndex, 1, foundStudy)
	},
	FILL_CANVASES: (state, series = []) => {
		if (!series.length) series = [].concat(...state.studies.map(study => study.imageData.series))
		let canvases = []
		for (let index = 0; index < state.canvases.length; index++) {
			const existingCanvas = state.canvases[index]
			const isVisible = index < state.canvasLayout.numCanvases
			const alreadyHasImage = series.some(series => series.seriesId === existingCanvas.seriesId)
			if (!isVisible || alreadyHasImage) {
				canvases.push(existingCanvas)
				continue
			}
			const { dom, vue } = existingCanvas
			let seriesId, asterisImageId
			const nextUnopenedSeries = getNextUnopenedSeries()
			if (nextUnopenedSeries) {
				seriesId = nextUnopenedSeries.seriesId
				if (!shouldLoadFromMiddle(state, nextUnopenedSeries)) {
					const firstImage = nextUnopenedSeries.images[0]
					if (firstImage) asterisImageId = firstImage.imageId
				} else {
					const middleImage = nextUnopenedSeries.images[Math.round(nextUnopenedSeries.images.length / 2) - 1]
					if (middleImage) asterisImageId = middleImage.imageId
				}
			}
			canvases.push(new Canvas({ index, seriesId, asterisImageId, dom, vue }))
		}
		state.canvases = canvases

		function getNextUnopenedSeries() {
			const openedSeries = canvases.map(canvas => canvas.seriesId)
			const firstUnopenedSeries = series.find(s => !openedSeries.includes(s.seriesId))
			return firstUnopenedSeries
		}
	},
	SET_ACTIVE_CANVAS_INDEX: (state, index) => {
		state.prevActiveCanvasIndex = state.activeCanvasIndex
		state.activeCanvasIndex = index
	},
	SET_SERIES_VIEWPORT_CACHE(state, { seriesId, viewport }) {
		state.seriesViewportCache[seriesId] = viewport
	},
	CLEAR_SERIES_VIEWPORT_CACHE(state, seriesId) {
		if (!seriesId) state.seriesViewportCache = {}
		else delete state.seriesViewportCache[seriesId]
	},
	SET_CANVAS_ACTIVE_SERIES: (state, { canvasIndex, studyId, seriesId, imageView }) => {
		const oldCanvas = state.canvases[canvasIndex]
		const newCanvas = new Canvas({
			...oldCanvas,
			studyId,
			seriesId,
			asterisImageId: getInitialImageId(),
			frameIndex: 0,
			imageView,
		})
		state.canvases.splice(canvasIndex, 1, newCanvas)

		function getInitialImageId() {
			if (studyId && seriesId) {
				let study = state.studies.find(study => study.studyId === studyId)
				if (!study) {
					// the parent studyId may not match the series.studyId
					study = state.studies.find(study => study.imageData.series.some(series => series.seriesId === seriesId))
				}
				const series = study.imageData.series.find(series => series.seriesId === seriesId)
				if (series && series.images.length) {
					if (!shouldLoadFromMiddle(state, series, study) || series.images.length <= 2) return series.images[0].imageId
					return series.images[Math.round(series.images.length / 2) - 1].imageId
				}
			}
		}
	},
	SET_CANVAS_ACTIVE_IMAGE: (state, { canvasIndex, asterisImageId, frameIndex }) => {
		const oldCanvas = state.canvases[canvasIndex]
		const newCanvas = new Canvas({ ...oldCanvas, asterisImageId, frameIndex })
		state.canvases.splice(canvasIndex, 1, newCanvas)
	},
	SET_CANVAS_INITIALIZING: (state, { canvasIndex, isInitializing }) => {
		state.canvases[canvasIndex].isInitializing = isInitializing
	},
	RESET_CANVAS_LAYOUT: state => {
		state.activeCanvasIndex = 0
		state.canvasLayout.canvasesPerRow = 1
		state.canvasLayout.numCanvases = 1
	},
	SET_CANVAS_LAYOUT: (state, { columns, numCanvases }) => {
		// Save the current layout
		state.prevCanvasLayout = { ...state.canvasLayout }
		// Apply new layout
		state.canvasLayout.canvasesPerRow = columns
		state.canvasLayout.numCanvases = numCanvases
		if (state.activeCanvasIndex >= numCanvases) state.activeCanvasIndex = 0
	},
	SWITCH_CANVASES: (state, { fromIndex, toIndex }) => {
		const canvases = [...state.canvases]
		if (fromIndex >= canvases.length || toIndex >= canvases.length) {
			return
		}
		const temp = canvases[toIndex]
		canvases[toIndex] = canvases[fromIndex]
		canvases[fromIndex] = temp
		canvases[toIndex].index = toIndex
		canvases[fromIndex].index = fromIndex
		state.canvases = canvases
	},
	SET_CANVAS_INFO: (state, { canvasIndex, domCanvas: dom, vueCanvas: vue }) => {
		const oldCanvas = state.canvases[canvasIndex]
		const newCanvas = new Canvas({ ...oldCanvas, dom, vue })
		state.canvases.splice(canvasIndex, 1, newCanvas)
	},
	CINE_PLAY: state => {
		if (state.activeCanvasIndex >= 0) state.canvases[state.activeCanvasIndex].cinePlayer.isPlaying = true
	},
	CINE_PAUSE: state => {
		if (state.activeCanvasIndex >= 0) state.canvases[state.activeCanvasIndex].cinePlayer.isPlaying = false
	},
	SET_CINE_FRAMERATE: (state, frameRate) => {
		if (state.activeCanvasIndex >= 0) state.canvases[state.activeCanvasIndex].cinePlayer.frameRate = frameRate
	},
	SET_VIEWER_HIDE_LOADING_TEXT: state => {
		state.hideLoadingText = true
	},
	SET_SERIES_METADATA_LOADED: state => {
		const studies = state.studies.slice()
		studies.forEach(study => {
			study.imageData.series.forEach(series => {
				series.isMetaLoaded = true
			})
		})
		state.studies = studies
	},
	SET_HANGING_PROTOCOLS: (state, hangingProtocols) => {
		state.hangingProtocols = hangingProtocols
	},
	SET_HANGING_PROTOCOL: (state, hangingProtocol) => {
		state.hangingProtocol = hangingProtocol
	},
	SET_HANGING_PROTOCOL_LOADING: (state, isLoading) => {
		state.isHangingProtocolLoading = isLoading
	},
	SET_LOAD_SERIES_FROM_MIDDLE: (state, loadSeriesFromMiddle) => {
		state.loadSeriesFromMiddle = loadSeriesFromMiddle
	},
	SET_USER_SETTINGS: (state, data) => {
		const newSettings = Object.assign({}, state.userSettings, data)
		state.userSettings = clone(newSettings)
	},
	SET_ACTIVE_DISPLAYSET_INDEX: (state, index) => {
		state.activeDisplaySetIndex = index
	},
	SET_STACK_SCROLL_OFFSETS(state, offsets) {
		state.manualStackOffsets = offsets
	},
	ADD_STACK_SCROLL_OFFSETS(state, offsets) {
		state.manualStackOffsets.push(...offsets)
	},
	SET_ACTIVE_IMAGE_METADATA(state, metadata) {
		state.activeImageMetadata = metadata
	},
	SET_CALIBRATION(state, { imageId, oldLength, oldPixelSpacing, newLength }) {
		state.calibrations[imageId] = {
			oldLength,
			oldPixelSpacing,
			newLength,
		}
	},
	CLEAR_CALIBRATION(state, imageId) {
		if (imageId) delete state.calibrations[imageId]
		else state.calibrations = {}
	},
}
