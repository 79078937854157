




































import { Cell } from '../classes'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'

export default {
	name: 'ResponseSelector',
	props: ['cell'],
	data() {
		return {
			selectedTemplate: null,
		}
	},
	computed: {
		canShowResponse() {
			if (this.cell.template.editorMode) return true
			else return this.cell.report.canViewResponse || this.cell.report.canRespond
		},
		canSelect() {
			if (this.cell.value) return false
			let canRespond = this.cell.report && this.cell.report.canRespond
			return !this.cell.value && (canRespond || this.cell.editorMode)
		},
	},
	watch: {
		'cell.report.canRespond': {
			handler(canRespond) {
				this.cell.props.required = canRespond
				this.cell.subLayouts.forEach(l => {
					l.readOnly = !canRespond
				})
			},
			immediate: true,
		},
		'cell.widgets': {
			handler(widgets) {
				if (widgets.length === 1) this.cell.value = widgets[0].name
				else if (this.cell.editorMode) {
					this.cell.value = null
					this.cell.subLayouts = []
				}
				this.setSubLayout()
			},
			immediate: true,
		},
	},
	created() {
		this.setSubLayout()
	},
	methods: {
		async setSubLayout() {
			if (!this.selectedTemplate && !this.cell.value) return
			if (!this.cell.value) {
				this.cell.value = this.selectedTemplate
			}

			let cell = this.cell

			if (
				cell.subLayout &&
				cell.subLayout.name !== cell.value &&
				!cell.editorMode &&
				cell.widgets.length > 1
			) {
				let msg = 'Are you sure you want to change the response type?'
				let confirmed = await showConfirm(msg)
				if (!confirmed) {
					cell.value = cell.subLayout.name // restore previous selector value
					return
				}
			}

			let report = cell.report
			let layout = cell.layout.template.getLayout(cell.value)
			if (layout) {
				cell.subLayouts = []
				let sub = layout.createInstance()
				sub.readOnly = report && !report.canRespond
				sub.forcePath = layout.name
				cell.addSubLayout(sub)
				if (cell.report) cell.report.validate()
			}
		},
	},
}
