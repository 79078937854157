import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { updateImage } from 'cornerstone-core/dist/cornerstone.js'
import store from '@store'
import { waitForEnabledElementImageToLoad } from '@/utils/wait.js'

const triggerEvent = cornerstoneTools.importInternal('util/triggerEvent')

export async function virtualStackScroll() {
	try {
		// has to have an element to broadcast the event through
		const element = store.getters.activeCanvas.dom
		triggerEvent(element, cornerstoneTools.EVENTS.STACK_SCROLL, { virtual: true })
	} catch {
		// swallow errors if no active canvas can proxy our event
	}
}

export async function virtualNewImage({ commit }, { metadata }) {
	commit('SET_ACTIVE_IMAGE_METADATA', metadata)
	// Using cornerstone.EVENTS.NEW_IMAGE with a virtual Event ran into issues with the default newImageSynchronizer, so we update canvases manually instead
	cornerstoneTools.store.state.enabledElements.forEach(async element => {
		try {
			const imageLoaded = await waitForEnabledElementImageToLoad(element)
			if (imageLoaded) updateImage(element)
		} catch (err) {
			// ignore "element not enabled" errors
		}
	})
}
