import { vec3 } from 'gl-matrix'

const vec3FromObject = ({ x, y, z }) => vec3.fromValues(x, y, z)

/**
 *
 * @param {*} scanAxisNormal - [x, y, z] array
 * @param {*} imageMetaDataMap - one of the results from BuildMetadata()
 */
export default function sortDatasetsByImagePosition(scanAxisNormal, imageMetaDataMap) {
	// See https://github.com/dcmjs-org/dcmjs/blob/4849ed50db8788741c2773b3d9c75cc52441dbcb/src/normalizers.js#L167

	let hasMissingSlices = false
	let hasOverlap = false

	const datasets = Array.from(imageMetaDataMap.values())
	const referenceDataset = datasets[0]

	const refIppVec = vec3FromObject(referenceDataset.imagePositionPatient)

	const distDataPairs = datasets.map(dataset => {
		const ippVec = vec3FromObject(dataset.imagePositionPatient)
		const positionVector = vec3.sub([], refIppVec, ippVec)
		const distance = vec3.dot(positionVector, scanAxisNormal)

		return {
			distance,
			dataset,
		}
	})

	distDataPairs.sort((a, b) => b.distance - a.distance)

	let uniqueDistDataPairs = distDataPairs.reduce((ret, dataPair) => {
		if (ret.find(dp => dp.distance === dataPair.distance)) {
			hasOverlap = true
		} else {
			ret.push(dataPair)
		}
		return ret
	}, [])

	const spacemap = {}
	const highestSpacing = {
		count: 0,
		space: 0,
	}
	for (let i = 1; i < uniqueDistDataPairs.length; i++) {
		const gap = Math.abs(uniqueDistDataPairs[i].distance - uniqueDistDataPairs[i - 1].distance)
		if (!spacemap[gap]) spacemap[gap] = 0
		spacemap[gap]++
		if (highestSpacing.count < spacemap[gap]) {
			highestSpacing.count = spacemap[gap]
			highestSpacing.space = gap
		}
	}

	// Use the most frequent spacing
	const spacing = highestSpacing.space

	const threshold = spacing * 0.05 // a 10% threshold up/down
	let sortedDatasets

	if (spacing > 0) {
		// Fill the array with null values between large gaps
		const filledArray = [uniqueDistDataPairs[0]]
		for (let i = 1; i < uniqueDistDataPairs.length; i++) {
			let gap = Math.abs(uniqueDistDataPairs[i].distance - uniqueDistDataPairs[i - 1].distance)
			if (!withinCompare(gap, spacing, threshold) && gap > spacing) {
				hasMissingSlices = true
				let d0 = distDataPairs[i - 1].distance
				let t = spacing
				while (t < gap) {
					filledArray.push({ distance: d0 + t, dataset: null })
					t += spacing
				}
			}
			filledArray.push(uniqueDistDataPairs[i])
		}

		sortedDatasets = filledArray.map(a => a.dataset)
	} else {
		// This shouldn't be possible anymore, but just in case
		sortedDatasets = uniqueDistDataPairs.map(a => a.dataset)
	}

	return {
		spacing,
		origin: distDataPairs[0].dataset.imagePositionPatient,
		directionality: distDataPairs[0].distance > 0 ? -1 : 1,
		hasMissingSlices,
		hasOverlap,
		sortedDatasets,
	}
}

function withinCompare(a, b, threshold = 0.001) {
	if (a - threshold < b && a + threshold > b) return true
	return false
}
