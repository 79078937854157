import getValue from './../internals/getValue.js'
// DBTODO: Dicom image loader
// import getLUTs from './getLUTs.js'

export default function(dicomTags) {
  return {
    // TODO VOT LUT Sequence
    rescaleIntercept: getValue(dicomTags, 'RESCALE_INTERCEPT'),
    rescaleSlope: getValue(dicomTags, 'RESCALE_SLOPE'),
    rescaleType: getValue(dicomTags, 'RESCALE_TYPE'),
    // modalityLUTSequence: getLUTs(
    //   dataSet.uint16('x00280103'),
    //   dataSet.elements.x00283000
    // ),
  }
}
