import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'

export default {
	resetLevels: ({ getters }) => {
		updateViewport(getters.activeCanvas, (enabledElement, canvas) => {
			let defaultViewport = cornerstone.getDefaultViewportForImage(
				canvas.dom,
				cornerstone.getImage(canvas.dom)
			)
			enabledElement.viewport.voi.windowWidth = defaultViewport.voi.windowWidth
			enabledElement.viewport.voi.windowCenter = defaultViewport.voi.windowCenter
			canvas.vue.isAnyImageLevelAdjusted = false
		})
	},
	rotateCanvas: ({ commit, getters }, rotation) => {
		updateViewport(getters.activeCanvas, (enabledElement, canvas) => {
			let clamped = enabledElement.viewport.rotation + rotation
			if (clamped >= 360) clamped -= 360
			if (clamped < 0) clamped += 360
			enabledElement.viewport.rotation = clamped
		})
	},
	toggleCanvasInvert: ({ commit, getters }) => {
		updateViewport(getters.activeCanvas, (enabledElement, canvas) => {
			enabledElement.viewport.invert = !enabledElement.viewport.invert
		})
	},
	toggleZoom1to1: ({ getters }) => {
		updateViewport(getters.activeCanvas, (enabledElement, canvas) => {
			if (enabledElement.viewport.scale === 1) {
				cornerstone.fitToWindow(canvas.dom)
			} else {
				enabledElement.viewport.scale = 1
			}
		})
	},
	toggleCanvasHorizontalFlip: ({ commit, getters }) => {
		updateViewport(getters.activeCanvas, (enabledElement, canvas) => {
			const { rotation } = enabledElement.viewport
			enabledElement.viewport.hflip = !enabledElement.viewport.hflip

			if (rotation !== 0 && rotation !== 180) {
				// If it has been rotated we have to rotate it correctly
				enabledElement.viewport.rotation = 360 - rotation
			}
		})
	},
	toggleCanvasVerticalFlip: ({ commit, getters }) => {
		updateViewport(getters.activeCanvas, (enabledElement, canvas) => {
			const { rotation } = enabledElement.viewport
			enabledElement.viewport.vflip = !enabledElement.viewport.vflip

			if (rotation !== 0 && rotation !== 180) {
				// If it has been rotated we have to rotate it correctly
				enabledElement.viewport.rotation = 360 - rotation
			}
		})
	},
	cacheSeriesViewport: ({ commit }, canvas) => {
		if (!canvas || (!canvas.seriesId && !canvas.imageId)) return
		try {
			const enabledElement = cornerstone.getEnabledElement(canvas.dom)
			if (!enabledElement) return
			const viewport = cloneViewport(enabledElement.viewport)
			const defaultViewport = cloneViewport(
				cornerstone.getDefaultViewportForImage(canvas.dom, cornerstone.getImage(canvas.dom))
			)
			if (!viewport || !defaultViewport) return
			// only cache viewport if it has been modified by the user
			if (JSON.stringify(viewport) !== JSON.stringify(defaultViewport)) {
				commit('SET_SERIES_VIEWPORT_CACHE', { seriesId: canvas.seriesId, viewport })
			} else {
				commit('CLEAR_SERIES_VIEWPORT_CACHE', canvas.seriesId)
			}
		} catch (err) {
			// ignore cornerstone "element not enabled" exceptions
		}
	},
	refreshCanvas: ({ state, getters }) => {
		updateViewport(getters.activeCanvas, (enabledElement, canvas) => {
			enabledElement.viewport = {
				...enabledElement.viewport,
				...state.seriesViewportCache[canvas.seriesId],
			}
		})
	},
}

/**
 * Creates a clone of a viewport object, filtering out properties we don't want to retain
 */
function cloneViewport(viewport) {
	if (!viewport) return
	const keyWhitelist = ['hflip', 'vflip', 'invert', 'rotation', 'voi']
	const clonedViewport = JSON.parse(JSON.stringify(viewport))
	for (const key in clonedViewport) if (!keyWhitelist.includes(key)) delete clonedViewport[key]
	return clonedViewport
}

/**
 * Modifies the viewport of the active canvas using a passed-in function
 *
 * @param {object} canvas - active canvas
 * @param {Function} updateFn - a function that acts on the canvas and viewport
 */
function updateViewport(canvas, updateFn) {
	if (!canvas || (!canvas.seriesId && !canvas.imageId)) return
	if (!canvas.dom) return
	try {
		const enabledElement = cornerstone.getEnabledElement(canvas.dom)
		if (!enabledElement) return

		updateFn(enabledElement, canvas)

		cornerstone.updateImage(canvas.dom)
	} catch (err) {
		// ignore cornerstone "element not enabled" exceptions
	}
}
