


























import TeleconsultationThumbnails from '@components/TeleconsultationThumbnails.vue'
import { openStudiesDlg } from '@dialogs/StudiesDlg.vue'
import { openUploadDlg } from '../../dialogs/UploadDlg.vue'
import { Cell, ReportDetail } from '../classes'
import consultations from '@services/consultationService'

export default {
	name: 'Thumbnails',
	components: {
		TeleconsultationThumbnails,
	},
	props: {
		cell: {
			type: Object,
			required: true,
		},
	},
	computed: {
		seriesToExclude() {
			return this.cell.report ? this.cell.report.seriesToExclude : []
		},
		images() {
			if (this.cell.editorMode) return []

			let report: ReportDetail = this.cell.report

			let result: IThumbnail[] = report.images.map(i => {
				return {
					imageId: i.id,
					seriesId: i.fakeSeriesId || i.seriesId,
					seriesNumber: i.seriesNumber,
					simpleName: i.simpleName,
					numberOfImages: i.numberOfImages,
					storageLocation: i.storageLocation,
				}
			})
			return result
		},
		attachments() {
			let cell: Cell = this.cell
			if (cell.editorMode) return []
			let result: IAttachment2[] = cell.report.attachments.map(i => {
				return {
					imageId: i.imageId,
					seriesId: i.seriesId,
					description: i.description,
					fileExtension: i.fileExtension,
					storageLocation: i.storageLocation,
				}
			})
			return result
		},
	},
	watch: {
		seriesToExclude() {
			if (this.cell.report) this.cell.report.validate()
		},
	},
	methods: {
		async addStudies(studyIds) {
			const { studies } = await this.$api.viewer.getStudy({ ids: studyIds }, false)
			studies.forEach(s => this.cell.report.addStudy(s))
			this.$router.replace({
				query: {
					...this.$route.query,
					studyId: this.cell.report.studyIds.join(','),
				},
			})
		},
		async addStudy() {
			let report = this.cell.report
			let study = await openStudiesDlg({
				excludeStudyIds: report.studyIds,
				searchTerm: report.patient.id,
			})
			if (study) {
				this.addStudies([study.studyId])
			}
		},
		async addFiles() {
			const studyIds = await openUploadDlg({
				context: 'addendum',
				showImageUpload: false,
				showAttachmentUpload: true,
				showViewButton: false,
				cancelOnClose: true,
				title: 'Add File',
			})
			if (studyIds.length) this.addStudies(studyIds)
		},
	},
}
