<template>
	<transition name="loading-fade" appear>
		<div v-if="isLoading" :class="[`loading-spinner`, { 'is-small': isSmall }]" :style="alignStyle">
			<svg-icon icon="spinner" pulse fixed />
			<span>Loading...</span>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		isTop: {
			type: [Boolean, String],
			default: false,
		},
		isMiddle: {
			type: Boolean,
			default: false,
		},
		isBottom: {
			type: [Boolean, String],
			default: false,
		},
		isLeft: {
			type: [Boolean, String],
			default: false,
		},
		isCenter: {
			type: Boolean,
			default: false,
		},
		isRight: {
			type: [Boolean, String],
			default: false,
		},
		isSmall: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		alignStyle() {
			const style = {}
			if (this.isLeft) {
				style.left = typeof this.isLeft === 'boolean' ? '0px' : this.isLeft
			}
			if (this.isCenter) {
				style.left = '50%'
				style.transform = 'translateX(-50%)'
			}
			if (this.isRight) {
				style.right = typeof this.isRight === 'boolean' ? '0px' : this.isRight
			}
			if (this.isTop) {
				style.top = typeof this.isTop === 'boolean' ? '0px' : this.isTop
			}
			if (this.isMiddle) {
				style.left = '50%'
				style.transform = 'translateX(-50%)'
			}
			if (this.isBottom) {
				style.bottom = typeof this.isBottom === 'boolean' ? '0px' : this.isBottom
			}
			if (Object.keys(style).length) {
				style.position = 'absolute'
			}
			return style
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.loading-spinner {
	display: inline-flex;
	align-items: center;
	font-size: 0.9rem;
	padding: 4px 8px;
	color: var(--secondary-label);
	z-index: 10;
	span {
		margin-left: 5px;
	}
	&.is-small {
		span {
			display: none;
		}
	}
}
</style>
