<template>
	<div class="filter">
		<label>{{ label }}</label>
		<div class="input-row">
			<ast-radio v-for="[id, name] in dateRangeOptions" :key="id" v-model="_dateRange" :value="id">
				{{ name }}
			</ast-radio>
		</div>
		<div class="input-row">
			<datepicker
				v-model="_startDate"
				placeholder="Start Date"
				:style="{ marginRight: '10px' }"
				allow-future-dates
			/>
			<datepicker v-model="_endDate" placeholder="End Date" end-of-day allow-future-dates />
		</div>
	</div>
</template>

<script>
import AstRadio from '@components/Radio'
import Datepicker from '@components/DatePicker.vue'
import { dateRangeLabels, getDateRanges, isSameDate } from '@utils/dateUtils'

export default {
	name: 'DateRangePicker',
	components: {
		AstRadio,
		Datepicker,
	},
	model: {
		prop: 'filter',
		event: 'input',
	},
	props: {
		filter: {
			type: Object,
			required: true,
		},
		label: {
			type: String,
			default: 'Date',
		},
		startDateKey: {
			type: String,
			default: 'startDate',
		},
		endDateKey: {
			type: String,
			default: 'endDate',
		},
		// whitelist of date range options to show (e.g. ['All', 'Today', 'Yesterday'])
		dateRanges: {
			type: Array,
			default: null,
		},
	},
	computed: {
		_dateRange: {
			get() {
				const dateRanges = getDateRanges()
				for (const key in dateRanges) {
					const dateRange = dateRanges[key]
					if (
						isSameDate(dateRange.startDate, this.filter[this.startDateKey]) &&
						isSameDate(dateRange.endDate, this.filter[this.endDateKey])
					) {
						return key
					}
				}
				return null
			},
			set(key) {
				const dateRanges = getDateRanges()
				let range = dateRanges[key]
				if (range !== undefined) {
					// emit a new copy of the filter with the modified ranges
					this.$emit('input', {
						...this.filter,
						[this.startDateKey]: range.startDate,
						[this.endDateKey]: range.endDate,
					})
				}
			},
		},
		_startDate: {
			get() {
				return this.filter[this.startDateKey]
			},
			set(startDate) {
				this.$emit('input', {
					...this.filter,
					[this.startDateKey]: startDate,
				})
			},
		},
		_endDate: {
			get() {
				return this.filter[this.endDateKey]
			},
			set(endDate) {
				this.$emit('input', {
					...this.filter,
					[this.endDateKey]: endDate,
				})
			},
		},
		dateRangeOptions() {
			return Object.entries(dateRangeLabels).filter(
				// if dateRanges prop is provided, only show those date range options
				([value, label]) => !this.dateRanges || this.dateRanges.includes(value)
			)
		},
	},
	mounted() {
		// convert date strings to date objects if necessary
		if (this._startDate && !this._startDate.getDate) this._startDate = new Date(this._startDate)
		this.$nextTick(() => {
			if (this._endDate && !this._endDate.getDate) this._endDate = new Date(this._endDate)
		})
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.vdp-datepicker + .vdp-datepicker {
	margin: 8px 0;
}
@media (min-width: $mqSmall) {
	.vdp-datepicker + .vdp-datepicker {
		margin: 0 8px;
	}
}
label {
	display: block;
	font-size: 0.9em;
	font-weight: 400;
	padding-bottom: 8px;
}
.input-row {
	display: flex;
	flex-wrap: wrap;
	@media (max-width: $mqSmall) {
		flex-direction: column;
		align-items: flex-start;
	}
	span {
		padding-bottom: 8px;
	}
}
</style>
