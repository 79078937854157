import { API } from '@services/api'
import { eventBus } from '@services/eventBus'
import { addNotification } from './notificationService'

enum MessageType {
	error = 'error',
	WatchStudies = 'watch_studies',
	StudyChangeNotification = 'study_change_notification',
}

interface IMessage {
	type: string
	error?: string
	data?: string
	studyIds?: string[]
	studyId?: string
}

let socket: WebSocket = null
let connectedPromise: Promise<void>
let subscribeSendTimer = null
let _watchedIds = new Set<string>()
let isLogout = false
let interval = null
let lifeTimerInterval = null
let wssUrl = window.config.websocketServerUrl
let wsFallbackUrl = ''
let pingTimeout = null
let isConnectedToFallback = false
let sleepInterval = 60 * 1000 // 60 seconds
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


interface IStudyCallback {
	studyId: string
	callback: (studyId: string) => void
}

class WsData {
	studyWatchCallbacks: IStudyCallback[] = []

	async start() {
    if (isMobile) return
		isLogout = false
		if (window.config.disableWS === 'true') return
    let parts = API.defaults.baseURL.split('//')
    let host = parts[1]
    let protocol = parts[0] === 'https:' ? 'wss' : 'ws'
    wsFallbackUrl = `${protocol}://${host}/ws`

		await this.init().catch((msg) => {
      wssUrl = wsFallbackUrl // fail back to the original server
      isConnectedToFallback = true
    })
		// try to create a connection every sleepInterval
		interval = setInterval(async () => {
			if (!socket && !isLogout) {
				await this.init().catch((msg) => {
          if (isConnectedToFallback) {
            wssUrl = `${protocol}://${host}/ws` // fail back to the original server
          }
        })
			}
		}, sleepInterval)

		eventBus.on('logout', () => {
			isLogout = true
			if (interval) {
				clearInterval(interval)
			}
		})
	}

	init(): Promise<any> {
    if (isMobile) return
    if (isLogout) {
      return
    }

    console.log('Connecting to :', wssUrl)

		if (isLogout) {
			return
		}
		connectedPromise = new Promise((resolve, reject) => {

			socket = new WebSocket(wssUrl)

			socket.onopen = () => {
				console.log('socket connected')
				resolve()
				this.sendStudySubscriptions()
			}

			socket.onclose = (event: CloseEvent) => {
				console.log('socket disconnected')
				socket = null
				_watchedIds = new Set<string>()
        isConnectedToFallback = false
			}

			socket.onmessage = evt => {
				this.recieveMessage(evt)
			}

      socket.onerror = evt => {
        if (socket.readyState === 3) {
          socket = null
          connectedPromise = null
          clearInterval(interval)

          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Failed to connect to the server: ' + wssUrl)
        }
      }
		})
		return connectedPromise
	}

	closeConnection() {
		if (socket) {
			socket.close()
			console.log('socket closed')
		}
	}

	recieveMessage(evt: MessageEvent) {
		let msg: IMessage = JSON.parse(evt.data)

		if (msg.error) {
			addNotification(msg.error, 'error')
		}

		switch (msg.type) {
			case MessageType.StudyChangeNotification:
				this.studyWatchCallbacks.forEach(i => {
					if (i.studyId === msg.studyId) {
						i.callback(msg.studyId)
					}
				})
				break
		}
	}

	reset() {
		this.studyWatchCallbacks = []
		this.sendStudySubscriptions()
	}

	async send(msg: IMessage) {
		if (!socket) return
		await connectedPromise
		socket.send(JSON.stringify(msg))
	}

	unsubscribeByCallback(callback: Function) {
		for (let i = this.studyWatchCallbacks.length - 1; i >= 0; i--) {
			if (this.studyWatchCallbacks[i].callback === callback) {
				this.studyWatchCallbacks.splice(i, 1)
			}
		}
		this.sendStudySubscriptions()
	}

	unsubscribe(studyId: string, callback: Function) {
		let item = this.studyWatchCallbacks.find(i => i.studyId === studyId && i.callback === callback)
		if (item) {
			this.studyWatchCallbacks.splice(this.studyWatchCallbacks.indexOf(item), 1)
			this.sendStudySubscriptions()
		}
	}

	subscribe(studyId: string, callback: (studyId: string) => void) {
		this.studyWatchCallbacks.push({
			studyId,
			callback,
		})
		this.sendStudySubscriptions()
	}

	sendStudySubscriptions() {
		if (subscribeSendTimer) {
			clearTimeout(subscribeSendTimer)
		}

		subscribeSendTimer = setTimeout(() => {
			let newSet = new Set<string>()
			this.studyWatchCallbacks.forEach(c => newSet.add(c.studyId))

			let changed = newSet.size !== _watchedIds.size
			if (!changed) {
				for (let id of newSet.values()) {
					if (!_watchedIds.has(id)) {
						changed = true
						break
					}
				}
			}

			if (changed) {
				this.send({
					type: MessageType.WatchStudies,
					studyIds: Array.from(newSet),
				})
				_watchedIds = newSet
			}
		}, 100)
	}
}

export const wsData = new WsData()
export default wsData
// @ts-ignore
window.wsData = wsData
