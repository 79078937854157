<template>
	<div
		class="report-drop-down"
		:class="{ stack: cell.props['label position'] == 'Left' }"
		:title="cell.isValueOverridden ? 'Calculated value is being overridden.' : ''"
	>
		<label v-if="cell.props.label" :style="labelStyle">{{ cell.props.label }}</label>
		<v-select
			v-model="cellValue"
			:clearable="false"
			:searchable="cell.props['allow custom value']"
			:taggable="cell.props['allow custom value']"
			:options="options"
			:class="{ 'is-overridden': cell.isValueOverridden, disabled: disabled }"
		/>
		<button
			v-if="cell.isValueOverridden"
			class="reset-override"
			title="Clear manual entry"
			@click="cell.userValue = null"
		>
			<svg-icon icon="undo" />
		</button>

		<div v-if="disabled" class="overlay"></div>
	</div>
</template>

<script>
export default {
	name: 'Dropdown',
	props: ['cell'],
	computed: {
		cellValue: {
			get() {
				return this.cell.value
			},
			set(value) {
				this.cell.value = value && value.trim()
			},
		},
		disabled() {
			return this.cell.editorMode || this.cell.readOnly
		},
		options() {
			let cell = this.cell
			const options = [...cell.props.options]
			// if dropdown is not required, check if there is a null/empty option; add one if necessary
			if (!cell.props.required && !options.some(o => [null, ''].includes(o))) {
				options.unshift(' ') // Alt-255 character to render an empty option, not space
			}
			return options
		},
		labelStyle() {
			if (this.cell.props['label position'] === 'Top') {
				return {
					display: 'block',
					marginBottom: '8px',
				}
			}
			return {}
		},
	},
	created() {
		if (this.cell.template.editorMode || (this.cell.report && this.cell.report.isPreview)) {
			this.cell.value = this.cell.props.default
		}
	},
}
</script>

<style lang="scss">
.report-drop-down {
	padding: 4px 0;
	position: relative;
	.overlay {
		position: absolute;
		background: transparent;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.reset-override {
		top: 4px;
		right: 24px;
	}
	.is-overridden {
		box-shadow: inset 3px 0 0 0 var(--icon-warning);
	}
}
</style>
