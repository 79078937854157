import { set } from 'vue-gtag'

// NOTE: When changing analytics custom dimensions, update this const
export const gaMap = {
	CLINIC_ID: 'dimension1',
	CLINIC_CODE: 'dimension2',
	OMNI_VERSION: 'dimension3',
	USERNAME: 'dimension4',
	IS_COMMUNITY: 'dimension5',
	IS_DESKTOP: 'dimension6',
}

const state = {}

const getters = {}

const mutations = {}

const actions = {
	SET_USER: (store, { id, clinicId, clinicCode, username, isCommunity, isDesktop }) => {
		set({
			userId: id,
			[gaMap.CLINIC_ID]: clinicId,
			[gaMap.CLINIC_CODE]: clinicCode,
			[gaMap.USERNAME]: username,
			[gaMap.IS_COMMUNITY]: String(isCommunity),
			[gaMap.IS_DESKTOP]: String(isDesktop),
		})
	},
	UNSET_USER: () => {
		set({
			userId: '',
			[gaMap.CLINIC_ID]: '',
			[gaMap.CLINIC_CODE]: '',
			[gaMap.USERNAME]: '',
			[gaMap.IS_COMMUNITY]: '',
			[gaMap.IS_DESKTOP]: '',
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
