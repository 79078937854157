<template>
	<div class="editor-placeholder">
		<svg-icon icon="info-circle" inline fixed />
		<span v-if="binding">
			The contents of <strong style="font-size: 0.9em;">{{ binding }}</strong> will appear here.
		</span>
		<span v-else>
			Select a panel from the <strong style="font-size: 0.9em;">Binding</strong> dropdown in the
			properties panel, and its contents will appear here.
		</span>
	</div>
</template>

<script>
export default {
	name: 'PanelView',
	props: ['cell'],
	computed: {
		binding() {
			return this.cell.props['binding']
		},
	},
}
</script>

<style lang="scss"></style>
