<template>
	<drag-annotation
		:id="`${id}-rect`"
		v-model="position"
		:group-id="groupId"
		@drag-start="draggingAll = true"
		@drag-end="draggingAll = false"
	>
		<g v-if="p1 && p2 && p3 && p4" :style="groupStyle" data-position-ref>
			<!--Display rect-->
			<polygon
				:style="strokeStyle"
				:points="
					`
					${p1.x},${p1.y}
					${p2.x},${p2.y}
					${p3.x},${p3.y}
					${p4.x},${p4.y}
				`
				"
			/>
			<!--Transparent hit target rect-->
			<polygon
				:data-id="`${id}-rect`"
				:data-group-id="groupId"
				:style="strokeHitTargetStyle"
				:points="
					`
					${p1.x},${p1.y}
					${p2.x},${p2.y}
					${p3.x},${p3.y}
					${p4.x},${p4.y}
				`
				"
			/>
			<!--Start handle-->
			<drag-annotation
				v-model="p1"
				:group-id="groupId"
				:color="activeColor"
				show-precision
				@drag-start="drag.p1 = true"
				@drag-end="drag.p1 = false"
			/>
			<!--End handle-->
			<drag-annotation
				v-model="p3"
				:group-id="groupId"
				:color="activeColor"
				show-precision
				drag-on-load
				@drag-start="drag.p3 = true"
				@drag-end="drag.p3 = false"
			/>
			<!--Data label-->
			<text-annotation
				v-if="statsVisible"
				:value="statText"
				:group-id="groupId"
				:event-source="textSource"
				:color="activeColor"
			/>
		</g>
	</drag-annotation>
</template>

<script>
import DragAnnotation from './DragAnnotation'
import TextAnnotation from './TextAnnotation'
import { quadMixin } from './mixins'

export default {
	name: 'RectAnnotation',
	components: {
		DragAnnotation,
		TextAnnotation,
	},
	mixins: [quadMixin],
	computed: {
		area() {
			const worldWidth = this.worldDistance(this.widthNorm)
			const worldHeight = this.worldDistance(this.heightNorm)
			return worldWidth * worldHeight
		},
	},
}
</script>
